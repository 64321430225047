import ApiService from '@/api/'

export default {
  getSubjectsAndOrders(userId) {
    /* @param userId - optional */

    // EXPECTED RESPONSE {object}:
    // cmplCd: "N"
    // guestAllowed: false
    // orderReqd: true
    // rsnCd: "S-100"
    // rsnTx: "WRONG"
    // sendToEgain: false
    // svcTx: "Incorrect item"
    // typeCd: "B"
    const userIdStr = userId ? `${userId}` : '2'
    return ApiService.get(`/apis/v1/selfservice/${userIdStr}/`)
  },
  postAccount({ payload, userId = '' }) {
    /*
      * @param payload {object} - required
      * @param userId - optional
      * example payload:
      *   "body": "Test email",
      *   "debugEmail": "true",
      *   "email": "test@mailinator.com",  // --Required
      *   "name": "caleb",
      *   "opco": "stsh",
      *   "ordId": "i155984331",    // -- (Required Only for Report an Order Issue-UC)
      *   "phone": "888-888-8888",
      *   "subject": "S-100",     // -- Required
      *   "userId": "1644178510" // -- Required
    */
    const userIdStr = userId ? `${userId}` : '2'
    return ApiService.post(`/apis/v1/selfservice/${userIdStr}/`, payload)
  },
  postAccountMkt({ payload, userId }) {
    /*
      * @param payload {object} - required
      * @param userId - required
      * example payload:
      *  "email": "dinara-gntl@mailinator.com", // --Required
      *  "logisticsOrderId": "B1000002397-A", // --Required
      *  "name": "Dina Aid",
      *  "opco": "GNTL",
      *  "phone": "3125667890",
      *  "productDetails": [{        // --Required
      *    "productId": "30081352",
      *    "reasonCode": "S-102",
      *    "comments": "this is a test"
      * }, {
      *    "productId": "30081635",
      *    "reasonCode": "S-103",
      *    "comments": "this is a test"
      *  }],
      *  "userId": 118393222 // --Required
    */
    const userIdStr = userId ? `${userId}` : '2'
    return ApiService.post(`/apis/v1/selfservice/${userIdStr}/marketPlace/`, payload)
  },
  postGeneral(payloadData) {
    const { opco, captcha, payload } = payloadData || {}
    return ApiService.post(`/apis/contactus/contact/v1/${opco}?captchaUserToken=${captcha}`, payload)
  }
}
