<script>
import { mapGetters, mapState } from 'vuex'

export default {
  render() {
    return ''
  },
  data() {
    return {
      hasSeenP3Modal: false,
      hasSeenUnataModal: false
    }
  },
  watch: {
    isAppLoaded(newValue, oldValue) {
      if (!oldValue && newValue) {
        this.initWelcomeView()
      }
    }
  },
  mounted() {
    if (this.isAppLoaded) {
      this.initWelcomeView()
    }
  },
  computed: {
    ...mapState({
      isAppLoaded: state => state.Application.isAppLoaded,
    }),
    ...mapGetters({
      varByName: 'SiteConfig/varByName',
      transitionCompletedOrStoreIsP3: 'FoodLionTransition/transitionCompletedOrStoreIsP3',
    })
  },
  methods: {
    initWelcomeView() {
      try {
        const { query } = this.$route
        const isSuperUserApp = window.appConfig.superUserEnabled
        const botCheck = /Googlebot|Googlebot-Image|Googlebot-News|Googlebot-video/i.test(navigator.userAgent)
        const isModalRouteUrl = this.$route.path.includes('modal')
        const hideSiteTranistion = query.transition === 'false'
        const isNativeApp = this.$store.getters['NativeContainer/isNativeApp']
        const siteTransitionModalEnabled = this.varByName('feature_site_transition_modal') && !isNativeApp
        const shouldShowModal = this.shouldShowStoreTransitionModal()

        if (botCheck || isSuperUserApp
          || (!shouldShowModal)
          || (isModalRouteUrl || hideSiteTranistion)) {
          return
        }
        if (siteTransitionModalEnabled) {
          this.$store.commit('Modals/setActiveModal', {
            fileName: 'WelcomeModal',
            props: {
              hasSeenP3Modal: this.hasSeenP3Modal,
              hasSeenUnataModal: this.hasSeenUnataModal
            }
          })
          this.setAndCacheStoreTransition()
        }
      } catch {
        console.error('WelcomeView:initWelcomeView:failed')
      }
    },
    setAndCacheStoreTransition() {
      if (this.transitionCompletedOrStoreIsP3) {
        window.localStorage.setItem('p3StoreTransitionModal', 'true')
        return
      }
      window.localStorage.setItem('unataStoreTransitionModal', 'true')
    },
    shouldShowStoreTransitionModal() {
      this.hasSeenP3Modal = window.localStorage.getItem('p3StoreTransitionModal')
      this.hasSeenUnataModal = window.localStorage.getItem('unataStoreTransitionModal')
      const hasNotSeenWelcomeModal = this.hasSeenP3Modal === null && this.hasSeenUnataModal === null

      return hasNotSeenWelcomeModal
        || (!this.hasSeenUnataModal && !this.transitionCompletedOrStoreIsP3)
        || (!this.hasSeenP3Modal && this.transitionCompletedOrStoreIsP3)
    }
  }
}
</script>
