<template>
  <button
    class="spyglass-toggle-tab z-[9999]"
    @click.stop.prevent="toggleState"
  >
    {{ showing ? 'CLOSE' : 'OPEN' }} INFO
  </button>
</template>

<script>
export default {
  data: () => ({
    showing: false
  }),
  methods: {
    toggleState() {
      this.showing = !this.showing
      this.$emit('spyglass-toggle', this.showing)
    }
  }
}
</script>
