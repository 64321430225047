import ServiceLocationsAPI from 'api/ServiceLocationsAPI'
import { SERVICE_TYPE_DESCRIPTORS, SERVICE_TYPE_DESCRIPTORS_FDLN, ORDER_SERVICE_TYPES } from 'utils/constants'

const getDefaultState = () => ({
  currentView: 'Select',
  suppressBrowseMode: false,
  shouldShowSlots: false,
  geoZipCode: '',
  redirectToModal: '', // state to maintain info of modal that open serviceModal
  isServiceSelectionLoading: false,
  inStoreOption: {
    ...SERVICE_TYPE_DESCRIPTORS[ORDER_SERVICE_TYPES.BROWSE],
    isLoading: false
  },
  pickupOption: {
    ...SERVICE_TYPE_DESCRIPTORS[ORDER_SERVICE_TYPES.PICKUP],
    isLoading: false
  },
  deliveryOption: {
    ...SERVICE_TYPE_DESCRIPTORS[ORDER_SERVICE_TYPES.DELIVERY],
    isLoading: false
  },
  guestCityOptions: [],
  selectedServiceId: ''
})

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    serviceOptionsData: (...[state, , , rootGetters]) => {
      const opco = rootGetters['SiteConfig/opco']
      if (opco === 'FDLN') {
        return [
          { ...state.inStoreOption, ...SERVICE_TYPE_DESCRIPTORS_FDLN[ORDER_SERVICE_TYPES.BROWSE] },
          { ...state.pickupOption, ...SERVICE_TYPE_DESCRIPTORS_FDLN[ORDER_SERVICE_TYPES.PICKUP] },
          { ...state.deliveryOption, ...SERVICE_TYPE_DESCRIPTORS_FDLN[ORDER_SERVICE_TYPES.DELIVERY] }
        ]
      }
      return [state.inStoreOption, state.pickupOption, state.deliveryOption]
    }
  },
  mutations: {
    setCurrentView(state, value) {
      state.currentView = value
    },
    setSuppressBrowseMode(state, value) {
      state.suppressBrowseMode = value
    },
    setShouldShowSlots(state, value) {
      state.shouldShowSlots = value
    },
    setGeoZipCode(state, value) {
      state.geoZipCode = value
    },
    reset(state) {
      Object.assign(state, getDefaultState())
    },
    setRedirectToModal(state, value) {
      state.redirectToModal = value
    },
    setServiceSelectionLoading(state, value) {
      state.isServiceSelectionLoading = value
    },
    setServiceOption(state, value) {
      switch (value.serviceType) {
        case ORDER_SERVICE_TYPES.BROWSE:
          state.inStoreOption = value
          break
        case ORDER_SERVICE_TYPES.PICKUP:
          state.pickupOption = value
          break
        case ORDER_SERVICE_TYPES.DELIVERY:
          state.deliveryOption = value
          break
        default:
          break
      }
    },
    setGuestCityOptions(state, value) {
      state.guestCityOptions = value
    },
    setSelectedServiceId(state, value) {
      state.selectedServiceId = value
    }
  },
  actions: {
    async getServiceLocation({ commit }, { id }) {
      const request = { params: { serviceLocationId: id } }
      const responseData = await ServiceLocationsAPI.get(request)
      commit('reset')
      if (responseData.status === 200) {
        const { locations } = responseData.data?.response ?? {}
        if (locations.length && locations[0].location?.active) {
          return locations[0].location
        }
      }
      return {}
    },
    modifyOptionLoadingState({ getters, commit }, payload) {
      const { serviceType, loading } = payload

      getters.serviceOptionsData.forEach((option) => {
        const isLoading = option.serviceType === serviceType ? loading : false
        commit('setServiceOption', { ...SERVICE_TYPE_DESCRIPTORS[option.serviceType], isLoading })
      })
    }
  }
}
