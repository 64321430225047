<template>
  <fieldset>
    <div
      :class="{
        'pdl-radio-group--column': direction === 'column',
        'pdl-radio-group--row': direction === 'row'
      }"
    >
      <legend v-if="legend">
        {{ legend }}
      </legend>
      <PdlRadioButton
        v-for="option in options"
        :key="option.text"
        :label="option.text"
        :name="name"
        :model-value="option.value"
        :full-width="menuStyle"
        :is-checked="modelValue === option.value"
        @update:modelValue="emitChange"
      />
    </div>
  </fieldset>
</template>
<script>
import PdlRadioButton from '@/shared/form/PdlRadioButton'

export default {
  components: {
    PdlRadioButton
  },
  props: {
    options: {
      type: Array,
      default() {
        return []
      }
    },
    legend: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      required: true
    },
    modelValue: {
      type: String,
      required: true
    },
    menuStyle: {
      type: Boolean,
      default: false
    },
    direction: {
      type: String,
      default: 'column',
      validator: value => ['row', 'column'].indexOf(value) !== -1
    }
  },
  emits: ['update:modelValue'],
  methods: {
    emitChange(modelValue) {
      this.$emit('update:modelValue', modelValue)
    }
  }
}
</script>
