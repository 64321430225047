import { required } from '@vuelidate/validators'

export default {
  required,
  format(firstName) {
    // allow ios smart punctuation apostrophe for names like Catherine O’Hare
    return new RegExp(/^[a-zA-Z'’-]+(\s[a-zA-Z'’-]+)?$/).test(
      firstName
    )
  }
}
