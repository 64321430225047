export const excludedItemNames = [
  'My Account',
  'Create Account',
  'Sign In',
  'Create an Account'
]

// loyalty not registered - checkout not complete
export const loyaltyIncludeLinks = [
  {
    url: '/registration',
    label: 'Finish Account Setup'
  }
]

// loyalty not registered - checkout complete
export const registeredLoyaltyIncludeLinks = [
  {
    url: '/registration',
    label: 'Finish Profile'
  }
]

export const guestIncludeLinks = [
  {
    url: '/modal/login',
    linkType: 'Modal',
    label: 'Sign In'

  },
  {
    url: '/registration',
    linkType: 'Internal',
    label: 'Create Account'
  },
]

export const loggedinUserIncludeLinks = [
  {
    url: '/account',
    linkType: 'Internal',
    label: 'My Account'
  }
]

const contactIncludeLinks = [
  {
    label: 'Contact Us',
    linkType: 'External',
    url: '/contact'
  }
]

const orderHistoryIncludeLink = {
  label: 'Order History',
  linkType: 'Internal',
  url: '/account/history'
}

const getLoyaltyLinks = (isCheckoutRegistered, isLoyaltyRegistered, isLoggedIn) => {
  if (!isLoggedIn || isLoyaltyRegistered) return []

  return (isCheckoutRegistered) ? registeredLoyaltyIncludeLinks : loyaltyIncludeLinks
}

const mergeExtraLinks = (links, isLoyaltyRegistered) => {
  // hardcoding menu items until new menu items are setup 266 - 286 removed when items are available
  links = [...links, ...contactIncludeLinks]
  if (isLoyaltyRegistered) {
    links.splice(1, 0, orderHistoryIncludeLink)
  }
  return links
}

export const filterLinkData = (
  links = [],
  isLoyaltyRegistered = false,
  shouldIncludeLinks = false
) => {
  const filteredLinks = links.filter(item => !excludedItemNames.includes(item.label))

  let results = [...filteredLinks]
  if (shouldIncludeLinks) {
    results = mergeExtraLinks(results, isLoyaltyRegistered)
  }

  return results
}

export const mapAccountMenuItems = ({
  isCheckoutRegistered,
  isLoyaltyRegistered,
  isLoggedIn,
  userMenuData = {},
  guestMenuData = {}
}, shouldIncludeLinks) => {
  const guestLinks = !isLoggedIn ? guestIncludeLinks : []
  const loyaltyLinks = getLoyaltyLinks(isCheckoutRegistered, isLoyaltyRegistered, isLoggedIn)
  const fullyRegisteredLinks = isLoggedIn && isLoyaltyRegistered ? loggedinUserIncludeLinks : []
  const cmsSource = (isLoggedIn ? userMenuData : guestMenuData)
  const { links = [], spyGlassData = {} } = cmsSource || {}
  const cmsFilteredLinks = filterLinkData(links, isLoyaltyRegistered, shouldIncludeLinks)
  const joinedLinks = [...guestLinks, ...loyaltyLinks, ...fullyRegisteredLinks, ...cmsFilteredLinks]

  return {
    links: joinedLinks,
    spyGlassData
  }
}
