const HelpPage = () => import('./pages/HelpPage')

export default [
  {
    path: '/modal/help-modal:pathMatch(.*)*',
    redirect: '/help'
  },
  {
    path: '/modal/help/topic:pathMatch(.*)*',
    redirect: '/help',
  },
  {
    path: '/modal/help:pathMatch(.*)*',
    redirect: '/help',
  },
  {
    path: '/help-modal:pathMatch(.*)*',
    redirect: '/help'
  },
  {
    path: '/help/topic:pathMatch(.*)*',
    redirect: '/help',
  },
  {
    name: 'help',
    path: '/help:pathMatch(.*)*',
    component: HelpPage,
    beforeEnter(to, from, next) {
      setTimeout(() => {
        const { pathMatch } = to?.params || {}
        window.sharedVue.config.globalProperties.$store.commit('Help/setSearchQuery', `${pathMatch || ''}`)
      })
      next()
    }
  },
]
