/** function formatDateParameter - format date for display
 *
 * @param date - date string to be formatted
 * @param format - format output
* */

export default function (date, formatType) {
  if (!date) return ''
  return window.dateFormat(date, formatType)
}

/** @constant dateFormatConstants - constants to pass to the dateFormat function
 * @type {object}
 * @property {string} shortDate             - expected: '03/03/1991'
 * @property {string} shortYearDate         - expected: '03/03/91'
 * @property {string} shortMonthDay         - expected: '03/03'
 * @property {string} mediumMonthDay        - expected: 'Mar 03'
 * @property {string} mediumWeekMonthDay    - expected: 'Thu, Mar 3'
 * @property {string} longDate              - expected: 'March 03, 2022'
 * @property {string} longMonthDay          - expected: 'March 3'
 * @property {string} longWeekMonthDay      - expected: 'Thursday, Mar 3'
 * @property {string} longFullWeekMonthDay  - expected: 'Thursday, March 3'
 * @property {string} timeShort             - expected: '06:00pm'
 * @property {string} shortMonthLongDate    - expected: 'Mar 03, 2022'
*/
const dateFormatConstants = {
  shortDate: 'UTC:mm/dd/yyyy',
  shortYearDate: 'UTC:mm/dd/yy',
  shortMonthDay: 'UTC:mm/dd',
  mediumMonthDay: 'UTC:mmm dd',
  mediumWeekMonthDay: 'UTC:ddd, mmm d',
  longDate: 'UTC:mmmm dd, yyyy',
  longMonthDay: 'UTC:mmmm d',
  longWeekMonthDay: 'UTC:dddd, mmm d',
  longFullWeekMonthDay: 'UTC:dddd, mmmm d',
  timeShort: 'hh:MMtt',
  shortMonthLongDate: 'mmm dd, yyyy',
}

export const {
  shortDate,
  shortYearDate,
  shortMonthDay,
  mediumMonthDay,
  mediumWeekMonthDay,
  longDate,
  longMonthDay,
  longWeekMonthDay,
  longFullWeekMonthDay,
  timeShort,
  shortMonthLongDate
} = dateFormatConstants
