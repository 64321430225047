/**
 * @param {string} code - keyboard code ignored if not value "ArrowUp"|"ArrowDown"
 * @param {number} currentPosition in pixels
 * @param {number} listItemOffset - in pixels
 * @param {number} listOffset - in pixels
 * @param {Element} scrollObject - html element
 * @returns {void}
 */
// eslint-disable-next-line max-params
export function handleKeyStrokeScroll(code, currentPosition, listItemOffset, listOffset, scrollObject) {
  const accOffSet = currentPosition * listItemOffset
  if (code === 'ArrowDown' && accOffSet - scrollObject.scrollTop > listOffset) {
    scroll({ top: accOffSet - listOffset }, scrollObject)
  } else if (code === 'ArrowUp' && accOffSet < listOffset) {
    scroll({ top: accOffSet - listItemOffset }, scrollObject)
  }
}
/**
 * @param {{top: number}} position
 * @param {Element} scrollObject
 * @returns {void}
 */
function scroll(position, scrollObject) {
  scrollObject.scroll({
    ...position,
    behavior: 'smooth'
  })
}
