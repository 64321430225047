import * as components from '@/shared'

const sharedInstaller = {
  install(Vue) {
    Object.keys(components).forEach((componentName) => {
      Vue.component(componentName, components[componentName])
    })
  }
}

export {
  sharedInstaller
}
