/** function formatAltId - format Alt Id for Loyalty account phone
* Requirement - Phone format should not contain special characters
* Purpose - Strip extra characters from parameter
* @param val - can be type String or Number
* Return as String type
* If value is falsy return current state
* */
export default function (val) {
  return val ? String(val).replace(/\D/g, '') : val
}
