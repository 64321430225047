<template>
  <div
    v-if="notifications.length"
    class="flex flex-row"
    :class="{
      'pdl-notification-bar_container w-full py-4 px-6 text-white': !isMobile,
      'pdl-notification-bar-mobile_container justify-between relative rounded-brand h-auto':isMobile,
      'bg-white': isMobile && !getContactFreeLevel,
      'pdl-notification-bar_container--emergency': getContactFreeLevel
    }"
  >
    <h1
      v-if="displayFirstName && !isMobile"
      class="pdl-notification-bar_greetings flex flex-column flex-initial justify-center mr-8 pr-8 font-semibold"
      :class="{'pdl-notification-bar_greetings--emergency': getContactFreeLevel}"
    >
      Hi, {{ displayFirstName }}
    </h1>
    <a
      href="#"
      :class="[
        'cursor-pointer h-auto min-h-[4rem] text-white no-underline',
        isMobile ? 'pdl-notification-bar-mobile_content' : 'pdl-notification-bar_content'
      ]"
      aria-label="Go to notifications"
      aria-haspopup="dialog"
      data-opens-modal="true"
      @click.prevent="onNotificationBarClick"
      @keydown.enter="onNotificationBarClick"
    >
      <div
        class="flex"
      >
        <span
          class="pdl-notification-bar_count rounded-brand bg-primary mr-2 inline-block text-center font-size-14
            float-left text-white"
        >
          {{ notifications.length }}
        </span>
        <div
          class="pdl-notification-bar_title items-end uppercase font-semibold"
          :class="{
            'text-gray-800': isMobile,
            'pdl-notification-bar_title--emergency': getContactFreeLevel && !isMobile
          }"
        >
          Notifications
          <PdlIconBase
            v-if="!isMobile"
            icon-title="Go to notifications"
            :stroke-color="getContactFreeLevel ? 'black' : 'white'"
            :container-class="'pdl-notification-bar_title-icon'"
          >
            <PdlIconCarrotRight />
          </PdlIconBase>
        </div>
      </div>
      <ul
        class="relative"
        :class="{
          'pdl-notification-bar_list-emergency': getContactFreeLevel && !isMobile
        }"
      >
        <li
          v-for="(notification, index) in notifications"
          :id="`notification-${index}`"
          :key="index"
          class="absolute"
          :class="{
            'pdl-notification-bar_copy': !isMobile,
            'pdl-notification-bar_copy--active': index === 0 && !isMobile,
            'pdl-notification-bar-mobile_copy pr-14 w-full block text-gray-800': isMobile,
            'pdl-notification-bar-mobile_copy--active': index === 0 && isMobile
          }"
        >
          {{ notification.headline }}
        </li>
      </ul>
    </a>
    <PdlIconBase
      v-if="isMobile"
      icon-title="Arrow Icon"
      :icon-class="getContactFreeLevel ? 'vector-icon-color--darkest-grey' : 'vector-icon-color--dark-grey'"
      container-class="pdl-notification-bar-mobile_title-icon vector-icon-size--arrow flex h-full self-center
        whitespace-nowrap text-ellipsis overflow-hidden"
    >
      <PdlIconCaret />
    </PdlIconBase>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import PdlIconCaret from 'components/icons/icon/PdlIconCaret'
import PdlIconCarrotRight from 'components/icons/icon/PdlIconCarrotRight'

export default {
  components: {
    PdlIconCaret,
    PdlIconCarrotRight
  },
  props: {
    currentIndex: {
      type: Number,
      default: 0
    },
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      iteration: 0
    }
  },
  computed: {
    ...mapState({
      notifications: state => state.Notifications.notifications
    }),
    ...mapGetters({
      displayFirstName: 'UserProfile/displayFirstName',
      contactFreeLevel: 'UserProfile/contactFreeLevel'
    }),
    getContactFreeLevel() {
      return this.contactFreeLevel >= 1
    }
  },
  mounted() {
    if (this.notifications.length) {
      this.startRollAnimation()
    }
  },
  beforeUnmount() {
    this.stopRollAnimation()
  },
  methods: {
    onNotificationBarClick() {
      this.$store.commit('Modals/setActiveModal', {
        fileName: 'PdlNotificationModal',
      })
    },
    startRollAnimation() {
      let currentIndex = 0
      const addAnimationClass = (() => {
        let el = document.getElementById(`notification-${currentIndex}`)
        el.className = 'pdl-notification-bar_copy pdl-notification-bar_copy--overactive hidden'
        currentIndex = (currentIndex + 1) % this.notifications.length
        el = document.getElementById(`notification-${(currentIndex)}`)
        el.className = 'pdl-notification-bar_copy pdl-notification-bar_copy--active'
      })
      this.iteration = setInterval(addAnimationClass, 5000)
    },
    stopRollAnimation() {
      clearInterval(this.iteration)
      this.iteration = 0
    }
  }
}
</script>
