<template>
  <!--
    This component is designed using existing PdlGuidingStarsTooltip.vue (Client) Component.
    This component just serves the purpose of displaying dynamic tooltip with pointing arrows in any direction.
  -->
  <div
    v-clickaway="{handler: 'handleClickOutside'}"
    class="w-tooltip_pop"
    :class="[
      {'w-tooltip_pop--active':isActive},
      {'w-tooltip_pop--mobileview':isMobileView && !withinProductTile},
      {'w-tooltip_pop--in-tile bottom-6': withinProductTile},
      {'top-4': withinProductTile && getDirection === 'top'},
      {'w-tooltip_wrap--right': !isMobileView && openTooltipTop},
    ]"
  >
    <div
      class="w-tooltip_menutip"
      :class="[
        isMobileView && !withinProductTile ? 'w-tooltip_menutip--top': `w-tooltip_menutip--${getDirection}`,
        {'w-tooltip_menutip--centered':centeredMenutip},
        isMobileView && openTooltipTop
          && !filterBrandOffering ? 'w-tooltip_menutip--mobileopenupwards' : '',
        darkMode ? 'bg-black' : ''
      ]"
    />
    <div
      ref="tooltipEl"
      :class="contentClasses"
    >
      <!-- eslint-disable vue/no-v-html -->
      <span
        v-if="tooltipContent && !$slots?.default?.()"
        v-html="getTooltipContent"
      />
      <slot />
    </div>
  </div>
</template>
<script>
import clickaway from 'utils/custom-directives/clickaway'

export default {
  directives: {
    clickaway
  },
  props: {
    /**
      * Tooltip content is HTML content.
      *
    */
    tooltipContent: {
      type: String,
      default: '',
    },
    /**
      * Direction of the pointing arrow
      * @values direction {String} Default 'left', Accepts ['right', 'left', 'bottom', 'top']
    */
    direction: {
      type: String,
      default: 'left'
    },
    /**
      * Direction of the pointing arrow will be 'top' in mobile view
      *
    */
    isMobileView: {
      type: Boolean,
      default: false
    },
    /* border-radius the tooltip */
    rounded: {
      type: Boolean,
      default: true
    },
    isActive: {
      type: Boolean,
      default: false
    },
    autoWidth: {
      type: Boolean,
      default: false
    },
    maxWidth: {
      type: String,
      default: ''
    },
    fixedWidth: {
      type: Boolean,
      default: false
    },
    centeredMenutip: {
      type: Boolean,
      default: false
    },
    withinProductTile: {
      type: Boolean,
      default: false
    },
    leftText: {
      type: Boolean,
      default: false
    },
    openTooltipTop: {
      type: Boolean,
      default: false
    },
    filterBrandOffering: {
      type: Boolean,
      default: false
    },
    darkMode: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getDirection() {
      if (!['right', 'left', 'bottom', 'top'].includes(this.direction.toLowerCase())) {
        return 'left'
      }
      return this.direction.toLowerCase()
    },
    getTooltipContent() {
      /**
       * As this is a storybook component, this.cleanHtml() can't be used
       * It needs to be added in future.
       * eg: return this.tooltipContent ? this.cleanHtml(this.tooltipContent) : ''
       */
      return this.tooltipContent
    },
    contentClasses() {
      return [
        this.fixedWidth ? 'w-tooltip_fixed' : '',
        'w-tooltip_content',
        this.isMobileView && !this.withinProductTile ? 'w-tooltip_content--mobileview'
          : `w-tooltip_content--${this.getDirection}`,
        this.openTooltipTop && this.isMobileView
          && !this.filterBrandOffering ? 'w-tooltip_content--mobileopenupwards' : '',
        this.rounded ? 'w-tooltip_content--rounded' : '',
        this.autoWidth ? 'w-auto' : '',
        this.maxWidth ? `max-w-[${this.maxWidth}]` : '',
        this.leftText ? 'text-left' : '',
        this.darkMode ? 'bg-black text-white' : ''
      ]
    },
  },
  watch: {
    isActive(val) {
      if (this.isMobileView && val) {
        this.$nextTick(() => {
          const tooltip = this.$refs.tooltipEl
          // reset
          tooltip.style.left = 'auto'

          const boundingRect = tooltip.getBoundingClientRect()
          const tooltipWidth = boundingRect.width

          if (boundingRect.x < 0) {
            tooltip.style.left = `${(-1 * boundingRect.x) + 10}px`
          } else if ((boundingRect.x + tooltipWidth) > window.innerWidth) {
            tooltip.style.left = `-${(boundingRect.x + tooltipWidth + 10) - (window.innerWidth)}px`
          }
        })
      }
    }
  },
  methods: {
    handleClickOutside() {
      this.$emit('clickaway')
    },
    marketplaceRedirect() {
      this.$store.commit('Modals/clearActiveModal')
      this.$router.push(`/pages/ship2me`)
    },
  },
}
</script>
