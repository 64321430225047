<template>
  <div class="pdl-incrementer pdl-incrementer--no-space pdl-incrementer--limited">
    <button
      class="button button--fourth button-width--flex pdl-incrementer_part button--connect-left"
      :class="{'button--disabled': disableDecrement }"
      :disabled="disableDecrement"
      aria-label="Decrement value"
      @click.stop="decrement()"
    >
      <PdlIconBase
        icon-title="Minus Icon"
        :container-class="[
          'vector-icon-size--xsmall',
          {'vector-icon-color--darker-grey': disableIncrement },
          {'vector-icon-color--increment': !disableIncrement }
        ]"
      >
        <PdlIconMinus />
      </PdlIconBase>
    </button>
    <div class="pdl-incrementer_part pdl-incrementer_part--wide order-tip_decor">
      <input
        :id="inputId"
        v-model="value"
        class="input--fourth input--sb input--connect-center pdl-incrementer_input"
        type="number"
        step="0.01"
        :max="maximum"
        :min="minimum"
        :maxlength="maxLength"
        :disabled="!isEnabled"
        aria-labelledby="tip-amount"
        @blur="onInput"
        @keydown.enter.prevent="onInput"
      >
      <p
        id="tip-amount"
        class="sr-only"
      >
        Tip amount (in dollars)
      </p>
    </div>
    <button
      class=" button button--fourth button-width--flex button--connect-right pdl-incrementer_part"
      aria-label="Increment value"
      :class="{'button--disabled': disableIncrement }"
      :disabled="disableIncrement"
      @click.stop="increment()"
    >
      <PdlIconBase
        icon-title="Plus Icon"
        :container-class="[
          'vector-icon-size--xsmall',
          {'vector-icon-color--darker-grey': disableIncrement },
          {'vector-icon-color--increment': !disableIncrement }
        ]"
      >
        <PdlIconPlus />
      </PdlIconBase>
    </button>
    <span
      v-if="showDollarPrefix"
      class="pdl-incrementer_dollar-sign"
    >$</span>
    <PdlLoadingButton
      v-if="showSave"
      id="save-tip-button"
      label="Save"
      button-color="second"
      :classes="['button-width--flex', 'ml-4']"
      :is-loading="loading"
      :is-disabled="disableSave"
      @click="save"
      @keyup.enter.space="save"
    />
  </div>
</template>

<script>
import {
  PdlIconBase,
} from '@/shared/icons'
import PdlIconMinus from 'components/icons/icon/PdlIconMinus'
import PdlIconPlus from 'components/icons/icon/PdlIconPlus'
import PdlLoadingButton from '@/shared/interactions/PdlLoadingButton'
import roundToDecimal from 'utils/filters/roundToDecimal'

export default {
  components: {
    PdlIconBase,
    PdlIconMinus,
    PdlIconPlus,
    PdlLoadingButton
  },
  props: {
    model: {
      type: [Number, String],
      default: 0,
      validator: value => (_.isNumber(value) || (_.isString(value) && !Number.isNaN(value)))
      // string must be number, but sometimes we need to use a String for double decimals
    },
    rate: {
      type: Number,
      default: 1
    },
    minimum: {
      type: Number,
      default: 0
    },
    maximum: {
      type: Number,
      default: 9999
    },
    maxLength: {
      type: Number,
      default: 2
    },
    isEnabled: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    useDecimal: {
      type: Boolean,
      default: false
    },
    showDollarPrefix: {
      type: Boolean,
      default: false
    },
    inputId: {
      type: String,
      default: ''
    },
    showSave: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      value: 0
    }
  },
  computed: {
    disableSave() {
      return this.parseNumber(this.model) === this.parseNumber(this.value) || this.loading || this.value < 0
    },
    disableDecrement() {
      return !this.isEnabled || this.loading || this.value <= this.minimum
    },
    disableIncrement() {
      return !this.isEnabled || this.loading || this.value >= this.maximum
    }
  },
  watch: {
    model(m) {
      this.value = m
    }
  },
  created() {
    this.value = this.model
  },
  methods: {
    onInput({ target }) {
      this.validateInput({ target })
    },
    validateInput({ target }) {
      const v = target.value
      const value = Number.parseFloat(v)
      if (this.isEnabled && _.isNumber(value)) {
        this.update(value)
      }
    },
    update(v) {
      if (this.isEnabled && !this.loading) {
        if (v >= this.maximum) {
          v = this.maximum
        }
        if (!v || v <= this.minimum) {
          v = this.minimum
        }

        const newValue = this.parseNumber(v)
        this.value = newValue.toFixed(2)
        this.$emit('update', this.value)
      }
    },
    save() {
      this.$emit('save', this.value)
    },
    parseNumber(n) {
      return (this.useDecimal) ? Number.parseFloat(roundToDecimal(n))
        : Math.round(Number.parseInt(n, 10))
    },
    increment() {
      this.update(this.parseNumber(this.value) + this.rate)
    },
    decrement() {
      this.update(this.parseNumber(this.value) - this.rate)
    }
  }
}
</script>
