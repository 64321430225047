<template>
  <div
    v-if="!isNativeApp"
    :class="[
      'global-header',
      { 'global-header-mobile': isMobile,
        'global-header-native-container': isMobileApp,
        'global-header--active-search': !isDesktop && isSearchInputActive,
        '!border-none': isNewNavDesignEnabled || isMobileNavABTest
      }
    ]"
  >
    <nav
      :class="['global-header_nav global-header_nav--main spyglass-nav-group_wrapper', {
        'mx-auto max-w-[192rem]' : isNewNavDesignEnabled,
      }]"
      aria-label="Main Menu"
    >
      <PdlShopModeHelpPanel
        v-if="helpModeActive"
        :is-mobile="isMobile"
        :is-tablet="isTablet"
      />
      <PdlOnboardingTooltip
        v-if="!helpModeActive && storeConfirmationActive"
        :is-mobile="isMobile"
        :is-tablet="isTablet"
      />
      <PdlMenuButton
        v-if="!isMobileApp"
        ref="menuButton"
        :menu-active="isMenuOpen"
        @menu-button-clicked="onMenuButtonClick"
      />
      <PdlGlobalHeaderLogo
        v-if="!isMobileApp"
        class="global-header_logo-container flex"
        :opco-name="opcoName"
        @home-nav-item-clicked="onHomeNavItemClicked"
      />
      <span
        v-if="showLoyaltyCard"
        class="global-header_loyalty-card"
        @click="openLoyaltyCard"
        @keyup.enter.space="openLoyaltyCard"
        @open-loyalty-card="openLoyaltyCard"
      >
        <PdlIconBase
          icon-title="Loyalty Card"
          container-class="loyalty-card_icon"
        >
          <PdlIconLoyaltyCard />
        </PdlIconBase>
        <div class="global-header_loyalty-card-text">
          Card
        </div>
      </span>
      <div
        v-if="!isNewNavDesignEnabled || (!isDesktop && !optimizelyShowNewHeaderDesign)"
        :class="[
          'global-header_dropdown-container',
          {'global-header_dropdown-container--shopping-help-mobile': shoppingHelpMobile }
        ]"
      >
        <ul
          v-show="!isMobileApp"
          class="global-header_list visible-above-1200"
          aria-label="Main"
        >
          <PdlMainNavItem
            :label="browseAislesLinks.label"
            :url="browseAislesLinks.url"
            :link-type="browseAislesLinks.linkType"
            :has-right-caret="browseAislesLinks.linkType === 'Dropdown Menu'"
            :main-nav-active="isBrowseAislesMenuOpen"
            @nav-item-clicked="onNavItemClick"
            @focus-in="closeMenu"
          >
            <PdlBrowseAislesMenu
              v-if="isBrowseAislesMenuOpen"
              v-clickaway="{
                exclude: 'nav-browse-aisles',
                handler: 'closeMenu'
              }"
              class="hidden lg:flex"
              :categories="browseAislesCategories"
              :browse-aisle-promo-item="browseAislePromoItem"
              @product-group-clicked="onProductGroupClick"
              @open-url="openUrl"
            />
          </PdlMainNavItem>
          <PdlMainNavItem
            :label="shopNowLinks.label"
            :url="shopNowLinks.url"
            :link-type="shopNowLinks.linkType"
            :has-right-caret="shopNowLinks.linkType === 'Dropdown Menu'"
            :main-nav-active="isMyShopMenuOpen"
            @nav-item-clicked="onNavItemClick"
            @focus-in="closeMenu"
          >
            <PdlMyShopMenu
              v-if="isMyShopMenuOpen"
              v-clickaway="{
                exclude: 'nav-my-shop',
                handler: 'closeMenu'
              }"
              class="visible-above-1200"
              :my-shop-menu-items="myShopMenuItems"
              :shop-promo-item="shopPromoItem"
              @nav-item-clicked="onNavItemClick"
              @open-url="openUrl"
            />
          </PdlMainNavItem>
        </ul>
        <PdlSpyglass
          v-if="spyglassActive"
          :spyglass-content="mainNavItems.spyGlassData"
        />
      </div>
      <div
        :class="isTablet && isSearchInputActive ?
          'global-header_resizable-section--tablet--active':
          'global-header_resizable-section'"
      >
        <div class="global-header_search">
          <Typeahead
            :is-mobile="isMobile"
            :is-tablet="isTablet"
            :is-global-header="true"
            :is-new-nav-design-enabled="isNewNavDesignEnabled || isMobileNavABTest"
            :show-barcode-scanner="showBarcodeScanner"
            :show-voice-search="showVoiceSearch"
            :show-search-dropdown="true"
            :enable-mask="true"
            :marketplace-seller-name="marketplaceSellerName"
            @input-dirty="setInputActive"
            @on-search-focus="onSearchFocus"
            @scan-barcode="scanBarcode"
            @voice-search="voiceSearch"
            @handle-native-redirect="handleNativeRedirect"
          />
        </div>
      </div>
     <HeaderAccountNav
      ref="headerAccountNav"
      :is-new-nav-design-enabled="isNewNavDesignEnabled"
      :is-mobile-app="isMobileApp"
      :opco="opco"
      :spyglass-active="spyglassActive"
      @track-nav-updated="trackNavUpdated"
      @nav-item-clicked="onNavItemClick"
      @account-menu-clicked="onAccountButtonClick"
      @change-city="onChangeCity"
      @logout="onLogout"
     />
     <PdlCartButton
        ref="cartButton"
        :class="['global-header_cart-button-container', {
          'rounded-full !h-16' : isNewNavDesignEnabled || isMobileNavABTest
        }]"
        :cart-amount="cartAmount"
        :is-small="cartButtonIsSmall"
        @focus-in="closeMenu(); handleCloseCategory($event);"
        @track-nav-updated="trackNavUpdated"
      />
    </nav>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import {
  mapActions, mapGetters, mapState, mapMutations
} from 'vuex'
import clickaway from 'utils/custom-directives/clickaway'
import ApiService from '@/api/'
import PdlCartButton from 'components/navigation/PdlCartButton'
import PdlMainNavItem from 'components/navigation/nav-items/PdlMainNavItem'
import PdlMenuButton from 'components/navigation/PdlMenuButton'
import PdlIconLoyaltyCard from 'components/icons/icon/PdlIconLoyaltyCard'
import PdlSpyglass from 'components/spyglass/PdlSpyglass'
import Typeahead from 'components/search/Typeahead'
import PdlGlobalHeaderLogo from './PdlGlobalHeaderLogo'
import HeaderAccountNav from './HeaderAccountNav'

export default {
  components: {
    PdlMyShopMenu: defineAsyncComponent(() => import(
      /* webpackChunkName:"nav" */'components/navigation/PdlMyShopMenu'
    )),
    HeaderAccountNav,
    PdlCartButton,
    PdlMainNavItem,
    PdlMenuButton,
    PdlBrowseAislesMenu: defineAsyncComponent(() => import(
      /* webpackChunkName:"nav" */'components/navigation/PdlBrowseAislesMenu'
    )),
    PdlOnboardingTooltip: defineAsyncComponent(() => import(
      /* webpackChunkName:"nav" */'components/navigation/PdlOnboardingTooltip'
    )),
    PdlShopModeHelpPanel: defineAsyncComponent(() => import(
      /* webpackChunkName:"nav" */'components/navigation/shop-mode/PdlShopModeHelpPanel'
    )),
    PdlIconLoyaltyCard,
    PdlGlobalHeaderLogo,
    PdlSpyglass,
    Typeahead
  },
  directives: { clickaway },
  props: {
    cartAmount: {
      type: Number,
      default: 0
    },
    browseAislesCategories: {
      type: Array,
      required: true
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    isTablet: {
      type: Boolean,
      default: false
    },
    showBarcodeScanner: {
      type: Boolean
    },
    showVoiceSearch: {
      type: Boolean
    },
    spyglassActive: {
      type: Boolean
    },
    opcoTheme: {
      type: String,
      required: true
    },
    opcoId: {
      type: String,
      required: true
    },
    pageView: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      cartButtonIsSmall: true,
      searchIsSmall: true,
      browseAislesMenuOpen: false,
      myShopMenuOpen: false,
      accountMenuOpen: false
    }
  },
  computed: {
    ...mapState({
      helpModeActive: state => state.ShoppingMode.helpModeActive,
      storeConfirmationActive: state => state.ShoppingMode.storeConfirmationActive,
      optimizelyShowNewHeaderDesign: state => state.Optimizely.showNewNavigationMobileDesign
    }),
    ...mapGetters({
      isSearchInputActive: 'GlobalHeader/isSearchInputActive',
      isBrowseAislesMenuOpen: 'GlobalHeader/isBrowseAislesMenuOpen',
      isMyShopMenuOpen: 'GlobalHeader/isMyShopMenuOpen',
      isAccountMenuOpen: 'GlobalHeader/isAccountMenuOpen',
      isMobileMainMenuOpen: 'GlobalHeader/isMobileMainMenuOpen',
      isMobileBrowseAislesMenuOpen: 'GlobalHeader/isMobileBrowseAislesMenuOpen',
      opco: 'SiteConfig/opco',
      isLoggedIn: 'LoginStatus/isLoggedIn',
      userStatus: 'LoginStatus/userStatus',
      deliveryServiceLocation: 'UserProfile/deliveryServiceLocation',
      userId: 'UserProfile/userId',
      defaultDeliveryAddress: 'UserProfile/defaultDeliveryAddress',
      cardNumber: 'LoyaltyAccount/cardNumber',
      isMobileApp: 'NativeContainer/isMobileApp',
      marketplaceSellerName: 'GlobalHeader/marketplaceSellerName',
      isDesktop: 'ScreenSize/isDesktop',
      isLoyaltyRegistered: 'LoyaltyAccount/isLoyaltyRegistered',
      siteConfigByName: 'SiteConfig/varByName',
      mainNavItems: 'GlobalHeader/mainNavItems',
      myShopMenuItems: 'GlobalHeader/myShopMenuItems',
      browseAislePromoItem: 'GlobalHeader/browseAislePromoItem',
      shopPromoItem: 'GlobalHeader/shopPromoItem',
      isNativeApp: 'NativeContainer/isNativeApp',
      isSubNavMenuOpen: 'GlobalHeader/isSubNavMenuOpen',
      isCategoryMenuOpen: 'GlobalHeader/isCategoryMenuOpen'
    }),
    isPeapodTheme() {
      return this.opcoTheme === 'PPOD'
    },
    isNewNavDesignEnabled() {
      return this.siteConfigByName('feature_new_navigation_design')
    },
    showLoyaltyCard() {
      return this.opco !== 'PPOD' && this.isMobileApp
    },
    opcoName() {
      return this.isPeapodTheme ? this.opcoId : this.opcoTheme
    },
    hasDelivery() {
      return this.siteConfigByName('config_available_service_types')
        ? this.siteConfigByName('config_available_service_types').includes('D') : false
    },
    hasPickUp() {
      return this.siteConfigByName('config_available_service_types')
        ? this.siteConfigByName('config_available_service_types').includes('P') : false
    },
    hasInStore() {
      return this.siteConfigByName('config_available_service_types')
        ? this.siteConfigByName('config_available_service_types').includes('B') : false
    },
    isMenuOpen() {
      return this.isMobileMainMenuOpen || this.isMobileBrowseAislesMenuOpen
    },
    isOpeningOtherMenu() { // check if this other menu in global header are going to open in desktop view
      return this.isBrowseAislesMenuOpen || this.isMyShopMenuOpen || this.isAccountMenuOpen
    },
    shoppingHelpMobile() {
      return this.isMobile && this.helpModeActive
    },
    browseAislesLinks() {
      if (!this.mainNavItems.links.length) {
        return {
          label: 'Browse Aisles'
        }
      }
      return this.mainNavItems.links[0]
    },
    shopNowLinks() {
      if (!this.mainNavItems.links.length) {
        return {
          label: 'Shop Now'
        }
      }
      return this.mainNavItems.links[1]
    },
    isNewProductApiServices() {
      return this.siteConfigByName('feature_category_product_new_services')
    },
    currentServiceLocationId() {
      return this.deliveryServiceLocation.serviceLocationId
    },
    isTypeAheadEnabled() {
      return this.siteConfigByName('feature_type_ahead')
    },
    isMobileNavABTest() {
      return (!this.isDesktop && this.optimizelyShowNewHeaderDesign)
    }
  },
  watch: {
    isMenuOpen(value) {
      if (!value) {
        this.$refs.menuButton.$el.focus()
      }
    },
    currentServiceLocationId(serviceLocationId) {
      if (serviceLocationId) {
        this.requestStoreEcommerceState(serviceLocationId)
      }
    },
  },
  methods: {
    ...mapActions({
      setSearchInputState: 'GlobalHeader/setSearchInputState',
      setOpenMainMenu: 'GlobalHeader/setOpenMainMenu',
      setOpenMobileMainMenu: 'GlobalHeader/setOpenMobileMainMenu',
      toggleBrowseAislesMenu: 'GlobalHeader/toggleBrowseAislesMenu',
      toggleMyShopMenu: 'GlobalHeader/toggleMyShopMenu',
      setCloseMyShopMenu: 'GlobalHeader/setCloseMyShopMenu',
      setCloseBrowseAislesMenu: 'GlobalHeader/setCloseBrowseAislesMenu',
      setCloseSearchInput: 'GlobalHeader/setCloseSearchInput',
      setCloseAccountMenu: 'GlobalHeader/setCloseAccountMenu',
      setCloseMobileMainMenu: 'GlobalHeader/setCloseMobileMainMenu',
      toggleSearchMaskOverlay: 'GlobalHeader/toggleSearchMaskOverlay',
      setMask: 'GlobalHeader/setMask',
      setHelpMode: 'ShoppingMode/setHelpMode',
      removeMaskOverlay: 'GlobalHeader/removeMaskOverlay',
      logNativeContainerError: 'NativeContainer/logNativeContainerError',
      requestStoreEcommerceState: 'FoodLionTransition/requestStoreEcommerceState'
    }),
    ...mapMutations({
      setCloseSubNavMenu: 'GlobalHeader/setCloseSubNavMenu',
      toggleCategoryMenu: 'GlobalHeader/toggleCategoryMenu'
    }),
    clearShoppingModeHelp() {
      if (this.helpModeActive) {
        this.setHelpMode(false)
        this.removeMaskOverlay()
      }
    },
    onMenuButtonClick() {
      this.clearShoppingModeHelp()
      this.setOpenMobileMainMenu()
    },
    onAccountButtonClick() {
      this.clearShoppingModeHelp()
      if (this.isSubNavMenuOpen) {
        this.setCloseSubNavMenu()
      }
      if (this.isBrowseAislesMenuOpen) {
        this.setCloseBrowseAislesMenu()
      } else if (this.isMyShopMenuOpen) {
        this.setCloseMyShopMenu()
      } else if (this.isSearchInputActive) {
        this.setCloseSearchInput()
      }
    },
    setInputActive(val, setFocus) {
      if (!this.isOpeningOtherMenu) {
        this.setSearchInputState(val)
        const payload = {
          maskOverlay: val && !this.isMobile && this.isTypeAheadEnabled && !this.isNewNavDesignEnabled,
          maskOverlayHeader: !this.isMobile && this.isTypeAheadEnabled && !this.isNewNavDesignEnabled,
        }
        this.setMask(payload)
        this.closeMenu()
      }
      if (setFocus) { // ADA
        setTimeout(() => {
          if (this.isMobile || this.isTablet) {
            this.$refs.cartButton.$el.focus()
          } else {
            this.$refs.accountButton.$el.focus()
          }
        }, 100)
      }
    },
    onSearchFocus() {
      this.closeMenu()
      this.setSearchInputState(true)
      const payload = {
        maskOverlay: !this.isMobile && this.isTypeAheadEnabled && !this.isNewNavDesignEnabled,
        maskOverlayHeader: !this.isMobile && this.isTypeAheadEnabled && !this.isNewNavDesignEnabled
      }
      this.setMask(payload)
    },
    onHomeNavItemClicked() {
      this.clearShoppingModeHelp()
      if (!this.isCategoryMenuOpen) {
        this.toggleCategoryMenu()
      }
      this.$emit('home-nav-item-clicked')
    },
    openUrl(event) {
      this.clearShoppingModeHelp()
      if (this.isBrowseAislesMenuOpen) {
        this.setCloseBrowseAislesMenu()
      } else if (this.isMyShopMenuOpen) {
        this.setCloseMyShopMenu()
      }
      this.$emit('open-url', event)
    },
    handleCloseCategory(payload) {
      if (this.isCategoryMenuOpen && payload !== 'keyboard-navigation') {
        this.toggleCategoryMenu()
      }
    },
    onNavItemClick(label, url, linkType, elementId) {
      const isBrowseAislesNavItem = ['Browse Aisles', 'Departments'].includes(label)
      const isMyShopNavItem = ['My Shop', 'Savings & More'].includes(label) && linkType === 'Dropdown Menu'
      if (isBrowseAislesNavItem) {
        this.clearShoppingModeHelp()
        if (this.isAccountMenuOpen) {
          this.setCloseAccountMenu()
        } else if (this.isMyShopMenuOpen) {
          this.setCloseMyShopMenu()
        } else if (this.isSearchInputActive) {
          this.setCloseSearchInput()
        }
        this.toggleBrowseAislesMenu()
        this.sendGTMTrackerFromHeader(this.browseAislePromoItem)
      } else if (isMyShopNavItem) {
        this.clearShoppingModeHelp()
        if (this.isAccountMenuOpen) {
          this.setCloseAccountMenu()
        } else if (this.isBrowseAislesMenuOpen) {
          this.setCloseBrowseAislesMenu()
        } else if (this.isSearchInputActive) {
          this.setCloseSearchInput()
        }
        this.toggleMyShopMenu()
        this.sendGTMTrackerFromHeader(this.shopPromoItem)
      } else {
        this.closeMenu()
        this.$emit('nav-item-clicked', label, url, linkType, elementId)
      }
    },
    shouldSendGTMtracker(promoItem) {
      const menuOpen = this.isBrowseAislesMenuOpen || this.isMyShopMenuOpen
      return promoItem.hasPromo && menuOpen
    },
    sendGTMTrackerFromHeader(promoItem) {
      if (this.shouldSendGTMtracker(promoItem)) {
        const scheduledData = promoItem.scheduledContentData
        this.$emit('track-promotion-impression', scheduledData)
      }
    },
    onChangeCity() {
      this.$emit('change-city')
    },
    retrieveProduct(keywords) {
      let apiPath = '/api/v4.0/user/products'
      if (this.isNewProductApiServices) {
        apiPath = `/api/v5.0/products/${this.userId}/${this.currentServiceLocationId}`
      }
      return ApiService.get(apiPath, {
        params: {
          keywords
        }
      })
    },
    async scanBarcode() {
      const scannedItem = await this.NativeContainer.capabilities.ScannerService.getScannedItem()
      let url
      let response = await this.retrieveProduct(scannedItem)
      const { suggestedKeywords } = response.data.response
      if (response.data.response.products.length === 0 && suggestedKeywords) {
        response = await this.retrieveProduct(suggestedKeywords)
      }
      if (response.data.response.products.length === 1
                  && response.data.response.products[0].prodId) {
        const productId = response.data.response.products[0].prodId
        url = (`/product/${productId}`)
      } else {
        url = (`/product-search/${scannedItem}`)
      }
      this.$emit('scan-barcode-url', url)
    },
    voiceSearch() {
      this.$emit('voice-search')
    },
    onLogout() {
      this.$emit('logout')
    },
    onProductGroupClick(label, url) {
      this.$emit('product-group-clicked', label, url)
    },
    closeMenu(setFocus = false) {
      if (this.isBrowseAislesMenuOpen) {
        this.setCloseBrowseAislesMenu()
      }
      if (this.isAccountMenuOpen) {
        this.setCloseAccountMenu()
        if (setFocus) {
          this.$refs.headerAccountNav.focusOnAccountMenu()
        }
      }
      if (this.isMyShopMenuOpen) {
        this.setCloseMyShopMenu()
      }
    },
    openLoyaltyCard() {
      if (this.isLoyaltyRegistered) {
        this.$emit('open-loyalty-card')
      } else if (this.isLoggedIn) {
        this.$emit('nav-item-clicked', 'Create Account', '/modal/registration')
      } else {
        this.$emit('nav-item-clicked', 'Sign In', 'modal/guest-transfer-modal', 'Modal')
      }
    },
    handleNativeRedirect(path) {
      this.$emit('handle-native-redirect', path)
    },
    trackNavUpdated(data) {
      this.$emit('track-nav-updated', data)
    }
  }
}
</script>
