import * as modules from './modules'
import * as ClientPlugins from './plugins'

// Force all modules to be namespaced (by Filename)
Object.keys(modules).forEach((key) => {
  modules[key].namespaced = true
  return modules[key]
})
const clientPlugins = Object.keys(ClientPlugins).reduce((group, key) => {
  group.push(ClientPlugins[key])
  return group
}, [])
const plugins = [...clientPlugins]

export {
  modules,
  plugins
}
