<template>
  <form
    ref="form"
    action="#"
    :class="['typeahead-container', { 'typeahead-container--small': isMobile },
             { 'empty-search-results_search': isEmptySearch },
             {'empty-search-results_search--active': isEmptySearch && isFocused}]"
    tabindex="-1"
    @focusout="onFocusOut"
  >
    <div
      id="typeahead-container_input"
      :class="[
        'typeahead-container_input spyglass-nav-group_wrapper',
        { 'px-0': isMobile && isAddItemModal && isFocused }
      ]"
    >
      <div
        :class="[
          {
            loading: loading === -1,
            'typeahead-container_input-field': !isFocused,
            'typeahead-container_input-field--active': isFocused,
            'typeahead-container_input-field--desktop': isDesktop,
            'typeahead-container_input-field--fade-placeholder': fadePlaceholder,
            '!rounded-full' : isNewNavDesignEnabled
          }
        ]"
      >
        <div :class="{'global-search-bar-wrapper': (!isMobile && isGlobalHeader)
          || (!isMobile && isTablet && isAddItemModal)}">
          <div
            :class="['input-wrapper',
             {
                'input-wrapper--active': isFocused && !isNewNavDesignEnabled,
                '!bg-gray-50 !rounded-full': isNewNavDesignEnabled,
                '!h-16': isNewNavDesignEnabled && !isMobile,
                '!border-2 !border-gray-300 !rounded-full': isNewNavDesignEnabled && isFocused && !isMobile,
              }
            ]"
          >
            <PdlIconBase
              v-if="!isDesktopGlobalHeaderOrModal"
              icon-title="Search"
              :container-class="['vector-icon-size--small typeahead-container_input-search-icon search-icon',
              ]"
              :vb-width="28"
              :vb-height="28"
            >
              <PdlIconSearchIcon :class="isNewNavDesignEnabled && isMobile ? 'stroke-gray-900' : 'stroke-gray-600'"/>
            </PdlIconBase>
            <!-- eslint-disable vue/use-v-on-exact -->
            <input
              :id="inputId"
              ref="searchInput"
              :class="[ 'search-input', {
                'bg-white rounded-2xl': !isNewNavDesignEnabled,
                '!bg-gray-50 p-0 !pl-2 !ml-6 !h-16 w-full !rounded-full'
                : !isMobile && isNewNavDesignEnabled,
                '!bg-gray-50 p-0 h-[4.4rem] w-full !rounded-full'
                : isMobile && isNewNavDesignEnabled,
              }]"
              :value="query"
              type="search"
              :placeholder="placeholderText"
              autocomplete="off"
              :aria-label="searchInputLabel"
              :spellcheck="false"
              autocorrect="off"
              autocapitalize="off"
              :aria-autocomplete="showSearchSuggestions ? 'list' : false"
              :aria-expanded="isExpanded ? 'true' : 'false'"
              :aria-describedby="isTypeAheadEnabled ? 'searchDescription' : false"
              :aria-owns="showSearchSuggestions ? 'searchSuggestions' : undefined"
              :aria-activedescendant="activeDescendant"
              @focus="afterFocus"
              @keydown="handleKeyEvent"
              @keydown.enter.prevent="onEnter"
              @keydown.esc="reset"
              @input="handleInput"
              @keydown.shift.tab="reset"
            >
            <span
              v-if="showVoiceSearch && !isDirty && !query"
              class="typeahead-container_voice-search"
              aria-label="Voice Search"
              @click="voiceSearch"
              @keyup.enter.space="voiceSearch"
            >
              <PdlIconBase
                icon-title="voice"
                container-class="vector-icon-size--small"
              >
                <PdlIconVoiceSearch />
              </PdlIconBase>
            </span>
            <span
              v-if="showBarcodeScanner && !isDirty && !query"
              class="typeahead-container_scan-bar"
              aria-label="Barcode Scan"
              @click="scanBarcode"
              @keyup.enter.space="scanBarcode"
            >
              <PdlIconBase
                icon-title="Scanbar"
                container-class="vector-icon-size--small"
                :vb-width="28"
                :vb-height="28"
              >
                <PdlIconScanBar />
              </PdlIconBase>
            </span>
            <button
              v-if="query && !isMobileApp"
              :class="[
                'search-field_clear-icon',
                {
                  'search-field_clear-icon--mobile': isMobile,
                  'search-field_clear-icon--mask': (isDirty || isFocused) && !isDesktopGlobalHeaderOrModal,
                  'search-field_clear-icon--desktop-mask': (isDirty || isFocused) && isDesktopGlobalHeaderOrModal
                },
                'clear-search-icon'
              ]"
              @click.stop.prevent="clearQuery"
            >
              <span class="element-invisible">
                Clear search
              </span>
              <PdlLoadingSpinner />
              <PdlIconBase
                v-if="loading !== -1"
                icon-title="Close"
                icon-class="search-field_clear-icon--color"
                container-class="vector-icon-size--xsmall"
              >
                <PdlIconClose />
              </PdlIconBase>
            </button>
            <!-- search button for desktop -->
            <button
              v-if="isDesktopGlobalHeaderOrModal && canDismissInputByMask && (isDirty || isFocused)"
              :class="['button search-button',
                       { 'button--prime': isDirty || isFocused,
                         'button--disabled': !query },
                       'search-btn']"
              :disabled="!query"
              aria-label="Search"
              @click.prevent="onEnter"
            >
              <PdlIconBase
                icon-title="Search"
                :container-class="['vector-icon-size--small typeahead-container_input-search-icon',
                  isNewNavDesignEnabled ? '!top-[1.2rem]' : 'top-5'
                ]"
                :vb-width="28"
                :vb-height="28"
              >
                <PdlIconSearchIcon class='stroke-gray-600'/>
              </PdlIconBase>
            </button>
            <!-- search button for tablet -->
            <button
              v-if="showSearchButton"
              :class="['button search-button',
                       { 'button--prime': isDirty || isFocused,
                         'button--disabled': !query },
                       'search-btn']"
              :disabled="!query"
              @click.prevent="onEnter"
            >
              Search
            </button>
            <PdlIconBase
              v-if="isDesktopGlobalHeaderOrModal && !isFocused && !query"
              icon-title="Search"
              :container-class="['vector-icon-size--small typeahead-container_input-search-icon right-side-icon',
                isNewNavDesignEnabled ? '!top-4' : 'top-5'
              ]"
              :vb-width="28"
              :vb-height="28"
            >
              <PdlIconSearchIcon class="stroke-gray-600"/>
            </PdlIconBase>
            <div
              v-if="isTypeAheadEnabled"
              id="searchDescription"
              class="sr-only"
            >
              When results are available, use the up and down keys to review and the enter key to select.
            </div>
          </div>
          <button
            v-if="!isMobile && canDismissInputByMask && (isDirty || isFocused) && !isDesktopGlobalHeaderOrModal"
            class="button button--fourth typeahead-container_input-close-button"
            :class="[{'mt-10': isEmptySearch}]"
            @click.stop.prevent="reset(false, true)"
          >
            Cancel
          </button>
          <div
            v-if="showFixedDropdownMenuTip"
            class="menutip menutip--left border-solid border-l border-t border-r-0
              border-b-0 border-[#d9d9d6] absolute !left-[26px] top-[56px]"
          />
        </div>
        <transition name="slide">
          <aside
            :class="[
              'typeahead-container_search-container',
              typeaheadContainerClassString,
              {
                '!z-30 !border !border-[#d8d9d6]': isNewNavDesignEnabled,
                'w-[78rem] max-h-[none]': useCitrusBannerXStyles,
                'fixed right-8 left-auto top-[7.6rem]': showFixedDropdown,
                'mt-10': cutoffAlertFlag && isMobile && showSearchTrends,
                'border-none': !isExpanded
              }
            ]"
            role="none"
            @keydown.esc="clearQuery"
          >
            <div
              v-if="showSearchSuggestions"
            >
              <div
                :class="[
                  {
                    'grid grid-cols-3 p-0':  (isTablet || isDesktop) && productSuggestions.length,
                    'left-0 max-h-[calc(100vh-400px)] overflow-y-hidden': useCitrusBannerXStyles
                  }
                ]"
              >
                <div
                  class="sr-only"
                  aria-live="assertive"
                >
                  {{ searchSuggestions.length }} results currently available.
                  Use the up and down keys to review the results and use the
                  enter key to select.
                </div>
                <div
                  v-if="isDesktop && !showFixedDropdownMenuTip"
                  :class="[
                    'menutip menutip--left border-solid border-l border-t border-r-0 border-b-0',
                    {
                      'border-[#d9d9d6]': !isAddItemModal && !isNewNavDesignEnabled,
                      'border-gray-100': isAddItemModal,
                      'border-[#d8d9d6]' : isNewNavDesignEnabled
                    }
                  ]"
                />
                <div v-if="productSuggestions.length && isMobile">
                  <PdlSearchTermList
                    id="topSuggestion"
                    ref="list"
                    list-name=""
                    :is-header-search-term="true"
                    :header-search-term="keyword"
                    :search-terms="[keyword]"
                    :current-position="currentPosition"
                    :current-list="listName"
                    :query="cleanQuery"
                    :is-highlightable="true"
                    :is-scroll="isScroll"
                    @value-selected="valueSelected"
                    @on-active="onActive"
                    @mouse-move-event="onMouseMove"
                  />
                  <PdlMiniRecommendations
                    :location="'search-typeahead'"
                    :page-view="'typeahead'"
                    :active-search-term="activeSearchTerm"
                    :query="query"
                    :is-mobile="isMobile"
                    :typeahead-products="productSuggestions"
                    :show-view-all="showViewAll"
                    :header-search-term="keyword"
                    @update-cart="updateCart"
                    @view-all-mini-carousel-selected="viewAllMiniCarouselSelected"
                  />
                </div>
                <PdlSearchTermList
                  id="searchSuggestions"
                  ref="list"
                  :class="[
                    'search-term-list',
                    {
                      'max-h-150': isAddItemModal && !isMobile,
                      'p-4 pt-8': isTablet || isDesktop,
                      '!h-full': (isTablet || isDesktop) && !useCitrusBannerXStyles,
                      'overflow-y-hidden': useCitrusBannerXStyles
                    }
                  ]"
                  list-name="Search Suggestions"
                  :search-terms="isMobile ? searchSuggestions.slice(1) : searchSuggestions"
                  :current-position="currentPosition"
                  :current-list="listName"
                  :query="cleanQuery"
                  :is-highlightable="true"
                  :is-scroll="isScroll"
                  :use-citrus-banner-x-styles="useCitrusBannerXStyles"
                  @value-selected="valueSelected"
                  @on-active="onActive"
                  @mouse-move-event="onMouseMove"
                />
                <!-- Cleanup class if converted to a feature -->
                <div
                  v-if="productSuggestions.length && !isMobile"
                  :class="[
                    'tile-container bg-grey border-l border-gray-100 w-full col-span-2 p-4 pt-8 bg-gray-50 -mr-4'
                  ]"
                >
                  <p class="text-gray-500 text-xs pl-6 pb-2 font-bold">
                    Product Suggestions
                  </p>
                  <TypeaheadProductTileList
                    ref="tileList"
                    :class="{
                      'h-4/5': isTablet,
                      'max-h-[calc(100vh-460px)]': useCitrusBannerXStyles
                    }"
                    :products="productSuggestions"
                    :active-search-term="activeSearchTerm"
                    :tile-position="tilePosition"
                    :show-view-all="showViewAll"
                    :is-view-all-selected="isViewAllSelected"
                    @update-cart="updateCart"
                    @value-selected="valueSelected"
                    @on-active-tile="onActiveTile"
                  />
                </div>
              </div>
            </div>
            <SearchTrends
              v-if="showSearchTrends"
              :current-position="currentPosition"
              :current-list="listName"
              :is-add-item-modal="isAddItemModal"
              :is-mobile="isMobile"
              :is-new-nav-design-enabled="isNewNavDesignEnabled"
              :use-citrus-banner-x-styles="useCitrusBannerXStyles"
              :show-fixed-dropdown-menu-tip="showFixedDropdownMenuTip"
              @value-selected="valueSelected"
              @on-active="onActive"
              @on-remove="onRemove"
              @mousemove="onMouseMove"
            />
            <CitrusBannerXAdContainer
              v-if="showCitrusBannerX"
              :show-skinny-banner-for-desktop="true"
              :is-search-dropdown="true"
              :slot-id="'Skinny'"
              site-location-name="Search Typeahead"
              @redirect="redirect"
              @banner-loaded="setCitrusBannerXLoaded"
            />
          </aside>
        </transition>
      </div>
      <button
        v-if="isMobile && canDismissInputByMask && (isDirty || isFocused) && !isDesktopGlobalHeaderOrModal"
        class="button border-none text-[#E0004D] typeahead-container_input-close-button"
        @click.stop.prevent="reset(false, true)"
        @keydown.tab.prevent="reset(false, true)"
      >
        Cancel
      </button>
      <PdlSpyglass
        v-if="spyglassActive"
        :spyglass-content="placeholderSpyglassData"
        :is-group="true"
      />
    </div>
  </form>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { mapMutations, mapGetters, mapState } from 'vuex'
import PdlIconClose from 'components/icons/icon/PdlIconClose'
import PdlIconSearchIcon from 'components/icons/icon/PdlIconSearchIcon'
import PdlIconScanBar from 'components/icons/icon/PdlIconScanBar'
import PdlIconVoiceSearch from 'components/icons/icon/PdlIconVoiceSearch'
import PdlSpyglass from 'components/spyglass/PdlSpyglass'
import SearchTrends from 'components/search/SearchTrends'
import { handleKeyStrokeScroll } from 'utils/services/keyStrokeScroll'
import productImpressionTracking from 'utils/mixins/tracking/productImpressionTracking'
import { EXTERNAL_URLS } from 'utils/constants'
import optimizelyFullstack from 'components/user-research/services/optimizely-fullstack-service'
import encodeSquareBrackets from 'utils/filters/encodeSquareBrackets'
import clickaway from 'utils/custom-directives/clickaway'
import { trackProductImpression } from 'utils/tracking/product/trackProductImpression'
import { trackCartChange, qtyIncreasedByOne } from 'utils/tracking/cart/trackCartChange'
import { mapSpyglassData } from 'utils/services/spyglass-content'
import ProductTileService from 'utils/services/product-tile/product-tile-service'
import ApiService from '@/api/'

export default {
  components: {
    PdlIconClose,
    PdlIconScanBar,
    PdlIconVoiceSearch,
    PdlIconSearchIcon,
    SearchTrends,
    CitrusBannerXAdContainer: defineAsyncComponent(
      () => import(/* webpackChunkName: 'bannerads' */'components/cms-content/banner-x/CitrusBannerXAdContainer')
    ),
    PdlMiniRecommendations: defineAsyncComponent(() => import(
      /* webpackChunkName: 'typeahead' */'components/product-recommendation/PdlMiniRecommendations'
    )),
    TypeaheadProductTileList: defineAsyncComponent(() => import(
      /* webpackChunkName: 'typeahead' */'components/search/TypeaheadProductTileList'
    )),
    PdlSpyglass
  },
  directives: {
    clickaway,
  },
  mixins: [productImpressionTracking],
  props: {
    isMobile: {
      type: Boolean,
      default: false
    },
    isTablet: {
      type: Boolean,
      default: false
    },
    isGlobalHeader: {
      type: Boolean,
      default: false
    },
    isAddItemModal: {
      type: Boolean,
      default: false
    },
    showBarcodeScanner: {
      type: Boolean
    },
    showVoiceSearch: {
      type: Boolean
    },
    showSearchDropdown: {
      type: Boolean
    },
    enableMask: {
      type: Boolean,
      default: false
    },
    emptySearchPlaceholder: {
      type: String,
      default: ''
    },
    suggestedKeyword: {
      type: String,
      default: ''
    },
    marketplaceSellerName: {
      type: String,
      default: ''
    },
    isEmptySearch: {
      type: Boolean,
      default: false
    },
    isNewNavDesignEnabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      searchType: {
        recent: 'Your Searches',
        suggested: 'Search Suggestions',
        popular: 'Popular Searches'
      },
      searchSuggestions: [],
      productSuggestions: [],
      cache: {},
      query: '',
      listName: '',
      loading: false,
      isDirty: false,
      placeholderList: [],
      spyGlassData: { parent: [] },
      isFocused: false,
      currentPosition: -1,
      isScroll: false,
      tilePosition: -1,
      showPlaceholderCarousel: false,
      indexTimeout: null,
      fadeTimeout: null,
      placeholderIndex: -1,
      placeholderText: '',
      fadePlaceholder: false,
      placeholderIntervalID: null,
      activeDescendant: null,
      activeSearchTerm: '',
      showViewAll: false,
      citrusBannerXLoaded: false
    }
  },
  computed: {
    ...mapState({
      showBannerXAtSearchDropdown: state => state.Optimizely.showBannerXAtSearchDropdown,
      cutoffAlertFlag: state => state.CutOff.cutoffAlertFlag,
      semanticSearchV7Enabled: state => state.Optimizely.semanticSearchV7Enabled,
    }),
    ...mapGetters({
      isSearchInputActive: 'GlobalHeader/isSearchInputActive',
      spyglassActive: 'ScheduledContent/spyglassActive',
      currentSearchTerm: 'GlobalHeader/getSelectedSearchValue',
      popularSearchTerms: 'SearchTerms/topSearchTerms',
      recentSearchTerms: 'SearchTerms/usedSearchTerms',
      isMobileApp: 'NativeContainer/isMobileApp',
      isVisible: 'SearchTerms/isVisible',
      isDesktop: 'ScreenSize/isDesktop',
      isDesktopWithMax1375: 'ScreenSize/isDesktopWithMax1375',
      isDesktopWithMax1432: 'ScreenSize/isDesktopWithMax1432',
      resourceByName: 'SiteConfig/resourceByName',
      varByName: 'SiteConfig/varByName',
      isNativeApp: 'NativeContainer/isMobileApp',
      deliveryServiceLocation: 'UserProfile/deliveryServiceLocation',
      userId: 'UserProfile/userId',
      getCartItems: 'Cart/getCartItems',
      opco: 'SiteConfig/opco'
    }),
    currentUrl() {
      return this.$route.path
    },
    isHomePage() {
      return this.currentUrl === '/' || this.currentUrl === '/home'
    },
    typeaheadDefaultPlaceholder() {
      return this.resourceByName('TYPEAHEAD_DEFAULT_PLACEHOLDER')
    },
    canDismissInputByMask() {
      return this.enableMask ? this.isSearchInputActive : true
    },
    hasItems() {
      return this.searchSuggestions.length > 0
    },
    isEmpty() {
      return !this.query
    },
    isDesktopGlobalHeaderOrModal() {
      return this.isDesktop && (this.isGlobalHeader || this.isAddItemModal)
    },
    isAddItemSearching() {
      if (this.isAddItemModal) {
        return this.isFocused
      }
      return true
    },
    placeholderSpyglassData() {
      if (!_.isEmpty(this.spyGlassData)) {
        return {
          parent: this.spyGlassData.parent
        }
      }
      return {}
    },
    hasFocusedInput() {
      return this.isFocused && this.canDismissInputByMask
    },
    hasFocusedEmptyOrScrollableInput() {
      return (this.isEmpty || this.isScroll) && this.hasFocusedInput
    },
    showSearchButton() {
      const canShowButton = this.isTablet || (!this.isGlobalHeader && !this.isAddItemModal)
      return canShowButton && this.canDismissInputByMask && (this.isDirty || this.isFocused)
    },
    searchInputLabel() {
      return this.query ? `Search by ${this.query}` : 'Search'
    },
    isProductSearchResultsPage() {
      return this.$route?.path?.split('/')[1] === 'product-search'
    },
    pharmacyAppLink() {
      return this.varByName('brand_pharmacy_app_link')
    },
    scanItAppLink() {
      return this.varByName('brand_scanit_app_link')
    },
    typeaheadContainerClassString() {
      let classList = ''
      if (this.isTablet || this.isDesktop) {
        classList += `${
          this.productSuggestions.length
            ? 'typeahead-container_search-container--tilePreview'
            : 'typeahead-container_search-container--desktop'
        } top-24`
      }
      if (this.isAddItemModal) {
        if (this.isDesktop) {
          classList += ' border-gray-100 border-solid border'
        }
        if (this.isMobile) {
          classList += 'w-full border-none fixed top-36 left-0 h-full'
          if ((!this.isDirty || !this.isFocused)) {
            classList += ' hidden'
          }
        }
      }
      return classList
    },
    currentServiceLocationId() {
      return this.deliveryServiceLocation.serviceLocationId
    },
    isNewTypeAheadApiServices() {
      return this.varByName('feature_category_product_new_services')
    },
    isTypeAheadEnabled() {
      return this.varByName('feature_type_ahead')
    },
    showDropdown() {
      return this.showSearchDropdown && this.isTypeAheadEnabled
    },
    showSearchSuggestions() {
      return !this.isEmpty && this.hasItems && this.showSearchDropdown
      && this.canDismissInputByMask && this.isAddItemSearching
    },
    isExpanded() {
      return this.hasFocusedInput && this.showDropdown
    },
    cleanQuery() {
      // need to remove the open square bracket
      // it's causing issue in regex checks in SearchTermList
      return this.query.replace(new RegExp(/[[]/g), '')
    },
    inputId() {
      switch (true) {
        case (this.isEmptySearch):
          return 'typeahead-empty-search-input'
        case (this.isAddItemModal):
          return 'add-item-search-input'
        default:
          return 'typeahead-search-input'
      }
    },
    activeList() {
      switch (this.listName) {
        case 'Your Searches':
          return this.recentSearchTerms
        case 'Popular Searches':
          return this.popularSearchTerms
        default:
          return this.searchSuggestions
      }
    },
    listLength() {
      return this.isTileScroll ? this.productSuggestions.length : this.searchSuggestions.length
    },
    isTileScroll() {
      return this.tilePosition > -1
    },
    maxTiles() {
      return this.isMobile ? 5 : 4
    },
    isViewAllSelected() {
      return this.tilePosition === 4
    },
    keyword() {
      return this.searchSuggestions[0]
    },
    isCartPage() {
      return this.currentUrl.includes('cart')
    },
    showCitrusBannerX() {
      const bannerEnabled = this.varByName('feature_citrus_bannerx_ad_at_search_dropdown')
        || this.showBannerXAtSearchDropdown
      return bannerEnabled && this.isDesktop && this.isExpanded
    },
    useCitrusBannerXStyles() {
      return this.citrusBannerXLoaded && this.isDesktop
    },
    isFDLN() {
      return this.opco === 'FDLN'
    },
    atFixedDropdownBreakpoint() {
      return this.isFDLN ? this.isDesktopWithMax1432 : this.isDesktopWithMax1375
    },
    showFixedDropdown() {
      return this.useCitrusBannerXStyles && this.atFixedDropdownBreakpoint && !this.isNewNavDesignEnabled
    },
    showFixedDropdownMenuTip() {
      return this.isExpanded && this.citrusBannerXLoaded && this.atFixedDropdownBreakpoint
        && !this.isNewNavDesignEnabled
    },
    showSearchTrends() {
      return !this.showSearchSuggestions && this.isExpanded
    },
    enableSemanticSearch() {
      return this.varByName('feature_semantic_search')
    },
  },
  watch: {
    isSearchInputActive(newValue, oldValue) {
      if (newValue !== oldValue && oldValue) {
        if (this.isFocused) {
          this.reset(true)
        }
        this.reset()
      }
    },
    $route(newValue, oldValue) {
      if (newValue.path !== oldValue.path) {
        this.reset()
        this.setPlaceholder()
        this.onSearchResultsPage(newValue)
      }
    },
    isVisible(newValue, oldValue) {
      if (newValue !== oldValue && !newValue) {
        this.reset()
      }
    },
    marketplaceSellerName(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.query = newValue
      }
    },
    recentSearchTerms: {
      handler() {
        if (this.recentSearchTerms.length > 0) {
          this.listName = this.searchType.recent
        } else {
          this.listName = this.searchType.popular
        }
      },
      deep: true,
    },
    placeholderIndex(newVal) {
      if (this.showPlaceholderCarousel) {
        this.placeholderText = this.placeholderList[newVal]
      }
    },
    showPlaceholderCarousel(newVal) {
      if (newVal) {
        this.rotatePlaceholder()
      }
    },
    productSuggestions: {
      handler(newVal, oldVal) {
        if (!this.isMobile && this.showSearchSuggestions && this.isNewProductSet(newVal, oldVal)) {
          this.logImpression(newVal)
        }
      },
      deep: true,
    },
    currentPosition(newVal) {
      this.tilePosition = -1
      if (newVal !== -1) {
        if (this.isScroll) {
          this.query = this.activeList[newVal]
        }
        if (!this.isMobile
        && this.listName === this.searchType.suggested) {
          this.getTypeAheadTile(this.searchSuggestions[newVal])
        }
      }
    }
  },
  async created() {
    if (this.suggestedKeyword) {
      this.query = this.suggestedKeyword
    }
    await this.getSearchPlaceholderText()
    this.setPlaceholder()
  },
  mounted() {
    this.$store.dispatch('GlobalHeader/setSearchSelectedValue', '')
  },
  beforeUnmount() {
    this.currentPosition = -1
  },
  errorCaptured(error) {
    console.error(error)
    return false
  },
  methods: {
    ...mapMutations({
      addUsedSearchTerms: 'SearchTerms/addUsedSearchTerms',
      setIsVisible: 'SearchTerms/setIsVisible'
    }),
    onMouseMove() {
      this.isScroll = false
    },
    setPlaceholder() {
      if (this.isHomePage && this.placeholderList && this.isEmpty && !this.isFocused) {
        this.showPlaceholderCarousel = true
        this.setPlaceholderIndex()
        return
      }
      this.showPlaceholderCarousel = false
      this.stopTimeouts()
      this.setDefaultPlaceholderText()
    },
    setDefaultPlaceholderText() {
      if (this.isFocused) {
        this.placeholderText = ''
      }
      if (this.emptySearchPlaceholder) {
        this.placeholderText = this.emptySearchPlaceholder
        return
      }
      if (this.isMobileApp) {
        this.placeholderText = 'Search'
        return
      }
      if (!this.isHomePage && this.isEmpty && !this.isFocused) {
        this.placeholderText = this.typeaheadDefaultPlaceholder
      }
    },
    setPlaceholderIndex() {
      if (this.placeholderIndex !== this.placeholderList.length - 1) {
        this.placeholderIndex += 1
      } else {
        this.placeholderIndex = 0
      }
      this.fadePlaceholder = false
    },
    async rotatePlaceholder() {
      await new Promise((resolve) => { this.indexTimeout = setTimeout(resolve, 4500) })
      this.fadePlaceholder = true
      await new Promise((resolve) => { this.fadeTimeout = setTimeout(resolve, 500) })
      this.setPlaceholderIndex()
      if (this.showPlaceholderCarousel) {
        this.rotatePlaceholder()
      }
    },
    stopTimeouts() {
      clearTimeout(this.indexTimeout)
      clearTimeout(this.fadeTimeout)
    },
    onSearchResultsPage(route) {
      const {
        path: value,
        query: { sellerId },
        params: { keywords }
      } = route
      const searchResultsPage = value.split('/')[1]
      const searchResultsPathNames = ['product-search', 'article-search', 'savings-search']
      if (!searchResultsPathNames.includes(searchResultsPage)) {
        this.query = ''
      }
      if (keywords) {
        this.query = keywords
      } else if (sellerId) {
        this.query = this.marketplaceSellerName
      }
    },
    handleInput($event) {
      this.setPlaceholder()
      this.query = $event.target.value
      if (!this.query && this.isDirty) {
        return this.reset(true)
      }
      if (this.query && !this.isDirty) {
        this.isDirty = true
        this.isFocused = true
        this.$emit('input-dirty', true)
      }
      if (!this.isVisible) {
        this.setIsVisible(true)
      }
      if (this.query.length < 1) {
        return ''
      }
      return this.updateQuery()
    },
    // eslint-disable-next-line
    updateQuery: _.debounce(async function() {
      if (!this.isEmpty && !this.isScroll) {
        // need to encode square brackets because the api is failing
        // other characters are encoded by default since its a get api call
        const queryForApi = encodeSquareBrackets(this.query)
        let apiPath = '/api/v3.0/user/typeahead'
        if (this.isNewTypeAheadApiServices) {
          apiPath = `/api/v5.0/user/typeahead/${this.userId}/${this.currentServiceLocationId}`
        }
        const result = await ApiService.get(apiPath, {
          params: {
            like: queryForApi
          }
        })
        const MAX_TYPEAHEAD_ITEM = this.useCitrusBannerXStyles ? 9 : 15
        if (result.status === 200) {
          this.searchSuggestions = result.data.response.slice(0, MAX_TYPEAHEAD_ITEM)
          this.listName = this.searchType.suggested
          this.currentPosition = -1
        }
        this.getTypeAheadTile(this.keyword)
      }
      return ''
    }, 400),
    async getTypeAheadTile(keyword) {
      let apiPath = '/api/v4.0/user/products'
      if (this.isNewTypeAheadApiServices) {
        apiPath = `/api/v5.0/products/${this.userId}/${this.currentServiceLocationId}`
      }
      if (!this.cache[keyword]) {
        const response = await ApiService.get(apiPath, {
          params: {
            sort: 'bestMatch asc',
            filter: '',
            start: 0,
            rows: 20,
            keywords: keyword,
            nutrition: false,
            flags: true,
            facet: 'categories,brands,nutrition,sustainability,specials,newArrivals,privateLabel',
            facetExcludeFilter: true,
            includeOutOfStock: false
          }
        })
        if (response.status === 200) {
          this.cache[keyword] = response.data.response.products.filter(product => product.flags.active)
        }
      }
      this.setProductSuggestions(keyword)
      this.activeSearchTerm = keyword
      this.showViewAll = this.filterProductSuggestions(this.cache[keyword]).length > this.productSuggestions.length
    },
    setProductSuggestions(keyword) {
      this.productSuggestions = this.filterProductSuggestions(this.cache[keyword]).slice(0, this.maxTiles)
    },
    filterProductSuggestions(prods = []) {
      return prods.filter(prod => !this.isCartItem(prod))
    },
    isCartItem({ prodId }) {
      return this.getCartItems.some(cartItem => cartItem.prodId === prodId)
    },
    reset(isActive = false, setButtonFocus = false) {
      this.$emit('input-dirty', isActive, setButtonFocus)
      this.searchSuggestions = []
      this.loading = false
      this.isDirty = false
      this.currentPosition = -1
      this.isFocused = isActive
      this.setPlaceholder()
    },
    resetSearchKeyword() {
      if (this.currentSearchTerm) {
        this.query = this.currentSearchTerm
      }
    },
    clearQuery() {
      this.query = ''
      this.searchSuggestions = []
      this.currentPosition = -1
      this.loading = false
      this.isFocused = false
      this.isDirty = false
      this.$nextTick(() => {
        this.$refs.searchInput.focus()
      })
      this.setPlaceholder()
    },
    resetSearchComplete() {
      this.addUsedSearchTerms(this.currentSearchTerm)
      this.reset()
      this.resetSearchKeyword()
    },
    onActive({
      activeDescendant, index, listName
    }) {
      this.listName = listName || this.listName
      this.currentPosition = index
      this.activeDescendant = activeDescendant
    },
    onActiveTile(index) {
      this.tilePosition = index
    },
    onRemove({ position }) {
      this.currentPosition = position - 1
    },
    afterFocus() {
      this.isFocused = true
      this.$emit('on-search-focus')
      this.setPlaceholder()
      this.updateQuery()
      this.tilePosition = -1
    },
    clickProduct(product) {
      // eslint-disable-next-line max-len
      this.$router.push(ProductTileService.generateProductDetailsUrl(product.prodId, product.name, product.size))
    },
    onEnter() {
      if (this.isTileScroll && this.tilePosition !== 4) {
        const prodTile = this.productSuggestions[this.tilePosition]
        this.clickProduct(prodTile)
        return
      }
      if (this.currentSearchTerm !== this.query) {
        this.loading = this.currentPosition
      }
      let selectedValue = this.query
      if (this.hasItems && this.currentPosition >= 0) {
        selectedValue = this.searchSuggestions[this.currentPosition]
      }
      if (this.hasFocusedEmptyOrScrollableInput) {
        selectedValue = this.listName === this.searchType.recent
          ? this.recentSearchTerms[this.currentPosition]
          : this.popularSearchTerms[this.currentPosition]
      }
      this.query = selectedValue
      if (this.query) {
        const result = {
          selectedValue,
          searchTermPosition: this.currentPosition + 1,
          listName: this.listName
        }
        this.handleSearch(result)
      } else {
        this.reset()
      }
    },
    async handleSearch(searchTerm) {
      const { selectedValue } = searchTerm
      const noQuotesSelectedValue = selectedValue.replace(/['"]+/g, '')
      if (this.isProductSearchResultsPage && selectedValue === this.currentSearchTerm) {
        this.resetSearchComplete()
        document.activeElement.blur()
        return
      }
      const redirectPath = await this.checkKeywordRedirect(noQuotesSelectedValue)
      if (this.isAddItemModal) {
        // if user is searching inside the AddItemModal
        this.$store.dispatch('GlobalHeader/setSearchSelectedValue', selectedValue)
        this.$emit('on-search-term-select', searchTerm)
        this.getSearchType(searchTerm)
        this.resetSearchComplete()
      } else if (redirectPath) {
        this.keywordRedirect(redirectPath, selectedValue)
      } else {
        this.redirectToSearchPage(searchTerm)
      }
      document.activeElement.blur()
    },
    async checkKeywordRedirect(keyword) {
      let response
      try {
        const payload = {
          search_keyword_redirect: {
            limit: 1,
            fm: {
              'Search Text': [keyword]
            }
          }
        }
        const responseData = await this.$store.dispatch('ScheduledContent/queryRegion', {
          region: payload
        })
        response = responseData?.response?.regionContents?.[0]?.contents?.[0]
          ?.map.scheduledContentData.map['Redirect Path']
      } catch (e) {
        this.loading = false
      }
      return response
    },
    redirectToSearchPage(searchTerm) {
      const { selectedValue } = searchTerm
      const noQuotesSelectedValue = selectedValue.replace(/['"]+/g, '')
      const searchType = this.getSearchType(searchTerm)
      this.logSearchRedirect(searchTerm)
      let semanticSearch = false
      if (this.enableSemanticSearch && this.semanticSearchV7Enabled) {
        semanticSearch = true
      }
      this.$router.push({
        name: 'product-search',
        params: { keywords: noQuotesSelectedValue },
        query: {
          searchRef: searchType,
          semanticSearch
        },
      })
      this.$store.dispatch('GlobalHeader/setSearchSelectedValue', selectedValue)
      this.resetSearchComplete()
    },
    keywordRedirect(path, searchKeyword) {
      this.$trackClientLog('search_keyword_redirect', {
        searchKeyword,
        url: path
      })
      if (
        this.isMobileApp
        && (_.contains(EXTERNAL_URLS, path) || this.pharmacyAppLink === path || this.scanItAppLink === path)
      ) {
        this.$emit('handle-native-redirect', path)
      } else {
        this.$router.push({ path, query: { searchRef: 'redirect', searchTerm: searchKeyword } })
      }
      this.resetSearchComplete()
      this.$store.dispatch('GlobalHeader/setSearchSelectedValue', '')
    },
    getSearchType(searchTerm) {
      let searchTermType = ''
      if (searchTerm.listName === 'Your Searches' && this.currentPosition > -1) {
        searchTermType = 'recent'
      } else if (searchTerm.listName === 'Search Suggestions' && this.currentPosition > -1) {
        searchTermType = 'suggested'
      } else if (searchTerm.listName === 'Popular Searches' && this.currentPosition > -1) {
        searchTermType = 'popular'
      }
      this.$emit('search-type', searchTermType)
      return searchTermType
    },
    valueSelected(result) {
      this.query = result.selectedValue
      this.currentPosition = result.searchTermPosition - 1
      this.handleSearch(result)
    },
    scanBarcode() {
      this.$emit('scan-barcode')
    },
    voiceSearch() {
      this.$emit('voice-search')
    },
    onFocusOut(e) {
      if (this.$refs.form.contains(e.relatedTarget) || !e.relatedTarget) {
        return
      }
      this.reset()
    },
    async getSearchPlaceholderText() {
      const payload = {
        header_search_keywords: {
          limit: 6
        }
      }
      const results = await this.$store.dispatch('ScheduledContent/queryRegion', {
        region: payload
      })
      const { regionContents = [] } = results?.response || {}
      if (regionContents[0]?.contents?.length) {
        const searchPlaceholderData = regionContents.filter(val => val.region === 'header_search_keywords')
        this.handlePlaceholderData(searchPlaceholderData[0].contents)
      } else {
        this.placeholderList = []
      }
    },
    handlePlaceholderData(data) {
      Object.keys(data).forEach((key) => {
        const { map } = data[key]
        this.setPlaceholderList(map.scheduledContentData.map['Message Text'])
        this.setSpyGlassData(map)
      })
    },
    setPlaceholderList(text) {
      this.placeholderList.push(text)
    },
    setSpyGlassData(map) {
      this.spyGlassData.parent = mapSpyglassData(map)
    },
    logSearchRedirect(searchTerm) {
      const { selectedValue, searchTermPosition, listName } = searchTerm
      const isTypeaheadUsed = !!listName
      const payload = {
        keywords: selectedValue,
        typeahead_used: !!isTypeaheadUsed,
        list_name: listName
      }
      if (Number.isInteger(searchTermPosition) && searchTermPosition >= 0) {
        payload.index = searchTermPosition
      }
      this.$trackClientLog('search_typeahead', payload)
    },
    logImpression(prodSet) {
      const prodInfo = prodSet.map((product, index) => ({
        ...product,
        list: 'search-typeahead - product suggestions',
        position: index + 1
      }))
      const sourceInfo = {
        personalizationImpressionFlag: false,
        listType: `search-typeahead - product suggestions: ${this.activeSearchTerm}`,
        listName: `search-typeahead - product suggestions: ${this.activeSearchTerm}`,
        addToCartLocationdetail: this.activeSearchTerm,
        searchTerm: this.query,
        impressionLocation: 'search-typeahead',
      }
      trackProductImpression({ products: prodInfo, sourceInfo })
    },
    handleKeyEvent(event) {
      const { code } = event
      if (code === 'ArrowUp') {
        event.preventDefault()
        this.isScroll = true
        if (this.hasItems) {
          this.hasSuggestionsScrollUp(code)
        } else {
          this.noSuggestionsScrollUp()
        }
      } else if (code === 'ArrowDown') {
        event.preventDefault()
        this.isScroll = true
        if (this.hasItems) {
          this.hasSuggestionsScrollDown(code)
        } else {
          this.noSuggestionsScrollDown()
        }
      } else if (code === 'ArrowRight' && this.hasItems && this.currentPosition > -1
      && this.productSuggestions.length) {
        event.preventDefault()
        this.isScroll = true
        this.tilePosition = 0
      } else if (code === 'ArrowLeft' && this.isTileScroll) {
        event.preventDefault()
        this.isScroll = true
        this.tilePosition = -1
      } else {
        this.isScroll = false
      }
    },
    hasSuggestionsScrollUp(code) {
      let position = this.isTileScroll ? this.tilePosition : this.currentPosition
      const list = this.isTileScroll ? this.$refs.tileList.$el : this.$refs.list.$el
      const listItem = this.isTileScroll ? list.children[0].children[0] : list.children[1].children[0]
      const listOffset = list.scrollHeight - list.clientHeight + listItem.offsetHeight
      if (position < 1) {
        position = this.isTileScroll && this.listLength === 4 ? this.listLength : this.listLength - 1
        list.scroll({
          top: listOffset,
          behavior: 'smooth'
        })
      } else {
        handleKeyStrokeScroll(
          code,
          position,
          listItem.offsetHeight,
          listOffset,
          list
        )
        position -= 1
      }
      if (this.isTileScroll) {
        this.tilePosition = position
        return
      }
      this.currentPosition = position
    },
    noSuggestionsScrollUp() {
      if (this.currentPosition <= 0) {
        if (this.recentSearchTerms.length && this.listName === this.searchType.popular) {
          this.currentPosition = this.recentSearchTerms.length - 1
          this.listName = this.searchType.recent
        } else {
          this.currentPosition = this.popularSearchTerms.length - 1
          this.listName = this.searchType.popular
        }
        return
      }
      this.currentPosition -= 1
    },
    hasSuggestionsScrollDown(code) {
      let position = this.isTileScroll ? this.tilePosition : this.currentPosition
      const list = this.isTileScroll ? this.$refs.tileList.$el : this.$refs.list.$el
      const listItem = this.isTileScroll ? list.children[0].children[0] : list.children[1].children[0]
      const listOffset = list.clientHeight - listItem.offsetTop - listItem.offsetHeight
      if (position < this.listLength - 1) {
        position += 1
        handleKeyStrokeScroll(
          code,
          position,
          listItem.offsetHeight,
          listOffset,
          list
        )
      } else if (this.isTileScroll && this.listLength === 4 && position === 3) {
        position = this.listLength
        handleKeyStrokeScroll(
          code,
          position,
          listItem.offsetHeight,
          listOffset,
          list
        )
      } else {
        position = 0
        list.scroll({
          top: 0,
          behavior: 'smooth'
        })
      }
      if (this.isTileScroll) {
        this.tilePosition = position
        return
      }
      this.currentPosition = position
    },
    noSuggestionsScrollDown() {
      if (this.currentPosition === this.activeList.length - 1) {
        this.currentPosition = 0
        if (this.listName === this.searchType.popular && this.recentSearchTerms.length) {
          this.listName = this.searchType.recent
        } else {
          this.listName = this.searchType.popular
        }
        return
      }
      this.currentPosition += 1
    },
    async updateCart(cart) {
      const updatedCart = {
        addToCartLocation: 'search-typeahead',
        addToCartLocationdetail: this.activeSearchTerm,
        indexPosition: cart.productIndex ? cart.productIndex : null,
        searchTerm: this.query,
        ...cart
      }
      trackCartChange(updatedCart, true)
      if (qtyIncreasedByOne(cart)) {
        optimizelyFullstack.trackEvent('Search_TypeAhead_Add_To_Cart', { value: cart.productData.price })
      }
    },
    viewAllMiniCarouselSelected() {
      this.handleSearch({
        searchTermPosition: 0,
        selectedValue: this.keyword,
        listName: 'Your Searches'
      })
    },
    setCitrusBannerXLoaded() {
      this.citrusBannerXLoaded = true
    },
    redirect(event) {
      this.$router.push(event.url)
    }
  }
}
</script>
