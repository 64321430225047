import ApiService from '@/api/'
import CurrentUserIdAPI from 'api/CurrentUserIdAPI'
import AutoLoginAPI from 'api/AutoLoginAPI'

export default {
  get(payload) {
    const ServiceLocationApiVersion = window.sharedVue.config.globalProperties.$store.getters['SiteConfig/varByName'](
      'feature_express_delivery'
    )
    if (ServiceLocationApiVersion) {
      return ApiService.get(`/api/v6.0/serviceLocations`, payload)
    }
    return ApiService.get(`/api/v5.0/serviceLocations`, payload)
  },
  async update(params, allowAutoLogin = false) {
    const {
      currentServiceLocationId,
      serviceLocationId,
      dryRun,
      delivAreaChange,
      custTypeChange,
    } = params
    let { userId, basketId } = params

    if (userId === 2 && allowAutoLogin) {
      const result = await CurrentUserIdAPI.get()
      const { data } = result
      userId = data?.userId || 2
    }

    const autoLoginRequired = userId === 2

    if (autoLoginRequired && allowAutoLogin) {
      try {
        const autoLoginApiVersion = 'v3.0'
        const result = await AutoLoginAPI.perform(autoLoginApiVersion, currentServiceLocationId)
        const { data } = result

        userId = data?.response?.userId || 2
        basketId = window.sharedVue.config.globalProperties.$store.state.Cart.basketId || 1
      } catch (e) {
        userId = 2
      }
    }

    let queryParams = `serviceLocationId=${serviceLocationId}`
    if (dryRun) {
      queryParams += `&dryRun=true`
    }
    if (custTypeChange) {
      queryParams += `&custTypeChange=true`
    }
    if (delivAreaChange) {
      queryParams += `&delivAreaChange=true`
    }
    const config = {
      pathVariables: {
        userId,
        basketId
      },
      isTemplateUrlPath: true
    }
    return ApiService.put(`/api/v6.0/user/:userId/orders/:basketId/serviceLocation?${queryParams}`,
      params, config)
  },
  lookupById(serviceLocationId) {
    const params = {
      ...serviceLocationId && { serviceLocationId }
    }
    return this.get({ params })
  }
}
