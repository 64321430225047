/**
 * Capitalize the first letter of the provided input.
 *
 * @param {string} value word / statement to capitalize
 * @return input value with first letter capitalized and all other letters lowercase
 */
export default function (value) {
  if (typeof value !== 'string') return ''
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
}
