import ApiService from '@/api/'
import buildURL from 'utils/lib/buildEncodedURL'

function screenSizeString(rootGetters) {
  let currentSize
  if (rootGetters['ScreenSize/isTablet']) {
    currentSize = 'Tablet'
  }
  if (rootGetters['ScreenSize/isDesktop']) {
    currentSize = 'Desktop'
  }
  if (rootGetters['ScreenSize/isMobile']) {
    currentSize = 'Mobile'
  }
  return currentSize
}

export default {
  namespaced: true,
  state: {
    region: {},
    currentQuotIndex: 0,
    quotientAds: [],
    currentScheduledContent: {},
    spyglassActive: false,
    timeTravelDate: null,
    userContent: {}, // used for CMS dynamic links
    homepagePromos: null,
    videoId: null,
    adOptions: {},
    savingsCategoryName: '',
    homeKwmItems: [],
    homeHeroItems: [],
    customPageType: '',
    searchShelfHeaderUrl: '',
    searchShelfHeaderTerm: '',
    productSetHeadline: '',
    nativeScheduledContentQueryData: ''
  },
  getters: {
    region: state => state.region,
    quotientAds: state => state.quotientAds,
    currentQuotIndex: state => state.currentQuotIndex,
    spyglassActive: state => state.spyglassActive,
    currentScheduledContent: state => state.currentScheduledContent,
    userContent: state => state.userContent,
    timeTravelDate: state => state.timeTravelDate,
    homepagePromos: state => state.homepagePromos,
    videoId: state => state.videoId,
    adOptions: state => state.adOptions,
    homeKwmItems: state => state.homeKwmItems,
    homeHeroItems: state => state.homeHeroItems,
    customPageType: state => state.customPageType,
    searchShelfHeaderUrl: state => state.searchShelfHeaderUrl,
    searchShelfHeaderTerm: state => state.searchShelfHeaderTerm,
    productSetHeadline: state => state.productSetHeadline,
    nativeScheduledContentQueryData: state => state.nativeScheduledContentQueryData
  },
  mutations: {
    setRegion(state, value) {
      _.each(value, (item) => { state.region[item.region] = item.contents })
    },
    setAdOptions(state, adOptions) {
      state.adOptions = adOptions
    },
    setSavingsCategoryName(state, value) {
      state.savingsCategoryName = value
    },
    setCustomPageType(state, value) {
      state.customPageType = value
    },
    incrementQuotIndex(state) {
      state.currentQuotIndex += 1
    },
    resetQuotIndex(state) {
      state.currentQuotIndex = 0
    },
    setCurrentScheduledContent(state, value) {
      state.currentScheduledContent = value
    },
    addQuotient(state, payload) {
      state.quotientAds.push(payload)
    },
    setVideoId(state, payload) {
      state.videoId = payload
    },
    clearQuotientAds(state) {
      state.quotientAds = []
    },
    updateDate(state, value) {
      state.timeTravelDate = value
    },
    toggleSpyglass(state, value) {
      state.spyglassActive = value
    },
    setUserContent(state, value) {
      const content = {}
      const {
        userProfile, orderStatus, name, defaultDeliveryAddress, defaultBillingAddress
      } = value

      const {
        information, opco, retailerCard, refData, redirectUrl, deliveryServiceLocation
      } = userProfile || {}

      content.name = name
      if (!content.name?.firstName || content.name?.firstName === '') {
        content.name = {}
        content.name.firstName = 'Guest'
        content.name.fullName = 'Guest'
      } else {
        content.name.fullName = `${content.name.firstName} ${content.name.lastName}`
      }
      content.info = information
      content.opco = opco
      if (retailerCard) {
        content.info.savingsStarNumber = retailerCard.cardNumber
      }
      const { customerDefaultDeliveryServiceLocation = {}, fuelSurcharge } = refData || {}
      content.billingAddress = defaultBillingAddress
      content.deliveryAddress = defaultDeliveryAddress
      content.feeAmounts = customerDefaultDeliveryServiceLocation.feeAmounts || {}
      content.fuelSurcharge = fuelSurcharge
      content.feeAmounts.deliveryMinOrder = orderStatus?.store?.deliveryMinOrder
        || deliveryServiceLocation?.feeAmounts?.minimumDeliveryAmount
      content.urls = {}
      content.urls.bannercoupons = redirectUrl
      content.hasUserInformation = true
      state.userContent = content
    },
    setHomepagePromos(state, value) {
      state.homepagePromos = value
    },
    setHomeKwmItems(state, value) {
      state.homeKwmItems = value
    },
    setHomeHeroItems(state, value) {
      state.homeHeroItems = value
    },
    setSearchShelfHeaderUrl(state, value) {
      state.searchShelfHeaderUrl = value
    },
    setSearchShelfHeaderTerm(state, value) {
      state.searchShelfHeaderTerm = value
    },
    setProductSetHeadline(state, value) {
      state.productSetHeadline = value
    },
    setNativeScheduledContentQueryData(state, value) {
      state.nativeScheduledContentQueryData = value
    }
  },
  actions: {
    async queryRegion({
      commit, state, rootGetters
    }, payload) {
      const screenSize = screenSizeString(rootGetters)
      const appTargeting = rootGetters['NativeContainer/isAnyApp'] ? 'Native' : 'Web'
      const { userId, currentServiceLocationId } = appConfig.user
      const pathParams = `/${userId}/${currentServiceLocationId}`
      const responseData = await ApiService.get(`/api/v4.0/cms${pathParams}`, {
        params: {
          regions: payload.region,
          dt: state.timeTravelDate,
          appTargeting,
          screenSize,
        },
        paramsSerializer: params => buildURL(params)
      })
      if (responseData.status === 200) {
        commit('setRegion', responseData.data.response.regionContents)
      }
      return responseData.data
    }
  }
}
