/** function parseDateTime - parse date string for PDL order date format
 *
 * @param date string - time string to be formatted
 * @return string - parsed out date string
* */

const shortDateTimeRegex = /^(\d{4}|\+\d{6})(?:-(\d{2})(?:-(\d{2})(?:T(\d{2}):(\d{2})?)?)?)$/

export default function (dateString) {
  if (!shortDateTimeRegex.test(dateString)) {
    return new Date()
  }
  const matches = dateString.match(shortDateTimeRegex)
  const year = parseInt(matches[1], 10)
  const month = parseInt(matches[2], 10)
  const day = parseInt(matches[3], 10)
  const hour = parseInt(matches[4], 10)
  const minute = parseInt(matches[5], 10)

  if (Number.isNaN(year)
  || Number.isNaN(month)
  || Number.isNaN(day)
  || Number.isNaN(hour)
  || Number.isNaN(minute)) {
    return new Date()
  }

  return new Date(year, month - 1, day, hour, minute)
}
