<template>
  <div v-show="!isMobileMoreMenuOpen">
    <div
      ref="globalHeader"
      :class="[
        'global-header-base',
        {
          '!w-full' : isNewNavDesignEnabled
        }
      ]">
      <header>
      <WarningHeader
        :class="{ 'visibility-hidden': hideGlobalHeader }"
        @working-cut-off="emitWorkingCutOff"
        @submitted-cut-off="emitSubmittedCutOff"
      />
      <PdlGlobalHeader
        :class="{ 'visibility-hidden': hideGlobalHeader }"
        :is-mobile="isMobile"
        :is-tablet="isTablet"
        :show-voice-search="showVoiceSearch"
        :show-barcode-scanner="showBarcodeScanner"
        :browse-aisles-categories="browseAislesCategories"
        :opco-theme="opcoTheme"
        :opco-id="opcoId"
        :spyglass-active="spyglassActive"
        :is-new-nav-design-enabled="isNewNavDesignEnabled"
        @track-promotion-impression="trackPromotionImpression"
        @home-nav-item-clicked="handleHomeClick"
        @nav-item-clicked="handleNavItemClick"
        @change-city="handleChangeCity"
        @logout="handleLogout"
        @product-group-clicked="handleProductGroupClick"
        @scan-barcode-url="scanBarcodeUrl"
        @voice-search="voiceSearch"
        @open-loyalty-card="openLoyaltyCard"
        @open-url="openUrl"
        @handle-native-redirect="handleNativeRedirect"
        @track-nav-updated="trackNavUpdated"
      />
      <PdlMobileMenu
        v-if="isTabletOrSmaller && !isMobileNewHeaderDesign"
        :browse-aisles-categories="browseAislesCategories"
        @track-promotion-impression="trackPromotionImpression"
        @home-nav-item-clicked="handleHomeClick"
        @nav-item-clicked="handleNavItemClick"
        @change-city="handleChangeCity"
        @logout="handleLogout"
        @product-group-clicked="handleProductGroupClick"
        @select-service-button-clicked="handleSelectServiceClick"
        @checkout-button-clicked="handleMenuCheckoutClick"
        @change-service-type="onChangeServiceType"
        @change-time="onChangeTime"
        @change-address="onChangeAddress"
        @open-url="openUrl"
        @menu-close="onMenuClose"
        @primary-nav-clicked="trackPrimaryNavClick"
      />
      <MobileMenu
        v-if="isTabletOrSmaller && isMobileNewHeaderDesign"
        :browse-aisles-categories="browseAislesCategories"
        :optimizely-show-new-header-design="optimizelyShowNewHeaderDesign"
        @track-promotion-impression="trackPromotionImpression"
        @home-nav-item-clicked="handleHomeClick"
        @nav-item-clicked="handleNavItemClick"
        @change-city="handleChangeCity"
        @logout="handleLogout"
        @product-group-clicked="handleProductGroupClick"
        @select-service-button-clicked="handleSelectServiceClick"
        @checkout-button-clicked="handleMenuCheckoutClick"
        @change-service-type="onChangeServiceType"
        @change-time="onChangeTime"
        @change-address="onChangeAddress"
        @open-url="openUrl"
        @track-nav-updated="trackNavUpdated"
        @menu-close="onMenuClose"
        @primary-nav-clicked="trackPrimaryNavClick"
      />
      <PdlGlobalHeaderSubnav
          v-if="isDesktop"
          class="sticky top-0 z-10"
          :spyglass-active="spyglassActive"
          :is-new-nav-design-enabled="isNewNavDesignEnabled"
          :page-view="pageView"
          @nav-item-clicked="handleNavItemClick"
          @shopping-mode-change="handleShoppingModeChange"
          @track-nav-updated="trackNavUpdated"
          @close-menu="handleCloseAccountMenu"
      />
      </header>
      <div ref="scrollableHeader"
        class="global-header-base_scroll-section"
      >
        <CategoryNavigation
          v-if="showCategoryNavigation"
          :page-view="pageView"
          @track-nav-updated="trackNavUpdated"
          tabindex="0"
        />
        <PdlTitleBarBase
          v-if="showTitleBar && isDesktop"
          ref="titleBar"
          tabindex="-1"
        />
        <NotificationBar
          v-if="isHomePage && !isMobile"
          ref="notificationBar"
          tabindex="-1"
        />
        <MiniCart
          v-if="showMiniCart"
          :style="miniCartStyles"
          :has-mask-overlay="hasMaskOverlay"
        />
        <span
          v-if="
            hasMaskOverlay &&
            hasMaskOverlayHeader &&
            !isNewNavDesignEnabled
          "
          class="global-header-base_scroll-section-mask"
          @click="hideMask"
          @keyup.enter.space="hideMask"
        />
      </div>
      <PdlTitleBarBase
          v-if="showTitleBar && (isMobile || isTablet)"
          ref="titleBar"
          tabindex="-1"
        />
    </div>
    <span
      v-if="hasMaskOverlay"
      :class="[
        shoppingModeHelpMaskClass,
        {
          'mask-global-header': !(isNewNavDesignEnabled && isDesktop),
          'mask--top-zero':
            !hasMaskOverlayHeader && shoppingModeHelpMaskClass === '',
          'mask--search-input': hasMaskOverlayHeader,
        },
      ]"
      @click="hideMask"
      @keyup.enter.space="hideMask"
    />
    <SuperuserStatus v-if="isEnabled" />
  </div>
</template>
<script>
import { defineAsyncComponent } from 'vue'
import {
  mapActions, mapGetters, mapMutations, mapState
} from 'vuex'
import {
  GTM, USER_PREFERENCE_KEYS, GTM_GA4, ORDER_SERVICE_TYPES
} from 'utils/constants'
import {
  workingCutOff,
  submittedCutOff,
} from 'utils/services/order/orderCutoff'
import trackNavigationGtm from 'utils/tracking/nav/trackNavigationGtm'
import trackNavigationUpdated from 'utils/tracking/nav/trackNavigationUpdated'
import ProductContentService from 'utils/services/product-content-service'
import { MODAL_URL_MAPS } from 'utils/constants/modal-mapping'
import SuperuserStatus from 'components/superuser/SuperuserStatus'
import PdlGlobalHeader from 'components/header/PdlGlobalHeader'
import NotificationBar from 'components/notification/NotificationBar'
import userAPI from 'api/UserAPI'
import PdlGlobalHeaderSubnav from 'components/navigation/PdlGlobalHeaderSubnav'
import CategoryNavigation from 'components/navigation/category-nav/CategoryNavigation'
import optimizelyFullstack from 'components/user-research/services/optimizely-fullstack-service'
import { isDesktopSafari } from 'components/detect/services/detect-services'
import trackNavigation from 'utils/tracking/nav/trackNavigation'
import generateSiteLocation from 'utils/lib/generateSiteLocation'
import PdlTitleBarBase from './PdlTitleBarBase'
import WarningHeader from './WarningHeader'
import { handleExternalItemClick } from '../mobile-app/navigation/utils'

const CONSUMER_CAT_ID_BLACKLIST = [0, 435, 555]
const scrollUp = 'scroll-up'
const scrollDown = 'scroll-down'
const scrollUpBrowseAisles = 'scroll-up-browse-aisles'
const scrollDownBrowseAisles = 'scroll-down-browse-aisles'
const scrollUpPDP = 'scroll-up-pdp'
const scrollDownPDP = 'scroll-down-pdp'

export default {
  components: {
    PdlGlobalHeader,
    PdlGlobalHeaderSubnav,
    PdlTitleBarBase,
    SuperuserStatus,
    NotificationBar,
    WarningHeader,
    PdlMobileMenu: defineAsyncComponent(() => import('components/navigation/mobile-menu/PdlMobileMenu')),
    MobileMenu: defineAsyncComponent(() => import('components/navigation/mobile-menu/MobileMenu')),
    MiniCart: defineAsyncComponent(() => import('components/mini-cart/MiniCart')),
    CategoryNavigation
  },
  props: {
    opcoTheme: {
      type: String,
      default: 'PPOD',
    },
    opcoId: {
      type: String,
      default: 'PPOD',
    },
    pageView: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      browseAislesCategories: [],
      debounceMenusResize: null,
      spyglassActive: false,
      actionRequestTotals: 0,
      windowHeight: 0,
      globalHeaderHeight: 0,
      browseAislesCategoriesPillsHeight: 107,
      scrollUp: false,
      scrollDown: false,
      scrollUpBrowseAisles: false,
      scrollDownBrowseAisles: false,
      scrollUpPDP: false,
      scrollDownPDP: false,
      optimizelyShowMethodSelector: false,
      userClosedCategoryMenu: false,
    }
  },
  computed: {
    ...mapState({
      miniCartEnabled: state => state.MiniCart.miniCartEnabled,
      fullstackInitialized: state => state.Optimizely.fullstackInitialized,
      actionClicked: state => state.ShoppingMode.actionClicked,
      helpModeActive: state => state.ShoppingMode.helpModeActive,
      serviceTypeSet: state => state.ShoppingMode.serviceTypeSet,
      storeConfirmationActive: state => state.ShoppingMode.storeConfirmationActive,
      categories: state => state.BrowseAislesCategories.categories,
      scheduledContentCategories: state => state.BrowseAislesCategories.scheduledContentCategories,
      cutoffAlertFlag: state => state.CutOff.cutoffAlertFlag,
      isEnabled: state => state.SuperUser.enabled,
      optimizelyShowNewHeaderDesign: state => state.Optimizely.showNewNavigationMobileDesign,
      pdpRedesignEnabled: state => state.Optimizely.pdpRedesignEnabled
    }),
    ...mapGetters('UserProfile', [
      'isDeliveryAddressComplete',
      'deliveryServiceLocation'
    ]),
    ...mapGetters({
      isMobile: 'ScreenSize/isMobile',
      isTablet: 'ScreenSize/isTablet',
      isTabletOrSmaller: 'ScreenSize/isTabletOrSmaller',
      isDesktop: 'ScreenSize/isDesktop',
      isXlargeDesktop: 'ScreenSize/isXlargeDesktop',
      prefPup: 'UserPref/prefPup',
      timeTravelDate: 'ScheduledContent/timeTravelDate',
      isMobileMainMenuOpen: 'GlobalHeader/isMobileMainMenuOpen',
      isMobileMoreMenuOpen: 'NativeContainer/isMobileMoreMenuOpen',
      isAccountMenuOpen: 'GlobalHeader/isAccountMenuOpen',
      getCart: 'Cart/getCart',
      varByName: 'SiteConfig/varByName',
      hasOpenModal: 'Modals/activeComponent',
      isNativeApp: 'NativeContainer/isNativeApp',
      isSubNavMenuOpen: 'GlobalHeader/isSubNavMenuOpen',
      isCategoryMenuOpen: 'GlobalHeader/isCategoryMenuOpen',
      isAnyApp: 'NativeContainer/isAnyApp'
    }),
    isNewNavDesignEnabled() {
      return this.varByName('feature_new_navigation_design')
    },
    showTitleBar() {
      if (this.isProductPage) {
        return !!this.isAnyApp
      }
      if (this.isNewNavDesignEnabled || (!this.isDesktop && this.optimizelyShowNewHeaderDesign)) {
        return !(this.isHomePage || this.isBrowseAislesPage || this.isPastPurchasesPage || this.isRecommendForYouPage)
      }
      return !this.isHomePage
    },
    isProductPage() {
      return this.$route.path.includes('/product/')
    },
    isHomePage() {
      return this.$route.path === '/' || this.$route.path === '/home'
    },
    isAccountPage() {
      return this.$route.path.includes('/account')
    },
    shouldCancelScrollEffect() {
      // to avoid title bar issues during scroll
      return this.isAccountPage || (this.isAnyApp && !this.spyglassActive)
    },
    isBrowseAislesPage() {
      return this.$route.path.includes('browse-aisles')
    },
    isPastPurchasesPage() {
      return this.$route.path.includes('past-purchases')
    },
    isRecommendForYouPage() {
      return this.$route.path.includes('recommended-for-you')
    },
    isCartPage() {
      return this.$route.path.includes('cart')
    },
    userInfo() {
      return this.$store.getters['UserProfile/userInfo']
    },
    userStatus() {
      return this.$store.getters['LoginStatus/userStatus']
    },
    fullyRegistered() {
      return this.$store.getters['LoginStatus/fullyRegistered']
    },
    hasMaskOverlay() {
      return this.$store.getters['GlobalHeader/hasMaskOverlay']
    },
    hasMaskOverlayHeader() {
      return this.$store.getters['GlobalHeader/hasMaskOverlayHeader']
    },
    hasPDPRedesign() {
      return this.varByName('feature_product_detail_page_redesign') && this.pdpRedesignEnabled
    },
    hasMaskOverlaySubnav() {
      return this.helpModeActive
    },
    isMobileNewHeaderDesign() {
      return this.isNewNavDesignEnabled || this.optimizelyShowNewHeaderDesign
    },
    shoppingModeHelpMaskClass() {
      if (!this.helpModeActive) {
        return ''
      }
      if (this.$store.getters['NativeContainer/isMobileApp'] && this.isMobile) {
        return 'top-[13.5rem]'
      }
      if (this.$store.getters['NativeContainer/isMobileApp'] && this.isTablet) {
        return 'top-[21.5rem]'
      }
      if (this.isMobile) {
        return 'top-[18.5rem]'
      }
      if (this.isTablet) {
        return 'top-[21.3rem]'
      }
      return 'mask--shopping-help-desktop'
    },
    showMiniCart() {
      return (
        !!this.getCart.items.length
        && this.miniCartEnabled
        && this.varByName('feature_mini_cart')
        && this.isXlargeDesktop
        && !this.isCartPage
      )
    },
    miniCartStyles() {
      let height = this.globalHeaderHeight
      let marginTop = '0px'
      const browseAislesPillsVisible = !this.scrollUp && !this.scrollDown

      if (this.isBrowseAislesPage) {
        if (browseAislesPillsVisible) {
          height += this.browseAislesCategoriesPillsHeight
          marginTop = `${this.browseAislesCategoriesPillsHeight}px`
        } else {
          height = this.globalHeaderHeight
        }
      }

      return {
        height: `calc(100vh - ${height}px)`,
        marginTop,
      }
    },
    desktopMenusAreOpened() {
      return (
        this.$store.getters['GlobalHeader/isBrowseAislesMenuOpen']
        || this.$store.getters['GlobalHeader/isAccountMenuOpen']
      )
    },
    mobileMenuIsOpen() {
      return (
        this.$store.getters['GlobalHeader/isMobileMainMenuOpen']
        || this.$store.getters['GlobalHeader/isMobileBrowseAislesMenuOpen']
        || this.$store.getters['GlobalHeader/isMobileMyShopMenuOpen']
      )
    },
    serviceType() {
      return this.deliveryServiceLocation.serviceType || null
    },
    showBarcodeScanner() {
      return this.$store.getters['NativeContainer/isMobileApp']
    },
    showVoiceSearch() {
      return (
        this.$store.getters['NativeContainer/isMobileApp']
        && this.$store.getters['SiteConfig/varByName'](
          'feature_native_voice_to_text'
        )
      )
    },
    defaultDeliveryAddress() {
      return this.$store.getters['UserProfile/defaultDeliveryAddress']
    },
    hideGlobalHeader() {
      if (this.isTabletOrSmaller && this.hasMaskOverlaySubnav) {
        return false
      }
      return this.isTabletOrSmaller && this.mobileMenuIsOpen
    },
    subCategoryPillsTopPosition() {
      if (this.isTablet) {
        return 100
      }
      if (
        this.isMobile
        && !this.$store.getters['NativeContainer/isMobileApp']
      ) {
        return 130
      }
      if (this.$store.getters['NativeContainer/isMobileApp']) {
        return 80
      }
      return 145
    },
    screenSize() {
      if (this.isMobile) {
        return 'mobile'
      }
      if (this.isTablet) {
        return 'tablet'
      }
      if (this.isDesktop) {
        return 'desktop'
      }
      return ''
    },
    showCategoryNavigation() {
      if (!this.isNewNavDesignEnabled || this.optimizelyShowNewHeaderDesign || this.isAnyApp) {
        return false
      }
      if (!this.isDesktop) {
        return this.isPastPurchasesPage || this.isRecommendForYouPage
      }
      return this.isDesktop && this.isCategoryMenuOpen
    },
    categoryNavOpensByDefault() {
      return (this.isHomePage || this.isBrowseAislesPage || this.isRecommendForYouPage)
    }
  },
  watch: {
    $route(to, from) {
      this.userClosedCategoryMenu = false
      const newVal = to.fullPath
      const oldVal = from.fullPath
      const isProductSearchPageItself = newVal.includes('product-search')
        && oldVal.includes('product-search')
      if (newVal && newVal !== oldVal) {
        this.$store.commit('CitrusBannerXAd/setBannerXData', null)
      }
      if (newVal !== oldVal && !newVal.includes('modal')) {
        document.body.classList.remove(scrollDown)
        document.body.classList.remove(scrollDownBrowseAisles)
        document.body.classList.remove(scrollDownPDP)
        this.scrollDown = false
        this.scrollDownBrowseAisles = false
        this.scrollDownPDP = false
        this.$refs.scrollableHeader.style.transform = `translateY(0px)`
        if (!isProductSearchPageItself) {
          this.setGlobalHeaderHeight()
        }
      }
      if (!oldVal.includes('pages') && newVal.includes('pages')) {
        this.setGlobalHeaderHeight()
      }
    },
    prefPup() {
      this.findShoppingModePreferences()
    },
    userInfo() {
      this.setDeliveryData()
      if (!this.hasOpenModal) {
        this.findShoppingModePreferences()
      }
    },
    fullstackInitialized(initialized) {
      if (initialized) {
        this.getOptimizelyData()
      }
    },
    showMiniCart: {
      immediate: true,
      handler() {
        this.updateMiniCartActive(this.showMiniCart)
      },
    },
    actionClicked(newVal) {
      if (newVal) {
        this.storeEventSetup(newVal)
      }
    },
    screenSize(newSize, oldSize) {
      if (oldSize !== '' && newSize !== oldSize) {
        this.setGlobalHeaderHeight()
      }
    },
    cutoffAlertFlag() {
      this.setGlobalHeaderHeight()
    },
    timeTravelDate(oldValue, newValue) {
      if (oldValue !== newValue) {
        this.spyglassActive = true
        this.getHeaderContent()
        this.getNotifications()
        this.fetchScheduledContentCategories()
      }
    },
    hasMaskOverlay(val) {
      if (!this.isMobileNewHeaderDesign) {
        this.hideOverflowForBody(val)
      }
    },
    scheduledContentCategories: {
      handler(newValue, oldValue) {
        if (oldValue !== newValue) {
          this.browseAislesCategories = this.getBrowseAislesCategories()
        }
      },
      deep: true,
    },
    isCategoryMenuOpen(newValue, oldValue) {
      if (oldValue !== newValue) {
        this.setGlobalHeaderHeight()
      }
    },
    mobileMenuIsOpen(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          optimizelyFullstack.trackEvent('Mobile Navigation Menu Open')
        } else {
          optimizelyFullstack.trackEvent('Mobile Navigation Menu Close')
        }
      }
    }
  },
  async created() {
    this.$store.dispatch('GlobalHeader/init')
    this.getHeaderContent()
    await this.getNotifications()
    this.setGlobalHeaderHeight()
    await this.fetchScheduledContentCategories()
    await this.fetchCategories()
    this.browseAislesCategories = this.getBrowseAislesCategories()
    await this.fetchScheduledContent()
  },
  mounted() {
    const { globalHeader } = this.$refs
    if (!globalHeader) return
    this.debounceMenusResize = _.debounce(this.closeOverlaysOnResize, 250)
    window.addEventListener('resize', () => {
      if (this.debounceMenusResize) this.debounceMenusResize()
      this.setWindowHeight()
    })
    window.setTimeout(() => {
      this.globalHeaderHeight = this.$refs.globalHeader?.offsetHeight
    }, 3000)
    this.setWindowHeight()
    this.setUpStickyScrollHeader()

    if (this.fullstackInitialized) {
      this.getOptimizelyData()
    }
  },
  updated() {
    // Ensuring the position of scrollableHeader is relative prevents a negative
    // window.scrollY from causing an incorrect header height in desktop Safari.
    if (!this.isMobile && this.isBrowseAislesPage && isDesktopSafari()) {
      this.$nextTick(() => {
        const { scrollableHeader } = this.$refs
        if (scrollableHeader) {
          scrollableHeader.style.position = 'relative'
        }
      })
    }
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.debounceMenusResize)
    this.debounceMenusResize = null
  },
  methods: {
    ...mapActions({
      getNotifications: 'Notifications/getNotifications',
      logNativeContainerError: 'NativeContainer/logNativeContainerError',
      fetchCategories: 'BrowseAislesCategories/fetchCategories',
      fetchScheduledContentCategories:
        'BrowseAislesCategories/fetchScheduledContentCategories',
      fetchScheduledContent: 'BrowseAislesCategories/fetchScheduledContent',
      setCloseAccountMenu: 'GlobalHeader/setCloseAccountMenu',
    }),
    ...mapMutations({
      setGlobalHeaderCategory: 'BrowseAislesCategories/setGlobalHeaderCategory',
      updateMiniCartActive: 'MiniCart/updateMiniCartActive',
      setAlert: 'Alert/setAlert',
      setCloseSubNavMenu: 'GlobalHeader/setCloseSubNavMenu',
      toggleSubNavMenu: 'GlobalHeader/toggleSubNavMenu',
      setCloseCategoryMenu: 'GlobalHeader/setCloseCategoryMenu',
      setOpenCategoryMenu: 'GlobalHeader/setOpenCategoryMenu'
    }),
    handleCloseAccountMenu() {
      if (this.isAccountMenuOpen) {
        this.setCloseAccountMenu()
      }
    },
    setUpStickyScrollHeader() {
      const { scrollableHeader } = this.$refs
      if (!scrollableHeader) return
      const { body } = document
      let lastScroll = 0
      scrollableHeader.style.transform = `translateY(0px)`
      const scrollEventHandler = _.throttle(() => {
        const currentScroll = window.scrollY
        if (currentScroll <= 5) {
          body.classList.remove(scrollUp)
          body.classList.remove(scrollUpBrowseAisles)
          body.classList.remove(scrollUpPDP)
          this.scrollUp = false
          this.scrollUpBrowseAisles = false
          this.scrollUpPDP = false
        }

        const isAtBottom = window.innerHeight + window.scrollY
          >= document.body.scrollHeight
        if (!isAtBottom) {
          this.onScrollBottom(body, currentScroll, lastScroll)
          this.onScrollBottomBrowsePDP(body, currentScroll, lastScroll)
        }
        lastScroll = currentScroll

        if (this.storeConfirmationActive) {
          this.$store.dispatch('ShoppingMode/setStoreConfirmationState', false)
        }

        this.globalHeaderHeight = this.$refs.globalHeader?.offsetHeight
      }, 50)
      window.addEventListener('scroll', scrollEventHandler, { passive: true })
    },
    onScrollBottom(body, currentScroll, lastScroll) {
      const { scrollableHeader } = this.$refs
      const topOffsetInPx = scrollableHeader?.style?.transform
      if (!scrollableHeader || !topOffsetInPx) return
      let topOffset = 0
      const maxOffset = 150
      const currentTopOffset = topOffsetInPx.match(/\d+/)[0]
      const scrollDiff = lastScroll - currentScroll
      topOffset = currentTopOffset - scrollDiff

      if (currentScroll > lastScroll) {
        this.onDownScroll(body, topOffset, maxOffset)
      } else if (currentScroll < lastScroll) {
        this.onUpScroll(body, topOffset)
      }
    },
    onScrollBottomBrowsePDP(body, currentScroll, lastScroll) {
      // specific class for Browse Aisles Page Scroll
      if (this.isBrowseAislesPage) {
        if (
          currentScroll > this.subCategoryPillsTopPosition
          && currentScroll > lastScroll
          && !body.classList.contains(scrollDownBrowseAisles)
        ) {
          this.cleanUpClasses(body, scrollUpBrowseAisles, scrollDownBrowseAisles)
          this.scrollUpBrowseAisles = false
          this.scrollDownBrowseAisles = true
        } else if (
          currentScroll < lastScroll
          && body.classList.contains(scrollDownBrowseAisles)
        ) {
          this.cleanUpClasses(body, scrollDownBrowseAisles, scrollUpBrowseAisles)
          this.scrollDownBrowseAisles = false
          this.scrollUpBrowseAisles = true
        }
      }
      // specific class for Product detail page
      if (this.isProductPage && this.hasPDPRedesign) {
        if (
          currentScroll > this.subCategoryPillsTopPosition
          && currentScroll > lastScroll
          && !body.classList.contains(scrollDownPDP)
        ) {
          this.cleanUpClasses(body, scrollUpPDP, scrollDownPDP)
          this.scrollUpPDP = false
          this.scrollDownPDP = true
        } else if (
          currentScroll < lastScroll
          && body.classList.contains(scrollDownPDP)
        ) {
          this.cleanUpClasses(body, scrollDownPDP, scrollUpPDP)
          this.scrollDownPDP = false
          this.scrollUpPDP = true
        }
      }
    },
    cleanUpClasses(body, removedClass, addedClass) {
      body.classList.remove(removedClass)
      body.classList.add(addedClass)
    },
    onDownScroll(body, topOffset, maxOffset) {
      if (this.isCategoryMenuOpen) this.setCloseCategoryMenu()
      // once ab test for PDP redesign is complete this can be removed.
      if (this.isProductPage && !this.hasPDPRedesign) {
        return
      }
      if (this.isProductPage && !body.classList.contains(scrollDown)) {
        this.cleanUpClasses(body, scrollUp, scrollDown)
        body.scrollUp = false
        body.scrollDown = true
      }
      if (this.isBrowseAislesPage && !body.classList.contains(scrollDown)) {
        this.cleanUpClasses(body, scrollUp, scrollDown)
        this.scrollUp = false
        this.scrollDown = true
      } else {
        let destinationOffset = topOffset <= maxOffset ? topOffset : maxOffset
        // ios devices has some issue with hiding the title bar
        // so adding a condition for that
        if (window.scrollY <= 0) {
          destinationOffset = 0
        }
        this.$refs.scrollableHeader.style.transform = !this
          .shouldCancelScrollEffect
          ? `translateY(${-destinationOffset}px)`
          : ''
      }
    },
    onUpScroll(body, topOffset) {
      if (
        !this.isCategoryMenuOpen
        && this.categoryNavOpensByDefault
        && !this.userClosedCategoryMenu
      ) {
        this.setOpenCategoryMenu()
      }
      // once ab test for PDP redesign is complete this can be removed.
      if (this.isProductPage && !this.hasPDPRedesign) {
        return
      }
      if (this.isProductPage && body.classList.contains(scrollDown)) {
        this.cleanUpClasses(body, scrollDown, scrollUp)
        body.scrollUp = false
        body.scrollDown = true
      }
      if (this.isBrowseAislesPage && body.classList.contains(scrollDown)) {
        this.cleanUpClasses(body, scrollDown, scrollUp)
        this.scrollUp = true
        this.scrollDown = false
      } else {
        const destinationOffset = topOffset >= 0 ? topOffset : 0
        this.$refs.scrollableHeader.style.transform = !this
          .shouldCancelScrollEffect
          ? `translateY(${-destinationOffset}px)`
          : ''
        if (window.scrollY <= 0) {
          this.setGlobalHeaderHeight()
        }
      }
    },
    setWindowHeight() {
      if (window.innerHeight !== this.windowHeight) {
        this.windowHeight = window.innerHeight
        const vh = window.innerHeight * 0.01
        document.documentElement.style.setProperty('--vh', `${vh}px`)
      }
    },
    setGlobalHeaderHeight() {
      setTimeout(() => {
        const height = this.$refs.globalHeader?.offsetHeight
        document.body.style['padding-top'] = `${height}px`
        document.documentElement.style.setProperty('--header', `${height}px`)
        document.documentElement.style.setProperty(
          'scroll-padding-top',
          `${height}px`
        )
      }, this.isNewNavDesignEnabled ? 5 : 500)
    },
    hideMask() {
      if (this.$store.getters['GlobalHeader/isSearchInputActive']) {
        this.$store.commit('GlobalHeader/toggleMaskOverlay', true)
        this.$store.commit('GlobalHeader/setSearchInputState', false)
      }
    },
    getBrowseAislesCategories() {
      // remove Black Listed Categories from categories API
      const categoriesWithoutBlackListedCatId = _.filter(
        this.categories,
        (category) => {
          return !_.contains(CONSUMER_CAT_ID_BLACKLIST, category.consumCatId)
        }
      )

      const categories = [
        ...this.scheduledContentCategories,
        ...categoriesWithoutBlackListedCatId,
      ]

      // remove categories without Category Icon to avoid display issues
      return _.filter(categories, (category) => {
        return category.catImageURL || category.categoryIcon
      })
    },
    findShoppingModePreferences() {
      let delay = 100
      let count = 0

      const serviceTypeChecker = setInterval(() => {
        if (this.serviceType) {
          clearInterval(serviceTypeChecker)
          // only worried about browse/pickup in this block
          if (this.serviceType === ORDER_SERVICE_TYPES.BROWSE) {
            this.insertBrowseModeAddress()
          }
          this.$store.commit('ShoppingMode/setWidgetReady')
        } else if (count > 10) {
          clearInterval(serviceTypeChecker)
        } else {
          delay += 100
          count += 1
        }
      }, delay)
    },
    storeEventSetup(type) {
      const action = type
      const suppressBrowseSelection = 'set-order-type-no-browse|cart'.split('|').includes(action)
      && this.serviceTypeSet === 'B'
      switch (action) {
        case 'time':
          this.onChangeTime()
          break
        case 'set-order-location':
          this.onChangeAddress(this.serviceTypeSet)
          break
        default:
          this.onChangeServiceType(this.serviceTypeSet, suppressBrowseSelection)
          break
      }
      this.trackMethodSelectorForNavUpdate(type)
      this.$trackGtmEvent(GTM.siteEntryNav, {
        navSelection: action === 'time' ? 'time-slot' : this.serviceTypeSet,
      })
      this.$store.dispatch('ShoppingMode/setStoreConfirmationState', false)
      this.$store.commit('ShoppingMode/setNextActionRequest', {
        tileMode: '',
        serviceType: '',
      })
    },
    trackMethodSelectorForNavUpdate(type) {
      let navElementText = ''
      let url = ''
      switch (type) {
        case 'time':
          navElementText = 'Select a Time'
          if (this.serviceTypeSet === 'P') {
            url = 'Pickup Times'
          } else if (this.serviceType === 'D') {
            url = 'Delivery Times'
          } else {
            url = 'Select a Time'
          }
          break
        case 'set-order-location':
          navElementText = 'select a store'
          if (this.serviceTypeSet === 'P') {
            url = 'Select a Pickup Location'
          } else if (this.serviceType === 'D') {
            url = 'Delivery Zip Code'
          } else {
            url = 'Select a Store'
          }
          break
        case 'set-order-type':
          navElementText = 'select a shopping method'
          url = 'Pick a Shopping Method'
          break
        case 'set-order-type-no-browse':
          navElementText = 'Try Pickup/Delivery'
          url = 'Pick a Shopping Method'
          break
        default:
          break
      }
      this.trackNavUpdated({
        action: this.isMobile ? 'mobile menu' : 'primary header',
        label: navElementText,
        url
      })
    },
    emitWorkingCutOff() {
      workingCutOff()
    },
    emitSubmittedCutOff() {
      submittedCutOff()
    },
    async insertBrowseModeAddress() {
      try {
        const payload = {
          type: 'B',
          userPref: USER_PREFERENCE_KEYS.PREFERRED_IN_STORE,
        }
        const inStorePref = await this.$store.dispatch(
          'UserPref/getUserPrefResponse',
          payload
        )
        if (
          typeof inStorePref !== 'object'
          || inStorePref.value === undefined
        ) {
          throw new Error('failed to fetch store preferences for user')
        }
        let id = inStorePref.value
        if (!id) {
          id = this.deliveryServiceLocation.serviceLocationId || ''
        }
        const inStoreAddress = await this.$store.dispatch(
          'ServiceSelection/getServiceLocation',
          { id }
        )
        this.$store.dispatch('ShoppingMode/setBrowseAddress', {
          addr: inStoreAddress.address,
          city: inStoreAddress.city,
          state: inStoreAddress.state,
          zip: inStoreAddress.zip,
        })
      } catch (e) {
        throw new Error(e)
      }
    },
    closeOverlaysOnResize() {
      if ((this.isMobile || this.isTablet) && this.desktopMenusAreOpened) {
        this.$store.dispatch('GlobalHeader/setCloseBrowseAislesMenu')
        this.$store.dispatch('GlobalHeader/setCloseAccountMenu')
        this.$store.dispatch('GlobalHeader/removeMaskOverlay')
      }
      if (this.isDesktop && this.mobileMenuIsOpen) {
        this.$store.dispatch('GlobalHeader/setCloseMobileMainMenu')
        this.$store.dispatch('GlobalHeader/setCloseMobileBrowseAislesMenu')
        this.$store.dispatch('GlobalHeader/removeMaskOverlay')
      }
    },
    setDeliveryData() {
      this.currentServiceLocation = this.deliveryServiceLocation
      this.$store.dispatch(
        'ShoppingMode/setDeliveryAddress',
        this.userInfo.addresses['1']?.addressLine1
      )
      this.$store.dispatch(
        'ShoppingMode/setDeliveryDefaultZip',
        this.userInfo.addresses['1']?.zip
      )
    },
    getHeaderContent() {
      this.$store.dispatch('GlobalHeader/retrieveNavigationItems')
    },
    handleLogout() {
      let status
      userAPI.logOut()
        .then(() => { status = 'success' })
        .catch((response) => { status = `fail ${response.text}` })
        .finally(() => {
          // Analytics team does not want us to use generateSiteLocation here.
          // They do not need the page name for header items, just 'Header'.
          this.$trackGA4Event(GTM_GA4.logout,
            {
              response: status,
              type: 'manual',
              site_location: 'Header'
            })
        })
    },
    handleChangeCity() {
      this.handleLogout()
    },
    handleHomeClick() {
      this.$router.push('/home')
      // Analytics team does not want the page name for elements in the header
      // such as the logo, just "Header."
      const location = {
        ga4SiteLocation: 'Header'
      }
      this.trackNavClick('Logo', '/home', location, 'brand-logo_link')
      this.trackNavUpdated({
        action: 'utility header',
        label: 'Logo',
        url: '/home'
      })
    },
    openUrl(event) {
      ProductContentService.sendClickTrackFromGeneric(event)
      if (event.url) {
        this.$router.push(event.url)
      }
    },
    trackPromotionImpression(data) {
      const actionName = 'promoView'
      const containerKey = data['Content Container Key']
      const payload = {}
      payload[actionName] = {
        promotions: [
          {
            id: containerKey || data.scheduledContentData.map.key,
            name: data['Friendly Tracking Description'],
            position: data.Site_Location,
          },
        ],
      }
      this.$trackGtmEvent(GTM.promotionImpression, {
        ecommerce: payload,
      })
    },
    hideOverflowForBody(val) {
      if (val) {
        document.body.classList.add('hidden-overflow')
      } else {
        document.body.classList.remove('hidden-overflow')
      }
    },
    handleNewNavItemClicks(label) {
      const { scrollableHeader } = this.$refs
      if (label === 'Categories') {
        if (this.isSubNavMenuOpen) {
          this.setCloseSubNavMenu()
        }
        if (document.body.classList.contains(scrollDownBrowseAisles)) {
          scrollableHeader.style.transform = `translateY(0px)`
        }
        if (this.isCategoryMenuOpen) {
          this.setCloseCategoryMenu()
          this.userClosedCategoryMenu = true
        } else {
          this.userClosedCategoryMenu = false
          this.setOpenCategoryMenu()
        }
        this.trackNavUpdated({
          action: 'utility header',
          url: this.isCategoryMenuOpen ? 'expand categories' : 'collapse categories',
          label
        })
      } else if (label === 'Pharmacy & Wellness') {
        if (this.isAccountMenuOpen) {
          this.setCloseAccountMenu()
        }
        this.toggleSubNavMenu()
      } else if (label !== 'Categories' || label !== 'Pharmacy & Wellness') {
        this.setCloseSubNavMenu()
      }
    },
    handleNavItemClick(label, url, linkType, elementId) {
      this.trackNavClick(label, url, linkType, elementId)
      this.$emit('close-menu')
      if (MODAL_URL_MAPS[url]) {
        this.$store.commit('Modals/setActiveModal', {
          fileName: MODAL_URL_MAPS[url],
          elemIdThatOpenedModal: elementId,
        })
        return
      }
      if (this.isNewNavDesignEnabled || (this.optimizelyShowNewHeaderDesign && !this.isDesktop)) {
        this.handleNewNavItemClicks(label)
        this.handleGTMTagging(label, url)
      }
      if (typeof url === 'string' && linkType === 'Modal') {
        url = url.replace(/modal\/|\/modal\//, '')
      }
      if (linkType === 'Modal') {
        this.handleModalRouting(url, label)
        return
      }
      if (linkType !== 'External') {
        this.$router.push(url)
      }
    },
    handleModalRouting(url, label) {
      if (url === 'delivery-pickup-modal') {
        this.$store.commit('ServiceSelection/setCurrentView', 'T')
        this.$store.commit('Modals/setActiveModal', {
          fileName: 'PdlServiceSelectionModal',
        })
        return
      }
      if (url === 'create-account') {
        this.$router.push('/registration')
        return
      }
      if (label === 'My Account') {
        this.$router.push('/account')
        return
      }
      if (
        label === 'Give Us Feedback'
        || url === '/modal/user-feedback-modal'
        || url === 'user-feedback-modal'
      ) {
        this.$router.push('/contact/feedback')
        return
      }
      this.$store.dispatch('Modals/openModal', url)
    },
    handleGTMTagging(label, url) {
      if (label === 'Shopping List' && !this.isMobile) {
        this.trackNavUpdated({
          action: 'utility header',
          label,
          url
        })
      }
    },
    handleProductGroupClick(label, url, elementId) {
      this.$router.push(url)
      this.setGlobalHeaderCategory(label)
      optimizelyFullstack.trackEvent('Browse Aisles Categories Links')
      this.trackNavClick('Browse Aisles Menu', url, label, elementId)
    },
    scanBarcodeUrl(url) {
      this.$router.push(url)
    },
    async voiceSearch() {
      try {
        const voiceToText = await this.NativeContainer.capabilities.VoiceSearchService.voiceSearch()
        this.$router.push(`/product-search/${voiceToText}`)
      } catch (error) {
        if (
          !error.includes('Speech recognition failed')
          && error !== 'User has canceled search'
        ) {
          this.$store.commit('Alert/setAlert', {
            type: 'warning',
            header: 'Warning!',
            body: 'An error occurred while doing voice search.',
          })
        }
      }
    },
    openLoyaltyCard() {
      const cardNumber = this.$store.getters['LoyaltyAccount/cardNumber']
      const firstName = this.$store.getters['UserProfile/displayFirstName']
      const lastName = this.$store.getters['UserProfile/displayLastName']
      try {
        // check user status also guarantees session is valid
        const isGuestUser = this.$store.getters['LoginStatus/isGuestUserWithNoHandle']
        if (!isGuestUser) {
          this.NativeContainer.capabilities.LoyaltyService.openLoyaltyPage({
            cardNumber,
            firstName,
            lastName,
          })
        }
      } catch (e) {
        this.logNativeContainerError()
      }
    },
    handleSelectServiceClick() {
      this.$store.commit('ServiceSelection/setSuppressBrowseMode', true)
      this.$store.commit('Modals/setActiveModal', {
        fileName: 'PdlServiceSelectionModal',
      })
    },
    handleMenuCheckoutClick() {
      if (this.userStatus === 'G') {
        this.$router.push('/registration')
      } else {
        this.$store.commit('Modals/setActiveModal', {
          fileName: 'PdlCheckoutModal',
        })
      }
    },
    onChangeServiceType(serviceType, suppressBrowsingMode) {
      this.$store.commit('ServiceSelection/setCurrentView', 'Select')
      this.$store.commit('ServiceSelection/setShouldShowSlots', false)
      this.$store.commit(
        'ServiceSelection/setSuppressBrowseMode',
        suppressBrowsingMode
      )
      this.$store.commit('Modals/setActiveModal', {
        fileName: 'PdlServiceSelectionModal',
      })
    },
    onChangeTime() {
      this.$store.commit('ServiceSelection/setCurrentView', 'T')
      this.$store.commit('ShoppingMode/setServiceTypeBypass', false)
      this.$store.commit('Modals/setActiveModal', {
        fileName: 'PdlServiceSelectionModal',
      })
    },
    onMenuClose() {
      this.$nextTick(() => this.$refs.menuButton?.$el.focus())
    },
    onChangeAddress(serviceType) {
      if (this.isDeliveryAddressComplete && serviceType === 'D') {
        this.$router.push('/account/user/delivery-address')
        return
      }
      this.$store.commit('ServiceSelection/setCurrentView', serviceType)
      this.$store.commit('ServiceSelection/setShouldShowSlots', false)
      this.$store.commit('Modals/setActiveModal', {
        fileName: 'PdlServiceSelectionModal',
      })
    },
    handleShoppingModeChange() {
      const deliveryAddressModal = '/account/user/delivery-address'.replace(
        /modal\/|\/modal\//,
        ''
      )
      this.$store.dispatch('Modals/openModal', deliveryAddressModal)
    },
    trackNavClick(title, destination, location, elementId) {
      this.trackForOptimizely(title, destination, elementId)
      const locationIsObject = typeof location === 'object' && location !== null

      let gtmLocation = 'header'
      let ga4Type = 'utility header'
      let ga4SiteLocation = `${generateSiteLocation(this.$route)}`

      if (locationIsObject) {
        gtmLocation = location.gtm || gtmLocation
        ga4Type = location.ga4 || ga4Type
        ga4SiteLocation = location.ga4SiteLocation || ga4SiteLocation
      } else if (location) {
        gtmLocation = location
        ga4Type = location
      }

      if (elementId?.includes('nav-account-menu')) {
        ga4SiteLocation = 'Header - User Menu'
        ga4Type = 'user menu'
      }

      trackNavigationGtm({
        navLocation: gtmLocation,
        navElementText: `${title}`,
        navURL: `${destination}`,
      })

      if (title !== 'Browse Aisles Menu') {
        trackNavigation({
          type: ga4Type,
          linkText: `${title}`,
          linkUrl: `${destination}`,
          elementId: `${elementId}`,
          siteLocation: ga4SiteLocation
        })
      }
    },
    handleError({ body }) {
      this.setAlert({
        icon: true,
        type: 'error',
        header: 'Error',
        body,
        primary: {
          text: 'Ok',
        },
      })
    },
    async handleExternalItemClick(item) {
      try {
        await handleExternalItemClick({
          item,
          store: this.$store,
        })
      } catch (e) {
        this.logNativeContainerError()
        this.handleError({
          body: 'An error occurred while opening external link.',
        })
      }
    },
    trackPrimaryNavClick() {
      optimizelyFullstack.trackEvent('Primary Navigation')
    },
    trackForOptimizely(label, url) {
      switch (label) {
        case 'Shopping List':
          optimizelyFullstack.trackEvent('Navigation - Shopping List')
          if (!this.isMobile) {
            this.trackNavUpdated({
              action: 'utility header',
              label,
              url
            })
          }
          break
        case 'My Account':
          optimizelyFullstack.trackEvent('My Account')
          break
        case 'Sign In':
          optimizelyFullstack.trackEvent('Sign In-Navigation-Link')
          break
        case 'Create Account':
          optimizelyFullstack.trackEvent('Create Account-Navigation-Link')
          break
        case 'Notifications':
          optimizelyFullstack.trackEvent('Notifications-Navigation-Link')
          break
        case 'Refer-a-friend':
          optimizelyFullstack.trackEvent('Refer A Friend-Navigation-Link')
          break
        case 'Give Us Feedback':
          optimizelyFullstack.trackEvent('Feedback-Navigation-Link')
          break
        case 'Contact Us':
          optimizelyFullstack.trackEvent('Contact Us-Navigation-Link')
          break
        case 'Past Purchases':
          optimizelyFullstack.trackEvent('Past Purchases Pageview')
          break
        case 'Recommended For You':
          optimizelyFullstack.trackEvent('For You Pageview')
          break
        default:
          break
      }
    },
    async getOptimizelyData() {
      const isBannerXFeatureFlagsEnabled = await optimizelyFullstack.isFeatureEnabled('banner_x_search_dropdown')
      const isBannerXEnabledForSearchDropdown = await optimizelyFullstack.getFeatureVariableBoolean(
        'banner_x_search_dropdown', 'bannerx_searchdropdown'
      )
      this.$store.commit(
        'Optimizely/setShowBannerXAtSearchDropdown', isBannerXFeatureFlagsEnabled && isBannerXEnabledForSearchDropdown
      )
    },
    handleNativeRedirect(path) {
      const item = {
        url: path,
      }
      this.handleExternalItemClick(item)
    },
    trackNavUpdated(data) {
      if (this.isNewNavDesignEnabled) {
        if (data.label === 'Pharmacy & Wellness') {
          trackNavigation({
            type: 'utility header',
            label: this.isSubNavMenuOpen ? 'expand pharmacy' : 'collapse pharmacy',
            index: data.index,
            linkId: data.elementId,
            siteLocation: `${generateSiteLocation(this.$route)}`
          })
          trackNavigationUpdated({
            action: 'primary header',
            url: this.isSubNavMenuOpen ? 'expand pharmacy' : 'collapse pharmacy',
            label: data.label,
            index: data.index,
          })
        } else {
          trackNavigation({
            ...data,
            type: 'utility header',
            linkId: data.elementId,
            index: data.index,
            siteLocation: `${generateSiteLocation(this.$route)}`
          })
          trackNavigationUpdated(data)
        }
      }
    }
  }
}
</script>
