import ApiService from '@/api/'

export default {
  async getSavingsHistory(params) {
    const {
      /* eslint-disable max-len */
      filterSelection, size, start, cardNumber
    } = params
    // GET /<loyalty_service>/v1/history/savings/{opco}/{cardNumber}?type={filterSelected}&start={start}&size={size}&type={filter}
    return ApiService.get(`/apis/rewards-history/v1/history/savings/${cardNumber}?start=${start}&size=${size}&type=${filterSelection}`)
  },
}
