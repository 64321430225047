import { GTM } from 'utils/constants'

/**
 * @param {{
 *       digitalCouponsSavings: number,
 *       promotionsSavings: number,
 *       weeklySpecialSavings: number,
 *       totalSavings: number,
 *  }} SortIntent
 * @returns {void}
 */
export function trackViewSavings(
  {
    digitalCouponsSavings, promotionsSavings, weeklySpecialSavings, totalSavings
  }
) {
  window.sharedVue.config.globalProperties.$trackGtmEvent(GTM.viewSavings, {
    digitalCouponsSavings,
    promotionsSavings,
    weeklySpecialSavings,
    totalSavings
  })
}
