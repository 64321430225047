import ApiService from '@/api/'

export default {
  getFullOrderStatus(userId, routeService = false) {
    return ApiService.get(`/api/v6.0/user/${userId}/order/status`, { params: { routeService } })
  },
  getFulfillmentOrderStatus(userId, orderId) {
    return ApiService.get(`/apis/fulfillment/${userId}/order/v1/details/${orderId}`)
  },
  postSubstitutionPreference(userId, orderId, payload) {
    return ApiService.post(`/apis/fulfillment/${userId}/order/v1/${orderId}/rejectedSubs`, payload)
  },
  getPickupStatus(orderId, userId) {
    const payload = {
      fields: [
        'pickupStatus'
      ]
    }
    return ApiService.post(`/apis/fulfillment/${userId}/order/v1/pickup-status/${orderId}`, payload)
  }
}
