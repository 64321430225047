import SuperUserApi from 'api/SuperUserApi'


export function logoutSuperUser(rejection) {
  if (!rejection?.response?.data?.response) {
    return Promise.reject(rejection)
  }

  if ((rejection.response.status === 409 && rejection.response.data.response.code === 'SESSION_LOCKED')
    || (rejection.response.status === 409 && rejection.response.data.response.code === 'SESSION_ABORTED')
    || (rejection.response.status === 409 && rejection.response.data.response.code === 'SESSION_EXPIRED')
    || rejection.response.status === 401
    || rejection.response.status === 403) {
    SuperUserApi.logoutSuperuser()
  }

  return Promise.reject(rejection)
}
