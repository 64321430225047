<!-- eslint-disable max-len !-->
<template>
  <g
    stroke="none"
    stroke-width="1"
    fill="none"
    fill-rule="evenodd"
  >
    <g
      transform="translate(5.000000, 0.500000)"
      fill-rule="nonzero"
    >
      <path
        d="M21.5063291,14.3225698 C21.5063291,13.8159572 21.0955472,13.4052365 20.5889957,13.4052365 C20.082322,13.4052365 19.6716624,13.8159572 19.6716624,14.3225698 C19.6716624,19.234584 15.6753916,23.2308548 10.7633775,23.2308548 C5.85136341,23.2308548 1.85509259,19.234584 1.85509259,14.3225698 C1.85509259,13.8159572 1.44431072,13.4052365 0.937759268,13.4052365 C0.4310855,13.4052365 0.020425955,13.8159572 0.020425955,14.3225698 C0.020425955,19.9372 4.35017803,24.55897 9.84604421,25.0260149 L9.84604421,29.1262501 L7.81574098,29.1262501 C7.30906722,29.1262501 6.89840766,29.5369708 6.89840766,30.0435835 C6.89840766,30.5501961 7.30906722,30.9609168 7.81574098,30.9609168 L13.7111364,30.9609168 C14.2176879,30.9609168 14.6284697,30.5501961 14.6284697,30.0435835 C14.6284697,29.5369708 14.2176879,29.1262501 13.7111364,29.1262501 L11.6807108,29.1262501 L11.6807108,25.0259538 C17.176577,24.5589088 21.5063291,19.9371388 21.5063291,14.3225698 Z"
        stroke="#979797"
        stroke-width="0.888888889"
        fill="#979797"
      />
      <path
        d="M10.7633775,-3.00006933e-14 C8.09045173,-3.00006933e-14 5.91582137,2.17463035 5.91582137,4.84755616 L5.91582137,14.6732967 C5.91582137,17.3462225 8.09045173,19.5207916 10.7633775,19.5207916 C13.4364257,19.5207916 15.611056,17.3461614 15.611056,14.6732356 L15.611056,4.84755616 C15.611056,2.17463035 13.4364257,-3.00006933e-14 10.7633775,-3.00006933e-14 Z"
        fill="#9B9B9B"
      />
    </g>
  </g>
</template>
