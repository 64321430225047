<template>
  <div class="sidebar-tabs_wrapper">
    <div
      class="sidebar-tabs_left"
    >
      <ul
        class="sidebar-tabs_list"
        role="tablist"
      >
        <slot
          name="tab-headers"
        />
      </ul>
    </div>

    <div class="tabs-details sidebar-tabs_right">
      <slot name="tab-content" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabHeaders: {
      type: Array,
      default: () => [],
    },
    tabContent: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isTabSelected: false
    }
  },
  methods: {
    selectTab(selectedTab) {
      this.tabHeaders.forEach((tabHeader) => {
        tabHeader.isActive = tabHeader.name === selectedTab.name
      })

      this.isTabSelected = true
      this.tabContent.forEach((content) => {
        content.isActive = content.headerId === selectedTab.id
        if (content.headerId === selectedTab.id) {
          this.emitTabChange(selectedTab)
        }
      })
    },
    emitTabChange(tab) {
      this.$emit('tab-change', tab)
    }
  }
}
</script>
