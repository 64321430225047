import DeferredSingleActionService from 'utils/interceptors/services/deferred-single-action'
import { createOrderAlert } from 'utils/services/order/orderAlert'
/**
 * This interceptor handles logs invalid API content
 * It checks for session aborted or session locked for when the same user logs in different devices
 * It checks for 205 for when we select a service type and reloads the page (please fix in future to not do this)
 *
*
*/
const CF_CAPTCHA_REGEX = /^.*cf\.challenge\.js.*$/m
const CF_ERROR_REGEX = /^.*[Cc]loud[Ff]lare.*$/m
const CF_MAINTENACE_REGEX = /^.*maintenance.*$/m


const isValidResponse = (response) => {
  // We don't validate other types of content currently (i.e. HTML or HTML template response is valid response)
  // All our APIs should return JSON response when it is working properly
  return response.config.url.includes('api')
}

const isCloudFlareResponse = (response) => {
  return CF_ERROR_REGEX.test(response) || CF_CAPTCHA_REGEX.test(response) || CF_MAINTENACE_REGEX.test(response)
}
const checkAndCreateOrderAlert = () => {
  const isLoggedIn = window.sharedVue.config.globalProperties.$store.getters['LoginStatus/userStatus'] !== 'G'
  const wasViewed = window.sharedVue.config.globalProperties.$store.getters['Order/orderNoticeViewed']
  if (isLoggedIn && !wasViewed) {
    createOrderAlert()
    window.sharedVue.config.globalProperties.$store.commit('Order/setOrderNoticeViewed', true)
  }
}

const logInvalidContent = (type) => {
  window.sharedVue.config.globalProperties.$trackClientLog('invalid_content', {
    response: type.data,
    url: type.config.url,
    status: type.status
  })
}

const setCFLock = () => {
  DeferredSingleActionService.setCFLockoutCallback()
  DeferredSingleActionService.setLock(true)
  DeferredSingleActionService.execute()
}


export function pendingRejection(rejection) {
  if (window.initPhase.flag || !rejection?.response?.status) {
    return Promise.reject(rejection)
  }
  if (rejection?.response?.status === 413) {
    return Promise.resolve()
  }

  if (rejection.data
    && (!isValidResponse(rejection) || rejection?.response?.status >= 429)
    && !rejection.config.url.includes('log')) {
    logInvalidContent(rejection)
    if (isCloudFlareResponse(rejection.data)) {
      setCFLock()
    }
  }

  if (rejection.response.status === 409 && rejection.response.data.response.code === 'SESSION_LOCKED') {
    DeferredSingleActionService.setSessLockout()
  }

  return Promise.reject(rejection)
}

export function pendingResponse(response) {
  if (window.initPhase.flag) {
    return Promise.resolve(response)
  }
  // used to check for cut off order times alerting the user
  const data = response?.data || {}
  if (window.sharedVue) {
    window.sharedVue.config.globalProperties.$store.dispatch('CutOff/processCutoffAlertResponse', data)
  }
  if (data.alerts) {
    checkAndCreateOrderAlert()
  }

  if (!isValidResponse(response)
    && !response.config.url.includes('log')
  && !DeferredSingleActionService.isLocked()) {
    logInvalidContent(response)

    if (isCloudFlareResponse(response.data)) {
      setCFLock()
    }
  }

  return Promise.resolve(response)
}
