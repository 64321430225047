import locationsByQuery from 'api/locationsByQueryApi'
import { STORE_LOCATOR_FILTERS } from 'utils/constants'

function initialState() {
  return {
    activeResultIndex: undefined,
    error: false,
    loading: false,
    searchQuery: '',
    stores: [],
    locatorFilters: [...STORE_LOCATOR_FILTERS],
    // updated during every search for user display
    appliedFilters: []
  }
}

export default {
  namespaced: true,
  state: initialState,
  getters: {
    activeResultIndex: state => state.activeResultIndex,
    error: state => state.error,
    loading: state => state.loading,
    searchQuery: state => state.searchQuery,
    stores: state => state.stores,
    locatorFilters: state => state.locatorFilters,
    appliedFilters: state => state.appliedFilters
  },
  mutations: {
    setActiveResultIndex(state, payload) {
      state.activeResultIndex = payload
    },
    setError(state, payload) {
      state.error = payload
    },
    setFilters(state, payload) {
      state.locatorFilters = payload
    },
    setFilterByIndex(state, payload) {
      if (payload.index < 0) {
        return
      }
      Object.keys(payload.filter).forEach((key) => {
        // specific key overwrite for checked
        const keyName = key === 'checked' ? 'active' : key
        state.locatorFilters[payload.index][keyName] = payload.filter[key]
      })
    },
    insertFilter(state, payload) {
      state.locatorFilters.push(payload)
    },
    setAppliedFilters(state, payload) {
      state.appliedFilters = payload
    },
    setLoading(state, payload) {
      state.loading = payload
    },
    setSearchQuery(state, payload) {
      state.searchQuery = payload
    },
    setStores(state, payload) {
      state.stores = payload
    },
    clearFilters(state) {
      state.locatorFilters.map((filterOption) => {
        filterOption.active = false
        return filterOption
      })
    },
    clearResultIndex(state) {
      state.activeResultIndex = undefined
    },
    resetResults(state) {
      state.activeResultIndex = undefined
      state.error = false
      state.stores = []
    },
    reset(state) {
      const initState = initialState()
      Object.keys(initState).forEach((key) => {
        if (key !== 'locatorFilters') {
          state[key] = initState[key]
        }
      })
      window.sessionStorage.removeItem('store_locator_query')
      window.sessionStorage.removeItem('store_locator_filters')
    },
  },
  actions: {
    updateActiveResultIndex({ commit }, payload) {
      commit('setActiveResultIndex', payload)
    },
    clearActiveResultIndex({ commit }) {
      commit('clearResultIndex')
    },
    clearActiveFilters({ commit }) {
      commit('clearFilters')
    },
    resetApp({ commit }) {
      commit('reset')
      commit('clearFilters')
    },
    updateFilterByValue({ state, commit }, config) {
      const filterIndex = state.locatorFilters.findIndex(filter => filter.value === config.value)
      commit('setFilterByIndex', {
        index: filterIndex,
        filter: config
      })
    },
    async getStoresByQuery({ commit, state, rootState }, { query = undefined, reset = true } = {}) {
      const { opco } = rootState.SiteConfig
      commit('setLoading', true)

      if (query) {
        commit('setSearchQuery', query)
      }

      if (reset) {
        commit('resetResults')
      } else {
        commit('setError', false)
        commit('clearResultIndex')
      }

      try {
        const { searchQuery, locatorFilters } = state

        const activeFilters = locatorFilters.filter(filter => filter.active)
        commit('setAppliedFilters', activeFilters)
        // extract active filter values for query
        const filters = activeFilters.map(filter => filter.value)
        const response = await locationsByQuery({
          opco,
          query: searchQuery,
          type: 'GROCERY',
          filters
        })
        window.sessionStorage.setItem('store_locator_query', searchQuery)
        window.sessionStorage.setItem('store_locator_filters', JSON.stringify(filters))
        commit('setStores', response.data.stores)
        commit('setLoading', false)
      } catch (err) {
        commit('setStores', [])
        commit('setLoading', false)
        commit('setError', true)
      }
    },
  }
}
