<template>
  <button
    :id="id"
    type="submit"
    class="button"
    :class="classList"
    :disabled="isDisabled"
    @click="onClick($event)"
    @keydown.tab="keydownTab"
  >
    <PdlLoadingSpinner
      v-if="isLoading"
      :inline="false"
      ada-message="Processing Request"
    />
    <slot
      v-if="!isLoading"
      name="beforeLabel"
    />
    {{ label }}
    <slot
      v-if="!isLoading"
      name="afterLabel"
    />
  </button>
</template>

<script>
import PdlLoadingSpinner from '@/shared/interactions/PdlLoadingSpinner'

export default {
  components: {
    PdlLoadingSpinner
  },
  emits: ['click', 'keydown-tab'],
  props: {
    id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    buttonColor: {
      type: String,
      default: 'prime'
    },
    classes: {
      type: Array,
      default() {
        return []
      }
    },
    isLoading: {
      type: Boolean,
      required: true
    },
    isDisabled: {
      type: Boolean,
      required: true
    },
    ignorePrevent: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    classList() {
      return [
        `button--${this.buttonColor}`,
        { loading: this.isLoading },
        { 'button--disabled': this.isDisabled },
        ...this.classes
      ]
    }
  },
  methods: {
    onClick($event) {
      if (!this.ignorePrevent) {
        $event.preventDefault()
      }
      if (this.isLoading) return
      this.$emit('click', $event)
    },
    keydownTab() {
      this.$emit('keydown-tab')
    }
  }
}
</script>
