import { GTM_GA4, GHOST_USER_ID } from 'utils/constants'
import { trackStoreDetails } from 'utils/tracking/trackStoreDetails'

export function trackUserProperties() {
  const vueInstance = window.sharedVue.config.globalProperties
  const userId = vueInstance.$store.getters['UserProfile/userId']
  const opco = vueInstance.$store.getters['SiteConfig/opco']
  const storeIsSpectrum = vueInstance.$store.getters['FoodLionTransition/storeIsSpectrum']
  const transitionState = vueInstance.$store.getters['FoodLionTransition/transitionState']
  const ecommStatus = opco === 'FDLN' ? `ecomm ${storeIsSpectrum} - ${transitionState}` : ''
  const storeNumber = vueInstance.$store.getters['UserProfile/storeNumber']
  const isLoggedIn = vueInstance.$store.getters['LoginStatus/isLoggedIn']
  const userType = vueInstance.$store.getters['UserProfile/userInfo']?.information?.userType
  trackStoreDetails({
    storeCheck: opco === 'FDLN' ? `fdln ecommerce: ${storeIsSpectrum}` : '',
    storeType: opco === 'FDLN' ? transitionState : '',
    storeNumber,
    userId,
    ecommStatus,
    userType: isLoggedIn ? userType : 'null'
  })

  const cardNumber = vueInstance.$store.getters['UserProfile/retailerCardNumber']
  const loyaltyId = !cardNumber ? null : cardNumber
  if (userId === GHOST_USER_ID || loyaltyId === 777) {
    return
  }
  const orderQty = window.appConfig.timeOrdQy
  if (userId && orderQty !== null) {
    const ga4TagData = {
      user_id: userId,
      loyalty_id: loyaltyId,
      number_of_orders: orderQty,
      user_store: storeNumber
    }
    vueInstance.$trackGA4Event(GTM_GA4.userProperties, ga4TagData)
  }
}
