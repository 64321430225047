<template>
  <div
    v-if="count > 0"
    :class="[
      getClass,
      {'overflow-hidden': isHeroBannerCarousel}
    ]"
  >
    <h2
      v-if="screenReaderHeading"
      class="sr-only"
    >
      {{ screenReaderHeading }}
    </h2>
    <div @touchstart="handleTouchStart"
      v-touch:swipe="(event) => onSwipe(event)"
      class="pdl-carousel_row"
      tabindex="0"
      @keydown="handleKeyEvents"
    >
      <div
        class="pdl-carousel_body"
      >
        <div
          id="carousel-buttons"
          :class="{'absolute h-full max-w-[1920px] inset-x-0 mx-auto':isHeroBannerLargeDesktopView}"
        >
          <button
            v-if="showButton"
            v-show="!isMin || ((isKwmCarousel || isHeroBannerCarousel) && count > 1)"
            class="button--reset pdl-carousel_trigger"
            :class="{
              'pdl-carousel_trigger--prev': !isHeroBannerCarousel,
              'pdl-carousel_trigger--theme-2': withBackground,
              'border-0 opacity-100': isHeroBannerCarousel || isMobileView,
              'left-12': isHeroBannerDesktopView,
              'left-[30px]': isHeroBannerLargeDesktopView,
              'left-[10%] top-[23%]': isHeroBannerCarousel && !isDesktop,
              'left-[5%] top-[30%]': isHeroBannerCarousel && isMobileView
            }"
            aria-label="Previous slide"
            @click="goPrevious"
          >
            <PdlIconBase
              v-if="!isHeroBannerCarousel"
              icon-title="Previous"
              :stroke-color="isKwmCarousel && !isTabletView ? 'gray' : 'black'"
              :container-class="[
                'pdl-carousel_trigger-icon',
                { 'p-0': isKwmCarousel && !isTabletView }
              ]"
            >
              <PdlIconCarrotLeft />
            </PdlIconBase>
            <PdlIconBase
              v-if="isHeroBannerCarousel"
              icon-title="Previous"
              :vb-height="30"
              :vb-width="30"
              :icon-class="'vector-icon-stroke--prime vector-icon-stroke-hover--white'"
              :container-class="[
                'h-[4rem] w-[4rem] border-2 border-solid border-primary bg-white rounded-full hover:bg-primary'
              ]"
            >
              <PdlIconArrowLeftThin />
            </PdlIconBase>
          </button>
          <button
            v-if="showButton"
            v-show="!isMax || ((isKwmCarousel || isHeroBannerCarousel) && count > 1)"
            aria-label="Next slide"
            class="button--reset pdl-carousel_trigger"
            :class="{
              'pdl-carousel_trigger--theme-2': withBackground,
              'pdl-carousel_trigger--next': !isHeroBannerCarousel,
              'border-0 opacity-100': isHeroBannerCarousel || isMobileView,
              'right-12': isHeroBannerDesktopView,
              'right-[30px]': isHeroBannerLargeDesktopView,
              'right-[10%] top-[23%]': isHeroBannerCarousel && !isDesktop,
              'right-[5%] top-[30%]': isHeroBannerCarousel && isMobileView
            }"
            @click="goNext"
          >
            <PdlIconBase
              v-if="!isHeroBannerCarousel"
              icon-title="Next"
              :stroke-color="isKwmCarousel && !isTabletView ? 'gray' : 'black'"
              :container-class="[
                'pdl-carousel_trigger-icon',
                { 'p-0': isKwmCarousel && !isTabletView }
              ]"
            >
              <PdlIconCarrotRight />
            </PdlIconBase>
            <PdlIconBase
              v-if="isHeroBannerCarousel"
              icon-title="Next"
              :vb-height="30"
              :vb-width="30"
              :icon-class="'vector-icon-stroke--prime vector-icon-stroke-hover--white'"
              :container-class="[
                'h-[4rem] w-[4rem] border-2 border-solid border-primary bg-white rounded-full hover:bg-primary'
              ]"
            >
              <PdlIconArrowRightThin />
            </PdlIconBase>
          </button>
        </div>
        <div
          :style="minHeightStyle"
          :class="{
            'pdl-carousel_container--mobile': useMobileView,
            'mt-4': isBrowseAislesPage
          }"
          class="pdl-carousel_container"
        >
          <div
            :ref="`CarouselSlider`"
            :style="carouselStyle"
            :class="{'pdl-carousel_slider--mobile': useMobileView}"
            class="pdl-carousel_slider"
          >
            <div
              v-for="(data, index) in items"
              :key="index"
              :ref="`CarouselItem${index}`"
              tabindex="-1"
              :style="slideStyle"
              :class="[
                'pdl-carousel_item',
                getConfigClass('carousel-item'),
                carouselItemClass,
                {
                  'visibility-hidden no-height' : !isActive(index)
                    && (!hasBottomArrows && (isKwmCarousel && isMobileView))
                },
              ]"
            >
              <slot
                name="item-content"
                v-bind="data"
                :view-size="viewSize"
                :style="heightStyle"
                class="pdl-carousel_content"
                :is-last-slide="isLastSlide(index, items)"
                :item-count-prior-slide="findPriorSlide(index, items)"
                :index="index"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="!useMobileView && showBottomNavigation && !hasOneSlide"
      class="pdl-carousel_row pdl-carousel_nav-items"
      :class="{
        'absolute justify-center mt-10 mb-20 top-0 pdl-carousel_row' : isHeroBannerCarousel && isMobileView,
        'pdl-carousel_row pdl-carousel_nav-items--bottom-arrows':
          hasBottomArrows && !isHeroBannerCarousel && !isMobileView,
        'justify-between px-5 mt-[-3.3rem]': isKwmCarousel && !isDesktop,
        '-mt-10 z-10 mb-10': isKwmCarousel,
        'absolute justify-start top-[8%] z-10': isHeroBannerCarousel && !hasBottomArrows && !isXXLargeDesktop,
        'absolute justify-center top-[3.5%] z-10': isHeroBannerCarousel && hasBottomArrows,
        'absolute justify-start -top-[55rem] z-10': isHeroBannerCarousel && !hasBottomArrows && isXXLargeDesktop,
        'left-40 w-auto': isHeroBannerDesktopView && !isXXLargeDesktop
      }"
      :style="heroBannerNavStyle"
    >
      <button
        v-if="hasBottomArrows && !hideSideButtons && !isHeroBannerCarousel"
        :class="isKwmCarousel && !isDesktop
          ? 'pdl-carousel_bottom-trigger-kwm-mobile outline-0' : 'pdl-carousel_bottom-trigger'"
        aria-label="Previous slide"
        @click="goPrevious"
      >
        <div
          :class="{'block px-8 py-12 -translate-y-10' : isKwmCarousel && !isDesktop}"
        >
          <PdlIconBase
            icon-title="Previous"
            stroke-color="white"
            :icon-class="{'vector-icon-stroke--darkest-grey' : isKwmCarousel && !isDesktop }"
            :container-class="[
              'pdl-carousel_bottom-trigger-icon'
            ]"
          >
            <PdlIconCarrotLeft />
          </PdlIconBase>
        </div>
      </button>
      <div
        v-if="isHeroBannerCarousel"
        :class="['h-[2.7rem] w-[2.7rem]', {
          'pl-0': isHeroBannerDesktopView
        }
        ]"
      >
        <button
          :class="['rounded-full w-[2.5rem] h-[2.5rem] z-0', {
            'bg-white' : !isHeroBannerProductAndNonDesktopDesign
          }]"
          :aria-label="heroCarouselButtonText"
          @click="pauseHeroBannerOnClick"
        >
          <PdlIconPlayPauseButton
            :manual-scroll-reset="manualScrollReset"
            :hero-banner-paused="heroBannerPaused"
            :is-hero-banner-product-and-non-desktop-design="isHeroBannerProductAndNonDesktopDesign"
          />
        </button>
      </div>
      <ul
        class="pdl-carousel_nav"
        :class="{
          'mb-[-9rem] lg:mb-0': isKwmCarousel,
          'p-0': isHeroBannerCarousel && !isXXLargeDesktop,
          'pdl-carousel--slider' : isCarouselSlider
        }"
      >
        <li
          v-for="(item, index) in navItems"
          :key="index"
          class="pdl-carousel_nav-item"
          :class="{
            'flex items-center justify-center h-8 w-8 border-2 rounded-full border-dotted border-primary p-1'
              : isActive(index) && isHeroBannerCarousel && isDesktop,
            'flex items-center justify-center h-4 w-4 border rounded-full border-solid border-white p-[0.1rem]'
              : isActive(index) && isHeroBannerProductAndNonDesktopDesign,
            'flex items-center justify-center h-4 w-4 border rounded-full border-primary p-[0.1rem]'
              : isActive(index) && isHeroBannerLifeStyleAndNonDesktopDesign,
          }"
        >
          <button
            :aria-label="`go to slide ${index + 1}`"
            class="button--reset pdl-carousel_nav-trigger"
            :class="{
              'pdl-carousel_nav-trigger--active': isActive(index) && !isHeroBannerCarousel,
              'pdl-carousel_nav-trigger--theme-2': withBackground,
              'pdl-carousel_nav-trigger--kwm-desktop-tablet': isKwmCarousel && !isMobileView,
              'pdl-carousel_nav-trigger--kwm-mobile': isKwmCarousel && !isDesktop,
              'h-[7px] w-[7px] bg-gray-900 border-0': !isActive(index) & isHeroBannerCarousel && isDesktop,
              'h-[10px] w-[10px] border-0 bg-primary': isActive(index) && isHeroBannerCarousel && isDesktop,
              'h-[5.5px] w-[5.5px] bg-gray-50 border-0 opacity-30 hover:bg-gray-50'
                : !isActive(index) & isHeroBannerProductAndNonDesktopDesign,
              'h-[6px] w-[6px] border-0 bg-white hover:bg-white'
                : isActive(index) && isHeroBannerProductAndNonDesktopDesign,
              'h-[6px] w-[6px] border-0 bg-primary hover:bg-primary'
                : isActive(index) && isHeroBannerLifeStyleAndNonDesktopDesign,
              'h-[5.5px] w-[5.5px] bg-gray-900 border-0 hover:bg-gray-900'
                : !isActive(index) && isHeroBannerLifeStyleAndNonDesktopDesign,
              'rounded-none': isCarouselSlider
            }"
            @click="goTo(index, true)"
          />
        </li>
      </ul>
      <button
        v-if="hasBottomArrows && !hideSideButtons && !isHeroBannerCarousel || isKwmCarousel && !isDesktop"
        aria-label="Next slide"
        :class="isKwmCarousel && !isDesktop
          ? 'pdl-carousel_bottom-trigger-kwm-mobile outline-0' : 'pdl-carousel_bottom-trigger'"
        @click="goNext"
      >
        <div
          :class="{'block px-8 py-12 -translate-y-10' : isKwmCarousel && !isDesktop}"
        >
          <PdlIconBase
            icon-title="Next"
            stroke-color="white"
            :icon-class="{'vector-icon-stroke--darkest-grey' : isKwmCarousel && !isDesktop }"
            :container-class="[
              'pdl-carousel_bottom-trigger-icon'
            ]"
          >
            <PdlIconCarrotRight />
          </PdlIconBase>
        </div>
      </button>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import {
  PdlIconBase,
} from '@/shared/icons'
import PdlIconPlayPauseButton from 'components/icons/icon/PdlIconPlayPauseButton'
import PdlIconArrowLeftThin from 'components/icons/icon/PdlIconArrowLeftThin'
import PdlIconArrowRightThin from 'components/icons/icon/PdlIconArrowRightThin'
import PdlIconCarrotLeft from 'components/icons/icon/PdlIconCarrotLeft'
import PdlIconCarrotRight from 'components/icons/icon/PdlIconCarrotRight'
import detectIE from 'utils/filters/detectIE'

const CAROUSEL_MAX_WIDTH_PX = 1240
const MAX_ICON_WIDTH_PX = 50 * 2

export default {
  components: {
    PdlIconBase,
    PdlIconCarrotLeft,
    PdlIconCarrotRight,
    PdlIconArrowLeftThin,
    PdlIconArrowRightThin,
    PdlIconPlayPauseButton
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    height: {
      type: [String, Number],
      default: 'auto'
    },
    navMax: {
      type: Number,
      default: 5
    },
    withBackground: {
      type: Boolean,
      default: false
    },
    styleClass: {
      type: String,
      default: ''
    },
    isCarouselSlider: {
      type: Boolean,
      default: false
    },
    hasBottomArrows: {
      type: Boolean,
      default: false
    },
    hasChevronButton: {
      type: Boolean,
      default: true
    },
    showBottomNavigation: {
      type: Boolean,
      default: true
    },
    hideSideButtons: {
      type: Boolean,
      default: false,
    },
    viewSize: {
      type: String,
      required: true
    },
    width: {
      type: Number,
      required: true
    },
    slidesContainTiles: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    isKwmCarousel: {
      type: Boolean,
      default: false
    },
    isHeroBannerCarousel: {
      type: Boolean,
      default: false
    },
    carouselItemClass: {
      type: String,
      default: ''
    },
    pauseHeroBanner: {
      type: Boolean,
      default: false
    },
    screenReaderHeading: {
      type: String,
      default: '',
    }
  },
  data: () => ({
    selected: 0,
    sliderElement: undefined,
    className: 'pdl-carousel',
    viewSizeDelay: 20, // Wait for dom styles update
    selectedElement: undefined,
    carouselStyle: '',
    isHeroBannerLifeStyle: false,
    autoScrollInterval: null,
    heroBannerPaused: false,
    restartFromPauseButton: false,
    manualScrollReset: false,
    isKeyboardEvent: false,
    touchStartX: 0,
    touchStartY: 0
  }),
  computed: {
    ...mapGetters({
      isDesktop: 'ScreenSize/isDesktop',
      isXXLargeDesktop: 'ScreenSize/isXXLargeDesktop'
    }),
    isMobileView() {
      return ['xsmall', 'small'].includes(this.viewSize)
    },
    isTabletView() {
      return ['medium', 'large'].includes(this.viewSize)
    },
    useMobileView() {
      return this.isMobileView && !this.hasBottomArrows
    },
    isBrowseAislesPage() {
      return this.$route?.path?.includes('browse-aisles')
    },
    isHomePage() {
      return this.currentUrl === '/' || this.currentUrl === '/home' || this.currentUrl === '/virtual-assistant'
    },
    count() {
      return this.items.length
    },
    max() {
      return this.count - 1
    },
    isMax() {
      return this.selected >= this.max
    },
    isMin() {
      return this.selected < 1
    },
    navItems() {
      return (this.items.length > this.navMax) ? this.items.slice(0, this.navMax) : this.items
    },
    slideStyle() {
      const style = {
        height: this.getHeightStyle()
      }
      if (this.fullWidth) {
        style.width = `${this.width}px`
        return style
      }
      const currentWidth = Math.min(this.width, CAROUSEL_MAX_WIDTH_PX)
      style.width = `${currentWidth}px`
      return style
    },
    getClass() {
      if (this.isKwmCarousel && !this.isTabletView) {
        return [
          this.className,
          `${this.className}--kwm-trigger`
        ]
      }
      if (this.width >= (CAROUSEL_MAX_WIDTH_PX + MAX_ICON_WIDTH_PX)) {
        return [
          this.className,
          `${this.className}--max`
        ]
      }

      return [
        this.className,
        `${this.className}--${this.viewSize}`
      ]
    },
    heightStyle() {
      return {
        height: this.getHeightStyle()
      }
    },
    minHeightStyle() {
      return {
        'min-height': this.getHeightStyle()
      }
    },
    sliderPositionStyle() {
      if (this.height === 'auto') {
        return 'relative'
      }
      return ''
    },
    hasOneSlide() {
      return this.items.length === 1
    },
    isHeroBannerDesktopView() {
      return this.isHeroBannerCarousel && this.isDesktop && !this.isXXLargeDesktop
    },
    isHeroBannerLargeDesktopView() {
      return this.isHeroBannerCarousel && this.isXXLargeDesktop
    },
    isHeroBannerProductAndDesktopDesign() {
      return this.isDesktop && !this.isHeroBannerLifeStyle
    },
    isHeroBannerProductAndNonDesktopDesign() {
      return this.isHeroBannerCarousel && !this.isDesktop && !this.isHeroBannerLifeStyle
    },
    isHeroBannerLifeStyleAndDesktopDesign() {
      return this.isDesktop && this.isHeroBannerLifeStyle
    },
    isHeroBannerLifeStyleAndNonDesktopDesign() {
      return !this.isDesktop && this.isHeroBannerLifeStyle
    },
    heroBannerNavStyle() {
      if (this.isHeroBannerDesktopView) {
        return 'padding-left: 2px;'
      }
      return ''
    },
    heroCarouselButtonText() {
      return this.heroBannerPaused ? 'Play' : 'Pause'
    },
    showButton() {
      return (this.isMobileView && this.isKeyboardEvent) || (!this.isMobileView
        && ((!this.hasBottomArrows && this.hasChevronButton) || this.isHeroBannerCarousel || this.isCarouselSlider))
    }
  },
  watch: {
    width() {
      this.updateCarousel()
    },
    items: {
      handler() {
        this.updateCarousel()
      },
      deep: true,
    },
    pauseHeroBanner() {
      if (this.pauseHeroBanner) {
        this.pauseTimer()
        this.clearAutoScrollForHeroBanners()
      }
      if (!this.pauseHeroBanner) {
        this.restartScrollForHeroBanners(this.timeLeft)
      }
    }
  },
  created() {
    this.setupCarousel()
  },
  mounted() {
    this.setAutoScrollForHeroBanners()
  },
  beforeUnmount() {
    clearInterval(this.autoScrollInterval)
    clearInterval(this.timerInterval)
  },
  methods: {
    onSwipe(event) {
      this.isKeyboardEvent = false
      if (event === 'left') {
        this.manualScrollReset = true
        this.resetTimer()
        this.goNext()
      }

      if (event === 'right') {
        this.goPrevious()
      }
    },
    goNext(event) {
      // event will be undefined when triggered during auto scroll interval
      // prevent any tracking when during autoscroll\
      if (this.restartFromPauseButton) {
        this.clearAutoScrollForHeroBanners()
        this.setAutoScrollForHeroBanners()
        this.restartFromPauseButton = false
      }
      if (event !== undefined && !this.heroBannerPaused) {
        this.manualScrollReset = true
        this.resetTimer()
      }
      if (this.selected === (this.items.length - 1)) {
        this.goTo(0, true)
        if (event === undefined) {
          this.$emit('auto-scroll')
        }
        return
      }
      this.goTo(this.selected + 1, true)
      if (event === undefined) {
        this.$emit('auto-scroll')
      }
    },
    goPrevious() {
      if (!this.heroBannerPaused) {
        this.manualScrollReset = true
        this.resetTimer()
      }
      if (this.selected === 0) {
        this.goTo(this.items.length - 1, true)
        return
      }
      this.goTo(this.selected - 1, true)
    },
    goTo(which, setFocus) {
      this.manualScrollReset = !this.heroBannerPaused
      setTimeout(() => {
        if (this.manualScrollReset && !this.heroBannerPaused) {
          this.manualScrollReset = false
          this.resetTimer()
          this.clearAutoScrollForHeroBanners()
          this.setAutoScrollForHeroBanners()
        }
      }, 50)
      this.$emit('image-changed')
      this.$emit('selected', which)
      if (this.items[which]) {
        this.selected = which
        this.selectedElement = this.$refs[`CarouselItem${which}`][0] || null
        this.checkAndSetLifeStyleTemplate()
      } else {
        // Causes range error if the following two lines are combined, seems to be a bug in eslint
        // https://stackoverflow.com/questions/48391913/eslint-error-cannot-read-property-range-of-null
        const [firstItem] = this.$refs[`CarouselItem${0}`][0]
        this.selected = firstItem?.parentElement?.childElementCount - 1
        this.selectedElement = this.$refs[`CarouselItem${this.selected}`][0] || null
      }

      this.setCarouselStyle(setFocus)
    },
    isActive(key) {
      return key === this.selected
    },
    handleTransition() {
      let focusElement

      if (this.slidesContainTiles) {
        // eslint-disable-next-line prefer-destructuring
        focusElement = this.selectedElement.getElementsByTagName('li')[0]
      } else if ((this.isKwmCarousel && this.hasChevronButton)
        || (this.isHeroBannerCarousel && !this.isDesktop)) {
        focusElement = null
      } else {
        focusElement = this.selectedElement
      }

      if (focusElement) {
        focusElement.focus()
      }
      this.selectedElement.removeEventListener('transitionend', this.handleTransition)
    },
    setCarouselStyle(setFocus) {
      if (!detectIE() && this.selectedElement && setFocus) { // ADA
        if (this.hasBottomArrows) {
          this.selectedElement.parentElement.addEventListener('transitionend', this.handleTransition)
        } else {
          this.selectedElement.addEventListener('transitionend', this.handleTransition)
        }
      }
      const currentWidth = this.isHeroBannerCarousel ? this.width : Math.min(this.width, CAROUSEL_MAX_WIDTH_PX)
      const offset = currentWidth * this.selected
      let style = `transform: translateX(-${offset}px); height: ${this.getHeightStyle()};`
      style = this.sliderPositionStyle ? `${style} position: ${this.sliderPositionStyle};` : style
      this.carouselStyle = style
    },
    getConfigClass(c) {
      return `${this.styleClass}_${c}`
    },
    setupCarousel() {
      this.sliderElement = this.$refs.CarouselSlider
      setTimeout(() => {
        this.setSelectedElement()
        this.setCarouselStyle()
      }, 200)
    },
    setSelectedElement() {
      const selectedElement = this.$refs[`CarouselItem${this.selected}`]
      this.selectedElement = selectedElement ? selectedElement[0] : null
      this.checkAndSetLifeStyleTemplate()
    },
    updateCarousel() {
      setTimeout(() => {
        this.setSelectedElement()
        this.goTo(this.selected, false)
      }, 200)
    },
    checkAndSetLifeStyleTemplate() {
      // page indicator color should be primary only for lifestyle template for hero banner
      if (this.isHeroBannerCarousel) {
        const selectedItem = this.items[this.selected]
        if (selectedItem?.content['Lifestyle Temp']
              && selectedItem?.content['Lifestyle Temp'][0] === 'Yes') {
          this.isHeroBannerLifeStyle = true
        } else {
          this.isHeroBannerLifeStyle = false
        }
      }
    },
    getHeightStyle() {
      return this.height === 'auto' ? `${this.height}` : `${this.height}rem`
    },
    isLastSlide(currentNum, items) {
      return items.length > 1 && currentNum === items.length - 1
    },
    findPriorSlide(index, items) {
      const priorSlide = items[index - 1]
      if (priorSlide) {
        return priorSlide
      }
      return {}
    },
    clearAutoScrollForHeroBanners() {
      this.heroBannerPaused = true
      if (this.isHeroBannerCarousel) {
        clearInterval(this.autoScrollInterval)
      }
    },
    setAutoScrollForHeroBanners() {
      this.heroBannerPaused = false
      this.resetTimer()
      this.manualScrollReset = false
      clearInterval(this.autoScrollInterval)
      if (this.isHeroBannerCarousel) {
        this.autoScrollInterval = setInterval(() => {
          this.goNext()
          this.timeLeft = 8
        }, 8000)
      }
    },
    restartScrollForHeroBanners(restartTime) {
      this.heroBannerPaused = false
      this.restartFromPauseButton = true
      clearInterval(this.autoScrollInterval)
      this.autoScrollInterval = null
      this.startTimer()
      this.autoScrollInterval = setInterval(() => {
        clearInterval(this.timerInterval)
        this.timerInterval = null
        this.goNext()
      }, (restartTime * 1000) - 250)
    },
    startTimer() {
      if (this.timeLeft <= 0) {
        this.resetTimer()
      }
      this.timerInterval = setInterval(() => {
        this.timeLeft -= 0.25
      }, 250)
    },
    pauseTimer() {
      clearInterval(this.timerInterval)
    },
    resetTimer() {
      this.timeLeft = 8
      clearInterval(this.timerInterval)
      this.timerInterval = null
      this.startTimer()
    },
    pauseHeroBannerOnClick() {
      this.heroBannerPaused = !this.heroBannerPaused
      if (this.heroBannerPaused) {
        this.pauseTimer()
        this.clearAutoScrollForHeroBanners()
        this.restartFromPauseButton = false
      } else if (!this.heroBannerPaused) {
        this.restartScrollForHeroBanners(this.timeLeft)
      }
    },
    handleTouchStart(event) {
      this.touchStartX = event.touches[0].clientX
      this.touchStartY = event.touches[0].clientY

      event.target.addEventListener('touchmove', this.handleTouchMove)
      event.target.addEventListener('touchend', this.handleTouchEnd)
    },
    handleTouchMove(event) {
      const deltaX = event.touches[0].clientX - this.touchStartX
      const deltaY = event.touches[0].clientY - this.touchStartY
      const isHorizontalSwipe = Math.abs(deltaX) > Math.abs(deltaY)

      if (isHorizontalSwipe) {
        event.preventDefault()
      }
    },
    handleTouchEnd(event) {
      event.target.removeEventListener('touchmove', this.handleTouchMove)
      event.target.removeEventListener('touchend', this.handleTouchEnd)
    },
    handleKeyEvents(event) {
      const keys = ['Tab', 'Enter', 'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight']
      if (keys.includes(event.key)) {
        this.isKeyboardEvent = true
      } else {
        this.isKeyboardEvent = false
      }
    }
  }
}
</script>
