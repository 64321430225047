import ApiService from '@/api/'

export default {
  getCart(params, userId, basketId) {
    return ApiService.get(`/api/v6.0/user/${userId}/order/${basketId}/cart`, { params })
  },
  updateCart(params, userId, basketId) {
    const config = {
      isTemplateUrlPath: true,
      pathVariables: {
        userId,
        basketId
      },
    }
    return ApiService.put(`/api/v6.0/user/:userId/order/:basketId/cart`, params, config)
  },
  deleteItemFromCart(value, userId, basketId) {
    const params = {
      productId: value.productId
    }
    return ApiService.delete(`/api/v6.0/user/${userId}/order/${basketId}/cart`, { params })
  },
  clearEntireCart(payload) {
    return ApiService.delete(`/api/v6.0/user/${payload.userId}/order/${payload.basketId}/cart`)
  },
  getCurrentBasketId(userId) {
    return ApiService.get(`/api/v6.0/user/${userId}/order/current`)
  },
  getBasketId(userId) {
    // note: this API is being decomissioned and replaced with order/status
    return ApiService.get(`/api/v5.0/user/${userId}/order/list`)
  },
  setPrimaryCart({ userId, basketId }) {
    return ApiService.put(`/api/v6.0/user/${userId}/order/${basketId}/primary`)
  },
  setCartSubstitutionPref(value, userId, basketId) {
    const params = {
      value
    }
    return ApiService.put(`/api/v6.0/user/${userId}/order/${basketId}/cart/substitutionsAllowed`, { ...params })
  },
  setItemSubPreference(params, payload) {
    const { prodId, basketId, userId } = params
    return ApiService.put(`/api/v6.0/user/${userId}/order/${basketId}/cart/${prodId}/substitute`, { ...payload })
  }
}
