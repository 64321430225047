import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache
} from '@apollo/client/core'
import { createApolloProvider } from '@vue/apollo-option'
import {
  determineEnvironment,
  getGraphQLUri
} from 'utils/services/graphql/environment'

const env = determineEnvironment(window.location.hostname, appConfig.opcoId)
const uri = getGraphQLUri(env, appConfig.opcoId)

const httpLink = new HttpLink({
  // Uri below is For manually pointing at the local graphql server
  // uri: 'http://localhost:4000/',
  uri
})

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext({
    headers: {
      // authorization: localStorage.getItem('token') || null,
      // secret: localStorage.getItem('ppdtk') || null,
      env,
      // env: 'stage',
      // env: 'qa10',
      // env: 'dev04',
      opco: appConfig.opcoId,
    },
  })

  return forward(operation)
})

const link = ApolloLink.from([
  authMiddleware,
  httpLink,
])
// Create the apollo client
export const apolloClient = new ApolloClient({
  link,
  cache: new InMemoryCache(),
  connectToDevTools: env !== 'prod',
})

export const apolloProvider = createApolloProvider({
  defaultClient: apolloClient,
})
