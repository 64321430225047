<template>
  <div
    v-show="!isMobileApp"
    class="header-nav_wrapper"
  >
    <nav
      :class="[
        'global-header_nav global-header_nav--sub spyglass-nav-group_wrapper',
        {
          'bg-white mx-auto max-w-[192rem] border-b-[0.05rem] border-solid border-gray-100'
            : isNewNavDesignEnabled
        }
      ]"
      aria-label="Secondary"
    >
      <ul
        class="global-header_list justify-items-center"
      >
        <PdlSubNavItem
        v-if="isNewNavDesignEnabled"
        class="mb-2 ml-[0.8rem]"
        :label="'Categories'"
        :has-right-caret="true"
        :has-hover-icon="false"
        :is-category-menu-button="true"
        :button-class="'text-grey-900 font-semibold relative top-[0.1rem] text-sm'"
        :is-new-nav-design-enabled="isNewNavDesignEnabled"
        @nav-item-clicked="onNavItemClick"
        @focus-in="closeMenu"
      />
       <div
          v-if="isNewNavDesignEnabled"
          class="hidden lg:block border-l border-solid border-gray-100 h-10 mt-6 mx-[0.8rem]"
        />
        <PdlSubNavItem
          v-for="(item, index) in combinedNavItems"
          :key="`subheader-nav-${index}`"
          :index="index"
          class="mb-2"
          :label="item.label"
          :url="item.url"
          :link-type="item.linkType"
          :button-class="
            item.sublinks
              && isNewNavDesignEnabled
              ? 'text-grey-900 font-semibold relative top-[0.1rem] text-sm'
              : 'nav-item_button'"
          :content-data="contentData"
          :scheduled-data="scheduledData"
          :is-new-nav-design-enabled="isNewNavDesignEnabled"
          :has-hover-icon="!item.sublinks"
          :has-right-caret="item.sublinks !== undefined"
          :menu-items="item.sublinkDetail"
          @nav-item-clicked="onNavItemClick"
          @track-nav-updated="trackNavUpdated"
        />
      </ul>
      <PdlShoppingModeWidget
        v-if="widgetReady"
        :is-new-nav-design-enabled="isNewNavDesignEnabled"
      />
      <PdlSpyglass
        v-if="spyglassActive"
        is-group="true"
        :spyglass-content="spyglassData"
      />
    </nav>
  </div>
</template>

<script>
import {
  mapActions, mapGetters, mapMutations, mapState
} from 'vuex'
import compileLinkVars from 'utils/mixins/compileLinkVars'
import PdlShoppingModeWidget from 'components/navigation/PdlShoppingModeWidget'
import PdlSubNavItem from 'components/navigation/nav-items/PdlSubNavItem'
import PdlSpyglass from 'components/spyglass/PdlSpyglass'

export default {
  components: {
    PdlShoppingModeWidget,
    PdlSubNavItem,
    PdlSpyglass
  },
  mixins: [compileLinkVars],
  props: {
    spyglassActive: {
      type: Boolean
    },
    contentData: {
      type: Object,
      default: null
    },
    scheduledData: {
      type: Object,
      default: null
    },
    isNewNavDesignEnabled: {
      type: Boolean,
      default: false
    },
    pageView: {
      type: String,
      default: 'Home'
    }
  },
  computed: {
    ...mapState({
      helpModeActive: state => state.ShoppingMode.helpModeActive,
      widgetReady: state => state.ShoppingMode.widgetReady,
      selectionModalNewViewedThisSession: state => state.WeeklyAdPDL.selectionModalViewedThisSession,
      hasPreview: state => state.WeeklyAdPDL.hasPreview
    }),
    ...mapGetters({
      siteConfigByName: 'SiteConfig/varByName',
      deliveryServiceLocation: 'UserProfile/deliveryServiceLocation',
      brandVars: 'SiteConfig/brandVars',
      weeklyAdPublications: 'WeeklyAd/publications',
      isMobileApp: 'NativeContainer/isMobileApp',
      subNavItems: 'GlobalHeader/subNavItems',
      subNavRedesignItems: 'GlobalHeader/subNavRedesignItems',
      selectionModalViewedThisSession: 'WeeklyAd/selectionModalViewedThisSession',
      isCategoryMenuOpen: 'GlobalHeader/isCategoryMenuOpen',
      isSubNavMenuOpen: 'GlobalHeader/isSubNavMenuOpen'
    }),
    hasDelivery() {
      return this.siteConfigByName('config_available_service_types')
        ? this.siteConfigByName('config_available_service_types').includes('D') : false
    },
    hasPickUp() {
      return this.siteConfigByName('config_available_service_types')
        ? this.siteConfigByName('config_available_service_types').includes('P') : false
    },
    hasInStore() {
      return this.siteConfigByName('config_available_service_types')
        ? this.siteConfigByName('config_available_service_types').includes('B') : false
    },
    serviceLabel() {
      if (this.hasDelivery && this.hasPickUp) {
        return 'Delivery / Pickup Times'
      }

      if (this.hasDelivery) {
        return 'Delivery Times'
      }

      if (this.hasPickUp) {
        return 'Pickup Times'
      }

      return ''
    },
    currentServiceType() {
      try {
        return this.deliveryServiceLocation.serviceType
      } catch (e) {
        return null
      }
    },
    brandContent() {
      return this.brandVars
    },
    combinedNavItems() {
      if (this.isNewNavDesignEnabled) {
        return _.isEmpty(this.subNavRedesignItems) ? [] : this.subNavRedesignItems.links
      }
      return _.isEmpty(this.subNavItems) ? [] : this.subNavItems.links
    },
    spyglassData() {
      if (_.isEmpty(this.subNavItems)) {
        return {}
      }
      if (_.isEmpty(this.subNavRedesignItems)) {
        return {}
      }
      return this.isNewNavDesignEnabled ? this.subNavRedesignItems.spyGlassData : this.subNavItems.spyGlassData
    }
  },
  methods: {
    ...mapActions({
      setHelpMode: 'ShoppingMode/setHelpMode',
      removeMaskOverlay: 'GlobalHeader/removeMaskOverlay'
    }),
    ...mapMutations({
      setCloseSubNavMenu: 'GlobalHeader/setCloseSubNavMenu',
      setCloseCategoryMenu: 'GlobalHeader/setCloseCategoryMenu'
    }),
    onNavItemClick(label, url, linkType, elementId) {
      // ensure shopping-mode help is gone following any sub-nav item click
      if (this.helpModeActive) {
        this.setHelpMode(false)
        this.removeMaskOverlay()
      }
      // In case the weekly ad label changes, check the url instead of the label.
      // In case "/modal/"" or "-modal" is removed in the future, check  only for "weekly-ad-selector."
      const isWeeklyAdTab = typeof url === 'string' && url.indexOf('weekly-ad/grid-view') > -1
      const hideWeeklyAd = !this.siteConfigByName('feature_weekly_ad')
      const hideSavingsPage = !this.siteConfigByName('feature_savings')
      const weeklyAdNew = this.siteConfigByName('feature_weekly_ad_pdl')
      const modalViewed = this.selectionModalViewedThisSession || this.selectionModalNewViewedThisSession
      const skipWeeklyAdModal = (this.weeklyAdPublications.length < 2 && !this.hasPreview)
       || hideWeeklyAd || hideSavingsPage || modalViewed
      const location = {
        gtm: linkType,
        ga4: 'primary header',
      }
      if (isWeeklyAdTab && skipWeeklyAdModal) {
        location.gtm = 'Internal'
        url = '/savings/weekly-ad/grid-view'
        this.$emit('nav-item-clicked', label,
          url,
          linkType,
          elementId)
      } else if (isWeeklyAdTab) {
        if (weeklyAdNew) {
          this.$store.commit('Modals/setActiveModal', {
            fileName: 'PdlNewWeeklyAdSelectorModal',
            style: 'modal_base-medium'
          })
        } else {
          this.$store.commit('Modals/setActiveModal', {
            fileName: 'PdlWeeklyAdSelectorModal',
            style: 'modal_base-medium'
          })
        }
      } else {
        this.$emit('nav-item-clicked',
          label,
          url,
          linkType,
          elementId)
      }
    },
    closeMenu(setFocus = false) {
      if (this.isBrowseAislesMenuOpen) {
        this.setCloseBrowseAislesMenu()
      }
      if (this.isAccountMenuOpen) {
        this.setCloseAccountMenu()
        if (setFocus) {
          this.$refs.accountButton.$el.focus()
        }
      }
      if (this.isSubNavMenuOpen) {
        this.setCloseSubNavMenu()
      }
      if (this.isMyShopMenuOpen) {
        this.setCloseMyShopMenu()
      }
    },
    trackNavUpdated(data) {
      const trackNavUpdatedData = {
        action: 'primary header',
        ...data
      }
      this.$emit('track-nav-updated', trackNavUpdatedData)
    }
  }
}
</script>
