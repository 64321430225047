import productReviewsAPI from 'api/productReviewsAPI'
// eslint-disable-next-line
const getDefaultState = () => {
  return {
    userReviews: {
      reviews: []
    },
    allReviews: {
      reviews: [],
      totalReviewCount: 0,
      ratingDistribution: [],
      averageOverallRating: 0
    },
    userReviewsError: false,
    allReviewsError: false
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    allReviews: state => state.allReviews,
    userReviews: state => state.userReviews,
    averageRating: state => state.allReviews.averageOverallRating,
    totalReviews: state => state.allReviews.totalReviewCount
  },
  mutations: {
    setAllReviews(state, value) {
      const { data, productId } = value
      const hasStatistics = data.Includes?.Products
        && data.Includes.Products[productId] && data.Includes.Products[productId].ReviewStatistics
      if (hasStatistics) {
        const statistics = data.Includes.Products[productId].ReviewStatistics
        state.allReviews.totalReviewCount = statistics.TotalReviewCount
        state.allReviews.averageOverallRating = statistics.AverageOverallRating
        if (Array.isArray(statistics.RatingDistribution)) {
          state.allReviews.ratingDistribution = statistics.RatingDistribution
        }
      }

      if (data.Results && Array.isArray(data.Results)) {
        state.allReviews.reviews = data.Results
      }
    },
    setUserReviews(state, value) {
      const { data } = value
      if (data.Results && Array.isArray(data.Results)) {
        state.userReviews.reviews = data.Results
      }
    },
    setError(state, value) {
      state[value.error] = value.hasError
    },
    reset(state) {
      Object.assign(state, getDefaultState())
    }
  },
  actions: {
    async getAllReviews({ commit }, config) {
      if (config?.productId) {
        const response = await productReviewsAPI.getReviews(config)
        if (response.status === 200 && response.data && !response.data.HasErrors) {
          if (!response.data.TotalResults) {
            commit('reset')
            return
          }
          commit('setAllReviews', {
            data: response.data,
            productId: config.productId
          })
        } else {
          commit('setError', {
            error: 'allReviewsError',
            hasError: true
          })
        }
      }
    },
    async getUserReviews({ commit, rootGetters }, config) {
      const userId = rootGetters['UserProfile/userId']
      if (userId) {
        config.userId = userId
        const response = await productReviewsAPI.getReviews(config)
        if (response.status === 200 && response.data && !response.data.HasErrors) {
          commit('setUserReviews', {
            data: response.data
          })
        } else {
          commit('setError', {
            error: 'userReviewsError',
            hasError: true
          })
        }
      }
    },
    async submitReview({ commit }, config) {
      config = config || {}
      const response = await productReviewsAPI.submitReview({
        ...config,
        action: config.action || 'submit'
      })
      const success = response.status === 200

      if (!success) {
        commit('setError', {
          error: 'submissionError',
          hasError: true
        })
      }

      return response
    },
    async submitPreviewReview({ dispatch }, config) {
      config = config || {}
      return dispatch('submitReview', {
        ...config,
        action: 'preview'
      })
    },
    fetchReviews({ dispatch }, payload) {
      const apiConfig = {
        productId: payload,
        offset: 0,
        limit: 10,
        sortType: 'SubmissionTime:desc'
      }
      dispatch('ProductReviews/getAllReviews', apiConfig, { root: true })
    }
  }
}
