<template>
  <div class="text-gray-600 text-center p-4 lg:my-12 lg:mx-auto">
    <p class="text-xl text-black font-bold mb-4 break-words">
      {{ title }}
    </p>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    }
  }
}
</script>
