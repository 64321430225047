import { filterBreadcrumbs } from './helpers/BreadCrumbHelpers'

/**
 * Title Bar : TitleBar
 * Store dynamic title bar state variables
 */
export default {
  namespaced: true,
  state: {
    displayViewOption: false,
    showViewSwitcher: false,
    showViewOptions: false,
    enableListView: false,
    hasPrintButton: false,
    sortSubHeader: '',
    pageTitle: '',
    infoSection: {},
    breadcrumbs: [],
    isBackButtonHidden: false
  },
  getters: {
    breadcrumbs: state => state.breadcrumbs,
    pageTitle: state => state.pageTitle,
    displayViewOption: state => state.displayViewOption,
    sortSubHeader: state => state.sortSubHeader,
    infoSection: state => state.infoSection,
    showViewOptions: state => state.showViewOptions,
    // For view switchers on PLPs
    showViewSwitcher: state => state.showViewSwitcher,
    enableListView: state => state.enableListView,
    hasPrintButton: state => state.hasPrintButton,
    isBackButtonHidden: state => state.isBackButtonHidden
  },
  mutations: {
    setPageTitle(state, payload) {
      state.pageTitle = payload
    },
    setSortSubHeader(state, payload) {
      state.sortSubHeader = payload
    },
    setShowViewOptions(state, payload) {
      state.showViewOptions = payload
    },
    setShowViewSwitcher(state, payload) {
      state.showViewSwitcher = payload
    },
    setEnableListView(state, payload) {
      state.enableListView = payload
    },
    setHasPrintButton(state, payload) {
      // Payload is a Boolean
      state.hasPrintButton = payload
    },
    setInfoSection(state, payload) {
      state.infoSection = payload
    },
    storeBreadcrumb(state, next) {
      state.breadcrumbs = filterBreadcrumbs(next, state.breadcrumbs)
    },
    removeLastBreadcrumb(state) {
      state.breadcrumbs.pop()
    },
    setIsBackButtonHidden(state, payload) {
      state.isBackButtonHidden = payload
    },
  }
}
