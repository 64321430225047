import { cancelSlot } from 'api/slotsApi'
import OrderAdjustmentsAPI from 'api/OrderAdjustmentsAPI'
import SessionAPI from 'api/SessionAPI'
import cutOffHelper from 'utils/lib/cutOffHelper'

function initialState() {
  return {
    initialSeconds: 0,
    initialDate: null,
    submitted: false,
    secondsRemaining: 0,
    cutoffAlertFlag: false,
    timeIntervalId: 0,
    cutOffStatus: '', // this state is used to trigger Angular Code
    cutOffUpdateInProgress: false
  }
}
export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    setInitialSeconds(state, payload) {
      state.initialSeconds = payload
    },
    setInitialDate(state, payload) {
      state.initialDate = payload
    },
    setSubmitted(state, payload) {
      state.submitted = payload
    },
    setSecondsRemaining(state, payload) {
      state.secondsRemaining = payload
    },
    setCutoffAlertFlag(state, payload) {
      state.cutoffAlertFlag = payload
    },
    setTimeIntervalId(state, payload) {
      state.timeIntervalId = payload
    },
    setCutOffStatus(state, payload) {
      state.cutOffStatus = payload
    },
    setCutOffUpdateInProgress(state, payload) {
      state.cutOffUpdateInProgress = payload
    }
  },
  actions: {
    initializeCutoff({ state, commit }) {
      commit('setCutoffAlertFlag', false)
      commit('setInitialSeconds', 0)
      commit('setInitialDate', null)
      clearInterval(state.timeIntervalId)
      commit('setTimeIntervalId', 0)
      commit('setSecondsRemaining', 0)
      commit('setCutOffStatus', '')
      commit('setSubmitted', false)
    },
    activateCutoffAlertFlag({
      state, commit, dispatch
    }, payload) {
      commit('setCutoffAlertFlag', payload)
      if (state.timerIntervalId !== 0) {
        clearInterval(state.timeIntervalId)
        commit('setTimeIntervalId', 0)
      }
      if (state.cutoffAlertFlag) {
        const intervalId = setInterval(() => dispatch('cutoffCallBack'), 1000)
        commit('setTimeIntervalId', intervalId)
      }
    },
    async cutoffCallBack({
      state, commit, rootGetters
    }) {
      const basketId = rootGetters['Cart/getBasketId']
      const userId = rootGetters['UserProfile/userId']
      const newSecondsRemaining = state.initialSeconds
                    - (Date.now() - state.initialDate) / 1000

      commit('setSecondsRemaining', newSecondsRemaining)
      const cancelParams = {
        userId,
        basketId: rootGetters['Cart/getBasketId']
      }
      if (state.secondsRemaining < 2) {
        // stop the timer
        clearInterval(state.timeIntervalId)
        commit('setTimeIntervalId', 0)
        if (!state.submitted) {
          cutOffHelper.trackSlotCutOffClientLog('SUBMITTED', 'SLOTS_CUTOFF_NOT_SUBMITTED_START')
          await cancelSlot(cancelParams).execute()
          cutOffHelper.trackSlotCutOffClientLog('NOT_SUBMITTED', 'SLOT_CANCEL')
          commit('setCutOffStatus', 'workingCutOff')
        } else {
          cutOffHelper.trackSlotCutOffClientLog('SUBMITTED', 'SLOTS_CUTOFF_SUBMITTED_START')
          await OrderAdjustmentsAPI.sendOrderUpdateEmail({
            basketId,
            userId,
          })

          cutOffHelper.trackSlotCutOffClientLog('SUBMITTED', 'ORDER_UPDATE_EMAIL')
          await SessionAPI.exitSession()
          cutOffHelper.trackSlotCutOffClientLog('SUBMITTED', 'SessionAPI_EXIT')
          commit('setCutOffStatus', 'submittedCutOff')
        }
      }
    },
    createCutoffAlert({
      state, dispatch
    }, payload) {
      if (!state.cutoffAlertFlag) {
        dispatch('activateCutoffAlertFlag', true)
        dispatch('updateCutoffAlert', payload)
      }
    },
    updateCutoffAlert({ commit }, payload) {
      if (payload?.alerts?.SUBMITTED_CUTOFF) {
        commit('setInitialSeconds', payload.alerts.SUBMITTED_CUTOFF.secondsToCutoff)
        commit('setInitialDate', Date.now())
        commit('setSubmitted', true)
      } else if (payload?.alerts?.WORKING_CUTOFF) {
        commit('setInitialSeconds', payload.alerts.WORKING_CUTOFF.secondsToCutoff)
        commit('setInitialDate', Date.now())
        commit('setSubmitted', false)
      }
    },
    processCutoffAlertResponse({
      state, dispatch
    }, payload) {
      const submittedCutoffFlag = payload.alerts?.SUBMITTED_CUTOFF
      const workingCutoffFlag = payload.alerts?.WORKING_CUTOFF
      const hasAPICutoffAlertFlag = submittedCutoffFlag || workingCutoffFlag
      if (state.cutoffAlertFlag && hasAPICutoffAlertFlag) {
        dispatch('updateCutoffAlert', payload)
        return
      }
      if (!state.cutoffAlertFlag && hasAPICutoffAlertFlag) {
        dispatch('createCutoffAlert', payload)
      }
    }
  }
}
