import WeeklyAdApi from 'api/WeeklyAdApi'
import productAPI from 'api/productApi'

const initialState = {
  weeklyAdOffers: [],
  publicationChanged: false,
  publication: {},
  publicationLoaded: false,
  totalOffers: 0,
  weeklyAdZoneProducts: [],
  adRetrievalStart: 0,
  adRetrievalIncrement: 60,
  hasBrickAndMortarStore: true,
  serviceType: '',
  weeklyAdProductsInitialized: false,
  publicationError: false,
  previewPublication: {},
  weeklyAdProductsError: false,
  isItemDetailsModalActive: false,
  selectedStoreNumber: null,
  hasPreview: false,
  previewSelected: false,
  searchKeyword: '',
  searchResult: null,
  initialRetrieval: true,
  filterSelected: '',
  weeklyAdZoneLimit: 0,
  weeklyAdViewedThisSession: [],
  eligibleZip: false,
  publicationFilters: [],
  serviceLocationId: 0,
  setWeeklyAdViewedThisSession: [],
  selectedWeeklyAdProduct: {},
  selectionModalViewedThisSession: false,
  combinedPublications: [],
  sortSelection: 'PAGE_ORDER',
  sortV2Selection: 'CATEGORY',
  sortChanged: false,
  sortOptions: [
    {
      title: 'Page Order',
      value: 'PAGE_ORDER',
      order: 'ASC',
      new: false
    },
    {
      title: 'Alphabetically',
      value: 'ALPHABETICAL',
      order: 'ASC',
      new: false
    },
    {
      title: 'Category',
      value: 'CATEGORY',
      order: 'ASC',
      new: true
    },
    {
      title: 'Discount',
      value: 'MAX_DISCOUNT',
      order: 'ASC',
      new: true
    },
    {
      title: 'Page Order',
      value: 'PAGE_ORDER',
      order: 'ASC',
      new: true
    }
  ],
  mainFilterOptions: [
    {
      name: 'Has Coupon',
      selected: false,
      exposed: true
    },
    {
      name: 'SNAP Eligible',
      selected: false,
      exposed: true
    },
    {
      name: 'Guiding Stars Nutrition',
      selected: false,
      exposed: true
    },
    {
      name: 'Sustainability Rating',
      selected: false,
      exposed: false
    }
  ],
  filterOptions: {
    hasCoupons: false,
    ebtEligible: false,
    sustainable: false,
    guidingStars: false
  },
  start: 'none',
  size: {
    CATEGORY: 3,
    MAX_DISCOUNT: 60,
    PAGE_ORDER: 60
  },
}

export default {
  namespaced: true,
  state: initialState,
  getters: {
    filterSearchQuery: (state) => {
      return state.searchKeyword || state.filterSelected
    }
  },
  mutations: {
    setPublication(state, payload) {
      state.publication = payload
    },
    setPublicationFilters: (state, payload) => {
      state.publicationFilters = payload
    },
    setModalState(state, payload) {
      // payload: { modal: string, isActive: boolean }
      if (state.modals[payload.modal]) {
        state.modals[payload.modal].isActive = payload.isActive
      }
    },
    setHasPreview(state, payload) {
      state.hasPreview = payload.previewAvailable
      if (state.hasPreview) {
        state.previewPublication = {
          adsStartDate: payload.startDate,
          adsEndDate: payload.endDate,
          externalDisplayName: payload.externalDisplayName,
          thumbnailImageUrl: payload.thumbnailImageUrl,
        }
      }
    },
    setSelectedWeeklyAdProduct(state, payload) {
      state.selectedWeeklyAdProduct = payload
    },
    setHasBrickAndMortarStore(state, payload) {
      state.hasBrickAndMortarStore = payload
    },
    setServiceType(state, payload) {
      state.serviceType = payload
    },
    setWeeklyAdOffers(state, payload) {
      state.weeklyAdOffers = payload
    },
    setWeeklyAdTotal(state, payload) {
      state.totalOffers = payload
    },
    setError(state, payload) {
      state[payload.name] = payload.hasError
    },
    setSelectedStoreNumber(state, payload) {
      state.selectedStoreNumber = payload
    },
    reset(state) {
      Object.assign(state, initialState)
    },
    setEligibleZip(state, payload) {
      state.eligibleZip = payload
    },
    setPublicationLoaded(state, payload) {
      state.publicationLoaded = payload
      if (state.publicationChanged) {
        state.publicationChanged = false
      }
    },
    setInitialRetrievalDone(state) {
      state.initialRetrieval = false
    },
    increaseAdRetrievalStart(state) {
      state.adRetrievalStart += state.adRetrievalIncrement
    },
    moreAds(state, payload) {
      const merge = [...state.weeklyAdOffers, ...payload]
      state.weeklyAdOffers = merge
    },
    setServiceLocationId(state, payload) {
      state.serviceLocationId = payload
    },
    setSortSelection(state, payload) {
      state.sortSelection = payload
    },
    setSortChanged(state, payload) {
      state.sortChanged = payload
    },
    setSearchKeyword(state, payload) {
      state.searchKeyword = payload
    },
    setSearchResult(state, payload) {
      state.searchResult = payload
    },
    setFilterSelected(state, payload) {
      state.filterSelected = payload
    },
    setWeeklyAdZoneLimit(state, payload) {
      state.weeklyAdZoneLimit = payload
    },
    resetRetrievalStart(state) {
      state.adRetrievalStart = 0
      state.start = 'none'
    },
    setWeeklyAdViewedThisSession(state, payload) {
      state.weeklyAdViewedThisSession = payload
    },
    detailModalOpen(state, payload) {
      state.isItemDetailsModalActive = payload
    },
    setWeeklyAdZoneProducts(state, payload) {
      state.weeklyAdZoneProducts = payload
    },
    setPreviewSelected(state, payload) {
      state.previewSelected = payload
    },
    resetPublication(state) {
      state.publication = {}
      state.publicationChanged = true
    },
    setCombinedPublications(state, payload) {
      state.combinedPublications = payload
    },
    setSelectionModalSeen(state, payload) {
      state.selectionModalViewedThisSession = payload
    },
    setSortSelectionV2(state, payload) {
      state.sortV2Selection = payload
    },
    setMainFilters(state, payload) {
      const index = state.mainFilterOptions.findIndex(option => option.name === payload.name)
      if (index !== -1 && payload.name !== 'Filters') {
        state.mainFilterOptions.splice(index, 1, {
          ...state.mainFilterOptions[index],
          ...payload
        })
      }
    }
  },
  actions: {
    // mirror existing business logic that determines when weekly ad should be retrieved
    // leads to calling in house api instead of flipp. old publication & offer calls are now just 1 call
    async getWeeklyAd({
      commit, state, rootState, rootGetters, dispatch
    }) {
      const { opco } = rootState.SiteConfig
      const userInfo = rootGetters['UserProfile/userInfo']
      const deliveryServiceLocation = rootGetters['UserProfile/deliveryServiceLocation']
      const getResourceByName = rootGetters['SiteConfig/resourceByName']
      const { serviceType, zip } = deliveryServiceLocation
      const onStoreLocator = window.location.href.indexOf('store-locator') > -1

      // get delivery allowed locations
      let eligibleZip = false
      if (serviceType === 'D') {
        const deliveryZips = getResourceByName('WEEKLY_AD_DELIVERY_ZIPS')
        if (deliveryZips !== null && deliveryZips !== '') {
          eligibleZip = deliveryZips.toString().split(',').includes(zip)
          commit('setEligibleZip', eligibleZip)
        }
      }

      if (opco && opco !== 'PPOD' && userInfo) {
        if (state.selectedStoreNumber === null) {
          const storeNumber = String(deliveryServiceLocation.storeNumber).padStart(4, '0')
          commit('setSelectedStoreNumber', storeNumber)
        } else if (state.selectedStoreNumber.length < 4) {
          const storeNumber = String(state.selectedStoreNumber).padStart(4, '0')
          commit('setSelectedStoreNumber', storeNumber)
        }

        const { clickAndCollect } = deliveryServiceLocation
        const showOnSavingsScreen = serviceType === 'B'
        || (serviceType === 'P' && clickAndCollect)
        || eligibleZip || opco === 'FDLN'
        // Always show the ad on the store locator.
        // On the Savings screen,
        // hide when it is a PUP (not Click and Collect).
        const showAd = onStoreLocator ? true : showOnSavingsScreen
        commit('setServiceType', serviceType)
        commit('setError', {
          name: 'publicationsError',
          hasError: false
        })
        await dispatch('retrievePDLAd', { showAd })
      }
    },
    // retrieve in house weekly ad api with configured params and process response
    async retrievePDLAd({
      commit, rootGetters, dispatch
    }, payload) {
      const { showAd } = payload
      const isMobile = rootGetters['ScreenSize/isMobile']
      const userInfo = rootGetters['UserProfile/userInfo']
      const { userId } = userInfo.information
      const serviceLocationId = rootGetters['UserProfile/serviceLocationId']
      if (serviceLocationId && showAd && userId) {
        commit('setHasBrickAndMortarStore', true)
        commit('setServiceLocationId', serviceLocationId)
        const response = await dispatch('getProducts', { isMobile, getEffectiveAds: false })
        if (response.error === false) {
          dispatch('handleAdRetrievalSuccess', response.ads)
        }
        if (response.error === true) {
          if (response.errorStatus === 204) {
            dispatch('handleSearchFailure')
          } else {
            dispatch('handleAdRetrievalFailure')
          }
        }
      } else {
        commit('setHasBrickAndMortarStore', false)
      }
    },
    async getProducts({
      state, commit, rootGetters, // rootState
    }, payload) {
      const userId = rootGetters['UserProfile/userId']
      const serviceLocationId = rootGetters['UserProfile/serviceLocationId']
      const { isMobile, getEffectiveAds } = payload
      const {
        adRetrievalIncrement, adRetrievalStart, searchKeyword, sortSelection, sortV2Selection,
        filterOptions, start, size, sortOptions, previewSelected, filterSelected, weeklyAdZoneLimit
      } = state
      // const { weeklyAdV2Enabled } =  rootState.Optimizely
      const weeklyAdV2Enabled = true
      const getVarByName = rootGetters['SiteConfig/varByName']
      const weeklyAdV2Config = getVarByName('feature_weekly_ad_v2')
      const preview = previewSelected
      let response = {}
      try {
        if (weeklyAdV2Enabled && weeklyAdV2Config && isMobile) {
          const [sortSelectionObj] = sortOptions.filter(sort => sort.value === sortV2Selection)

          const categoryParams = {
            start,
            size: size[sortV2Selection],
            adsSizePerCategory: 25,
            sortSelection: [sortSelectionObj.value],
            currentlyEffectiveAds: getEffectiveAds,
            preview,
            keyword: searchKeyword,
            categoryIds: [],
            filterOptions
          }
          response = await WeeklyAdApi.getWeeklyAdCategory(userId, serviceLocationId, categoryParams)
        } else {
          const [sortSelectionObj] = sortOptions.filter(sort => sort.value === sortSelection)
          const apiParams = {
            start: adRetrievalStart,
            size: getEffectiveAds ? weeklyAdZoneLimit : adRetrievalIncrement,
            sortSelection: sortSelectionObj,
            preview,
            keyword: searchKeyword,
            category: filterSelected,
            currentlyEffectiveAds: getEffectiveAds
          }
          response = await WeeklyAdApi.getWeeklyAdPDL(userId, serviceLocationId, apiParams)
        }
        return { error: false, ads: response, weeklyAdV2Enabled }
      } catch (e) {
        return { error: true, errorStatus: e.status, weeklyAdV2Enabled }
      } finally {
        commit('setPublicationLoaded', true)
      }
    },
    handleAdRetrievalSuccess({ commit, state }, payload) {
      if (payload.status === 200) {
        commit('setHasPreview', payload.data.previewInfo)
        const publication = {
          adsEndDate: payload.data.adsEndDate,
          adsStartDate: payload.data.adsStartDate,
          thumbnailImageUrl: payload.data.thumbnailImageUrl,
          externalDisplayName: payload.data.externalDisplayName,
          pdfUrl: payload.data.pdfUrl
        }
        commit('setPublication', publication)
        commit('setPublicationFilters', payload.data.categoryCounts)

        if (state.initialRetrieval) {
          commit('setCombinedPublications', [publication, state.previewPublication])
          commit('setInitialRetrievalDone')
        }
        commit('setWeeklyAdOffers', payload.data.ads)
        commit('setWeeklyAdTotal', payload.data.total)
        if (state.searchKeyword !== '' || state.filterSelected !== '') {
          commit('setSearchResult', {
            count: state.totalOffers,
            keyword: state.searchKeyword ? state.searchKeyword : state.filterSelected
          })
        }
      }
    },
    handleAdRetrievalFailure({ commit }) {
      commit('setError', {
        name: 'publicationError',
        hasError: true
      })
    },
    async retrieveMoreAds({
      commit, dispatch, rootGetters
    }) {
      commit('increaseAdRetrievalStart')
      const isMobile = rootGetters['ScreenSize/isMobile']
      const response = await dispatch('getProducts', { isMobile, getEffectiveAds: false })
      if (response.error === false) {
        commit('moreAds', response.ads.data.ads)
        return { error: false }
      }
      return { error: true }
    },
    async applySort({ commit, dispatch }, payload) {
      commit('setSortSelection', payload)
      commit('setSortChanged', true)
      commit('resetRetrievalStart')
      await dispatch('retrievePDLAd', { showAd: true })
    },
    async applySortV2({ commit, dispatch }, payload) {
      commit('setSortSelectionV2', payload)
      commit('setSortChanged', true)
      commit('resetRetrievalStart')
      await dispatch('retrievePDLAd', { showAd: true })
    },
    setWeeklyAdViewedThisSession({ state, commit }, payload) {
      const index = state.weeklyAdViewedThisSession.findIndex(e => e.id === payload.id)
      const weeklyAdViewed = [...state.weeklyAdViewedThisSession]
      if (index === -1) weeklyAdViewed.push(payload)
      commit('setWeeklyAdViewedThisSession', weeklyAdViewed)
    },
    setSelectedWeeklyAdProduct({ commit }, payload) {
      commit('setSelectedWeeklyAdProduct', payload)
      commit('detailModalOpen', true)
    },
    closeDetailModal({ commit }) {
      commit('detailModalOpen', false)
    },
    async getOfferQualifyingProducts({ rootGetters }, payload) {
      const {
        isNewProductApiServices, circularId
      } = payload
      const userInfo = rootGetters['UserProfile/userInfo']
      const { userId } = userInfo.information
      const serviceLocationId = rootGetters['UserProfile/serviceLocationId']
      const identifier = circularId
      const params = {
        id: identifier,
        start: 0,
        rows: 50,
        sort: 'bestMatch asc',
        flags: true
      }
      const results = await productAPI.getProducts(
        userId, serviceLocationId, isNewProductApiServices, params
      )
      if (results.status === 200) {
        return results.data.response.products
      }
      return []
    },
    async getWeeklyAdZoneProducts({ state, commit, dispatch }, limit) {
      commit('setWeeklyAdZoneLimit', limit)
      if (state.hasBrickAndMortarStore) {
        const response = await dispatch('getProducts', { getEffectiveAds: true })
        if (response.error === false) {
          commit('setWeeklyAdZoneProducts', response?.ads?.data?.ads)
        }
      }
    },
    async search({ commit, dispatch }, payload) {
      commit('setSearchKeyword', payload.searchTerm)
      commit('resetRetrievalStart')
      await dispatch('retrievePDLAd', { showAd: true })
    },
    async applyFilter({ commit, dispatch }, payload) {
      commit('setFilterSelected', payload.id)
      commit('resetRetrievalStart')
      await dispatch('retrievePDLAd', { showAd: true })
    },
    async clearSearchFilter({ commit, dispatch }) {
      commit('setSearchKeyword', '')
      commit('setFilterSelected', '')
      commit('setSearchResult', null)
      await dispatch('retrievePDLAd', { showAd: true })
    },
    handleSearchFailure({ state, commit }) {
      if (state.searchKeyword !== '') {
        commit('setWeeklyAdOffers', [])
        commit('setWeeklyAdTotal', 0)
        commit('setSearchResult', { count: 0, keyword: state.searchKeyword })
      } else {
        commit('setError', {
          name: 'weeklyAdProductsError',
          hasError: true
        })
      }
    },
    selectPreview({ commit, dispatch }, payload) {
      commit('setPreviewSelected', payload)
      commit('resetPublication')
      commit('resetRetrievalStart')
      dispatch('previewSelectorInteracted')
    },
    previewSelectorInteracted({ commit }) {
      commit('setSelectionModalSeen', true)
    }
  }

}
