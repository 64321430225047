import UserPreferencesAPI from 'api/UserPreferencesAPI'
import UserInvoicesApi from 'api/UserInvoicesAPI'
import { LOGGING_TYPE, USER_PREFERENCE_KEYS } from 'utils/constants'

const initialState = {
  paperlessPreference: true,
  preferenceUpdateSuccess: false,
  preferenceUpdateFailure: false,
  invoices: [],
  failedInvoiceFetch: false,
  failedOrderDetailFetch: false,
  errorCode: null,
  loading: false,
  orderDetails: null,
  serviceType: null,
}

export default {
  namespaced: true,
  state: initialState,
  getters: {
    invoices: state => state.invoices,
    // eslint-disable-next-line  max-params
    getInvoiceUrl: (state, getters, rootState, rootGetters) => (orderId) => {
      const userId = rootGetters['UserProfile/userId']
      const { host, protocol } = window.location || {}
      // ex. https://qa10-stsh.service.qa.peapod.com/apis/fulfillment/5563640/order/v1/invoice/i16658955
      return (!userId || !orderId) ? ''
        : `${protocol}//${host}/apis/fulfillment/${userId}/order/v1/invoice/${orderId}`
    },
    paperlessPreference: state => state.paperlessPreference,
    preferenceUpdateSuccess: state => state.preferenceUpdateSuccess,
    preferenceUpdateFailure: state => state.preferenceUpdateFailure,
    failedInvoiceFetch: state => state.failedInvoiceFetch,
    failedOrderDetailFetch: state => state.failedOrderDetailFetch,
    errorCode: state => state.errorCode,
    loading: state => state.loading,
    orderDetails: state => state.orderDetails,
    serviceType: state => state.serviceType
  },
  mutations: {
    setInvoices(state, value) {
      state.invoices = value
    },
    paperlessPreference(state, value) {
      state.paperlessPreference = value
    },
    setPreferenceUpdateSuccess(state, value) {
      state.preferenceUpdateSuccess = value
    },
    setPreferenceUpdateFailure(state, value) {
      state.preferenceUpdateFailure = value
    },
    resetResultStates(state) {
      state.preferenceUpdateSuccess = false
      state.preferenceUpdateFailure = false
    },
    setFailedInvoiceFetch(state, value) {
      state.failedInvoiceFetch = value
    },
    setfailedOrderDetailFetch(state, value) {
      state.failedOrderDetailFetch = value
    },
    setErrorCode(state, value) {
      state.errorCode = value
    },
    setLoading(state, value) {
      state.loading = !!value
    },
    setOrderDetails(state, value) {
      state.orderDetails = value
    },
    setServiceType(state, value) {
      state.serviceType = value
    }
  },
  actions: {
    retrieveOnlineOrderHistory: async ({ rootGetters }) => {
      const userId = rootGetters['UserProfile/userId']
      let response = Promise.resolve({
        status: 400,
        data: {
          response: {
            errors: ['ORDER_HISTORY_NOT_AVAILABLE']
          }
        }
      })

      if (userId) {
        response = await UserInvoicesApi.getOrderHistory(`${userId}`)
      }
      return response
    },
    retrieveInstoreOrderHistory: async ({ rootGetters }) => {
      const payload = {
        userId: rootGetters['UserProfile/userId'],
        loyaltyId: rootGetters['LoyaltyAccount/cardNumber']
      }
      let response = Promise.resolve({
        status: 400,
        data: {
          response: {
            errors: ['ORDER_HISTORY_NOT_AVAILABLE']
          }
        }
      })

      try {
        const missingFields = []

        Object.keys(payload).forEach((key) => {
          if (!payload[key]) {
            missingFields.push(key)
          }
        })

        if (missingFields.length > 0) {
          throw new Error({ message: `Missing fields: ${missingFields.join(', ')}` })
        }

        response = await UserInvoicesApi.getInstoreOrderHistory(payload)
        if (response.status !== 200) {
          throw new Error(response)
        }
      } catch (err) {
        this.$trackClientLog('instore_history_get_error', err, LOGGING_TYPE.exception)
      }
      return response
    },
    retrieveCurrentPaperlessPreference: async ({ commit, rootGetters }) => {
      const userId = rootGetters['UserProfile/userId']
      const paperlessPref = await UserPreferencesAPI.get(USER_PREFERENCE_KEYS.PAPERLESS, userId)

      if (paperlessPref.status !== 200) return

      commit('paperlessPreference', paperlessPref.data.response.preference.value)
    },
    retrieveOrderDetails: async ({ commit, rootGetters }, orderData = {}) => {
      commit('setfailedOrderDetailFetch', false)
      const { basketId, serviceType } = orderData
      const userId = rootGetters['UserProfile/userId']
      const response = await UserInvoicesApi.getOrderDetails(userId, basketId, serviceType)

      if (response.status !== 200) {
        commit('setfailedOrderDetailFetch', true)
      }

      if (response.status === 200) {
        const orderDetails = (serviceType === 'INSTORE') ? response?.data : response?.data?.response
        commit('setOrderDetails', orderDetails)
        commit('setServiceType', serviceType)
        return (serviceType === 'INSTORE') ? response?.data : response?.data?.response
      }

      return null
    },
    setPaperlessInvoicePreference: async ({ commit, rootGetters }, payload) => {
      const userId = rootGetters['UserProfile/userId']
      const preferenceUpdate = await UserPreferencesAPI.update(
        USER_PREFERENCE_KEYS.PAPERLESS, payload, userId
      )

      const trackClientLog = window.sharedVue.config.globalProperties.$trackClientLog
      if (preferenceUpdate.status !== 200) {
        commit('setPreferenceUpdateFailure', true)
        trackClientLog('paperless_pref_update_error', {}, LOGGING_TYPE.exception)
      } else {
        commit('paperlessPreference', payload)
        commit('setPreferenceUpdateSuccess', true)
        trackClientLog('paperless_pref_update', {}, LOGGING_TYPE.event)
      }
    }
  }
}
