const prodHostnameMap = [
  'foodlion.com',
  'giantfood.com',
  'giantfoodstores.com',
  'hannaford.com',
  'martinsfoods.com',
  'peapod.com',
  'stopandshop.com',
  'su-fdln.service.prod.peapod.com',
  'su-gntc.service.prod.peapod.com',
  'su-gntl.service.prod.peapod.com',
  'su-mrtn.service.prod.peapod.com',
  'su-stsh.service.prod.peapod.com',
]

const stageEnvMap = [
  'stage',
  'sit',
  'kodiak',
  'ada'
]

const cf2HostnameMap = {
  GNTL: 'giantfood.com',
  MRTN: 'martinsfoods.com',
  GNTC: 'giantfoodstores.com',
  STSH: 'stopandshop.com',
  PPOD: 'peapod.com',
  FDLN: 'foodlion.com',
  HNFD: 'hannaford.com'
}

const buildCf2Uri = (env, opco) => {
  const envName = getEnvironmentAbbreviation(env)
  if (envName === 'prod') return `https://core.pdl.${cf2HostnameMap[opco]}/${envName}/apollo/graphql`
  if (envName === 'dev') return `https://nonprd-core.pdl.${cf2HostnameMap[opco]}/${envName}/apollo/graphql`
  return `https://nonprd-core.pdl.${cf2HostnameMap[opco]}/${envName}/${opco.toLowerCase()}/apollo/graphql`
}

export const getEnvironmentAbbreviation = (env) => {
  let envName = env
  if (env.indexOf('dev') === 0) envName = 'dev'
  if (stageEnvMap.includes(env)) envName = 'stage'
  if ((env.indexOf('qa') === 0
  || env.indexOf('alpha') === 0)) envName = 'qa'

  return envName
}

export const determineEnvironment = (hostname, opco) => {
  let env

  // Check if production. All prism production envs hostname does not include sub domain
  prodHostnameMap.forEach((prodHostname) => {
    if (hostname === prodHostname) env = 'prod'
  })

  if (env) return env

  const siteSubdomain = hostname.split('.')[0]

  if (siteSubdomain.indexOf('preview') === 0 && opco === 'FDLN') {
    return 'prod'
  }

  if (stageEnvMap.includes(siteSubdomain) || siteSubdomain.indexOf('alpha') === 0) {
    return siteSubdomain
  }

  if (siteSubdomain.indexOf('qa') === 0) {
    return siteSubdomain.split('-')[0]
  }

  return 'dev'
}

export const getGraphQLUri = (env, opco) => {
  return buildCf2Uri(env, opco)
}
