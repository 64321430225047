/** function dateWithinRange - checks number of days to coupon/offer expiry.
 * @param date - date you are checking
 * @param range - range from today you are checking against
 * @return calculateDaysSinceStart boolean - if date is less than range days away
* */
export default function (date, range) {
  const offerStartDateTime = window.LuxonDateTime.fromISO(date)
  const currentDateTime = window.LuxonDateTime.local()
  const daysApart = currentDateTime.diff(offerStartDateTime, 'days').toObject() // => { days: 121 }
  return daysApart.days < range
}
