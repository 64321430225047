import ApiService from '@/api/'

export default {
  addDevice(deviceInfo, deviceType, userId) {
    const data = {
      deviceId: deviceInfo.deviceToken,
      vendorDeviceId: deviceInfo.vendorToken,
      deviceType
    }
    return ApiService.post(`/api/v4.0/user/${userId}/devices`, data)
  },
  removeDevice(deviceInfo, userId) {
    return ApiService.delete(`/api/v4.0/user/${userId}/devices`, {
      params: {
        vendorDeviceId: deviceInfo.vendorToken
      }
    })
  },
}
