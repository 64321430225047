<template>
  <div
    class="bg-[#F8F8F8]">
    <div
      class="relative max-w-[192rem] mx-auto"
      @mouseleave="closeViewMoreMenu"
      @focusout="closeViewMoreMenu"
    >
      <button
        v-show="canScrollLeft && !isMobile"
        class="absolute left-3 w-16 h-full z-50"
        aria-label="Previous Categories"
        @click="scrollPills('left')"
      >
        <PdlIconBase
          icon-title="Previous Categories"
          :icon-class="'vector-icon-stroke--prime'"
        >
          <PdlIconArrowLeftThin
            aria-label="Scroll left to view previous categories"
            tabindex="0"
          />
        </PdlIconBase>
      </button>
      <div
        class="
          flex flex-row flex-nowrap
          h-[9.5rem]
          w-full
          bg-[#F8F8F8]
          border-b border-solid border-gray-100
        "
        :class="{'border-t': !isDesktop}"
      >
        <div
          v-show="canScrollLeft && !isMobile"
          class="absolute top-0 -left-4 mt-0.5 z-40 w-44 h-[95%] category-nav_previous-button-gradient"
        />
        <ul
          ref="productCategories"
          class="flex flex-row flex-nowrap overflow-auto category-nav max-w-[192rem] mx-auto"
          role="menu"
        >
          <li
            v-for="(category, index) in allCategories"
            class="cursor-pointer"
            :key="index"
            :role="category.categoryName === 'Divider'? '' : 'menuitem'"
            :tabindex="category.categoryName === 'Divider'? '-1' : '0'"
            @keydown.enter="category.categoryName === 'View More' ?
            toggleViewMore(category) : categorySelected(category, index) "
          >
            <div
              v-if="category.categoryName === 'Divider'"
              class="h-28 mt-5 border-l border-solid border-hover mx-4"
            />
            <div
              v-else
              v-content-tracker="{
                contentData: category.contentData,
                scheduledData: category.scheduledContentData,
                getContentLogBody,
                isTrackingEnabled: !!category.contentData,
              }"
              :id="`nav-${category.categoryName}`"
              class="w-[9.8rem] h-full flex text-center"
              :class="{
                'ml-6': index === 0,
                'mr-6': index === allCategories.length - 1,
                'spyglass-wrapper': spyglassActive,
                'category-nav-redesign-active-state': isActive(category.categoryName)
              }"
              @click.prevent="categorySelected(category, index)"
              @keydown.enter="categorySelected(category, index)"
              @mouseenter.self="toggleViewMore(category)"
              @mouseleave.self="toggleViewMore(category)"
              @focusin.self="toggleViewMore(category)"
              @focusout.self="toggleViewMore(category)"
            >
              <div
                class="flex flex-col w-full hover:bg-hover items-center px-2"
                :class="{
                  'bg-hover': isActive(category.categoryName),
                  'hover:font-bold': category.categoryName === 'View More'
                }"
              >
                <div
                  v-if="hasCategoryImage(category)"
                  class="h-12 w-12 mx-14 mt-8 bg-contain bg-center bg-no-repeat"
                  :style="getCategoryImage(category)"
                />
                <div
                  v-else
                  class="h-12 w-12 mx-12 mt-8 bg-contain bg-center bg-no-repeat
                  "
                  :style="getCategoryIcon(category)"
                />
                <span
                  class="flex mt-3 h-1/3 text-[1.3rem] leading-[1.4rem] text-gray-900"
                  :class="{
                      'font-bold': isActive(category.categoryName)
                    }"
                >
                  {{ getCategoryName(category) }}
                  <PdlIconBase
                    v-if="category.categoryName === 'View More'"
                    icon-title="down arrow"
                    :vb-height="40"
                    :vb-width="40"
                    container-class="vector-icon-size--small stroke-black ml-2"
                    aria-label="Toggle view more categories"
                  >
                    <PdlIconCarrotUp
                      v-if="showViewMoreCategories"
                      :stroke-width="showViewMoreCategories ? '4' : '3'"
                    />
                    <PdlIconCarrotDown
                      v-if="!showViewMoreCategories"
                    />
                  </PdlIconBase>
                </span>
                <div
                  v-if="isActive(category.categoryName)"
                  class="w-12 border-2 border-solid border-primary mx-[2.25rem] mt-0.5"
                />
              </div>
              <PdlSpyglass
                v-if="spyglassActive && category.spyGlassData"
                :spyglass-content="category.spyGlassData"
                :is-group="false"
                tabindex="-1"
              />
            </div>
          </li>
        </ul>
        <div
          v-show="canScrollRight && !isMobile"
          class="absolute top-0 -right-4 mt-0.5
            z-40 w-44 h-[95%] category-nav_next-button-gradient"
        />
        <div
          ref="viewMoreMenu"
          v-if="showViewMoreCategories"
          class="absolute bg-white border border-gray-100 rounded-t-none
            rounded-b-md shadow-lg top-[9.5rem] z-[9999]"
        >
           <CategoryNavigationMenu
            :index="viewMoreMenuItemIndex"
            :categories="viewMoreCategories"
            @category-clicked="categorySelected"
          />
        </div>
      </div>
      <button
        v-show="canScrollRight && !isMobile"
        aria-label="Next Categories"
        class="absolute top-0 right-3 w-16 h-full z-50"
        @click="scrollPills('right')"
        tabindex="0"
      >
        <PdlIconBase
          icon-title="Next Categories"
          :icon-class="'vector-icon-stroke--prime'"
        >
          <PdlIconArrowRightThin
            aria-label="Scroll right to view next categories"
          />
        </PdlIconBase>
      </button>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import PdlIconArrowLeftThin from 'components/icons/icon/PdlIconArrowLeftThin'
import PdlIconArrowRightThin from 'components/icons/icon/PdlIconArrowRightThin'
import PdlIconCarrotDown from 'components/icons/icon/PdlIconCarrotDown'
import PdlIconCarrotUp from 'components/icons/icon/PdlIconCarrotUp'
import contentTracker from 'utils/custom-directives/contentTracker'
import browseAislesGetCategoryUrl from 'utils/filters/browseAislesGetCategoryUrl'
import scrollElementToCenter from 'utils/filters/scrollElementToCenter'
import compileLinkVars from 'utils/mixins/compileLinkVars'
import { CDN_URL } from 'utils/constants'
import ProductContentService from 'utils/services/product-content-service'
import getContentImgSrc from 'utils/lib/contentImgSrc'
import PdlSpyglass from 'components/spyglass/PdlSpyglass'
import { trackBrowseAisle } from 'utils/tracking/trackBrowseAisle'
import categoryNavGetCategoryName from 'utils/filters/categoryNavGetCategoryName'
import CategoryNavigationMenu from './CategoryNavigationMenu'


const RECOMMEND_FOR_YOU_AS_A_CATEGORY = {
  categoryName: 'For You',
  categoryIcon: '4189259:IKWRU.png',
}

const VIEW_MORE_AS_A_CATEGORY = {
  categoryName: 'View More',
  categoryIcon: '4224786:5SW6R.png',
}

const DIVIDER = {
  categoryName: 'Divider',
}

export default {
  components: {
    PdlSpyglass,
    PdlIconArrowLeftThin,
    PdlIconArrowRightThin,
    PdlIconCarrotDown,
    PdlIconCarrotUp,
    CategoryNavigationMenu,
  },
  directives: {
    contentTracker,
  },
  mixins: [compileLinkVars],
  props: {
    pageView: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    canScrollLeft: false,
    canScrollRight: false,
    spyGlassData: { parent: [] },
    showViewMoreCategories: false
  }),
  computed: {
    ...mapState({
      categories: state => state.BrowseAislesCategories.categories,
      scheduledContentCategories: state => state.BrowseAislesCategories.reArrangedScheduledContentCategories,
      subCategoryName: state => state.BrowseAislesCategories.subCategoryName,
      categoryBgImg: state => state.BrowseAislesCategories.categoryBgImg,
      leafCategory: state => state.BrowseAislesCategories.leafCategory,
      hideRecommendedForYou: state => state.Optimizely.hideRecommendedForYou
    }),
    ...mapGetters({
      spyglassActive: 'ScheduledContent/spyglassActive',
      isDesktop: 'ScreenSize/isDesktop',
      isMobile: 'ScreenSize/isMobile'
    }),
    getActiveTopCategory() {
      return this.leafCategory?.categoryName
    },
    getCategoryTreeId() {
      return this.leafCategory?.categoryTreeId
    },
    topCategories() {
      return this.categories
    },
    isMoreScheduledCategories() {
      return this.scheduledContentCategories?.length > 3
    },
    scheduledCatAndLinks() {
      let links = [RECOMMEND_FOR_YOU_AS_A_CATEGORY]
      if (this.hideRecommendedForYou) {
        links = []
      }
      if (this.isMoreScheduledCategories) {
        return [
          ...links,
          ...this.scheduledContentCategories.slice(0, 2),
          VIEW_MORE_AS_A_CATEGORY,
          DIVIDER,
        ]
      }
      return [...links, ...this.scheduledContentCategories, DIVIDER]
    },
    allCategories() {
      return [...this.scheduledCatAndLinks, ...this.topCategories]
    },
    viewMoreCategories() {
      return this.scheduledContentCategories.slice(
        2,
        this.scheduledContentCategories.length
      )
    },
    isBrowseAislesPage() {
      return this.$route.path.includes('browse-aisles')
    },
    isPastPurchasesPage() {
      return this.$route.path.includes('past-purchases')
    },
    isRecommendationsPage() {
      return this.$route.path.includes('recommended-for-you')
    },
    viewMoreMenuItemIndex() {
      return this.allCategories.indexOf(VIEW_MORE_AS_A_CATEGORY)
    }
  },
  async created() {
    await this.fetchScheduledContent()
  },
  watch: {
    getActiveTopCategory(newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        this.centerPill()
      }
    },
    showViewMoreCategories(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.setViewMorePosition()
      }
    }
  },
  mounted() {
    this.checkLeftRightButtons()
    this.debounceScroll = _.debounce(this.checkLeftRightButtons, 50)
    this.$refs.productCategories.addEventListener('scroll', this.debounceScroll)
    this.trackTopCategoryClick()
    this.centerPill()
  },
  methods: {
    ...mapActions({
      fetchScheduledContent: 'BrowseAislesCategories/fetchScheduledContent',
    }),
    hasCategoryImage(category) {
      const imgObjList = this.categoryBgImg.filter(
        item => category.categoryName === item.categoryName
      )
      const imgObj = imgObjList && imgObjList.length > 1
        ? imgObjList[imgObjList.length - 1]
        : imgObjList[0]
      return imgObj?.categoryIcon
    },
    getCategoryName(category) {
      const nameToDisplay = categoryNavGetCategoryName(category)
      return this.cleanAllExpressions(nameToDisplay)
    },
    getCategoryImage(category) {
      const imgObjList = this.categoryBgImg.filter(
        item => category.categoryName === item.categoryName
      )
      const imgObj = imgObjList && imgObjList.length > 1
        ? imgObjList[imgObjList.length - 1]
        : imgObjList[0]
      if (imgObj) {
        const imgUrl = imgObj.categoryIcon
          ? getContentImgSrc(CDN_URL, imgObj.categoryIcon)
          : ''
        return {
          backgroundImage: `url(${imgUrl})`,
        }
      }
      return {}
    },
    isActive(key) {
      if (key === RECOMMEND_FOR_YOU_AS_A_CATEGORY.categoryName && this.isRecommendationsPage) {
        return true
      }
      return this.getActiveTopCategory === key && this.isBrowseAislesPage
    },
    getCategoryIcon(category) {
      let imgUrl
      if (category.catImageURL) {
        imgUrl = category.catImageURL
      } else {
        imgUrl = getContentImgSrc(CDN_URL, category.categoryIcon)
      }
      return {
        backgroundImage: `url(${imgUrl})`,
      }
    },
    scrollPills(type) {
      const categoriesWrapper = this.$refs.productCategories
      let left = 0
      if (type === 'left') {
        left = categoriesWrapper?.scrollLeft - categoriesWrapper?.clientWidth / 2
      } else {
        left = categoriesWrapper?.scrollLeft + categoriesWrapper?.clientWidth / 2
      }
      categoriesWrapper.scroll({ left, behavior: 'smooth' })
      this.$emit('track-nav-updated', {
        action: this.isBrowseAislesPage ? 'browse-aisles' : 'categories',
        url: type === 'left' ? 'left arrow' : 'right arrow',
        label: type === 'left' ? 'left arrow' : 'right arrow'
      })
    },
    checkLeftRightButtons() {
      const { scrollLeft, scrollWidth, clientWidth } = this.$refs.productCategories || {}
      this.canScrollLeft = scrollLeft > 0
      if (scrollLeft === 0 || scrollLeft < scrollWidth - clientWidth) {
        this.canScrollRight = true
      } else if (scrollLeft === scrollWidth - clientWidth) {
        this.canScrollRight = false
      }
    },
    closeViewMoreMenu() {
      // focusout occurs before click event, so wait for click event before closing
      setTimeout(() => {
        this.showViewMoreCategories = false
      }, 500)
    },
    categorySelected(category, index) {
      if (category.categoryName === VIEW_MORE_AS_A_CATEGORY.categoryName) {
        this.showViewMoreCategories = !this.showViewMoreCategories
        return
      }
      if (
        category.categoryName === RECOMMEND_FOR_YOU_AS_A_CATEGORY.categoryName
      ) {
        this.$router.push('/recommended-for-you')
        this.trackforGTMNavUpdateEvent(category, index, '/recommended-for-you')
        return
      }
      const url = browseAislesGetCategoryUrl(category)
      ProductContentService.trackCategoryContentClicked(category)
      ProductContentService.setCMSCategoryAsCurrentScheduledContent(
        url,
        category
      )
      this.trackforGTMNavUpdateEvent(category, index, url)
      this.$router.push(url)
    },
    trackforGTMNavUpdateEvent(category, index, url) {
      const topCategoryIndex = this.topCategories.indexOf(category)
      if (topCategoryIndex >= 0) {
        index = this.viewMoreMenuItemIndex + this.viewMoreCategories.length + topCategoryIndex
      }
      this.$emit('track-nav-updated', {
        action: 'categories',
        label: category.categoryName,
        url,
        index: index + 1
      })
    },
    toggleViewMore(category) {
      if (!this.isDesktop) {
        return
      }
      if (category.categoryName === VIEW_MORE_AS_A_CATEGORY.categoryName) {
        this.showViewMoreCategories = true
      } else {
        this.showViewMoreCategories = false
      }
    },
    getContentLogBody(payload) {
      this.logData = payload
    },
    setViewMorePosition() {
      if (this.showViewMoreCategories) {
        this.$nextTick(() => {
          const leftPos = document.getElementById('nav-View More')?.getBoundingClientRect()?.left
          this.$refs.viewMoreMenu.style.left = `${leftPos}px`
        })
      }
    },
    trackTopCategoryClick() {
      const payload = {
        categoryTreeId: this.getCategoryTreeId,
        categoryName: this.subCategoryName,
        majorCategory: this.getActiveTopCategory,
      }
      trackBrowseAisle(payload)
    },
    centerPill() {
      setTimeout(() => {
        scrollElementToCenter(
          this.$refs.productCategories,
          this.$refs.productCategories?.querySelector('.category-nav-redesign-active-state')
        )
      }, 100)
    }
  },
}
</script>
