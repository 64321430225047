import ApiService from '@/api/'
import { SUPERUSER_ERROR_CODES } from 'utils/constants'

const { SESSION_LOCKED, SESSION_ABORTED, SESSION_EXPIRED } = SUPERUSER_ERROR_CODES

const superuserTokenKey = 'suTkn'

const shouldLogoutSuperuser = (error) => {
  switch (error.status) {
    case 401:
      return true
    case 403:
      return true
    case 409:
      switch (error.data.response.code) {
        case SESSION_LOCKED:
        case SESSION_ABORTED:
        case SESSION_EXPIRED:
          return true
        default:
          return false
      }
    default:
      return false
  }
}

export default {
  async getOrderMode(userId, featureApiSessionOrder) {
    if (!this.tokenExists()) {
      return this.removeSuperuserStateAndLogout()
    }
    try {
      let response
      let mode
      if (featureApiSessionOrder) {
        response = await ApiService.get(`/api/v6.0/user/${userId}/customerCare/currentSessionOrder`)
        mode = response.data.sessionOrderStatus
      } else {
        response = await ApiService.get('/api/v3.0/customerCare/currentSessionOrder')
        mode = response.data.response.msg
      }

      return mode
    } catch (error) {
      return this.handleError(error)
    }
  },
  updateOrderMode(orderMode, userId, featureApiSessionOrder) {
    if (!this.tokenExists()) {
      return this.removeSuperuserStateAndLogout()
    }
    try {
      if (featureApiSessionOrder) {
        return ApiService.put(`/api/v6.0/user/${userId}/customerCare/currentSessionOrder`, {
          sessionOrderStatus: orderMode
        })
      }
      return ApiService.put('/api/v3.0/customerCare/currentSessionOrder', {
        state: orderMode
      })
    } catch (error) {
      return this.handleError(error)
    }
  },
  async getCurrentBasketId(userId) {
    return ApiService.get(`/api/v6.0/user/${userId}/order/superuser-current`)
  },
  async login(params) {
    let result
    try {
      result = await ApiService.post(
        `/api/v4.0/customerCare/login`,
        params
      )
      if (result.status !== 200) throw new Error(result.data.response.code)
    } catch (error) {
      this.handleError(result)
      throw error // for UI
    }
  },
  logoutSuperuser(router) {
    this.removeSuperuserStateAndLogout(router)
  },
  removeSuperuserStateAndLogout() {
    this.deleteToken()
    if (window.location.pathname !== '/shop/auth/login/customer-care') {
      window.location = '/shop/auth/login/customer-care'
    }
  },
  handleError(error) {
    if (shouldLogoutSuperuser(error)) {
      this.removeSuperuserStateAndLogout()
    }
    return error
  },
  deleteToken() {
    window.sharedVue.config.globalProperties.$cookies.remove(superuserTokenKey, '/', '.peapod.com')
  },
  tokenExists() {
    const token = window.sharedVue.config.globalProperties.$cookies.get(superuserTokenKey)
    return !!(token?.length)
  },
  getToken() {
    return window.sharedVue.config.globalProperties.$cookies.get(superuserTokenKey)
  }
}
