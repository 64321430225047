<template>
  <PdlIconBase
    icon-title="Checked"
    icon-class="vector-icon-color--white"
    container-class="vector-icon-size--small"
  >
    <PdlIconCheck />
  </PdlIconBase>
</template>
<script>
import {
  PdlIconBase,
} from '@/shared/icons'
import PdlIconCheck from 'components/icons/icon/PdlIconCheck'

export default {
  components: {
    PdlIconBase,
    PdlIconCheck
  }
}
</script>
