import HealthPreferencesAPI from 'api/HealthPreferencesAPI'
import { GTM } from 'utils/constants'

// To be removed after health-preference 2.0
const listOfDietaryPreferences = [
  'GLUTEN_FREE',
  'DAIRY_FREE',
  'KOSHER',
  'ORGANIC',
  'VEGAN',
  'VEGETARIAN',
  'GUIDING_STARS',
  'USDA_LOW_SODIUM'
]

export default {
  namespaced: true,
  state: {
    healthPreferences: [],
    allergens: [],
    dietaryRestrictions: [],
    selectedArr: []
  },
  getters: {
    currentDietaryPreferences: state => _.filter(state.healthPreferences,
      item => item.id !== '_78' && listOfDietaryPreferences.includes(item.id)),
    dietaryPreferences: state => _.filter(state.healthPreferences,
      item => item.id !== '_78'),
    sustainabilityPreferences: state => _.filter(state.healthPreferences,
      item => item.id === '_78'),
    allergens: state => _.filter(state.healthPreferences,
      item => item.type === 'ALLERGEN'),
    dietaryRestrictions: state => _.filter(state.healthPreferences,
      item => item.type === 'DIETARY_PREFERENCE')
  },
  mutations: {
    setPreferences(state, payload = []) {
      state.healthPreferences = payload
    },
    togglePreference(state, value) {
      const index = state.healthPreferences.findIndex(item => item.id === value)
      state.selectedArr.push(value)
      state.healthPreferences[index].selected = !state.healthPreferences[index].selected
    },
    logSavePreference(state) {
      state.selectedArr.forEach((id) => {
        const index = state.healthPreferences.findIndex(item => item.id === id)
        const preference = state.healthPreferences[index]
        const payload = {
          section: preference.id === '_78' || preference.id === 'GUIDING_STARS'
            ? 'sustainability preferences' : 'dietary preferences',
          preference: state.healthPreferences[index].name,
          status: state.healthPreferences[index].selected ? 'on' : 'off'
        }

        window.sharedVue.config.globalProperties.$trackGtmEvent(GTM.changeHealthPreference, {
          event: GTM.changeHealthPreference,
          category: 'account',
          action: 'update health & lifestyle preferences',
          label: `${payload.section} - ${payload.preference} - ${payload.status}`
        })
      })
    },
    resetPreference(state) {
      state.healthPreferences.forEach((item) => {
        const selectedId = state.selectedArr.find(id => id === item.id)
        if (selectedId) {
          item.selected = false
        }
      })
      state.selectedArr = []
    },
    resetSelectedArr(state) {
      state.selectedArr = []
    }
  },
  actions: {
    async getHealthPreferences({ commit, rootGetters }) {
      let response = {}
      const isLoggedIn = rootGetters['LoginStatus/isLoggedIn']
      let userId = appConfig?.user?.userId
      if (isLoggedIn && (!userId || userId === 2)) {
        userId = rootGetters['UserProfile/currentUserId']
      }
      response = userId !== 2 ? await HealthPreferencesAPI.get(userId) : {}
      commit('setPreferences', response?.data?.dietaryPreferences)
      return response
    },
    async updateHealthPreferences({ commit, rootGetters }, payload) {
      let response = {}
      const isLoggedIn = rootGetters['LoginStatus/isLoggedIn']
      let userId = appConfig?.user?.userId
      if (isLoggedIn && (!userId || userId === 2)) {
        userId = rootGetters['UserProfile/currentUserId']
      }
      response = userId !== 2 ? await HealthPreferencesAPI.put(payload, userId) : {}
      commit('logSavePreference')
      commit('resetSelectedArr')
      return response
    }
  }
}
