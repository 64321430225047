<template>
  <div
    class="filter-dropdown_button-container"
    :class="{'filter-dropdown_button-container--inline' : inline}"
  >
    <div>
      <button
        ref="button"
        :class="[
          'filter-dropdown_button relative',
          {'filter-dropdown_button--expanded': isPanelOpen}
        ]"
        :aria-controls="panelId"
        :aria-expanded="isPanelOpen.toString()"
        @click="toggleDropdown"
      >
        {{ label }}
        <PdlIconBase
          icon-title="Show dropdown"
          :icon-class="isPanelOpen ? `vector-icon-stroke--prime open`: 'vector-icon-stroke--prime'"
          container-class="vector-icon-size--medium"
        >
          <PdlIconCarrotDown />
        </PdlIconBase>
      </button>
    </div>
    <div
      v-show="isPanelOpen"
      :id="panelId"
      ref="panel"
      :class="['filter-dropdown_pane', {
        'filter-dropdown_pane--full-width' : isFullWidth,
        'filter-dropdown_pane--drop-left' : isDropLeft
      }]"
      role="region"
      tabindex="-1"
    >
      <slot />
      <option
        v-for="(option, i) in options"
        :key="i"
        class="select_option"
        :value="option.name"
      >
        {{ option.name }}
      </option>
    </div>
  </div>
</template>

<script>
import {
  PdlIconBase
} from '@/shared/icons'
import PdlIconCarrotDown from 'components/icons/icon/PdlIconCarrotDown'

export default {
  components: {
    PdlIconBase,
    PdlIconCarrotDown
  },
  props: {
    panelId: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    inline: {
      type: Boolean,
      default: false
    },
    isFullWidth: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      required: false,
      default() {
        return []
      }
    },
    isDropLeft: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isPanelOpen: false
    }
  },
  methods: {
    closeInteraction() {
      this.isPanelOpen = false
    },
    toggleDropdown() {
      if (this.isPanelOpen) {
        // optional action that can be triggered on closing the panel/drop down
        this.$emit('toggle-dropdown-action')
      }
      this.isPanelOpen = !this.isPanelOpen
      this.$refs[this.isPanelOpen ? 'panel' : 'button'].focus()
    }
  }
}
</script>
