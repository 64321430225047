<template>
  <g>
    <PdlIconSns
      v-if="isOpco('STSH')"
    />
    <PdlIconGntl
      v-if="isOpco('GNTL')"
    />
    <PdlIconGntc
      v-if="isOpco('GNTC')"
    />
    <PdlIconMrtn
      v-if="isOpco('MRTN')"
    />
    <PdlIconFdlnHorizontal
      v-if="isOpco('FDLN')"
    />
    <PdlIconHnfd
      v-if="isOpco('HNFD')"
    />
    <PdlIconPeapod
      v-if="isOpco('PPOD')"
    />
  </g>
</template>
<script>
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    PdlIconPeapod: defineAsyncComponent(() => import('components/icons/logo/PdlIconPeapod')),
    PdlIconGntl: defineAsyncComponent(() => import('components/icons/logo/PdlIconGntl')),
    PdlIconGntc: defineAsyncComponent(() => import('components/icons/logo/PdlIconGntc')),
    PdlIconMrtn: defineAsyncComponent(() => import('components/icons/logo/PdlIconMrtn')),
    PdlIconSns: defineAsyncComponent(() => import('components/icons/logo/PdlIconSns')),
    PdlIconFdlnHorizontal: defineAsyncComponent(() => import('components/icons/logo/PdlIconFdlnHorizontal')),
    PdlIconHnfd: defineAsyncComponent(() => import('components/icons/logo/PdlIconHnfd'))
  },
  methods: {
    isOpco(opco) {
      return appConfig.opcoTheme === opco
    }
  }
}
</script>
