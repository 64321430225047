import ApiService from '@/api/'

export default {
  getSummary(data, cartBasketData) {
    return ApiService.get(
      `/api/v6.0/user/${cartBasketData.userId}/order/${cartBasketData.basketId}/summary`,
      data
    )
  },
  getPurchaseOrder(payload) {
    return ApiService.get(`/api/v6.0/user/${payload.userId}/order/${payload.basketId}`
    + `/purchaseOrder`, payload.params)
  },
  updatePurchaseOrder(payload) {
    return ApiService.put(`/api/v6.0/user/${payload.userId}/order/${payload.basketId}`
    + `/purchaseOrder`, payload.params)
  },
  deletePurchaseOrder(payload) {
    return ApiService.delete(`/api/v6.0/user/${payload.userId}/order/${payload.basketId}/purchaseOrder`)
  },
  cancelOrder(payload) {
    return ApiService.delete(`/api/v6.0/user/${payload.userId}/order/${payload.basketId}`)
  },
  saveItemComment(userId, basketId, productId, comment) {
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    }
    return ApiService.put(`/api/v4.0/user/${userId}/order/${basketId}/product/${productId}/comment`,
      comment, { headers })
  },
  getItemComment(userId, basketId, productId) {
    return ApiService.get(`/api/v4.0/user/${userId}/order/${basketId}/product/${productId}/comment`)
  },
  getAllItemComments(userId, basketId) {
    return ApiService.get(`/api/v4.0/user/${userId}/order/${basketId}/product/comment`)
  },
  deleteItemComment(userId, basketId, productId) {
    return ApiService.delete(`/api/v4.0/user/${userId}/order/${basketId}/product/${productId}/comment`)
  },
  getLastOrder(userId, basketId) {
    return ApiService.get(`/api/v6.0/user/${userId}/order/${basketId}/last-order?productStatus=ALL`)
  }
}
