import ApiService from '@/api/'

export default {
  create() {
    const sessionUpdatesEnabled = window.sharedVue.config.globalProperties.$store.getters['SiteConfig/varByName'](
      'feature_session_updates'
    )
    if (sessionUpdatesEnabled) {
      return ApiService.get('/api/v2.0/sessionid', {
        headers: {
          Authorization: `Basic ${btoa(appConfig.apiKey)}`,
        },
      })
    }
    return Promise.resolve(false)
  },
  exitSession() {
    const sessionUpdatesEnabled = window.sharedVue.config.globalProperties.$store.getters['SiteConfig/varByName'](
      'feature_session_updates'
    )
    if (sessionUpdatesEnabled) {
      return ApiService.post('/api/v2.0/session-exit')
    }
    return Promise.resolve(false)
  },
}
