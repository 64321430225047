/** function parseShortDate - parse date string for PDL order date format
 *
 * @param date string - time string to be formatted
 * @return string - parsed out date string
* */

const shortDateRegex = /^(\d{4})-(\d{1,2})-(\d{1,2})$/

export default function (dateString) {
  if (!shortDateRegex.test(dateString)) {
    return new Date()
  }

  const matches = dateString.match(shortDateRegex)
  const year = parseInt(matches[1], 10)
  const month = parseInt(matches[2], 10)
  const day = parseInt(matches[3], 10)

  if (Number.isNaN(year)
  || Number.isNaN(month)
  || Number.isNaN(day)) {
    return new Date()
  }

  return new Date(year, month - 1, day)
}
