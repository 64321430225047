import ApiService from '@/api/'

export default function (apiPathParams, unitPrice, prodCategory, price) {
  const params = {
    facet: 'categories,brands,nutrition,sustainability,specials,newArrivals,privateLabel',
    facetExcludeFilter: true,
    filter: `unitprice:lt${unitPrice};privateLabel;prodCategory:${prodCategory};price:lt${price}`,
    flags: true,
    ...(apiPathParams.isSponsoredAdsFromCitrus && { includeSponsoredProducts: false }),
    nutrition: true,
    rows: 120,
    start: 0,
    sort: 'itemsPurchased desc,name asc'
  }
  if (apiPathParams.isNewProductApiServices) {
    return ApiService.get(`/api/v5.0/products/${apiPathParams.userId}/${apiPathParams.serviceLocationId}`, { params })
  }
  return ApiService.get('/api/v4.0/user/products', { params })
}
