// Handle back button press on browser and title bar when Weekly Ad
// print view's iframe adds extra history entries. Store locator must
// also be handled because the user can view the circular there too,
// causing the same issue where the back button does not work as expected.

const weeklyAdPrintView = '/savings/weekly-ad/print-view'
const storeLocator = '/store-locator'

export const routeBack = (to, from) => {
  const fromWeeklyAdToWeeklyAd = to.path === weeklyAdPrintView && from.path === weeklyAdPrintView
  const fromStoreLocatorToStoreLocator = to.path === storeLocator && from.path === storeLocator
  return fromWeeklyAdToWeeklyAd || fromStoreLocatorToStoreLocator
}
