import PaymentMethodsApi from 'api/PaymentMethodsAPI'

const initialState = {
  showPayPal: false,
  currentPayPalSession: {},
  payPalToken: {},
  paymentTypesEnabled: true,
  payPalAuthorizedResponse: {},
  payPalDeviceData: '',
}
export default {
  namespaced: true,
  state: {
    ...initialState
  },
  getters: {
    getPayPalToken: state => state.payPalToken,
    getShowPayPal: (state, getters, rootState, rootGetters) => {
      const featurePaypal = rootGetters['SiteConfig/varByName']('feature_paypal')
      const hasActivePaymentMethod = rootGetters['UserProfile/userInfo']?.information?.hasActivePaymentMethod
      return featurePaypal && hasActivePaymentMethod
    },
    getPayPalAuthorizedResponse: state => state.payPalAuthorizedResponse,
    getPayPalDeviceData: state => state.payPalDeviceData,
  },
  mutations: {
    setShowPayPal(state, value) {
      state.showPayPal = value
    },
    setCurrentPayPalSession(state, value) {
      state.currentPayPalSession = value
    },
    setPayPalAuthorizedResponse(state, value) {
      state.payPalAuthorizedResponse = value
    },
    setPayPalToken(state, value) {
      state.payPalToken = value
    },
    setPaymentTypesEnabled(state, value) {
      state.paymentTypesEnabled = value
    },
    setPayPalDeviceData(state, value) {
      state.payPalDeviceData = value
    },
  },
  actions: {
    resetPayPalSession({ commit }) {
      commit('setCurrentPayPalSession', {})
    },
    resetpayPalToken({ commit }) {
      commit('setPayPalToken', {})
    },
    resetPayPalAuthorizedResponse({ commit }) {
      commit('setPayPalAuthorizedResponse', {})
    },
    async payPalPaymentSession({ commit, rootGetters }) {
      const userId = rootGetters['UserProfile/userId']
      const opco = rootGetters['SiteConfig/opco'].toLowerCase()
      const domainName = window.location.hostname
      const payload = {
        domainName,
        opco,
        walletType: 'PAYPAL'
      }
      const vueInstance = window.sharedVue.config.globalProperties
      vueInstance.$trackClientLog('pay_pal_validate_merchant_payload', payload)
      const payPalResponse = await PaymentMethodsApi.getPaymentSession(userId, payload)
      // Handle requests
      if (payPalResponse.status === 200 || payPalResponse.response?.status === 200) {
        commit('setCurrentPayPalSession', payPalResponse.data)
        vueInstance.$trackClientLog('pay_pal_validate_merchant_response', payPalResponse)
      }

      // Handle errors for payment session API

      if (payPalResponse.status >= 400 || payPalResponse.response?.status >= 400) {
        commit('Alert/setAlert', {
          type: 'error',
          header: 'Payment Session Error',
          body: 'Something went wrong. Please try again later.',
          primary: {
            text: 'Ok',
            callback: async () => {
              vueInstance.$store.commit('Alert/clear')
            }
          }
        }, { root: true })
        vueInstance.$trackClientLog('pay_pal_validate_merchant_response', payPalResponse.response)
        return payPalResponse.response
      }

      return payPalResponse
    },
    resetValues() {
      window.$store.dispatch('ApplePaymentStore/resetapplePayToken')
      window.$store.dispatch('ApplePaymentStore/resetApplePaySession')
      window.$store.dispatch('PayPalPaymentStore/resetpayPalToken')
      window.$store.dispatch('PayPalPaymentStore/resetPayPalAuthorizedResponse')
      window.$store.dispatch('PayPalPaymentStore/resetPayPalSession')
      window.$store.commit('CartPaymentInformation/setPayPalPerformCheckout', false)
    }
  }
}
