/*
* @param number - Number to trim decimals from
* @param digits - Number of decimal places to leave in the number
*/
export default function (number, digits) {
  if (Number.isNaN(number)) throw new Error(`${number} is not a number`)

  const multiplier = 10 ** digits
  const adjustedNum = number * multiplier
  const truncatedNum = Math[adjustedNum < 0 ? 'ceil' : 'floor'](adjustedNum)

  return truncatedNum / multiplier
}
