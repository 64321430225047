/* eslint-disable complexity */
/* eslint-disable max-params */
/* eslint-disable sonarjs/cognitive-complexity */
import ApiService from '@/api/'

const buildCouponRequestData = (cardNumber, version, params) => {
  const {
    selectedCategories, sortSelection, loadable, loaded, keywords,
    targeted, sourceSystems, start, size, circularId, includeExpired, id
  } = params
  const coupons = {
    query: {
      start: start || 0,
      size: size || 60,
      ...(id && { id }),
      ...(circularId && { circularId }),
      ...(keywords && { keywords }),
    },
    filter: {
      loadable,
      loaded,
      ...(includeExpired && { includeExpired }),
      ...(sourceSystems && { sourceSystems }),
      ...(targeted && { targeted }),
    },
    copientQuotientTargetingEnabled: true,
  }

  if (version === 'v7.0') {
    coupons.cardNumber = cardNumber
  }

  if (Array.isArray(selectedCategories)) {
    const activeSelectedCategories = selectedCategories.filter(category => category.active)
    const categoryArr = _.pluck(activeSelectedCategories, 'id')
    if (categoryArr.length) {
      coupons.filter.topCategoryTreeIds = categoryArr
    }
  }
  if (sortSelection && Object.hasOwn(sortSelection, 'name')) {
    const { name, order = 'asc' } = sortSelection
    coupons.sorts = [{ [name]: order }]
  }

  return coupons
}

export default {
  async getCoupons(userId, serviceLocationId, cardNumber, version, params = {}) {
    const couponVersion = version ?? 'v6.0'
    const coupons = buildCouponRequestData(cardNumber, couponVersion, params)
    /* eslint-disable max-len */
    const couponResponse = await ApiService.post(`/api/${couponVersion}/coupons/users/${userId}/prism/service-locations/${serviceLocationId}/coupons/search?fullDocument=true&unwrap=true`, coupons)
    if (couponResponse.status === 200) {
      return couponResponse
    }
    throw couponResponse
  },
  // eslint-disable-next-line max-params
  async getFullCouponDetails(userId, serviceLocationId, couponId, cardNumber, version) {
    const params = {
      query: {
        id: couponId
      },
      filter: {},
      copientQuotientTargetingEnabled: true,
    }
    let couponVersion = version
    if (couponVersion === undefined) {
      couponVersion = 'v6.0'
    }
    if (couponVersion === 'v7.0') {
      params.cardNumber = cardNumber
    }
    /* eslint-disable max-len */
    const couponSearchResponse = await ApiService.post(`/api/${couponVersion}/coupons/users/${userId}/prism/service-locations/${serviceLocationId}/coupons/search?fullDocument=true&unwrap=true`, params)
    if (couponSearchResponse.status === 200 && couponSearchResponse.data.coupons.length) {
      return couponSearchResponse
    }
    throw couponSearchResponse
  },
  // eslint-disable-next-line max-params
  async getMultipleCouponsById(userId, serviceLocationId, couponIds, cardNumber, version) {
    const params = {
      query: {
        size: couponIds.length,
        ids: couponIds
      },
      filter: {},
      copientQuotientTargetingEnabled: true,
    }
    let couponVersion = version
    if (couponVersion === undefined) {
      couponVersion = 'v6.0'
    }
    if (couponVersion === 'v7.0') {
      params.cardNumber = cardNumber
    }
    /* eslint-disable max-len */
    const couponsById = await ApiService.post(`/api/${couponVersion}/coupons/users/${userId}/prism/service-locations/${serviceLocationId}/coupons/search?fullDocument=true&unwrap=true`, params)
    if (couponsById.status === 200 && couponsById.data.coupons.length) {
      return couponsById
    }
    throw couponsById
  },
  async loadCoupon(userId, params = {}) {
    const { couponId } = params
    const couponResponse = await ApiService.post(`/api/v6.0/users/${userId}/coupons/clipped`, { couponId })
    if (couponResponse.data.response.result !== 'SUCCESS' || typeof couponResponse.data.response.result !== 'string') {
      throw couponResponse
    }
    return couponResponse
  },
  async optIn(cardNumber, opco, params = {}) {
    const couponResponse = await ApiService.post(`/apis/store-coupons/v1/${opco}/${cardNumber}/claim`, params)
    /* 502 indicates user already opted in or a mulesoft error, continue with handling logic */
    if (couponResponse.status === 200 || couponResponse?.message.indexOf('502') !== -1) {
      return couponResponse
    }
    throw couponResponse
  },
  async getAllClippedCoupons(userId, serviceLocationId, cardNumber, couponVersion) {
    const apiParams = {
      sortSelection: {
        order: 'desc',
        name: 'targeted'
      },
      loadable: true,
      loaded: true,
      sourceSystems: ['QUO', 'COP', 'INM'],
      size: 90
    }
    const couponResponse = await this.getCoupons(userId, serviceLocationId, cardNumber, couponVersion, apiParams)
    if (couponResponse.status === 200) {
      return couponResponse
    }
    throw couponResponse
  }
}
