import { BR_BRAND_FOLDERS } from 'utils/constants'
import ApiService from '@/api'

export default {
  namespaced: true,
  state: {
    routes: [],
  },
  mutations: {
    setRoutes(state, payload) {
      state.routes = payload
    }
  },
  actions: {
    async getNuxtAvailableRoutes({ commit }) {
      const opcoId = appConfig?.opcoId
      const brandFolder = BR_BRAND_FOLDERS[opcoId]
      const emptyResults = []
      if (!brandFolder) return emptyResults

      const brPagesContentType = 'Page'
      const brEndpoint = 'https://peapod.bloomreach.io'
      const brDocumentsDeliveryAPI = `${brEndpoint}/delivery/site/v2/documents`
      const brHostedPagesEndpoint = `
        ${brDocumentsDeliveryAPI}?contentType=${brPagesContentType}&folder=${brandFolder}&limit=100
      `
      try {
        const result = await ApiService.coreGet(brHostedPagesEndpoint)
        if (result.status !== 200) {
          return emptyResults
        }

        const nuxtHostedPath = []
        result?.data?.documents?.forEach((doc) => {
          const trimmedPath = doc?.path?.replace(`${brandFolder}/pages`, '')
          if (trimmedPath !== undefined && trimmedPath !== '/home' && trimmedPath !== '/') {
            nuxtHostedPath.push(trimmedPath)
          }
        })

        commit('setRoutes', nuxtHostedPath)
        return nuxtHostedPath
      } catch (e) {
        return emptyResults
      }
    }
  }
}
