<template>
  <div
    :id="id"
    ref="modal"
    class="modal_container"
    data-test="pdl-modal"
    :class="{
      'modal_no-margin' : hasNoMargin,
      'modal_container--transparent p-0' : isVideoModal || transparentModal
    }"
    role="dialog"
    aria-modal="true"
    aria-labelledby="modal-title"
    tabindex="-1"
  >
    <div
      class="modal_body"
      :class="{
        'overflow-hidden': !isVideoModal,
        'modal_body--transparent': isVideoModal || transparentModal
      }"
    >
      <div
        v-show="title || hasLeftHeader || hasRightHeader"
        :class="[
          'modal_header',
          { 'modal_header--adjustable-height': hasAdjustableHeightHeader }
        ]"
      >
        <div
          v-if="hasLeftHeader"
          class="modal_left-header"
        >
          <slot name="leftHeaderSection" />
        </div>
        <h2
          v-if="title"
          id="modal-title"
          data-test="modal-title"
          class="modal_title"
        >
          {{ title }}
        </h2>
        <slot
          v-if="hasRightHeader"
          name="rightHeaderSection"
        />
        <button
          v-if="hasCloseButton"
          id="close-button"
          ref="closeButton"
          class="modal_close"
          aria-label="close dialog"
          @keydown.tab.shift.prevent="focusOnLastElement"
          @click.stop="close"
        >
          <PdlIconBase
            icon-title="Close"
            container-class="modal_close-icon"
          >
            <PdlIconClose />
          </PdlIconBase>
        </button>
      </div>
      <section
        ref="modalContent"
        data-test="pdl-modal-content"
        class="modal_content"
        :class="[
          { 'modal_content--transparent' : isVideoModal || transparentModal },
          { 'modal_content--footer-padding' : hasFixedFooter },
          { 'modal_content--no-content-padding' : !hasContentPadding },
          { 'modal_content--scrollable': isScrollable && !isVideoModal },
          { 'modal_left-column': hasRightColumn },
          { 'modal_no-padding': hasNoPadding },
          { 'modal_no-side-padding': hasNoSidePadding },
          { 'modal_content--full-height': hasFullHeightContent },
          { 'overflow-y-auto': hasScrollContent},
          `${sectionClasses}`
        ]"
      >
        <slot />
      </section>
      <section
        v-if="hasRightColumn"
        class="modal_content modal_content--scrollable modal_right-column"
        :class="{'modal_right-column_border': hasBorder}"
      >
        <slot name="right" />
      </section>
      <div
        v-if="hasFixedFooter"
        class="modal_footer"
        :class="{ 'modal_footer--with-button': hasFooterButton }"
      >
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { PdlIconBase } from '@/shared/icons'
import PdlIconClose from 'components/icons/icon/PdlIconClose'

export default {
  components: {
    PdlIconBase,
    PdlIconClose
  },
  props: {
    id: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    isScrollable: {
      type: Boolean,
      default: true
    },
    isVideoModal: {
      type: Boolean,
      default: false
    },
    hasContentPadding: {
      type: Boolean,
      default: true
    },
    hasFixedFooter: {
      type: Boolean,
      default: false
    },
    hasFooterButton: {
      type: Boolean,
      default: false
    },
    hasLeftHeader: {
      type: Boolean,
      default: false
    },
    hasRightHeader: {
      type: Boolean,
      default: false
    },
    hasRightColumn: {
      type: Boolean,
      default: false
    },
    hasBorder: {
      type: Boolean,
      default: false
    },
    hasNoPadding: {
      type: Boolean,
      default: false
    },
    canCloseModal: {
      type: Boolean,
      default: true
    },
    hasNoSidePadding: {
      type: Boolean,
      default: false
    },
    hasNoMargin: {
      type: Boolean,
      default: false
    },
    hasCloseButton: {
      type: Boolean,
      default: true
    },
    hasFullHeightContent: {
      type: Boolean,
      default: false
    },
    hasScrollContent: {
      type: Boolean,
      default: false
    },
    triggerScroll: {
      type: Boolean,
      default: false
    },
    transparentModal: {
      type: Boolean,
      default: false
    },
    sectionClasses: {
      type: String,
      default: ''
    },
    hasAdjustableHeightHeader: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState({
      idToFocusOnOpen: state => state.Modals.idToFocusOnOpen,
      modals: state => state.Modals.activeComponent,
    }),
  },
  mounted() {
    if (this.canCloseModal) {
      window.addEventListener('keyup', this.handleKeyup)
      window.addEventListener('keydown', this.handleKeydown)
    }
    if (this.triggerScroll) {
      this.debounceScroll = _.debounce(this.scrollCatcher, 150)
      this.$refs.modalContent.addEventListener(
        'scroll',
        this.debounceScroll
      )
    }
    if (this.idToFocusOnOpen) {
      // Focus elements that do not have to wait for an endpoint response,
      // such as coupon tiles on weekly ad modals.
      const elementWithId = document.getElementById(this.idToFocusOnOpen)
      if (elementWithId) {
        elementWithId.focus()
      } else {
        this.$refs.modal.focus()
      }
    } else {
      this.$refs.modal.focus()
    }
  },
  methods: {
    closeInteraction() {
      this.close()
    },
    close(event) {
      if (window.sessionStorage.getItem('deeplinkTo') === 'PUP') {
        window.sessionStorage.removeItem('deeplinkTo')
      }
      if (this.canCloseModal) {
        window.removeEventListener('keyup', this.handleKeyup)
        window.removeEventListener('keydown', this.handleKeydown)
        this.$store.dispatch('Modals/closeModal')
        this.focusOnModalClose(event)
        this.$emit('close')
      } else {
        this.$emit('no-close')
      }
      if (this.triggerScroll) {
        this.debounceScroll = _.debounce(this.scrollCatcher, 150)
        this.$refs.modalContent.removeEventListener(
          'scroll',
          this.debounceScroll
        )
      }
    },
    focusOnModalClose(event) {
      const elToRemoveClassFrom = document.getElementsByClassName('focus-on-modal-close')[0]
      const modal = this.modals[0]
      const elemIdThatOpenedModal = modal?.elemIdThatOpenedModal
      let elementWithId = null
      // reopen account menu if modal closed with keyboard to return focus
      if (elemIdThatOpenedModal?.startsWith('nav-account-menu') && event?.detail === 0) {
        this.$store.dispatch('GlobalHeader/toggleAccountMenu')
      }

      this.$nextTick(() => {
        if (elemIdThatOpenedModal) {
          elementWithId = document.getElementById(elemIdThatOpenedModal)
          // handle situation where the tile rerenders and changes the end of the ID
          if (!elementWithId) {
            const truncatedId = elemIdThatOpenedModal.replace(/-\d+$/, '')
            elementWithId = document.querySelector(`[id^=${truncatedId}]`)
          }
        }
        const elToFocus = elementWithId || elToRemoveClassFrom

        if (elToFocus) {
          elToFocus.focus()
        }

        if (elToRemoveClassFrom) {
          elToRemoveClassFrom.classList.remove('focus-on-modal-close')
        }
      })
    },
    handleKeyup(event) {
      let isEscape = false
      if (event.key !== undefined) { // check for undefined since there is a key 0
        isEscape = event.key === 'Escape' || event.key === 'Esc'
      }

      if (isEscape) {
        this.close(event)
      }
    },
    handleKeydown(event) {
      this.$nextTick(() => {
        if (event.shiftKey && event.key === 'Tab' && document.activeElement.contains(this.$refs.modal)) {
          event.preventDefault()
          this.focusOnLastElement()
        }
      })
    },
    focusOnLastElement() {
      const backButton = document.getElementsByClassName('modal_back')[0]
      if (backButton) {
        backButton.focus()
      } else {
        const focusableElements = this.$refs.modal
          .querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])')
        if (focusableElements[focusableElements.length - 1]?.disabled) {
          if (focusableElements[focusableElements.length - 2]) {
            focusableElements[focusableElements.length - 2].focus()
          }
        } else if (focusableElements[focusableElements.length - 1]) {
          focusableElements[focusableElements.length - 1].focus()
        }
      }
    },
    scrollCatcher() {
      window.dispatchEvent(new CustomEvent('scroll'))
    }
  }
}
</script>
