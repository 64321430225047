export default function (currentAppVersion) {
  const store = window.sharedVue.config.globalProperties.$store
  let minAppVersion = store.getters['SiteConfig/varByName']('brand_mobile_version')
  // allow user through if any temporary mistakes happen on our end.
  if (typeof currentAppVersion === 'undefined' || currentAppVersion === null
  || typeof minAppVersion === 'undefined' || minAppVersion === null
  ) {
    return false
  }
  minAppVersion = minAppVersion.split('.').map(x => Number(x))
  currentAppVersion = currentAppVersion.split('.').map(x => Number(x))
  for (let i = 0; i < minAppVersion.length; i += 1) {
    if (currentAppVersion[i] > minAppVersion[i]) {
      return false
    }
    if (currentAppVersion[i] < minAppVersion[i]) {
      return true
    }
  }
  return false
}
