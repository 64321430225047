import instacartApi from 'api/instacartApi'
import { cancelSlot } from 'api/slotsApi'
import { INSTACART_ERROR_STRUCTURES, ADDRESS_ERROR_MESSAGE } from 'utils/constants'
import { trackInstacartError } from 'utils/tracking/trackInstacartError'
import generateRandomNumber from 'utils/generateRandomNumber'

/**
 * Store-specific constants
 */
const requiredParamsAddress = ['userId', 'opcoName', 'addressLine1', 'postalCode']
const ACTIONS = {
  UNKNOWN: 'UNKNOWN ACTION',
  CREATE_USER: 'createUser',
  VALIDATE_ADDRESS_AND_CREATE_USER: 'validateAddressAndCreateUser'
}

/**
 * Generate a random instance id (aka userId)
 *
 * @returns random instance id
 */
const generateRandomInstanceId = () => {
  const idComponents = []
  for (let i = 0; i < 5; i += 1) {
    idComponents.push(generateRandomNumber(10000).toString(16))
  }
  return idComponents.join('-')
}
const generateErrorLogString = (condesedError, matchFound = false) => {
  const { errorCode, errorMessage, errorMeta } = condesedError
  return [
    `match[${matchFound ? 'YES' : 'NO'}]`,
    `code[${errorCode}]`,
    `message[${errorMessage}]`,
    `meta[${errorMeta}]`
  ].join(' ')
}

const attemptErrorMatch = (condesedError) => {
  const errorMatch = INSTACART_ERROR_STRUCTURES.find((structure) => {
    const { message, meta } = structure
    return message === condesedError.errorMessage && meta === condesedError.errorMeta
  })

  if (!errorMatch) {
    return { match: false }
  }

  const { userMessage } = errorMatch
  return { match: true, userMessage }
}

/**
 * @function verifyPayloadFields
 *
 * Used by multiple actions to verify required parameters are present before
 * attempting to make the API calls. In the event that parameters are not
 * present then the specific error cause is stored in the error state of
 * this store.
 *
 * @param {object} payload - 4 string params relevant to user address validation
 * @param {string} action - denotes the exact name of action trying to call Instacart API
 * @param {[string]} requiredKeys - the names of properties expected within the payload
 */
const verifyPayloadFields = (payload, action = ACTIONS.UNKNOWN, requiredKeys = []) => {
  const storeName = 'InstacartOrders'
  if (typeof payload !== 'object') {
    throw new Error(`action ${storeName}/${action} missing required params object`)
  }

  if (!Array.isArray(requiredKeys)) {
    throw new Error(`action ${storeName}/${action} verifyPayloadFields() was not passed list of required keys`)
  }

  const keysProvided = Object.keys(payload)
  const missingParams = requiredKeys.filter(requiredKey => keysProvided.indexOf(requiredKey) < 0)

  if (missingParams.length) {
    throw new Error(`action ${storeName}/${action} missing param(s): ${missingParams.join(', ')}`)
  }
}

export const initialState = Object.freeze({
  validAddressErrorType: {
    invalidResponse: false,
    serverError: false
  },
  validAddressAndCreateUserErrorType: {
    invalidResponse: false,
    serverError: false
  },
  userCreated: false,
  isCreatingUser: false,
  validAddress: false,
  isValidatingAddress: false,
  validateAddressAndCreateUserFailureCount: 0,
  validateAddressAndCreateUserFailureMax: 2,
  deliveryAddress: {
    addressLine1: '',
    addressLine2: '',
    zip: ''
  },
  selectedSlot: {},
  preventSlotsReInit: false,
  addressErrorMessage: ADDRESS_ERROR_MESSAGE
})

export default {
  namespaced: true,
  state: { ...initialState },
  getters: {
    validateAddressAndCreateUserAttemptsExhausted: (state) => {
      const { validateAddressAndCreateUserFailureCount, validateAddressAndCreateUserFailureMax } = state
      return validateAddressAndCreateUserFailureCount >= validateAddressAndCreateUserFailureMax
    },
  },
  mutations: {
    setValidAddressErrorTypeInvalidResponse(state, value) {
      state.validAddressErrorType.invalidResponse = value
    },
    setValidAddressErrorTypeServerError(state, value) {
      state.validAddressErrorType.serverError = value
    },
    setValidAddressAndCreateUserErrorTypeInvlaidResponse(state, value) {
      state.validAddressAndCreateUserErrorType.invalidResponse = value
    },
    setValidAddressAndCreateUserErrorTypeServerError(state, value) {
      state.validAddressAndCreateUserErrorType.serverError = value
    },
    setUserCreated(state, value) {
      state.userCreated = value
    },
    setIsCreatingUser(state, value) {
      state.isCreatingUser = value
    },
    setAddressValid(state, value) {
      state.validAddress = value
    },
    setIsValidatingAddress(state, value) {
      state.isValidatingAddress = value
    },
    incrementValidateAddressAndCreateUserFailureCount(state) {
      state.validateAddressAndCreateUserFailureCount += 1
    },
    setDeliveryAddress(state, value) {
      state.deliveryAddress = value
    },
    resetDeliveryAddress(state) {
      state.deliveryAddress = {
        ...initialState.deliveryAddress
      }
    },
    setSelectedSlot(state, payload) {
      state.selectedSlot = payload
    },
    setPreventSlotsReInit(state, payload) {
      state.preventSlotsReInit = payload
    },
    setAddressErrorMessage(state, payload) {
      state.addressErrorMessage = payload
    }
  },
  actions: {
    async patchDeliveryAddress({ dispatch, state, rootGetters }) {
      const userStatus = rootGetters['LoginStatus/userStatus']
      const isLoggedIn = userStatus === 'R'
      if (isLoggedIn) {
        const deliveryAddress = rootGetters['UserProfile/deliveryAddress']
        if (deliveryAddress) {
          const { addressLine1, addressLine2 } = deliveryAddress
          // eslint-disable-next-line max-len
          const isProfileAddressDifferent = addressLine1 !== state?.deliveryAddress?.addressLine1 || addressLine2 !== state?.deliveryAddress?.addressLine2
          if (isProfileAddressDifferent) {
            const response = await dispatch('UserProfile/patchDeliveryAddress', state?.deliveryAddress, { root: true })
            if (response.status !== 200) {
              throw new Error('COULD_NOT_UPDATE_PROFILE_ADDRESS')
            }
          }
        }
      }
    },
    async cancelReservedSlot({ dispatch, rootGetters }) {
      const selectedSlot = rootGetters['Slots/selectedSlot']
      if (Object.keys(selectedSlot).length > 0) {
        const cancelParams = {
          userId: rootGetters['UserProfile/userId'],
          basketId: rootGetters['Cart/getBasketId']
        }
        await cancelSlot(cancelParams).execute()
        dispatch('Slots/clearSelectedSlot', null, { root: true })
        dispatch('ShoppingMode/clearCurrentSlot', null, { root: true })
      }
    },
    async receiveValidateAddressAndCreateUserSuccess({ commit, dispatch }, { addressLine1, addressLine2, postalCode }) {
      commit('setDeliveryAddress', {
        addressLine1,
        addressLine2,
        zip: postalCode
      })
      commit('setAddressValid', true)
      commit('setUserCreated', true)
      await dispatch('patchDeliveryAddress')
      commit('ServiceSelection/setCurrentView', 'T', { root: true })
      commit('Slots/setIntermediateServiceType', 'D', { root: true })
      commit('Modals/clearActiveModal', null, { root: true })
    },
    async interpretClientErrorResponse({ commit }, payload) {
      const { error, meta } = payload[0]

      const condesedError = {
        errorCode: error.code,
        errorMessage: error.message,
        errorMeta: meta.key
      }

      const messageMatch = attemptErrorMatch(condesedError)

      const errorString = generateErrorLogString(condesedError, messageMatch.match)

      trackInstacartError(errorString)

      commit('setAddressErrorMessage', messageMatch.userMessage)
    },
    async receiveValidateAddressAndCreateUserError({ dispatch, commit }, response = {}) {
      const statusCode = response?.data?.httpResponseCode
      if (statusCode === 400) {
        const errorMessages = response?.data?.errorMessages // < not guaranteed in response

        if (Array.isArray(errorMessages) && !!errorMessages.length) {
          dispatch('interpretClientErrorResponse', errorMessages)
        }

        commit('incrementValidateAddressAndCreateUserFailureCount')
        commit('setValidAddressAndCreateUserErrorTypeInvlaidResponse', true)
        commit('setValidAddressAndCreateUserErrorTypeServerError', false)
        await dispatch('cancelReservedSlot')
      } else {
        commit('setValidAddressAndCreateUserErrorTypeServerError', true)
        commit('setValidAddressAndCreateUserErrorTypeInvlaidResponse', false)
      }
      commit('resetDeliveryAddress')
      commit('setAddressValid', false)
      commit('setUserCreated', false)
    },
    async validateAddressAndCreateUser({ commit, dispatch, rootGetters }, payload) {
      try {
        const modifiedPayload = { ...payload }
        const { userId } = modifiedPayload
        if (!userId) modifiedPayload.userId = generateRandomInstanceId()
        verifyPayloadFields(modifiedPayload, ACTIONS.VALIDATE_ADDRESS_AND_CREATE_USER, requiredParamsAddress)

        const calledFromCheckout = payload?.checkout || false
        commit('setIsValidatingAddress', true)
        commit('setIsCreatingUser', true)
        commit('setValidAddressAndCreateUserErrorTypeInvlaidResponse', false)
        commit('setValidAddressAndCreateUserErrorTypeServerError', false)

        const addressAndUserResult = await instacartApi.validateAddressAndCreateUser(modifiedPayload)
        const responseStatus = addressAndUserResult.status
        if (responseStatus === 200) {
          if (!calledFromCheckout) {
            // this runs from simple address check, no need to call again from checkout
            await dispatch('receiveValidateAddressAndCreateUserSuccess', modifiedPayload)
          }
        } else {
          await dispatch('receiveValidateAddressAndCreateUserError', addressAndUserResult)
        }
      } catch (e) {
        if (e.message === 'COULD_NOT_UPDATE_PROFILE_ADDRESS') {
          const checkoutFlowType = rootGetters['SiteConfig/varByName']('config_checkout_flow')
          const nextRoute = checkoutFlowType === 'modal' ? '/modal/checkout' : '/order-summary'

          commit('Alert/setAlert', {
            type: 'error',
            icon: true,
            header: 'Address Update Failed',
            // eslint-disable-next-line max-len
            body: 'We could not update the address in your account. Please edit your delivery information and select time again.',
            primary: {
              text: 'Dismiss',
              color: 'fourth',
              callback: async () => {
                commit('Alert/clear', null, { root: true })
              }
            },
            secondary: {
              text: 'Edit Address',
              color: 'prime',
              callback: async () => {
                commit('Alert/clear', null, { root: true })
                commit('Modals/clearActiveModal', null, { root: true })
                window.sharedVue.config.globalProperties.$router.push(
                  {
                    path: '/account/user/delivery-address',
                    query: { nextRoute }
                  }
                )
              }
            }
          }, { root: true })
        }
      } finally {
        commit('setIsValidatingAddress', false)
        commit('setIsCreatingUser', false)
      }
    }
  }
}
