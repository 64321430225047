<template>
  <li
    :id="id"
    :ref="id"
    role="tab"
    :aria-selected="isActive"
    :aria-controls="panelId"
    :class="[
      'sidebar-header',
      { 'is-active': isActive && !isMobile},
    ]"
    tabindex="0"
    @click="selectTab"
    @keydown.enter="selectTab"
  >
    <p class="sidebar-header_title">
      {{ name }}
    </p>
    <PdlIconBase
      icon-title="Go to tab"
      :container-class="iconClass"
    >
      <PdlIconCarrotRight />
    </PdlIconBase>
  </li>
</template>
<script>
import {
  PdlIconBase,
} from '@/shared/icons'
import PdlIconCarrotRight from 'components/icons/icon/PdlIconCarrotRight'

export default {
  components: {
    PdlIconBase,
    PdlIconCarrotRight
  },
  props: {
    id: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      required: true
    },
    selected: {
      type: Boolean,
      default: false
    },
    isMobile: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      isActive: false
    }
  },
  computed: {
    panelId() {
      return `panel-${this.id}`
    },
    iconClass() {
      let iconClass = 'sidebar-header_arrow'
      if (this.isMobile) {
        iconClass += ' sidebar-header_arrow--mobile'
      } else if (this.isActive) {
        iconClass += ' is-active'
      }
      return iconClass
    }
  },
  watch: {
    isMobile(newVal) {
      if (newVal) {
        this.isActive = false
      }
    }
  },
  beforeMount() {
    this.isActive = this.selected
  },
  mounted() {
    this.$emit('tab-header-mounted', this)
  },
  methods: {
    selectTab() {
      if (this.$parent.selectTab) {
        this?.$parent?.selectTab(this)
      }
      this.$emit('select')
    }
  }
}
</script>
