import { GTM } from 'utils/constants'

export const trackBrowseAisle = (product) => {
  const payload = {
    categoryNumber: product.categoryTreeId,
    category: product?.categoryName,
    items: (product.productsId?.length > 0) ? product.productsId : undefined,
    majorCategory: product.majorCategory ? product.majorCategory : undefined
  }
  if (product.hierarchyLevel) {
    payload.hierarchyLevel = product.hierarchyLevel
  }
  if (product.navigationLocation) {
    payload.navigationLocation = product.navigationLocation
  }
  if (product.secondaryCategory) {
    payload.secondaryCategory = product.secondaryCategory
  }
  window.sharedVue.config.globalProperties.$trackGtmEvent(GTM.browseAisles, payload)
}
