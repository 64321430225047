import { mapSpyglassData } from 'utils/services/spyglass-content'
import {
  mapAccountMenuItems
} from 'store/modules/helpers/AccountNavHelpers'

export default {
  namespaced: true,
  state: {
    isMobileMainMenuOpen: false,
    isMobileBrowseAislesMenuOpen: false,
    isMobileMyShopMenuOpen: false,
    isBrowseAislesMenuOpen: false,
    isMyShopMenuOpen: false,
    isAccountMenuOpen: false,
    isSubNavMenuOpen: false,
    isCategoryMenuOpen: true,
    isSearchInputActive: false,
    hasMaskOverlay: false,
    maskOverlayHeader: false,
    selectedSearchValue: '',
    navigationInitiated: false,
    mainNavItems: {
      links: [],
      spyGlassData: {}
    },
    subNavItems: {},
    subNavRedesignItems: {},
    userMenuItems: {},
    guestMenuItems: {},
    myShopMenuItems: {},
    browseAislePromoItem: null,
    shopPromoItem: null,
    isInitialized: false,
    marketplaceSellerName: ''
  },
  getters: {
    isSearchInputActive: state => state.isSearchInputActive,
    isMobileMainMenuOpen: state => state.isMobileMainMenuOpen,
    isMobileBrowseAislesMenuOpen: state => state.isMobileBrowseAislesMenuOpen,
    isMobileMyShopMenuOpen: state => state.isMobileMyShopMenuOpen,
    isBrowseAislesMenuOpen: state => state.isBrowseAislesMenuOpen,
    isMyShopMenuOpen: state => state.isMyShopMenuOpen,
    isAccountMenuOpen: state => state.isAccountMenuOpen,
    isSubNavMenuOpen: state => state.isSubNavMenuOpen,
    isCategoryMenuOpen: state => state.isCategoryMenuOpen,
    hasMaskOverlay: state => state.hasMaskOverlay,
    hasMaskOverlayHeader: state => state.maskOverlayHeader,
    getSelectedSearchValue: state => state.selectedSearchValue,
    navigationInitiated: state => state.navigationInitiated,
    mainNavItems: state => state.mainNavItems,
    subNavItems: state => state.subNavItems,
    subNavRedesignItems: state => state.subNavRedesignItems,
    userMenuItems: state => state.userMenuItems,
    guestMenuItems: state => state.guestMenuItems,
    // eslint-disable-next-line max-params
    modifiedGuestMenuItems: (state, getters, rootState, rootGetters) => {
      const userStatus = rootGetters['LoginStatus/userStatus']
      const isLoggedIn = rootGetters['LoginStatus/isLoggedIn']
      const isLoyaltyRegistered = rootGetters['LoyaltyAccount/isLoyaltyRegistered']
      const { spyGlassData = {}, links = [] } = state.guestMenuItems
      const alteredMenuLinks = links.map((item) => {
        const label = item.label === 'Create Account' || item.label === 'Create an Account'
        if (label && userStatus === 'R' && !isLoyaltyRegistered) {
          return {
            ...item,
            label: 'Finish Profile'
          }
        }
        if (label && userStatus !== 'R' && isLoggedIn && !isLoyaltyRegistered) {
          return {
            ...item,
            label: 'Finish Account Setup'
          }
        }
        return item
      })
      return {
        links: alteredMenuLinks,
        spyGlassData
      }
    },
    myShopMenuItems: state => state.myShopMenuItems,
    browseAislePromoItem: state => state.browseAislePromoItem,
    shopPromoItem: state => state.shopPromoItem,
    marketplaceSellerName: state => state.marketplaceSellerName,
    accountMenuItems: (state, getters, rootState, rootGetters) => {
      const { userMenuItems, guestMenuItems } = state
      const isCheckoutRegistered = rootGetters['LoginStatus/userStatus'] === 'R'
      const isLoggedIn = rootGetters['LoginStatus/isLoggedIn']
      const isLoyaltyRegistered = rootGetters['LoyaltyAccount/isLoyaltyRegistered']
      const includeExtraLinks = rootGetters['SiteConfig/varByName']('feature_new_navigation_design')

      return mapAccountMenuItems({
        isCheckoutRegistered,
        isLoyaltyRegistered,
        isLoggedIn,
        userMenuData: userMenuItems,
        guestMenuData: guestMenuItems
      }, includeExtraLinks)
    }
  },
  mutations: {
    setSearchInputState(state, value) {
      state.isSearchInputActive = value
    },
    toggleBrowseAislesMenu(state) {
      state.isBrowseAislesMenuOpen = !state.isBrowseAislesMenuOpen
    },
    setCloseBrowseAislesMenu(state, value) {
      state.isBrowseAislesMenuOpen = value
    },
    toggleMyShopMenu(state) {
      state.isMyShopMenuOpen = !state.isMyShopMenuOpen
    },
    setCloseMyShopMenu(state, value) {
      state.isMyShopMenuOpen = value
    },
    toggleAccountMenu(state) {
      state.isAccountMenuOpen = !state.isAccountMenuOpen
    },
    setCloseAccountMenu(state, value) {
      state.isAccountMenuOpen = value
    },
    toggleSubNavMenu(state) {
      state.isSubNavMenuOpen = !state.isSubNavMenuOpen
    },
    setCloseSubNavMenu(state, value) {
      state.isSubNavMenuOpen = value
    },
    toggleCategoryMenu(state) {
      state.isCategoryMenuOpen = !state.isCategoryMenuOpen
    },
    setCloseCategoryMenu(state) {
      state.isCategoryMenuOpen = false
    },
    setOpenCategoryMenu(state) {
      state.isCategoryMenuOpen = true
    },
    setOpenMobileMainMenu(state, value) {
      state.isMobileMainMenuOpen = value
    },
    setCloseMobileMainMenu(state, value) {
      state.isMobileMainMenuOpen = value
    },
    setOpenMobileBrowseAislesMenu(state, value) {
      state.isMobileBrowseAislesMenuOpen = value
    },
    setCloseMobileBrowseAislesMenu(state, value) {
      state.isMobileBrowseAislesMenuOpen = value
    },
    setOpenMobileMyShopMenu(state, value) {
      state.isMobileMyShopMenuOpen = value
    },
    setCloseMobileMyShopMenu(state, value) {
      state.isMobileMyShopMenuOpen = value
    },
    toggleMaskOverlay(state, value) {
      state.hasMaskOverlay = !state.hasMaskOverlay
      state.maskOverlayHeader = value
    },
    setMaskOverlay(state, shouldShowMask) {
      state.hasMaskOverlay = shouldShowMask
    },
    setOverlayHeader(state, value) {
      state.maskOverlayHeader = value
    },
    removeMaskOverlay(state) {
      state.hasMaskOverlay = false
    },
    addMaskOverlay(state) {
      state.hasMaskOverlay = true
    },
    setSearchSelectedValue(state, value) {
      state.selectedSearchValue = value
    },
    setNavigationInitiated(state, value) {
      state.navigationInitiated = value
    },
    setMainNavItems(state, value) {
      state.mainNavItems = value
    },
    setSubNavItems(state, value) {
      state.subNavItems = value
    },
    setSubNavRedesignItems(state, value) {
      state.subNavRedesignItems = value
    },
    setUserMenuItems(state, value) {
      state.userMenuItems = value
    },
    setGuestMenuItems(state, value) {
      state.guestMenuItems = value
    },
    setMyShopMenuItems(state, value) {
      state.myShopMenuItems = value
    },
    setBrowseAislePromoItem(state, value) {
      state.browseAislePromoItem = value
    },
    setShopPromoItem(state, value) {
      state.shopPromoItem = value
    },
    setInitialized(state) {
      state.isInitialized = true
    },
    setMarketplaceSellerName(state, payload) {
      state.marketplaceSellerName = payload
    }
  },
  actions: {
    init({ commit }) {
      commit('setInitialized')
    },
    setSearchInputState({ commit }, payload) {
      commit('setSearchInputState', payload)
    },
    setCloseSearchInput({ commit }) {
      commit('setSearchInputState', false)
      const shouldCoverHeader = false
      commit('toggleMaskOverlay', !shouldCoverHeader)
    },
    toggleBrowseAislesMenu({ commit }) {
      commit('toggleBrowseAislesMenu')
      const shouldCoverHeader = false
      commit('toggleMaskOverlay', !shouldCoverHeader)
    },
    setCloseBrowseAislesMenu({ commit }) {
      commit('setCloseBrowseAislesMenu', false)
      const shouldCoverHeader = false
      commit('toggleMaskOverlay', !shouldCoverHeader)
    },
    toggleMyShopMenu({ commit }) {
      commit('toggleMyShopMenu')
      const shouldCoverHeader = false
      commit('toggleMaskOverlay', !shouldCoverHeader)
    },
    setCloseMyShopMenu({ commit }) {
      commit('setCloseMyShopMenu', false)
      const shouldCoverHeader = false
      commit('toggleMaskOverlay', !shouldCoverHeader)
    },
    toggleAccountMenu({ commit }) {
      commit('toggleAccountMenu')
      const shouldCoverHeader = false
      commit('toggleMaskOverlay', !shouldCoverHeader)
    },
    setCloseAccountMenu({ commit }) {
      commit('setCloseAccountMenu', false)
      const shouldCoverHeader = false
      commit('toggleMaskOverlay', !shouldCoverHeader)
    },
    setOpenMobileMainMenu({ commit }) {
      commit('setOpenMobileMainMenu', true)
      const shouldCoverHeader = false
      commit('toggleMaskOverlay', shouldCoverHeader)
    },
    setCloseSubNavMenu({ commit }) {
      commit('setCloseSubNavMenu', false)
    },
    setCloseCategoryNavMenu({ commit }) {
      commit('setCloseCategoryNavMenu', false)
    },
    setCloseMobileMainMenu({ commit }) {
      commit('setCloseMobileMainMenu', false)
      const shouldCoverHeader = false
      commit('toggleMaskOverlay', shouldCoverHeader)
    },
    setOpenMobileBrowseAislesMenu({ commit }) {
      commit('setOpenMobileBrowseAislesMenu', true)
      const shouldCoverHeader = false
      commit('toggleMaskOverlay', shouldCoverHeader)
    },
    setCloseMobileBrowseAislesMenu({ commit }) {
      commit('setCloseMobileBrowseAislesMenu', false)
      commit('removeMaskOverlay')
    },
    setOpenMobileMyShopMenu({ commit }) {
      commit('setOpenMobileMyShopMenu', true)
      const shouldCoverHeader = false
      commit('toggleMaskOverlay', shouldCoverHeader)
    },
    setCloseMobileMyShopMenu({ commit }) {
      commit('setCloseMobileMyShopMenu', false)
      commit('removeMaskOverlay')
    },
    toggleSearchMaskOverlay({ commit }, payload) {
      commit('toggleMaskOverlay', payload)
    },
    removeMaskOverlay({ commit }) {
      commit('removeMaskOverlay')
    },
    addMaskOverlay({ commit }) {
      commit('addMaskOverlay')
    },
    setSearchSelectedValue({ commit }, payload) {
      commit('setSearchSelectedValue', payload)
    },
    setMask({ commit }, { maskOverlay, maskOverlayHeader }) {
      commit('setMaskOverlay', maskOverlay)
      commit('setOverlayHeader', maskOverlayHeader)
    },
    async retrieveNavigationItems({ commit, dispatch }) {
      const navItems = {
        mainNavItems: null,
        subNavItems: null,
        subNavRedesignItems: null,
        userMenuItems: {},
        guestMenuItems: null,
        myShopMenuItems: null
      }

      const promoItems = {
        shopPromoItem: {},
        browseAislePromoItem: {}
      }

      function setUpSpyglassContent(content) {
        if (!content[0]) {
          return {}
        }
        const dataLayer = content[0].map
        return {
          parent: mapSpyglassData(dataLayer),
          children: []
        }
      }

      function setNavItems(key, data) {
        const items = []

        data.forEach((contentGroup) => {
          contentGroup.map.scheduledContentData.map.parts.myArrayList.forEach((item) => {
            items.push({
              url: item.map['Button Link'],
              linkType: item.map['Button Link Type'],
              label: item.map['Headline Copy'],
              svgId: item.map['SVG Id'] || '',
              sublinks: item.map['Include sublinks'],
              sublinkDetail: [
                {
                  url: item.map['Sublink 1 - Button Link'],
                  label: item.map['Sublink 1 - Headline']
                },
                {
                  url: item.map['Sublink 2 - Button Link'],
                  label: item.map['Sublink 2 - Headline']
                }
              ]
            })
          })
        })

        navItems[key] = {
          spyGlassData: setUpSpyglassContent(data),
          links: items
        }
        commit('setMainNavItems', navItems.mainNavItems)
        commit('setSubNavItems', navItems.subNavItems)
        commit('setSubNavRedesignItems', navItems.subNavRedesignItems)
        commit('setUserMenuItems', navItems.userMenuItems)
        commit('setGuestMenuItems', navItems.guestMenuItems)
        commit('setMyShopMenuItems', navItems.myShopMenuItems)
      }

      function setPromoItems(key, data) {
        if (data?.length && data[0].map?.scheduledContentData) {
          promoItems[key] = {
            content: data[0].map.scheduledContentData.map,
            scheduledContentData: data[0].map,
            hasPromo: true
          }
        } else {
          promoItems[key] = {
            content: {},
            scheduledContentData: {},
            hasPromo: false
          }
        }
        commit('setBrowseAislePromoItem', promoItems.browseAislePromoItem)
        commit('setShopPromoItem', promoItems.shopPromoItem)
      }

      function processContent(content) {
        content.forEach((contentRegion) => {
          switch (contentRegion.region) {
            case 'header_mainnav':
              setNavItems('mainNavItems', contentRegion.contents)
              break
            case 'header_subnav_redesign':
              setNavItems('subNavRedesignItems', [contentRegion.contents[0]])
              break
            case 'header_subnav':
              setNavItems('subNavItems', contentRegion.contents)
              break
            case 'header_usernav':
              setNavItems('userMenuItems', contentRegion.contents)
              break
            case 'header_guestnav':
              setNavItems('guestMenuItems', contentRegion.contents)
              break
            case 'header_shopnav':
              setNavItems('myShopMenuItems', contentRegion.contents)
              break
            case 'header_shopnav_promo':
              setPromoItems('shopPromoItem', contentRegion.contents)
              break
            default:
              break
          }
        })
        commit('setNavigationInitiated', true)
      }

      try {
        const cmsData = await dispatch('ScheduledContent/queryRegion', {
          region: {
            header_mainnav: {},
            header_subnav_redesign: {},
            header_subnav: {},
            header_usernav: {},
            header_guestnav: {},
            header_shopnav: {},
            header_shopnav_promo: {
              limit: 1
            },
          }
        },
        { root: true })
        if (typeof cmsData?.response?.regionContents === 'object') {
          processContent(cmsData.response.regionContents)
        } else {
          throw new TypeError('GLOBAL_HEADER_CONTENT_UNAVAILABLE')
        }
      } catch {
        console.error('GLOBAL_HEADER_CONTENT_FAILED')
      }
    }
  }
}
