import SuperUserApi from 'api/SuperUserApi'
import { ORDER_MODES } from 'components/superuser/constants'

export const superUserInit = async () => {
  const { featureApiSessionOrder } = window.appConfig.featureFlags
  const { userId } = window.appConfig.user
  const orderMode = await SuperUserApi.getOrderMode(userId, featureApiSessionOrder)
  const suUserState = {
    enabled: true,
    hasToken: SuperUserApi.tokenExists(),
    redeliveryMode: orderMode === ORDER_MODES.REDELIVERY,
    statusVisible: true
  }
  const { $store } = window.sharedVue.config.globalProperties
  $store.commit('SuperUser/setIsRedeliveryMode', suUserState.redeliveryMode)
  $store.commit('SuperUser/setIsStatusVisible', suUserState.statusVisible)
  $store.dispatch('SuperUser/init', suUserState)
}

export const superUserInitSessionOrders = async () => {
  const { $store } = window.sharedVue.config.globalProperties
  const { featureApiSessionOrder } = window.appConfig.featureFlags
  const { userId } = window.appConfig.user

  if (featureApiSessionOrder) {
    await $store.dispatch('UserProfile/initQueryUserProfile')
    await $store.dispatch('Order/initOrderStatus', { canRefresh: true })
    await $store.dispatch('getPrimaryBasket')

    const basketIdResponse = await SuperUserApi.getCurrentBasketId(userId)
    const { basketId } = basketIdResponse?.data || {}
    if (basketIdResponse.status === 200 && basketId) {
      $store.commit('Cart/setBasketId', basketId)
    }
  }
}
