import formatCurrency from 'utils/filters/formatCurrency'
import ebtFeatureEnabled from 'utils/services/ebt/ebtFeatureEnabled'
import { trackBeginCheckout } from 'utils/tracking/checkout/trackBeginCheckout'
import { trackCheckoutStep } from 'utils/tracking/checkout/trackCheckoutStep'

export const CheckoutModalErrorTypes = {
  NO_STORE: 'No store available.',
  NO_TIME_SLOT: 'No time slot chosen.',
  UNDER_MINIMUM: 'The order is under the minimum.',
  NEEDS_REGISTRATION: 'This action requires the user to be registered.',
  NEEDS_AUTHORIZATION: 'This action requires the user to be authorized.',
  NEEDS_DELIVERY_ADDRESS: 'This action requires the user to have a delivery address'
}

export const CheckoutModalStatus = {
  CHECKOUT_OPEN: 'CHECKOUT_OPEN',
  REDIRECTED: 'REDIRECTED'
}

const noTimeSlot = ({ $store }) => {
  if (!$store) return

  $store.commit('Alert/setAlert', {
    icon: 'icon_error-circle_alt',
    header: 'No slot time selected.',
    body: 'You do not have a time slot selected. Please select a time slot.',
    processAsHTML: true,
    type: 'warning',
    primary: {
      text: 'Continue',
      callback() {
        // Need to trigger specific controller function to refresh the slot selection
        // modal correctly to show the slot not selected anymore.
        $store.commit('Alert/clear')
        $store.commit('ServiceSelection/setCurrentView', 'T')
        $store.commit('Modals/setActiveModal', {
          fileName: 'PdlServiceSelectionModal'
        })
      }
    }
  })
}

const checkForSubmittedOrder = async ({ $store }) => {
  const orderStatus = $store.getters['Order/orderStatus']
  if (orderStatus?.currentOrder?.status === 'SUBMITTED') {
    await $store.commit('Cart/setCartIsSubmittedOrder', true)
  }
}

const checkForTimeslot = ({ $store }, reject) => {
  const ebtAmountRefunded = $store.state.EbtPayment.amountRefunded
  const slotNeeded = !ebtAmountRefunded
  // Slot not needed for ebt refund flow or for marketplace-only baskets
  if ($store.getters['Slots/hasReservedSlot'] === false && slotNeeded) {
    noTimeSlot({ $store })
    trackBeginCheckout('no order method selected')
    throw reject(new Error(CheckoutModalErrorTypes.NO_TIME_SLOT))
  }
}

const checkUserSignIn = ({ $store, $router }, reject, nextRoute) => {
  const userStatus = $store.getters['LoginStatus/userStatus']
  const userIsNotRegistered = userStatus !== 'R'

  if (userIsNotRegistered) {
    if ($store.getters['LoginStatus/isLoggedIn']) {
      $router.push({ path: '/registration', query: { nextRoute, finishMode: true } })
      trackBeginCheckout('need sign in')
      throw reject(new Error(CheckoutModalErrorTypes.NEEDS_REGISTRATION))
    }

    $store.commit('Modals/setActiveModal', {
      fileName: 'PdlLoginFlow',
      props: {
        registrationRoute: nextRoute,
        registrationFinishMode: true
      }
    })
    trackBeginCheckout('need sign in')
    throw reject(new Error(CheckoutModalErrorTypes.NEEDS_AUTHORIZATION))
  }
}

const checkHasOrderMinimum = ({ $store }, reject) => {
  const hasMetOrderMinimum = $store.getters['Order/hasMetOrderMinimum']
  const orderMinimum = $store.getters['Order/orderMinimum']
  const hasEBT = $store.getters['UserProfile/hasEBT']
  const ebtActive = hasEBT && ebtFeatureEnabled()
  const { enabled, redeliveryMode } = $store.state.SuperUser
  const isRedeliveryMode = !!(enabled && redeliveryMode)

  // MOV does not apply for accounts with EBT on file
  if (!hasMetOrderMinimum && !ebtActive && !isRedeliveryMode) {
    $store.commit('Alert/setAlert', {
      icon: 'icon_error-circle_alt',
      header: 'Order Under Minimum',
      body: `Your order total does not meet the ${formatCurrency(orderMinimum)} minimum order total.`,
      type: 'warning'
    })
    trackBeginCheckout('order minimum not met')
    throw reject(new Error(CheckoutModalErrorTypes.UNDER_MINIMUM))
  }
}

const checkDeliveryAddress = ({ $store, $router }, reject, nextRoute) => {
  const isDelivery = $store.getters['UserProfile/serviceType'] === 'D'
  const lacksDeliveryAddress = !$store.getters['UserProfile/deliveryAddress']?.addressLine1

  // user has no delivery address on file
  if (isDelivery && lacksDeliveryAddress) {
    trackBeginCheckout('need sign in')
    $store.commit('Alert/setAlert', {
      icon: 'icon_error-triangle_alt',
      type: 'error',
      header: 'Add Delivery Address',
      body: 'Enter your delivery address in order to checkout.',
      primary: {
        text: 'Okay',
        callback: () => {
          $store.commit('Alert/clear')
          $store.commit('Modals/clear')
          $router.push(
            {
              path: '/account/user/delivery-address',
              query: { nextRoute }
            }
          )
        }
      },
    })
    throw reject(new Error(CheckoutModalErrorTypes.NEEDS_AUTHORIZATION))
  }
}

export default () => new Promise((resolve, reject) => {
  (async () => {
    try {
      // Reject immediately and explicitly rather than allowing later accidental rejections.
      const $store = window?.sharedVue?.config?.globalProperties?.$store
      const $router = window?.sharedVue?.config?.globalProperties?.$router
      if (!$store) return reject(new Error(CheckoutModalErrorTypes.NO_STORE))

      trackCheckoutStep(2)

      checkForTimeslot({ $store }, reject)

      await $store.dispatch('Order/initOrderStatus', { canRefresh: true })

      const checkoutFlowType = $store.getters['SiteConfig/varByName']('config_checkout_flow')
      const nextRoute = checkoutFlowType === 'modal' ? '/modal/checkout' : '/order-summary'
      const { showDidYouForgetModal } = $store.state.Optimizely

      await checkForSubmittedOrder({ $store })

      checkUserSignIn({ $store, $router }, reject, nextRoute)

      checkDeliveryAddress({ $store, $router }, reject, nextRoute)

      checkHasOrderMinimum({ $store }, reject)

      // User does not have a card number.
      if (!$store.getters['UserProfile/retailerCardNumber']) {
        $router.push({ path: '/registration', query: { nextRoute, finishMode: true } })
        return resolve({ status: CheckoutModalStatus.REDIRECTED })
      }

      if (showDidYouForgetModal) {
        $store.commit('Modals/setActiveModal', {
          fileName: 'PdlDidYouForgetModal'
        })
        return resolve({ status: CheckoutModalStatus.REDIRECTED })
      }

      if (checkoutFlowType === 'modal') {
        $store.commit('Modals/setActiveModal', { fileName: 'PdlCheckoutModal' })
      } else {
        $store.commit('Modals/clearActiveModal')
        $store.commit('CartPaymentInformation/setCanViewOrderSummaryPage', true)
        $router.push('/order-summary')
      }
      trackBeginCheckout('Success')
      return resolve({ status: CheckoutModalStatus.CHECKOUT_OPEN })
    } catch (err) {
      return err
    }
  })()
})
