import ApiService from '@/api/'

export default {
  get(payload) {
    const endpoint = `/api/v6.0/user/${payload.userId}/order/${payload.basketId}/checkout`
    return ApiService.get(endpoint, payload.params)
  },
  put({
    userId, basketId, queryString, params
  }) {
    let endpoint = `/api/v6.0/user/${userId}/order/${basketId}/checkout`
    if (queryString) {
      endpoint += `?${queryString}`
    }
    return ApiService.put(endpoint, params)
  },
}
