import ApiService from '@/api/'
import { USER_PREFERENCE_KEYS } from 'utils/constants'

const { DIGITAL_RECEIPTS } = USER_PREFERENCE_KEYS

export default {
  getCommunications(userId) {
    return ApiService.get(`/api/v3.0/user/${userId}/alerts`)
  },
  getCommunicationsV2(userId) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          if (typeof userId !== 'number') {
            reject(new Error('@param: "userId" must be a number'))
          }

          const route = `/api/v4.0/profile/user/${userId}/alerts`

          const result = await ApiService.get(route)

          if (!result.status || result.status !== 200) {
            reject(new Error(`empty or unexpected status: ${result.status} received from: ${route}`))
          }

          if (!result.data) {
            reject(new Error(`no data returned from: ${route}`))
          }

          resolve(result.data)
        } catch (e) {
          reject(e)
        }
      })()
    })
  },
  updateCommunicationsV2(userId, payload) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          if (typeof userId !== 'number') {
            reject(new Error('@param: "userId" must be a number'))
          }

          const routeV2 = `/api/v4.0/profile/user/${userId}/alerts`

          const result = await ApiService.put(routeV2, payload)

          if (!result.status || result.status !== 200) {
            reject(new Error(`empty or unexpected status: ${result.status} received from: ${routeV2}`))
          }

          if (!result.data) {
            reject(new Error(`no data returned from: ${routeV2}`))
          }

          resolve(result.data)
        } catch (e) {
          reject(e)
        }
      })()
    })
  },
  updateCommunications(payload, userId) {
    return ApiService.put(`/api/v3.0/user/${userId}/alerts`, payload)
  },
  getDigitalReceipts(userId) {
    return ApiService.get(`/api/v3.0/user/${userId}/preferences/${DIGITAL_RECEIPTS}`)
  },
  updateDigitalReceipts(userId, value) {
    return ApiService.post(`/api/v3.0/user/${userId}/preferences/${DIGITAL_RECEIPTS}/${value}`)
  }
}
