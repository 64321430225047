const utils = {
  forEach: (obj, fun) => {
    Object.keys(obj).forEach((key) => {
      fun(obj[key], key)
    })
  },
  encode: val => encodeURIComponent(val)
    .replace(/%3A/gi, ':')
    .replace(/%24/g, '$')
    .replace(/%2C/gi, ',')
    .replace(/%20/g, '+')
}

/**
 * Build a URL with encoded brackets. Copy of Axios buildURL, but without ignoring brackets
 * https://github.com/axios/axios/issues/3316
 *
 * @param {object} [params] The params to be appended
 * @returns {string} The formatted params string
 */
module.exports = (params) => {
  const parts = []

  utils.forEach(params, (val, key) => {
    if (val === null || typeof val === 'undefined') {
      return
    }

    if (Array.isArray(val)) {
      key += '[]'
    } else {
      val = [val]
    }

    utils.forEach(val, (v) => {
      if (_.isDate(v)) {
        v = v.toISOString()
      } else if (_.isObject(v)) {
        v = JSON.stringify(v)
      }
      parts.push(`${utils.encode(key)}=${utils.encode(v)}`)
    })
  })

  return parts.join('&')
}
