<template>
  <div
    class="radio"
    :class="{ 'radio--menu': fullWidth }"
  >
    <input
      :id="id"
      :name="name"
      :checked="isChecked"
      :disabled="disabled"
      :value="modelValue"
      :aria-label="name"
      class="radio_input element-invisible"
      type="radio"
      @change.stop="emitChange"
    >
    <label
      :for="id"
      :class="[
        'radio_label ',
        {
          'radio_label--dropdown': fullWidth,
          'radio_label--remove-width': removeLabelWidth,
          'min-w-0': noMinWidth,
          'items-start': alignItemsStart
        },
      ]"
    >
      <div
        :class="[
          'radio_circle',
          {'mr-[0.5rem]': (marginRight && !isPastPurchasesPage)},
          {'mr-[0.8rem]': (marginRight && isPastPurchasesPage)},
          {'mt-[0.3rem]': marginTop},
        ]"
      >
        <div class="radio_dot" />
      </div>
    <span>{{ label }}<slot /></span>
    </label>
  </div>
</template>
<script>
export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      required: true
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    isChecked: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: [String, Number, Boolean, Array, Object],
      required: true
    },
    removeLabelWidth: {
      type: Boolean,
      default: false
    },
    noMinWidth: {
      type: Boolean,
      default: false
    },
    marginRight: {
      type: Boolean,
      default: false
    },
    marginTop: {
      type: Boolean,
      default: false
    },
    alignItemsStart: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    id() {
      return `${this.name}-${this.modelValue.toString().replace(/ /g, '-')}-radio`
    },
    isPastPurchasesPage() {
      return this.$route.name.includes('past-purchases')
    }
  },
  emits: ['update:modelValue'],
  methods: {
    emitChange() {
      this.$emit('update:modelValue', this.modelValue)
    }
  }
}
</script>
