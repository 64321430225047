/**
 * This is a simple observer based implementation to allow the dependents
 * to conform to a refresh interface so that when an observer adds itself it will add itself with a method of refresh
 * that will return a promise that this service can observe when notify is invoked.
 */
function SessionCacheObserverService() {
  let observers = []
  this.getObservers = () => [...observers]

  this.notify = () => {
    return Promise.all(
      observers.map((observer) => {
        // Don't fail everybody just because somebody didn't have a refresh function.
        if (Object.hasOwn(observer, 'refresh')) {
          return observer.refresh.apply(observer)
        }
        return Promise.resolve()
      })
    )
  }

  this.addDependent = (dependent) => {
    observers.push(dependent)
  }

  this.removeDependent = (dependent) => {
    observers = observers.filter(observer => observer !== dependent)
  }
}

const sessionCacheObserverService = new SessionCacheObserverService()
export { sessionCacheObserverService }
