/**
 * when user is shopping a locker pickup we need to manually edit the
 * slot end-time (back end will not supply adjusted timeslot for MVP).
 * The slot times are provided by server so we rely on string manipulation
 * of the timestamp rather than using a date library and risking issues
 * with timezones etc.
 */
export default function (timestamp) {
  try {
    const timestampComponents = timestamp.split('T')
    const timeOfDay = timestampComponents[1]
    const timeInt = parseInt(timeOfDay.replace(':', ''), 10)
    // 55 corresponds to 15 min + the 40 to start hour at 60
    let editedTimeString = (timeInt - 55).toString()
    if (editedTimeString.length < 4) {
      editedTimeString = `0${editedTimeString}`
    }

    const adjustedTimeofDay = [editedTimeString.slice(0, 2), editedTimeString.slice(2, 4)].join(':')
    return `${timestampComponents[0]}T${adjustedTimeofDay}`
  } catch (e) {
    return timestamp
  }
}
