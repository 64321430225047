<template>
  <img
    :src="srcImage"
    :alt="imgDescription"
    @error="imageLoadError"
    @load="onLoad"
  >
</template>
<script>
import { DEFAULT_IMG } from 'utils/constants'

export default {
  props: {
    imgSrc: {
      type: String,
      default: '',
    },
    imgDescription: {
      type: String,
      default: '',
    },
    showDefaultImg: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      observer: null,
      intersected: false
    }
  },
  computed: {
    defaultImg() {
      return this.showDefaultImg ? DEFAULT_IMG : ''
    },
    srcImage() {
      return (this.intersected && this.imgSrc) ? this.imgSrc : this.defaultImg
    }
  },
  mounted() {
    this.observer = new IntersectionObserver((entries) => {
      const image = entries[0]
      if (image.isIntersecting) {
        this.intersected = true
        this.$emit('image-displayed')
        this.observer.disconnect()
      }
    })
    this.observer.observe(this.$el)
  },
  unmounted() {
    this.observer.disconnect()
  },
  methods: {
    imageLoadError() {
      this.intersected = false
    },
    onLoad() {
      this.$emit('image-load')
    }
  }
}
</script>
