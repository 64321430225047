// import Vue from 'vue'
// import VueRouter from 'vue-router'
import { createRouter, createWebHistory } from 'vue-router'
import { GTM } from 'utils/constants'
import { trackProductSearch } from 'utils/tracking/search/trackProductSearch'
import routes from './routes'

// Vue.use(VueRouter)

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    /* Preserve scrolling between rewards tabs */
    const redemtpionRegex = /rewards\/redeem/g
    const pdpRouteName = 'product-details'
    const previousPageWasPdp = from.name === pdpRouteName
    const currentPageIsPdp = to.name === pdpRouteName
    const scrollToProductTile = previousPageWasPdp && !currentPageIsPdp && savedPosition !== null

    if (from.path.match(redemtpionRegex) && to.path.match(redemtpionRegex)) {
      return {}
    }

    if (scrollToProductTile) {
      return new Promise((resolve) => {
        const checkCondition = () => {
          const productImageElement = document.querySelector('.product-grid-image')
          if (productImageElement) {
            resolve(savedPosition)
          } else {
            requestAnimationFrame(checkCondition)
          }
        }

        checkCondition()
      })
    }

    return { left: 0, top: 0 }
  }
})

// copied from TrackManagement.js

const prefix = 'gt-'

const trackGtmEvent = (event, data) => {
  window.dataLayer = window.dataLayer || []
  event = typeof event === 'string' ? event : ''
  data = typeof data === 'object' ? data : {}
  event = event.startWith(prefix) ? event : `${prefix}${event}`
  const payload = {
    event,
    ...data
  }
  window.dataLayer.push(payload)
  return payload
}

// logging redirects

router.afterEach((to) => {
  if (to.query?.searchRef === 'redirect') {
    trackGtmEvent(GTM.siteSearch, {
      numSearchResults: 0,
      misspelledTerm: undefined,
      searchTerm: to.query.searchTerm,
      searchType: 'redirect',
      searchItemsReturned: 0
    })
    trackProductSearch({
      term: to.query.searchTerm,
      type: 'redirect'
    })
  }
})

export default router
