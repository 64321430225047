export default {
  namespaced: true,
  state: {
    competitiveExclusionIdentifier: '',
    categoryForRequest: null,
    bannerXData: null
  },
  mutations: {
    setCompetitiveExclusionIdentifier(state, competitiveExclusionIdentifier = '') {
      state.competitiveExclusionIdentifier = competitiveExclusionIdentifier
    },
    setCategoryForRequest(state, categoryForRequest = {}) {
      state.categoryForRequest = categoryForRequest
    },
    setBannerXData(state, bannerXData = {}) {
      state.bannerXData = bannerXData
    }
  }
}
