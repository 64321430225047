<template>
   <div
    id="app"
    tabindex="-1"
    class="outline-0"
  >
    <PdlAlert
      :aria-hidden="ariaHideComponent"
    />
    <a
      id="skip-nav"
      class="skip-nav"
      href="#"
      :aria-hidden="ariaHideComponent"
      @click.prevent="focusMainContent"
    >
      Skip to Main Content
    </a>
    <MiniHeader
      v-if="!showFullHeader"
      ref="globalHeaderBase"
      :opco-theme="opcoTheme"
      :opco-id="opcoId"
      :is-mobile="isMobile"
      :is-tablet="isTablet"
      :is-mobile-app="isAnyApp"
      :aria-hidden="ariaHideComponent"
    />
    <PdlGlobalHeader
      v-if="showFullHeader"
      ref="globalHeaderBase"
      :opco-theme="opcoTheme"
      :opco-id="opcoId"
      :page-view="routeMetaData.pageTitle"
      :is-mobile="isMobile"
      :is-tablet="isTablet"
      :aria-hidden="ariaHideComponent"
    />
     <div aria-live="assertive">
       <ColorBarAlertList
         :alerts="alerts"
         :alert-style="isMobile ? 'mx-4 bottom-[17rem]' : 'max-w-[45.5rem] top-84 right-8'"
         @remove-alert="removeAlert"
       />
     </div>
    <PdlModals
      ref="modals"
    />
    <div
      ref="mainContent"
      tabindex="-1"
      :class="[
        'pdl-page clearfix',
        { 'pdl-page_native': isMobileApp }
      ]"
      :aria-hidden="ariaHideComponent"
      @focusin="captureModalFocus"
    >
      <router-view
        :key="viewKey"
        ref="routerView"
      />
    </div>
    <PdlVersion
      v-if="spyglassActive"
      @content-refresh="contentRefresh"
    />
    <PdlMobileAppNavigation v-if="isMobileApp" />
    <PdlChat
      v-if="loadChat"
      :aria-hidden="ariaHideComponent"
    />
    <WelcomeView />
  </div>
</template>
<script>
import {
  mapGetters, mapMutations, mapState
} from 'vuex'
import optimizelyFullstack from 'components/user-research/services/optimizely-fullstack-service'
import {
  GTM, GTM_GA4, ORDER_SERVICE_TYPES, USER_PREFERENCE_KEYS, SERVICE_TYPE_TEXT, CART_UPDATED_MESSAGE
} from 'utils/constants'
import UUID from 'utils/services/UUID'
import userPreferenceService from 'components/user/account/services/user-preference-services'
import UserPreferencesAPI from 'api/UserPreferencesAPI'
import { checkForceUpdate } from 'components/mobile-force-update/service/mobile-force-update-service'
import isBusinessCustomer from 'components/account/functions/isBusinessCustomer'
import optimizelyService from 'components/user-research/services/optimizely-service'
import { getAppName } from 'utils/basic/dom'
import { createOrderAlert } from 'utils/services/order/orderAlert'
import ProductContentService from 'utils/services/product-content-service'
import { superUserInit, superUserInitSessionOrders } from 'utils/services/super-user-init'
import { trackInit } from 'utils/tracking/trackInit'
import { trackUserProperties } from 'utils/tracking/login/trackUserProperties'
import { defineAsyncComponent } from 'vue'
import { formatUrlsToObj, transformContentValue } from 'utils/services/vanity-urls/formatVanityUrls'
import { trackOnNativeApp } from 'utils/tracking/native/trackOnNativeApp'
import ccAddToBasket from 'utils/services/cc-add-to-basket'
import MiniHeader from 'components/header/MiniHeader'
import getScript from 'utils/services/script-loader/get-script'
import { trackPageView } from 'utils/tracking/trackPageView'
import trackDataDogSession from 'utils/tracking/trackDataDogSession'
import ColorBarAlertList from 'components/ColorBarAlertList'
import { trackLoyaltyId } from './utils/tracking/trackLoyaltyId'
import ScreenSize from './utils/mixins/ScreenSize'
import PdlModals from './modals/PdlModals'
import PdlVersion from './components/PdlVersion'
import PdlGlobalHeader from './components/header/PdlGlobalHeaderBase'
import WelcomeView from './components/welcome-view/WelcomeView'
import StoreByStoreLocatorAPI from './api/storeByStoreLocatorAPI'
import ServiceLocationsAPI from './api/ServiceLocationsAPI'

const trySuperUserInit = () => {
  if (window.appConfig.superUserEnabled) {
    superUserInit()
    superUserInitSessionOrders()
  }
}

export default {
  mixins: [
    ScreenSize,
  ],
  components: {
    ColorBarAlertList,
    PdlVersion,
    PdlGlobalHeader,
    MiniHeader,
    PdlModals,
    WelcomeView,
    PdlMobileAppNavigation: defineAsyncComponent(
      () => import('./components/mobile-app/navigation/PdlMobileAppNavigation')
    ),
    PdlChat: defineAsyncComponent(() => import('./components/chat/PdlChat'))
  },
  data() {
    return {
      loadChat: false,
      routeMetaData: {},
      layoutTypes: {
        headless: [
          'registration',
          'password-request'
        ]
      },
      layout: '',
      cartUpdatedAlert: {
        title: 'Cart updated',
        text: CART_UPDATED_MESSAGE
      },
      alerts: []
    }
  },
  computed: {
    ...mapState({
      isNativeApp: state => state.NativeContainer?.isNativeApp,
    }),
    ...mapGetters({
      storeNumber: 'UserProfile/storeNumber',
      userInfo: 'UserProfile/userInfo',
      deliveryServiceLocation: 'UserProfile/deliveryServiceLocation',
      varByName: 'SiteConfig/varByName',
      opco: 'SiteConfig/opco',
      timeTravelDate: 'ScheduledContent/timeTravelDate',
      breadcrumbs: 'TitleBar/breadcrumbs',
      getBasketId: 'Cart/getBasketId'
    }),
    ...mapState({
      fullstackInitialized: state => state.Optimizely.fullstackInitialized,
      isAppLoaded: state => state.Application.isAppLoaded,
    }),
    ...mapGetters('UserPref', [
      'prefMerch',
      'prefPup',
    ]),
    ...mapGetters({
      spyglassActive: 'ScheduledContent/spyglassActive',
      isMobile: 'ScreenSize/isMobile',
      isTablet: 'ScreenSize/isTablet',
      isDesktop: 'ScreenSize/isDesktop',
      isMobileApp: 'NativeContainer/isMobileApp',
      isAnyApp: 'NativeContainer/isAnyApp',
      getVarByName: 'SiteConfig/varByName',
      currentModal: 'Modals/activeComponent',
      userId: 'UserProfile/userId',
      isCatagoryMenuOpen: 'GlobalHeader/isCategoryMenuOpen',
      nativeScheduledContentQueryData: 'ScheduledContent/nativeScheduledContentQueryData'
    }),
    screenSize() {
      if (this.isMobile) {
        return 'mobile'
      }
      if (this.isTablet) {
        return 'tablet'
      }
      if (this.isDesktop) {
        return 'desktop'
      }
      return ''
    },
    opcoTheme() {
      return window.appConfig?.opcoTheme || 'PPOD'
    },
    opcoId() {
      return window.appConfig?.opcoId || 'PPOD'
    },
    viewKey() {
      return this.$route?.meta?.keepAliveGroup || this.$route.fullPath
    },
    ariaHideComponent() {
      return !!this.currentModal
    },
    showFullHeader() {
      return this.layout !== 'headless'
    },
    allowAutoLogin() {
      return this.varByName('feature_allow_sl_auto_login')
    },
    isSameDayDeliveryFeatureEnabled() {
      return this.varByName('feature_express_delivery')
    }
  },
  watch: {
    storeNumber() {
      this.trackStoreNumber()
    },
    timeTravelDate(newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        this.$store.commit('ScheduledContent/setHomeKwmItems', [])
        this.$store.commit('ScheduledContent/setHomeHeroItems', [])
        this.showVisibleContent(newVal, oldVal)
      }
    },

    screenSize(newSize, oldSize) {
      if (oldSize !== '' && newSize !== oldSize) {
        this.contentRefresh()
      }
    },
    $route(to, from) {
      const clearCache = to.name !== 'product-details'
      this.dataDogSession()
      if (to.query.ccrb || to.query.ccr) {
        this.loadCC()
      }
      if (to.query.jiracore) {
        this.$store.commit('ScheduledContent/setNativeScheduledContentQueryData', decodeURI(to.query.jiracore))
      }
      if (!to.query.jiracore && this.nativeScheduledContentQueryData) {
        this.$store.commit('ScheduledContent/setNativeScheduledContentQueryData', '')
      }
      if (!to.path) return
      this.routeMetaData = to?.meta
      this.setDocumentTitle(to)
      this.checkForVanityRedirect()
      this.logRouteChange(to, from)
      this.routeChangeHandler(to)
      if (to.name === 'home' || to.name === 'browse-aisle'
        || to.name === 'recommended-for-you') {
        if (!this.isCatagoryMenuOpen) {
          this.setOpenCategoryMenu()
        }
      } else {
        this.setCloseCategoryMenu()
      }
      document.getElementById('app').focus({ preventScroll: true })

      // popstate happens after routing
      setTimeout(() => {
        if (clearCache && !window.isPopstate) {
          this.$store.commit('Products/clearCollectionCache')
        }
      })
    },
    fullstackInitialized(initialized) {
      if (initialized) {
        this.injectBazaarVoiceScript()
        this.getOptimizelyData()
      }
    },
    isAppLoaded(newVal) {
      if (newVal) {
        // call this method when all site config is loaded
        // to prevent undefined title
        this.setDocumentTitle(this.$route)
      }
    },
    userId(newId, oldId = window.appConfig?.user?.userId) {
      const hasChanged = newId !== oldId
      const isNotGhostUser = newId !== 2
      if (hasChanged && isNotGhostUser) {
        this.$trackGA4Event(GTM_GA4.userProperties, { user_id: newId })
      }
    },
  },
  async beforeCreate() {
    try {
      this.$store.commit('Application/setIsAppLoading', false)
      this.$store.commit('Application/setIsAppLoaded', false)
      // check if mobile app and then initialize native container
      this.NativeContainer.initContainer()
      // Initialize UUID
      UUID.init()
      this.$store.commit('SiteConfig/setOpco', appConfig.opcoTheme)
      this.$store.commit('SiteConfig/setOpcoId', appConfig.opcoId)
      // need to call site config here when curentApp equals superLoginApp/ResetPassword
      // So for all apps need to call here
      await this.$store.dispatch('SiteConfig/getConfig')
      const currentApp = getAppName()
      // this method is called here first for performance reasons
      this.showVisibleContent()

      if (!(currentApp === 'userApp' || currentApp === 'superUserApp')) {
        this.$store.commit('Application/setIsAppLoading', false)
        this.$store.commit('Application/setIsAppLoaded', true)
        return
      }

      // Tell App components which is the last critical API
      const appLoadTask = Promise.all([
        this.$store.dispatch('SiteConfig/fetchResources'),
        this.$store.dispatch('LoginStatus/getLoginStatus'),
        this.$store.dispatch('UserProfile/initQueryUserProfile')
      ])

      this.$store.commit('Application/setAppLoadPromise', appLoadTask)
      this.$store.commit('Application/setIsAppLoading', true)
      await appLoadTask

      const isLoggedIn = this.$store.getters['LoginStatus/isLoggedIn']
      const userProfile = this.$store.getters['UserProfile/userInfo']
      this.checkApplePaySupported()
      const serviceLocationId = this.$store.getters['UserProfile/serviceLocationId']

      if (serviceLocationId) {
        await this.$store.dispatch('FoodLionTransition/requestStoreEcommerceState', serviceLocationId)
      }

      // Only use for critical APIs
      this.$store.commit('Application/setIsAppLoading', false)
      this.$store.commit('Application/setIsAppLoaded', true)
      this.$store.dispatch('Cart/queryCart')
      const { query } = this.$route
      const currentMobileVersion = query.v
      checkForceUpdate(currentMobileVersion)

      const isMultiBasket = this.$store.getters['SiteConfig/varByName']('feature_multibasket')
      const defaultDeliveryAddress = this.$store.getters['UserProfile/defaultDeliveryAddress']
      const defaultBillingAddress = this.$store.getters['UserProfile/defaultBillingAddress']
      const userContent = {
        userProfile,
        orderStatus: this.$store.getters['Order/orderStatus'],
        defaultDeliveryAddress,
        defaultBillingAddress,
        name: {
          firstName: this.$store.getters['UserProfile/displayFirstName'],
          lastName: this.$store.getters['UserProfile/displayLastName']
        }
      }
      // Call pending api to get the list of submitted orders - precut off orders - ship2me and pdl
      if (isMultiBasket && isLoggedIn) {
        await this.$store.dispatch('Order/initOrderStatus', { canRefresh: true })
      }
      this.setPrefPup()
      this.setShoppingMode(userProfile)
      this.$store.commit('ScheduledContent/setUserContent', userContent)
      await this.$store.dispatch('LoyaltyAccount/getLoyalty')
      const userCardId = this.$store.getters['UserProfile/retailerCardNumber']
      if (userCardId) {
        trackLoyaltyId(userCardId)
      }
      const isPdlWeeklyAd = this.$store.getters['SiteConfig/varByName']('feature_weekly_ad_pdl')
      if (isPdlWeeklyAd) {
        await this.$store.dispatch('WeeklyAdPDL/getWeeklyAd')
      } else {
        await this.$store.dispatch('WeeklyAd/getPublications')
        this.$store.dispatch('WeeklyAd/getWeeklyAdProducts')
      }

      isBusinessCustomer()
      this.$store.dispatch('PushNotification/enable')

      if (!this.varByName('feature_site_transition_modal')
        && !this.helpModeViewed()
        && !this.$store.getters['NativeContainer/isNativeApp']) {
        this.setAndCacheHelpMode()
      }
      this.serviceTypeChanged()

      if (!isMultiBasket && !this.isNativeApp) {
        const orderAlertDelay = this.createOrderAlertDelay()
        setTimeout(this.createOrderAlert, orderAlertDelay)
      }

      trackInit(this.$store.getters['NativeContainer/isMobileApp'])
      trySuperUserInit()
      trackUserProperties()
      // selects a store that is on prism and on spectrum  coming from unata
      // with the url parameters including: shopping method, store, and zip
      if (this.opcoId === 'FDLN') {
        this.modifyUnataUrl()
      }
    } catch (err) {
      console.error('APPLOAD:ERROR', err)
    }
  },
  created() {
    this.setAppStartTime()
    optimizelyFullstack.init()
    this.setPageLayout()
    this.checkMobileAppStatus()
  },
  async beforeMount() {
    await this.$store.dispatch('ShoppingList/mergeGuestList')
    await this.$store.dispatch('ShoppingList/queryUserLists', this.userId)
  },
  mounted() {
    setTimeout(() => {
      this.loadChat = true
    }, 4000)
    if (window.localStorage.getItem('shopMethodChanged') && this.isSameDayDeliveryFeatureEnabled) {
      this.pushAlert(this.cartUpdatedAlert)
      window.localStorage.removeItem('shopMethodChanged')
    }
  },
  methods: {
    checkApplePaySupported() {
      if (window.ApplePaySession) {
        const applePayMerchantIdentifier = window.$store.getters['SiteConfig/vars'].apple_pay_merchant_identifier
        const promise = window.ApplePaySession.canMakePaymentsWithActiveCard(applePayMerchantIdentifier)
        promise.then(async (canMakePayments) => {
          if (canMakePayments) {
            optimizelyFullstack.trackEvent('Apple Pay Feature - Impression')
            window.$store.commit('ApplePaymentStore/setShowApplePay', true)
          }
        })
      }
    },
    setAndCacheHelpMode() {
      window.localStorage.setItem('helpModeViewed', true)
      this.$store.commit('ShoppingMode/setHelpMode', true)
      this.$store.commit('ShoppingMode/setStoreConfirmationState', true)
      this.$store.dispatch('GlobalHeader/addMaskOverlay')
    },
    helpModeViewed() {
      return !!window.localStorage.getItem('helpModeViewed')
    },
    createOrderAlert() {
      const isLoggedIn = this.$store.getters['LoginStatus/userStatus'] !== 'G'
      const wasViewed = this.$store.getters['Order/orderNoticeViewed']
      if (isLoggedIn && !wasViewed) {
        createOrderAlert()
        this.$store.commit('Order/setOrderNoticeViewed', true)
      }
    },
    createOrderAlertDelay() {
      const defaultDelay = 0
      try {
        const configOrderAlertDelay = this.$store.getters['SiteConfig/vars'].config_order_alert_delay
        const customDelay = parseInt(configOrderAlertDelay, 10)
        return Number.isNaN(customDelay) ? defaultDelay : customDelay
      } catch (e) {
        return defaultDelay
      }
    },
    setAppStartTime() {
      optimizelyService.init()
      const appStartTime = new Date().getTime()
      window.sessionStorage.setItem('app_start_time', appStartTime)
      window.initPhase.flag = false
    },
    serviceTypeChanged() {
      const serviceTypeChanged = JSON.parse(window.localStorage.getItem('serviceTypeChange'))
      const serviceTypeChangeCached = serviceTypeChanged !== null
      if (serviceTypeChangeCached) {
        // sessionID will be different if user refreshed for service type, need to rely on a 30 second timeout
        const cachedRecentEnough = new Date().getTime() - serviceTypeChanged < 30000
        if (cachedRecentEnough) {
          // setup value in Vuex to disable additional check for service type if user already changed it
          this.$store.commit('ShoppingMode/setUserSelected', true)
        }
        // remove item for future cases
        localStorage.removeItem('serviceTypeChange')
        this.$trackGA4Event(GTM_GA4.userProperties, { subscription_plan: cachedRecentEnough })
      }
    },
    setShoppingMode() {
      this.$store.dispatch(
        'ShoppingMode/setDeliveryDefaultZip',
        this.deliveryServiceLocation.zip
      )
    },
    async setPrefPup() {
      const { userId } = appConfig.user
      const preferedPickupLocation = await userPreferenceService.get(
        USER_PREFERENCE_KEYS.PREFERRED_PUP, userId
      )
      if (preferedPickupLocation.value) {
        this.$store.commit('UserPref/setPrefPup', preferedPickupLocation.value)
      }
    },
    // Where is this called
    initialSlotsSetup() {
      const store = window.sharedVue.config.globalProperties.$store
      const performSlotsHomepageRequest = !!store.getters['SiteConfig/varByName']('feature_slots_homepage_request')
      const deliveryServiceLocation = store.getters['UserProfile/deliveryServiceLocation']

      if (!performSlotsHomepageRequest) return

      const { serviceType } = deliveryServiceLocation
      const serviceNeedsSlots = [ORDER_SERVICE_TYPES.DELIVERY, ORDER_SERVICE_TYPES.PICKUP].includes(serviceType)

      if (!serviceNeedsSlots) return

      const config = 'PdlSharedApp-initialSlotsSetup'
      this.$store.dispatch('Slots/displaySlots', { initialCall: true, serviceType, config })
    },
    trackStoreNumber() {
      if (this.storeNumber) {
        this.$trackGtmEvent(GTM.storeNumber, {
          storeNumber: this.storeNumber
        })
      }
    },
    showVisibleContent() {
      const contentRegions = {
        config: {
          home_page_hero: {
            limit: 100,
          },
        }
      }
      this.$store.dispatch('ScheduledContent/queryRegion',
        {
          region: contentRegions.config
        }).then((responseData) => {
        this.showHeroContent(responseData.response.regionContents)
      })
    },
    showHeroContent(contentData) {
      const homePageHeroContent = contentData.filter(
        val => val.region === 'home_page_hero'
      )[0]
      const homepageItems = ProductContentService.groupScheduledEntry(
        homePageHeroContent
      )
      // Need to display based on position order. Not coming sorted in the api
      const heroItems = homepageItems.filter(
        item => item.scheduledContentData.contentType === 'Content: Hero Banner'
      ).sort(
        (itemA, itemB) => itemA.scheduledContentData.Position_Order - itemB.scheduledContentData.Position_Order
      )
      const kwmItems = homepageItems.filter(
        item => item.scheduledContentData.contentType !== 'Content: Hero Banner'
      )
      this.$store.commit('ScheduledContent/setHomeKwmItems', kwmItems)
      this.$store.commit('ScheduledContent/setHomeHeroItems', heroItems)
    },
    ...mapMutations({
      setCloseCategoryMenu: 'GlobalHeader/setCloseCategoryMenu',
      setOpenCategoryMenu: 'GlobalHeader/setOpenCategoryMenu'
    }),
    setPageLayout() {
      const value = this.$route?.name || ''
      const hasLayout = Object.keys(this.layoutTypes).find((key) => {
        const layout = this.layoutTypes[key]
        return layout.indexOf(value) !== -1
      })
      this.layout = !hasLayout ? '' : hasLayout
    },
    contentRefresh() {
      this.$emitter.emit('content-refresh')
    },
    async loadCC() {
      await this.$store.dispatch('UserProfile/initQueryUserProfile')
      ccAddToBasket.loadCC()
    },
    async checkMobileAppStatus() {
      try {
        const isNativeContainer = await this.NativeContainer.isEnabledPromise()
        if (isNativeContainer) {
          await this.NativeContainer.capabilities.LoadingService.stopLoading()
        }
      } catch (error) {
        const payload = {
          error: JSON.stringify(error)
        }
        this.$trackClientLog('nativeContainerStopLoadingError', payload, 'EX')
      }
    },
    async checkForVanityRedirect() {
      const queryParams = this.$route.query
      const route = this.$route.path
      const predefinedRoutes = this.$router?.options?.routes
      const isPredefinedRoute = predefinedRoutes.some((key) => {
        const firstPath = key.path.split('/')[1]
        const routeFirst = route.split('/')[1]
        return routeFirst === firstPath
      })
      const isHomepageAndNotDeepLink = route === '/' && !queryParams['999']
      const isPagesInRoute = route.includes('/pages/')
      const isFdlnPharamcy = this.opcoId === 'FDLN' && route === '/pharmacy'
      const isRouteKnown = !isPagesInRoute && isPredefinedRoute && !isFdlnPharamcy
      if (isRouteKnown || isHomepageAndNotDeepLink || route === '/home') return
      const vanityObj = formatUrlsToObj(queryParams)
      const pathName = vanityObj.path ? vanityObj.path : route.substring(1)
      const responseData = await this.$store.dispatch('ScheduledContent/queryRegion', {
        region: {
          vanity_url: {
            ff: {
              'Vanity Url Key': [pathName]
            }
          }
        }
      })
      const regionContents = responseData.response?.regionContents
      if (regionContents.length && regionContents[0].contents && regionContents[0].contents.length) {
        const vanityUrl = regionContents[0].contents[0].map.scheduledContentData.map['Vanity Url']
        if (vanityUrl) {
          const formattedUrl = transformContentValue(vanityUrl, vanityObj.fields)
          this.$router.replace(formattedUrl)
        }
      }
    },
    logRouteChange(to, from) {
      const normalizedPath = to.path
      const nativeQueryPresent = from.query.native || to.query.native
      trackOnNativeApp(nativeQueryPresent, to.query.v || '')
      optimizelyService.trackPage(normalizedPath)
      trackPageView({
        url: normalizedPath,
        title: window.document.title || ''
      })
      if (to.name === 'browse-aisle') {
        optimizelyFullstack.trackEvent('Browse Aisles Pageviews')
      } else if (to.name === 'product-search') {
        optimizelyFullstack.trackEvent('Search Pageview')
      } else if (normalizedPath.includes('savings/all-specials') || normalizedPath === '/savings') {
        optimizelyFullstack.trackEvent('Sales Page Pageviews')
      } else if (normalizedPath.includes('savings/coupons')) {
        optimizelyFullstack.trackEvent('Coupons Pageview')
      }
    },
    routeChangeHandler(to) {
      const { path } = to
      const currentScheduledContent = this.$store.getters['ScheduledContent/currentScheduledContent']
      if (!_.isEmpty(currentScheduledContent)) {
        const currentScheduledContentUrlPath = this.parseUrlPath(currentScheduledContent.url)
        const encodedPath = this.parseUrlPath(path)
        if (currentScheduledContentUrlPath !== encodedPath) {
          this.$store.commit('ScheduledContent/setCurrentScheduledContent', {})
        }
      }
      if (this.$store.getters['NativeContainer/isMobileApp']) {
        this.$store.commit('MobileAppNavigation/tabChangeListener', path)
      }
      this.$store.commit('ScheduledContent/resetQuotIndex')
      window.googletag.cmd.push(() => {
        window.googletag.destroySlots()
      })
      this.setPageLayout()
    },
    parseUrlPath(url) {
      const fullUrl = `${window.location.origin}${url}`
      const parser = new URL(fullUrl)
      return parser.pathname
    },
    getSavingsScreenTab(routeConfig) {
      // TO DO: Move getSavingsScreenTab and setDocumentTitle out to a utils file
      let tab = routeConfig?.params?.tab
      const hasAllSpecialsTab = this.getVarByName('feature_all_specials')
      const hasWeeklyAdTab = this.getVarByName('feature_weekly_ad')
      const hasCouponsTab = this.getVarByName('feature_coupons')
      const isDesktopLandingScreen = !tab && this.isDesktop
      const savingsTabs = {
        'all-specials': 'All Specials',
        'weekly-ad': 'Weekly Ad',
        coupons: 'Coupons'
      }

      if (isDesktopLandingScreen) {
        if (hasAllSpecialsTab) {
          tab = 'all-specials'
        } else if (hasWeeklyAdTab) {
          tab = 'weekly-ad'
        } else if (hasCouponsTab) {
          tab = 'coupons'
        }
      }

      return tab ? savingsTabs[tab] : ''
    },
    setDocumentTitle(routeConfig) {
      let pageTitle = this.routeMetaData?.pageTitle
      const isSplashPage = this.routeMetaData?.isSplashPage
      const isRewardsPage = this.routeMetaData?.isRewardsPage
      const brandName = this.getVarByName('brand_name')
      const homePageTitle = this.getVarByName('brand_site_title')

      // To prevent an undefined title, only set the title if site config data has been set.
      if (!brandName || !homePageTitle) {
        return
      }

      // Browse Aisles, PDP, and splash page titles are handled on those pages because they require extra data.
      if (pageTitle === 'Browse Aisles' || pageTitle === 'Item Detail' || isSplashPage) return

      if (pageTitle === 'Savings') {
        const savingsTab = this.getSavingsScreenTab(routeConfig)
        pageTitle = savingsTab ? `${pageTitle} - ${savingsTab}` : `${pageTitle}`
      }

      if (isRewardsPage) {
        const rewardsName = this.getVarByName('brand_rewards_program_name')
        pageTitle = `${rewardsName}`
      }

      window.document.title = pageTitle ? `${pageTitle} | ${brandName}` : `${homePageTitle}`
    },
    captureModalFocus() {
      const modalComponent = this.$refs?.modals?.$el?.children[0]?.children[0] || null
      if (this.currentModal && modalComponent) {
        const closeButton = modalComponent.querySelector('#close-button')
        const backButton = document.querySelector('#back-button')
        if (backButton) {
          backButton.focus()
        } else if (closeButton) {
          closeButton.focus()
        } else {
          modalComponent.focus()
        }
      }
    },
    focusMainContent() {
      // Prevent focus scroll because it scrolls past the top of the main content.
      // Set scrolltop for browsers where preventScroll option does not work.
      const { scrollTop } = document.body
      const isHomePage = this.$route.path === '/' || this.$route.path === '/home'
      const titleBar = this.$refs.globalHeaderBase?.$refs.titleBar
      const mobileMainContent = this.$refs.routerView?.$refs.mobileMainContent
      const notifications = this.$refs.globalHeaderBase.$refs.notificationBar
      const focusMobileContent = this.isMobile && mobileMainContent
      const hasNotifications = isHomePage && this.$store.getters['Notifications/count']

      if (titleBar) {
        titleBar.$el.focus({ preventScroll: true })
      } else if (focusMobileContent) {
        mobileMainContent.focus({ preventScroll: true })
      } else if (hasNotifications) {
        notifications.$el.focus({ preventScroll: true })
      } else {
        this.$refs.mainContent.focus({ preventScroll: true })
      }
      document.body.scrollTop = scrollTop
    },
    dataDogSession() {
      const ddRumUser = window?.DD_RUM?.getInternalContext() || {}
      const { session_id: sessionId = '', view: { id = '' } = {} } = ddRumUser
      trackDataDogSession(sessionId, id)
    },
    async injectBazaarVoiceScript() {
      const bvloaderScript = this.getVarByName('config_bazaarvoice_bvloader_script')
      const tileRatingsABTestEnabled = await optimizelyFullstack.isFeatureEnabled('product_tile_rating_stars')
      const showTileRatingsABTest = await optimizelyFullstack
        .getFeatureVariableBoolean('product_tile_rating_stars', 'show_tile_rating_stars')
      const tileRatingsABTestCategories = await optimizelyFullstack
        .getSingleFeatureVariable('product_tile_rating_stars', 'product_categories')
      const showTileRatings = tileRatingsABTestEnabled && showTileRatingsABTest && tileRatingsABTestCategories
      if (showTileRatings && bvloaderScript) {
        this.$store.commit('Optimizely/setTileRatingsCategories', tileRatingsABTestCategories)
        this.$store.commit('Optimizely/setShowTileRatingsABTest', showTileRatings)
        getScript(bvloaderScript)
      }
    },
    async getOptimizelyData() {
      // for showing and hiding the Quotient Ad in weekly ad
      const variation = await optimizelyFullstack.getVariation(
        'm8s-665__a_b_test__weekly_ads__skinner_banner_ad_placement'
      )
      if (variation && variation === 'variation_2') {
        this.$store.commit('Optimizely/setShowWeeklyAdQuotientAd', true)
      }

      // Optimizely for redeem in cart and promo code
      await this.getInCartPointRedemption()

      const isFullscaleRvenueTestingyEnabled = await optimizelyFullstack
        .isFeatureEnabled('challenger_testing_fullscale_incremental_revenue_testing')
      const optimizelyIncrementalRevenueTest = await optimizelyFullstack
        .getFeatureVariableBoolean('challenger_testing_fullscale_incremental_revenue_testing', 'fullscale_revenue')
      this.$store.commit(
        'Optimizely/setHideRecommendedForYou',
        isFullscaleRvenueTestingyEnabled && !optimizelyIncrementalRevenueTest
      )
      this.searchShelfHeaderOptimizelyData()
      if (this.getVarByName('feature_semantic_search')) {
        this.semanticSearchV7OptimizelyData()
      }
      if (!this.isAnyApp) {
        let headerNavReDesignAbTestEnabled = false
        let showHeaderNavReDesignABTest = false
        if (!this.isDesktop) {
          headerNavReDesignAbTestEnabled = await optimizelyFullstack.isFeatureEnabled('2023_global_navigation_mobile')
          showHeaderNavReDesignABTest = await optimizelyFullstack
            .getFeatureVariableBoolean('2023_global_navigation_mobile', '2023_global_navigation_mobile')
        }
        this.$store.commit(
          'Optimizely/setShowNewNavigationMobileDesign', headerNavReDesignAbTestEnabled && showHeaderNavReDesignABTest
        )
      }
      const { userId } = appConfig.user
      const paymentTypesEnabled = await optimizelyFullstack.isFeatureEnabled('payment_types', userId)
      this.$store.commit('ApplePaymentStore/setPaymentTypesEnabled', paymentTypesEnabled)
      const applePayOptimizely = await optimizelyFullstack.getFeatureVariableBoolean('payment_types', 'applepay')
      if (!applePayOptimizely) {
        // fall back when optimizely returns null or undefined
        this.$store.commit('ApplePaymentStore/setApplePayOptimizely', true)
      } else {
        this.$store.commit('ApplePaymentStore/setApplePayOptimizely', applePayOptimizely)
      }
      this.getTimeSlotNudgeOptimizelyData()
      this.getMobileWebContentTileOptimizelyData()
      this.getPDPRedesignOptimizelyData()
      await this.filterRedesignOptimizelyData()
    },
    async searchShelfHeaderOptimizelyData() {
      const searchShelfHeaderEnabled = await optimizelyFullstack.isFeatureEnabled('search_shelf_header_redesign')
      const largeImageTileEnabled = await optimizelyFullstack.getFeatureVariableBoolean('search_shelf_header_redesign',
        'large_image_tiles')
      this.$store.commit(
        'Optimizely/setSearchShelfLargeImageTile',
        searchShelfHeaderEnabled && largeImageTileEnabled
      )
      this.$store.commit(
        'Optimizely/setSearchShelfHeaderEnabled',
        searchShelfHeaderEnabled
      )
    },
    async semanticSearchV7OptimizelyData() {
      const semanticSearchFeatureEnabled = await optimizelyFullstack.isFeatureEnabled('semantic_search_v2')
      const semanticSearchV7Enabled = await optimizelyFullstack.getFeatureVariableBoolean('semantic_search_v2',
        'semantic_search_v2')
      this.$store.commit(
        'Optimizely/setSemanticSearchFeatureEnabled',
        semanticSearchFeatureEnabled
      )
      this.$store.commit(
        'Optimizely/setSemanticSearchV7Enabled',
        semanticSearchV7Enabled && semanticSearchFeatureEnabled
      )
    },
    async filterRedesignOptimizelyData() {
      const searchFilterRedesignControl = await optimizelyFullstack
        .isFeatureEnabled('search_search_sort_filter_redesign')
      const searchFilterRedesignEnabled = await optimizelyFullstack
        .getFeatureVariableBoolean('search_search_sort_filter_redesign', 'search_search_sort_filter_redesign')
      this.$store.commit(
        'Optimizely/setSearchFilterRedesignEnabled',
        searchFilterRedesignEnabled && searchFilterRedesignControl
      )
      this.$store.commit(
        'Optimizely/setSearchFilterRedesignControl',
        searchFilterRedesignControl
      )
    },
    async getInCartPointRedemption() {
      const inCartPointRedemptionEnabled = await optimizelyFullstack
        .isFeatureEnabled('in_cart_point_redemption_feature')

      if (inCartPointRedemptionEnabled) {
        const rewardsRedemptionABTest = await optimizelyFullstack
          .getFeatureVariableBoolean('in_cart_point_redemption_feature', 'incartpointredemption')
        this.$store.commit('Optimizely/setShowRedeemInCart', rewardsRedemptionABTest)
        const promoCodeABTest = await optimizelyFullstack
          .getFeatureVariableBoolean('in_cart_point_redemption_feature', 'promocode')
        this.$store.commit('Optimizely/setShowPromoCode', promoCodeABTest)
      } else {
        // Default when not running A/B, show PromoCode, hide RedeemInCart
        this.$store.commit('Optimizely/setShowRedeemInCart', false)
        this.$store.commit('Optimizely/setShowPromoCode', true)
      }
    },
    async getTimeSlotNudgeOptimizelyData() {
      const isTimeSlotNudgeEnabled = await optimizelyFullstack
        .isFeatureEnabled('timeslot_nudge')
      if (isTimeSlotNudgeEnabled) {
        const forcedTimeSlotNudge = await optimizelyFullstack
          .getFeatureVariableBoolean('timeslot_nudge', 'forced_timeslot_nudge')
        const loggedIn = await optimizelyFullstack.getFeatureVariableBoolean('timeslot_nudge', 'log_in')
        const basketValue = await optimizelyFullstack.getFeatureVariableInteger('timeslot_nudge', 'basket_value')
        const serviceType = await optimizelyFullstack.getFeatureVariableString('timeslot_nudge', 'service_type')
        this.$store.commit('Optimizely/setTimeSlotNudge', {
          forcedTimeSlotNudge, loggedIn, basketValue, serviceType
        })
      }
    },
    async getMobileWebContentTileOptimizelyData() {
      const isMobileWebContentTileEnabled = await optimizelyFullstack
        .isFeatureEnabled('mobile_web_content_tile_design')
      const simpleTile = await optimizelyFullstack
        .getFeatureVariableBoolean('mobile_web_content_tile_design', 'content_simple_tile')
      const gridTile = await optimizelyFullstack
        .getFeatureVariableBoolean('mobile_web_content_tile_design', 'content_grid')
      const carouselTile = await optimizelyFullstack
        .getFeatureVariableBoolean('mobile_web_content_tile_design', 'content_carousel')
      this.$store.commit('Optimizely/setMobileWebContentTile', {
        isMobileWebContentTileEnabled, simpleTile, gridTile, carouselTile
      })
    },
    async getPDPRedesignOptimizelyData() {
      const pdpRedesignEnabled = await optimizelyFullstack
        .isFeatureEnabled('pdp_redesign')
      this.$store.commit('Optimizely/setPdpRedesignEnabled', pdpRedesignEnabled)
    },
    redirectToHome() {
      window.location.href = (`${window.location.protocol}//${window.location.host}/home`)
    },
    async zipcodeFromUnata(queryParams) {
      let zipCode = ''
      // to get the zip code info from store number coming from unata params
      if (queryParams.shopping_context === SERVICE_TYPE_TEXT.D) {
        zipCode = queryParams.delivery_zip
      }

      if (queryParams.shopping_context === SERVICE_TYPE_TEXT.P) {
        const { data, status } = await StoreByStoreLocatorAPI.getStoresByStoreId('FDLN', queryParams.store)
        if (status === 200) {
          zipCode = data.store?.zip
        }
      }
      return zipCode
    },
    async updateUserPref(type, area) {
      const prefKeys = {
        P: USER_PREFERENCE_KEYS.PREFERRED_PUP,
        D: USER_PREFERENCE_KEYS.PREFERRED_DELIVERY,
        B: USER_PREFERENCE_KEYS.PREFERRED_IN_STORE
      }
      const id = area.serviceType === 'P' ? area.pupId : area.id
      UserPreferencesAPI.update(prefKeys[type], id, this.userId)
      if (this.prefMerch !== 'true' && (type === 'P' || type === 'D')) {
        UserPreferencesAPI.update(
          USER_PREFERENCE_KEYS.MERCH_OPTION_SHOWN, 'true', this.userId
        )
      }
    },
    async modifyUnataUrl() {
      const queryParams = this.$route.query
      // queryParams.store is a three or four digits string, (e.g.,'507', '2229')
      const urlStoreNumber = Number(queryParams.store)
      // this.deliveryServiceLocation.storeNumber is a 4 digits string, (e.g.,'0507', '2229')
      const currentStoreNumber = Number(this.deliveryServiceLocation.storeNumber)
      const serviceType = Object.keys(SERVICE_TYPE_TEXT)
        .find(key => SERVICE_TYPE_TEXT[key] === queryParams.shopping_context)
      const zipCode = await this.zipcodeFromUnata(queryParams)
      if (zipCode) {
        if (this.deliveryServiceLocation?.zip === zipCode
          && this.deliveryServiceLocation?.serviceType === serviceType
          && currentStoreNumber === urlStoreNumber) {
          return
        }
        const results = await ServiceLocationsAPI.get({
          params: {
            customerType: 'C',
            opco: 'FDLN',
            radius: '30',
            serviceType,
            zip: zipCode
          },
        })
        const { data, status } = results
        if (status === 200) {
          const { response } = data
          // locationNumber is a three or four digits string, (e.g.,'507', '2229')
          let matchLocation = response?.locations.find(loc => loc?.location?.locationNumber === queryParams.store)
          if (!matchLocation?.location) {
            matchLocation = response?.locations.find(loc => loc?.location?.zip === zipCode)
          }
          const { location } = matchLocation
          const query = {
            currentServiceLocationId: location.id,
            serviceLocationId: location.id,
            userId: this.userId,
            orderId: this.userInfo.information.currentOrderId,
            delivAreaChange: serviceType === 'D',
            custTypeChange: false,
            basketId: this.getBasketId,
            serviceType
          }
          await ServiceLocationsAPI.update(query, this.allowAutoLogin)
          await this.updateUserPref(serviceType, location)
          setTimeout(() => {
            this.redirectToHome()
          }, 1000)
        }
      }
    },
    pushAlert(alert) {
      this.alerts.push(alert)
    },
    removeAlert(alert) {
      this.alerts.splice(this.alerts.indexOf(alert), 1)
    }
  }
}
</script>
