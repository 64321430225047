<template>
  <div class="absolute w-full h-full flex items-center justify-center">
    <div class="max-w-md">
      <span class="font-bold">
        {{ errorHeader }}
      </span>
      <p class="pt-4 text-gray-300">
        {{ errorMessage }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    errorMessage: {
      type: String,
      default: 'Close this window and try again. If the issue persists, try refreshing your page.'
    }
  },
  data: () => ({
    errorHeader: 'Looks like something went wrong.',
  })
}
</script>
