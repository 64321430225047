import ApiService from '@/api/'

export default {
  getCategories(serviceLocationId) {
    return ApiService.get(`/api/v3.0/user/categories/service-location/${serviceLocationId}`)
  },
  getSubCategories(categoryId, serviceLocationId) {
    return ApiService.get(`/api/v3.0/user/categories/${categoryId}/service-location/${serviceLocationId}`)
  }
}
