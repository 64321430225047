/* eslint-disable */
import ServiceLocationsAPI from 'api/ServiceLocationsAPI'

const storeEcommerceStates = {
  UNATA: 'UNATA',
  P3: 'P3',
  NON_ECOMMERCE: 'NON_ECOMMERCE'
}

const brandTransitionStates = {
  UNSTARTED: 'UNSTARTED',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED: 'COMPLETED'
}

export default {
  namespaced: true,
  state: {
    storeEcommerceStateById: {},
    storeEcommerceStateInitialized: false
  },
  getters: {
    storeEcommerceState: (state) => {
      return function (serviceLocationId) {
        return state.storeEcommerceStateById[serviceLocationId]
      }},
    storeServiceLocationId: (...[, , , rootGetters]) => {
      const deliveryServiceLocation = rootGetters['UserProfile/deliveryServiceLocation']
      return deliveryServiceLocation.serviceLocationId
    },
    storeIsSpectrum: (_, getters) => getters.storeEcommerceState(getters.storeServiceLocationId) === storeEcommerceStates.P3,
    storeIsP3: (_, getters) => id => getters.storeEcommerceState(id) === storeEcommerceStates.P3,
    storeIsUnata: (_, getters) => id => getters.storeEcommerceState(id) === storeEcommerceStates.UNATA,
    storeIsNonEcom: (_, getters) => id => getters.storeEcommerceState(id) === storeEcommerceStates.NON_ECOMMERCE,
    transitionState: (...[, , , rootGetters]) => {
      return rootGetters['SiteConfig/varByName']('ecommerce_transition_state')
    },
    transitionUnstarted: (_, getters) => getters.transitionState === brandTransitionStates.UNSTARTED,
    transitionInProgress: (_, getters) => getters.transitionState === brandTransitionStates.IN_PROGRESS,
    transitionCompleted: (_, getters) => getters.transitionState === brandTransitionStates.COMPLETED,
    transitionCompletedOrStoreIsP3: (...[, getters, , rootGetters]) => {
      const deliveryServiceLocation = rootGetters['UserProfile/deliveryServiceLocation']
      const serviceLocationId = deliveryServiceLocation.serviceLocationId
      return getters.storeIsP3(serviceLocationId) || getters.transitionCompleted
    }
  },
  mutations: {
    setStoreEcommerceState(state, { serviceLocationId, storeState }) {
      state.storeEcommerceStateById = {
        ...state.storeEcommerceStateById,
        [serviceLocationId]: storeState
      }
      state.storeEcommerceStateInitialized = true
    }
  },
  actions: {
    async requestStoreEcommerceState({ commit, getters }, serviceLocationId) {
      const resp = await ServiceLocationsAPI.get({
        params: {
          serviceLocationId: serviceLocationId
        }
      })
      const location =  resp?.data?.response?.locations[0]?.location
      commit('Cart/setShipMethod', location?.shipMethod, { root: true })
      commit('Cart/setDeliveryPartner', location?.deliveryPartner, { root: true })
      commit('Cart/setSubstitutionType', location?.substitutionType, { root: true })
      const existingStoreState = getters.storeEcommerceState(serviceLocationId)
      if (!existingStoreState) {
        const returnedStore = location || {}
        const {
          active, serviceType, site, pickupSiblingEnabled, pickupSiblingSite
        } = returnedStore
        let ecomEnabledCheck = false
        let siteCheck = ''
        if (serviceType === 'B') {
          ecomEnabledCheck = pickupSiblingEnabled
          siteCheck = pickupSiblingSite?.toUpperCase()
        } else {
          ecomEnabledCheck = active
          siteCheck = site?.toUpperCase()
        }

        if (!ecomEnabledCheck) {
          commit('setStoreEcommerceState', { serviceLocationId, storeState: storeEcommerceStates.NON_ECOMMERCE })
        } else if (siteCheck === 'UNATA') {
          commit('setStoreEcommerceState', { serviceLocationId, storeState: storeEcommerceStates.UNATA })
        } else if (siteCheck === 'PRISM') {
          commit('setStoreEcommerceState', { serviceLocationId, storeState: storeEcommerceStates.P3 })
        } else {
          console.error("Unhandled store state", returnedStore)
        }
      }
    }
  }
}
