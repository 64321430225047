import { handleExternalItemClick } from 'components/mobile-app/navigation/utils'

/**
 * Whether is FDLN opco
 * @return {boolean}
 * */
const isFDLN = () => {
  const opco = window.sharedVue.config.globalProperties.$store.getters['SiteConfig/opco']
  return opco === 'FDLN'
}

/**
 * Whether the selected FDLN store is on Spectrum
 * @return {boolean} - true: is a Spectrum store, false: is not a Spectrum store
 * */
const currentStoreIsOnSpectrum = (serviceLocationId) => {
  const store = window.sharedVue.config.globalProperties.$store
  const transitionCompleted = store.getters['FoodLionTransition/transitionCompleted']
  const transitionInProgress = store.getters['FoodLionTransition/transitionInProgress']
  const storeIsP3 = store.getters['FoodLionTransition/storeIsP3']

  return !isFDLN()
    || transitionCompleted
    || (transitionInProgress && storeIsP3(serviceLocationId))
}

const selectedLocationIsOnSpectrum = (location) => {
  const store = window.sharedVue.config.globalProperties.$store
  const transitionCompleted = store.getters['FoodLionTransition/transitionCompleted']
  return !isFDLN() || transitionCompleted || (location.active === true && location.site === 'prism')
}

/**
 * Whether the selected FDLN store is Browser Only store
 * @return {boolean} - true: is browser only, false: is not browser only
 * */
const nonSpectrumBrowseOnly = () => {
  const store = window.sharedVue.config.globalProperties.$store
  const transitionUnstarted = store.getters['FoodLionTransition/transitionUnstarted']
  const transitionInProgress = store.getters['FoodLionTransition/transitionInProgress']
  const storeIsSpectrum = store.getters['FoodLionTransition/storeIsSpectrum']
  return isFDLN()
    && (transitionUnstarted || (transitionInProgress && !storeIsSpectrum))
}

/**
 * Whether to hide right tile for FDLN ("Try Pickup/Delivery" or "Select a Time")
 * @return {boolean} - true: hide, false: show
 * */
const nonSpectrumHideRightTile = () => {
  const store = window.sharedVue.config.globalProperties.$store
  const transitionInProgress = store.getters['FoodLionTransition/transitionInProgress']
  const serviceType = store.getters['UserProfile/serviceType']

  const exception = transitionInProgress && serviceType === 'B'
  return nonSpectrumBrowseOnly() && !exception
}

const unataSupported = (location) => {
  const store = window.sharedVue.config.globalProperties.$store
  const {
    serviceType, site, pickupSiblingEnabled, pickupSiblingSite
  } = location
  const transitionCompleted = store.getters['FoodLionTransition/transitionCompleted']
  if (serviceType === 'P') {
    return pickupSiblingEnabled && site?.toUpperCase() === 'UNATA' && !transitionCompleted
  }
  if (serviceType === 'B') {
    return pickupSiblingEnabled && pickupSiblingSite?.toUpperCase() === 'UNATA' && !transitionCompleted
  }
  return false
}

const unataLink = ({ serviceType, zipOrStoreNumber, redirectUrl }) => {
  if (redirectUrl) {
    return redirectUrl
  }
  const store = window.sharedVue.config.globalProperties.$store
  const getVarByName = store.getters['SiteConfig/varByName']
  const EXTERNAL_UNATA_URL = getVarByName('feature_external_unata_url') || 'https://shop.foodlion.com'
  const zipOrStoreText = serviceType === 'delivery' ? 'delivery_zip' : 'store'
  return `${EXTERNAL_UNATA_URL}/?shopping_context=${serviceType}&${zipOrStoreText}=${zipOrStoreNumber}`
}

const openUnataFromAppToBrowser = async (params) => {
  const store = window.sharedVue.config.globalProperties.$store
  const item = {
    linkType: 'External',
    name: 'Open external UNATA',
    url: unataLink(params)
  }
  try {
    await handleExternalItemClick({
      item,
      store
    })
  } catch (e) {
    store.dispatch('NativeContainer/logNativeContainerError')
    handleError({ body: 'An error occurred while opening external link.' })
  }
}

const openUnataFromWebToBrowser = (params) => {
  window.open(unataLink(params), '_blank', 'noopener,noreferrer')
}

const handleError = ({ body }) => {
  const store = window.sharedVue.config.globalProperties.$store
  store.commit('Alert/setAlert', {
    icon: true,
    type: 'error',
    header: 'Error',
    body,
    primary: {
      text: 'Ok',
    }
  })
}

export {
  currentStoreIsOnSpectrum,
  isFDLN,
  nonSpectrumBrowseOnly,
  nonSpectrumHideRightTile,
  openUnataFromAppToBrowser,
  openUnataFromWebToBrowser,
  selectedLocationIsOnSpectrum,
  unataSupported,
  unataLink,
}
