<template>
  <div class="field">
    <fieldset class="field_row">
      <legend v-if="legend">
        {{ legend }}
      </legend>
      <PdlToggleBox
        v-for="(option, index) in options"
        v-bind="option"
        :ref="`input${index}`"
        :key="index"
        :value="tempValue"
        :group-key="index"
        :v="v"
        :form-id="String(formId)"
        :name="String(name)"
        :type="type"
        :menu-style="menuStyle"
        :input-classes="inputClasses"
        @input="onGroupChange"
      />
    </fieldset>
    <InputMessages
      class="field_row"
      :errors="errors"
      :description="description"
      :has-errors="hasErrors"
      :has-persistent-messages="hasPersistentMessages"
      :persistent-messages="persistentMessagesList"
    />
  </div>
</template>
<script>
import PdlToggleBox from './PdlToggleBox'
import InputBase from './PdlInputBaseMixin'

export default {
  components: {
    PdlToggleBox
  },
  mixins: [InputBase],
  props: {
    options: {
      type: Array,
      default() {
        return []
      }
    },
    legend: {
      type: String,
      default: ''
    },
    menuStyle: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onGroupChange($event) {
      this.tempValue = $event
    }
  }
}
</script>
