<template>
  <PdlMenu
    class="absolute bg-white border border-gray-50 rounded-t-none rounded-b-md shadow-lg top-[4.4rem] z-[9999]">
    <template #nav>
      <nav>
        <ul>
          <PdlMenuNavItem
            v-for="(item, index) in menuItems"
            :key="index"
            :label="item.label"
            :url="item.url"
            :link-type="item.linkType"
            :is-new-nav-design-enabled="isNewNavDesignEnabled"
            :list-item-class="[
              'nav-item','nav-item--dropdown-item','font-normal !hover:text-gray-900 hover:bg-gray-50'
            ]"
            @nav-item-clicked="onNavItemClick"
            @focus-out="index === menuItems.length - 1 ? $emit('last-item-focus-out') : null"
          />
        </ul>
      </nav>
    </template>
  </PdlMenu>
</template>

<script>
import PdlMenu from './PdlMenu'
import PdlMenuNavItem from './nav-items/PdlMenuNavItem'

export default {
  components: {
    PdlMenu,
    PdlMenuNavItem
  },
  props: {
    menuItems: {
      type: Array,
      required: true
    },
    isNewNavDesignEnabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onNavItemClick(label, url, linkType, elementId) {
      this.$emit('nav-item-clicked', label, url, linkType, elementId)
    }
  }
}
</script>
