<template>
  <div
    class="accordion"
    :class="[{'accordion--full-width': isFullWidth,
              'accordion--large': isLargeStyle && !isMedStyle,
              'accordion--med': isMedStyle && !isLargeStyle,
              'accordion--dark-text': hasDarkText}]"
  >
    <button
      class="accordion_button button--reset"
      :class="[
        { 'accordion_button--not-collapsible' : !isCollapsible },
        {'accordion_button--bottom-border' : hasBottomBorder || (hasBottomBorderWhenOpen && expanded)}
      ]"
      :disabled="!isCollapsible"
      @click="toggleView"
    >
      <div
        v-if="!onlineActive"
        class="accordion_label"
        :class="[
          { 'accordion_label--with-second' : secondLabel },
          { 'vector-icon_inline_termsnconditions vector-icon-size--small': displayInline}
        ]"
      >
        <slot
          class="accordion_label-icon"
          name="icon"
        />
        <span>{{ mainLabel }}</span>
        <div
          v-if="showSubLabel"
          class="accordion_label-sub"
        >
          <span class="font-light text-sm tracking-wide">
            {{ subLabel }}
          </span>
          <PdlCommonTooltip
            v-if="tooltipMessage"
            icon-size="medium"
            :hover="true"
            :message="tooltipMessage"
            :brand-icon-fill="true"
          />
        </div>
      </div>
      <div
        v-if="secondLabel"
        class="accordion_label-second"
      >
        {{ secondLabel }}
        <div
          v-if="secondSubLabel"
          class="accordion_label-second-sub font-size-14-normal"
        >
          {{ secondSubLabel }}
        </div>
      </div>
      <PdlIconBase
        v-if="isCollapsible"
        icon-class="vector-icon-stroke--dark-grey pb-1.5"
        icon-title="Expand Arrow"
        container-class="vector-icon-size--medium vector-icon_inline"
      >
        <PdlIconCarrotUp v-if="expanded" />
        <PdlIconCarrotDown v-else />
      </PdlIconBase>
    </button>
    <!-- Parent section for Non-Collapsed Accordions -->
    <div
      class="accordion_collapsible"
      :class="[
        { 'open' : expanded },
        { 'accordion_collapsible-full' : hasProductSet && isDesktop},
        { 'overflow-visible' : allowOverflow }
      ]"
      :style="{'height': `${computedStyle}`}"
    >
      <div
        ref="accordionContent"
        :class="[{ 'accordion_content' : hasDefaultStyles }]"
      >
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  PdlIconBase,
} from '@/shared/icons'
import PdlIconCarrotDown from 'components/icons/icon/PdlIconCarrotDown'
import PdlIconCarrotUp from 'components/icons/icon/PdlIconCarrotUp'
import PdlCommonTooltip from '@/shared/interactions/PdlCommonTooltip'

export default {
  components: {
    PdlIconBase,
    PdlIconCarrotDown,
    PdlIconCarrotUp,
    PdlCommonTooltip
  },
  props: {
    label: {
      type: String,
      required: true
    },
    allowOverflow: {
      type: Boolean,
      default: false
    },
    defaultOpen: {
      type: Boolean,
      default: false
    },
    subLabel: {
      type: String,
      default: ''
    },
    secondLabel: {
      type: String,
      default: ''
    },
    secondSubLabel: {
      type: String,
      default: ''
    },
    onlineActive: {
      type: Boolean,
      default: false
    },
    alias: {
      type: String,
      default: ''
    },
    isCollapsible: {
      type: Boolean,
      default: true
    },
    isFullWidth: {
      type: Boolean,
      default: false
    },
    isLargeStyle: {
      type: Boolean,
      default: false
    },
    isMedStyle: {
      type: Boolean,
      default: false
    },
    hasDarkText: {
      type: Boolean,
      default: false
    },
    toggleStates: {
      type: Array,
      default: () => []
    },
    hasDefaultStyles: {
      type: Boolean,
      default: true
    },
    hasBottomBorder: {
      type: Boolean,
      default: false
    },
    hasBottomBorderWhenOpen: {
      type: Boolean,
      default: false
    },
    forcedHeight: {
      type: Number,
      default: 0
    },
    tooltipMessage: {
      type: String,
      default: ''
    },
    hideSubLabelOnCollapse: {
      type: Boolean,
      default: false
    },
    displayInline: {
      type: Boolean,
      default: true
    },
    isDefaultOpen: {
      type: Boolean,
      default: false
    },
    hasProductSet: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isExpanded: this.isDefaultOpen ? true : !this.isCollapsible,
      height: 0,
      contentHeight: 0
    }
  },
  computed: {
    ...mapGetters({
      isMobile: 'ScreenSize/isMobile',
      isDesktop: 'ScreenSize/isDesktop',
    }),
    expanded() {
      if (this.isDefaultOpen) {
        return this.isExpanded
      }
      if (!_.isEmpty(this.toggleStates)) {
        const targetAccordionState = this.toggleStates.find(item => item.name === this.alias)
        if (targetAccordionState) {
          return targetAccordionState.state
        }
        return this.isExpanded
      }
      return this.isExpanded
    },
    computedStyle() {
      if (this.isCollapsible && !this.expanded) {
        return `${this.height}px`
      }
      return 'auto'
    },
    showSubLabel() {
      if (this.hideSubLabelOnCollapse) {
        return this.subLabel && this.isExpanded
      }
      return this.subLabel
    },
    mainLabel() {
      return this.alias || this.label
    }
  },
  watch: {
    expanded() {
      if (this.forcedHeight > 0 && this.alias.includes('Shipping')) {
        this.height = this.forcedHeight
        return
      }
      this.height = this.expanded
        ? this.contentHeight
        : 0
    }
  },
  mounted() {
    // Must be on mounted, we need elements to be rendered to check height
    window.addEventListener('load', this.checkHeight)
    if (this.defaultOpen) {
      this.openView()
    }
  },
  beforeUnmount() {
    window.removeEventListener('load', this.checkHeight)
  },
  methods: {
    checkHeight() {
      this.contentHeight = this.$refs?.accordionContent?.offsetHeight || 0
    },
    openView() {
      this.checkHeight()
      this.isExpanded = true
    },
    toggleView() {
      if (this.isCollapsible) {
        this.checkHeight()
        this.isExpanded = !this.isExpanded
        this.$emit('on-toggle', {
          name: this.alias,
          state: !this.expanded
        })
      }
    },
  }
}
</script>
