import ApiService from '@/api/'

export default {
  getOrderHistory(userId) {
    return ApiService.get(`/api/v6.0/user/${userId}/order/history`)
  },

  getOrderDetails(userId, basketId, serviceType) {
    let queryParam = ''

    if (serviceType === 'SHIP2ME') {
      queryParam = '?isMarketPlace=true'
    }

    if (serviceType === 'INSTORE') {
      queryParam = '?isInStore=true'
    }

    return ApiService.get(`/api/v6.0/user/${userId}/order/history/detail/${basketId}${queryParam}`)
  },

  getInstoreOrderHistory({ userId, loyaltyId }) {
    return ApiService.get(`/api/v6.0/user/${userId}/order/history?filter=instore&loyaltyNumber=${loyaltyId}`)
  }
}
