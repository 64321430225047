<template>
  <li
    :class="textClass"
  >
    <slot />
    <PdlIconBase
      :icon-title="isValid ? 'pass' : 'fail'"
      :icon-class="iconClass"
      container-class="vector-icon-size--xxsmall vector-icon_inline-text"
    >
      <PdlIconCheck v-if="showSuccess" />
      <PdlIconClose v-if="showError" />
    </PdlIconBase>
  </li>
</template>

<script>
import {
  PdlIconBase,
} from '@/shared/icons'
import PdlIconCheck from 'components/icons/icon/PdlIconCheck'
import PdlIconClose from 'components/icons/icon/PdlIconClose'

export default {
  components: {
    PdlIconBase,
    PdlIconClose,
    PdlIconCheck
  },
  props: {
    isValid: {
      type: Boolean,
      required: true
    },
    isDirty: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    showError() {
      return !this.isValid && this.isDirty
    },
    showSuccess() {
      return this.isValid && this.isDirty
    },
    classColor() {
      if (this.showSuccess) {
        return 'success'
      }
      if (this.showError) {
        return 'error'
      }
      return 'darker-grey'
    },
    iconClass() {
      return `vector-icon-color--${this.classColor}`
    },
    textClass() {
      return `text--base-${this.classColor}`
    }
  }
}
</script>
