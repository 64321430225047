import ClientLoggingAPI from '@/api/ClientLoggingAPI'

const DELIMITER = '|' // Delimiter for fields in payload body
const DEFAULT_TYPE = 'E' // Type of payload to track (event)

const BREAKPOINTS = {
  mobile: [240, 680],
  tablet: [681, 1199],
  desktop: [1200, Number.MAX_VALUE]
}

let deviceDataCache = ''

const getScreenType = width => Object.keys(BREAKPOINTS)
  .filter(k => width >= BREAKPOINTS[k][0] && width <= BREAKPOINTS[k][1])[0] || 'none'

const formatBody = body => Object.keys(body)
  .reduce((content, message) => {
    const hasMessage = !_.isUndefined(body[message]) && body[message] !== null
    return content + (hasMessage ? `<${message}:"${body[message]}">` : '')
  }, '')

const getDimensions = (mobileapp) => {
  if (window.innerHeight && window.innerWidth) {
    return {
      resolution: `${window.innerWidth}x${window.innerHeight}`,
      screenSize: getScreenType(window.innerWidth),
      mobileapp,
    }
  }
  return {}
}

const getDeviceData = async (ctx) => {
  const { NativeContainer } = ctx.config.globalProperties
  let enabled = false
  if (NativeContainer) {
    enabled = await NativeContainer.isEnabledPromise()

    if (!deviceDataCache && enabled) {
      deviceDataCache = await NativeContainer.capabilities.DeviceInfo.getDeviceData()
    }
  }
  return {
    enabled,
    userAgent: window.navigator.userAgent,
    deviceData: deviceDataCache
  }
}

const makeMessage = components => components.join(DELIMITER)

const getClientConfigValues = (ctx) => {
  const siteConfigData = {}
  const { $store: vuexStore } = ctx.config.globalProperties
  siteConfigData.flushInterval = vuexStore.getters['SiteConfig/varByName']('config_log_flush_interval')
  siteConfigData.maxQueueSize = vuexStore.getters['SiteConfig/varByName']('config_log_max_queue_size')
  siteConfigData.maxMessageCharCount = vuexStore.getters['SiteConfig/varByName']('config_log_max_message_char_count')
  siteConfigData.logDomain = vuexStore.getters['SiteConfig/varByName']('config_log_domain')
  siteConfigData.logKey = vuexStore.getters['SiteConfig/varByName']('config_log_key')
  return siteConfigData
}
const createLogger = ctx => async (name, payload, logType) => {
  const device = await getDeviceData(ctx)
  const dimensions = getDimensions(device.enabled)
  const type = logType || DEFAULT_TYPE
  const { $store: vuexStore } = ctx.config.globalProperties
  const userType = vuexStore.getters['UserProfile/userInfo']?.information?.userType
  const { timeOrdQy } = window.appConfig
  const body = formatBody({
    ...payload,
    ...dimensions,
    userType,
    timeOrdQy
  })
  const siteData = getClientConfigValues(ctx)
  let messageArgs = [device.userAgent, device.deviceData, type, name, body]
  const opco = vuexStore.getters['SiteConfig/opco']
  const userGuid = vuexStore.getters['UserProfile/userInfo']?.information?.userGuid
  const userId = vuexStore.getters['UserProfile/userId']
  const { storeId } = vuexStore.getters['UserProfile/deliveryServiceLocation']
  messageArgs = [userGuid, userId, storeId, opco, ...messageArgs]
  ClientLoggingAPI.configValues({ ...siteData })
  ClientLoggingAPI.queue(makeMessage(messageArgs))
}

export default {
  createLogger
}
