<template>
  <div :class="[{'z-0' : noIndex},]">
    <label
      v-if="fieldLabel"
      :for="id"
      class="forms_label"
    >{{ fieldLabel }}</label>
    <div
      :class="[
        {'select': !newSortRedesign},
        {'relative cursor-pointer z-[2]': newSortRedesign},
        {'select--full-width' : fullWidth },
        {'select--no-margin' : noMargin },
        hasCustomContainerClass ? customContainerClass : ''
      ]"
    >
      <!-- eslint-disable vuejs-accessibility/no-onchange -->
      <select
        :id="id"
        :value="modelValue"
        :required="isRequired"
        :aria-describedby="errorMessageId"
        :class="[
          {'h-20': weeklyAdSelect},
          {'select_select': !newSortRedesign},
          {'focus:content-[initial]': newSortRedesign},
          {'relative font-bold pb-[0.15rem] w-[10.3rem] pr-[1.5rem] pl-[0.2rem] leading-[2.1rem] ': newSortRedesign},
          {'appearance-none cursor-pointer truncate  bg-white text-black': newSortRedesign},
          {'z-0' : noIndex},
          {'select_select--light' : lightBorder },
          {'text-primary' : textPrime }
        ]"
        @change.stop="onChange"
        @click.prevent.stop="onClick"
      >
        <option
          v-if="label"
          class="select_option"
          value
          disabled
        >
          {{ label }}
        </option>
        <option
          v-for="(option, i) in options"
          :key="i"
          class="select_option"
          :value="option.value"
        >
          {{ optionTitle(option) }}
        </option>
      </select>
      <PdlIconBase
        v-if="!newSortRedesign"
        role="presentation"
        icon-title="Show select"
        icon-class="vector-icon-stroke--prime"
        :container-class="[
          'select_caret',
           mediumCaret ? 'vector-icon-size--medium' : 'vector-icon-size--mediumlarge'
         ]"
      >
        <PdlIconCarrotDown />
      </PdlIconBase>
      <PdlIconBase
        v-else
        role="presentation"
        icon-title="Show select"
        :container-class="[
        'w-6 h-6 absolute right-2 top-[0.2rem]',
        'flex flex-row flex-nowrap z-[1] pointer-events-none'
         ]"
      >
        <PdlIconCarrotDownSort :opco="opco" />
      </PdlIconBase>
    </div>
    <p
      v-if="error"
      :id="errorMessageId"
      aria-live="polite"
      :class="[
        'text--base-strong',
        'text--base-error',
        { 'trailer--double': !noErrorPadding }
      ]"
    >
      <slot name="errors" />
    </p>
  </div>
</template>

<script>
import PdlIconCarrotDown from 'components/icons/icon/PdlIconCarrotDown'
import PdlIconCarrotDownSort from 'components/icons/icon/PdlIconCarrotDownSort'
import { PdlIconBase } from '@/shared/icons'

export default {
  components: {
    PdlIconBase,
    PdlIconCarrotDown,
    PdlIconCarrotDownSort
  },
  props: {
    id: {
      type: String,
      required: true
    },
    noMargin: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    fieldLabel: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default() {
        return []
      }
    },
    modelValue: {
      type: [Number, String],
      default: ''
    },
    lightBorder: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    mediumCaret: {
      type: Boolean,
      default: false
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    noErrorPadding: {
      type: Boolean,
      default: false
    },
    noIndex: {
      type: Boolean,
      default: false
    },
    customContainerClass: {
      type: [Boolean, String],
      default: false
    },
    weeklyAdSelect: {
      type: Boolean,
      default: false
    },
    newSortRedesign: {
      type: Boolean,
      default: false
    },
    textPrime: {
      type: Boolean,
      default: false
    },
    opco: {
      type: String,
      default: ''
    }
  },
  computed: {
    hasCustomContainerClass() {
      return !!this.customContainerClass
    },
    errorMessageId() {
      return this.error ? `${this.id}-errors` : undefined
    }
  },
  methods: {
    optionTitle(option) {
      return option.title ? option.title : option.name
    },
    onChange(event) {
      this.$emit('update:modelValue', event.target.value)
    },
    onClick() {
      this.$emit('click', this.modelValue)
    },
  },
  emits: ['update:modelValue', 'click']
}
</script>
