<template>
  <div>
    <div
      v-if="isDesktop && !showFixedDropdownMenuTip"
      :class="[
        'menutip menutip--left border-solid border-l border-t border-r-0 border-b-0',
        {
          'border-[#d9d9d6]': !isAddItemModal && !isNewNavDesignEnabled,
          'border-gray-100': isAddItemModal,
          'border-[#d8d9d6]' : isNewNavDesignEnabled
        }
      ]"
    />
    <div
      :class="[
        'search-term-list p-4',
        {
          'max-h-152': isAddItemModal && !isMobile,
          'pt-8': !useCitrusBannerXStyles,
          'max-h-[calc(100vh-400px)] pt-0 mt-8': useCitrusBannerXStyles
        }
      ]"
    >
      <PdlSearchTermList
        v-if="hasUsedTerms"
        list-name="Your Searches"
        :is-removable="true"
        :search-terms="recentSearchTerms"
        :current-position="currentPosition"
        :current-list="currentList"
        @value-selected="selectVal"
        @remove-term="removeSearchTerm"
        @on-active="onActive"
      />
      <div
        v-if="hasUsedTerms && hasPopularSearch"
        class="w-full border-t border-gray-100 pb-4"
      />
      <PdlSearchTermList
        v-if="hasPopularSearch"
        list-name="Popular Searches"
        :search-terms="popularSearchTerms"
        :current-position="currentPosition"
        :current-list="currentList"
        @value-selected="selectVal"
        @on-active="onActive"
      />
    </div>
  </div>
</template>
<script>
import {
  mapGetters, mapActions, mapMutations
} from 'vuex'
import TopKeywordsApi from 'api/TopKeywordsApi'

export default {
  props: {
    currentPosition: {
      type: Number,
      default: -1
    },
    currentList: {
      type: String,
      default: ''
    },
    isAddItemModal: {
      type: Boolean,
      default: false
    },
    isNewNavDesignEnabled: {
      type: Boolean,
      default: false
    },
    useCitrusBannerXStyles: {
      type: Boolean,
      default: false
    },
    showFixedDropdownMenuTip: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      isMobile: 'ScreenSize/isMobile',
      isDesktop: 'ScreenSize/isDesktop',
      popularSearchTerms: 'SearchTerms/topSearchTerms',
      recentSearchTerms: 'SearchTerms/usedSearchTerms',
      userId: 'UserProfile/userId',
      deliveryServiceLocation: 'UserProfile/deliveryServiceLocation',
      varByName: 'SiteConfig/varByName'
    }),
    hasPopularSearch() {
      return this.popularSearchTerms.length > 0
    },
    hasUsedTerms() {
      return this.recentSearchTerms.length > 0
    }
  },
  created() {
    const MAX_TOP_KEYWORDS = 8
    this.fetchTopKeywords(MAX_TOP_KEYWORDS)
    this.fetchUsedTerms()
  },
  methods: {
    ...mapActions({
      fetchUsedTerms: 'SearchTerms/fetchUsedTerms',
    }),
    ...mapMutations({
      removeUsedSearchTerms: 'SearchTerms/removeUsedSearchTerms',
      setInitialized: 'SearchTerms/setInitialized',
      setTopSearchTerms: 'SearchTerms/setTopSearchTerms'
    }),
    selectVal(currentResult) {
      this.$emit('value-selected', currentResult)
    },
    removeSearchTerm(currentResult) {
      this.removeUsedSearchTerms(currentResult.position)
      this.$emit('on-remove', currentResult)
    },
    onActive(payload) {
      this.$emit('on-active', payload)
    },
    async fetchTopKeywords(size) {
      const { serviceLocationId } = this.deliveryServiceLocation
      const isNewTopKeywordServices = this.varByName('feature_category_product_new_services')
      const response = await TopKeywordsApi(this.userId, serviceLocationId, isNewTopKeywordServices, size)
      try {
        if (response.status === 200 && response.data.response.length > 0) {
          this.setTopSearchTerms(response.data.response.slice(0, size))
        }
        this.setInitialized()
      } catch (err) {
        this.setTopSearchTerms([])
        this.setInitialized()
      }
    }
  }
}
</script>
