import DeferredSingleActionService from 'utils/interceptors/services/deferred-single-action'
import { redirectToLoginWithErrorCode, isUserOnGateway } from 'components/auth/services/auth-helper-service'

export function authHttpInterceptor(rejection) {
  if (window.initPhase.flag || !rejection?.response?.status) {
    return Promise.reject(rejection)
  }
  // Session is expired - queue up relogin api call with relogin service.
  if (rejection.response.status === 409
    && rejection.response.data.response.code === 'SESSION_LOCKED'
  ) {
    DeferredSingleActionService.setSessLockout()
  }

  if (rejection.response.status === 409
    && rejection.response.data.response.code === 'SESSION_ABORTED'
  ) {
    DeferredSingleActionService.setSessionAbortAlert()
    // for session abort, we prevent the call resuming and user refreshes page
    // if they click continue shopping
    return () => new Promise()
  }

  if (rejection.response.status === 401
    && rejection.response.data.response?.code === 'LOGIN_REQUIRED'
    && !isUserOnGateway()) {
    redirectToLoginWithErrorCode(rejection.response.data.response.code)
  }

  return Promise.reject(rejection)
}
