export default {
  methods: {
    isNewProductSet(newVal, oldVal) {
      if (!newVal.length) {
        return false
      }
      if (newVal.length !== oldVal.length) {
        return true
      }
      return !!newVal.filter(({ prodId }, i) => prodId !== oldVal[i].prodId).length
    }
  }
}
