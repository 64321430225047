import ApiService from '@/api/'
import parseDateTime from 'utils/filters/parseDateTime'
import isNowAfterDateTime from 'utils/filters/isNowAfterDateTime'
import { LOGGING_TYPE } from 'utils/constants'

import RecentOrdersAPI from 'api/RecentOrdersAPI'

const isAllowed = (order, state) => (
  (order.serviceType === 'D' && state.allowedDelivery) || (order.serviceType === 'P' && state.allowedPickup)
) && order.orderReviewAllowed

const notSkipped = orderReview => (!!orderReview && !orderReview.skipped && !orderReview.starRating) || !orderReview

const isAfterPickupTime = (timeEnd, serviceType) => {
  if (serviceType === 'P') {
    return isNowAfterDateTime(parseDateTime(timeEnd))
  }
  return true
}

export default {
  namespaced: true,
  state: {
    orders: null,
    reviewOrder: null,
    loading: false,
    allowedDelivery: true,
    allowedPickup: false,
    pendingOrderId: null,
    fromRouteNavigation: false,
    logData: []
  },
  getters: {
    orders: state => state.orders,
    reviewOrder: state => state.reviewOrder,
    pendingOrderId: state => state.pendingOrderId,
    logData: state => state.logData
  },
  mutations: {
    setOrders(state, payload) {
      state.orders = payload || null
    },
    setReviewOrder(state, payload) {
      state.reviewOrder = payload || null
    },
    setLoading(state, payload) {
      state.loading = payload || false
    },
    setPendingOrderId(state, payload) {
      state.pendingOrderId = payload || null
    },
    setFromRouteNavigation(state, payload) {
      state.fromRouteNavigation = payload
    },
    insertLogData(state, payload) {
      state.logData.push(payload)
    }
  },
  actions: {
    async logCannotRateReason({ state }) {
      window.sharedVue.config.globalProperties.$trackClientLog(
        'order-rating-synopsis',
        { data: ['CannotRateOrder', ...state.logData].join(',') },
        LOGGING_TYPE.event
      )
    },
    async getRecentOrders({ commit, state }, userId) {
      if (state.orders && !state.loading) return state.orders

      commit('setLoading', true)
      try {
        const recentOrders = await RecentOrdersAPI.userOrdersQuery(userId)
        const { response } = recentOrders?.data || {}

        if (!response) {
          throw new Error(`no response data from: RecentOrdersAPI.userOrdersQuery()`)
        }

        const { completedOrders } = response

        if (!Array.isArray(completedOrders)) {
          throw new Error(`no completedOrders array in response from RecentOrdersAPI.userOrdersQuery()`)
        }

        commit('setOrders', completedOrders)
        return completedOrders
      } catch (e) {
        commit('insertLogData', e)
        commit('setOrders', null)
        return null
      } finally {
        commit('setLoading', false)
      }
    },
    async getReviewOrder({
      commit, dispatch, state, rootGetters
    }, userId) {
      commit('insertLogData', `Info|from route navigation: ${state.fromRouteNavigation}`)
      if (!state.reviewOrder) {
        await dispatch('getRecentOrders', userId)

        if (!Array.isArray(state.orders) || state.orders.length < 1) {
          commit('insertLogData', `Reason|'no completed orders returned from action: getRecentOrders'`)
          dispatch('logCannotRateReason')
          return null
        }
        commit(
          'insertLogData',
          `Info|${state.orders.length} order(s) found, ${state.orders.slice().map(order => order.orderId).join()}`
        )

        const reviewOrder = await dispatch('findReviewableOrder', _.sortBy(state.orders, 'timeEnd').reverse())

        // If the user has pending delivery orders,
        // and the pending delivery order is the same day as the review order,
        // check if it has been delivered and not skipped, and if not don't show the order review
        const pendingOrderList = rootGetters['Order/orderStatus']?.pendingOrder

        let pendingOrder

        if (Array.isArray(pendingOrderList) && pendingOrderList.length) {
          pendingOrder = rootGetters['Order/orderStatus']?.pendingOrder[0]
        }

        const { serviceType } = pendingOrder || {}
        commit('insertLogData', `Info|serviceType from pendingOrder: ${serviceType}`)

        if (!reviewOrder) {
          commit('insertLogData', 'Reason|no review order to rate')
          dispatch('logCannotRateReason')
          return null
        }

        if (serviceType === 'D') {
          dispatch('setDeliveryReviewOrder', {
            pendingOrder,
            reviewOrder
          })
        } else {
          commit('setReviewOrder', reviewOrder)
          dispatch('expandReviewOrderData')
        }
      }
      return state.reviewOrder
    },
    findReviewableOrder({ state, commit }, orderList) {
      return orderList.find((order) => {
        const { orderReview, timeEnd, serviceType } = order

        const reviewNotSkipped = notSkipped(orderReview)
        const reviewIsAllowed = isAllowed(order, state)
        const orderIsAfterPickupTime = isAfterPickupTime(timeEnd, serviceType)

        // ensure reason(s) for failure is included in log for tracking
        if (!reviewNotSkipped) {
          commit('insertLogData', `Reason|order review skipped by user, order: ${order.orderId}`)
        }
        if (!reviewIsAllowed) {
          commit('insertLogData', `Reason|review disallowed on order: ${order.orderId}`)
        }
        if (!orderIsAfterPickupTime) {
          commit('insertLogData', `Reason|order not after pickup time: ${order.orderId}`)
        }

        return reviewNotSkipped && reviewIsAllowed && orderIsAfterPickupTime
      })
    },
    checkOrderDelivered({ commit }, order) {
      const { orderProgress } = order || {}
      const routeProgress = orderProgress?.route?.progress

      if (!orderProgress) {
        commit('insertLogData', `Reason|no order progress data present`)
        return false
      }

      if (!routeProgress) {
        commit('insertLogData', `Reason|no route progress data present`)
        return false
      }

      let { actualDepartureTime } = routeProgress

      if (!actualDepartureTime) {
        commit('insertLogData', `Reason|no actualDepartureTime data present`)
        return false
      }

      // passed actually time means delivered. It can be null sometimes.
      actualDepartureTime = new Date(actualDepartureTime).getTime()
      const currentTime = _.now()
      if (currentTime > actualDepartureTime) {
        return true
      }
      commit('insertLogData', `Reason|current time ahead of departure time`)
      return false
    },
    async setDeliveryReviewOrder({
      state, commit, dispatch
    }, { pendingOrder, reviewOrder }) {
      if (!pendingOrder) {
        commit('insertLogData', `Reason|no pendingOrder data`)
        dispatch('logCannotRateReason')
        return
      }
      // const { orderStatusEnabled } = rootGetters['UserProfile/refData'].deliveryServiceLocation
      const pendingOrderDate = new Date(pendingOrder.timeEnd).getDate()
      const reviewOrderDate = new Date(reviewOrder.timeEnd).getDate()

      if (pendingOrderDate === reviewOrderDate) {
        const pendingOrderDelivered = await dispatch('checkOrderDelivered', pendingOrder)
        if (pendingOrderDelivered || state.fromRouteNavigation) {
          commit('setReviewOrder', reviewOrder)
          dispatch('expandReviewOrderData')
        } else {
          commit('insertLogData', `Reason|cannot tell if order was delivered`)
          dispatch('logCannotRateReason')
          commit('setReviewOrder', null)
        }
      } else {
        commit('setReviewOrder', reviewOrder)
        dispatch('expandReviewOrderData')
      }
    },
    async expandReviewOrderData({ state, commit, rootGetters }) {
      try {
        const { reviewOrder } = state
        const { userId } = rootGetters['UserProfile/userInfo'].information
        const path = `/apis/fulfillment/${userId}/driver/v1/tips/${reviewOrder.orderId}`
        const reviewOrderDetails = await ApiService.get(path)
        const { data } = reviewOrderDetails

        return commit('setReviewOrder', { ...state.reviewOrder, ...{ tippedAtCheckout: data.tipsExist } })
      } catch (e) {
        return e
      }
    }
  }
}
