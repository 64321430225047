<template>
  <button
    :id="id"
    :class="classList"
    :disabled="disabled"
    :type="type"
    @click.prevent="onClick($event)"
  >
    <slot />
  </button>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  emits: ['click'],
  props: {
    id: {
      type: String,
      default: null
    },
    styling: {
      type: String,
      default: 'prime'
    },
    withHover: {
      type: Boolean,
      default: false,
    },
    withAnimation: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'button'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      opco: 'SiteConfig/opco'
    }),
    classList() {
      let classes = `button button--${this.styling} flex justify-center items-center
        h-16 pl-12 pr-2.5 group`
      if (this.withHover) {
        classes += ' hover:bg-cta-hover'
      }
      if (this.withAnimation) {
        // animation on group hover to extend the width
        classes += ' hover:transition-all'
      }
      if (this.opco === 'STSH') {
        classes += ' rounded-full'
      }
      return classes
    }
  },
  methods: {
    onClick($event) {
      if (this.type !== 'External') {
        this.$emit('click', $event)
      }
    }
  }
}
</script>
