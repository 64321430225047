/**
 * /** function getFirstAddressWithName - determine if current time is past the param "time"
 * @param {Object} addresses a collection of addresses from User Profile
 */

export default function (addresses) {
  let response = null
  if (!!addresses
    && typeof addresses === 'object'
    && Object.keys(addresses).length > 0) {
    const addressItem = _.find(addresses, (address) => {
      return (!!(address?.name?.firstName) || !!(address?.name?.lastName))
    })
    if (addressItem) {
      response = addressItem
    }
  }

  return response
}
