<template>
  <div>
    <h3
      v-if="isCollapsible"
    >
      <button
        :id="parentId"
        class="flex justify-between w-full"
        :class="(isCollapsible ? 'pt-4' : 'pt-8')"
        :aria-expanded="expanded"
        :aria-controls="`${parentId}-list`"
        @click.prevent="toggleView"
        @keydown.up.down.home.end.prevent="handleKeyDown($event)"
      >
        <span
          class="block pb-4 font-bold"
          :class="{ 'pt-2': isCollapsible }"
        >
          {{ parentTitle }}
        </span>
        <PdlIconBase
          icon-title="Expand Arrow"
          stroke-color="#000"
          container-class="h-12 w-12"
        >
          <PdlIconCarrotUp v-if="expanded" />
          <PdlIconCarrotDown v-else />
        </PdlIconBase>
      </button>
    </h3>
    <!-- Parent section for Non-Collapsed Accordions -->
    <div
      v-else
    >
      <h3
        class="text-gray-900 font-bold block pb-4 pt-8"
      >
        {{ parentTitle }}
      </h3>
    </div>
    <div
      :id="`${parentId}-list`"
      :aria-labelledby="isCollapsible ? parentId : null"
      :role="isCollapsible ? 'region' : null"
    >
      <ul
        class="overflow-hidden ease-transition-height"
        :class="classCombo"
      >
        <li
          v-for="(link,key) in childLinks"
          :key="key"
        >
          <PdlAnchor
            v-if="link.type === 'External'"
            class="block pb-4"
            anchor-class="text-gray-600 no-underline"
            :external="true"
            :link-title="link.name"
            :location="link.url"
            :tabindex="isCollapsible && !expanded ? -1 : null"
          />
          <PdlAnchor
            v-else
            class="block pb-4"
            anchor-class="text-gray-600"
            :external="false"
            :link-title="link.name"
            :navigation-info="{
              route: link.url,
              isNamedModal: link.type === 'Modal',
              isVideo: link.type === 'Video',
              isServiceModal: link.type === 'ServiceModal'
            }"
            :tabindex="isCollapsible && !expanded ? -1 : null"
            @link-clicked="triggerInternalAction"
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {
  PdlIconBase,
} from '@/shared/icons'
import PdlIconCarrotDown from 'components/icons/icon/PdlIconCarrotDown'
import PdlIconCarrotUp from 'components/icons/icon/PdlIconCarrotUp'
import PdlAnchor from '@/shared/base/PdlAnchor'

export default {
  components: {
    PdlIconBase,
    PdlIconCarrotUp,
    PdlIconCarrotDown,
    PdlAnchor
  },
  props: {
    parentTitle: {
      type: String,
      required: true
    },
    childLinks: {
      type: Array,
      required: true
    },
    isCollapsible: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    expanded: false
  }),
  computed: {
    classCombo() {
      if (!this.isCollapsible) {
        return 'max-h-fit'
      }
      return `${this.expanded ? 'max-h-fit' : 'max-h-0'}`
    },
    parentId() {
      return this.parentTitle.replace(/\s+/g, '-').toLowerCase()
    }
  },
  methods: {
    triggerInternalAction(actionName, title) {
      this.$emit('internal-action', actionName, title)
    },
    toggleView() {
      this.expanded = !this.expanded
    },
    handleKeyDown($event) {
      this.$emit('handle-key-down', $event)
    }
  }
}
</script>
