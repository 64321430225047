<!-- eslint-disable max-len !-->
<template>
  <path d="m1 1 5 5-5 5" :class="strokeClass" stroke-width="2" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round" />
</template>
<script>
export default {
  props: {
    strokeClass: {
      type: [String, Object],
      default: '',
    },
  }
}
</script>
