<template>
  <div
    class="alert"
  >
    <transition name="slideFromTop">
      <div
        v-if="isAlertPresent"
        class="flex flex-col md:flex-row w-full fixed top-0 z-[9999] bg-white"
        role="alertdialog"
        aria-labelledby="aria_alert-header"
        aria-describedby="aria_alert-body"
        aria-hidden="false"
        aria-live="assertive"
      >
        <h2
          id="aria_alert-header"
          ref="alertHeader"
          class="flex items-center border-solid border-0 border-gray-100 border-r"
          :class="[
              {'p-8 whitespace-nowrap': !isMobile},
              {'p-4 pb-0': isMobile}
            ]"
          tabindex="-1"
        >
          <span
            v-if="alert.icon"
            aria-hidden="true"
            class="vector-icon_inline--left"
          >
            <PdlIconBase
              :icon-title="alert.type"
              :icon-class="
                alert.type === 'success' ? `vector-icon-stroke--${svgColor}` : `vector-icon-color--${svgColor}`
              "
              container-class="vector-icon-size--medium"
            >
              <PdlIconSuccess v-if="alert.type === 'success'" />
              <PdlIconWarning v-else />
            </PdlIconBase>
          </span>
          <!-- eslint-disable vue/no-v-html -->
          <span
            v-if="alert.processAsHTML"
            class="font-bold mb-1"
            v-html="cleanHtml(alert.header)"
          />
          <span
            v-if="!alert.processAsHTML"
            class="font-bold mb-1"
          >
            {{ alert.header }}
          </span>
        </h2>
        <div
          id="aria_alert-body"
          class="flex items-center px-4 pb-4 md:p-8"
        >
          <PdlCheckbox
            v-if="alert.header === 'Alcohol Confirmation'"
            id="checkbox-alert"
            name="checkbox-alert"
            :value="false"
            @change="updateCheckbox"
          />
          <span
            v-if="alert.processAsHTML"
            v-html="cleanHtml(alert.body)"
          />
          <span
            v-if="!alert.processAsHTML"
          >
            {{ alert.body }}
          </span>
          <span
            v-if="alert.accessibleBody"
            class="element-invisible"
          >
            {{ alert.accessibleBody }}
          </span>
        </div>
        <div
          class="flex items-center ml-auto w-full md:w-auto mb-4 my-4 md:my-8"
        >
          <PdlLoadingButton
            v-if="alert.secondary.text"
            id="alert-button_secondary-button"
            class="mx-4 md:min-w-[18rem] px-5"
            :class="[
              {'w-1/2 mt-0': isMobile}
            ]"
            :label="alert.secondary.text"
            :button-color="alert.secondary.color || 'fourth'"
            :is-loading="secondaryBtnLoading"
            :is-disabled="isSecondaryBtnDisabled"
            @click="submitSecondary"
          />
          <PdlLoadingButton
            id="alert-button_primary-button"
            class="mx-4 md:min-w-[18rem] px-5"
            :class="[
              {'!w-1/2': alert.secondary.text && isMobile},
              {'w-full mt-0': isMobile}
            ]"
            :label="alert.primary.text"
            :button-color="alert.primary.color"
            :is-loading="primaryBtnLoading"
            :is-disabled="isPrimaryBtnDisabled"
            @click="submitPrimary"
            @keydown-tab="tabEvent"
          />
        </div>
      </div>
    </transition>
    <div
      v-if="isAlertPresent"
      class="mask mask--black mask--alert-mask"
      tabindex="-1"
    />
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import PdlLoadingButton from '@/shared/interactions/PdlLoadingButton'
import {
  PdlIconBase
} from '@/shared/icons'
import PdlIconSuccess from 'components/icons/icon/PdlIconSuccess'
import PdlIconWarning from 'components/icons/icon/PdlIconWarning'
import compileLinkVars from 'utils/mixins/compileLinkVars'

export default {
  components: {
    PdlLoadingButton,
    PdlIconWarning,
    PdlIconSuccess,
    PdlIconBase
  },
  mixins: [
    compileLinkVars
  ],
  computed: {
    ...mapState({
      alert: state => state.Alert.alert,
      primaryBtnLoading: state => state.Alert.primaryBtnLoading,
      isPrimaryBtnDisabled: state => state.Alert.isPrimaryBtnDisabled,
      secondaryBtnLoading: state => state.Alert.secondaryBtnLoading,
      isSecondaryBtnDisabled: state => state.Alert.isSecondaryBtnDisabled,
    }),
    ...mapGetters({
      isMobile: 'ScreenSize/isMobile',
      brandVars: 'SiteConfig/brandVars',
    }),
    isAlertPresent() {
      return this.alert?.active
    },
    svgColor() {
      return this.alert.type !== 'warning' ? this.alert.type : 'prime'
    }
  },
  watch: {
    alert(newVal) {
      if (!newVal.active) {
        return
      }
      this.$nextTick(() => {
        this.$refs.alertHeader.focus()
      })
    }
  },
  methods: {
    ...mapMutations({
      setPrimaryBtnState: 'Alert/setPrimaryBtnState',
      setSecondaryBtnState: 'Alert/setSecondaryBtnState'
    }),
    updateCheckbox(option) {
      if (option.checked) {
        this.alert.primary.color = 'prime'
        this.$store.commit('Alert/setPrimaryBtnState', false)
      } else {
        this.alert.primary.color = 'fourth'
        this.$store.commit('Alert/setPrimaryBtnState', true)
      }
    },
    submitPrimary() {
      this.$store.commit('Alert/setLoadingState', 'primary')
      this.callback('primary')
    },
    submitSecondary() {
      this.$store.commit('Alert/setLoadingState', 'secondary')
      this.callback('secondary')
    },
    callback(type) {
      const shopModeHelpPanel = document.getElementById('shop-mode-help-panel')
      if (shopModeHelpPanel) {
        shopModeHelpPanel.focus()
      }
      if (this.alert[type].callback === undefined) {
        this.$store.commit('Alert/clear')
        return false
      }
      return this.alert[type].callback()
    },
    tabEvent() {
      this.$refs.alertHeader.focus()
    }
  }
}
</script>
