import { GTM } from 'utils/constants/tracking'
import {
  setupDateForLog,
  mapProductListDetails,
  cartItemCount,
} from 'utils/tracking/helpers'

/**
 *
 * @param {number} step
 * @param {Object} options
 */
export function trackCheckoutStep(step, options = {}) {
  const checkoutContext = getCheckoutContext()
  const formattedCartDataForTracking = formatCheckoutData(
    checkoutContext,
    step,
    options
  )
  const vueInstance = window.sharedVue.config.globalProperties
  vueInstance.$trackGtmEvent(GTM.checkoutStep, formattedCartDataForTracking)
}

/**
 * @returns {CheckoutContext}
 */
function getCheckoutContext() {
  const store = window.sharedVue.config.globalProperties.$store
  const { lockersEnabledInStore } = store.state.PickupLockers
  const selectedSlot = store.getters['Slots/selectedSlot']
  const products = store.getters['Cart/getCartItems']
  const productsSortedByCategory = store.getters['Cart/getProductsSortedByCategory']
  return {
    lockersEnabledInStore,
    selectedSlot,
    products,
    productsSortedByCategory,
  }
}

/**
 * @param {CartData} cartData - formatted by formatCheckoutData
 * @param {Object} productsSortedByCategory
 * @returns {CartDataWithCount}
 */
function addCartCount(cartData, productsSortedByCategory) {
  const cartCounter = cartItemCount(productsSortedByCategory)
  const cartCountInfo = {
    cartItemsType: 'Grocery Only',
    groceryitemCount: cartCounter ? cartCounter.groceryitemCount : 0,
    groceryitemTotal: cartCounter ? cartCounter.groceryitemTotal : 0,
    groceryitemValue: cartCounter ? cartCounter.groceryitemValue : 0,
  }
  return {
    ...cartData,
    ...cartCountInfo,
  }
}

/**
 * @param {CheckoutContext}
 * @param {number} step
 * @param {Object} options
 * @returns {CartDataWithCount}
 */
function formatCheckoutData(
  {
    selectedSlot,
    lockersEnabledInStore,
    products,
    productsSortedByCategory,
  },
  step,
  options
) {
  const { date, available, serviceType } = selectedSlot
  const dateData = setupDateForLog({ date })
  const productsWithDetails = mapProductListDetails(products)
  const availability = available
  const serviceModel = lockersEnabledInStore ? 'L' : serviceType
  const deliveryOption = serviceType === 'D' ? 'delivery' : 'pick up'
  const vueInstance = window.sharedVue.config.globalProperties
  const { substitutionsAllowed } = vueInstance.$store.state.Cart.cart

  const formattedCartData = {
    ...dateData,
    ...availability,
    deliveryOption,
    serviceModel,
    substitutions: `cart level - ${substitutionsAllowed ? 'on' : 'off'}`,
    ecommerce: {
      currencyCode: 'USD',
      checkout: {
        products: productsWithDetails,
        actionField: {
          step,
          options,
        },
      },
    },
  }
  return addCartCount(formattedCartData, productsSortedByCategory)
}

/**
 * @typedef {import('utils/tracking/tracking.types').SlotDateData} SlotDateData
 * @typedef {import('utils/tracking/tracking.types').Product} Product
 *
 *
 * @typedef CheckoutContext
 *
 * @property {Object} selectedSlot  - from vuex store 'PickupLocker.lockersEnabledInStore'
 * @property {boolean} lockersEnabledInStore - from vuex getter 'Slots/selectedSlot'
 * @property {Product[]} products - from vuex getter 'Cart/getCartItems'
 * @property {Product[]} productsSortedByCategory - from vuex 'Cart/getProductsSortedByCategory'
 *
 * @typedef CartData
 *
 * @property {SlotDateData}
 * @property {boolean} availability
 * @property {"delivery"|"pick up"} deliveryOption
 * @property {string} serviceModel
 * @property {{
 *  currencyCode: 'USD',
 *  checkout: {
 *    products: ProductDetails[]
 *    actionField: { step: number, options: Object }
 *  }
 * }}
 *
 * @typedef CartDataWithCount
 *
 * @property {CartData} CartData
 * @property {"Grocery Only"} cartItemsType
 * @property {number} groceryitemCount
 * @property {number} groceryitemTotal
 */
