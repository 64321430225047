import ApiService from '@/api/'

export default {
  get(payload) {
    const endpoint = `/api/v5.0/user/${payload.userId}/order/${payload.basketId}/adjustments/${payload.field}`
    return ApiService.get(endpoint, payload.params)
  },
  put(payload) {
    let endpoint = `/api/v5.0/user/${payload.userId}/order/${payload.basketId}/adjustments/${payload.field}`
    if (payload.queryString) {
      endpoint += `?${payload.queryString}`
    }
    return ApiService.put(endpoint, payload.params)
  },
  getAdjustments(payload) {
    const endpoint = `/api/v6.0/user/${payload.userId}/order/${payload.basketId}/adjustments/${payload.field}`
    return ApiService.get(endpoint, payload.params)
  },
  putAdjustments(payload) {
    let endpoint = `/api/v6.0/user/${payload.userId}/order/${payload.basketId}/adjustments/${payload.field}`
    if (payload.queryString) {
      endpoint += `?${payload.queryString}`
    }
    return ApiService.put(endpoint, payload.params)
  },
  getSavings(payload) {
    const endpoint = `/api/v6.0/user/${payload.userId}/order/${payload.basketId}/savings`
    return ApiService.get(endpoint, payload.data)
  },
  deleteAdjustments(payload) {
    const endpoint = `/api/v6.0/user/${payload.userId}/order/${payload.basketId}/adjustments/${payload.field}`
    return ApiService.delete(endpoint, payload.params)
  },
  delete(payload) {
    const endpoint = `/api/v5.0/user/${payload.userId}/order/${payload.basketId}/adjustments/${payload.field}`
    return ApiService.delete(endpoint)
  },
  createNewBasket(payload) {
    const { userId, serviceLocationID } = payload
    return ApiService.post(`/api/v6.0/user/${userId}/order/${serviceLocationID}`)
  },
  sendOrderUpdateEmail({ basketId, userId }) {
    return ApiService.put(`/api/v6.0/user/${userId}/order/${basketId}/adjustments/orderupdateemail`)
  }
}
