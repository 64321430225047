import * as plugins from '@/plugins'

const pluginsInstaller = {
  install(app) {
    Object.keys(plugins).forEach((pluginName) => {
      plugins[pluginName].install(app)
    })
  }
}

export {
  pluginsInstaller
}
