<template>
  <header
    class="global-header pt-0 border-none"
    :class="[
      {
        'global-header-native-container': isMobileApp
      }
    ]"
  >
    <nav
      class="flex flex-wrap justify-center"
      aria-label="Main"
    >
      <PdlTitleBar
        :title="pageTitle"
        ref="titleBar"
        tabindex="-1"
        :show-back="showBackButton"
      />
      <PdlGlobalHeaderLogo
        v-if="!isMobileApp"
        class="global-header--space-top"
        :opco-name="opcoTheme"
        :link-class="'block'"
        @home-nav-item-clicked="onLogoClicked"
      />
    </nav>
  </header>
</template>

<script>
import { mapGetters } from 'vuex'
import trackNavigation from 'utils/tracking/nav/trackNavigation'
import trackNavigationGtm from 'utils/tracking/nav/trackNavigationGtm'
import generateSiteLocation from 'utils/lib/generateSiteLocation'
import PdlTitleBar from 'components/PdlTitleBar'
import PdlGlobalHeaderLogo from './PdlGlobalHeaderLogo'

export default {
  components: {
    PdlGlobalHeaderLogo,
    PdlTitleBar
  },
  props: {
    isMobileApp: {
      type: Boolean,
      default: false
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    isTablet: {
      type: Boolean,
      default: false
    },
    opcoTheme: {
      type: String,
      required: true
    },
    opcoId: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      pageTitle: 'TitleBar/pageTitle'
    }),
    opcoName() {
      return this.opcoTheme
    },
    isRegistrationPage() {
      return this.$route.path.includes('/registration')
    },
    showBackButton() {
      return !this.isRegistrationPage
    }
  },
  created() {
    this.removeStickyHeaderStyles()
  },
  methods: {
    async onLogoClicked() {
      await this.$router.push('/home')
      this.$router.go()
      this.trackNavClick('Logo', '/home')
    },
    removeStickyHeaderStyles() {
      document.body.classList.remove('hidden-overflow')
      setTimeout(() => {
        document.body.style['padding-top'] = `0px`
        document.documentElement.style.setProperty('--header', `0px`)
      }, 1)
    },
    trackNavClick(title, destination, location = '') {
      trackNavigationGtm({
        navLocation: location || 'header',
        navElementText: `${title}`,
        navURL: `${destination}`
      })
      trackNavigation({
        type: 'utility header',
        linkUrl: `${destination}`,
        linkText: `${title}`,
        siteLocation: `${generateSiteLocation(this.$route)}`
      })
    },
  }
}
</script>
