/**
 * Returns a reference to the store. Can throw an error if the store is not instantiated.
 *
 * @param throwError
 * @throws an Error if the store is not instantiated (optional)
 * @returns {*}
 */
export const getStore = ({ throwError = false } = {}) => {
  const $store = window?.sharedVue?.config?.globalProperties?.$store
  if (throwError && !$store) throw new Error('Store is not instantiated.')
  return $store
}
