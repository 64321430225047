export default {
  state: {
    trackedRecipeImpressions: []
  },
  getters: {
    trackedRecipeImpressions: state => state.trackedRecipeImpressions
  },
  mutations: {
    trackRecipeImpression(state, payload) {
      if (!state.trackedRecipeImpressions.includes(payload)) {
        state.trackedRecipeImpressions.push(payload)
      }
    }
  },
  actions: {
    saveRecipeImpressions({ commit }, { recipe }) {
      if (recipe) {
        commit('trackRecipeImpression', recipe)
      }
    }
  }
}
