<template>
  <component
    :is="`${xOnly ? 'div' : 'button'}`"
    :class="[
      'pill-box' ,
      { 'pill-box--prime' : isLast },
      { 'pill-box--no-cursor' : xOnly }
    ]"
    @click="onClick"
    @keyup.enter.space="onClick"
  >
    <div
      :id="labelId"
      class="pill-box_label"
      :aria-label="label + ' press to clear filter'"
    >
      {{ label || keywords }}
    </div>
    <div
      :tabindex="xOnly ? 0 : null"
      :role="xOnly ? 'button' : null"
      class="pill-box_close-container"
      :aria-labelledby="xOnly ? labelId : null"
      @click="closeIconClicked"
      @keyup.enter.space="closeIconClicked"
    >
      <PdlIconBase
        icon-title="Close"
        container-class="pill-box_close-button"
      >
        <PdlIconClose />
      </PdlIconBase>
    </div>
  </component>
</template>

<script>
import { PdlIconBase } from '@/shared/icons'
import PdlIconClose from 'components/icons/icon/PdlIconClose'

export default {
  components: {
    PdlIconClose,
    PdlIconBase
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    isLast: {
      type: Boolean,
      default: false,
    },
    filter: {
      type: Object,
      default: null
    },
    keywords: {
      type: String,
      default: ''
    },
    xOnly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    labelId() {
      return this.label.replace(/\s+/g, '-').toLowerCase()
    },
  },
  methods: {
    emitNeededEvent() {
      if (this.isLast) {
        this.$emit('clear-all')
        this.$emit('clear-filter')
      } else if (this.keywords) {
        this.$emit('clear-keywords')
      } else {
        this.$emit('clear-filter', {
          obj: {
            key: this.filter ? this.filter.key : null,
            value: this.filter ? this.filter.value : null,
            label: this.label,
            checked: false
          }
        })
      }
    },
    onClick() {
      if (!this.xOnly) {
        this.emitNeededEvent()
      }
    },
    closeIconClicked() {
      if (this.xOnly) {
        this.emitNeededEvent()
      }
    }
  }
}
</script>
