import ApiService from '@/api/'

export default {
  getTip(payload) {
    let endpoint = `api/v6.0/user/${payload.userId}/order/${payload.basketId}/driverTip`
    if (payload.queryString) {
      endpoint += `?${payload.queryString}`
    }
    return ApiService.get(endpoint)
  },
  putTip(payload) {
    const endpoint = `api/v6.0/user/${payload.userId}/order/${payload.basketId}/driverTip`
    return ApiService.put(endpoint, payload.params)
  },
  delete(payload) {
    const endpoint = `api/v6.0/user/${payload.userId}/order/${payload.basketId}/driverTip`
    return ApiService.delete(endpoint)
  }
}
