import PaymentMethodsApi from 'api/PaymentMethodsAPI'
import { GTM_GA4 } from 'utils/constants'

export default {
  namespaced: true,
  state: {
    snapAmount: null,
    alreadyUsedEBT: false,
    ebtCertified: false,
    serviceLocationIds: [],
    changeSnapAmount: false,
    stateNames: [],
    amountRefunded: null
  },
  mutations: {
    setSnapAmount(state, value) {
      state.snapAmount = value
    },
    setChangeSnapAmount(state, value) {
      state.changeSnapAmount = value
    },
    setAlreadyUsedEbtStatus(state, value) {
      state.alreadyUsedEBT = value
    },
    setEbtCertified(state, value) {
      state.ebtCertified = value
    },
    setEbtAmountRefunded(state, value) {
      state.amountRefunded = value
    },
    setServiceLocationIds(state, payload) {
      if (Array.isArray(payload)) {
        state.serviceLocationIds = payload
      }
    },
    setStatesNames(state, payload) {
      if (Array.isArray(payload)) {
        state.stateNames = payload
      }
    },

  },
  actions: {
    async addEbtCard({ commit, rootGetters }, payload) {
      const userId = rootGetters['UserProfile/userId']
      const response = await PaymentMethodsApi.addEbtCard(userId, payload)
      const success = response.status >= 200 && response.status < 300
      const vueInstance = window.sharedVue.config.globalProperties
      if (success) {
        commit('UserProfile/updateUserProfileData', {
          path: `information.hasEBT`,
          value: true
        }, { root: true })
        vueInstance.$trackGA4Event(GTM_GA4.addPaymentMethod, {
          action: 'Add',
          payment_type: 'EBT',
          response: success ? 'success : ' : `fail : ${response.status}`,
          site_location: window.document.title,
        })
      }
      return response
    }
  }
}
