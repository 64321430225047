<template>
  <div
    class="pdl-toggle"
    :class="{'pdl-toggle--mobile': isMobile}"
  >
    <label
      :for="id"
      class="pdl-toggle_switch"
    >
      <input
        :id="id"
        class="pdl-toggle_input"
        type="checkbox"
        :checked="checked"
        :disabled="isDisabled"
        :aria-checked="isChecked.toString()"
        @change="onChange"
      >
      <span
        class="pdl-toggle_slider"
        :class="{
          'pdl-toggle_slider--checked': checked
        }"
      />
      <span
        v-if="!hideLabel"
        class="pdl-toggle_label"
        :class="labelClass"
      >
        {{ label }}
      </span>
    </label>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  emits: ['change'],
  props: {
    id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    value: {
      type: [String, Number, Boolean],
      required: true
    },
    name: {
      type: String,
      required: true
    },
    checked: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    hideLabel: {
      type: Boolean,
      default: false
    },
    labelClass: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isChecked: false
    }
  },
  computed: {
    ...mapGetters({
      isMobile: 'ScreenSize/isMobile'
    })
  },
  beforeMount() {
    this.isChecked = this.checked
  },
  beforeUpdate() {
    this.isChecked = this.checked
  },
  methods: {
    onChange(event) {
      const { checked, value } = event.target
      this.isChecked = !this.isChecked
      this.$emit('change', {
        value,
        checked,
        name: this.label,
        key: this.name
      })
    }
  }
}
</script>
