import capitalize from 'utils/filters/capitalize'
import { getPastPurchasesTabName } from 'utils/services/get-past-purchases-tab-name'

/**
 * @file Output formatted site location
 * Main function: generateSiteLocation
 */
const accountMapping = {
  user: 'User Information',
  email: 'Email Address',
  'delivery-address': 'Delivery Address',
  loyalty: 'Loyalty Card Information',
  card: 'Loyalty Card Number & Alt ID',
  mailing: 'Loyalty Card Mailing Address',
  'privacy-preferences': 'Privacy Preferences',
  history: 'Order History',
  invoice: 'Past Orders',
  preference: 'Invoice Preferences',
  balance: 'Account Balance',
  payment: 'Payment Information',
  credit: 'Credit / Debit Card',
  checking: 'Checking Account',
  ebt: 'SNAP EBT Account',
  communications: 'Communications',
  'health-preferences': 'Health & Lifestyle Preferences',
  rewards: 'Rewards & Programs',
  'pod-pass': 'Membership Pass',
  'savings-history': 'Savings History',
  online: 'Past Orders - Online',
  'in-store': 'Past Orders - In Store',
  details: 'Past Orders - Details'
}
const accountExceptionsMapping = {
  'account/user/loyalty/email': 'My Account - Loyalty Card Email Address',
  '^account/payment/credit/.*$': 'My Account - Credit / Debit Card'
}
const contactMapping = {
  feedback: 'Give Us Feedback',
  email: 'Online Order & Account Questions',
  'email-more': 'In-Store, Rewards & Offer Questions',
  'product-request': 'Product Request',
}
const rewardsMapping = {}

const routeMappingObj = {
  '^rewards.*$': match => mapRewards(match),
  '^savings.*$': match => mapSavings(match),
  '^account.*$': match => manualMapping(match, 'My Account', accountMapping, accountExceptionsMapping),
  '^contact.*$': match => manualMapping(match, 'Contact Us', contactMapping),
  '^help(/([\\w-]*)[/]?)?.*$': (...args) => mapHelpCenter(args),
  '^browse-aisles/categories/1/categories/(\\d*)-\\w*$':
    (...args) => mapBrowseAislesWithSubCategory(args),
  '^browse-aisles/categories/1/categories/(\\d*)/(\\w*)/(\\d*)-?([-\\w]*).*$':
    (...args) => mapBrowseAislesWithSubCategory(args),
  '^password-request[/]?$': 'Reset Password',
}

const modalsMapping = {
  // Map value can be a function or string
  PdlPastPurchaseModal: skipTabInfo => mapPastPurchases(skipTabInfo)
}

const getModalDetails = (fileName, skipTabInfo) => {
  const mapValue = modalsMapping[fileName]
  const mapValueIsFunction = mapValue instanceof Function
  const modalDetails = mapValueIsFunction ? mapValue(skipTabInfo) : mapValue
  return modalDetails ? ` - ${modalDetails}` : ''
}

const parseTitle = (docTitle) => {
  const [pageName, brandName] = docTitle.split('|').map(part => part.trim())
  return { pageName, brandName }
}

const fromPageTitle = () => {
  const { pageName } = parseTitle(window.document.title)
  return pageName
}

const fromRoutePath = (match) => {
  return match
    .split('/')
    .map(segment => segment.split('-').map(word => capitalize(word)).join(' '))
    .join(' - ')
}

const fromRouteName = (name) => {
  return name.split('-').map(word => capitalize(word)).join(' ')
}

const fromMetaPageTitle = (route) => {
  return route?.meta?.pageTitle
}

/**
 * Check if the input matches regexObj key and replace the input with replacer function output or fixed string
 *
 * @param {string} input Input string
 * @param {object} regexObj Object<regexString, function | string>
 * @returns {string} return empty string if there's no match
 */
const matchRegularExpression = (input, regexObj) => {
  const matchedPattern = Object.keys(regexObj).find((regexPattern) => {
    const regex = new RegExp(regexPattern)
    return regex.test(input)
  })
  if (matchedPattern) {
    const replacer = regexObj[matchedPattern]
    if (typeof replacer === 'function') {
      const regex = new RegExp(matchedPattern)
      return input.replace(regex, replacer)
    }
    return replacer
  }
  return ''
}

const getTopCategoryName = (categoryTreeId) => {
  const topCategories = window.sharedVue.config.globalProperties.$store.state.BrowseAislesCategories.categories
  const topCategory = topCategories.find(category => category.categoryTreeId === categoryTreeId)
  return topCategory?.categoryName
}

/**
 * Map the matched string to proper site location
 *
 * Use last route path as unique key to get proper site location
 * If there's duplicate last route, put it in exceptionsMapping
 *
 * @param {string} match Matched string
 * @param {string} rootRouteText Text that replace the root route
 * @param {object} routeMapping Object that maps the last route path to proper site location
 * @param {object} [exceptionsMapping] Object<string | regexString, function | string>
 * @returns {string} formatted siteLocation
 */
const manualMapping = (match, rootRouteText, routeMapping, exceptionsMapping) => {
  let siteLocation = ''
  if (exceptionsMapping) {
    const exceptionKey = Object.keys(exceptionsMapping).find(key => match === key)
    if (exceptionKey) return exceptionsMapping[exceptionKey]
    siteLocation = matchRegularExpression(match, exceptionsMapping)
    if (siteLocation) return siteLocation
  }
  const routePath = match.split('/')
  const lastRoutePath = routePath.pop()
  siteLocation = rootRouteText
  if (routeMapping[lastRoutePath]) siteLocation += ` - ${routeMapping[lastRoutePath]}`
  return siteLocation
}

const mapRewards = (match) => {
  const ROOT = 'Rewards'
  const hasManualMapping = manualMapping(match, ROOT, rewardsMapping)

  const isLoggedIn = window.sharedVue.config.globalProperties.$store.getters['LoginStatus/isLoggedIn']
  if (!isLoggedIn) {
    return `${fromRoutePath(match)} - Not Signed In`
  }

  // Check that the manual mapping is not just the root
  return hasManualMapping !== ROOT ? hasManualMapping : fromRoutePath(match)
}

const mapSavings = (match) => {
  const allSpecialsCategoryReg = /savings\/all-specials\/category\/(\d*)/g
  const isDesktop = window.sharedVue.config.globalProperties.$store.getters['ScreenSize/isDesktop']
  const isDesktopSavingsLandingPage = isDesktop && match === 'savings'
  if (isDesktopSavingsLandingPage) {
    return 'Savings - All Specials'
  }
  if (allSpecialsCategoryReg.test(match)) {
    return match.replace(allSpecialsCategoryReg, (...args) => {
      let siteLocation = 'Savings - All Specials'
      // Group 1: Top CategoryTreeId
      const topCategoryName = getTopCategoryName(args?.[1] * 1)
      if (topCategoryName) siteLocation += ` - ${topCategoryName}`
      return siteLocation
    })
  }
  return fromRoutePath(match)
}

const mapHelpCenter = (args) => {
  let siteLocation = 'Help Center'
  // Group 2: mainTopic
  const mainTopic = args?.[2]?.split('-').map(word => capitalize(word)).join(' ')
  if (mainTopic) siteLocation += ` - ${mainTopic}`
  return siteLocation
}

const mapBrowseAislesWithSubCategory = (args) => {
  let siteLocation = 'Browse Aisles'
  // Group 1: Top CategoryTreeId
  const topCategoryName = getTopCategoryName(args?.[1] * 1)
  // Group 4: Sub CategoryName
  const subCategoryName = args?.[4]?.split('-').map(word => capitalize(word)).join(' ') || 'Featured'
  if (topCategoryName) siteLocation += ` - ${topCategoryName}`
  if (subCategoryName) siteLocation += ` - ${subCategoryName}`
  return siteLocation
}

const mapPastPurchases = (skipTabInfo) => {
  let siteLocation = 'Past Purchases'
  if (!skipTabInfo) {
    const tab = getPastPurchasesTabName()
    if (tab) siteLocation += ` - ${tab}`
  }
  return siteLocation
}

const mapCart = (skipTabInfo) => {
  let siteLocation = 'Cart'
  const cartHasItems = window.sharedVue.config.globalProperties.$store.getters['Cart/cartHasItems']
  if (!cartHasItems) siteLocation += ' - Empty'
  const modal = window.sharedVue.config.globalProperties.$store.getters['Modals/activeComponent']
  if (modal) siteLocation += getModalDetails(modal.fileName, skipTabInfo)
  return siteLocation
}

const autoPopulate = (route, skipTabInfo) => {
  const routeName = route.name
  switch (routeName) {
    case 'home':
      return fromRouteName(routeName)
    case 'product-group':
      return fromRouteName(routeName)
    case 'product-details':
      return fromMetaPageTitle(route)
    case 'cart':
      return mapCart(skipTabInfo)
    case 'past-purchases':
      return mapPastPurchases(skipTabInfo)
    default:
      return fromPageTitle()
  }
}

/**
 * Generate formatted site location
 *
 * Flow:
 * 1. Test routePath using Regex for manual mapping
 * 2. If no predefined mapping, populate site location from route or page title
 * 3. If there's an append location, return <rootRoute> - <formatted appendLocation>
 *
 * @param {object} route Vue route object
 * @param {string} [appendLocation] <carousel / location> that appends to the end
 * @param {boolean} [skipMiddleRoutes=false] skip middle routes from the route path when appending
 * @returns {string|undefined} formatted siteLocation, return undefined if error out
 */
const generateSiteLocation = (route, appendLocation, skipMiddleRoutes = false, skipTabInfo = false) => {
  try {
    // Remove the first slash '/'
    let routePath = route.path.slice(1)

    // If the last char is '/', remove it
    routePath = routePath.slice(-1) === '/' ? routePath.slice(0, -1) : routePath

    // Manual mapping
    let siteLocation = matchRegularExpression(routePath, routeMappingObj)

    // Auto-populate
    if (!siteLocation) siteLocation = autoPopulate(route, skipTabInfo)

    // If we append location with skipMiddleRoutes true, will return <rootRoute> - <formatted append location>
    if (appendLocation) {
      const rootRoute = skipMiddleRoutes ? siteLocation.split(' - ').shift() : siteLocation
      const formattedLocation = appendLocation.split(' ').map(word => capitalize(word)).join(' ')
      return `${rootRoute} - ${formattedLocation}`
    }
    return siteLocation
  } catch (e) {
    console.error(e)
    return undefined
  }
}

export default generateSiteLocation
