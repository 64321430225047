<template>
  <button
    :id="buttonId"
    :aria-expanded="isActive"
    aria-haspopup="true"
    :class="['items-center',
      {
        'min-w-[10rem] p-4 text-sm font-bold leading-none flex-col hidden lg:flex' : !isNewNavDesignEnabled,
        'hidden lg:flex flex-row text-sm font-normal p-4 flex-row': isNewNavDesignEnabled
      }
    ]"
    @click.stop="onAccountButtonClick"
    @keydown.esc="closeMenu"
    @keydown.down="handleDownKey"
  >
    <div
      v-if="!isNewNavDesignEnabled"
      class="w-8 mb-2 pt-2"
    >
      <PdlIconBase
        icon-title="My Account Icon"
        icon-class="w-8 h-8"
      >
        <PdlIconAccount />
      </PdlIconBase>
    </div>
    {{ accountButtonLabel }}
    <div
      v-if="isNewNavDesignEnabled"
      v-show="!isActive"
      class="nav-item_caret ml-1"
    >
      <PdlIconBase
        icon-title="down arrow"
        container-class="vector-icon-size--xsmall stroke-black"
      >
        <PdlIconCarrotDown />
      </PdlIconBase>
    </div>
    <div
      v-if="isNewNavDesignEnabled"
      v-show="isActive"
      class="nav-item_caret ml-1"
    >
      <PdlIconBase
        icon-title="down up"
        container-class="vector-icon-size--xsmall stroke-black stroke-4"
      >
        <PdlIconCarrotUp />
      </PdlIconBase>
    </div>
  </button>
</template>

<script>
import { mapGetters } from 'vuex'
import PdlIconAccount from 'components/icons/icon/PdlIconAccount'
import PdlIconCarrotDown from 'components/icons/icon/PdlIconCarrotDown'
import PdlIconCarrotUp from 'components/icons/icon/PdlIconCarrotUp'

export default {
  components: {
    PdlIconAccount,
    PdlIconCarrotDown,
    PdlIconCarrotUp
  },
  props: {
    buttonId: {
      type: String,
      required: true
    },
    firstName: {
      type: String,
      required: true
    },
    isNewNavDesignEnabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters('LoginStatus', ['isLoggedIn']),
    accountButtonLabel() {
      let label = 'Sign In'
      if (this.isLoggedIn && !this.isNewNavDesignEnabled) {
        label = (!this.firstName) ? 'Account' : this.firstName
      }
      if (this.isLoggedIn && this.isNewNavDesignEnabled) {
        label = (!this.firstName) ? 'Account' : `Hi, ${this.firstName}`
      }
      return label
    },
    isActive() {
      return this.$store.getters['GlobalHeader/isAccountMenuOpen']
    }
  },
  methods: {
    onAccountButtonClick() {
      this.$emit('account-button-clicked')
    },
    handleDownKey() {
      if (this.isActive) {
        this.$emit('focus-on-account-menu')
      }
    },
    closeMenu() {
      this.$emit('close-menu')
    }
  }
}
</script>
