const getUserProfileAndEcommStatus = () => {
  const store = window.sharedVue.config.globalProperties.$store
  const {
    zip, storeId, priceZone, serviceLocationId
  } = store.getters['UserProfile/deliveryServiceLocation']
  const storeEcommerceState = store.getters[
    'FoodLionTransition/storeEcommerceState'
  ](serviceLocationId)
  const opco = store.getters['SiteConfig/opco']
  const userProfileAndStoreInfo = {
    zip,
    storeId,
    priceZone,
    serviceLocationId
  }
  if (opco === 'FDLN') {
    userProfileAndStoreInfo.ecommEnabled = storeEcommerceState === 'P3'
  }
  return userProfileAndStoreInfo
}

const bulkAddToCart = async (payload) => {
  const store = window.sharedVue.config.globalProperties.$store
  // more logic on PDL side to handle current cart items and get new quantity for products
  const cartItems = store.getters['Cart/getCartItems']
  const filteredItems = []
  payload.items.forEach((item) => {
    const matchingItem = cartItems.find(cartItem => cartItem.prodId === item.productId)
    if (matchingItem) {
      item.quantity += matchingItem?.qty
    }
    filteredItems.push(item)
  })
  try {
    let responseData = {}
    const outerResponse = await store.dispatch('Cart/updateCartItems', { items: filteredItems })
    // handling partial add to cart response
    if (outerResponse?.response?.status === 409 && outerResponse?.response?.data) {
      responseData = outerResponse?.response?.data?.response
    } else {
      responseData = outerResponse?.data?.response
    }
    return {
      id: payload.id,
      items: [
        ...responseData?.successes?.map(resp => ({ id: resp.productId, code: resp.code })) ?? [],
        ...responseData?.errors?.map(resp => ({ id: resp.productId, code: resp.code })) ?? []
      ]
    }
  } catch (error) {
    return error
  }
}

const parseItemsQueryString = (queryString) => { // expects 'ProductId1_Qty,ProductId2_Qty'
  let products = []
  const itemsArray = queryString.split(',')
  const isANumberRegex = /^\d+$/
  itemsArray.forEach((item) => {
    const split = item.split('_')
    //  make sure inputs are numeric and then push to array
    if (!isANumberRegex.test(split[0]) || !isANumberRegex.test(split[1])) return
    products.push({
      productId: Number(split[0]),
      quantity: Number(split[1])
    })
  })

  // limit to ten products as per contract with partner
  if (products.length > 20) {
    products = products.slice(0, 20)
  }

  return products
}

export {
  getUserProfileAndEcommStatus,
  bulkAddToCart,
  parseItemsQueryString
}
