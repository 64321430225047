/**
* @param date - date string to be formatted
* @param options - optional Intl.DateTimeFormat constructor and configuration options
* @return String - ex: "Monday, Nov 30"
* */
export function formatDate(date, { ...options }) {
  const {
    weekday = 'long',
    month = 'short',
    day = 'numeric'
  } = {
    ...options
  }

  return window.LuxonDateTime
    .fromISO(date)
    .toLocaleString({
      weekday,
      month,
      day
    })
}

/**
 * @param time - time string to be formatted
 * @return String - ex: "9:00pm"
 * */
export function formatTime(time) {
  return window.LuxonDateTime
    .fromISO(time)
    .toLocaleString(window.LuxonDateTime.TIME_SIMPLE)
    .toLowerCase()
}
