const PATH_NAME = 999
const VANITY_OBJ_KEY = 996
const VANITY_OBJ_VALUE = 997
// eslint-disable-next-line
const allExpressionsRE = /\{\{([^{}]*)\}\}/g

export function formatUrlsToObj(queryObj) {
  const vanityObj = {
    path: queryObj[PATH_NAME] || '',
    fields: {}
  }
  if (queryObj[VANITY_OBJ_KEY] && queryObj[VANITY_OBJ_VALUE]) {
    if (!Array.isArray(queryObj[VANITY_OBJ_KEY])) {
      vanityObj.fields[queryObj[VANITY_OBJ_KEY]] = queryObj[VANITY_OBJ_VALUE]
    } else {
      queryObj[VANITY_OBJ_KEY].forEach((item, index) => {
        vanityObj.fields[item] = queryObj[VANITY_OBJ_VALUE][index]
      })
    }
  }

  return vanityObj
}


export function transformContentValue(val, vanityObj) {
  return val.replace(allExpressionsRE, (expression => replaceExpression(expression, vanityObj))).trim()
}

const replaceExpression = (expression, vanityObj) => {
  const field = expression.replace(/[{()}]/g, '').replace(/\s/g, '').split('.')
  if (field.length <= 1) {
    return ''
  }
  const fieldKey = field.pop()
  return vanityObj[fieldKey]
}
