// Use axios.get instead api.get because we get a CORS error otherwise.
// (withCredentials seems to be set to true with api.get and needs to be false.)
import axios from 'axios'
import ApiService from '@/api/'
import couponAPI from 'api/couponApi'
import checkExpiration from 'utils/filters/checkExpiration'

const initialState = {
  publications: [],
  selectedPublicationIndex: 0,
  selectedPublicationId: null,
  weeklyAdProducts: [],
  nonExpiredWeeklyAdProducts: [],
  gridViewWeeklyAdProducts: [],
  weeklyAdZoneProducts: [],
  clippedCoupons: [],
  clippedCouponsLookup: {},
  clippedCouponIds: [],
  selectedWeeklyAdProduct: {},
  clipCouponInIframe: null,
  limit: 60,
  slicedLimit: 60,
  sortSelection: 'pageOrder',
  sortChanged: false,
  sortOptions: [
    {
      title: 'Page Order',
      value: 'pageOrder'
    },
    {
      title: 'Alphabetically',
      value: 'alphabetically'
    }
  ],
  publicationFilters: [],
  searchResult: {
    text: '',
    count: ''
  },
  selectedFilters: [],
  modals: {
    selector: {
      isActive: false
    },
    printView: {
      isActive: false
    },
    itemDetails: {
      isActive: false
    },
    listControls: {
      isActive: false
    },
    varietyModal: {
      isActive: false
    }
  },
  hasBrickAndMortarStore: true,
  serviceType: '',
  publicationsInitialized: false,
  weeklyAdProductsInitialized: false,
  weeklyAdZoneProductsInitialized: false,
  clippedCouponsInitialized: false,
  publicationsError: false,
  noPublicationExistsError: false,
  weeklyAdProductsError: false,
  weeklyAdZoneProductsError: false,
  anonymousCouponsError: false,
  selectedStoreNumber: null,
  varietyModalDetails: {},
  anonymousOffersInitialized: false,
  guidLookupByCircularId: {},
  anonymousOffersLookupByGuid: {},
  selectionModalViewedThisSession: false,
  keywords: '',
  filterSearchQuery: '',
  weeklyAdViewedThisSession: [],
  eligibleZip: false,
  storeLocatorVisited: false
}

const sortByName = (a, b) => {
  const aAdName = a.name
  const bAdName = b.name

  if (aAdName < bAdName) return -1
  if (aAdName > bAdName) return 1
  return 0
}
const sortByPageOrder = (a, b) => {
  const offset = 10
  /* eslint-disable max-len */
  const canCompareByPage = Object.hasOwn(a, 'page') && Object.hasOwn(b, 'page')
  const canCompareByVerticalPosition = Object.hasOwn(a, 'page_relative_top_offset') && Object.hasOwn(b, 'page_relative_top_offset')
  const canCompareByHorizontalPosition = Object.hasOwn(a, 'page_relative_left_offset') && Object.hasOwn(b, 'page_relative_left_offset')
  const canCompareByPosition = canCompareByVerticalPosition && canCompareByHorizontalPosition
  const itemsAreOnSamePage = a.page === b.page
  const itemsAreInTheSameRow = ((a.page_relative_top_offset - offset) <= b.page_relative_top_offset) && ((a.page_relative_top_offset + offset) >= b.page_relative_top_offset)
  /* eslint-enable max-len */

  if (canCompareByPage && canCompareByPosition && itemsAreOnSamePage) {
    // sort by page position
    if (itemsAreInTheSameRow) {
      return a.page_relative_left_offset - b.page_relative_left_offset
    }
    return a.page_relative_top_offset - b.page_relative_top_offset
  }

  if (canCompareByPage) {
    // sort by page
    return a.page - b.page
  }
  return 0
}

export default {
  namespaced: true,
  state: initialState,
  getters: {
    publications: state => state.publications,
    selectedPublicationIndex: state => state.selectedPublicationIndex,
    selectedPublicationId: state => state.selectedPublicationId,
    nonExpiredWeeklyAdProducts: state => state.nonExpiredWeeklyAdProducts,
    weeklyAdProducts: state => state.weeklyAdProducts,
    weeklyAdProductsBatch: (state) => {
      const ads = state.gridViewWeeklyAdProducts
      return state.slicedLimit ? ads.slice(0, state.slicedLimit) : ads
    },
    weeklyAdZoneProducts: state => state.weeklyAdZoneProducts,
    clippedCoupons: state => state.clippedCoupons,
    clippedCouponsLookup: state => state.clippedCouponsLookup,
    clippedCouponIds: state => state.clippedCouponIds,
    selectedWeeklyAdProduct: state => state.selectedWeeklyAdProduct,
    weeklyAdViewedThisSession: state => state.weeklyAdViewedThisSession,
    clipCouponInIframe: state => state.clipCouponInIframe,
    weeklyAdsLimit(state) {
      const ads = state.gridViewWeeklyAdProducts
      return ads.length
    },
    weeklyAdsGridTotal: state => state.gridViewWeeklyAdProducts.length,
    sortSelection: state => state.sortSelection,
    sortChanged: state => state.sortChanged,
    sortOptions: state => state.sortOptions,
    publicationFilters: state => state.publicationFilters,
    searchResult: state => state.searchResult,
    selectedFilters: state => state.selectedFilters,
    isSelectorModalActive: state => state.modals.selector.isActive,
    isPrintViewModalActive: state => state.modals.printView.isActive,
    isItemDetailsModalActive: state => state.modals.itemDetails.isActive,
    islistControlsModalActive: state => state.modals.listControls.isActive,
    hasBrickAndMortarStore: state => state.hasBrickAndMortarStore,
    serviceType: state => state.serviceType,
    keywords: state => state.keywords,
    filterSearchQuery: state => state.filterSearchQuery,
    publicationsInitialized: state => state.publicationsInitialized,
    weeklyAdProductsInitialized: state => state.weeklyAdProductsInitialized,
    weeklyAdZoneProductsInitialized: state => state.weeklyAdZoneProductsInitialized,
    clippedCouponsInitialized: state => state.clippedCouponsInitialized,
    publicationsError: state => state.publicationsError,
    noPublicationExistsError: state => state.noPublicationExistsError,
    weeklyAdProductsError: state => state.weeklyAdProductsError,
    weeklyAdZoneProductsError: state => state.weeklyAdProductsError,
    anonymousCouponsError: state => state.anonymousCouponsError,
    selectedStoreNumber: state => state.selectedStoreNumber,
    varietyModalDetails: state => state.varietyModalDetails,
    anonymousOffersInitialized: state => state.anonymousOffersInitialized,
    guidLookupByCircularId: state => state.guidLookupByCircularId,
    anonymousOffersLookupByGuid: state => state.anonymousOffersLookupByGuid,
    selectionModalViewedThisSession: state => state.selectionModalViewedThisSession,
    eligibleZip: state => state.eligibleZip,
    storeLocatorVisited: state => state.storeLocatorVisited
  },
  mutations: {
    setVarietyModalDetails(state, payload) {
      state.varietyModalDetails = payload
    },
    setPublications(state, payload) {
      const sortByPublicationName = (a, b) => {
        // Sort Order: Weekly Ad, Sneak Peek, Look Book, All Others

        // If one is a Weekly Ad and one is not, sort the Weekly Ad first.
        // If a and b are not Weekly Ads but one is a Look Book, sort the Look Book first.
        // if a and b are both Weekly Ads, both Look Books, or neither weekly Ads nor Look Books, sort by date.

        const aIsWeeklyAd = a.name === 'Weekly Circular'
        const bIsWeeklyAd = b.name === 'Weekly Circular'
        const aIsLookBook = a.name !== 'Weekly Circular'
        const bIsLookBook = b.name !== 'Weekly Circular'

        const aValidFrom = new Date(a.valid_from)
        const bValidFrom = new Date(b.valid_from)

        if ((aIsWeeklyAd && !bIsWeeklyAd) || (aIsLookBook && !bIsWeeklyAd && !bIsLookBook)) {
          return -1
        }

        if ((!aIsWeeklyAd && bIsWeeklyAd) || (!aIsWeeklyAd && !aIsLookBook && bIsLookBook)) {
          return 1
        }

        if (bValidFrom.getTime() < aValidFrom.getTime()) {
          return 1
        }

        return -1
      }

      state.publications = payload.sort(sortByPublicationName)
    },
    setSelectedPublicationIndex(state, payload) {
      state.selectedPublicationIndex = payload
    },
    setSelectedPublicationId(state, payload) {
      state.selectedPublicationId = payload
    },
    setSortSelection(state, payload) {
      state.sortSelection = payload
    },
    setSortChanged(state, payload) {
      state.sortChanged = payload
    },
    setActiveFilters: (state, payload) => {
      state.publicationFilters[payload.value].active = payload.checked
    },
    setSelectedFilters(state) {
      const filterArr = []
      state.publicationFilters.forEach((filter) => {
        if (filter.active) {
          filterArr.push(filter.name)
          if (state.searchResult) {
            state.searchResult.count = filter.count
            state.searchResult.text = filter.name
          }
        }
      })
      state.selectedFilters = filterArr
    },
    setPublicationFilters(state, payload) {
      const memo = {}
      const filterArr = payload.reduce((categories, item) => {
        if (item.item_type === 1 && item.categories[0]) {
          if (!memo[item.categories[0]]) {
            categories.push({ name: item.categories[0], active: false, count: 1 })
            memo[item.categories[0]] = true
          } else {
            categories.find(category => category.name === item.categories[0]).count += 1
          }
        }
        return categories
      }, [])
      state.publicationFilters = filterArr.sort(sortByName)
    },
    setModalState(state, payload) {
      // payload: { modal: string, isActive: boolean }
      if (state.modals[payload.modal]) {
        state.modals[payload.modal].isActive = payload.isActive
      }
    },
    setClipCouponInIframe(state, payload) {
      state.clipCouponInIframe = payload
    },
    setWeeklyAdsSorted(state, payload) {
      const sortableArr = [...payload]
      if (state.sortSelection === 'alphabetically') {
        sortableArr.sort(sortByName)
      } else {
        sortableArr.sort(sortByPageOrder)
      }
      state.gridViewWeeklyAdProducts = sortableArr
    },
    setFilteredWeeklyAdProducts(state, payload) {
      let filteredArr = {}
      if (state.selectedFilters.length > 0) {
        filteredArr = payload.filter(item => state.selectedFilters.includes(item.categories[0]))
      } else {
        filteredArr = payload
      }
      state.searchResult.count = filteredArr.length
      if (state.sortSelection === 'alphabetically') {
        state.gridViewWeeklyAdProducts = filteredArr.sort(sortByName)
      } else {
        state.gridViewWeeklyAdProducts = filteredArr.sort(sortByPageOrder)
      }
    },
    setWeeklyAdsLimit(state) {
      state.slicedLimit += state.limit
    },
    setClippedCoupons(state, payload) {
      state.clippedCoupons = payload
    },
    setClippedCouponsLookup(state, payload) {
      state.clippedCouponsLookup = payload
    },
    setClippedCouponIds(state, payload) {
      state.clippedCouponIds = payload
    },
    setSelectedWeeklyAdProduct(state, payload) {
      state.selectedWeeklyAdProduct = payload
    },
    setWeeklyAdViewedThisSession(state, payload) {
      state.weeklyAdViewedThisSession = payload
    },
    setHasBrickAndMortarStore(state, payload) {
      state.hasBrickAndMortarStore = payload
    },
    setServiceType(state, payload) {
      state.serviceType = payload
    },
    setKeywords(state, payload) {
      state.keywords = payload
    },
    setFilterSearchQuery(state, payload) {
      state.filterSearchQuery = payload
    },
    setPublicationsInitialized(state) {
      state.publicationsInitialized = true
    },
    setWeeklyAdProductsInitialized(state) {
      state.weeklyAdProductsInitialized = true
    },
    setWeeklyAdZoneProductsInitialized(state) {
      state.weeklyAdZoneProductsInitialized = true
    },
    setClippedCouponsInitialized(state, payload) {
      state.clippedCouponsInitialized = payload
    },
    setWeeklyAdProducts(state, payload) {
      state.weeklyAdProducts = payload
    },
    setNonExpiredWeeklyAdProducts(state, payload) {
      // removing expired ads here
      const nonExpiredAds = payload.filter(item => checkExpiration(item.valid_to))
      state.nonExpiredWeeklyAdProducts = nonExpiredAds
    },
    setWeeklyAdZoneProducts(state, payload) {
      state.weeklyAdZoneProducts = payload.sort(sortByPageOrder)
    },
    setCouponAsLoaded(state, payload) {
      const onWeeklyAdDetailsModal = state.selectedWeeklyAdProduct?.coupons
      if (onWeeklyAdDetailsModal) {
        const loadedCoupon = state.selectedWeeklyAdProduct.coupons.find(coupon => coupon.id === payload)
        if (loadedCoupon) {
          loadedCoupon.loaded = true
        }
      }
      state.clippedCouponsLookup[payload] = true
      state.clippedCouponIds.push(payload)
    },
    setAnonymousOffersInitialized(state, payload) {
      state.anonymousOffersInitialized = payload
    },
    setGuidLookupByCircularId(state, payload) {
      state.guidLookupByCircularId = payload
    },
    setAnonymousOffersLookupByGuid(state, payload) {
      state.anonymousOffersLookupByGuid = payload
    },
    setError(state, payload) {
      state[payload.name] = payload.hasError
    },
    setSelectedStoreNumber(state, payload) {
      state.selectedStoreNumber = payload
    },
    setSelectionModalViewedThisSession(state, payload) {
      state.selectionModalViewedThisSession = payload
    },
    resetSortSelection(state) {
      state.sortSelection = 'pageOrder'
    },
    resetFilters(state) {
      state.selectedFilters = []
      state.publicationFilters.forEach((filter) => {
        if (filter.active) {
          filter.active = false
        }
      })
    },
    resetLimit(state) {
      state.weeklyAdsLimit = state.limit
    },
    resetFromStorelocator(state) {
      state.selectedStoreNumber = null
      state.selectedPublicationIndex = 0
      state.publicationsInitialized = false
      state.weeklyAdProductsInitialized = false
      state.publications = []
      state.weeklyAdProducts = []
      state.weeklyAdZoneProducts = []
      state.storeLocatorVisited = true
    },
    reset(state) {
      Object.assign(state, initialState)
    },
    setStoreLocatorVisited(state, payload) {
      state.storeLocatorVisited = payload
    },
    setSearchResult(state, payload) {
      state.searchResult = payload
    },
    setEligibleZip(state, payload) {
      state.eligibleZip = payload
    }
  },
  actions: {
    setVarietyModalDetails({ commit }, payload) {
      commit('setVarietyModalDetails', payload)
    },
    async getPublications({
      commit, state, rootState, rootGetters, dispatch
    }) {
      const { opco } = rootState.SiteConfig
      const userInfo = rootGetters['UserProfile/userInfo']
      const deliveryServiceLocation = rootGetters['UserProfile/deliveryServiceLocation']
      const getResourceByName = rootGetters['SiteConfig/resourceByName']
      const { serviceType, zip, clickAndCollect } = deliveryServiceLocation
      const onStoreLocator = window.location.href.indexOf('store-locator') > -1

      // get delivery allowed locations
      let eligibleZip = false
      if (serviceType === 'D') {
        const deliveryZips = getResourceByName('WEEKLY_AD_DELIVERY_ZIPS')
        if (deliveryZips !== null && deliveryZips !== '') {
          eligibleZip = deliveryZips.toString().split(',').includes(zip)
          commit('setEligibleZip', eligibleZip)
        }
      }

      if (opco && opco !== 'PPOD' && userInfo) {
        if (state.selectedStoreNumber === null) {
          const storeNumber = String(deliveryServiceLocation.storeNumber).padStart(4, '0')
          commit('setSelectedStoreNumber', storeNumber)
        } else if (state.selectedStoreNumber.length < 4) {
          const storeNumber = String(state.selectedStoreNumber).padStart(4, '0')
          commit('setSelectedStoreNumber', storeNumber)
        }

        const showOnSavingsScreen = serviceType === 'B'
        || (serviceType === 'P' && clickAndCollect)
        || eligibleZip || opco === 'FDLN'
        // Always show the ad on the store locator.
        // On the Savings screen,
        // hide when it is a PUP (not Click and Collect).
        const showAd = onStoreLocator ? true : showOnSavingsScreen

        commit('setServiceType', serviceType)
        commit('setError', {
          name: 'publicationsError',
          hasError: false
        })
        commit('setStoreLocatorVisited', false)
        await dispatch('retrievePublications', { showAd })
      }
    },
    async retrievePublications({ commit, state, rootGetters }, payload) {
      const { showAd } = payload
      const currentStoreNumber = state.selectedStoreNumber
      if (currentStoreNumber && showAd) {
        commit('setHasBrickAndMortarStore', true)
        const getVarByName = rootGetters['SiteConfig/varByName']
        const flippBaseUrl = getVarByName('flipp_base_url')
        const flippAccessToken = getVarByName('flipp_access_token')
        const flippMerchantIdentifier = getVarByName('flipp_merchant_identifier')
        const newWeeklyAd = getVarByName('feature_weekly_ad_pdl')

        /* eslint-disable max-len */
        const publicationsEndpoint = `publications/${flippMerchantIdentifier}?locale=en-US&access_token=${flippAccessToken}`
        try {
          const publications = await axios.get(`${flippBaseUrl}${publicationsEndpoint}&store_code=${currentStoreNumber}`)
          commit('setPublications', publications.data)
          commit('setPublicationsInitialized')
          if (newWeeklyAd) {
            const vueInstance = window.sharedVue.config.globalProperties
            const { previewSelected } = vueInstance.$store.state.WeeklyAdPDL
            const publicationIndex = previewSelected ? 1 : 0
            commit('setSelectedPublicationIndex', publicationIndex)
          }
        } catch (e) {
          commit('setError', {
            name: 'publicationsError',
            hasError: true
          })
          if (e?.response?.status === 422) {
            commit('setError', {
              name: 'noPublicationExistsError',
              hasError: true
            })
          }
        }
      } else {
        commit('setHasBrickAndMortarStore', false)
      }
    },
    selectPublication({ commit }, publicationIndex) {
      commit('setSelectionModalViewedThisSession', true)
      commit('setSelectedPublicationIndex', publicationIndex)
    },
    activateModal({ commit }, modal) {
      // can change name to openModal once modal functionality is moved to Vue
      commit('setModalState', {
        modal,
        isActive: true
      })
    },
    deactiveModal({ commit }, modal) {
      // can change name to closeModal once modal functionality is moved to Vue
      commit('setModalState', {
        modal,
        isActive: false
      })
      commit('setSelectedWeeklyAdProduct', {})
    },
    async getProducts({ commit, state, rootGetters }, publicationId) {
      if (state.selectedStoreNumber === null) {
        commit('setSelectedStoreNumber', rootGetters['UserProfile/deliveryServiceLocation'].storeNumber)
      }
      const currentStoreNumber = state.selectedStoreNumber
      const getVarByName = rootGetters['SiteConfig/varByName']
      const flippBaseUrl = getVarByName('flipp_base_url')
      const flippAccessToken = getVarByName('flipp_access_token')
      const search = state.keywords
      /* eslint-disable max-len */
      let publicationEndpoint = `publication/${publicationId}/products?locale=en-US&access_token=${flippAccessToken}`
      if (search !== '') {
        publicationEndpoint += `&keywords=${search}`
      }
      return axios.get(`${flippBaseUrl}${publicationEndpoint}&store_code=${currentStoreNumber}`)
    },
    async getWeeklyAdProducts({ commit, state, dispatch }) {
      const havePublications = state.publications.length

      if (havePublications) {
        const publicationId = state.publications[state.selectedPublicationIndex].id

        commit('setError', {
          name: 'weeklyAdProductsError',
          hasError: false
        })

        try {
          const weeklyAdProducts = await dispatch('getProducts', publicationId)
          commit('setNonExpiredWeeklyAdProducts', weeklyAdProducts.data)
          commit('setWeeklyAdProducts', weeklyAdProducts.data)
          commit('setWeeklyAdsSorted', state.nonExpiredWeeklyAdProducts)
          commit('setPublicationFilters', state.nonExpiredWeeklyAdProducts)
          commit('setWeeklyAdProductsInitialized')
        } catch {
          commit('setError', {
            name: 'weeklyAdProductsError',
            hasError: true
          })
        }
        dispatch('getWeeklyAdZoneProducts')
      }
    },
    getMoreWeeklyAdProducts({ commit }) {
      commit('setWeeklyAdsLimit')
    },
    async getWeeklyAdZoneProducts({ commit, state, dispatch }) {
      if (state.selectedPublicationIndex !== 0) {
        const firstPublicationId = state.publications[0].id
        try {
          const weeklyAdZoneProducts = await dispatch('getProducts', firstPublicationId)
          const nonExpiredAds = weeklyAdZoneProducts.data.filter(item => checkExpiration(item.valid_to))
          commit('setWeeklyAdZoneProducts', nonExpiredAds)
        } catch {
          commit('setError', {
            name: 'weeklyAdZoneProductsError',
            hasError: true
          })
        }
      } else {
        commit('setWeeklyAdZoneProducts', state.nonExpiredWeeklyAdProducts)
        commit('setWeeklyAdZoneProductsInitialized')
      }
    },
    async getAnonymousOffers({ commit, rootState }) {
      // Flipp iframe and APIs use the GUID as the coupon ID. PDL coupon APIs use the QUO_ ID.
      // Coupons can be searched by circular ID, but not clipped. The anonymous offers API
      // contains the GUID and the circular ID (PDG ID). Flipp uses the anonymous offers API
      // to populate coupons, but they do not provide the circular Id for coupons at this time.
      // Until they do, the anonymous offers API must be called in order to get the circular IDs.
      // The circular ID can be used to look up the corresponding coupon from the PDL coupon search API.
      const { opco } = rootState.SiteConfig
      const url = `/apis/circular-coupons/v1/${opco}/offers/anonymous`

      try {
        const response = await ApiService.get(url)
        const anonymousOffers = response.data?.offers || []
        const guidLookupByCircularId = {}
        const anonymousOffersLookupByGuid = {}

        commit('setError', {
          name: 'anonymousCouponsError',
          hasError: false
        })

        if (anonymousOffers.length) {
          anonymousOffers.forEach((anonymousOffer) => {
            // Example: { 21152099: 'fa573daa-d1fd-4134-8c3c-d6a02aac7a1b' }
            guidLookupByCircularId[anonymousOffer.couponExternalId] = anonymousOffer.id
            // Example: { fa573daa-d1fd-4134-8c3c-d6a02aac7a1b: { couponExternalId: '21152099', id: 'fa573daa-d1fd-4134-8c3c-d6a02aac7a1b', ... } }
            anonymousOffersLookupByGuid[anonymousOffer.id] = anonymousOffer
          })
          commit('setGuidLookupByCircularId', guidLookupByCircularId)
          commit('setAnonymousOffersLookupByGuid', anonymousOffersLookupByGuid)
          commit('setAnonymousOffersInitialized', true)
        } else {
          commit('setError', {
            name: 'anonymousCouponsError',
            hasError: true
          })
        }
      } catch {
        commit('setError', {
          name: 'anonymousCouponsError',
          hasError: true
        })
      }
    },
    async getClippedCoupons({ commit, state, rootGetters }) {
      const userInfo = rootGetters['UserProfile/userInfo']
      const clippedCouponsLookup = {}
      const clippedCouponIds = []
      const { guidLookupByCircularId } = state
      if (userInfo) {
        const userId = rootGetters['UserProfile/userId']
        const couponVersion = rootGetters['SiteConfig/varByName']('coupon_version')
        const { serviceLocationId } = rootGetters['UserProfile/deliveryServiceLocation']
        const cardNumber = rootGetters['UserProfile/retailerCardNumber']

        try {
          const clippedCouponsResponse = await couponAPI.getAllClippedCoupons(userId, serviceLocationId, cardNumber, couponVersion)
          const clippedCoupons = clippedCouponsResponse.data.coupons
          commit('setClippedCoupons', clippedCoupons)

          clippedCoupons.forEach((coupon) => {
            const circularId = coupon.circularId || ''
            if (guidLookupByCircularId[circularId]) {
              // Create object to look up a coupon's loaded state by GUID.
              // Create an array of IDs to send to flipp to show as loaded on the print ad.
              // If Flipp stops supporting the functionality to change the coupon icon color on
              // the print ad, then the clippedCouponIds array will no longer be needed.
              // However, clippedCouponsLookup will still be needed.
              clippedCouponIds.push(guidLookupByCircularId[circularId])
              clippedCouponsLookup[guidLookupByCircularId[circularId]] = true
            }
          })
          commit('setClippedCouponsLookup', clippedCouponsLookup)
          commit('setClippedCouponIds', clippedCouponIds)
          commit('setClippedCouponsInitialized', true)
        } catch {
          commit('setClippedCouponsInitialized', false)
        }
      }
    },
    async setWeeklyAdViewedThisSession({ state, commit }, payload) {
      const index = state.weeklyAdViewedThisSession.findIndex(e => e.id === payload.id)
      const weeklyAdViewed = [...state.weeklyAdViewedThisSession]
      if (index === -1) weeklyAdViewed.push(payload)
      commit('setWeeklyAdViewedThisSession', weeklyAdViewed)
    },
    async setSelectedWeeklyAdProduct({ state, commit, dispatch }, payload) {
      // make a copy so we don't actually mutate a product
      const item = { ...payload }
      // Convert flipp coupons data to pdl format.
      if (item.coupons?.length) {
        if (state.anonymousCouponsError) {
          item.coupons = []
          // Make a specific error to show Weekly Ad error message
          // instead of using anonymousCouponsError, so that we
          // only show the error for deals with coupons.
          item.couponConversionError = true
        } else {
          item.couponConversionError = false
          await dispatch('processSelectedItemCoupons', item)
        }
      }
      commit('setSelectedWeeklyAdProduct', item)
    },
    async processSelectedItemCoupons({ commit, state }, payload) {
      const item = payload
      const coupons = []
      item.coupons.forEach((couponWithFlippData) => {
        const guid = couponWithFlippData.external_id
        const couponFromAnonymousOffersApi = state.anonymousOffersLookupByGuid[guid]
        const endDate = couponWithFlippData.valid_to
        const startDate = couponWithFlippData.valid_from
        const isValid = endDate ? checkExpiration(endDate) : false

        if (isValid) {
          // If Flipp gives us the circular ID (PDG ID), then we do not have to get the anonymous offers
          // to get the circular ID. Flipp is looking into whether or not they can provide this data.
          if (couponFromAnonymousOffersApi) {
            const coupon = {
              description: couponFromAnonymousOffersApi.description,
              endDate: couponFromAnonymousOffersApi.expirationDate,
              id: guid,
              circularId: couponFromAnonymousOffersApi.couponExternalId,
              imageUrl: couponFromAnonymousOffersApi.url,
              legalText: couponFromAnonymousOffersApi.legalText,
              loaded: state.clippedCouponsLookup[guid] || false,
              name: couponFromAnonymousOffersApi.name,
              startDate: couponFromAnonymousOffersApi.startDate,
              title: couponFromAnonymousOffersApi.title
            }

            coupons.push(coupon)
          } else {
            // If no match found, display coupon but show as unclippable (no circularId).
            const formattedEndDate = endDate ? endDate.replace(/T.*/, '') : ''
            const formattedStartDate = startDate ? startDate.replace(/T.*/, '') : ''

            const unclippableCoupon = {
              description: couponWithFlippData.promotion_text,
              endDate: formattedEndDate,
              id: guid,
              circularId: null,
              imageUrl: couponWithFlippData.image,
              legalText: couponWithFlippData.disclaimer_text,
              loaded: false, // cannot look up loaded state regardless if it was loaded or not
              name: couponWithFlippData.brand_display_name,
              startDate: formattedStartDate,
              title: couponWithFlippData.sale_story
            }

            coupons.push(unclippableCoupon)
          }
        }
      })
      item.coupons = coupons
      commit('setSelectedWeeklyAdProduct', item)
    },
    applySort({ commit, state }, payload) {
      commit('setSortSelection', payload)
      commit('setWeeklyAdsSorted', state.gridViewWeeklyAdProducts)
    },
    updateFilters({ commit, state }, payload) {
      commit('setActiveFilters', payload)
      commit('setSelectedFilters')
      commit('setFilteredWeeklyAdProducts', state.nonExpiredWeeklyAdProducts)
      commit('setFilterSearchQuery', payload.name)
    },
    clearSort({ commit, state }) {
      commit('resetSortSelection')
      commit('setSortChanged', false)
      commit('setWeeklyAdsSorted', state.gridViewWeeklyAdProducts)
    },
    clearFilters({ commit, state }) {
      commit('resetFilters')
      commit('setFilteredWeeklyAdProducts', state.nonExpiredWeeklyAdProducts)
    },

    async clearSearch({ commit, state, dispatch }, payload) {
      commit('setKeywords', '')
      commit('setFilterSearchQuery', '')
      const publicationId = state.publications[state.selectedPublicationIndex].id
      try {
        const weeklyAdProducts = await dispatch('getProducts', publicationId)
        if (weeklyAdProducts.data.length > 0) {
          commit('setNonExpiredWeeklyAdProducts', weeklyAdProducts.data)
          commit('setWeeklyAdProducts', weeklyAdProducts.data)
          commit('setWeeklyAdsSorted', state.nonExpiredWeeklyAdProducts)
          if (payload) {
            commit('resetFilters')
            dispatch('updateFilters', payload)
          }
        }
      } catch {
        commit('setError', {
          name: 'weeklyAdProductsError',
          hasError: true
        })
      }
    },
    setSearchResult({ commit }, payload) {
      commit('setSearchResult', payload)
    },
    async searchWeeklyAdProducts({ commit, state, dispatch }, payload) {
      commit('setKeywords', payload.searchTerm)
      commit('setFilterSearchQuery', payload.searchTerm)

      const publicationId = state.publications[state.selectedPublicationIndex].id

      commit('setError', {
        name: 'weeklyAdProductsError',
        hasError: false
      })

      try {
        const weeklyAdProducts = await dispatch('getProducts', publicationId)
        let count = 0
        if (weeklyAdProducts.data.length > 0) {
          count = weeklyAdProducts.data.length
          commit('setNonExpiredWeeklyAdProducts', weeklyAdProducts.data)
          commit('setWeeklyAdProducts', weeklyAdProducts.data)
          commit('setWeeklyAdsSorted', state.nonExpiredWeeklyAdProducts)
        } else {
          commit('setNonExpiredWeeklyAdProducts', [])
          commit('setWeeklyAdProducts', [])
          commit('setWeeklyAdsSorted', [])
        }
        const text = payload.searchTerm
        const payloadResult = {
          count,
          text
        }
        commit('setSearchResult', payloadResult)
      } catch {
        commit('setError', {
          name: 'weeklyAdProductsError',
          hasError: true
        })
      }
    }
  }
}
