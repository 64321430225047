import { GTM } from 'utils/constants'
import { setupDataForSlot } from 'utils/tracking/helpers'

/**
   * @param {{
   *   slotData: object,
   *   headers: Header[],
   *   selectionLocation: string,
   *   name: string,
   *   isFirstSelectedSlot: boolean,
   * }} object
 * */
export function trackSlotSelection({
  slotData,
  headers,
  selectionLocation,
  name,
  isFirstSelectedSlot
}) {
  const vueInstance = window.sharedVue.config.globalProperties
  const payload = setupDataForSlot(slotData, headers)
  let eventId
  const { providerId } = slotData

  const storeId = window.sharedVue.config.globalProperties.$store.getters['UserProfile/storeId']

  if (isFirstSelectedSlot) { // first slot
    eventId = GTM.firstDeliverySelection
    payload.firstDeliverySelection = slotData.date
    payload.selectionLocation = selectionLocation
    payload.providerId = providerId
    payload.storeId = storeId
  } else if (slotData.unattended) { // unattended slot
    eventId = GTM.unattendedDeliverySelection
    payload.unattendedDeliverySelection = name
  } else { // normal slot
    eventId = GTM.deliverySelection
    payload.deliverySelection = slotData.date
    payload.providerId = providerId
    payload.storeId = storeId
  }

  vueInstance.$trackGtmEvent(eventId, payload)
}
