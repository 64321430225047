<template>
  <div>
    <button
      ref="trigger"
      v-clickaway="{handler: 'handleClickOutside'}"
      class="trigger inline-block"
      @click.prevent="clickToolTip"
      @mouseenter="toggle()"
      @mouseleave="hide()"
      @focusin="toggle()"
      @focusout="hide()"
    >
      <slot />
    </button>

    <div
      ref="popover"
      class="tooltip popover vue-popover-theme absolute transition duration-150"
      :class="active ? 'opacity-100 visible' : 'opacity-0 invisible'"
    >
      <div
        class="wrapper text-white absolute x"
        :style="wrapperPosition"
      >
        <div
          class="menutip absolute"
          :class="popoverArrowClass"
          :style="[menutipPosition, menutipBorder]"
        />
        <div
          ref="inner"
          class="font-normal"
          :class="popoverInnerClass"
        >
          <div>
            <slot
              name="popover"
            />
          </div>
        </div>
        <div />
      </div>
    </div>
  </div>
</template>

<script>
import clickaway from 'utils/custom-directives/clickaway'

export default {
  directives: {
    clickaway
  },
  props: {
    mode: {
      default: '',
      type: String
    },
    iconSize: {
      default: 'x-small',
      type: String
    },
    popoverArrowClass: {
      default: '',
      type: String
    },
    popoverInnerClass: {
      default: '',
      type: String
    },
    placement: {
      default: 'bottom',
      type: String
    }
  },
  data() {
    return {
      active: false,
      wrapperXOffset: 0,
      wrapperYOffset: 0,
      hideOnScrollTouchActive: false
    }
  },
  computed: {
    wrapperPosition() {
      return {
        bottom: `transform: translateX(-50%) translateY(1rem)`,
        right: `transform: translateY(-50%)`,
        top: `transform: translateX(-50%) translateY(-100%) translateY(-1rem)`,
        bottomLeft: `transform: translateX(-90%) translateY(1rem)`
      }[this.placement]
    },
    menutipPosition() {
      return {
        bottom: { top: 0, left: '50%', transform: 'translateX(-50%) translateY(-50%) rotate(45deg)' },
        right: { top: '50%', transform: 'translateY(-50%) translateX(-50%) rotate(45deg)' },
        top: { top: '100%', left: '50%', transform: 'translateY(-50%) translateX(-50%) rotate(45deg)' },
        bottomLeft: { top: 0, left: '90%', transform: 'translateX(-50%) translateY(-50%) rotate(45deg)' }
      }[this.placement]
    },
    menutipBorder() {
      return {
        bottom: { borderBottom: 'none', borderRight: 'none' },
        right: { borderRight: 'none', borderTop: 'none' },
        top: { borderTop: 'none', borderLeft: 'none' },
        bottomLeft: { borderBottom: 'none', borderRight: 'none' }
      }[this.placement]
    }
  },
  mounted() {
    this.wrapperXOffset = this.$refs.trigger.clientWidth / 2
    this.wrapperYOffset = this.$refs.trigger.clientHeight / 2
    window.addEventListener('scroll', this.hideOnScroll)
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.hideOnScroll)
    if (this.hideOnScrollTouchActive) {
      window.removeEventListener('touchmove', this.hideOnScroll)
    }
    // Ensure subsequential elements are removed
    return this.$refs?.popover?.remove()
  },
  methods: {
    hide() {
      if (this.mode === 'hover') {
        this.active = false
      }
    },
    hideOnScroll() {
      this.active = false
      if (this.hideOnScrollTouchActive) {
        window.removeEventListener('touchmove', this.hideOnScroll)
        this.hideOnScrollTouchActive = false
      }
    },
    clickToolTip() {
      this.active = !this.active
      this.appendTooltip()
      if (!this.hideOnScrollTouchActive) {
        window.addEventListener('touchmove', this.hideOnScroll)
        this.hideOnScrollTouchActive = true
      }
    },
    toggle() {
      if (this.mode === 'hover') {
        this.active = true
        this.appendTooltip()
      }
    },
    handleClickOutside() {
      if (this.mode === 'click') {
        this.active = false
      }
    },
    appendTooltip() {
      const rect = this.$refs.trigger.getBoundingClientRect()
      let left
      let top

      if (this.placement === 'right') {
        top = rect.top + document.documentElement.scrollTop + this.wrapperYOffset
        left = rect.right
      }

      if (this.placement === 'bottom' || this.placement === 'bottomLeft') {
        top = rect.bottom + document.documentElement.scrollTop
        left = rect.left + this.wrapperXOffset
      }

      if (this.placement === 'top') {
        top = rect.top + document.documentElement.scrollTop
        left = rect.left + this.wrapperXOffset
      }

      document.body.appendChild(this.$refs.popover)
      this.$refs.popover.style.top = `${top}px`
      this.$refs.popover.style.left = `${left}px`
    }
  }
}
</script>
