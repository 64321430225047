import ApiService from '@/api/'

const rewardsPath = '/apis/rewards/v1'
const loyaltyOffersRedemptionPath = '/api/v2.0/rewards'
const balancesPath = '/apis/balances/program/v1'
const balancesPathV2 = '/apis/balances/program/v2'

export default {
  /**
  * Fetches loyalty offers for the given card number
  *  All offers are activated for a user within the microservice
  * @param {String} opco - Required. Valid for STSH, GNTL, GNTC, MRTN
  * @param {String} cardNumber - Required. Loyalty account to search
  * @param {String} userId - Required. User Id.
  * @param {String} type -  Valid for lto, bonus, continuity. Multiple can be submitted e.g. ?type=bonus,continuity
  * @param {String} orderId -  The current order id for the user. Used to tie discounts to products in cart
  * @param {String} serviceLocationId - id of the store location they are shipping
  * @param {String} start - Starting index for fetched offers
  * @param {String} size - Number of offers to fetch. 1 - 300
  */
  getOffers({ opco, cardNumber, userId }, {
    type, orderId, serviceLocationId, size
  }) {
    const loyaltyOffersPath = `/api/v3.0/loyalty/${userId}/${opco}/${cardNumber}`

    const params = {
      ...type && { type },
      ...orderId && { orderId },
      ...serviceLocationId && { serviceLocationId },
      start: 0,
      ...size && { size },
    }
    return ApiService.get(loyaltyOffersPath, { params })
  },

  /**
   * Clear the offer cache for the card number
   *
   * @param {String} opco - user opco
   * @param {String} cardNumber - user card number
   */
  refreshOffersCache({ opco, cardNumber }) {
    return ApiService.post(`${rewardsPath}/clearoffercache/${opco}/${cardNumber}`)
  },


  /**
   * Fetches donations history for the given card number.
   * @param {String} cardNumber - Required. Loyalty account to search
   * @param {Number} months - Number of months to display
   */
  async getDonationsHistory({ cardNumber }) {
    const params = {
      months: 12
    }
    return ApiService.get(`/apis/rewards-history/v1/history/charity-donations/${cardNumber}`, { params })
  },


  /**
  * Loads a burn loyalty offer to the given loyalty account in exchange for points
  * @param {String} opco - Required. Valid for STSH, GNTL, GNTC, MRTN
  * @param {String} cardNumber - Required. Loyalty account to redeem offer towards
  * @param {String} userId - Required. User ID.
  * @param {String} offerId -  Required. Copient provided offer. Must be numbers only, no prefix e.g. COP_
  */
  async redeemSpecialOffer({
    opco, cardNumber, userId, offerId
  }) {
    const params = {
      ...offerId && { offerId }
    }
    const response = await ApiService
      .put(`${loyaltyOffersRedemptionPath}/limitedtimeoffer/${opco}/${userId}/${cardNumber}`, null, { params })
    if (response.status !== 200
      || response.data === undefined
      || response.data.status.code < 0) {
      throw new Error('Generic Error')
    }
    return response
  },
  /**
  * Submits request to convert flex points to grocery dollars program in Copient.
  * Response only indicates request was received, does not indicate the conversion was successful
  * @param {String} opco - Required. Valid for STSH, GNTL, GNTC, MRTN
  * @param {String} cardNumber - Required. Loyalty account to redeem points
  * @param {String} points -  Required. Points to convert. Valid in increments of 100 from 100 - 2000
  */
  async redeemGroceryDollars({
    opco, cardNumber, points,
  }) {
    const params = {
      ...points && { redemptionPoints: points }
    }
    const response = await ApiService
      .put(`${rewardsPath}/grocerydollars/${opco}/${cardNumber}`, null, { params })
    this.checkAccountLocked(response)
    if (response.status !== 200
      || response.data === undefined
      || response.data.status.code < 0) {
      throw new Error('Generic Error')
    }
    return response
  },
  /**
  * Submits request to convert flex points to gas savings in Excentus
  * Response only indicates request was received, does not indicate the conversion was successful
  * @param {String} opco - Required. Valid for STSH and GNTL only
  * @param {String} cardNumber - Required. Loyalty account to redeem points
  * @param {String} points -  Required. Points to convert. Valid in increments of 100 from 100 - 2000
  */
  async redeemGasSavings({
    opco, cardNumber, points
  }) {
    const params = {
      ...points && { redemptionPoints: points }
    }
    const response = await ApiService.put(`${rewardsPath}/gassavings/${opco}/${cardNumber}`, null, { params })
    this.checkAccountLocked(response)
    if (response.response
      && response.response.data.status.code === '-4') {
      throw new Error('Redemption temporarily disabled')
    } else if (response.status !== 200
      || response.data === undefined
      || response.data.status.code < 0) {
      throw new Error('Generic Error')
    }
    return response
  },

  /**
   * Redeems specified donation
   * @param {String} opco - Required. Valid for STSH and GNTL only
   * @param {String} cardNumber - Required. Loyalty account to redeem points
   * @param {String} points -  Required. Points to convert. Valid in increments of 100 from 100 - 2000
 */
  /* eslint-disable-next-line */
  async redeemDonation({
    opco, cardNumber, points
  }) {
    const params = {
      donationPoints: points
    }
    const response = await ApiService
      .post(`${rewardsPath}/loyaltydonations/${opco}/${cardNumber}`, null, { params })
    this.checkAccountLocked(response)
    if (response.status !== 200
      || response.data === undefined
      || response.data.status.code < 0) {
      throw new Error('Generic Error')
    }
    return response
  },

  /**
  *   Fetches the available programs for a given user
  *   Programs must be enabled on a per store level in the sub-club database
  *   @param {String} cardNumber - Required. Loyalty account to fetch balances for
  *   @param {String} storeNumber - Required. Store number, must be four digits long, add leading zeroes
  *   @param {Boolean} details - Return a breakdown of points along with total amount
  */
  async getCopientBalances(cardNumber, storeNumber) {
    const params = {
      ...storeNumber && { storeNumber },
      details: true
    }
    if (!cardNumber) throw new Error('Missing Card Number')
    const response = await ApiService.get(`${balancesPath}/balances/${cardNumber}`, { params })
    if (response.status !== 200
      || response.data === undefined) {
      throw new Error('Generic Error')
    }
    return response
  },
  /**
   *   Fetches the available programs for a given user
   *   Programs must be enabled on a per store level in the sub-club database
   *   @param {String} cardNumber - Required. Loyalty account to fetch balances for
   *   @param {String} opco - Required. Brands
   */
  async getCopientBalancesV2(cardNumber, opco) {
    const params = {
      details: true
    }
    if (!cardNumber) throw new Error('Missing Card Number')
    const response = await ApiService.get(`${balancesPathV2}/balances/${opco}/${cardNumber}`, { params })
    if (response.status !== 200
      || response.data === undefined) {
      throw new Error('Generic Error')
    }
    return response
  },
  /**
  *   Fetches the available gas savings for a given user at a given store
  *   @param {String} cardNumber - Required. Loyalty account to fetch balances for
  *   @param {String} storeNumber - Required. Store number, must be four digits long, add leading zeroes
  *   @param {Boolean} details - Return a breakdown of points along with total
  */
  async getExcentusBalance(cardNumber, storeNumber) {
    const params = {
      ...storeNumber && { storeNumber },
      details: true
    }
    const response = await ApiService.get(`${balancesPath}/gas/points/${cardNumber}`, { params })
    if (response.status !== 200
      || response.data === undefined) {
      throw new Error('Generic Error')
    }
    return response
  },
  checkAccountLocked(response) {
    if (response.response
      && response.response.status === 412
      && response.response.data.status.code === '-3') {
      throw new Error('Account is locked')
    }
  }
}
