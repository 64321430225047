<template>
  <div
    class="pill-radio"
  >
    <div class="pill-radio_row">
      <PdlTabList
        v-if="enableTipOptions"
        :tabs="tabs"
        :default-tab="defaultSelection"
        :loading="loading"
        :full-width="fullWidth"
        :flex="flex"
        :can-deselect="canDeselect"
        :tab-index-stay-zero="tabIndexStayZero"
        :tab-shrink="true"
        :is-primary-alt="isPrimaryAlt"
        :is-mobile="isMobile"
        @tab-change="onSelection"
      >
        <PdlTab
          v-for="(item, index) in items"
          :id="`pillradio-option-${index}`"
          :key="index"
          :name="item.name"
          :value="item.value"
          :selected="item.isActive"
          :loading="loading"
          @tab-mounted="tabMounted"
        />
      </PdlTabList>
    </div>
    <div
      v-if="showInput"
      class="pill-radio_row"
    >
      <PdlIncrementInput
        :input-id="inputId"
        :model="model"
        :maximum="maximum"
        :max-length="maxLength"
        :use-decimal="useDecimal"
        :loading="loading"
        :show-dollar-prefix="true"
        :is-enabled="showInput"
        :show-save="showSave"
        @update="onUpdate"
        @save="onSave"
      />
    </div>
  </div>
</template>

<script>

import PdlIncrementInput from '@/shared/PdlIncrementInput'
import PdlTabList from '@/shared/tabs/PdlTabList'
import PdlTab from '@/shared/tabs/PdlTab'

export default {
  components: {
    PdlTabList,
    PdlTab,
    PdlIncrementInput
  },
  props: {
    model: {
      type: [Number, String],
      default: 0,
      validator: value => (_.isNumber(value) || (_.isString(value) && !Number.isNaN(value)))
      // string must be number, but sometimes we need to use a String for double decimals
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      required: true
    },
    defaultSelection: {
      type: Number,
      default: 0
    },
    minimum: {
      type: Number,
      default: 0
    },
    maximum: {
      type: Number,
      default: 999
    },
    maxLength: {
      type: Number,
      default: 6
    },
    applyValue: {
      type: Boolean,
      default: true
    },
    showInput: {
      type: Boolean,
      default: true
    },
    useDecimal: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    flex: {
      type: Boolean,
      default: false
    },
    canDeselect: {
      type: Boolean,
      default: false
    },
    tabIndexStayZero: {
      type: Boolean,
      default: false
    },
    isPrimaryAlt: {
      type: Boolean,
      default: false
    },
    enableTipOptions: {
      type: Boolean,
      default: true
    },
    inputId: {
      type: String,
      default: ''
    },
    showSave: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    debounce: null,
    tabs: []
  }),
  watch: {
    model(m) {
      this.value = m
    }
  },
  methods: {
    setValue(v) {
      if (this.applyValue) {
        this.value = v
      }
    },
    onUpdate(v) {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        if (this.showInput) {
          this.setValue(v)
          this.$emit('update', v)
        }
      }, 500)
    },
    onSave(v) {
      // prevent double events when clicking outside of input field
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        if (this.showInput) {
          this.setValue(v)
          this.$emit('save', v)
        }
      }, 500)
    },
    onSelection(v) {
      this.setValue(v)
      this.$emit('select-change', v)
    },
    tabMounted(tab) {
      this.tabs.push(tab)
    },
  }
}
</script>
