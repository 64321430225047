import { modules as allModules } from '@/store'
/**
 * Turns a state property name into a mutation name in the pattern: 'name' --> 'setName'
 *
 * @param name - the name of the state property
 * @returns {string} - the name of the mutation
 */
export const createMutationName = name => `set${name.charAt(0).toUpperCase()}${name.slice(1)}`

/**
 * Creates a mutations object from a state object. The mutations object will contain one mutation for each property in
 * the given object. For example:
 *
 * // Input
 * { name: 'Jeff', age: 20 }
 *
 * // Output
 * {
 *   setName: (state, payload) => state.name = payload,
 *   setAge: (state, payload) => state.age = payload
 * }
 *
 * @type {object} - the generated mutations object, suitable for injection into a Vuex module
 */
export const createSetterMutations = state => Object
  .entries(state)
  .reduce((accum, [key]) => ({
    ...accum,
    [createMutationName(key)]: (_state, payload) => {
      _state[key] = payload
    }
  }), {})

/**
 * Resets the named state properties to their original values.
 *
 * @param names - the names of the properties to set
 */
export const createReset = (names, initialState) => ({ commit }) => {
  names.forEach((name) => {
    commit(createMutationName(name), initialState[name])
  })
}

export function dispatchActionForAllModules(
  actionName,
  { modules = allModules, modulePrefix = '', flags = {} } = {}
) {
  // For every module...
  Object.keys(modules).forEach((moduleName) => {
    const moduleDefinition = modules[moduleName]
    if (moduleDefinition.actions?.[actionName]) {
      // Dispatch the action if the module is namespaced. Otherwise,
      // set a flag to dispatch the action globally at the end.
      if (moduleDefinition.namespaced) {
        window.sharedVue.config.globalProperties.$store.dispatch(`${modulePrefix}${moduleName}/${actionName}`)
      } else {
        flags.dispatchGlobal = true
      }
    }
  })

  // If this is the root and at least one non-namespaced module
  // was found with the action...
  if (!modulePrefix && flags.dispatchGlobal) {
    // Dispatch the action globally.
    window.sharedVue.config.globalProperties.$store.dispatch(actionName)
  }
}
