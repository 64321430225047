<template>
  <div
    ref="shoppingModeWrapper"
    class="ml-auto flex text-sm"
    :class="[
      {'pr-8 pb-1': isDesktop,
      'pdl-shopping-mode-tile-pair pt-0.5 w-full border-t border-gray-100': !isDesktop,
      'h-28': isMobileMenu
      }
    ]"
  >
    <div
      class="flex w-full"
    >
      <div
        class="flex"
        :class="{
          'w-full px-6 py-5 bg-white': !isDesktop,
          'flex-col': mobileHomePage
        }"
      >
        <div
          class="flex items-center"
          :class="{'mt-2' : isDesktop && opco !== 'STSH'}"
        >
          <PdlIconBase
            container-class="shopping-mode-icon"
            icon-title="shopping-mode-icon"
            :icon-class="{
              'w-10 h-10 vector-icon-color--darkest-grey': true,
              'mt-2': opco === 'STSH' || opco === 'FDLN' || serviceType === 'D',
            }"
            :vb-width="32"
            :vb-height="32"
          >
            <PdlIconInstoreGntc v-if="serviceType === 'B' && opco === 'GNTC'" />
            <PdlIconPickupGntc v-if="serviceType === 'P' && opco === 'GNTC'" />
            <PdlIconDeliveryGntc v-if="serviceType === 'D' && opco === 'GNTC'" />
            <PdlIconInstoreGntl v-if="serviceType === 'B' && opco === 'GNTL'" />
            <PdlIconPickupGntl v-if="serviceType === 'P' && opco === 'GNTL'" />
            <PdlIconDeliveryGntl v-if="serviceType === 'D' && opco === 'GNTL'" />
            <PdlIconInstoreStsh
              v-if="serviceType === 'B' && (opco === 'STSH' || opco === 'PPOD' || opco === 'FDLN')"
            />
            <PdlIconPickupStsh
              v-if="serviceType === 'P' && (opco === 'STSH' || opco === 'PPOD' || opco === 'FDLN')"
            />
            <PdlIconDeliveryStsh
              v-if="serviceType === 'D' && (opco === 'STSH' || opco === 'PPOD' || opco === 'FDLN')"
            />
            <PdlIconInstoreMrtn v-if="serviceType === 'B' && opco === 'MRTN'" />
            <PdlIconPickupMrtn v-if="serviceType === 'P' && opco === 'MRTN'" />
            <PdlIconDeliveryMrtn v-if="serviceType === 'D' && opco === 'MRTN'" />
          </PdlIconBase>
          <div class="flex items-baseline">
            <button
              v-if="isBrowseOnly"
              :class="['mb-0.5 robot-shopping-mode-type font-bold',
                mobileHomePage ? 'pl-4' : 'pl-2'
              ]"
              :disabled="unataExperience"
              @click.prevent.stop="emitAction('set-order-type')">
                <strong
                  ref="method-select_shoppingMode"
                >Browsing</strong>
            </button>
            <button
              v-else
              ref="shoppingModeType"
              :class="[' robot-shopping-mode-type font-bold',
                mobileHomePage ? 'pl-4' : 'pl-2',
                {'align-middle': isMobileMenu}
              ]"
              aria-haspopup="dialog"
              data-opens-modal="true"
              @click.prevent="emitAction('set-order-type')"
            >
              <strong
                ref="method-select_shoppingMode"
              >{{ displayText.shoppingMode }}</strong>
            </button>
            <button
            v-if="serviceType === 'D' && (isFerryPickupLocation || isFireIsland)"
              class="pl-2 pr-2"
              :class="{ 'align-middle' : isMobileMenu }"
              @click.prevent.stop="emitAction('set-order-type')"
              ref="SetOrderTypeA"
            >
              -
            </button>
            <span
              v-else
              class="pl-2"
              @click.prevent.stop="emitAction('set-order-type')"
              @keyup.enter.space="emitAction('set-order-type')"
              ref="SetOrderTypeB"
            >
              {{  displayText.preposition  }}
            </span>
            <button
              v-if="serviceType === 'D' && (isFerryPickupLocation || isFireIsland)"
                    class="flex"
                    :class="{
                        'align-middle': isMobileMenu
                      }"
                    @click.prevent.stop="emitAction('set-order-type')"
            >
              Confirm Address
              <span
                v-if="!isDesktop"
              >
                <PdlIconBase
                  icon-title="Arrow Icon"
                  container-class="h-8 w-3.5 ml-4"
                  iconClass="overflow-visible"
                  :vb-width="10"
                  :vb-height="11"
                >
                  <PdlIconCarrotRightRound
                    stroke-class="stroke-[#242021]"
                  />
                </PdlIconBase>
              </span>
            </button>
                <!-- 'pt-2': mobileHomePage, -->
            <button
              v-else
              class="robot-shopping-mode-location pl-2 flex no-wrap items-baseline"
              :class="{
                'pt-0': isMobileMenu
              }"
              :style="mobileMethodButtonMaxWidthStyle"
              aria-haspopup="dialog"
              data-opens-modal="true"
              @click.prevent="emitAction('set-order-location')"
              ref="SetOrderLocation"
            >
              <strong
                :class="['leading-1', {
                  'truncate text-ellipsis': isMobile
                }]"
              >
                {{ truncateAddress }}
              </strong>
              <span
                v-if="showZipCode"
                class="leading-1"
              >
                , {{ zipcode }}
              </span>
              <span
                v-if="mobileHomePage || (isMobileMenu && serviceType === 'B')"
              >
                <PdlIconBase
                  icon-title="Arrow Icon"
                  container-class="w-3.5 ml-4"
                  iconClass="overflow-visible"
                  :vb-width="10"
                  :vb-height="11"
                >
                  <PdlIconCarrotRightRound
                    stroke-class="stroke-[#242021]"
                  />
                </PdlIconBase>
              </span>
            </button>
          </div>
        </div>
        <div
          v-if="serviceType !== 'B'"
          class="flex items-center"
          :class="{
            'mt-4 pb-4': !isDesktop,
            'ml-11': mobileHomePage,
            'pt-2': isDesktop && opco !== 'STSH'
          }"
        >
          <span
            v-if="isDesktop || isMobileMenu"
            class="pl-3"
          >|</span>
          <button
            v-if="displayTime"
            class="flex pl-3 items-baseline"
            @click.prevent="emitAction(action.name)"
          >
            <span
              ref="method-select_timeslot"
              class="leading-1"
            >
              {{ computedOrderWeekday }}{{ orderDayOfMonth }}, {{ truncateTime }}
            </span>
            <span
              v-if="!isDesktop"
            >
              <PdlIconBase
                icon-title="Arrow Icon"
                :container-class="[
                  'w-3.5',
                  isExtraSmallMobile ? 'ml-3' : 'ml-4'
                ]"
                iconClass="overflow-visible"
                :vb-width="10"
                :vb-height="11"
              >
                <PdlIconCarrotRightRound
                  :stroke-class="'stroke-[#242021]'"
                />
              </PdlIconBase>
            </span>
          </button>
          <button
            v-if="!hideRightTile"
            class="flex pl-3 robot-shopping-mode-slot align-middle items-center"
            :aria-hidden="!hideRightTile"
            aria-haspopup="dialog"
            data-opens-modal="true"
            @click.prevent="selectTime(action.name)"
          >
            <strong
              class="highlighted-text font-bold leading-1"
              ref="SelectTime"
            >
              {{ action.text }}
            </strong>
            <span
              v-if="!isDesktop && !displayTime"
            >
              <PdlIconBase
                icon-title="Arrow Icon"
                :container-class="[
                  'h-8 w-3.5 flex',
                  isExtraSmallMobile ? 'ml-3' : 'ml-4'
                ]"
                :vb-width="10"
                :vb-height="11"
              >
                <PdlIconCarrotRightRound
                  :stroke-class="'stroke-primary'"
                />
              </PdlIconBase>
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import {
  mapActions, mapGetters, mapState, mapMutations
} from 'vuex'
import truncate from 'utils/filters/truncate'
import timeSlotTrim15 from 'utils/filters/timeSlotTrim15'
import { GTM_GA4, ORDER_SERVICE_TYPES } from 'utils/constants'
import PdlIconCarrotRightRound from 'components/icons/icon/PdlIconCarrotRightRound'
import parseDateTime from 'utils/filters/parseDateTime'
import { nonSpectrumBrowseOnly, nonSpectrumHideRightTile } from 'utils/StoreTransition'
import optimizelyFullstack from 'components/user-research/services/optimizely-fullstack-service'
import trackNavigation from 'utils/tracking/nav/trackNavigation'
import generateSiteLocation from 'utils/lib/generateSiteLocation'
import { checkFireIsland } from 'utils/FireIslands'

export default {
  components: {
    PdlIconInstoreStsh: defineAsyncComponent(() => import(/* webpackChunkName: 'icons' */
      'components/icons/icon/PdlIconInstoreStsh'
    )),
    PdlIconDeliveryStsh: defineAsyncComponent(() => import(/* webpackChunkName: 'icons' */
      'components/icons/icon/PdlIconDeliveryStsh'
    )),
    PdlIconPickupStsh: defineAsyncComponent(() => import(/* webpackChunkName: 'icons' */
      'components/icons/icon/PdlIconPickupStsh'
    )),
    PdlIconInstoreGntl: defineAsyncComponent(() => import(/* webpackChunkName: 'icons' */
      'components/icons/icon/PdlIconInstoreGntl'
    )),
    PdlIconDeliveryGntl: defineAsyncComponent(() => import(/* webpackChunkName: 'icons' */
      'components/icons/icon/PdlIconDeliveryGntl'
    )),
    PdlIconPickupGntl: defineAsyncComponent(() => import(/* webpackChunkName: 'icons' */
      'components/icons/icon/PdlIconPickupGntl'
    )),
    PdlIconInstoreMrtn: defineAsyncComponent(() => import(/* webpackChunkName: 'icons' */
      'components/icons/icon/PdlIconInstoreMrtn'
    )),
    PdlIconDeliveryMrtn: defineAsyncComponent(() => import(/* webpackChunkName: 'icons' */
      'components/icons/icon/PdlIconDeliveryMrtn'
    )),
    PdlIconPickupMrtn: defineAsyncComponent(() => import(/* webpackChunkName: 'icons' */
      'components/icons/icon/PdlIconPickupMrtn'
    )),
    PdlIconInstoreGntc: defineAsyncComponent(() => import(/* webpackChunkName: 'icons' */
      'components/icons/icon/PdlIconInstoreGntc'
    )),
    PdlIconDeliveryGntc: defineAsyncComponent(() => import(/* webpackChunkName: 'icons' */
      'components/icons/icon/PdlIconDeliveryGntc'
    )),
    PdlIconPickupGntc: defineAsyncComponent(() => import(/* webpackChunkName: 'icons' */
      'components/icons/icon/PdlIconPickupGntc'
    )),
    PdlIconCarrotRightRound
  },
  props: {
    isMobileMenu: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState({
      lockersEnabledInStore: state => state.PickupLockers.lockersEnabledInStore,
      orderEndTimestamp: state => state.ShoppingMode.orderEndTimestamp,
      orderStartTimestamp: state => state.ShoppingMode.orderStartTimestamp,
      pickupName: state => state.ShoppingMode.pickupAddress.name,
      orderWeekday: state => state.ShoppingMode.orderWeekday,
      orderDayOfMonth: state => state.ShoppingMode.orderDayOfMonth
    }),
    ...mapGetters({
      lockerDescriptor: 'PickupLockers/lockerDescriptor',
      varByName: 'SiteConfig/varByName',
      resourceByName: 'SiteConfig/resourceByName',
      transitionUnstarted: 'FoodLionTransition/transitionUnstarted'
    }),
    ...mapGetters('UserProfile', [
      'userInfo',
      'serviceLocationId',
      'deliveryServiceLocation',
      'unataExperience'
    ]),
    ...mapGetters('ShoppingMode', {
      browseAddressDisplay: 'browseAddressDisplay',
      deliveryAddressText: 'deliveryAddressText',
      pickupStreetAddress: 'pickupStreetAddress',
      zipcode: 'zipcode',
    }),
    ...mapGetters('SiteConfig', {
      opco: 'opco',
      resourceByName: 'resourceByName'
    }),
    ...mapGetters('FoodLionTransition', {
      transitionCompletedOrStoreIsP3: 'transitionCompletedOrStoreIsP3'
    }),
    ...mapGetters('ScreenSize', {
      isDesktop: 'isDesktop',
      isDesktopSmall: 'isDesktopSmall',
      isTablet: 'isTablet',
      isMobile: 'isMobile',
      isExtraSmallMobile: 'isExtraSmallMobile',
      isExtraExtraSmallMobile: 'isExtraExtraSmallMobile'
    }),
    /**
     * value returned will be 1 of 3:
     *
     * "D" - Delivery
     * "P" - Pickup
     * "B" - Browse (In-Store)
     */
    isBrowseOnly() {
      return nonSpectrumBrowseOnly()
    },
    hideRightTile() {
      if (this.serviceType === 'B') {
        return true
      }
      return nonSpectrumHideRightTile()
    },
    serviceType() {
      return this.deliveryServiceLocation.serviceType
    },
    serviceLocationId() {
      return this.deliveryServiceLocation.serviceLocationId
    },
    addressDisplay() {
      let addressDisp = ''
      switch (this.serviceType) {
        case 'D':
          addressDisp = this.deliveryAddressText
          break
        case 'P':
          addressDisp = (this.isFerryPickupLocation || this.isFireIsland) ? this.pickupName : this.pickupStreetAddress
          break
        case 'B':
          addressDisp = this.browseAddressDisplay
          break
        default:
          break
      }
      return addressDisp
    },
    truncateAddress() {
      if (this.isDesktop) {
        return truncate(this.addressDisplay, !this.isDesktopSmall ? 18 : 16, true)
      }
      if (this.isMobileMenu) {
        return truncate(this.addressDisplay, 14, true)
      }
      if (this.isMobileMenu) {
        return truncate(this.addressDisplay, !this.isExtraSmallMobile ? 16 : 10, true)
      }
      return this.addressDisplay
    },
    truncateTime() {
      if (this.isMobileMenu) {
        return truncate(this.orderTimeRange, !this.isExtraSmallMobile ? 10 : 5, true)
      }
      return this.orderTimeRange
    },
    orderTimeRange() {
      const startTime = this.orderStartTimestamp
      let endTime = this.orderEndTimestamp
      if (!startTime || !endTime) return ''
      if (this.lockersEnabledInStore) endTime = timeSlotTrim15(endTime)
      return `${this.formatTimeToDisplay(startTime)} - ${this.formatTimeToDisplay(endTime)}`
    },
    displayText() {
      const textObj = { shoppingMode: null, preposition: null }

      // Note: lockersEnabledInStore takes into account the service type,
      // so no need to evaluate it in the switch statement
      if (this.lockersEnabledInStore) {
        textObj.shoppingMode = this.isMobileMenu
          ? this.lockerDescriptor : `${this.lockerDescriptor} Pickup`
        textObj.preposition = 'at'
        return textObj
      }
      switch (this.serviceType) {
        case 'D':
          textObj.shoppingMode = 'Delivery'
          textObj.preposition = 'to'
          break
        case 'P':
          textObj.shoppingMode = 'Pickup'
          textObj.preposition = 'at'
          break
        case 'B':
          textObj.shoppingMode = 'In-Store'
          textObj.preposition = 'at'
          break
        default:
          break
      }
      return textObj
    },
    /**
     * @computed
     * @function displayTime
     *
     * Allows the timestring to be displayed if one has been passed
     * down and the user is not in browse mode.
     *
     * @returns {boolean}
     */
    displayTime() {
      return this.serviceType !== 'B' && this.validTimeString
    },
    /**
     * @computed
     * @function validTimeString
     *
     *
     * determines if a usable timestring has been passed
     * into the component or not which can effect logic/text
     * in various places.
     *
     * @returns {boolean}
     */
    validTimeString() {
      return (
        typeof this.orderTimeRange === 'string'
        && this.orderTimeRange.length > 0
      )
    },
    /**
     * @computed
     * @function action
     *
     * For the far-most button the text & action can change depending on what
     * mode etc the user is currently in.  This provides an object to handle
     * all of those unique cases.
     *
     * @returns {object}
     */
    action() {
      if (this.serviceType === 'B') {
        return {
          text: '',
          name: 'set-order-type-no-browse',
        }
      }
      return {
        text: this.validTimeString ? '' : 'Select a Time',
        name: 'time',
      }
    },
    displayActionButton() {
      return this.serviceType !== 'B' && !(!this.isDesktop && this.displayTime)
    },
    isFerryPickupLocation() {
      const dropFerryLocations = this.resourceByName('DROP_FERRY_LOCATIONS')
      return !!(dropFerryLocations?.includes(this.zipcode))
    },
    isDelivery() {
      return this.serviceType === ORDER_SERVICE_TYPES.DELIVERY
    },
    mobileMethodButtonMaxWidthStyle() {
      if (!this.isMobile || this.serviceType === 'D') {
        return {}
      }
      let maxwidthPercentage = this.lockersEnabledInStore ? 58 : 69
      if (this.isExtraExtraSmallMobile) {
        maxwidthPercentage = this.lockersEnabledInStore ? 46 : 61
      } else if (this.isExtraSmallMobile && !this.isExtraExtraSmallMobile) {
        maxwidthPercentage = this.lockersEnabledInStore ? 53 : 66
      }
      return { 'max-width': `${maxwidthPercentage}%` }
    },
    isFireIsland() {
      const isFerryMainland = window.localStorage.getItem('Mainland')
      return checkFireIsland(this.zipcode) && !isFerryMainland
    },
    showZipCode() {
      return this.serviceType === 'B' || (this.serviceType === 'P' && (!this.isDesktopSmall && this.isDesktop))
    },
    computedOrderWeekday() {
      return this.serviceType === 'P' && (this.isDesktopSmall || !this.isDesktop) ? '' : `${this.orderWeekday} `
    },
    mobileHomePage() {
      return !this.isDesktop && !this.isMobileMenu
    }
  },
  mounted() {
    // allow widget a moment to populate with content before proceeding
    setTimeout(() => {
      const templateWidth = this.calculateElementWidth(
        this.$refs.shoppingModeWrapper
      )
      const shoppingModeWidth = this.calculateElementWidth(
        this.$refs.shoppingModeType
      )
      if (!Number.isNaN(templateWidth) && !Number.isNaN(shoppingModeWidth)) {
        this.setWidgetWidth({
          template: templateWidth,
          shopping_mode: shoppingModeWidth,
        })
      }
    }, 100)
  },
  methods: {
    ...mapActions('ShoppingMode', {
      optionClick: 'optionClick',
      setWidgetWidth: 'setWidgetWidth',
    }),
    ...mapActions('Modals', {
      setModalId: 'setModalId',
    }),
    ...mapMutations({
      setCurrentView: 'ServiceSelection/setCurrentView',
      setFerryTransition: 'UserProfile/setFerryTransition',
      setFireIslandTransition: 'UserProfile/setFireIslandTransition',
    }),
    calculateElementWidth(el) {
      return el ? Math.round(el.getBoundingClientRect().width) : 0
    },
    cleanTrackingText(text) {
      return text
        .replace(/\s+/g, ' ')
        .replace(/\\n/g, '')
        .replace(/\s+,/g, ',')
    },
    emitAction(actionName) {
      const tracking = {
        type: 'primary header',
        linkText: '',
        siteLocation: generateSiteLocation(this.$route)
      }
      if (actionName === 'set-order-type') {
        const shoppingMode = this.displayText?.shoppingMode
        if (shoppingMode) {
          this.$trackGA4Event(GTM_GA4.ecommerce, {
            action: 'shopping method modal loaded',
            type: shoppingMode.toLowerCase(),
          })
        }
        const a = this.$refs['method-select_shoppingMode']?.innerText || ''
        const b = this.$refs.shoppingModeType?.innerText || ''
        const c = this.$refs.SetOrderTypeA?.innerText || ''
        const d = this.$refs.SetOrderTypeB?.innerText || ''
        tracking.linkText = ((a === b) ? a : (a + b)) + c + d
      }
      if (actionName === 'set-order-location') {
        tracking.linkText = this.$refs.SetOrderLocation?.innerText || ''
      }
      if (actionName === 'time') {
        tracking.linkText = this.$refs['method-select_timeslot']?.innerText || ''
      }
      tracking.linkText = this.cleanTrackingText(tracking.linkText)
      trackNavigation(tracking)
      optimizelyFullstack.trackEvent('Method Selector Navigation')
      this.$trackClientLog('select_shopping_mode', {
        serviceType: this.serviceType,
        tileMode: actionName,
      })
      if (this.transitionUnstarted) {
        this.setCurrentView('B')
        this.$store.commit('Modals/setActiveModal', {
          fileName: 'PdlServiceSelectionModal',
        })
      } else {
        this.optionClick({
          serviceType: this.serviceType,
          tileMode: actionName,
        })
        this.setModalId(actionName)
      }
    },
    selectTime(actionName) {
      this.$trackClientLog('select_shopping_mode', {
        serviceType: this.serviceType,
        tileMode: actionName,
      })
      trackNavigation({
        type: 'primary header',
        linkText: this.cleanTrackingText(this.$refs.SelectTime?.innerText),
        siteLocation: generateSiteLocation(this.$route)
      })
      if (this.serviceType === 'D' && (this.isFerryPickupLocation || this.isFireIsland)) {
        this.setCurrentView('D')
        this.setFerryTransition(this.isFerryPickupLocation)
        this.setFireIslandTransition(this.isFireIsland)
        this.$store.commit('Modals/setActiveModal', {
          fileName: 'PdlServiceSelectionModal',
        })
      } else {
        this.optionClick({
          serviceType: this.serviceType,
          tileMode: actionName,
        })
        this.setModalId(actionName)
      }
    },
    truncate(txt, limit, addEllipses) {
      return truncate(txt, limit, addEllipses)
    },
    formatTimeToDisplay(time) {
      if (!time) return ''
      const parsedTime = parseDateTime(time)
      const originalTime = new Date(parsedTime)
      let hours = originalTime.getHours()
      const minutes = originalTime.getMinutes()
      const ampm = hours >= 12 ? ' PM' : ' AM'
      hours %= 12
      hours = hours || 12 // the hour '0' should be '12'
      const timeToDisplay = minutes > 0 ? `${hours}:${minutes}` : `${hours}`
      return `${timeToDisplay} ${ampm}`
    }
  },
}
</script>
