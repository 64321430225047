// eslint-disable-line import/prefer-default-export
export default 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lc'
  + 'mF0ZWQgYnkgSWNvTW9vbi5pbyAtLT4KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5'
  + 'vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4KPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL'
  + '3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHdpZHRoPSI0OCIgaGVpZ2h0PSI0OCIgdmlld0JveD0iMCAwIDQ'
  + '4IDQ4Ij4KPGcgaWQ9Imljb21vb24taWdub3JlIj4KCTxsaW5lIHN0cm9rZS13aWR0aD0iMSIgeDE9IiIgeTE9IiIgeDI9IiIgeTI9IiIgc3Ryb2tlP'
  + 'SIjNDQ5RkRCIiBvcGFjaXR5PSIiPjwvbGluZT4KPC9nPgoJPHBhdGggZD0iTTM4LjQ4IDkuMDcwYzAuMjQ4IDAgMC40NSAwLjIwMiAwLjQ1IDAuNDV'
  + '2MjguOTU5YzAgMC4yNDgtMC4yMDIgMC40NS0wLjQ1IDAuNDVoLTI4Ljk1OWMtMC4yNDggMC0wLjQ1LTAuMjAyLTAuNDUtMC40NXYtMjguOTU5YzAtM'
  + 'C4yNDggMC4yMDItMC40NSAwLjQ1LTAuNDVoMjguOTU5ek0zOC40OCA4aC0yOC45NTljLTAuODQgMC0xLjUyIDAuNjgxLTEuNTIgMS41MjF2MjguOTU'
  + '5YzAgMC44NCAwLjY4MSAxLjUyMSAxLjUyIDEuNTIxaDI4Ljk1OWMwLjg0IDAgMS41Mi0wLjY4MSAxLjUyLTEuNTIxdi0yOC45NTljMC0wLjg0LTAuN'
  + 'jgxLTEuNTIxLTEuNTItMS41MjF2MHoiIG9wYWNpdHk9IjEiIHZpc2liaWxpdHk9ImZhbHNlIiBmaWxsPSIjZDdkN2Q3Ij48L3BhdGg+Cgk8cGF0aCB'
  + 'kPSJNMTIuMjI4IDM1LjIyNHYtMy4zMjljMC0wLjE0NSAwLjA1OC0wLjI4NSAwLjE2LTAuMzg4bDUuNTg3LTUuNTg3YzAuMjE0LTAuMjE0IDAuNTYxL'
  + 'TAuMjE0IDAuNzc1IDBsMi4xMTkgMi4xMTljMC4yMTQgMC4yMTQgMC41NjEgMC4yMTQgMC43NzUgMGw4LjE5MS04LjE5MmMwLjIxNC0wLjIxNCAwLjU'
  + '2MS0wLjIxNCAwLjc3NSAwbDUgNWMwLjEwMyAwLjEwMyAwLjE2IDAuMjQyIDAuMTYgMC4zODh2OS45ODhjMCAwLjMwMy0wLjI0NSAwLjU0OC0wLjU0O'
  + 'CAwLjU0OGgtMjIuNDQ3Yy0wLjMwMyAwLTAuNTQ4LTAuMjQ1LTAuNTQ4LTAuNTQ4eiIgb3BhY2l0eT0iMSIgdmlzaWJpbGl0eT0iZmFsc2UiIGZpbGw'
  + '9IiNkN2Q3ZDciPjwvcGF0aD4KCTxwYXRoIGQ9Ik0yMS41NDQgMTcuOTY3YzAgMS45ODItMS42MDYgMy41ODgtMy41ODggMy41ODhzLTMuNTg4LTEuN'
  + 'jA2LTMuNTg4LTMuNTg4YzAtMS45ODEgMS42MDYtMy41ODggMy41ODgtMy41ODhzMy41ODggMS42MDYgMy41ODggMy41ODh6IiBvcGFjaXR5PSIxIiB'
  + '2aXNpYmlsaXR5PSJmYWxzZSIgZmlsbD0iI2Q3ZDdkNyI+PC9wYXRoPgo8L3N2Zz4K'
