import ApiService from '@/api/'

let messageQueue = []
let isFlushingInProgress = false

const configData = {
  flushInterval: 1500,
  maxQueueSize: 50,
  maxMessageCharCount: 1500,
  logDomain: '',
  logKey: ''
}

const configValues = (data) => {
  if (data?.flushInterval) {
    configData.flushInterval = parseInt(data?.flushInterval, 10)
  }
  if (data?.maxQueueSize) {
    configData.maxQueueSize = parseInt(data?.maxQueueSize, 10)
  }
  if (data?.maxMessageCharCount) {
    configData.maxMessageCharCount = parseInt(data?.maxMessageCharCount, 10)
  }
  configData.logDomain = data?.logDomain
  configData.logKey = data?.logKey
}

const queue = (msg) => {
  if (msg.length <= configData.maxMessageCharCount) {
    if (messageQueue.length >= configData.maxQueueSize) {
      messageQueue.shift()
    }
    if (messageQueue.length < configData.maxQueueSize) {
      messageQueue.push(msg)
    }
  }
}

const flushQueue = () => {
  if (isFlushingInProgress) {
    return
  }

  if (messageQueue.length) {
    isFlushingInProgress = true
    const pending = messageQueue
    messageQueue = []
    if (configData.logDomain && configData.logKey) {
      ApiService.post(`${configData.logDomain}`, { messages: pending }, {
        headers: {
          'Ocp-Apim-Subscription-Key': configData.logKey
        }
      })
    } else {
      /* eslint-disable no-console */
      console.warn('Client log microservice API was not called: missing site vars')
    }
    isFlushingInProgress = false
  }
  setTimeout(flushQueue, configData.flushInterval)
}

setTimeout(flushQueue, configData.flushInterval)

export default {
  queue,
  configValues
}
