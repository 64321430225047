import ApiService from '@/api/'

export default {
  get(userId) {
    return ApiService.get(`api/v1.0/user/${userId}/health-preferences`)
  },
  put(payload, userId) {
    return ApiService.put(`api/v1.0/user/${userId}/health-preferences`, payload)
  }
}
