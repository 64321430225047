<template>
  <div
    :class="[
      'menu',
      size ? 'menu--' + size : '',
      { 'menu--with-sidebar': showSidebar }
    ]"
  >
    <div
      v-if="showSidebar && sidebarPosition === 'left'"
      class="menu_sidebar menu_sidebar--left"
    >
      <slot name="sidebar" />
    </div>
    <div class="menu_main-content">
      <slot name="nav" />
    </div>
    <aside
      v-if="showSidebar && sidebarPosition === 'right'"
      class="menu_sidebar"
    >
      <slot name="sidebar" />
    </aside>
  </div>
</template>

<script>
export default {
  props: {
    showSidebar: {
      type: Boolean,
      default: false
    },
    sidebarPosition: {
      type: String,
      default: 'right'
    },
    size: {
      type: String,
      default: 'small'
    }
  }
}
</script>
