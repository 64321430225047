/*
* @param text - String
* @param characterLimit - Number representing the number of characters to limit the string.
* @param addEllipses - Boolean to add ellipses in truncated string
*/
export default function (text, characterLimit, addEllipses = false) {
  if (text && text.length > characterLimit) {
    return text.substring(0, characterLimit) + (addEllipses ? '...' : '')
  }
  return text
}
