export default function (val, centformat = false, dropZeroes = false) {
  if (!val || Number.isNaN(Number(val))) {
    val = 0
  }
  if (centformat && parseFloat(val) > 0 && parseFloat(val) < 1) {
    return `${val * 100}¢`
  }

  if (dropZeroes && val % 1 === 0) {
    return `${val < 0 ? '-' : ''}$${Math.abs(parseFloat(val))}`
  }
  // account for negative values as inputs as well
  return `${val < 0 ? '-' : ''}$${Math.abs(parseFloat(val)).toFixed(2)}`
}
