import productCategoryAPI from 'api/productCategoryAPI'
import { mapSpyglassData } from 'utils/services/spyglass-content'
import reArrangeScheduledCategories from 'utils/filters/reArrangeScheduledCategories'

export default {
  namespaced: true,
  state: {
    categories: [],
    activeTopCategory: '',
    subCategories: [],
    categoryTreeId: '1',
    categoryId: '',
    subCategoryId: '1',
    subCategoryName: '',
    categoryBgImg: [],
    scheduledContentCategories: [],
    reArrangedScheduledContentCategories: [],
    scheduledContentSubCategories: [],
    leafCategory: {},
    globalHeaderCategory: '',
    activeTopCategoryTreeId: '',
    activeSubCategoryTreeId: ''
  },
  mutations: {
    setCategories(state, value) {
      state.categories = value
    },
    setScheduledContentCategories(state, value) {
      state.scheduledContentCategories = value
    },
    setReArrangedScheduledContentCategories(state, value) {
      state.reArrangedScheduledContentCategories = value
    },
    setActiveTopCategory(state, value) {
      state.activeTopCategory = value
    },
    setSubCategories(state, value) {
      state.subCategories = value
    },
    setScheduledContentSubCategories(state, value) {
      state.scheduledContentSubCategories = value
    },
    setCategoryTreeId(state, value) {
      state.categoryTreeId = value
    },
    setCategoryId(state, value) {
      state.categoryId = value
    },
    setSubCategoryId(state, value) {
      state.subCategoryId = value
    },
    setSubCategoryName(state, value) {
      state.subCategoryName = value
    },
    addCategoryBgImg(state, value) {
      state.categoryBgImg.push(value)
    },
    setLeafCategory(state, value) {
      state.leafCategory = value
    },
    setGlobalHeaderCategory(state, value) {
      state.globalHeaderCategory = value
    },
    setActiveTopCategoryTreeId(state, value) {
      state.activeTopCategoryTreeId = value
    },
    setActiveSubCategoryTreeId(state, value) {
      state.activeSubCategoryTreeId = value
    },
  },
  actions: {
    async fetchCategories({ commit, rootGetters, dispatch }) {
      const userInfo = rootGetters['UserProfile/userInfo']
      let serviceLocationId = rootGetters['UserProfile/serviceLocationId']
      if (!userInfo || !serviceLocationId) {
        await dispatch('UserProfile/initQueryUserProfile', null, { root: true })
        serviceLocationId = rootGetters['UserProfile/serviceLocationId']
      }
      const response = await productCategoryAPI.getCategories(serviceLocationId)
      try {
        if (response.status === 200 && response.data.response?.childCategories) {
          commit('setCategories', response.data.response.childCategories)
        }
      } catch (err) {
        commit('setCategories', [])
      }
    },
    async fetchSubCategories({ commit, rootGetters, dispatch }, categoryId) {
      const userInfo = rootGetters['UserProfile/userInfo']
      if (!userInfo) {
        await dispatch('UserProfile/initQueryUserProfile', null, { root: true })
      }
      const serviceLocationId = rootGetters['UserProfile/serviceLocationId']
      const response = await productCategoryAPI.getSubCategories(
        categoryId, serviceLocationId
      )
      try {
        if (response.status === 200 && response.data.response?.childCategories) {
          commit('setSubCategories', response.data.response.childCategories)
          commit('setLeafCategory', response.data.response.category)
          commit('setActiveTopCategoryTreeId', response.data.response.category.categoryTreeId)
        }
      } catch (err) {
        commit('setSubCategories', [])
      }
      return response
    },
    async fetchLowerCategories({ commit, rootGetters, dispatch }, categoryId) {
      let userInfo = rootGetters['UserProfile/userInfo']
      if (!userInfo) {
        await dispatch('UserProfile/initQueryUserProfile', null, { root: true })
        userInfo = rootGetters['UserProfile/userInfo']
      }
      const deliveryServiceLocation = rootGetters['UserProfile/deliveryServiceLocation']

      const { serviceLocationId } = deliveryServiceLocation
      const response = await productCategoryAPI.getSubCategories(
        categoryId, serviceLocationId
      )
      if (response.status === 200) {
        commit('setActiveSubCategoryTreeId', categoryId)
        return response?.data.response
      }
      return {}
    },
    async fetchScheduledContent({ commit, dispatch }) {
      const payload = {
        browse_aisles_top_category_bg_image: {
          limit: 25
        }
      }
      const results = await dispatch('ScheduledContent/queryRegion', {
        region: payload
      }, { root: true })
      const { regionContents } = results.response
      if (regionContents[0].contents.length) {
        // eslint-disable-next-line max-len
        let dataLayer
        const browseAislesBgImageContent = regionContents
          .filter(val => val.region === 'browse_aisles_top_category_bg_image')
        Object.keys(browseAislesBgImageContent[0].contents).forEach((key) => {
          dataLayer = browseAislesBgImageContent[0].contents[key].map
          const categoryName = browseAislesBgImageContent[0].contents[key].map['Browse Aisles Shelf Category']
          const spyGlassData = { parent: [] }
          spyGlassData.parent = mapSpyglassData(dataLayer)
          const bgImgObj = {
            categoryName,
            categoryIcon:
              browseAislesBgImageContent[0].contents[key].map.scheduledContentData.map['Content ID: Category Icon'],
            deskTop: browseAislesBgImageContent[0].contents[key].map.scheduledContentData.map['Content ID: Desktop'],
            mobile: browseAislesBgImageContent[0].contents[key].map.scheduledContentData.map['Content ID: Mobile'],
            spyGlassData
          }
          commit('addCategoryBgImg', bgImgObj)
        })
      }
    },
    async fetchScheduledContentCategories({ commit, dispatch }) {
      const payload = {
        browse_aisles_home_nav: {
          limit: 1
        }
      }
      const results = await dispatch('ScheduledContent/queryRegion', {
        region: payload
      }, { root: true })
      if (results?.response) {
        const { regionContents } = results.response
        if (regionContents[0].contents.length) {
          const categoriesList = []
          regionContents[0].contents[0].map.scheduledContentData.map.parts.myArrayList.forEach((item) => {
            categoriesList.push({
              categoryIcon: item.map['Content ID: Logo'],
              categoryName: item.map['Headline Copy'],
              categoryLink: item.map['Button Link'],
              categoryLinkType: item.map['Button Link Type'],
              cmsContentKey: item.map['Content Container URL Key'],
              spyGlassData: {
                scheduledContentKey: item.map.key,
                moduleType: item.map.moduleType,
                ...regionContents[0].contents[0].map
              },
              contentData: item.map,
              scheduledContentData: regionContents[0].contents[0].map
            })
            const bgImgSpyGlassData = { parent: [] }
            bgImgSpyGlassData.parent.push(
              { name: 'Scheduled Id', value: regionContents[0].contents[0].map.key },
              { name: 'Container Id', value: item.map.key },
              { name: 'Start Date', value: regionContents[0].contents[0].map['Start Date'] },
              { name: 'End Date', value: regionContents[0].contents[0].map['End Date'] },
              { name: 'Module Type', value: item.map.moduleType },
              { name: 'Site Location', value: regionContents[0].contents[0].map.Site_Location },
              { name: 'Customer Status', value: regionContents[0].contents[0].map['Customer Status'] },
              { name: 'Customer Type', value: regionContents[0].contents[0].map['Customer Type'] },
              {
                name: 'Device Type (Screen Size)',
                value: regionContents[0].contents[0].map['Device Type (Screen Size)']
              },
              { name: 'App Targeting', value: regionContents[0].contents[0].map['App Targeting'] }
            )
            const bgImgObj = {
              categoryName: item.map['Headline Copy'],
              deskTop: item.map['Content ID: Desktop'],
              mobile: item.map['Content ID: Mobile'],
              bgImgSpyGlassData
            }
            commit('addCategoryBgImg', bgImgObj)
          })
          const reOrderedCategoriesList = reArrangeScheduledCategories([...categoriesList])
          commit('setReArrangedScheduledContentCategories', reOrderedCategoriesList)
          commit('setScheduledContentCategories', categoriesList)
        } else {
          commit('setScheduledContentCategories', [])
        }
      } else {
        commit('setScheduledContentCategories', [])
      }
    },
    async fetchScheduledContentSubCategories({ commit, dispatch }, value) {
      if (!value) {
        return
      }
      const payload = {
        browse_aisles_top_category_nav: {
          limit: 1,
          ff: {
            'Browse Aisles Shelf Category Id': [value]
          }
        }
      }
      const results = await dispatch('ScheduledContent/queryRegion', {
        region: payload
      }, { root: true })
      const regionContents = results?.response?.regionContents
      if (regionContents?.[0]?.contents?.length) {
        const categoriesList = []
        regionContents[0].contents[0].map.scheduledContentData.map.parts.myArrayList.forEach((item) => {
          categoriesList.push({
            categoryIcon: item.map['Content ID: Logo'],
            categoryName: item.map['Headline Copy'],
            categoryLink: item.map['Button Link'],
            categoryLinkType: item.map['Button Link Type'],
            categoryId: item.map.id,
            categoryTreeId: item.map.id,
            cmsContentKey: item.map['Content Container URL Key'],
            spyGlassData: {
              scheduledContentKey: item.map.key,
              moduleType: item.map.moduleType,
              ...regionContents[0].contents[0].map
            },
            contentData: item.map,
            scheduledContentData: regionContents[0].contents[0].map
          })
        })
        commit('setScheduledContentSubCategories', categoriesList)
      } else {
        commit('setScheduledContentSubCategories', [])
      }
    }
  }
}
