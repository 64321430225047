import { isDefined } from 'utils/basic/ref-checkers'

export const isEnabled = () => (
  isDefined(window.DetectIphone)
  && isDefined(window.DetectIpod)
  && isDefined(window.DetectIpad)
  && isDefined(window.DetectAndroid)
  && isDefined(window.DetectPalmWebOS)
)

export const isIOSDevice = () => isEnabled() && (window.DetectIphone() || window.DetectIpod() || window.DetectIpad())

/**
 * Detects if the current device is an iOS device and coming from device browser
 *
 * @returns {boolean}
 */
export const isIOSBrowser = () => isIOSDevice() && ((/safari/i).test(window.navigator.userAgent))

/**
 * Detects if the current device is an IOS device and within a web view of an app (not in Safari)
 * Some reference:
 * https://stackoverflow.com/questions/2143763/does-uiwebview-
 * send-the-same-user-agent-in-the-request-headers-as-mobile-safari
 *
 * @returns {boolean}
 */
export const isIOSWebView = () => isIOSDevice() && !isIOSBrowser()

export const isAndroidDevice = () => isEnabled() && window.DetectAndroid()

export const isDesktopSafari = () => !isIOSDevice() && (/safari/i).test(window.navigator.sayswho || '')
