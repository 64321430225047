/* eslint-disable no-useless-catch */
import savingsHistoryAPI from 'api/SavingsHistoryAPI'

export default {
  namespaced: true,
  state: {
    historyItems: [],
    initialHistoryItems: [],
    historyTotal: 0,
    historySummary: {},
    historyYTDTotal: -1,
    dataLoaded: false,
    retrievalStart: 0,
    retrievalIncrement: 60,
    historyLoading: true,
    historyAPIError: false,
    filterSelection: 'YEAR_TO_DATE',
    filterOptions: [
      {
        title: 'Year-to-Date',
        value: 'YEAR_TO_DATE',
      },
      {
        title: 'Past 12 Months',
        value: 'PAST_YEAR',
      },
      {
        title: 'Past 30 Days',
        value: 'PAST_MONTH',
      },
      {
        title: 'Past 7 Days',
        value: 'PAST_WEEK'
      },
    ],
  },
  getters: {
    historyItems: state => state.historyItems,
    initialHistoryItems: state => state.initialHistoryItems,
    historyTotal: state => state.historyTotal,
    historySummary: state => state.historySummary,
    filterSelection: state => state.filterSelection,
    filterOptions: state => state.filterOptions,
    retrievalStart: state => state.retrievalStart,
    isDataLoaded: state => state.dataLoaded,
    historyLoading: state => state.historyLoading,
    historyAPIError: state => state.historyAPIError,
    retrievalIncrement: state => state.retrievalIncrement,
  },
  mutations: {
    setHistoryItems(state, payload) {
      state.historyItems = (payload)
    },
    setInitialHistoryItems(state, payload) {
      state.initialHistoryItems = (payload)
    },
    setHistoryTotal(state, payload) {
      state.historyTotal = payload
    },
    setHistorySummary(state, payload) {
      state.historySummary = payload
    },
    setFilterSelection(state, payload) {
      state.filterSelection = payload
    },
    setDataLoaded(state, payload) {
      state.dataLoaded = payload
    },
    setHistoryLoading(state, payload) {
      state.historyLoading = payload
    },
    setHistoryAPIError(state, payload) {
      state.historyAPIError = payload
    },
    setHistoryYTDTotal(state, payload) {
      state.historyYTDTotal = payload
    }
  },
  actions: {
    // eslint-disable-next-line
    async retrieveSavingsHistory({
      commit, state, rootGetters
    }, apiParamsIn) {
      if (apiParamsIn?.preventReload && apiParamsIn?.preventReload === false) {
        commit('setHistoryLoading', true)
      }
      const opco = rootGetters['SiteConfig/opco']
      const cardNumber = rootGetters['LoyaltyAccount/cardNumber']
      const { filterSelection } = state
      const apiParams = {
        start: apiParamsIn?.start,
        size: apiParamsIn?.size,
        filterSelection,
        opco,
        cardNumber
      }
      try {
        const savingsHistory = await savingsHistoryAPI.getSavingsHistory(apiParams)
        const { savingsSummary, tripsHistory } = savingsHistory.data
        let totalTrips = state.historyItems
        if (apiParamsIn.reset) {
          totalTrips = []
        }
        totalTrips = totalTrips.concat(tripsHistory.result)
        commit('setHistorySummary', savingsSummary)
        commit('setHistoryItems', totalTrips)
        if (apiParams.filterSelection === 'YEAR_TO_DATE') {
          commit('setHistoryYTDTotal', savingsSummary.totalSavings)
        }
        if (apiParamsIn.start === 0) {
          commit('setInitialHistoryItems', tripsHistory.result)
        }
        commit('setHistoryTotal', tripsHistory.total)
        commit('setHistoryAPIError', false)
      } catch (e) {
        commit('setHistoryAPIError', true)
      }
      commit('setHistoryLoading', false)
    },
    resetSavingsHistory({ commit, state }) {
      commit('setHistoryItems', state.initialHistoryItems)
    },
    emptySavingsHistory({ commit }) {
      commit('setHistoryItems', [])
    },
    async fetchAndRefreshSavingsHistory({
      commit, dispatch
    }, payload) {
      commit('setFilterSelection', payload.filterSelection)
      dispatch('retrieveSavingsHistory', payload)
    }
  }
}
