import ApiService from '@/api/'
import UserProfileAPI from 'api/UserProfileAPI'
import ServiceLocationsAPI from 'api/ServiceLocationsAPI'
import CurrentUserIdAPI from 'api/CurrentUserIdAPI'
import excludeNilFromObj from 'utils/filters/excludeNilFromObj'
import phoneNumberParts from 'utils/filters/phoneNumberParts'
import formatPhoneWhole from 'utils/filters/formatPhoneWhole'
import flattenObject from 'utils/filters/flattenObject'
import patchFormatter from 'utils/filters/patchFormatter'
import formatAltId from 'utils/filters/formatAltId'
import sanitizeApostrophe from 'utils/filters/sanitizeApostrophe'
import getFirstAddressWithName from 'utils/filters/getFirstAddressWithName'
import { currentStoreIsOnSpectrum } from 'utils/StoreTransition'


const testPhoneValidity = (phone, phoneEntrySingle) => {
  const {
    phoneEntry, areaCode, prefix, suffix
  } = phone || {}
  return phoneEntrySingle || phoneEntry || (areaCode && prefix && suffix)
}

export default {
  namespaced: true,
  state: {
    userInfo: null,
    addressDefaults: null,
    addresses: null,
    // Do not use the preferredPaymentMethod found in information as the source of truth
    information: null,
    opco: null,
    refData: null,
    retailerCard: null,
    sessionId: null,
    updateErrors: [],
    primaryEmailAddress: '',
    secondaryEmailAddress: '',
    emailUpdateError: false,
    emailUpdated: false,
    useDeliveryForBilling: true,
    billingLoaded: false,
    reloginRequired: false,
    // COVID-19 relevant fields
    contactFreeLevel: 0,
    marketAllowingPickup: true,
    restrictedItemsExist: false,
    availablePickupStores: [],
    userProfileResponse: null, // Promise handler -- should be Promise or null
    currentUserId: null,
    currentUserPromise: null,
    shouldSyncAddress: false,
    ferryTransition: false,
    fireIslandTransition: false
  },
  getters: {
    userProfileResponse: state => (state.userProfileResponse),
    userInfo: (state) => {
      // always guarantee an object with an expected structure - prevents parsing errors on page load
      // appConfig does not return user or userId for all pages. Must find a different solution.
      //    ie. /shop/reset/reset-password
      const userInfo = JSON.parse(state.userInfo)
      if (userInfo?.information && (state.currentUserId || window.appConfig?.user?.userId)) {
        userInfo.information.userId = state.currentUserId || window.appConfig?.user?.userId
      }
      return userInfo
    },
    userId: (state, getters) => {
      if (state.currentUserId !== null && getters?.userInfo?.information) {
        return state.currentUserId
      }
      return (getters?.userInfo?.information?.userId || window.appConfig?.user?.userId)
    },
    currentUserId: state => state.currentUserId,
    currentUserPromise: state => state.currentUserPromise,
    hasEBT: state => (state?.information?.hasEBT || false),
    addressDefaults: state => state.addressDefaults,
    addresses: (state, getters) => (getters.userInfo?.addresses || []),
    hasActivePaymentMethod: (s, getters) => (getters?.userInfo?.information?.hasActivePaymentMethod),
    shopCode: (state, getters) => (getters?.userInfo?.information?.shopCode),
    displayFirstName: (state, getters, rootState, rootGetters) => {
      const { defaultDeliveryAddress = {} } = getters
      return defaultDeliveryAddress.name?.firstName || rootGetters['LoyaltyAccount/firstName'] || ''
    },
    displayAnyName: (state, getters) => {
      // displays any first name found in addresses, or defaults to email
      let name = ''
      const { information = {} } = getters
      const { email = '' } = information
      const address = getFirstAddressWithName(getters.addresses)
      if (address) {
        const { firstName, lastName } = address?.name || {}
        name = firstName || lastName
      } else if (email) {
        name = email
      }
      return name
    },
    // eslint-disable-next-line max-params
    displayLastName: (state, getters, rootState, rootGetters) => {
      const { defaultDeliveryAddress = {} } = getters
      return defaultDeliveryAddress.name?.lastName || rootGetters['LoyaltyAccount/lastName'] || ''
    },
    email: (state, getters) => (getters?.userInfo?.information?.email),
    isDeliveryAddressComplete: (s, getters) => {
      const { defaultDeliveryAddress = {} } = getters
      const {
        name, addressLine1, city, zip, phoneEntry, phone, state
      } = defaultDeliveryAddress
      const { firstName, lastName } = name || {}
      const hasPhone = testPhoneValidity(phone, phoneEntry)
      return !!(firstName && lastName && addressLine1 && city && state && zip && hasPhone)
    },
    isPickupDeliveryStepComplete: (s, getters) => {
      const { defaultDeliveryAddress = {} } = getters
      const {
        name, phoneEntry, phone,
      } = defaultDeliveryAddress
      const { firstName, lastName } = name || {}
      const hasPhone = testPhoneValidity(phone, phoneEntry)
      return !!(firstName && lastName && hasPhone)
    },
    isBillingAddressComplete: (s, getters) => {
      const { defaultBillingAddress = {} } = getters
      const {
        name, addressLine1, city, zip, phoneEntry, phone, state
      } = defaultBillingAddress
      const { firstName, lastName } = name || {}
      const hasPhone = testPhoneValidity(phone, phoneEntry)
      return !!(firstName && lastName && addressLine1 && city && state && zip && hasPhone)
    },
    information: (state, getters) => (getters.userInfo?.information || {}),
    // !!!deliveryServiceLocation: need to migrate to Service Location API
    deliveryServiceLocation: (state, getters) => (getters.userInfo?.refData?.deliveryServiceLocation || {}),
    serviceType: (state, getters) => (getters.deliveryServiceLocation.serviceType || null),
    serviceLocationId: (state, getters) => {
      return (getters.deliveryServiceLocation.serviceLocationId
        || window.appConfig.user.currentServiceLocationId)
    },
    storeNumber: (state, getters) => (getters.deliveryServiceLocation.storeNumber || null),
    storeId: (state, getters) => (getters.deliveryServiceLocation.storeId || null),
    feeAmounts: (state, getters) => (getters.deliveryServiceLocation.feeAmounts || {}),
    opco: state => state.opco,
    opcoName: state => state.opco?.name,
    refData: state => state.refData,
    isClickAndCollect: (state, getters) => getters.deliveryServiceLocation.clickAndCollect,
    retailerCard: state => state.retailerCard,
    // retailerCardNumber to share cardNumber with LoyaltyAccount, short circuit if user is null
    retailerCardNumber: (state, getters) => {
      const { userInfo } = getters
      if (!userInfo) {
        return ''
      }
      const { retailerCard = {} } = userInfo
      return retailerCard.cardNumber || ''
    },
    // eslint-disable-next-line max-params
    isLoyaltyRegistered: (state, getters, rootState, rootGetters) => rootGetters['LoyaltyAccount/isLoyaltyRegistered'],
    isPartiallyRegistered: (state, getters, rootState) => {
      const { defaultDeliveryAddress = {} } = getters
      const firstName = defaultDeliveryAddress?.name?.firstName !== '' || false
      const isPeapod = rootState.SiteConfig.opco === 'PPOD'
      return (getters.retailerCardNumber && getters.isLoyaltyRegistered) || (firstName && isPeapod)
    },
    sessionId: state => state.sessionId,
    updateErrors: state => state.updateErrors,
    deliveryAddressId: (state, getters) => {
      const { userInfo } = getters
      return userInfo?.addressDefaults?.deliveryAddressId || ''
    },
    billingAddressId: (state, getters) => {
      const { userInfo } = getters
      return userInfo?.addressDefaults?.billingAddressId || ''
    },
    defaultDeliveryAddress: (state, getters) => {
      const { addresses, deliveryAddressId } = getters
      return _.findWhere(addresses, { addressId: deliveryAddressId }) || {}
    },
    defaultAttendedInstructions: (state, getters) => {
      const { defaultDeliveryAddress = {} } = getters
      return defaultDeliveryAddress?.instructions
    },
    defaultUnattendedInstructions: (state, getters) => {
      const { defaultDeliveryAddress = {} } = getters
      return defaultDeliveryAddress?.unattendedInstructions
    },
    defaultBillingAddress: (state, getters) => {
      const { addresses, billingAddressId } = getters
      return _.findWhere(addresses, { addressId: billingAddressId }) || {}
    },
    deliveryAddress: (state, getters) => {
      const { defaultDeliveryAddress } = getters
      return flattenObject(_.omit(defaultDeliveryAddress, 'phone', 'addressId'))
    },
    deliveryPhone: (state, getters) => {
      const { userInfo, deliveryAddressId } = getters
      const address = _.findWhere(userInfo.addresses, { addressId: deliveryAddressId }) || {}
      const { phone } = address || {}
      return formatPhoneWhole(phone)
    },
    deliveryPhoneList: (state, getters) => {
      const { userInfo, deliveryAddressId } = getters
      const address = _.findWhere(userInfo.addresses, { addressId: deliveryAddressId }) || {}
      const { phone } = address || {}
      return phone
    },
    billingAddress: (state, getters) => {
      const { defaultBillingAddress } = getters
      return flattenObject(_.omit(defaultBillingAddress, 'phone', 'addressId'))
    },
    useDeliveryForBilling: state => state.useDeliveryForBilling,
    billingPhone: (state, getters) => {
      const { userInfo, billingAddressId } = getters
      const address = _.findWhere(userInfo.addresses, { addressId: billingAddressId }) || {}
      const { phone } = address || {}
      return formatPhoneWhole(phone)
    },
    billingPhoneList: (state, getters) => {
      const { userInfo, billingAddressId } = getters
      const address = _.findWhere(userInfo.addresses, { addressId: billingAddressId }) || {}
      const { phone } = address || {}
      return phone
    },
    billingLoaded: state => state.billingLoaded,
    reloginRequired: state => state.reloginRequired,
    hasBothUserAndLoyaltyId: (state, getters) => getters.retailerCardNumber && getters.userInfo.information.userId,
    contactFreeLevel: state => state.contactFreeLevel,
    marketAllowingPickup: state => state.marketAllowingPickup,
    restrictedItemsExist: state => state.restrictedItemsExist,
    availablePickupStores: state => state.availablePickupStores,
    shouldSyncAddress: state => state.shouldSyncAddress,
    configOrdersAhead: (state, getters) => (
      // Determines how we compute that an order is nearby
      getters.refData?.customerDefaultDeliveryServiceLocation?.numberOfOrdersAhead || 5
    ),
    unataExperience: (state, getters, rootState, rootGetters) => rootGetters['SiteConfig/opco'] === 'FDLN'
      && !currentStoreIsOnSpectrum(rootGetters['UserProfile/serviceLocationId'])
  },
  mutations: {
    setUserProfileData(state, payload) {
      if (typeof payload !== 'object') {
        state.updateErrors.push('@param "payload" was not of type: "object"')
        return
      }

      try {
        // UPDATE - USE userInfo getter and loadUserProfileData action
        // NOTE: this is a hack for deep-cloning,
        // recursive method or lodash are future options to discuss for this
        const stringified = JSON.stringify(payload)
        const parsedPayload = JSON.parse(stringified)

        Object.keys(parsedPayload).forEach((profileKey) => {
          if (state[profileKey] !== undefined && profileKey !== 'sessionId') {
            state[profileKey] = parsedPayload[profileKey]
          } else {
            state.updateErrors.push(`Unknown profile key: "${profileKey}" was received`)
          }
        })
      } catch (e) {
        state.updateErrors.push('failed to parse UserProfile object during mutation')
      }
    },
    loadUserProfileData(state, payload) {
      // it is fine to stringify the user info payload since of how nested it is
      // and then we parse when we get
      // !!!deliveryServiceLocation: need to migrate to Service Location API
      if (payload.refData.deliveryServiceLocation.storeNumber) {
        const { storeNumber } = payload.refData.deliveryServiceLocation
        payload.refData.deliveryServiceLocation.storeNumber = (`0000${storeNumber}`).slice(-4)
      }

      state.userInfo = JSON.stringify(payload)
    },
    // lets phase out state.userInfo, and flatten userProfile a bit
    // until then, this mutation will keep it up to date with the rest of state
    updateUserProfileData(state, payload) {
      const userInfo = JSON.parse(state.userInfo)
      const { path, value } = payload
      if (path && (value !== undefined)) {
        const pathSections = path.split('.')
        const sections = pathSections.slice(0, pathSections.length - 1) // path to updated section
        const attribute = pathSections.slice(-1) // segment to be updated
        const stateSectionName = [sections[0]] // name of root state section to be upddated

        let objLocation = userInfo
        let stateLoc = state
        sections.forEach((section) => {
          objLocation = objLocation[section]
          stateLoc = stateLoc[section]
        })
        // update userInfo
        objLocation[attribute] = (typeof value === 'object') ? { ...objLocation[attribute], ...value } : value
        state.userInfo = JSON.stringify(userInfo)

        // update corresponding state section
        stateLoc[attribute] = (typeof value === 'object') ? { ...stateLoc[attribute], ...value } : value
        state[stateSectionName] = { ...state[stateSectionName] }
      }
    },
    setUseDeliveryForBilling(state, payload) { state.useDeliveryForBilling = !!payload },
    setBillingLoaded(state, payload) { state.billingLoaded = !!payload },
    setReloginRequired(state, payload) { state.reloginRequired = payload },
    setContactFreeLevel(state, payload) {
      state.contactFreeLevel = payload
    },
    setMarketAllowingPickup(state, payload) {
      state.marketAllowingPickup = payload
    },
    setRestrictedItemsExist(state, payload) {
      state.restrictedItemsExist = payload
    },
    setAvailablePickupStores(state, payload) {
      state.availablePickupStores = payload
    },
    setUserProfileResponse(state, payload) {
      state.userProfileResponse = payload
    },
    setCurrentUserId(state, userId) {
      state.currentUserId = userId
    },
    setCurrentUserPromise(state, payload) {
      state.currentUserPromise = payload
    },
    setShouldSyncAddress(state, payload) {
      state.shouldSyncAddress = payload
    },
    setFerryTransition(state, payload) {
      state.ferryTransition = payload
    },
    setFireIslandTransition(state, payload) {
      state.fireIslandTransition = payload
    }
  },
  actions: {
    loadUserProfileData({ commit }, payload) {
      commit('loadUserProfileData', payload)
    },
    // accepts a '.' delineated path param and the value to update at that path
    // should only be used for partial updates, otherwise use 'loadUserProfileData'
    // {
    //   path: 'information.email',
    //   value: 'test@test.com
    // }
    updateUserProfileData({ commit }, payload) {
      commit('updateUserProfileData', payload)
    },
    updateDeliveryInstructionsData({ commit, getters }, payload) {
      const defaultDeliveryAddressId = getters.deliveryAddressId

      commit('updateUserProfileData', {
        path: `addresses.${defaultDeliveryAddressId}.instructions`,
        value: payload
      })
    },
    updateUnattendedDeliveryInstructionsData({ commit, getters }, payload) {
      const defaultDeliveryAddressId = getters.deliveryAddressId

      commit('updateUserProfileData', {
        path: `addresses.${defaultDeliveryAddressId}.unattendedInstructions`,
        value: payload
      })
    },
    async getDeliveryServiceLocations(context, { query }) {
      try {
        const payload = {
          params: {
            customerType: 'C',
            zip: query
          }
        }
        const response = await ServiceLocationsAPI.get(payload)
        const deliveryLocations = response.data.response.locations.filter(loc => loc.location.serviceType === 'D')
        return Promise.resolve(deliveryLocations)
      } catch {
        return Promise.reject(new Error('fail'))
      }
    },
    storeDeliveryAddress({ commit, getters }, payload) {
      // Clean this all up once we can make APIs more consistent
      const addressId = getters.deliveryAddressId
      const { firstName, lastName } = payload
      payload.name = { firstName, lastName }
      payload.phone = payload.contact
      delete payload.firstName
      delete payload.lastName
      delete payload.contact
      commit('updateUserProfileData', {
        path: `addresses.${addressId}`,
        value: payload
      })
    },
    async putDeliveryAddress({ dispatch, getters, rootGetters }, payload) {
      let response

      Object.keys('putDeliveryAddress: ', payload).forEach((key) => {
        payload[key] = sanitizeApostrophe(payload[key])
      })
      payload.phone = formatAltId(payload.phone)
      const { phone, phoneExt } = payload
      const phoneObj = {
        contact: phoneNumberParts(phone, phoneExt)
      }
      const opco = rootGetters['SiteConfig/opco']
      const apiVer = rootGetters['SiteConfig/varByName']('feature_google_api_address_validation_enabled')
        ? 'v4.0' : 'v3.0'
      const { serviceType, userId } = getters

      const apiData = {
        ...payload,
        ...phoneObj,
        phoneEntry: payload.phone,
        opco: opco || '',
        serviceType: `${serviceType}`
      }
      try {
        const route = `/api/${apiVer}/user/${userId}/contact/delivery`
        response = await ApiService.put(route, apiData)
        if (response.status === 201) {
          dispatch('storeDeliveryAddress', apiData)
          // call Refresh user profile api for contact delivery address
          // This will be temp solution till we have Dynamo or session-Management goes live
          // Since Dynamo session is becoming stale when user updates happen
          await UserProfileAPI.refreshUserProfile(userId)
        }
        return response
      } catch {
        return { ...response }
      }
    },
    async patchUsername({ dispatch }, payload) {
      const { username } = payload
      let response
      try {
        response = await dispatch('patchUserProfile', {
          data: { handle: username },
          path: 'information'
        })
        return response
      } catch {
        return response
      }
    },
    async patchDeliveryAddress({ getters, dispatch }, payload) {
      Object.keys(payload).forEach((key) => {
        payload[key] = sanitizeApostrophe(payload[key])
      })
      const {
        firstName, lastName, city, state, zip, addressLine1, addressLine2, phone, phoneExt,
        instructions, unattendedInstructions, userConfirmation
      } = payload
      const isMerchant = getters.userInfo.information.userType === 'M'
      let addressStructure = {
        city,
        state,
        zip,
        addressLine1,
        addressLine2,
        instructions: instructions?.trim() || null,
        unattendedInstructions: unattendedInstructions?.trim() || null,
        userConfirmation
      }

      if (firstName || lastName) {
        addressStructure.name = {
          firstName,
          lastName
        }
      }

      if (phone) {
        const numericPhone = formatAltId(phone)
        addressStructure.phoneEntry = numericPhone
        addressStructure.phone = {
          phoneEntry: numericPhone,
          ...phoneNumberParts(numericPhone, phoneExt)
        }
      }

      if (isMerchant) {
        addressStructure.alternatePhone = addressStructure.phone
      }

      let response
      addressStructure = excludeNilFromObj(addressStructure)

      try {
        const delivAddressId = getters.userInfo.addressDefaults.deliveryAddressId
        response = await dispatch('patchUserProfile', { data: addressStructure, path: `addresses.${delivAddressId}` })
        return response
      } catch {
        return response
      }
    },
    async patchUserProfile({
      commit, dispatch, state, getters, rootGetters
    }, payload) {
      await dispatch('SiteConfig/getConfig', null, { root: true })
      await dispatch('UserProfile/retrieveCurrentUserId', null, { root: true })

      const apiVer = rootGetters['SiteConfig/varByName']('feature_google_api_address_validation_enabled')
        ? 'v5.0' : 'v4.0'
      const userId = getters.userId || state.currentUserId
      const apiData = patchFormatter(payload.data, payload.path)
      const response = await ApiService.put(`/api/${apiVer}/user/${userId}/profile`, apiData)
      if (response.status === 200) {
        apiData.forEach((entry) => {
          const { path, value } = entry
          commit('updateUserProfileData', { path, value })
        })
      }
      return response
    },
    async patchPassword({
      dispatch, state, getters
    }, payload) {
      const {
        password,
        confirmPassword
      } = payload

      let structure = excludeNilFromObj({
        password,
        confirmPassword
      })

      let response

      try {
        if (!structure.password || !structure.confirmPassword) {
          throw new Error('FORM_ERROR: password & confirm password required')
        }
        await dispatch('SiteConfig/getConfig', null, { root: true })
        await dispatch('UserProfile/retrieveCurrentUserId', null, { root: true })

        const userId = getters.userId || state.currentUserId
        structure = patchFormatter(structure, 'information')
        response = await ApiService.put(`/api/v4.0/user/${userId}/profile`, structure)
        return response
      } catch {
        return response
      }
    },
    async patchEmail({
      commit, dispatch, getters, state
    }, payload) {
      const {
        email,
        buddyEmail
      } = payload

      const {
        email: userInfoEmail,
        handle
      } = getters?.userInfo?.information || {}

      let structure = excludeNilFromObj({
        email,
        buddyEmail
      })

      let response

      try {
        if (!structure.email) {
          throw new Error('FORM_ERROR: email required')
        }

        await dispatch('SiteConfig/getConfig', null, { root: true })
        await dispatch('UserProfile/retrieveCurrentUserId', null, { root: true })

        const userId = getters.userId || state.currentUserId

        structure = patchFormatter(structure, 'information')
        response = await ApiService.put(`/api/v4.0/user/${userId}/profile`, structure)

        if (response.status === 200) {
          Object.keys(payload).forEach((key) => {
            if (key === 'email' && userInfoEmail === handle) {
              commit('updateUserProfileData', {
                path: `information.handle`,
                value: payload[key]
              })
            }
            commit('updateUserProfileData', {
              path: `information.${key}`,
              value: payload[key]
            })
          })
        }
        return response
      } catch {
        return response
      }
    },
    async initQueryUserProfile({
      state, commit, dispatch
    }) {
      const config = {}

      await dispatch('SiteConfig/getConfig', null, { root: true })
      await dispatch('UserProfile/retrieveCurrentUserId', null, { root: true })
      // Prevent immediate double loading of API
      // Allow other callers to wait for promise
      let response = null

      if (state.userProfileResponse) {
        response = state.userProfileResponse
      } else {
        commit('setUserProfileResponse', UserProfileAPI.get(config, state.currentUserId)) // set to a Promise
        response = state.userProfileResponse
      }

      response = await response

      if (response.status === 200) {
        const responseData = response.data
        const userProfile = response.data.response
        // set data here instead of loadUserProfileData because it may already exist if coming from Angular
        userProfile.sessionId = responseData.sessionId
        // convert addresses to an object to match Angular user profile model
        // TO DO: Do we need the phone filters from user-profile-patch-services.js?
        userProfile.addresses = _.reduce(userProfile.addresses, (accumulator, currentValue) => {
          currentValue.phoneEntry = formatPhoneWhole(currentValue.phone)
          accumulator[currentValue.addressId] = currentValue
          return accumulator
        }, {})

        const hasBilling = userProfile.addressDefaults.billingAddressId
          && userProfile.addresses[userProfile.addressDefaults.billingAddressId]

        commit('setBillingLoaded', hasBilling)
        commit('loadUserProfileData', userProfile)
        commit('setUserProfileData', userProfile)
        dispatch('PickupLockers/setCurrentSupport', null, { root: true })
      }

      commit('setUserProfileResponse', null)
      return response
    },
    async retrieveCurrentUserId({ commit, state }) {
      let response = ''

      if (state.currentUserPromise) {
        response = state.currentUserPromise
      } else {
        response = await CurrentUserIdAPI.get()
        commit('setCurrentUserPromise', response)
      }
      const { data } = response
      commit('setCurrentUserId', data.userId)
    },
    async refetchUserId({ commit }) {
      commit('setCurrentUserPromise', null)
      commit('setUserProfileResponse', null)
    },
  }
}
