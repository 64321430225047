<template>
  <div
    class="title-bar"
    :class="{ 'relative-el': !isFixed }"
  >
    <div class="title-bar_left">
      <button
        v-if="showBack"
        class="title-bar_back-button"
        @click="backButtonClick"
      >
        <span class="element-invisible">
          Back
        </span>
        <PdlIconBase
          icon-title="Back"
          stroke-color="white"
          container-class="title-bar_back-icon"
        >
          <PdlIconCarrotLeft />
        </PdlIconBase>
      </button>
      <ViewOptions
        :has-view-options="hasViewOptions"
        :hide-medium-view-options="hideMediumViewOptions"
      />
    </div>
    <h2
      v-if="isSubHead"
      class="title-bar_text title-bar_text--sub"
    >
      {{ title }}
    </h2>
    <h1
      v-else
      class="title-bar_text"
    >
      {{ title }}
    </h1>
    <div class="title-bar_right">
      <button
        v-if="hasInfoSection"
        aria-label="info"
        class="title-bar_info-button"
        @click="onIconClick"
      >
        <span
          v-if="!isMobile"
          class="title-bar_info-label"
        >
          {{ infoSection.text }}
        </span>
        <PdlIconBase
          icon-title="Info Icon"
          :icon-class="'svg-icon icon-fill--reverse title-bar_info-icon-size'"
          container-class="vector-icon-size--medium flex-center"
        >
          <PdlIconInfo />
        </PdlIconBase>
      </button>
      <button
        v-if="hasPrintButton && !isNativeApp"
        class="flex items-center button button-width--flex"
        id="qa-print-btn"
        @click="onButtonClick('print-cart')"
      >
        <PdlIconBase
          icon-title="Print"
          icon-class="h-9 w-12 vector-icon-stroke--white"
        >
          <PdlIconPrint />
        </PdlIconBase>
        <span class="ml-4">
          Print
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import {
  mapState, mapGetters, mapMutations, mapActions
} from 'vuex'
import { USER_PREFERENCE_KEYS } from 'utils/constants'
import UserPreferencesAPI from 'api/UserPreferencesAPI'
import PdlIconCarrotLeft from 'components/icons/icon/PdlIconCarrotLeft'
import PdlIconInfo from 'components/icons/icon/PdlIconInfo'
import PdlIconPrint from 'components/icons/icon/PdlIconPrint'
import ViewOptions from './ViewOptions'

export default {
  components: {
    PdlIconCarrotLeft,
    PdlIconInfo,
    PdlIconPrint,
    ViewOptions
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    isSubHead: {
      type: Boolean,
      default: false
    },
    infoLabelText: {
      type: String,
      default: ''
    },
    hasViewOptions: {
      type: Boolean,
      default: false
    },
    hideMediumViewOptions: {
      type: Boolean,
      default: false
    },
    hasPrintButton: {
      type: Boolean,
      default: false
    },
    showBack: {
      type: Boolean,
      default: true
    },
    backFunction: {
      type: Function,
      default: null
    },
    isFixed: {
      type: Boolean,
      default: true
    },
    inModal: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    selectAllCheckboxVal: false
  }),
  computed: {
    ...mapState({
      activeView: state => state.ProductTile.activeView,
      breadcrumbs: state => state.TitleBar.breadcrumbs
    }),
    ...mapGetters({
      infoSection: 'TitleBar/infoSection',
      isMobile: 'ScreenSize/isMobile',
      userId: 'UserProfile/userId',
      isNativeApp: 'NativeContainer/isNativeApp'
    }),
    hasInfoSection() {
      return !this.inModal && this.infoSection.text
    },
    isGridView() {
      return this.activeView === 'grid'
    },
    isListView() {
      return this.activeView === 'list-view'
    }
  },
  watch: {
    userInfo() {
      if (this.userInfo) {
        this.getShelfValue({ userPref: USER_PREFERENCE_KEYS.SHELF_VIEW }).then((activeView) => {
          this.setActiveView(activeView.value)
        })
      }
    }
  },
  created() {
    if (this.userId) {
      this.getShelfValue({ userPref: USER_PREFERENCE_KEYS.SHELF_VIEW }).then((activeView) => {
        this.setActiveView(activeView.value)
      })
    }
  },
  methods: {
    ...mapMutations({
      setActiveView: 'ProductTile/setActiveView'
    }),
    ...mapActions({
      getShelfValue: 'UserPref/getUserPrefResponse'
    }),
    backButtonClick() {
      if (this.backFunction) {
        this.backFunction()
        return
      }
      this.$router.back()
    },
    changeProductView(view) {
      this.setActiveView(view)
      UserPreferencesAPI.update(USER_PREFERENCE_KEYS.SHELF_VIEW, view, this.userId)
    },
    onButtonClick(event) {
      this.$emit(event)
    },
    onIconClick() {
      if (this.infoSection) {
        this.$store.commit('Modals/setActiveModal', {
          fileName: this.infoSection.modal,
          style: 'modal_base-medium'
        })
        if (this.infoSection.taggingData) {
          if (this.infoSection.taggingData.tagName?.startsWith('GA4')) {
            this.$trackGA4Event(this.infoSection.taggingData.tagName,
              this.infoSection.taggingData.payload)
          } else {
            this.$trackGtmEvent(this.infoSection.taggingData.tagName,
              this.infoSection.taggingData.payload)
          }
        }
      }
    },
  }
}
</script>
