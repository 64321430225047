<template>
  <input
    v-model="tempValue"
    v-bind="attrs"
    :id="id"
    :ref="'input'"
    :name="name"
    :form-id="formId"
    type="hidden"
    class="forms_input"
    :class="inputClasses"
  >
</template>
<script>
import InputBase from './PdlInputBaseMixin'

export default {
  mixins: [InputBase]
}
</script>
