import rasaChatbotAPI from 'api/RasaChatbotApi'
import {
  chatAvailability,
  getLiveChatSession,
  getChatHours
} from 'api/graphql/liveChat'

const CHAT_STATES = [
  'chatbot',
  'live'
]

function isMobile(rootGetters) {
  return rootGetters['NativeContainer/isMobileApp'] || rootGetters['ScreenSize/isMobile']
    || rootGetters['ScreenSize/isTabletMini']
}

export default {
  namespaced: true,
  state: {
    agentAvailable: false,
    // 'chatbot' || 'live'. This lives here so other areas of the app can open the chat feature
    // in whatever state they want. i.e. bypassing chatbot
    chatState: 'chatbot',
    conversation: [],
    errorState: false,
    liveChatConversation: [],
    liveChatKey: null,
    liveChatSessionId: null,
    liveChatAffinityToken: null,
    liveChatPollTimeout: null,
    // This is based on hard coded hours, not salesforce live chat availability
    isLiveChatOpen: false,
    isOpen: false,
    senderID: null,
    waitingOnResponse: false,
    loadChat: false,
  },
  mutations: {
    setAgentAvailable(state, payload) { state.agentAvailable = payload },
    setChatState(state, payload) {
      if (!CHAT_STATES.includes(payload)) return
      state.chatState = payload
    },
    setErrorState(state, payload) {
      state.errorState = payload
    },
    setIsOpen(state, payload) { state.isOpen = !!payload },
    setSenderID(state, payload) { state.senderID = payload },
    updateChat(state, newMessage) {
      state.waitingOnResponse = newMessage.type === 'sent'
      state.conversation = [
        ...state.conversation,
        newMessage
      ]
    },
    clearChatbotSession(state) {
      state.conversation = []
      state.errorState = false
    },
    setIsLiveChatOpen(state, payload) {
      state.isLiveChatOpen = payload
    },
    setLiveChatSession(state, payload) {
      state.liveChatKey = payload.sessionKey
      state.liveChatSessionId = payload.sessionId
      state.liveChatAffinityToken = payload.affinityToken
      state.liveChatPollTimeout = payload.clientPollTimeout
    },
    setLoadChat(state, payload) {
      state.loadChat = payload
    },
    resetLiveChatSession(state) {
      state.liveChatKey = null
      state.liveChatSessionId = null
      state.liveChatAffinityToken = null
      state.liveChatPollTimeout = null
      state.liveChatConversation = []
    },
    addLiveChatMessage(state, payload) {
      state.liveChatConversation = [
        ...state.liveChatConversation,
        payload
      ]
    }
  },
  actions: {
    setChatState({ commit, rootGetters }, payload) {
      const hasLiveChat = rootGetters['SiteConfig/varByName']('feature_salesforce_live_chat')
      if (payload === 'live' && !hasLiveChat) return

      commit('setChatState', payload)
    },
    setSenderID({ commit }) {
      commit('setSenderID', window.generateUUID())
    },
    async initBot({ commit, state, rootGetters }) {
      // If there is conversation history, no need to init the bot. Could be reopening bot window
      if (state.conversation.length === 0) {
        const response = await rasaChatbotAPI.sendMessage({
          userId: rootGetters['UserProfile/userId'],
          uniqueId: state.senderID,
          message: '/greet',
          isMobile: isMobile(rootGetters),
          opco: rootGetters['SiteConfig/opco'].toLowerCase(),
        })
        // const response = await mock()
        if (response.status === 200) {
          commit('updateChat', {
            responses: response.data,
            type: 'received',
          })
        } else {
          commit('setErrorState', true)
        }
      }
    },
    async sendMessage({ commit, state, rootGetters }, messagePayload) {
      if (messagePayload?.title) {
        commit('updateChat', {
          type: 'sent',
          text: messagePayload.title
        })
      }
      const response = await rasaChatbotAPI.sendMessage({
        userId: rootGetters['UserProfile/userId'],
        uniqueId: state.senderID,
        message: messagePayload?.payload,
        opco: rootGetters['SiteConfig/opco'].toLowerCase()
      })
      if (response.status === 200) {
        commit('updateChat', {
          responses: response.data,
          type: 'received',
        })
      } else {
        commit('setErrorState', true)
      }
    },
    async checkLiveChatIsOpen({ commit }) {
      const { query } = window.sharedVue.config.globalProperties.$apolloProvider.defaultClient
      const response = await query({
        query: getChatHours,
        fetchPolicy: 'no-cache'
      })
      const { isOpen } = response?.data?.liveChatHours || {}
      if (!isOpen) {
        commit('setIsLiveChatOpen', false)
        return false
      }
      commit('setIsLiveChatOpen', isOpen)
      return isOpen
    },
    async checkAgentAvailable({ commit, rootGetters }) {
      // If not salesforce livechat then don't check agent availability. Return true
      if (!rootGetters['SiteConfig/varByName']('feature_salesforce_live_chat')) return true

      const { query } = window.sharedVue.config.globalProperties.$apolloProvider.defaultClient
      const response = await query({
        query: chatAvailability,
        variables: {
          opco: appConfig.opcoId,
        },
        fetchPolicy: 'no-cache'
      })
      commit('setAgentAvailable', response.data.liveChatAvailability)

      return response.data.liveChatAvailability
    },
    async getLiveChatSession({ commit }) {
      const { query } = window.sharedVue.config.globalProperties.$apolloProvider.defaultClient
      const response = await query({
        query: getLiveChatSession,
        fetchPolicy: 'no-cache'
      })
      const { liveChatSession } = response.data
      commit('setLiveChatSession', liveChatSession)
      return liveChatSession
    },
    clearLiveChatSession({ commit }) {
      commit('resetLiveChatSession')
    },
    sentChatMessage({ commit }, text) {
      commit('addLiveChatMessage', {
        type: 'sent',
        text
      })
    },
    receivedChatMessage({ commit }, message) {
      commit('addLiveChatMessage', {
        ...message,
        type: 'received',
      })
    }
  }
}
