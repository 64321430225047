/**
 * Is the cache expired
 * @param {object} cache - The cache object
 * @returns {boolean} cache is expired or not
 */
export const isCacheExpired = (cache) => {
  const now = new Date()
  return now.getTime() > cache.expiry
}

/**
 * get cache from session storage
 * @param {string} key - the cache key
 * @returns {object || null} cache object or null
 */
export const getCache = (key) => {
  const cache = window.sessionStorage.getItem(key)
  if (cache) {
    return JSON.parse(cache)
  }
  return null
}

/**
 * set the cache in session storage
 * @param {string} key - the cache key
 * @param {object} data - the data to be cached
 * @param {number} expiry - time to expire the cache
 */
export const setCache = (key, data, expiry) => {
  const cacheData = {
    data,
    expiry: new Date().getTime() + expiry
  }
  window.sessionStorage.setItem(key, JSON.stringify(cacheData))
}
