export function mapSpyglassData(dataLayer) {
  return [
    { name: 'Scheduled Id', value: dataLayer.key },
    { name: 'Container Id', value: dataLayer.scheduledContentData.map.key },
    { name: 'Start Date', value: dataLayer['Start Date'] },
    { name: 'End Date', value: dataLayer['End Date'] },
    { name: 'Module Type', value: dataLayer.moduleType },
    { name: 'Site Location', value: dataLayer.Site_Location },
    { name: 'Customer Status', value: dataLayer['Customer Status'] },
    { name: 'Customer Type', value: dataLayer['Customer Type'] },
    { name: 'Device Type (Screen Size)', value: dataLayer['Device Type (Screen Size)'] },
    { name: 'App Targeting', value: dataLayer['App Targeting'] }
  ]
}
