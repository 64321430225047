import ApiService from '@/api/'

export const postInit = (credentials, config = {}) => {
  const { userId = '', opco = '', version = 'v3.0' } = config
  if (userId && opco) {
    credentials.opco = opco
    return ApiService.corePost(`/api/${version}/user/${userId}/init`, credentials)
  }
  return Promise.reject(new Error('missing parameters'))
}

export const initEmailVerification = (token) => {
  if (token) {
    return ApiService.corePost(`/api/v4.0/user/init/verification`, {
      token
    })
  }
  return Promise.reject(new Error('missing parameter'))
}
export const postResendVerificationEmail = (userId, registrationAttemptId) => {
  if (!!userId && !!registrationAttemptId) {
    return ApiService.corePost(`/api/v4.0/user/${userId}/init/emailResend`, {
      registrationAttemptId
    })
  }
  return Promise.reject(new Error('missing parameters'))
}
