import TrackManagement from 'utils/lib/TrackManagement'
import clientLogging from './clientLogging'

const install = (app) => {
  const log = clientLogging.createLogger(app)
  const { trackGtmEvent, trackGA4Event } = TrackManagement
  app.config.globalProperties.$trackGtmEvent = trackGtmEvent
  app.config.globalProperties.$trackGA4Event = trackGA4Event
  app.config.globalProperties.$trackClientLog = log
  app.config.globalProperties.$track = (event, payload, type) => {
    trackGtmEvent(event, payload)
    log(event, payload, type)
  }
}

export default {
  install
}
