import ApiService from '@/api/'

export default {
  state: {
    searchQuery: '',
    topicResponse: {},
    helpContentLoading: false
  },
  getters: {
    mainTopics: state => state.topicResponse?.links || [],
    pathParams: (state, getters, rootState, rootGetters) => {
      const userId = rootGetters['UserProfile/userId']
      const serviceLocationId = rootGetters['UserProfile/serviceLocationId']

      return serviceLocationId
        ? `/${userId || 2}/${serviceLocationId}`
        : ''
    }
  },
  mutations: {
    setHelpContentLoading(state, value) {
      state.helpContentLoading = value
    },
    setTopicResponse(state, value) {
      state.topicResponse = value
    },
    setSearchQuery(state, value) {
      state.searchQuery = value || ''
    },
    clearData(state) {
      state.searchQuery = ''
      state.topicResponse = {}
    }
  },
  actions: {
    async getContent({
      commit, getters, rootGetters
    }, { id, type }) {
      let results
      const { pathParams } = getters
      const timeTravelDate = rootGetters['ScheduledContent/timeTravelDate']
      const baseApiUrl = `/api/v4.0/help`

      try {
        if (!id) throw new Error('Missing ID')

        let params = { id }

        const parsed = Number.parseInt(id, 10)

        // allow for name based routes (i.e /help/article/Coupon-Policy )
        if (Number.isNaN(parsed)) {
          params = { alias: id }
        }

        commit('setHelpContentLoading', true)

        const responseData = await ApiService.get(
          `${baseApiUrl}/${type}${pathParams}`,
          {
            params: {
              ...params,
              dt: timeTravelDate
            },
          }
        )

        const { status, data } = responseData || {}
        if (status !== 200 || !data) throw responseData

        results = (type === 'article') ? data?.response[0] : data?.response
      } catch {
        results = {}
      }
      commit('setHelpContentLoading', false)
      return results || {}
    },
    async getHelpMainTopics({
      state, commit, getters, rootGetters
    }) {
      const { pathParams } = getters
      const timeTravelDate = rootGetters['ScheduledContent/timeTravelDate']

      if (Object.keys(state.topicResponse).length === 0) {
        const path = `/api/v4.0/help/topic${pathParams}`
        const responseData = await ApiService.get(
          `${path}`,
          {
            params: {
              dt: timeTravelDate,
            },
          }
        )
        const { response = {} } = responseData?.data || {}
        commit('setTopicResponse', response)
      }
    }
  }
}
