<template>
  <PdlMenu
    v-if="isAccountMenuOpen"
    :class="[
      'absolute',
      {
        'absolute top-[9.5rem] -right-[6rem] z-[102] rounded-none visible-above-1200': !isNewNavDesignEnabled,
        'border-hover border rounded-md hidden lg:flex right-2 top-20 border-gray-100 !z-30' : isNewNavDesignEnabled
      }
    ]"
    :show-sidebar="showSidebar"
    :sidebar-position="sidebarPosition"
    :size="size"
  >
    <template #sidebar>
      <PdlMenuSidebar
        :sidebar-head="sidebarHead"
      />
    </template>
    <template #nav>
      <nav>
        <div
          v-if="isNewNavDesignEnabled"
          :class="[
            {'menutip menutip--center': !isNewNavDesignEnabled}
          ]"
        />
        <ul
          ref="accountMenuList"
          :class="[{
            'bg-white p-6 rounded-brand': !isNewNavDesignEnabled,
            'flex flex-column justify-center bg-white': isNewNavDesignEnabled
          }]"
          @keydown.esc="closeMenu"
          @keydown.up.down="handleArrowKey($event)"
        >
          <PdlAccountNavItem
            v-for="(item, index) in accountMenuItems.links"
            :key="index"
            :index="index"
            :list-item-class="
              isNewNavDesignEnabled ?
                'w-full hover:bg-gray-50 flex flex-column justify-center pl-[2.5rem]' : ''"
            :label="item.label"
            :url="item.url"
            :link-type="item.linkType"
            :has-badge="item.label === 'Notifications'"
            :badge-amount="item.label === 'Notifications' ? notificationCount : 0"
            :is-hidden-item="isHiddenItem(item.label)"
            :is-new-nav-design-enabled="isNewNavDesignEnabled"
            @nav-item-clicked="onNavItemClick"
            @track-nav-updated="trackNavUpdated"
          />
          <hr
            v-if="isNewNavDesignEnabled && shouldShowChangeCity || isNewNavDesignEnabled && isLoggedIn"
            class="bg-gray-100 h-px my-0 w-72"
          >
          <PdlAccountNavItem
            v-if="shouldShowChangeCity"
            :is-new-nav-design-enabled="isNewNavDesignEnabled"
            :list-item-class="[
              'nav-item', {
                'nav-item--rule-above nav-item--primary' : !isNewNavDesignEnabled,
                'w-full hover:bg-gray-50 pl-10 pt-0' : isNewNavDesignEnabled
              }
            ]"
            label="Change City"
            @nav-item-clicked="onChangeCity"
          />
          <PdlAccountNavItem
            v-if="isLoggedIn"
            :is-new-nav-design-enabled="isNewNavDesignEnabled"
            :list-item-class="[
              'nav-item', {
                'nav-item--rule-above nav-item--primary' : !isNewNavDesignEnabled,
                'w-full hover:bg-gray-50 pl-10 pt-0' : isNewNavDesignEnabled
              }
            ]"
            label="Log Out"
            @nav-item-clicked="onLogout"
          />
        </ul>
      </nav>
    </template>
  </PdlMenu>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import PdlMenu from './PdlMenu'
import PdlAccountNavItem from './nav-items/PdlAccountNavItem'
import PdlMenuSidebar from './PdlMenuSidebar'

export default {
  components: {
    PdlMenu,
    PdlAccountNavItem,
    PdlMenuSidebar
  },
  props: {
    showSidebar: {
      type: Boolean,
      default: false
    },
    accountMenuItems: {
      type: Object,
      required: true
    },
    isNewNavDesignEnabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      sidebarPosition: 'left',
      size: 'small',
      sidebarHead: 'My Rewards'
    }
  },
  computed: {
    ...mapGetters({
      isAccountMenuOpen: 'GlobalHeader/isAccountMenuOpen',
      defaultDeliveryAddress: 'UserProfile/defaultDeliveryAddress',
      loyaltyAccountFirstName: 'LoyaltyAccount/firstName',
      loyaltyAccountLastName: 'LoyaltyAccount/lastName',
      notificationCount: 'Notifications/count',
      siteConfigByName: 'SiteConfig/varByName',
      userStatus: 'LoginStatus/userStatus',
      isLoggedIn: 'LoginStatus/isLoggedIn'
    }),
    firstName() {
      return this.defaultDeliveryAddress?.name?.firstName || this.loyaltyAccountFirstName
    },
    lastName() {
      return this.defaultDeliveryAddress?.name?.lastName || this.loyaltyAccountLastName
    },
    isGatewayDisabled() {
      return !this.siteConfigByName('domain_config_gateway_enabled')
    },
    shouldShowChangeCity() {
      return !this.isGatewayDisabled && this.userStatus === 'G' && !this.isLoggedIn
    }
  },
  methods: {
    ...mapActions({
      toggleAccountMenu: 'GlobalHeader/toggleAccountMenu'
    }),
    isHiddenItem(label) {
      if (this.isLoggedIn && label === 'Sign In') {
        return true
      }
      return (label === 'Notifications' && !this.notificationCount)
    },
    onNavItemClick(label, url, linkType, elementId) {
      this.toggleAccountMenu()
      this.$emit('nav-item-clicked', label, url, linkType, elementId)
    },
    onChangeCity() {
      this.toggleAccountMenu()
      this.$emit('change-city')
    },
    onLogout() {
      this.toggleAccountMenu()
      this.$emit('logout')
    },
    closeMenu() {
      this.$emit('close-menu', true)
    },
    handleArrowKey(event) {
      const { code, target } = event
      let index = [...this.$refs.accountMenuList.children].findIndex(element => element.contains(target))
      if (code === 'ArrowUp' && index > 0) {
        index -= 1
        this.focusOnMenuElement(index)
      }
      if (code === 'ArrowDown' && index < this.$refs.accountMenuList.children.length - 1) {
        index += 1
        this.focusOnMenuElement(index)
      }
    },
    focusOnMenuElement(index) {
      const focusableElement = this.$refs.accountMenuList.children[index].querySelectorAll('button, [href]')
      focusableElement[0].focus()
    },
    trackNavUpdated(data) {
      this.$emit('track-nav-updated', {
        action: this.isLoggedIn ? 'utility header - signed in' : 'utility header - signed out',
        ...data
      })
    }
  }
}
</script>
