/**
 * Analytics Event Tracking
 * @module TrackManagement
 */
const prefix = 'gt-'
const prefixGA4 = 'GA4-'

/**
 * Validate event and data, combine to single payload, per GTM spec
 * Verify event contains proper prefix.
 * Submit payload to global event data
 * @param {string} event - Name of event you want to track.
 * @param {object} data - Data you want to include.
 * @return {object} payload - Respond with payload for chaining.
 */
const trackGA4Event = (event, data) => {
  const vueInstance = window.sharedVue.config.globalProperties
  window.dataLayer = window.dataLayer || []
  event = typeof event === 'string' ? event : ''
  data = typeof data === 'object' ? data : {}
  event = event.startsWith(prefixGA4) ? event : `${prefixGA4}${event}`
  // No need to pass the prefix to native layers
  const eventNameforNative = event.replace(prefixGA4, '')
  const bascketId = vueInstance.$store.state.Cart.basketId
  const payload = {
    event,
    GA4: _.clone(data),
    ...(!data.basketId && { basketId: bascketId })
  }
  if (window.AnalyticsWebInterface) {
    // Call Android interface
    window.AnalyticsWebInterface.logEvent(eventNameforNative, JSON.stringify(data))
  } else if (window.webkit?.messageHandlers?.firebase) {
    // Call iOS interface
    const message = {
      command: 'logEvent',
      name: eventNameforNative,
      parameters: data
    }
    window.webkit.messageHandlers.firebase.postMessage(message)
  } else {
    // No Android or iOS interface found
    // clear past GA4 event
    window.dataLayer.push({ GA4: null })
    window.dataLayer.push(payload)
  }
  return payload
}

/**
 * Validate event and data, combine to single payload, per GTM spec
 * Verify event contains proper prefix.
 * Submit payload to global event data
 * @param {string} event - Name of event you want to track.
 * @param {object} data - Data you want to include.
 * @return {object} payload - Respond with payload for chaining.
 */
const trackGtmEvent = (event, data) => {
  window.dataLayer = window.dataLayer || []
  event = typeof event === 'string' ? event : ''
  data = typeof data === 'object' ? data : {}
  event = event.startsWith(prefix) ? event : `${prefix}${event}`
  const payload = {
    event,
    ...data
  }
  window.dataLayer.push(payload)
  return payload
}

export default {
  trackGtmEvent,
  trackGA4Event
}
