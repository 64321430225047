import {
  CARD_TYPE_VISA,
  CARD_TYPE_DISC,
  CARD_TYPE_MC,
  CARD_TYPE_AMEX
} from 'utils/regex'

import DEFAULT_IMG from './default-img'
import { GTM, GTM_GA4 } from './tracking'

import {
  CART_LOG_EVENT_NAMES,
  INTERWOVEN_CONFIG,
  PRODUCT_CONTENT_CONFIG,
  ORDERED_SIMPLED_FACETS,
  SORT_TYPES
} from './product-content'

const RECAPTCHA_KEY = '6LemRSwkAAAAADzRVSLvP16TYiqAtn4gZpd-lZQN'
const { appConfig } = window || {}

const {
  orderStatusDepartureTimeHourOffset = 1,
  orderStatusEtaBufferMinutes = 30,
  orderStatusLateMinutes = 30
} = appConfig || {}

const BR_BRAND_FOLDERS = {
  STSH: '/stopandshop',
  GNTL: '/giantfood',
  MRTN: '/martinsfoods',
  GNTC: '/giantfoodstores',
  FDLN: '/foodlion',
}

const ERROR_SESSION_RESPONSES = [
  'SESSION_LOCKED',
  'SESSION_ABORTED',
  'SESSION_EXPIRED'
]

const PACKAGING_OPTIONS = {
  podbag: 'podbag',
  oneway: 'one-way'
}

const LOGGING_TYPE = {
  route: 'R',
  modal: 'M',
  event: 'E',
  exception: 'EX'
}

const SUBSTITUTION_PREF_CODES = {
  PROD_SUB_PRODUCT_NONE: true,
  PROD_SUB_PRODUCT_TRUE: true,
  PROD_SUB_PRODUCT_FALSE: false,
}

const GTM_CONTAINER_ID = 'GTM-PTR8VBJ'
const PODBAG_PROD_ID = 227980
const CDN_URL = 'https://i5.peapod.com'

const USER_PREFERENCE_KEYS = {
  PUP_CUSTOMER_TOKEN: 'pup-customer-token',
  PREFERRED_PUP: 'preferred-pup',
  PREFERRED_IN_STORE: 'browsing-service-location',
  PREFERRED_DELIVERY: 'delivery-service-location',
  PRODUCT_SUBSTITUTION: 'product-substitution',
  EMAIL_CONFIRMATION: 'email-confirmation',
  PAYMENT_METHOD: 'payment-method',
  SHELF_VIEW: 'shelf-view',
  PAST_PURCH_FILTER_OFFSET: 'past-purch-filter-offset',
  PAPERLESS: 'paperless',
  BROWSE_SORT: 'browse-sort-type',
  PAST_PURCH_SORT: 'past-purch-sort-type',
  FAVORITES_SORT: 'favorites-sort-type',
  ZIP_CODE_CONFIRM: 'zip-code-confirm',
  LANDING_PAGE_ID: 'landing-page-id',
  HAS_POD_BAG: 'has-pod-bag',
  BANNER_LINK_PROMPT: 'banner-link-prompt',
  GA_CIM: 'ga-cim',
  SEARCH_ZIP: 'search-zip',
  MERCH_OPTION_SHOWN: 'merch-option-shown',
  MERCH_ALERT_SHOWN: 'merch-alert-shown',
  ORDER_SERVICE_LOCATION: 'order-service-location',
  DIGITAL_RECEIPTS: '71',
  SL_CLIPPED_COUPON_PREFERENCE: 'shopping-list-auto-add-coupons-type'
}

const MAX_EMAIL_LENGTH = 59
const MAX_CHECKING_LENGTH = 17
const MAX_BANKNAME_LENGTH = 25
const MAX_ROUTING_LENGTH = 9
const MAX_PASSWORD_LENGTH = 64
const MAX_LOGIN_LENGTH = 128
const MIN_PASSWORD_LENGTH = 8
const MAX_COMMENT_LENGTH = 100
const MAX_COMMENT_DISPLAY_LENGTH = 50
const MAX_LOYALTY_CITY_LENGTH = 20
const MAX_LASTNAME_LENGTH = 30
const MAX_LOGIN_ATTEMPTS = 5
const OTP_LENGTH = 6
const FORGOT_PASSWORD_REMINDER_COUNT = 3

const CREDIT_CARD_TYPES = {
  DISC: {
    type: 'DISC',
    name: 'Discover',
    alias: ['disc', 'discover', 'DISC', 'DISCOVER', 'Discover'],
    pattern: CARD_TYPE_DISC,
    max: 3
  },
  MC: {
    type: 'MC',
    name: 'MasterCard',
    alias: ['mc', 'MC', 'MASTERCARD', 'mastercard', 'MasterCard'],
    pattern: CARD_TYPE_MC,
    max: 3
  },
  AMEX: {
    type: 'AMEX',
    name: 'American Express',
    alias: ['amex', 'AMEX', 'AMERICAN EXPRESS', 'american express', 'American Express'],
    pattern: CARD_TYPE_AMEX,
    max: 4
  },
  VISA: {
    type: 'VISA',
    name: 'Visa',
    alias: ['visa', 'VISA', 'Visa'],
    pattern: CARD_TYPE_VISA,
    max: 3
  }
}

const GLOBAL_ETA_BUFFER = (
  !orderStatusEtaBufferMinutes ? null : orderStatusEtaBufferMinutes
)
const GLOBAL_LATE_MINUTES = (orderStatusLateMinutes ? null : orderStatusLateMinutes)

const DRIVER_DEPARTURE_OFFSET = (!orderStatusDepartureTimeHourOffset ? 1 : orderStatusDepartureTimeHourOffset)
const PP_TIMES = [
  {
    value: 'last',
    name: 'Last Order',
    hyphenedName: 'last-order'
  },
  {
    value: '3',
    name: '3 Months',
    hyphenedName: 'three-months'
  },
  {
    value: '6',
    name: '6 Months',
    hyphenedName: 'six-months'
  },
  {
    value: 'all',
    name: 'All Time',
    hyphenedName: 'all-time'
  }
]

const ORDER_STATUS = {
  COMPLETE: 'COMPLETE',
  WORKING: 'WORKING',
  SUBMITTED: 'SUBMITTED',
}

// eslint-disable-next-line
const GM_URL = 'https://maps.googleapis.com/maps/api/js?client=gme-peapodllc&key=AIzaSyAumo58Vb5IPd5_VhStMcrzwNNiqMWI5hE'

const CART_LOG_LOCATIONS = {
  home: {
    mySpecials: 'HOME_MY_SPECIALS',
    recommended: 'HOME_RECOMMEND'
  },
  search: {
    keyword: 'SEARCH_BY_KEYWORD'
  },
  browseAisles: {
    products: 'BROWSE_AISLES',
    featured: 'BROWSE_AISLES_FEATURED',
    recommend: 'BROWSE_AISLES_RECOMMEND'
  },
  specials: {
    featured: 'FEATURED_SPECIALS',
    mySpecials: 'MY_SPECIALS',
    allSpecials: 'ALL_SPECIALS',
    coupons: 'LOADED_COUPONS'
  },
  pastPurchases: {
    products: 'PAST_PURCHASES'
  },
  orderStarter: {
    products: 'ORDER_STARTER'
  },
  favorites: {
    products: 'FAVORITES'
  },
  itemDetail: {
    product: 'ITEM_DETAIL',
    recommended: 'ITEM_DETAIL_RECOMMENDED',
    buyMoreSaveMore: 'ITEM_DETAIL_BMSM'
  },
  podGroup: {
    products: 'POD_GROUP',
    buyMoreSaveMore: 'POD_GROUP_BMSM',
    swapNSave: 'POD_GROUP_SWAPNSAVE'
  },
  newArrivals: {
    products: 'NEW_ARRIVALS'
  },
  organic: {
    products: 'ORGANIC'
  },
  eciCoupon: {
    products: 'ECI_COUPON'
  },
  cart: {
    products: 'CART'
  },
  splash: {
    recommendationBase: 'SPLASH_RECS_',
    productSetBase: 'SPLASH_PRODUCT_SET_',
    topSpecialsBase: 'SPLASH_TOP_SPECIALS_'
  },
  storeOffers: {
    products: 'STORE_OFFERS'
  }
}

const OPCO_THEME = window.appConfig?.opcoTheme || 'PPOD'
const OPCO_ID = window.appConfig?.opcoId || 'PPOD'


const SERVICE_TYPE_TEXT = {
  B: 'browse',
  P: 'pickup',
  D: 'delivery'
}

const SERVICE_TYPE_WITH_INSTORE = {
  B: 'In-Store',
  P: 'Pickup',
  D: 'Delivery',
}

const ORDER_SERVICE_TYPES = Object.freeze({
  /**
   * Users may browse products available in store and clip coupons, etc.
   * "Browse" mode may also be referred to as "In-Store" mode.
   */
  BROWSE: 'B',

  /**
   * Orders which are delivered to a customer's residence or mailing address
   * are considered "Delivery" orders.
   */
  DELIVERY: 'D',

  /**
   * Any order which is retreived at the store is considered a "Pickup" order,
   * including Click & Collect (CNC) orders and Locker Pickup orders.
   */
  PICKUP: 'P',

  /**
   * All marketplace orders are considered SHIP2ME
  */
  SHIP2ME: 'SHIP2ME',
})

const SERVICE_TYPE_DESCRIPTORS = Object.freeze({
  B: {
    title: 'In-Store',
    serviceType: 'B',
    key: 'SERVICE_TYPE_IN_STORE_HEADLINE',
    text: 'View the weekly ad and clip coupons at your local store.',
    buttonText: 'Find a Store'
  },
  P: {
    title: 'Pickup',
    serviceType: 'P',
    key: 'SERVICE_TYPE_PICK_UP_HEADLINE',
    text: 'Same day pickup. Same store selection. Same store pricing.',
    buttonText: 'Find a Location'
  },
  D: {
    title: 'Delivery',
    serviceType: 'D',
    key: 'SERVICE_TYPE_DELIVERY_HEADLINE',
    text: 'Order online, get groceries delivered right to your door.',
    buttonText: 'Enter a Zip Code'
  }
})

const SERVICE_TYPE_DESCRIPTORS_FDLN = Object.freeze({
  P: {
    text: 'Order online for store pickup, brought direct to your car.',
  },
  D: {
    text: 'Order online to get groceries delivered direct to your door.',
  }
})

const ALERT_METHODS = Object.freeze({
  /**
   * The user is opted into both email and sms notification alerts.
   */
  BOTH: 'BOTH',

  /**
   * The user is opted into email notification alerts only.
   */
  EMAIL: 'EMAIL',

  /**
   * The user is opted out of any notification alerts.
   */
  NEITHER: null,

  /**
   * The user is opted into sms notification alerts only.
   */
  SMS: 'SMS',
})

const LOCKER_PICKUP_PAGE_URL = '/pages/locker-pickup'
const SELF_SERVICE_PICKUP_PAGE_URL = '/pages/selfservice-pickup'
const INSTACART_FAQ_PAGE_URL = '/pages/instacart-delivery'
const SNAP_LANDING_PAGE_URL = '/pages/snap-ebt-overview'
const REEF_FAQ_PAGE_URL = 'pages/reef-delivery'
const FERRY_PICKUP_PAGE_URL = '/pages/island-ferry-pickup'

const ORDER_PROVIDER_IDS = Object.freeze({
  INSTACART: 'INSTACART',
  REEF: 'REEF',
  PEAPOD: 'PEAPOD'
})

const SUPERUSER_ERROR_CODES = {
  SESSION_LOCKED: 'SESSION_LOCKED',
  SESSION_ABORTED: 'SESSION_ABORTED',
  SESSION_EXPIRED: 'SESSION_EXPIRED'
}

const RESTRICTED_PRODUCT_TYPES = Object.freeze({
  L: 'Alcohol',
  T: 'Tobacco',
  H: 'Prescription',
  G: 'Diner Items',
  V: 'Cough Medicines',
  C: 'Certain Medicines',
  S: 'Sample'
})

const API_REGEX = /^.*\/api\/v\d\.0\/.*$/
const API_MICROSERVICE_REGEX = /^.*\/apis\/.*$/
const API_AUTOLOGIN_REGEX = /^.*\/api\/v\d\.0\/user\/auto-login.*$/
const API_AUTOLOGIN_V3_REGEX = /^.*\/api\/v\d\.0\/user\/\d.*\/\d.*\/auto-login.*$/
const API_CURRENTUSER_REGEX = /^.*\/api\/v\d\.0\/current\/user.*$/
const API_CLIENTINFO_REGEX = /^.*\/api\/v\d\.0\/client-info.*$/
const API_CLIENTLOG_REGEX = /^.*\/api\/v\d\.0\/client\/log.*$/

const OPTIMIZELY_CUSTOM_FIELD_MAPPINGS = Object.freeze({
  isGuest: 'isGuest',
  userType: 'userType',
  ecomStoreId: 'eComStorId',
  opco: 'opco',
  deliveryZipCode: 'deliveryZip',
  vip: 'vip',
  orderStatus: 'orderStatus',
  orderListId: 'orderListId',
  deliveryMethodCode: 'deliveryMethodCode',
  customerFirstOrder: 'customerFirstOrder',
  numberOfOrders: 'numberOrders',
  daysSinceLastOrder: 'daysSinceLastOrder',
  orderAmount: 'orderAmount',
  orderSkuQuantity: 'orderItemCount',
  hasPodpass: 'hasPodpass',
  isMobileApp: 'isMobileApp'
})

const BASE_PAGE_URL = '/'
const LOGIN_PAGE = 'shop/auth/login'
const REGISTRATION_PAGE = '/registration'
const GHOST_USER_ID = 2

const RESPONSE_CODES = {
  LOGIN_INVALID: 'LOGIN_INVALID',
  LOGIN_INPUT_INVALID: 'LOGIN_INPUT_INVALID',
  LOGIN_INACTIVE: 'LOGIN_INACTIVE',
  LOGIN_UNCV: 'LOGIN_UNCV',
  STORE_CLOSED_PICKUP_AVAILABLE: 'STORE_CLOSED_PICKUP_AVAILABLE',
  STORE_CLOSED_PICKUP_UNAVAILABLE: 'STORE_CLOSED_PICKUP_UNAVAILABLE',
  LOGIN_MIGR: 'LOGIN_MIGR',
  LOGIN_MISS_DLV_ST: 'LOGIN_MISS_DLV_ST',
  LEGACY_OPCO_USER_LOGIN_PPD_MATCH_SUCCESS: 'LEGACY_OPCO_USER_LOGIN_PPD_MATCH_SUCCESS',
  LEGACY_OPCO_USER_LOGIN_SUCCESS: 'LEGACY_OPCO_USER_LOGIN_SUCCESS'
}

const INSTACART_ERROR_STRUCTURES = [
  {
    message: `can't be blank`,
    meta: 'address_line_1',
    userMessage: 'Address line 1 cannot be blank.'
  },
  {
    message: `Address is not serviceable`,
    meta: 'address_line_1',
    userMessage: `The address provided is not servicible.`
  },
  {
    message: `invalid_address`,
    meta: 'address',
    userMessage: 'The address provided is not valid.'
  },
]

const EXTERNAL_URLS = {
  DELI: 'deli',
  DEBUG: 'debug'
}


const LOCKER_DESCRIPTORS = {
  default: 'Locker',
  pod: 'Self-Service'
}

const SCHEDULED_CONTENT_FIELD = [
  'Friendly Tracking Description',
  'Site_Location',
  'ScheduleId',
  'id',
  'Start Date',
  'End Date',
  'Content Container URL Key',
  'SEO Excluded',
  'Rank (Obsolete)',
  'Create Multipanel',
  'Scheduled_Priority',
  'Footer Included',
  'App Targeting',
  'hasFragments',
  'Customer Status',
  'Customer Type',
  'Test script field (migration)',
  'Rank',
  'Approved',
  'StoreCodes',
  'Page Title',
  'Products Targeted',
  'Device Type (Screen Size)',
  'fragmentParentContainers'
]
const MAX_TIP_AMOUNT = 9999.99
const MAX_TIP_LENGTH = 7 // 9999.99
const LARGE_TIP_AMOUNT = 100

const CUTOFF_MAX_TIME = 1000 * 60 * 15 // 15 minutes

const REVIEW_ORDER_DAY_SPAN = 10
const MY_ACCOUNT_COMMUNICATIONS_ALERT_METHODS = Object.freeze({
  /**
   * The user is opted into both email and sms notification alerts.
   */
  BOTH: 'BOTH',

  /**
   * The user is opted into email notification alerts only.
   */
  EMAIL: 'EMAIL',

  /**
   * The user is opted out of any notification alerts.
   */
  NEITHER: null,

  /**
   * The user is opted into sms notification alerts only.
   */
  SMS: 'SMS',
})

const UNWANTED_PASSPHRASES = [
  'password'
]

const HEALTH_PREFERENCE_FLAG_MAPPING = {
  DAIRY_FREE: 'dairy',
  GLUTEN_FREE: 'gluten',
  KOSHER: 'kosher',
  ORGANIC: 'organic',
  VEGAN: 'vegan',
  VEGETARIAN: 'vegetarian'
}

const HEALTH_PREFERENCE_PRIORITY_ORDER = [
  'USDA_LOW_SODIUM',
  'DAIRY_FREE',
  'GLUTEN_FREE',
  'VEGETARIAN',
  'ORGANIC',
  'VEGAN',
  'KOSHER'
]

const GUIDING_STAR_MAPPING = {
  key: 'guidingStars',
  value: 'Guiding Stars'
}

const SUSTAINABILITY_RATING_MAPPING = {
  key: 'sustainabilityRating',
  value: 'Sustainable Rating'
}

const SLOTS_CACHE_EXPIRY_IN_MILLISECONDS = 60_000

const STORE_LOCATOR_FILTERS = [
  {
    value: 'clickandcollect',
    name: 'Store pickup',
    active: false
  },
  {
    value: 'pharmacy',
    name: 'Pharmacy',
    active: false
  },
  {
    value: 'gas',
    name: 'Gas station',
    active: false
  },
  {
    value: 'bank',
    name: 'Bank',
    active: false
  },
  {
    value: 'scanit',
    name: 'Scan it!',
    active: false
  },
  {
    value: 'is24hr',
    name: 'Open 24 hours',
    active: false
  }
]

const STORE_LOCATOR_FILTERS_FDLN = [
  {
    value: 'foodLion2GoStorePickup',
    name: 'Food Lion To Go Store Pick-Up',
    active: false
  },
  {
    value: 'foodLion2GoHomeDelivery',
    name: 'Food Lion To Go Home Delivery',
    active: false
  }
]

const ADDRESS_ERROR_MESSAGE = `We could not verify your address. Please edit your delivery
information and try checking out again.`

const CART_UPDATED_MESSAGE = `Please review your shopping method and cart as selection, pricing
and availability may have changed.`

const ADDRESS_UPDATED_MESSAGE = `We have updated your address and saved it to your account.
Your delivery options may have changed.`

export {
  ADDRESS_ERROR_MESSAGE,
  ADDRESS_UPDATED_MESSAGE,
  ALERT_METHODS,
  API_AUTOLOGIN_V3_REGEX,
  API_AUTOLOGIN_REGEX,
  API_CLIENTINFO_REGEX,
  API_CLIENTLOG_REGEX,
  API_CURRENTUSER_REGEX,
  API_MICROSERVICE_REGEX,
  API_REGEX,
  BASE_PAGE_URL,
  BR_BRAND_FOLDERS,
  CART_LOG_EVENT_NAMES,
  CART_LOG_LOCATIONS,
  CART_UPDATED_MESSAGE,
  CDN_URL,
  CREDIT_CARD_TYPES,
  CUTOFF_MAX_TIME,
  DEFAULT_IMG,
  DRIVER_DEPARTURE_OFFSET,
  ERROR_SESSION_RESPONSES,
  EXTERNAL_URLS,
  FERRY_PICKUP_PAGE_URL,
  FORGOT_PASSWORD_REMINDER_COUNT,
  GHOST_USER_ID,
  GLOBAL_ETA_BUFFER,
  GLOBAL_LATE_MINUTES,
  GM_URL,
  GTM,
  GTM_GA4,
  GTM_CONTAINER_ID,
  GUIDING_STAR_MAPPING,
  HEALTH_PREFERENCE_FLAG_MAPPING,
  HEALTH_PREFERENCE_PRIORITY_ORDER,
  INSTACART_ERROR_STRUCTURES,
  INSTACART_FAQ_PAGE_URL,
  INTERWOVEN_CONFIG,
  LARGE_TIP_AMOUNT,
  LOCKER_DESCRIPTORS,
  LOCKER_PICKUP_PAGE_URL,
  LOGGING_TYPE,
  LOGIN_PAGE,
  MAX_BANKNAME_LENGTH,
  MAX_CHECKING_LENGTH,
  MAX_COMMENT_DISPLAY_LENGTH,
  MAX_COMMENT_LENGTH,
  MAX_EMAIL_LENGTH,
  MAX_LASTNAME_LENGTH,
  MAX_LOGIN_ATTEMPTS,
  MAX_LOGIN_LENGTH,
  MAX_LOYALTY_CITY_LENGTH,
  MAX_PASSWORD_LENGTH,
  MAX_ROUTING_LENGTH,
  MAX_TIP_AMOUNT,
  MAX_TIP_LENGTH,
  MIN_PASSWORD_LENGTH,
  MY_ACCOUNT_COMMUNICATIONS_ALERT_METHODS,
  OPCO_ID,
  OPCO_THEME,
  ORDER_PROVIDER_IDS,
  ORDER_SERVICE_TYPES,
  ORDER_STATUS,
  ORDERED_SIMPLED_FACETS,
  OPTIMIZELY_CUSTOM_FIELD_MAPPINGS,
  OTP_LENGTH,
  PACKAGING_OPTIONS,
  PODBAG_PROD_ID,
  PP_TIMES,
  PRODUCT_CONTENT_CONFIG,
  RECAPTCHA_KEY,
  REEF_FAQ_PAGE_URL,
  REGISTRATION_PAGE,
  RESPONSE_CODES,
  RESTRICTED_PRODUCT_TYPES,
  REVIEW_ORDER_DAY_SPAN,
  SCHEDULED_CONTENT_FIELD,
  SELF_SERVICE_PICKUP_PAGE_URL,
  SERVICE_TYPE_DESCRIPTORS,
  SERVICE_TYPE_DESCRIPTORS_FDLN,
  SERVICE_TYPE_TEXT,
  SERVICE_TYPE_WITH_INSTORE,
  SLOTS_CACHE_EXPIRY_IN_MILLISECONDS,
  SNAP_LANDING_PAGE_URL,
  SORT_TYPES,
  STORE_LOCATOR_FILTERS,
  STORE_LOCATOR_FILTERS_FDLN,
  SUBSTITUTION_PREF_CODES,
  SUPERUSER_ERROR_CODES,
  SUSTAINABILITY_RATING_MAPPING,
  UNWANTED_PASSPHRASES,
  USER_PREFERENCE_KEYS,
}
