<template>
  <button
    id="menu-button"
    aria-label="Menu"
    :aria-expanded="menuActive"
    aria-haspopup="true"
    class="menu-button hidden-above-1200"
    @click.stop="onMenuButtonClick"
  >
    <PdlIconBase
      container-class="menu-button_icon"
      icon-title="menu"
      :vb-width="38"
    >
      <PdlIconMenu />
    </PdlIconBase>
  </button>
</template>

<script>
import PdlIconMenu from 'components/icons/icon/PdlIconMenu'

export default {
  components: {
    PdlIconMenu
  },
  props: {
    menuActive: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onMenuButtonClick() {
      this.$emit('menu-button-clicked')
    }
  }
}
</script>
