import ApiService from '@/api/'

const newRoute = '/api/v4.0/user/payment'

export default {
  get(userId = 0) {
    if (!userId) return Promise.reject(new Error('User Id required for V6 api'))
    return ApiService.get(`/api/v6.0/user/payment/${userId}/paymentmethods/primarycard`)
  },
  update(payload, userId) {
    return ApiService.put(`${newRoute}/${userId}/creditcard`, payload)
  }
}
