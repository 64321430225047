export const ADDRESS_TYPES = {
  BILLING: 'defaultBillingAddress',
  DELIVERY: 'defaultDeliveryAddress'
}

export const ORDER_MODES = {
  CURRENT: 'CURRENT',
  REDELIVERY: 'REDELIVERY',
  REGULAR: 'REGULAR',
}

export const TAB_TYPES = {
  DELIVERY: 'Delivery',
  BILLING: 'Billing',
  LOYALTY: 'Loyalty'
}
