const buildDimension156Arr = (product) => {
  const {
    ebtEligible,
    sustainabilityRating,
    isMarketplaceProduct,
    sponsoredProductInfo,
    boosted
  } = product
  const dimension156 = [] // Product Attribute
  const isSustainable = sustainabilityRating > 0

  if (sponsoredProductInfo) {
    dimension156.push('sponsored')
  }
  if (ebtEligible) {
    dimension156.push('ebt')
  }
  if (isSustainable) {
    dimension156.push('sustainable')
  }
  if (isMarketplaceProduct) {
    dimension156.push('marketplace')
  }
  if (boosted) {
    dimension156.push('Staple')
  }
  return dimension156
}

export const buildItemsListsForProducts = (products, sourceInfo = {}) => {
  const impressionItems = []
  products.forEach((product, index) => {
    const productName = product.description ? product.description : product.name
    const { price, regularPrice, availableDisplayCoupons } = product
    const discount = price !== regularPrice ? (Math.round(regularPrice * 100) - Math.round(price * 100)) / 100 : null
    const flags = product.flags ? Object.keys(product?.flags).filter(key => product?.flags[key] === true) : null
    const itemVariant = buildDimension156Arr(product)?.join()
    const locationId = window.sharedVue.config.globalProperties.$store.getters['UserProfile/storeNumber']
    impressionItems.push({
      item_id: `${product.prodId}`,
      item_name: productName,
      coupon: availableDisplayCoupons?.length ? availableDisplayCoupons[0].id : null,
      currency: 'USD',
      discount: discount || null,
      index: product.position || index + 1,
      item_brand: product.brand || '',
      item_category: product.rootCatName,
      item_category2: product.subcatName,
      item_category5: flags?.length >= 1 ? flags.join() : null,
      item_list_id: product.list || Object.keys(sourceInfo).length ? sourceInfo.listType : null,
      item_list_name: Object.keys(sourceInfo).length ? sourceInfo.listName : null,
      item_variant: itemVariant || null,
      location_id: locationId,
      price: product.regularPrice,
      ...(product.qty && { quantity: product.qty }),
    })
  })

  return impressionItems
}
