import { postInit } from 'api/UserPostInitAPI'
import AutoLoginAPI from 'api/AutoLoginAPI'
import CurrentUserIdAPI from 'api/CurrentUserIdAPI'
import userProfileAPI from 'api/UserProfileAPI'
import { isVersionGreaterOrEqual } from 'utils/ApiVersionCheckers'

export default {
  namespaced: true,
  state: {
    existingUsernameError: false,
    invalidUsernameError: false,
    apiError: false,
    isCheckoutFlow: false,
    loading: false,
    redirectUrl: '',
    matchPeapodUserError: false,
    registrationAttempt: null
  },
  getters: {
    credentials(state) {
      // simulate object structure api expects
      return {
        email: state.email,
        password: state.password,
        confirmPassword: state.password
      }
    },
    existingUsernameError: state => state.existingUsernameError,
    invalidUsernameError: state => state.invalidUsernameError,
    apiError: state => state.apiError,
    isCheckoutFlow: state => state.isCheckoutFlow,
    loading: state => state.loading,
    redirectUrl: state => state.redirectUrl,
    matchPeapodUserError: state => state.matchPeapodUserError,
    userInitVersion: (state, getters, rootState, rootGetters) => {
      return rootGetters['SiteConfig/varByName']('api_user_init') || 'v3.0'
    },
    verifyEmailEnabled: (state, getters) => {
      const { userInitVersion } = getters
      return isVersionGreaterOrEqual(userInitVersion, '4.0')
    }
  },
  mutations: {
    setExistingUsernameError(state, payload) {
      state.existingUsernameError = payload
    },
    setInvalidUsernameError(state, payload) {
      state.invalidUsernameError = payload
    },
    setMatchPeapodUserError(state, payload) {
      state.matchPeapodUserError = !!payload
    },
    setApiError(state, payload) {
      state.apiError = payload
    },
    setLoading(state, payload) {
      state.loading = payload
    },
    setIsCheckoutFlow(state, payload) {
      state.isCheckoutFlow = payload
    },
    resetErrors(state) {
      state.existingUsernameError = false
      state.invalidUsernameError = false
      state.apiError = false
      state.matchPeapodUserError = false
    },
    setRedirectUrl(state, payload) {
      state.redirectUrl = payload
    },
    setRegistrationAttempt(state, payload = {}) {
      state.registrationAttempt = payload
    },
    removeRegistrationAttempt(state) {
      state.registrationAttempt = null
    }
  },
  actions: {
    updateLoading({ commit }, payload) {
      commit('setLoading', payload)
    },
    updateIsCheckoutFlow({ commit }, payload) {
      commit('setIsCheckoutFlow', payload)
    },
    resetFormValidation({ commit }) {
      commit('resetErrors')
    },
    async createCredentials({
      commit, dispatch, getters, rootGetters
    }, data) {
      commit('resetErrors')
      commit('setLoading', true)
      try {
        const payload = data || {}
        const userId = rootGetters['UserProfile/userId']
        let config = {}
        const opco = rootGetters['SiteConfig/opco']
        let currentUserId = null
        // If Ghost user then get current User id
        if (userId === 2) {
          // call auto-login to have current user API called with cookies
          const { currentServiceLocationId = '' } = appConfig?.user || {}
          const apiVersion = 'v3.0'
          const autoLoginResponse = await AutoLoginAPI.perform(apiVersion, currentServiceLocationId)
          if (autoLoginResponse?.status === 200) {
            const currentUserAPIResponse = await CurrentUserIdAPI.get()
            if (currentUserAPIResponse?.status === 200) {
              currentUserId = currentUserAPIResponse?.data?.userId
            }
          }
        }
        const userID = currentUserId || userId
        config = {
          userId: userID,
          opco,
          version: getters.verifyEmailEnabled ? getters.userInitVersion : 'v3.0'
        }
        const timeStamp = new Date()
        const initResponse = await postInit(payload, config)

        await dispatch('handleEmailVerification', { initResponse, timeStamp })

        if (initResponse?.status === 201) {
          // call Refresh user profile api for update delivery address
          // This will be temp solution till we have Dynamo or session-Management goes live
          // Since Dynamo session is becoming stale when user updates happen
          await userProfileAPI.refreshUserProfile(userID)
          await dispatch('UserProfile/refetchUserId', {}, { root: true })
          await dispatch('refreshProfile')
          commit('setLoading', false)
          return initResponse
        }

        commit('setLoading', false)
        return initResponse
      } catch (err) {
        const errorResponse = err.response || err
        dispatch('setCredentialsError', errorResponse)
        commit('setLoading', false)
        return errorResponse
      }
    },
    setCredentialsError({
      commit
    }, errorResponse) {
      const errorResponseData = errorResponse.data?.response?.errors || []
      const [a] = errorResponseData
      const error = a?.code || ''
      switch (error) {
        case 'USER_EMAIL_IN_USE':
          commit('setExistingUsernameError', true)
          break
        case 'USER_EMAIL_INVALID':
          commit('setInvalidUsernameError', true)
          break
        case 'PEAPOD_OPCO_USER_EMAIL_EXISTS':
          commit('setMatchPeapodUserError', true)
          break
        default:
          commit('setApiError', true)
      }
    },
    async refreshProfile() {
      let results
      try {
        await this.dispatch('UserProfile/initQueryUserProfile')
        await this.dispatch('LoginStatus/getLoginStatus')
        results = await this.dispatch('Cart/queryCart')
      } catch (err) {
        return Promise.reject(err)
      }
      return results
    },
    handleEmailVerification({
      getters, dispatch
    }, { initResponse, timeStamp }) {
      if (getters.verifyEmailEnabled && initResponse?.status === 201) {
        const { registrationAttemptId = '', email = '' } = initResponse?.data?.response?.data || {}

        window.localStorage.setItem('registrationAttempt', JSON.stringify({
          registrationAttemptId,
          timeStamp,
          email
        }))
        dispatch('fetchRegistrationAttempt')
      } else {
        dispatch('removeRegistrationAttempt')
      }
    },
    fetchRegistrationAttempt({
      commit
    }) {
      const registrationAttemptStorage = window.localStorage.getItem('registrationAttempt')
      commit('setRegistrationAttempt', JSON.parse(registrationAttemptStorage) || null)
    },
    removeRegistrationAttempt({
      commit
    }) {
      window.localStorage.removeItem('registrationAttempt')
      commit('removeRegistrationAttempt')
    }
  }
}
