import userApis from 'api/UserAPI'
import ApiService from '@/api/'
import { LOGGING_TYPE } from 'utils/constants'

/* Time in milliseconds to group user status calls */
const STATUS_DEBOUNCE_RATE = 1000
const SECURE_LOGIN_OFFSET = 3 // minutes
const MFA_LOGIN_VERSION = 'v7.0'

const initialState = {
  secureLogin: false,
  handle: null,
  userStatus: 'G',
  timeStamp: null,
  error: false,
  apiResponse: null, // temporary bridge to system
  attemptedLoginName: null, // temporary bridge to system
  userInitResponse: null, // Promise handler -- should be Promise or null
  loginStatusResponse: null, // Promise handler -- should be Promise or null
  intendedURL: null, // Intended route, will be redirected to after guest login
}

export default {
  namespaced: true,
  state: initialState,
  getters: {
    loginStatus: state => state,
    secureLogin: state => state.secureLogin,
    userStatus: state => state.userStatus,
    handle: state => state.handle,
    error: state => state.error,
    fullyRegistered: state => state.userStatus === 'R',
    hasHandle: state => !!state.handle,
    isLoggedIn: (state, getters) => state.userStatus === 'R' || !!getters.handle,
    partiallyRegistered: (state, getters) => state.userStatus === 'G' && getters.isLoggedIn,
    isRegularUser: (state, getters) => getters.fullyRegistered && !!getters.handle,
    isGuestUser: state => state.userStatus === 'G',
    isGuestUserWithNoHandle: (state, getters) => state.userStatus === 'G' && !getters.isLoggedIn,
    apiResponse: state => state.apiResponse,
    attemptedLoginName: state => state.attemptedLoginName,
    intendedURL: state => state.intendedURL,
    loginStatusResponse: state => state.loginStatusResponse,
    timeStamp: state => state.timeStamp
  },
  mutations: {
    setLoginStatus(state, value) {
      state.timeStamp = new Date()
      Object.assign(state, {
        ...value
      })
    },
    setSecureLogin(state, value) {
      state.secureLogin = value
    },
    setUserStatus(state, value) {
      state.userStatus = value
    },
    setHandle(state, value) {
      state.handle = value
    },
    setError(state, value) {
      state.error = value
    },
    reset(state) {
      Object.assign(state, initialState)
    },
    setApiResponse(state, payload) {
      state.apiResponse = payload
    },
    setAttemptedLoginName(state, payload) {
      state.attemptedLoginName = payload
    },
    setUserInitResponse(state, payload) { state.userInitResponse = payload }, // should be Promise or null
    setLoginStatusResponse(state, payload) { state.loginStatusResponse = payload }, // should be Promise or null
    setIntendedURL(state, payload) {
      state.intendedURL = payload
    }
  },
  actions: {
    resetLoginStatus({ commit }) {
      commit('reset')
    },
    updateLoginStatus({ commit }, payload) {
      commit('setLoginStatus', payload)
    },
    setReloginError({ commit }) {
      commit('setSecureLogin', false)
      window.sharedVue.config.globalProperties.$trackClientLog('my_acc_relogin_required', {}, LOGGING_TYPE.exception)
    },
    setCurrentAsIntendedURL({ commit, getters }, { path, matched, fullPath }) {
      const hasPath = path?.length > 1 && matched?.length > 0

      if (hasPath && !getters.intendedURL) {
        commit('setIntendedURL', fullPath)
      }
    },
    async login({ commit }, { payload, apiVersion = 'v5.0' }) { // loginName, password
      if (apiVersion !== MFA_LOGIN_VERSION) {
        payload.rememberMe = true
      }
      payload.loginName = payload.loginName.toLowerCase()
      try {
        const response = await ApiService.corePost(`/api/${apiVersion}/user/login`, payload)
        commit('setAttemptedLoginName', payload.loginName)
        commit('setApiResponse', response)
        return response
      } catch (err) {
        return err.response
      }
    },
    async relogin({ commit, dispatch }, { payload, apiVersion = 'v6.0' }) { // loginName, password
      if (apiVersion !== MFA_LOGIN_VERSION) {
        payload.rememberMe = true
      }
      payload.loginName = payload.loginName.toLowerCase()
      try {
        const response = await ApiService.corePost(`/api/${apiVersion}/user/re-login`, payload)
        commit('setAttemptedLoginName', payload.loginName)
        commit('setApiResponse', response)
        dispatch('getLoginStatus')
        return response
      } catch (err) {
        return err.response
      }
    },
    async guestTransferLogin({ commit }, { payload, apiVersion = 'v6.0' }) { // loginName, password
      payload.loginName = payload.loginName.toLowerCase()
      let response
      try {
        response = await ApiService.corePut(`/api/${apiVersion}/user/guest/transfer`, payload)
        commit('setAttemptedLoginName', payload.loginName)
      } catch (error) {
        response = error?.response
      }
      commit('setApiResponse', response)
      return response
    },
    async queryUserInit({
      state, commit, dispatch, rootGetters
    }) {
      if (state.userInitResponse) {
        return state.userInitResponse
      }
      await dispatch('UserProfile/retrieveCurrentUserId', null, { root: true })
      const userId = rootGetters['UserProfile/currentUserId'] || ''
      let response = Promise.resolve({})
      // No Get Init call for Ghost user
      if (userId !== 2) {
        commit('setUserInitResponse', userApis.getInit(userId))
        response = await state.userInitResponse
        if (response.status === 200) {
          commit('setHandle', response?.data?.response?.data?.handle)
        }
      }
      commit('setUserInitResponse', null)
      return response
    },
    async tryGetSecureLogin({ getters, dispatch }) {
      const offset = SECURE_LOGIN_OFFSET * 60000 // minutes to millisec
      const currentTime = new Date().getTime()
      const triggerTime = getters.timeStamp ? getters.timeStamp.getTime() + offset : new Date().getTime()

      if (currentTime > triggerTime) {
        await dispatch('getLoginStatus')
      }
      return getters.secureLogin
    },
    async getLoginStatus({
      state, commit, dispatch,
    }) {
      // Prevent immediate double loading of API
      // Allow other callers to wait for promise
      if (state.loginStatusResponse) {
        return state.loginStatusResponse
      }
      commit('setLoginStatusResponse', dispatch('processLoginStatus'))
      const response = await state.loginStatusResponse
      const { userStatus } = response?.data?.response || {}
      if (response.status === 200) {
        if (userStatus === 'G') {
          await dispatch('queryUserInit')
        }
        if (userStatus === 'N') {
          // we do not need to retain user handle once they log out. 'N'
          await commit('setHandle', null)
        }
        commit('setLoginStatus', response.data.response)
      } else {
        commit('setError', response)
      }
      /* Debounce this call 1s so that we can check status later */
      setTimeout(() => commit('setLoginStatusResponse', null), STATUS_DEBOUNCE_RATE)
      return response
    },
    async processLoginStatus({ dispatch, rootGetters }) {
      await dispatch('UserProfile/retrieveCurrentUserId', null, { root: true })
      const userId = rootGetters['UserProfile/currentUserId'] || ''
      return userApis.getLoginStatus(userId)
    }
  }
}
