import openCheckoutModal from 'components/checkout/functions/openCheckoutModal'
import ccAddToBasket from 'utils/services/cc-add-to-basket'

const openServiceSelectionModal = () => {
  window.sharedVue.config.globalProperties.$store.commit('Modals/setActiveModal', {
    fileName: 'PdlServiceSelectionModal',
  })
}

const handleStatusModalRequest = async (to, openModalCallback, isPickupSubsRoute) => {
  const { orderId } = to.params
  const store = window.sharedVue.config.globalProperties.$store || {}

  await store.dispatch('UserProfile/initQueryUserProfile', null, { root: true })
  await store.dispatch('Cart/queryCart', null, { root: true })
  await store.dispatch('Order/initOrderStatus', { canRefresh: true })

  const pendingOrders = store.getters['Order/pendingOrders']
  const authenticatedUser = store.getters['LoginStatus/fullyRegistered']
  if (!authenticatedUser) {
    window.sessionStorage.setItem('DL-ORD-STAT', orderId)
    if (isPickupSubsRoute) {
      window.sessionStorage.setItem('PICKUP-SUBS', JSON.stringify('true'))
    }
    store.commit('Modals/setActiveModal', {
      fileName: 'PdlLoginFlow',
    })
    return
  }

  if (pendingOrders && pendingOrders.length > 0) {
    openModalCallback(pendingOrders, orderId, store)
  }
}

const openOrderStatusModal = async (pendingOrders, orderId, $store) => {
  const orderDetails = pendingOrders.find(orders => orders.orderId === orderId)
  $store.commit('SiteConfig/setOpco', appConfig.opcoTheme)
  $store.commit('SiteConfig/setOpcoId', appConfig.opcoId)
  await $store.dispatch('SiteConfig/getConfig')
  $store.commit('RecentOrders/setPendingOrderId', orderDetails.orderId)
  $store.dispatch('Order/setTrackOrder', orderDetails.orderId)
  $store.commit('Modals/setActiveModal', { fileName: 'PdlOrderStatusModal' })
}

const openOrderStatusOrDetailsModal = async (pendingOrders, orderId, $store) => {
  const orderDetails = pendingOrders.find(orders => orders.orderId === orderId)

  if (orderDetails?.serviceType === 'P') {
    $store.commit('SiteConfig/setOpco', appConfig.opcoTheme)
    $store.commit('SiteConfig/setOpcoId', appConfig.opcoId)
    await $store.dispatch('SiteConfig/getConfig')
  }

  $store.commit('RecentOrders/setPendingOrderId', orderDetails.orderId)
  $store.dispatch('Order/setTrackOrder', orderDetails.orderId)
  if (orderDetails.serviceType !== 'SHIP2ME') {
    if (orderDetails.isPastCutoff || orderDetails.orderStatusInfo?.isPastCutoff) {
      if (orderDetails.serviceType === 'DELIVERY' || orderDetails.serviceType === 'D') {
        $store.commit('Modals/setActiveModal', { fileName: 'PdlOrderStatusModal' })
      } else {
        $store.commit('Modals/setActiveModal', { fileName: 'PdlPastCutoffPickupDetailsModal' })
      }
    } else {
      $store.commit('Modals/setActiveModal', { fileName: 'PdlPreCutoffDetailsModal' })
    }
  } else {
    $store.commit('Modals/setActiveModal', { fileName: 'PdlMarketplacePendingOrderDetails' })
  }
}

export default [
  {
    name: 'slots',
    path: '/modal/slots',
    beforeEnter(to, from, next) {
      setTimeout(() => {
        const store = window.sharedVue.config.globalProperties.$store
        store.commit('ServiceSelection/setCurrentView', 'T')
        openServiceSelectionModal()
      })
      next(false)
    }
  },
  {
    name: 'slots-pickup',
    path: '/modal/slots/pickup',
    beforeEnter(to, from, next) {
      setTimeout(() => {
        const store = window.sharedVue.config.globalProperties.$store
        const userStatus = store.getters['LoginStatus/userStatus']
        store.commit(
          'ServiceSelection/setCurrentView',
          store.getters['UserProfile/serviceType'] === 'P' && userStatus === 'R' ? 'T' : 'P'
        )
        openServiceSelectionModal()
      })
      next(false)
    }
  },
  {
    name: 'slots-delivery',
    path: '/modal/slots/delivery',
    beforeEnter(to, from, next) {
      setTimeout(() => {
        const store = window.sharedVue.config.globalProperties.$store
        const userStatus = store.getters['LoginStatus/userStatus']
        store.commit(
          'ServiceSelection/setCurrentView',
          store.getters['UserProfile/serviceType'] === 'D' && userStatus === 'R' ? 'T' : 'D'
        )
        openServiceSelectionModal()
      })
      next(false)
    }
  },
  {
    name: 'address-instore',
    path: '/modal/address/instore',
    beforeEnter(to, from, next) {
      setTimeout(() => {
        window.sharedVue.config.globalProperties.$store.commit('ServiceSelection/setCurrentView', 'B')
        openServiceSelectionModal()
      })
      next(false)
    }
  },
  {
    name: 'address-delivery',
    path: '/modal/address/delivery',
    beforeEnter(to, from, next) {
      setTimeout(() => {
        const openDeliveryOrMyAccount = async () => {
          const vueInstance = window.sharedVue.config.globalProperties
          await vueInstance.$store.dispatch('UserProfile/initQueryUserProfile')
          const defaultDeliveryAddress = vueInstance.$store.getters['UserProfile/defaultDeliveryAddress']
          const openAddressSelectionModal = () => {
            vueInstance.$store.commit('ServiceSelection/setCurrentView', 'D')
            openServiceSelectionModal()
          }
          const openMyAccountDelivery = () => {
            vueInstance.$router.push('/account/user/delivery-address')
          }
          return !defaultDeliveryAddress?.addressLine1
            ? openAddressSelectionModal()
            : openMyAccountDelivery()
        }
        openDeliveryOrMyAccount()
      })
      next(false)
    }
  },
  {
    name: 'order-rating',
    path: '/modal/order-rating',
    beforeEnter(to, from, next) {
      setTimeout(() => {
        window.sharedVue.config.globalProperties.$store.commit('RecentOrders/setFromRouteNavigation', true)
        window.sharedVue.config.globalProperties.$store.commit('Modals/setActiveModal', {
          fileName: 'PdlOrderRatingModal',
          style: 'modal--narrow bg-white'
        })

        next({ path: '/' })
      })
    }
  },
  {
    name: 'checkout',
    path: '/modal/checkout',
    beforeEnter(to, from, next) {
      setTimeout(() => {
        openCheckoutModal()
      })
      const currentPath = from.path || '/'
      next({ path: currentPath })
    }
  },
  {
    name: 'order-status-default',
    path: '/modal/order-status',
    beforeEnter(to, from, next) {
      setTimeout(() => {
        const getOrderInfo = async () => {
          const store = window.sharedVue.config.globalProperties.$store || {}
          await store.dispatch('Order/initOrderStatus', { canRefresh: true })
          store.commit('Modals/setActiveModal', {
            fileName: 'PdlOrderListingModal'
          })
        }
        getOrderInfo()
      })
      const currentPath = from.path || '/'
      if (currentPath === '/order-confirmation') {
        next({ path: '/cart' })
        return
      }
      next({ path: currentPath })
    }
  },
  {
    name: 'pickup-substitutions',
    path: '/modal/pickup-substitutions/:orderId',
    beforeEnter(to, from, next) {
      setTimeout(() => {
        return handleStatusModalRequest(to, openOrderStatusModal, true)
      })
      const currentPath = from.path || '/'
      next({ path: currentPath })
    }
  },
  {
    name: 'order-status-details',
    path: '/modal/order-status/:orderId',
    beforeEnter(to, from, next) {
      setTimeout(() => {
        return handleStatusModalRequest(to, openOrderStatusOrDetailsModal, false)
      })
      const currentPath = from.path || '/'
      next({ path: currentPath })
    }
  },
  {
    name: 'pickup-preferences',
    path: '/modal/pickupPreferences',
    beforeEnter(to, from, next) {
      setTimeout(() => {
        const checkPickUpPreference = async () => {
          const vueInstance = window.sharedVue.config.globalProperties
          const { NativeContainer } = vueInstance
          const store = vueInstance.$store || {}
          await NativeContainer.isEnabledPromise()
          await store.dispatch('UserProfile/initQueryUserProfile')
          const userInfo = store.getters['UserProfile/userInfo']
          if (userInfo.information.email) {
            window.sessionStorage.setItem('deeplinkTo', 'PUP')
            await store.dispatch('Order/initOrderStatus')
            const currentOrder = await store.getters['Order/currentOrder']
            if (currentOrder.status === 'SUBMITTED' && currentOrder.serviceType === 'P') {
              store.commit('Modals/setActiveModal', {
                fileName: 'PdlCheckoutSmsModal',
              })
            }
          } else {
            window.sessionStorage.setItem('deeplinkTo', 'PUP')
            vueInstance.$router.push('/modal/login')
          }
        }
        checkPickUpPreference()
      })
      const currentPath = from.path || '/'
      next({ path: currentPath })
    }
  },
  {
    name: 'shoppable-recipe',
    path: '/modal/recipes/:recipeId',
    beforeEnter(to, from, next) {
      const loadShoppableRecipe = async () => {
        const store = window.sharedVue.config.globalProperties.$store
        await store.dispatch('UserProfile/initQueryUserProfile')
        ccAddToBasket.loadRecipe(to.params.recipeId)
      }
      setTimeout(() => {
        loadShoppableRecipe()
      })
      const currentPath = from.path || '/'
      next({ path: currentPath })
    }
  },
  {
    name: 'manage-cookies',
    path: '/modal/manage-cookies',
    beforeEnter(to, from, next) {
      window.OneTrust?.ToggleInfoDisplay()
      const currentPath = from.path || '/'
      next({ path: currentPath })
    }
  }
]
