/**
 * The purpose of this store is to abstract all the complexities
 * of unattended delivery away from the generalized Slots.js store
 * and keep all of that logic in a single unit
 */
import ApiService from '@/api/'
import UserPreferencesAPI from 'api/UserPreferencesAPI'
import {
  PODBAG_PROD_ID,
  LOGGING_TYPE,
  PACKAGING_OPTIONS,
  USER_PREFERENCE_KEYS
} from 'utils/constants'
import formatCurrency from 'utils/filters/formatCurrency'

function initialState() {
  return {
    initialized: false,
    feeAmounts: null,
    oneWayAvailable: false,
    podBagAvailable: false,
    singlePackagingMethodMarket: false,
    unattendedDeliveryAllowed: false,
    unattendedDeliveryPossible: false,
    deliveryFee: null,
    unattendedPackagingMethod: PACKAGING_OPTIONS.oneway, // 'one-way' || 'podbag' if available
    hasChosenPackagingMethod: false,
    userOwnsPodbag: false,
    supportSlotFiltering: false,
    podbagInCart: false,
    // default podbag as out of stock, this stores the product information for podbag
    podBagInfo: {
      price: '',
      flags: {
        outOfStock: true
      }
    }
  }
}

export default {
  namepsaced: true,
  state: initialState(),
  getters: {
    oneWayFee: state => formatCurrency(state.feeAmounts?.oneWayFee),
    packagingMethodDisplayName: (state) => {
      const { unattendedPackagingMethod } = state

      if (unattendedPackagingMethod === PACKAGING_OPTIONS.podbag) {
        return 'PodBag\u2122'
      }
      if (unattendedPackagingMethod === PACKAGING_OPTIONS.oneway) {
        return 'Single-Use Packaging'
      }
      return ''
    },
    podBagFee: state => formatCurrency(state.podBagInfo?.price),
    podBagOutofStock: state => state.podBagInfo?.flags?.outOfStock,
  },
  mutations: {
    setInitialized(state, payload) {
      state.initialized = payload
    },
    setFeeAmounts(state, payload) {
      state.feeAmounts = payload
    },
    setOneWayAvailable(state, payload) {
      state.oneWayAvailable = payload
    },
    setPodBagAvailable(state, payload) {
      state.podBagAvailable = payload
    },
    setSinglePackagingMethodMarket(state, payload) {
      state.singlePackagingMethodMarket = payload
    },
    setUnattendedDeliveryAllowed(state, payload) {
      state.unattendedDeliveryAllowed = payload
    },
    setUnattendedDeliveryPossible(state, payload) {
      state.unattendedDeliveryPossible = payload
    },
    setDeliveryFee(state, payload) {
      state.deliveryFee = payload
    },
    setHasChosenPackagingMethod(state, payload) {
      state.hasChosenPackagingMethod = payload
    },
    setUserOwnsPodbag(state, payload) {
      state.userOwnsPodbag = payload
    },
    setPodBagInfo(state, payload) {
      state.podBagInfo = payload
    },
    setUnattendedPackagingMethod(state, payload) {
      state.unattendedPackagingMethod = payload
    },
    setSupportSlotFiltering(state, payload) {
      state.supportSlotFiltering = payload
    },
    setPodbagInCart(state, payload) {
      state.podbagInCart = payload
    }
  },
  actions: {
    calculateDeliveryFee({ state, commit }, cartSubtotal = 0) {
      commit('setDeliveryFee', state.feeAmounts.level3Fee)

      if (cartSubtotal > state.feeAmounts.standardQty) {
        commit('setDeliveryFee', state.feeAmounts.standardFee)
      }

      if (cartSubtotal > state.feeAmounts.level2Qty) {
        commit('setDeliveryFee', state.feeAmounts.level2Fee)
      }
    },
    async getPodBagInfo({ commit, rootGetters, dispatch }, serviceLocationId) {
      try {
        if (!rootGetters['UserProfile/userInfo']) {
          await dispatch('UserProfile/initQueryUserProfile', null, { root: true })
        }
        const userId = rootGetters['UserProfile/userId']
        const isNewProductApiServices = rootGetters['SiteConfig/varByName']('feature_category_product_new_services')
        let apiPath = `/api/v3.0/user/products/${PODBAG_PROD_ID}`
        if (isNewProductApiServices) {
          apiPath = `/api/v5.0/products/info/${userId}/${serviceLocationId}/${PODBAG_PROD_ID}`
        }
        const response = await ApiService.get(apiPath, {
          params: {
            extendedInfo: true,
            flags: true,
            hkInclude: true,
            nutrition: true,
            substitute: true,
            serviceLocationId
          }
        })

        if (![200, 201].includes(response.status)) {
          // improper status returned from API
          throw new Error('get_podbag_info_error')
        }

        commit('setPodBagInfo', response.data.response.products[0])
      } catch (e) {
        window.sharedVue.config.globalProperties.$trackClientLog(e.message, {}, LOGGING_TYPE.exception)
        commit('setPodBagInfo', {
          price: '',
          flags: {
            outOfStock: true
          }
        })
      }
    },
    async checkUserOwnsPodbag({ commit, rootGetters }) {
      try {
        const userId = rootGetters['UserProfile/userId']
        const userAlreadyOwnsPodbag = await UserPreferencesAPI.get(
          USER_PREFERENCE_KEYS.HAS_POD_BAG, userId
        )

        if (userAlreadyOwnsPodbag.status !== 200) {
          throw new Error('get_podbag_preference_error')
        }

        const { value } = userAlreadyOwnsPodbag.data.response.preference

        if (typeof value !== 'boolean') {
          throw new Error('get_podbag_preference_error')
        }

        commit('setUserOwnsPodbag', value)
      } catch (e) {
        window.sharedVue.config.globalProperties.$trackClientLog(e.message, {}, LOGGING_TYPE.exception)
        commit('setUserOwnsPodbag', false)
      }
    },
    async checkPodbagInCart({ rootGetters, commit }) {
      const cartItems = rootGetters['Cart/getCart'].items
      commit('setPodbagInCart', cartItems.some(item => item.prodId === PODBAG_PROD_ID))
    },
    async checkUnattendedSlotSelected({ rootState, rootGetters, commit }) {
      // if user has an unattended slot its an indication they already picked a pacakging method
      const slotSelectedAlready = rootState.Slots.selectedSlotId !== 0

      if (slotSelectedAlready) {
        const selectedSlot = rootGetters['Slots/selectedSlot']

        if (selectedSlot.unattended && selectedSlot.available) {
          commit('setHasChosenPackagingMethod', true)
        }
      }
    },
    /**
     * NOTE: this looks very similar to the below action
     * but is called in the case when user either purchased
     * a Podbag in the past or has one in their cart already
     */
    async configurePodbagConfirmedOption({ commit }) {
      commit('setUnattendedPackagingMethod', PACKAGING_OPTIONS.podbag)
      commit('setSupportSlotFiltering', true)
      commit('setHasChosenPackagingMethod', true)
    },
    async configurePodbagOnlyMarket({ state, commit }) {
      commit('setUnattendedPackagingMethod', PACKAGING_OPTIONS.podbag)
      commit('setHasChosenPackagingMethod', true)

      const { outOfStock } = state.podBagInfo?.flags || {}
      commit('setSupportSlotFiltering', !outOfStock)
    },
    async configureSingleUseOnlyMarket({ commit }) {
      commit('setUnattendedPackagingMethod', PACKAGING_OPTIONS.oneway)
      commit('setHasChosenPackagingMethod', true)
      commit('setSupportSlotFiltering', true)
    },
    async addPodBagToCart({ state, dispatch }) {
      await dispatch('checkPodbagInCart')

      if (!state.podbagInCart) {
        const payload = {
          items: [{
            productId: PODBAG_PROD_ID,
            quantity: 1
          }]
        }

        await dispatch('Cart/updateCartItems', payload, { root: true })
      }
    },
    async removePodBagFromCart({ state, dispatch }) {
      await dispatch('checkPodbagInCart')

      if (state.podbagInCart) {
        const payload = {
          items: [{
            productId: PODBAG_PROD_ID
          }]
        }

        await dispatch('Cart/deleteFromCart', payload, { root: true })
      }
    },
    /**
     *
     * @action configurePodbagInCart
     *
     * Note: this is called at the time of slot reservation.  There are other points
     * in the user flow where some of the factors related to podbag are changed but
     * slot reservation is the correct point to add/remove podbag from cart because
     * of the final say in the slot being attended/unattended.
     */
    async configurePodbagInCart({ state, dispatch }, slotUnattended) {
      if (slotUnattended && state.unattendedPackagingMethod === PACKAGING_OPTIONS.podbag && !state.userOwnsPodbag) {
        await dispatch('addPodBagToCart')
      } else {
        await dispatch('removePodBagFromCart')
      }
    },
    async choosePackagingOption({ commit }, option) {
      try {
        if (option !== PACKAGING_OPTIONS.podbag && option !== PACKAGING_OPTIONS.oneway) {
          throw new Error('unattended_packaging_unknown_option')
        }
        const packagingMethod = option

        window.sharedVue.config.globalProperties.$trackClientLog(
          'slots_change_packaging_method',
          { packagingMethod },
          LOGGING_TYPE.event
        )
        commit('setUnattendedPackagingMethod', packagingMethod)
        commit('setHasChosenPackagingMethod', true)
      } catch (e) {
        window.sharedVue.config.globalProperties.$trackClientLog(
          e.message,
          {},
          LOGGING_TYPE.exception
        )
      }
    },
    // eslint-disable-next-line complexity
    async initialize({
      state,
      rootGetters,
      commit,
      dispatch
    }) {
      // logic to run only once per session
      if (state.initialized) {
        return
      }

      commit('setInitialized', true)
      const subtotal = rootGetters['Cart/getSubTotal']
      const { refData, information } = rootGetters['UserProfile/userInfo']
      const { userType } = information
      const personalUseCustomer = userType === 'C'

      // collect market information from service location
      const {
        feeAmounts,
        oneWayAvailable,
        podBagAvailable,
        unattendedDeliveryAllowed,
        serviceLocationId
      } = refData.customerDefaultDeliveryServiceLocation

      commit('setFeeAmounts', feeAmounts)
      commit('setOneWayAvailable', oneWayAvailable)
      commit('setPodBagAvailable', podBagAvailable)
      commit('setUnattendedDeliveryAllowed', unattendedDeliveryAllowed)
      commit('setUnattendedDeliveryPossible', podBagAvailable || oneWayAvailable)

      // find delivery fee based on market data
      dispatch('calculateDeliveryFee', subtotal)

      if (state.unattendedDeliveryAllowed && personalUseCustomer && state.unattendedDeliveryPossible) {
        // user has unattended delivery as an option to them
        dispatch('checkUnattendedSlotSelected')
        await dispatch('getPodBagInfo', serviceLocationId)

        // following lines check data about user and market to make choices about packaging settings
        const bothMethodsAvailable = podBagAvailable && oneWayAvailable
        commit('setSinglePackagingMethodMarket', !bothMethodsAvailable)

        await dispatch('checkUserOwnsPodbag')
        await dispatch('checkPodbagInCart')

        if (state.userOwnsPodbag || state.podbagInCart) {
          dispatch('configurePodbagConfirmedOption')
          return
        }

        if (state.podBagAvailable) {
          if (state.oneWayAvailable) {
            // Generic handling for both available
            commit('setSupportSlotFiltering', true)
          } else {
            // PodBag-Only market
            dispatch('configurePodbagOnlyMarket')
          }
        } else if (state.oneWayAvailable) {
          // Single-Use-Only market
          dispatch('configureSingleUseOnlyMarket')
        }
      }
    }
  }
}
