<!-- eslint-disable max-len !-->
<template>
  <polyline
    fill="none"
    :stroke-width="strokeWidth"
    stroke-linecap="square"
    transform="translate(16.485250, 16.242750) rotate(-270.000000) translate(-16.485250, -16.242750) "
    points="12.2425 7.7575 20.728 16.2425 12.2425 24.728"
  />
</template>
<script>
export default {
  props: {
    strokeWidth: {
      type: String,
      default: '3'
    }
  }
}
</script>
