<template>
  <div class="spyglass-help_wrapper">
    <button
      class="spyglass-help_tab"
      @click.stop="spyglassStateActive = !spyglassStateActive"
    >
      <div>{{ spyglassStateActive ? 'CLOSE INFO' : 'OPEN INFO' }}</div>
  </button>
    <div
      v-if="spyglassStateActive"
      class="spyglass-help_container animation-fade"
    >
      <div class="spyglass-help_content">
        <p class="spyglass_jira-id spyglass_jira-id--content">
          <span class="spyglass_label">Article Id:&nbsp;</span>
          <a
            class="spyglass_jira-id-link"
            :href="`http://jiracore.peapod.com/browse/${article.key}`"
            target="_blank"
            rel="noopener noreferrer"
          >
            {{ article.key }}
          </a>
        </p>
        <p class="spyglass_start-date">
          <span class="spyglass_label">Start Date:&nbsp;</span>
          {{ article.startDate }}
        </p>
        <p class="spyglass_end-date">
          <span class="spyglass_label">End Date:&nbsp;</span>
          {{ article.endDate }}
        </p>
        <p
          v-if="article.customerStatus"
          class="spyglass_customer-status"
        >
          <span class="spyglass_label">Customer Status:&nbsp;</span>
          <span>{{ article.customerStatus.join(', ') }}</span>
        </p>
        <p
          v-if="article.customerType"
          class="spyglass_customer-type"
        >
          <span class="spyglass_label">Customer Type:&nbsp;</span>
          <span>{{ article.customerType.join(', ') }}</span>
        </p>
        <p
          v-if="article.opcos && !article.storeCodes || article.opcos && article.storeCodes"
          class="spyglass_opcos"
        >
          <span class="spyglass_label">Opcos:&nbsp;</span>
          <span>{{ article.opcos.join(', ') }}</span>
        </p>
        <p
          v-if="!article.opcos && article.storeCodes || article.opcos && article.storeCodes"
          class="spyglass_store-codes"
        >
          <span class="spyglass_label">Store Codes:&nbsp;</span>
          <span>{{ article.storeCodes.join(', ') }}</span>
        </p>
        <p
          v-if="!article.opcos && !article.storeCodes "
          class="spyglass_targeting"
        >
          <span class="spyglass_label">Targeting:&nbsp;</span>
          <span>All Stores</span>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    article: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    spyglassStateActive: false,
  }),
}
</script>
