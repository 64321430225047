/** Check if Feature is Brand and Service location level Enabled
 * @module ebtFeatureEnabled
 * @return Boolean - Feature enable or not
* */

export default () => {
  const $store = window?.sharedVue?.config?.globalProperties?.$store
  const { serviceLocationIds, stateNames } = $store.state.EbtPayment
  const deliveryServiceLocation = $store.getters['UserProfile/deliveryServiceLocation']
  const varByName = $store.getters['SiteConfig/varByName']
  const state = deliveryServiceLocation.state || ''
  const serviceLocationId = deliveryServiceLocation.serviceLocationId || ''
  const ebtServiceLocationActive = serviceLocationIds?.includes(parseInt(serviceLocationId, 10))
  const ebtStatesActive = stateNames?.includes(state)
  const ebtActive = ebtServiceLocationActive || ebtStatesActive
  return varByName('feature_ebt') && ebtActive
}
