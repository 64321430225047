/* eslint-disable sonarjs/no-useless-catch */
const optimizelyMobileService = async (type, payload) => {
  if (type === 'inAppReviews') {
    try {
      //  Pass in the loyalty info to capability
      await window.sharedVue.config.globalProperties.NativeContainer.capabilities.InAppReviewService
        .triggerInAppReview({ loyaltyId: payload.id, action: payload.action })
    } catch (err) {
      console.error(err)
      throw err
    }
  }
}

export default {
  optimizelyMobileService
}
