<template>
  <!-- Component for Text Area  -->
  <div class="field">
    <PdlInputLabel
      v-bind="labelProps"
    >
      <slot name="label" />
    </PdlInputLabel>
    <slot
      class="field_row"
      name="before"
    />
    <div class="field_row">
      <textarea
        v-model="tempValue"
        v-bind="attrs"
        :id="id"
        :ref="'input'"
        :name="name"
        :aria-label="ariaLabel"
        :aria-describedby="hasMessages ? `${id}-messages` : null"
        :form-id="formId"
        class="forms_input"
        :class="inputClasses"
        @input="v.$touch()"
      />
    </div>
    <slot
      class="field_row"
      name="after"
    />
    <InputMessages
      :id="`${id}-messages`"
      class="field_row"
      :errors="errors"
      :description="description"
      :has-errors="hasErrors"
      :has-persistent-messages="hasPersistentMessages"
      :persistent-messages="persistentMessagesList"
    />
  </div>
</template>

<script>
import InputBase from './PdlInputBaseMixin'

export default {
  mixins: [InputBase]
}
</script>
