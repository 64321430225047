<template>
  <div
    class="progress-bar"
    :class="getUnitAlignment"
  >
    <div class="progress-bar_units">
      {{ getProgressWithUnit }} of {{ getGoalWithUnit }} {{ description }}
    </div>
    <div class="progress-bar_background">
      <div
        class="absolute h-full left-0 rounded-lg"
        :class="{
          'bg-progress-bar' : percentComplete < 100,
          'bg-success' : percentComplete >= 100
        }"
        :style="getPercentComplete"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    progress: {
      type: Number,
      required: true
    },
    goal: {
      type: Number,
      required: true
    },
    unit: {
      type: String,
      required: true
    },
    percentComplete: {
      type: Number,
      required: true
    },
    isDollarValue: {
      type: Boolean,
      default: false
    },
    unitAlignment: {
      type: String,
      default: 'center'
    },
    description: {
      type: String,
      default: ''
    }
  },
  computed: {
    getProgressWithUnit() {
      return this.isDollarValue ? `${this.unit}${this.progress}` : `${this.progress}`
    },
    getGoalWithUnit() {
      return this.isDollarValue ? `${this.unit}${this.goal}` : `${this.goal} ${this.unit}`
    },
    getUnitAlignment() {
      switch (this.unitAlignment) {
        case 'left':
          return 'progress-bar--unit-left'
        case 'right':
          return 'progress-bar--unit-right'
        default:
          return 'progress-bar--unit-center'
      }
    },
    getPercentComplete() {
      if (Number.isNaN(this.percentComplete)) {
        return { width: '0%' }
      }
      return { width: `${this.percentComplete}%` }
    }
  }
}
</script>
