import { USER_PREFERENCE_KEYS, LOGGING_TYPE } from 'utils/constants'
import ServiceLocationsAPI from 'api/ServiceLocationsAPI'
import UserPreferencesAPI from 'api/UserPreferencesAPI'

class AddressSync {
  constructor() {
    this.city = null
  }

  static isolateDeliveryCityAndZip(id, addressList) {
    if (!addressList[id] || typeof addressList[id] !== 'object') {
      return null
    }

    const { city, zip } = addressList[id]

    return { city, zip }
  }

  static buildServiceLocationParamObject(userInfo, deliveryServiceLocation) {
    const { serviceType } = deliveryServiceLocation
    const customerType = userInfo?.information?.userType || 'C'

    const deliveryAddressId = userInfo?.addressDefaults?.deliveryAddressId || '0'
    const userAddressList = userInfo?.addresses || []

    const details = AddressSync.isolateDeliveryCityAndZip(deliveryAddressId, userAddressList)

    const { city, zip } = details

    return {
      zip,
      city,
      customerType,
      serviceType
    }
  }

  isolateLocationByCity(serviceLocations) {
    const matchingServiceLocation = serviceLocations.find(
      serviceLocation => serviceLocation?.location?.city === this.city
    )
    return matchingServiceLocation?.location?.id
  }

  async saveLocationByAddress(userInfo, deliveryServiceLocation) {
    const params = AddressSync.buildServiceLocationParamObject(userInfo, deliveryServiceLocation)

    this.city = params?.city

    const response = await ServiceLocationsAPI.get({ params })

    const { locations } = response?.data?.response || {}
    const correctDeliveryServiceLocation = this.isolateLocationByCity(locations)

    const userId = userInfo?.information?.userId
    const deliveryPref = USER_PREFERENCE_KEYS.PREFERRED_DELIVERY
    const preferenceUpdate = await UserPreferencesAPI.update(
      deliveryPref, correctDeliveryServiceLocation, userId
    )

    if (typeof preferenceUpdate !== 'object' || preferenceUpdate.status !== 200) {
      window.sharedVue.config.globalProperties.$trackClientLog('PREFERRED_DELIVERY_SYNC_FAIL', {}, LOGGING_TYPE.e)
    }
  }
}

const addressSync = new AddressSync()

export default addressSync
