<template>
  <div>
    <PdlIconBase
      v-if="!isLink"
      id="brand-logo"
      :icon-title="iconTitle"
      :container-class="iconStyleClass"
      :vb-width="iconWidth"
      :vb-height="iconHeight"
    >
      <OpcoLogo />
    </PdlIconBase>
    <a
      v-if="isLink"
      id="brand-logo_link"
      href="#"
      aria-label="Home"
      :class="linkClass"
      @click.prevent="onHeaderLogoClick"
    >
      <PdlIconBase
        :icon-title="iconTitle"
        :container-class="iconStyleClass"
        :vb-width="iconWidth"
        :vb-height="iconHeight"
      >
        <OpcoLogo />
      </PdlIconBase>
    </a>
  </div>
</template>

<script>
import OpcoLogo from 'components/OpcoLogo'

const brandList = ['GNTL', 'GNTC', 'MRTN', 'STSH', 'FDLN', 'HNFD', 'PPOD']

export default {
  components: {
    OpcoLogo
  },
  props: {
    opcoName: {
      type: String,
      required: true,
    },
    isMobileApp: {
      type: Boolean,
      default: false
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    isLink: {
      type: Boolean,
      default: true
    },
    linkClass: {
      type: [String, Object, Array],
      default: ''
    }
  },
  data: () => ({
    iconConfig: {
      PPOD: {
        title: 'Peapod',
        styleClass: 'vector-icon-peapod-logo',
        styleClassMobile: 'vector-icon-peapod-logo-mobile',
        width: 110
      },
      GNTL: {
        title: 'Giant Food',
        styleClass: 'vector-icon-gntl-logo',
        styleClassMobile: 'vector-icon-gntl-logo-mobile',
        width: 140
      },
      GNTC: {
        title: 'Giant Food Stores',
        styleClass: 'vector-icon-gntc-logo',
        styleClassMobile: 'vector-icon-gntc-logo-mobile',
        width: 158
      },
      MRTN: {
        title: 'Martins',
        styleClass: 'vector-icon-mrtn-logo',
        styleClassMobile: 'vector-icon-mrtn-logo-mobile',
        width: 233
      },
      STSH: {
        title: 'Stop and Shop',
        styleClass: 'vector-icon-sns-logo',
        styleClassMobile: 'vector-icon-sns-logo-mobile',
        width: 170,
        widthMobile: 140
      },
      FDLN: {
        title: 'Food Lion',
        styleClass: 'vector-icon-fdln-logo',
        styleClassMobile: 'vector-icon-fdln-logo-mobile',
        width: 170,
        widthMobile: 140
      },
      HNFD: {
        title: 'Hannaford',
        styleClass: 'vector-icon-hnfd-logo',
        styleClassMobile: 'vector-icon-hnfd-logo-mobile',
        width: 170,
        widthMobile: 140,
        height: 120
      }
    }
  }),
  computed: {
    isBrand() {
      return _.contains(brandList, this.opcoName) && this.opcoName !== 'PPOD'
    },
    whichIcon() {
      return (this.isBrand) ? this.iconConfig[this.opcoName] : this.iconConfig.PPOD
    },
    iconComponent() {
      return this.whichIcon.component
    },
    iconTitle() {
      return `${this.whichIcon.title} Logo`
    },
    iconStyleClass() {
      return (this.isMobileApp) ? this.whichIcon.styleClassMobile : this.whichIcon.styleClass
    },
    isMobileIconSize() {
      return this.isMobile && this.whichIcon.widthMobile
    },
    iconWidth() {
      return (this.isMobileIconSize) ? this.whichIcon.widthMobile : this.whichIcon.width
    },
    iconHeight() {
      return (this.isMobileIconSize) ? this.whichIcon.heightMobile : this.whichIcon.height
    }
  },
  methods: {
    onHeaderLogoClick() {
      return this.$emit('home-nav-item-clicked')
    },
    isOpco(opco) {
      return this.opcoName === opco
    }
  }
}
</script>
