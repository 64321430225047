import ApiService from '@/api/'

const DEFAULT = {
  ROWS: 50,
  START: 0,
  SORT: 'bestMatch asc',
  FLAGS: true
}

export default {
  /**
    *   Fetches qualifying products for a given offer
    *   @param {String} userId - Required. Current User Id
    *   @param {String} serviceLocationId - Required. Current Service Location of User
    *   @param {String} id - Id of offer to fetch products for
    *   @param {Object} options - Parameters for which products to retrieve
    *   @param {Number} options.start - Required. Index to start at for returned products
    *   @param {Number} options.rows -  Required. Number of products to fetch after start index
    *   @param {String} options.sort - Required. Order for the products to be sorted in
    *   @param {Boolean} options.flags - Required. OWhether to return product flags or not
    *   @param {String} options.isNewProductApiServices - Required. To identify if new api services are enabled
    */
  async getQualifyingProducts(userId, serviceLocationId, id, {
    start, rows, sort, flags, isNewProductApiServices
  }) {
    const params = {
      ...id && { couponId: id },
      start: start || DEFAULT.START,
      rows: rows || DEFAULT.ROWS,
      sort: sort || DEFAULT.SORT,
      flags: flags || DEFAULT.FLAGS

    }
    if (isNewProductApiServices) {
      return ApiService.get(`/api/v5.0/products/${userId}/${serviceLocationId}`, { params })
    }
    return ApiService.get(`/api/v4.0/user/products`, { params })
  },
}
