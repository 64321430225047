<template>
  <div>
    <div
      v-if="hasViewOptions"
      :class="[
      'title-bar_view-options',
        {
          'border-2 border-solid border-gray-100' : !isTitleBar,
          'title-bar_view-options--hide-on-medium' : hideMediumViewOptions
        }
      ]"
    >
      <button
        :class="[
          'title-bar_view-button',
          {
            'title-bar_view-button--grid-active': isGridView && isTitleBar,
            'bg-hover rounded-l-[.8rem]': isListView && !isTitleBar
          }
        ]"
        :disabled="isListView"
        aria-label="list view"
        @click="changeProductView('list-view')"
      >
        <PdlIconBase
          icon-title="List View Icon"
          :icon-class="{
            'svg-icon icon-fill--reverse': isTitleBar,
            'svg-icon vector-icon-fill--primary-black': !isTitleBar && isListView
          }"
          container-class="vector-icon-size--small flex-center"
        >
          <PdlIconListView />
        </PdlIconBase>
      </button>
      <button
        :class="[
          'title-bar_view-button',
          {
            'title-bar_view-button--list-active': isListView && isTitleBar,
            'bg-hover rounded-r-[.8rem]': isGridView && !isTitleBar
          }
        ]"
        :disabled="isGridView"
        aria-label="grid view"
        @click="changeProductView('grid-view')"
      >
        <PdlIconBase
          icon-title="Grid View Icon"
          :icon-class="{
            'svg-icon icon-fill--reverse': isTitleBar,
            'svg-icon vector-icon-fill--primary-black': !isTitleBar && isGridView
          }"
          container-class="vector-icon-size--small flex-center"
        >
          <PdlIconGridView />
        </PdlIconBase>
      </button>
    </div>
  </div>
</template>

<script>
import {
  mapGetters, mapState, mapMutations, mapActions
} from 'vuex'
import { USER_PREFERENCE_KEYS, GTM_GA4 } from 'utils/constants'
import UserPreferencesAPI from 'api/UserPreferencesAPI'
import PdlIconGridView from 'components/icons/types/PdlIconGridView'
import PdlIconListView from 'components/icons/types/PdlIconListView'

export default {
  components: {
    PdlIconGridView,
    PdlIconListView
  },
  props: {
    hasViewOptions: {
      type: Boolean,
      default: false
    },
    hideMediumViewOptions: {
      type: Boolean,
      default: false
    },
    isTitleBar: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState({
      activeView: state => state.ProductTile.activeView,
      subCategoryName: state => state.BrowseAislesCategories.subCategoryName,
      savingsCategoryName: state => state.ScheduledContent.savingsCategoryName
    }),
    ...mapGetters({
      userId: 'UserProfile/userId',
      isNativeApp: 'NativeContainer/isNativeApp',
      pageTitle: 'TitleBar/pageTitle'
    }),
    isGridView() {
      return this.activeView === 'grid-view'
    },
    isListView() {
      return this.activeView === 'list-view'
    }
  },
  created() {
    this.getShelfValue({ userPref: USER_PREFERENCE_KEYS.SHELF_VIEW }).then((activeView) => {
      this.setActiveView(activeView.value)
    })
  },
  methods: {
    ...mapMutations({
      setActiveView: 'ProductTile/setActiveView'
    }),
    ...mapActions({
      getShelfValue: 'UserPref/getUserPrefResponse'
    }),
    changeProductView(view) {
      this.setActiveView(view)
      UserPreferencesAPI.update(USER_PREFERENCE_KEYS.SHELF_VIEW, view, this.userId)
      let location
      switch (this.pageTitle) {
        case 'Browse Aisles':
          location = `${this.pageTitle} - ${this.subCategoryName}`
          break
        case 'Savings':
          location = `${this.pageTitle} - ${this.savingsCategoryName}`
          break
        default:
          location = `${this.pageTitle}`
      }
      this.$trackGA4Event(GTM_GA4.click, {
        link_text: `${view} [icon]`,
        action: `Change to ${view}`,
        type: 'view',
        site_location: location,
      })
    }
  }
}
</script>
