import ApiService from '@/api/'
import { onLogoutResponse } from 'components/auth/services/auth-helper-service'

const UserAPI = function () {
  /**
   * Logs the user out.
   *
   * @param skipLogoutResponse - skips the automatic call to authHelperService.onLogoutResponse after logout
   * @returns {Promise<unknown>}
   */
  const logOut = ({ skipLogoutResponse = false } = {}) => new Promise((resolve, reject) => {
    (async () => {
      try {
        const { userId } = window.appConfig.user
        const logoutPath = `/api/v3.0/user/${userId}/logout`
        await ApiService.post(logoutPath)

        window.sessionStorage.removeItem('su_order_mode')
        window.sessionStorage.removeItem('reference_info')
        window.sessionStorage.removeItem('guest_searched_zip')

        const $store = window?.sharedVue?.config?.globalProperties?.$store
        if ($store) $store.dispatch('SearchTerms/clearUsedKeywords')

        if (!skipLogoutResponse) onLogoutResponse()
      } catch (error) {
        // Acknowledge the error here, but allow handling elsewhere if necessary.
        console.error('There was a problem logging out:', error)
        return reject(error)
      }

      return resolve()
    })()
  })

  const getLoginStatus = (userId) => {
    return ApiService.get(`/api/v3.0/profile/user/${userId}/status`)
  }

  const getInit = (userId = '') => {
    return ApiService.get(`/api/v3.0/user/${userId}/init`)
  }

  // RESPONSE LIST
  return {
    getLoginStatus,
    getInit,
    logOut,
  }
}

const userAPI = new UserAPI()
export default userAPI
