<template>
  <div class="show-more_container">
    <button
      class="button button--second"
      :class="{
        'loading': loading,
        'button-width--full': isMobile,
        'button-width--lg': !isMobile
      }"
      @click="showMore"
    >
      Show More
      <PdlLoadingSpinner />
    </button>
  </div>
</template>
<script>
import PdlLoadingSpinner from '@/shared/interactions/PdlLoadingSpinner'

export default {
  components: {
    PdlLoadingSpinner
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    showMore() {
      this.$emit('show-more')
    }
  }
}
</script>
