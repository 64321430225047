import ApiService from '@/api/'
import UserPreferencesAPI from 'api/UserPreferencesAPI'
import { USER_PREFERENCE_KEYS } from 'utils/constants'
import sortAlphabetically from 'utils/filters/sortAlphabetically'

const sortOptionsConfig = {
  department: {
    name: 'Department',
    value: 'category asc, name asc'
  },
  location: {
    name: 'Product Location',
    value: 'aisle asc, department asc, name asc'
  },
  name: {
    name: 'Name: A-Z',
    value: 'name asc'
  },
  date: {
    name: 'Date Added: Most Recent',
    value: 'date desc'
  }
}

function constructProductIdList(products) {
  if (!Array.isArray(products)) {
    return null
  }

  return products.slice().map(product => product.prodId).join(',')
}

export default {
  namespaced: true,
  state: {
    showConsolidationModal: false,
    showMergeModal: false,
    productIds: [],
    weeklyAdIds: [],
    couponIds: [],
    currentList: {},
    writeInList: [],
    productList: [],
    couponList: [],
    weeklyAdList: [],
    loading: false,
    printViewProducts: {},
    listId: null,
    sortOptionsConfig,
    sortOptions: [
      sortOptionsConfig.department,
      sortOptionsConfig.location,
      sortOptionsConfig.name,
      sortOptionsConfig.date
    ],
    userPrefSort: sortOptionsConfig.department.value,
    productsSelectedCount: 0,
    shoppingListItemsChecked: {
      writeIns: {},
      products: {},
      weeklyAdDeals: {},
      coupons: {}
    },
    selectedProducts: {},
    isListEmpty: false,
    isUnsortedList: false,
    checkSortStatusOfShoppingList: false,
    autoAddClippedCouponsPref: '',
    userListsQueried: false,
    showEmptyState: false
  },
  getters: {
    showConsolidationModal: state => state.showConsolidationModal,
    showMergeModal: state => state.showMergeModal,
    listId: state => state.listId,
    productIds: state => state.productIds,
    weeklyAdIds: state => state.weeklyAdIds,
    couponIds: state => state.couponIds,
    printViewProducts: state => state.printViewProducts,
    loading: state => state.loading,
    currentList: state => state.currentList,
    writeInList: state => state.writeInList,
    productList: state => state.productList,
    weeklyAdList: state => state.weeklyAdList,
    couponList: state => state.couponList,
    listCount: state => state.writeInList.length
      + state.productList.length
      + state.weeklyAdList.length
      + state.couponList.length,
    sortOptionsConfig: state => state.sortOptionsConfig,
    sortOptions: state => state.sortOptions,
    userPrefSort: state => state.userPrefSort,
    productsSelectedCount: state => state.productsSelectedCount,
    shoppingListItemsChecked: state => state.shoppingListItemsChecked,
    selectedProducts: state => state.selectedProducts,
    isListEmpty: state => state.writeInList.length === 0
      && state.productList.length === 0
      && state.weeklyAdList.length === 0
      && state.couponList.length === 0,
    isUnsortedList: state => state.isUnsortedList,
    checkSortStatusOfShoppingList: state => state.checkSortStatusOfShoppingList,
    autoAddClippedCouponsPref: state => state.autoAddClippedCouponsPref,
    userListsQueried: state => state.userListsQueried,
    showEmptyState: state => state.showEmptyState
  },
  mutations: {
    setShowConsolidationModal(state, value) {
      state.showConsolidationModal = value === 'N'
    },
    setShowMergeModal(state, value) {
      state.showMergeModal = value === 'M'
    },
    pushToProductList(state, value) {
      // until sl api map/returns coupons
      const payload = value.coupon ? _.omit(value, 'coupon') : value
      state.productList.push(payload)
      state.productIds.push(value.prodId)
      state.isUnsortedList = true
    },
    removeFromProductList(state, value) {
      const prodIndex = state.productList.findIndex(product => product.prodId === value)
      const idIndex = state.productIds.findIndex(id => id === value)
      if (prodIndex > -1) {
        state.productList.splice(prodIndex, 1)
      }
      if (idIndex > -1) {
        state.productIds.splice(idIndex, 1)
      }
    },
    pushToWeeklyAdList(state, value) {
      state.weeklyAdList.push(value)
      state.weeklyAdIds.push(value.id)
      state.isUnsortedList = true
    },
    removeFromWeeklyAdList(state, value) {
      // make sure we're comparing strings to strings
      const index = state.weeklyAdList.findIndex(weeklyAd => `${weeklyAd.id}` === `${value}`)
      const idIndex = state.weeklyAdIds.findIndex(id => `${id}` === `${value}`)
      if (index > -1) {
        state.weeklyAdList.splice(index, 1)
      }
      if (idIndex > -1) {
        state.weeklyAdIds.splice(idIndex, 1)
      }
    },
    pushToCouponList(state, value) {
      state.couponList.push(value)
      state.couponIds.push(value.id)
      state.isUnsortedList = true
    },
    removeFromCouponList(state, value) {
      const index = state.couponList.findIndex(coupon => coupon.id === value)
      const idIndex = state.couponIds.findIndex(id => id === value)
      if (index > -1) {
        state.couponList.splice(index, 1)
      }
      if (idIndex > -1) {
        state.couponIds.splice(idIndex, 1)
      }
    },
    setCouponAsClipped(state, id) {
      const index = state.couponList.findIndex(coupon => coupon.id === id)
      if (index > -1) {
        const coupons = [...state.couponList]
        coupons[index].clipped = true
        state.couponList = coupons
      }
    },
    setLoading(state, value) {
      state.loading = value
    },
    setItemIds(state, value) {
      state.productIds = value?.productIds ? value.productIds : []
      state.weeklyAdIds = value?.weeklyAdIds ? value.weeklyAdIds : []
      state.couponIds = value?.couponIds ? value.couponIds : []
    },
    setCurrentList(state, value) {
      state.currentList = value
      state.writeInList = value?.text?.length ? value.text : []
      state.productList = value?.products?.length ? value.products : []
      state.weeklyAdList = value?.weeklyAds?.length ? value.weeklyAds : []
      state.couponList = value?.coupons?.length ? value.coupons : []
    },
    addToWriteInList(state, value) {
      if (state.userPrefSort === sortOptionsConfig.date.value) {
        state.writeInList.unshift({ name: value })
      } else {
        state.writeInList.push({ name: value })
      }
    },
    sortWriteInList(state, value) {
      state.writeInList = [...value].sort(sortAlphabetically('name'))
    },
    removeFromWriteInList(state, value) {
      const index = state.writeInList.findIndex(item => item.name === value)
      state.writeInList.splice(index, 1)
    },
    setListId(state, value) {
      state.listId = value
    },
    setPrintViewProducts(state, value) {
      state.printViewProducts = value
    },
    setUserPrefSort(state, value) {
      // Override any preference that we are not currently supporting with the default.
      if (!state.sortOptions.some(option => option.value === value)) {
        state.userPrefSort = sortOptionsConfig.department.value
      } else {
        state.userPrefSort = value
      }
    },
    setShoppingListItemChecked(state, value) {
      // The value param is a config obj: { type: 'coupons', id: '123', checked: true, tagData: {} }.
      if (!value.type || !value.id) {
        throw new Error('setShoppingListItemChecked: value must have type and id')
      }
      if (value.checked && !value.tagData) {
        throw new Error('setShoppingListItemChecked: checked items must have tag data')
      }
      // Duplicate the entire obj so the state can be watched.
      const checkedItems = {
        ...state.shoppingListItemsChecked
      }
      if (!value.checked) {
        delete checkedItems[value.type][value.id]
      } else {
        checkedItems[value.type][value.id] = value.tagData
      }
      state.shoppingListItemsChecked = checkedItems
    },
    setAllItemsUnchecked(state) {
      state.shoppingListItemsChecked = {
        writeIns: {},
        products: {},
        weeklyAdDeals: {},
        coupons: {}
      }
    },
    setShoppingListProductsChecked(state, value) {
      // The value param is an obj that replaces shoppingListItemsChecked.products,
      // such as an empty obj or an obj in this format: { 123: true, 234: true }.
      // Duplicate the entire obj so the state can be watched.
      const checkedItems = {
        ...state.shoppingListItemsChecked
      }
      checkedItems.products = value
      state.shoppingListItemsChecked = checkedItems
    },
    setSelectedProduct(state, value) {
      const selectedProducts = {
        ...state.selectedProducts
      }

      if (value.checked) {
        selectedProducts[value.prodId] = value
      } else {
        delete selectedProducts[value.prodId]
      }

      state.selectedProducts = selectedProducts
    },
    setSelectedProducts(state, value) {
      state.selectedProducts = value
    },
    removeFromSelectedProducts(state, value) {
      delete state.selectedProducts[value]
    },
    setIsUnsortedList(state, value) {
      state.isUnsortedList = value
    },
    setCheckSortStatusOfShoppingList(state, value) {
      state.checkSortStatusOfShoppingList = value
    },
    setAutoAddClippedCouponsPref(state, value) {
      state.autoAddClippedCouponsPref = value
    },
    setUserListsQueried(state, value) {
      state.userListsQueried = value
    },
    setShowEmptyState(state, value) {
      state.showEmptyState = value
    },
    resetList(state) {
      state.listId = null
      state.currentList = {}
      state.writeInList = []
      state.productList = []
      state.weeklyAdList = []
      state.couponList = []
      state.productIds = []
      state.weeklyAdIds = []
      state.couponIds = []
      state.shoppingListItemsChecked = {
        writeIns: {},
        products: {},
        weeklyAdDeals: {},
        coupons: {}
      }
      state.selectedProducts = {}
      state.productsSelectedCount = 0
      state.showEmptyState = true
    }
  },
  actions: {
    async createUserList({ commit, dispatch, rootGetters }, payload) {
      const config = {
        isTemplateUrlPath: true,
        pathVariables: {
          userId: payload.userId
        },
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      }
      const response = await ApiService.post(`/api/v1/user/shoppinglist/users/:userId/lists`, null, config)
      if (response.status === 200) {
        await dispatch('updateUserPrefSort', sortOptionsConfig.department.value)
        commit('setListId', response.data.lists[0].listId)
        // save guest userId for guest to auth merge
        const isGuest = !rootGetters['LoyaltyAccount/isLoyaltyRegistered']
        const userId = rootGetters['UserProfile/userId']
        if (isGuest) {
          localStorage.setItem('guestUserId', userId)
        }
      }
      return response
    },
    async mergeGuestList({ rootGetters }) {
      const userId = rootGetters['UserProfile/userId']
      const guestUserId = localStorage.getItem('guestUserId')
      if (guestUserId) {
        const config = {
          isTemplateUrlPath: true,
          pathVariables: {
            userId
          }
        }
        const response = await ApiService.post(
          `/api/v1/user/shoppinglist/users/:userId/migrate/items?guestUserId=${guestUserId}`, null, config
        )
        if (response.status === 200 || response.status === 206) {
          localStorage.removeItem('guestUserId')
        }
      }
    },
    async queryUserLists({ commit }, payload) {
      const response = await ApiService.get(`/api/v1/user/shoppinglist/users/${payload}/lists`)
      if (response.status === 200 && response.data?.lists?.length) {
        const list = response.data.lists[0]
        commit('setShowConsolidationModal', list.shoppingList.primaryFlag)
        commit('setShowMergeModal', list.shoppingList.primaryFlag)
        commit('setListId', list.shoppingList.listId)
        commit('setItemIds', list)
        // Necessary to show/hide empty state instead of skeleton tiles
        commit('setUserListsQueried', true)
      } else if (response.status !== 500) {
        // Necessary to show empty state when a user clears cache and refreshes page (200 with no response data)
        // or deletes a list, then navigates away and back (204). Also covering 404, just in case.
        commit('setUserListsQueried', true)
      }
      // TO DO: Show error message for 500

      return response
    },
    async queryShoppingList({ state, commit, rootGetters }, payload) {
      const userId = rootGetters['UserProfile/userId']
      const serviceLocationId = rootGetters['UserProfile/serviceLocationId']
      const listId = payload?.listId || state.listId
      const sort = payload?.sort || state.userPrefSort
      const params = {
        serviceLocation: serviceLocationId,
        sort
      }
      const queryString = new URLSearchParams(params)
      // Only use v2 of the shoppinglist for this get request.
      // It has been updated to include information about clipped coupons.
      const response = await ApiService.get(
        `/api/v2/user/shoppinglist/users/${userId}/lists/${listId}?${queryString}&start=0&rows=500`
      )
      if (response.status === 200) {
        // extra product data required for locker data
        const getVarByName = rootGetters['SiteConfig/varByName']
        const additionalLockerRestrictions = getVarByName('feature_locker_extra_product_restrictions')

        if (additionalLockerRestrictions && response?.data?.products.length > 0) {
          const productIdList = constructProductIdList(response?.data?.products)

          const productParams = {
            flags: true,
            extendedInfo: true,
            includeOutOfStock: false
          }

          const productQueryString = new URLSearchParams(productParams)
          const productsCheck = await ApiService.get(
            `api/v5.0/products/info/${userId}/${serviceLocationId}/${productIdList}?${productQueryString}`
          )

          if (productsCheck.status === 200 && productsCheck.data?.response?.products?.length) {
            // keep sort order and insert expanded product data to allow for locker restrictions
            const expandedProductsMap = {}
            const expandedProducts = productsCheck.data?.response?.products
            expandedProducts.forEach((product) => {
              expandedProductsMap[product.prodId] = product
            })
            const products = response.data.products?.map(product => expandedProductsMap[product.prodId] || product)
            response.data.products = products
          }
        }

        // Associate the type of sort with the current list so we do not have to use userPrefSort
        // to determine how to sort the list into sections on the front end. Otherwise, the list
        // renders sorted incorrectly once before sorting correctly due to the user preference API
        // and the queryShoppingList API updating the store at different times.
        commit('setCurrentList', {
          ...response.data,
          sort
        })
        commit('setIsUnsortedList', false)
      }
      return response
    },
    async getUserPrefSort({ commit, rootGetters }) {
      const userId = rootGetters['UserProfile/userId']
      const response = await UserPreferencesAPI.get(USER_PREFERENCE_KEYS.FAVORITES_SORT, userId)
      if (response.status === 200) {
        const { value } = response.data.response.preference
        // UserPreferencesAPI returns 'description' by default
        const prefSort = (value === 'description') ? sortOptionsConfig.name.value : value
        commit('setUserPrefSort', prefSort)
      }
    },
    async updateUserPrefSort({ commit, rootGetters }, payload) {
      const userId = rootGetters['UserProfile/userId']
      const response = await UserPreferencesAPI.update(
        USER_PREFERENCE_KEYS.FAVORITES_SORT, payload, userId
      )
      if (response.status === 200) {
        commit('setUserPrefSort', payload)
      }
    },
    async updateConsolidationStatus({ commit }, payload) {
      const { userId, listId } = payload
      const response = await ApiService.put(`/api/v1/user/shoppinglist/users/${userId}/lists/${listId}/primary/flag`)
      if (response.status === 200) {
        commit('setShowConsolidationModal', false)
      }
    },
    async updateMergeStatus({ commit }, payload) {
      const { userId, listId } = payload
      const response = await ApiService.put(`/api/v1/user/shoppinglist/users/${userId}/lists/${listId}/primary/flag`)
      if (response.status === 200) {
        commit('setShowMergeModal', false)
      }
    },
    async clearShoppingList({ commit }, payload) {
      const { userId, listId } = payload
      const response = await ApiService.delete(`/api/v1/user/shoppinglist/users/${userId}/lists/${listId}`)
      if (response.status === 200) {
        commit('resetList')
      }
    },
    async getAutoAddClippedCouponsPref({ commit, rootGetters }) {
      const userId = rootGetters['UserProfile/userId']
      const response = await UserPreferencesAPI.get(
        USER_PREFERENCE_KEYS.SL_CLIPPED_COUPON_PREFERENCE, userId
      )
      if (response.status === 200) {
        commit('setAutoAddClippedCouponsPref', response.data.response.preference.value)
      }
    },
    async handleAddClippedCouponsToSl({
      state, dispatch
    }, payload) {
      if (!state.autoAddClippedCouponsPref) {
        await dispatch('getAutoAddClippedCouponsPref')
      }
      if (state.autoAddClippedCouponsPref === 'unselected') {
        dispatch('showUpdateAlert', payload)
      } else if (state.autoAddClippedCouponsPref === 'opt-in') {
        dispatch('addCouponToList', payload)
      }
    },
    showUpdateAlert({ commit, dispatch }, payload) {
      const bodyTextFirst = 'Would you like to automatically add clipped coupons to your shopping list?'
      const bodyTextSecond = 'You can change this setting in your list options.'
      commit('Alert/setAlert', {
        header: 'Add Clipped Coupons to List?',
        body: `${bodyTextFirst} ${bodyTextSecond}`,
        primary: {
          text: 'Yes',
          callback: () => {
            dispatch('updateAutoAddClippedCouponsPref', true)
              .then(() => {
                dispatch('addCouponToList', payload)
              })
            commit('Alert/clear', null, { root: true })
          }
        },
        secondary: {
          text: 'No',
          callback: () => {
            dispatch('updateAutoAddClippedCouponsPref', false)
            commit('Alert/clear', null, { root: true })
          }
        }
      }, { root: true })
    },
    async updateAutoAddClippedCouponsPref({ commit, rootGetters }, status) {
      const preference = status ? 'opt-in' : 'opt-out'
      const userId = rootGetters['UserProfile/userId']
      const response = await UserPreferencesAPI.update(
        USER_PREFERENCE_KEYS.SL_CLIPPED_COUPON_PREFERENCE, preference, userId
      )
      if (response.status === 200) {
        commit('setAutoAddClippedCouponsPref', preference)
      }
    },
    async addCouponToList({
      state, commit, dispatch, rootGetters
    }, payload) {
      const userId = rootGetters['UserProfile/userId']
      const { serviceLocationId } = rootGetters['UserProfile/deliveryServiceLocation']
      // if shopping list is not created while clipping coupon
      if (state.listId === null) {
        const listParams = {
          userId,
          serviceLocationId
        }
        await dispatch('createUserList', listParams)
      }
      const couponParams = {
        listId: state.listId,
        items: [
          {
            itemId: payload.id
          }
        ]
      }
      const response = await ApiService.post(
        `/api/v1/user/shoppinglist/users/${userId}/lists/${state.listId}/coupons`, couponParams
      )
      if (response.status === 200) {
        commit('pushToCouponList', payload)
      }
    },
    addToWriteInList({ commit, state }, payload) {
      commit('addToWriteInList', payload)
      if (state.userPrefSort === sortOptionsConfig.name.value) {
        commit('sortWriteInList', state.writeInList)
      }
    }
  }
}
