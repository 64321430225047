/* eslint-disable no-useless-escape, max-len */
const A_LETTER_OR_NUMBER = /[a-z0-9]/i
const CARD_TYPE_AMEX = /^3[47]/
const CARD_TYPE_DISC = /^(6011|65|64[4-9]|622)/
const CARD_TYPE_MC = /^(5[1-5]|2)/
const CARD_TYPE_VISA = /^4/
// disalow special characters from email - MDM
// accepted characters for MDM are .+_-
// eslint-disable-next-line max-len, no-control-regex
const EMAIL_SAFE_CHAR = /^[a-zA-Z0-9.+_-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
const HAS_SQUARE_BRACKETS = /[[\]]/
const IS_AMEX_PATTERN = /^(?:3[47]\d{13})$/
const IS_DISCOVER_PATTERN = /^(?:6(?:011|5\d\d)\d{12})$/
const IS_MASTERCARD_PATTERN = /^(?:5[1-5]\d{14})$/
const IS_NUMBER = /^\d*$/gm
const IS_URL = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/
const IS_VISA_PATTERN = /^(?:4\d{12}(?:\d{3})?)$/
const LONG_ISO8601_DATE = /^(\d{4}|\+\d{6})(?:-(\d{2})(?:-(\d{2})(?:T(\d{2}):(\d{2}):(\d{2})\.(\d{1,3})(?:Z|([-+])(\d{2}):(\d{2}))?)?)?)$/
const NOT_NUMBER = /\D/g
const NOT_NUMBER_OR_LETTER = /[^0-9a-zA-Z]/g
const ONE_LOWERCASE = /(?=.*[a-z])/
const ONE_NUMBER = /(?=.*\d)/
const ONE_UPPERCASE = /(?=.*[A-Z])/
const PHONE_NUMBER = /^\D?([2-9])(\d{2})\D?\D?([2-9])(\d{2})\D?(\d{4})$/
const SAFE_ID = /^[a-zA-Z][a-zA-Z0-9_-]+$/g
const STARTS_WITH_DOLLAR = /^\$/
const TIP_FORMAT = /^\d{0,4}(\.\d{1,2})?$/
const UNSAFE_CHAR = /[^A-Za-z0-9]+/g // used for removing all non alpha or numeric
const UNSAFE_KEY_CHAR = /[^A-Za-z0-9 ]+/g // used for removing all non alpha, numeric, and space
const VALID_ITEM_COMMENT_INPUT = /^[\w.,:;?'"`~!@#$%^&*()\-=+{}<>|/\\[\]\n ]*$/

export {
  A_LETTER_OR_NUMBER,
  CARD_TYPE_AMEX,
  CARD_TYPE_DISC,
  CARD_TYPE_MC,
  CARD_TYPE_VISA,
  EMAIL_SAFE_CHAR,
  HAS_SQUARE_BRACKETS,
  IS_AMEX_PATTERN,
  IS_DISCOVER_PATTERN,
  IS_MASTERCARD_PATTERN,
  IS_NUMBER,
  IS_URL,
  IS_VISA_PATTERN,
  LONG_ISO8601_DATE,
  NOT_NUMBER_OR_LETTER,
  NOT_NUMBER,
  ONE_LOWERCASE,
  ONE_NUMBER,
  ONE_UPPERCASE,
  PHONE_NUMBER,
  SAFE_ID,
  STARTS_WITH_DOLLAR,
  TIP_FORMAT,
  UNSAFE_CHAR,
  UNSAFE_KEY_CHAR,
  VALID_ITEM_COMMENT_INPUT,
}
