import OrderAdjustmentsAPI from 'api/OrderAdjustmentsAPI'
import { trackViewSavings } from 'utils/tracking/trackViewSavings'

/**
 * @function isArrayWithContent
 *
 * Returns boolean representing if the provided
 * parameter is an array with at least one item.
 *
 * @param x - value to examine
 */
const isArrayWithContent = x => Array.isArray(x) && !!x.length

function processCoupons(fullSummary, apiResponse) {
  // test to see if expired coupons have been returned from dynamo
  if ('expiring' in apiResponse.coupons[0]) {
    fullSummary.expiredCoupons = apiResponse.coupons.filter(coupon => coupon.expiring === true)
    // set expired coupon value
    fullSummary.expiredCoupons.forEach((coupon) => { fullSummary.expiredSavings += coupon.value })
    // filter out expired for the full list, then check to make sure there's still valid coupons
    const validCouponList = apiResponse.coupons.filter(coupon => coupon.expiring === false)
    if (validCouponList.length) {
      fullSummary.tileDetails.push({
        id: 'coupons',
        tileHeadline: 'Coupons',
        items: validCouponList,
        total: apiResponse.couponSavings - fullSummary.expiredSavings
      })
    }
  } else {
    fullSummary.tileDetails.push({
      id: 'coupons',
      tileHeadline: 'Coupons',
      items: apiResponse.coupons,
      total: apiResponse.couponSavings
    })
  }
}

function formatSavingsData(apiResponse) {
  const fullSummary = {
    totalSavings: apiResponse.totalSavingsAmt,
    tileDetails: [],
    hasDisclaimer: false,
    expiredCoupons: [],
    hasExpiredCoupons: false,
    expiredSavings: 0
  }

  // promotion group
  if (apiResponse.promotionSavingsTotal > 0 || isArrayWithContent(apiResponse.promotionSavings)) {
    const tileDetail = {
      id: '',
      tileHeadline: 'Promotions',
      items: apiResponse.promotionSavings,
      total: apiResponse.promotionSavingsTotal
    }
    if (apiResponse.pepDiscount > 0) {
      tileDetail.items.push({
        displayText: 'Direct Check discount',
        value: apiResponse.pepDiscount
      })
    }
    if (apiResponse.delivDiscount > 0) {
      tileDetail.items.push({
        displayText: 'Delivery Discount',
        value: apiResponse.delivDiscount
      })
    }
    fullSummary.tileDetails.push(tileDetail)
  }

  // coupons
  if (isArrayWithContent(apiResponse.coupons)) {
    processCoupons(fullSummary, apiResponse)
  }

  // weekly savings
  if (isArrayWithContent(apiResponse.weeklySavingsItems)) {
    fullSummary.tileDetails.push({
      id: 'weekly-specials',
      tileHeadline: 'Weekly specials',
      items: apiResponse.weeklySavingsItems,
      total: apiResponse.weeklySavingsTotal
    })
  }

  // buy more save more
  if (isArrayWithContent(apiResponse.buyMoreSaveMoreSavingsItems)) {
    fullSummary.tileDetails.push({
      id: 'buy-more-save-more',
      tileHeadline: 'Buy More Save More',
      items: apiResponse.buyMoreSaveMoreSavingsItems,
      total: apiResponse.buyMoreSaveMoreSavingsTotal
    })
    fullSummary.hasDisclaimer = true
  }

  // in store coupons
  if (isArrayWithContent(apiResponse.inStoreCoupons)) {
    fullSummary.tileDetails.push({
      id: 'digital-coupons',
      tileHeadline: 'Digital Coupons',
      items: apiResponse.inStoreCoupons,
      total: apiResponse.inStoreCouponSavings
    })
  }

  // sort by savings group totals highest to lowest
  fullSummary.tileDetails = _.sortBy(fullSummary.tileDetails, 'total').reverse()

  // remove expired coupons from total savings and coupon savings
  if (fullSummary.expiredCoupons.length) {
    fullSummary.hasExpiredCoupons = true
    fullSummary.totalSavings -= fullSummary.expiredSavings
  }

  return fullSummary
}

const initialState = () => ({
  fetchInProgress: false,
  savingsData: {
    // below are the actual content for popup
    hasDisclaimer: false,
    tileDetails: [],
    totalSavings: 0,
  },
  // location can either be 'cart' or 'checkout'
  // The reason this is needed is because we need
  // to know where to look for the preferred payment
  // method when making the call to get savings
  location: 'cart',
  fetchError: false
})

const mutations = {
  setSavingsData(state, payload) {
    state.savingsData = payload
  },
  setFetchInProgress(state, payload) {
    state.fetchInProgress = payload
  },
  setFetchError(state, payload) {
    state.fetchError = payload
  },
  setSavingsLocation(state, payload) {
    // payload is a string containing either 'cart' or 'checkout'
    state.location = payload
  }
}

const actions = {
  async fetchAndRefreshSavingsData({ commit, rootGetters }, payload) {
    commit('setFetchInProgress', true)
    try {
      const basketId = rootGetters['Cart/getBasketId']
      const userId = rootGetters['UserProfile/userId']
      const version = 'v5.0'

      const data = {
        params: {
          calculateInStore: payload.calcInStore
        }
      }
      if (payload.paymentMethod) {
        data.params.paymentMethod = payload.paymentMethod
      }
      const payloadData = {
        version,
        data,
        basketId,
        userId
      }
      const result = await OrderAdjustmentsAPI.getSavings(payloadData)

      if (result.status !== 200 || result.data.response.result.code !== 'SAVINGS_GET_SUCCESS') {
        throw new Error(result)
      }

      const savingsResponse = result.data.response.savings
      // format response into data into template
      const savingsData = formatSavingsData(savingsResponse)

      commit('setSavingsData', savingsData)
      commit('setFetchInProgress', false)
      commit('setFetchError', false)

      trackViewSavings({
        digitalCouponsSavings: savingsResponse.inStoreCouponSavings,
        promotionsSavings: savingsResponse.promotionSavingsTotal,
        weeklySpecialSavings: savingsResponse.weeklySavingsTotal,
        totalSavings: savingsResponse.totalSavingsAmt
      })
    } catch (e) {
      commit('setFetchInProgress', false)
      commit('setFetchError', true)
    }
  }
}

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions
}
