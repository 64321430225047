/**
 * Uses for attach log tracker to cms driven component,
 * content_view log type is fired when component is being lazy loaded to the page.
 * @param {Object} scheduledData -Pass from component as prop
 * @param {Object} contentData -Pass from component as prop
 * @param {Boolean} isTrackingEnabled -Set value from component when tracking should be disabled, ex: gateway
 * @function getContentLog
 *   Expose getter for component level to use.
 *   specify a callback function and loaded the logdata in the data field when component mounted.
 * @returns {Object} --CMS information
 * NOTE: DO NOT handle content_click event here for now. Reason below
 * 1. Content click event log body may vary based on how component want them to be.
 * 2. Most of the time content click event is not fired directly in component level,
 *    its being emitted and bubbled out.
 * 3. We have $trackClientLog global plugin to use if you want to fire a content_click event directly.
 */
const contentTracker = {
  mounted(el, binding) {
    const { value } = binding
    const {
      scheduledData,
      contentData,
      isTrackingEnabled = true,
      getContentLogBody,
      afterIntersecting = () => {
        // call back when intersecting the elements
      }
    } = value
    const getContentLog = () => {
      if (scheduledData) {
        const containerKey = scheduledData['Content Container Key']
        const contentId = containerKey || contentData.key
        return {
          region: scheduledData.Site_Location,
          scheduledContentId: scheduledData.key || scheduledData.ScheduleId,
          contentId,
          contentModuleId: containerKey && contentData.key,
          fragmentKey: contentData['Fragment Key'],
          name: scheduledData['Friendly Tracking Description']
        }
      }
      return null
    }
    el.logData = getContentLog()
    const logView = () => {
      if (!el.logData?.region) {
        // eslint-disable-next-line
        console.warn('CMS_MISSING_REGION_INFO', el)
        return
      }
      window.sharedVue.config.globalProperties.$trackClientLog('content_view', el.logData)
    }
    const handleIntersect = (entries, observer) => {
      const content = entries[0]
      if (content.isIntersecting) {
        if (isTrackingEnabled) {
          logView()
        }
        afterIntersecting()
        observer.disconnect()
      }
    }
    const createObserver = () => {
      if (!el.observer) {
        el.observer = new IntersectionObserver(handleIntersect)
      }
      el.observer.observe(el)
    }
    createObserver()
    if (getContentLogBody) {
      getContentLogBody(el.logData)
    }
  },
  unmounted(el) {
    if (!el.observer) return
    el.observer.unobserve(el)
    delete el.observer
  }
}
export default contentTracker
