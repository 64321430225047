/**
 * Generate a random number from 0 to the provided max.
 *
 * @param {number} max highest possible random value
 * @returns {number} random number
 */

export default function (max) {
  return Math.round((Math.random() * max) + 1)
}
