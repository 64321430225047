<template>
  <div class="my-account form_container">
    <div v-if="formLoading">
      <div class="loading leader--double">
        <div class="loading-spinner-block">
          <PdlLoadingSpinner />
        </div>
      </div>
    </div>
    <div v-else-if="!loggedIn">
      <p>
        It looks like you are not signed in. Your satisfaction is 100%
        guaranteed, every order, every time. So, if for any reason you
        are not happy with an online purchase, we will make it right.
        <button
          class="link"
          @click="handleLoginClick"
        >
          Sign In
        </button>
      </p>
    </div>
    <div v-else-if="showOrderlistError">
      <p>
        It looks like you haven't placed an online order with us just
        yet. Your satisfaction is 100% guaranteed, every order, every
        time. So, if for any reason you are not happy with an online
        purchase, come back here to request a refund and we will make
        it right.
        <button
          class="link"
          @click="handleShopClick"
        >
          Start Shopping
        </button>
      </p>
    </div>
    <div v-else-if="contactUsStatus">
      <ContactConfirm :name="form.name" />
    </div>
    <form
      v-else
      name="contactUs"
      aria-description="Order contact form"
      novalidate
      @submit.prevent="handleSubmit"
    >
      <PdlSelect
        id="contact-us_subject"
        v-model="v$.form.subjectInput.$model"
        :error="v$.form.subjectInput.$error"
        :options="subjects"
        field-label="Choose a topic"
        class="forms_input-container"
        full-width
        no-error-padding
        :is-required="true"
        medium-caret
      >
        <template #errors>
          <span>{{ subjectMessage.required }}</span>
        </template>
      </PdlSelect>

      <div v-if="anyContactFieldsDisplayed">
        <p class="mt-12 mb-6 border-b border-solid border-gray-100" />

        <div class="mt-12 mb-7 font-semibold">
          Enter Your Contact Information
        </div>
      </div>

      <PdlBasicTextInput
        v-if="showInput(form.email, v$.form.email.$dirty)"
        id="contact-us_email"
        v-model="v$.form.email.$model"
        :error="v$.form.email.$error"
        field-name="email"
        input-label="Email"
        is-required
        :is-mobile-device="isMobileDevice"
      >
        <template #errors>
          <span v-if="v$.form.email.required.$invalid">{{ emailMessage.required }}</span>
          <span v-else-if="v$.form.email.email.$invalid">{{ emailMessage.format }}</span>
        </template>
      </PdlBasicTextInput>
      <PdlBasicTextInput
        v-if="showInput(form.email, v$.form.confirmEmail.$dirty)"
        id="contact-us_confirmEmail"
        v-model="v$.form.confirmEmail.$model"
        :error="v$.form.confirmEmail.$error"
        field-name="confirmEmail"
        input-label="Confirm Email"
        is-required
        :is-mobile-device="isMobileDevice"
      >
        <template #errors>
          <span v-if="v$.form.confirmEmail.required.$invalid">{{ emailMessage.required }}</span>
          <span v-else-if="v$.form.confirmEmail.email.$invalid">{{ emailMessage.format }}</span>
          <span v-else-if="v$.form.confirmEmail.sameAsEmail.$invalid">{{ emailMessage.match }}</span>
        </template>
      </PdlBasicTextInput>
      <PdlBasicTextInput
        v-if="showInput(form.name, v$.form.name.$dirty)"
        id="contact-us_name"
        v-model="v$.form.name.$model"
        :error="v$.form.name.$error"
        field-name="name"
        input-label="Name (optional)"
        :is-mobile-device="isMobileDevice"
      />
      <PdlBasicTextInput
        v-if="showInput(form.phoneInput, v$.form.phoneInput.$dirty)"
        id="contact-us_phone"
        v-model="v$.form.phoneInput.$model"
        :error="v$.form.phoneInput.$error"
        field-name="phone"
        input-label="Contact Phone (optional)"
        :is-mobile-device="isMobileDevice"
      >
        <template #errors>
          <span v-if="v$.form.phoneInput.format.$invalid">{{ phoneMessage.format }}</span>
        </template>
      </PdlBasicTextInput>

      <p class="mt-12 mb-6 border-b border-solid border-gray-100" />

      <PdlBasicTextArea
        id="contact-us_body"
        v-model="v$.form.body.$model"
        :error="v$.form.body.$error"
        field-name="body"
        input-label="Comments"
        instant-update
        has-description
        :is-required="true"
        @input="updateCharacterCount"
      >
        <template #errors>
          <span v-if="v$.form.body.required.$invalid">{{ commentMessage.required }}</span>
          <span v-else-if="v$.form.body.maxLength.$invalid">{{ commentMessage.maxLength(maxCommentLength) }}</span>
        </template>
        <template #description>
          <span>{{ charactersLeft }} characters left</span>
        </template>
      </PdlBasicTextArea>

      <div class="text--align-center">
        <PdlLoadingButton
          v-if="hasOrderId"
          id="contact-us_submit"
          label="Submit"
          button-color="third"
          :classes="[
            isMobile ? 'button-width--full' : 'button-width--med'
          ]"
          :is-loading="submitLoading"
          :is-disabled="v$.form.$invalid"
          :ignore-prevent="true"
        />
        <p
          v-if="!hasOrderId"
          class="forms_confirmation text--base-strong text--base-error"
        >
          An Order ID is required for this form.
        </p>
        <p
          v-if="displayError && !contactUsStatus"
          aria-live="polite"
          class="forms_confirmation text--base-strong text--base-error"
        >
          There was an error loading this feature.<br>
          Please try again, or for immediate assistance call
          <a
            class="link"
            :href="`tel://+${getBrandHelpPhoneNumber}`"
          >{{ getBrandHelpPhoneNumber }}</a>
        </p>
      </div>
    </form>
  </div>
</template>

<script>
import {
  required,
  email,
  maxLength,
  sameAs,
  helpers
} from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { mapState, mapGetters, mapActions } from 'vuex'
import { trackHelpSubmit } from 'utils/tracking/help/trackHelpSubmit'
import formatDateParams, { longWeekMonthDay } from 'utils/filters/formatDateParams'
import ContactConfirm from './ContactConfirm'

export default {
  components: {
    ContactConfirm
  },
  data: () => ({
    form: {
      email: '',
      confirmEmail: '',
      name: '',
      phone: {},
      phoneInput: '',
      subjectInput: '',
      body: '',
    },
    commentLength: 0,
    maxCommentLength: 500,
    submitLoading: false,
    formLoading: true,
    anyContactFieldsDisplayed: false,
    selectedOrder: ''
  }),
  computed: {
    ...mapState({
      emailSubjects: state => state.ContactUs.emailSubjects,
      userOrders: state => state.ContactUs.userOrders,
      contactUsStatus: state => state.ContactUs.contactUsStatus,
      displayError: state => state.ContactUs.displayError
    }),
    ...mapGetters({
      messages: 'FormMessages/messages',
      isMobile: 'ScreenSize/isMobile',
      getBrandHelpPhoneNumber: 'SiteConfig/brandContactNumber',
      opco: 'SiteConfig/opco',
      loggedIn: 'LoginStatus/isLoggedIn',
      userFirstName: 'UserProfile/displayFirstName',
      userLastName: 'UserProfile/displayLastName',
      deliveryPhone: 'UserProfile/deliveryPhone',
      userInfoData: 'UserProfile/userInfo',
      userId: 'UserProfile/userId',
      isMobileDevice: 'ScreenSize/isMobileDevice',
    }),
    emailMessage() {
      return this.messages('fields.email')
    },
    phoneMessage() {
      return this.messages('fields.phone')
    },
    commentMessage() {
      return this.messages('fields.comments')
    },
    subjectMessage() {
      return this.messages('fields.emailSubject')
    },
    genericMesssage() {
      return this.messages('fields.generic')
    },
    charactersLeft() {
      const charactersLeft = this.maxCommentLength - this.form.body.length
      return charactersLeft > 0 ? charactersLeft : 0
    },
    subjects() {
      const { emailSubjects } = this
      let subjects = emailSubjects.filter(({ orderRequired, title }) => (
        orderRequired === true || title === 'Other'))
      subjects.unshift({ title: '' })
      subjects = subjects.map((subj, index) => {
        return { ...subj, value: index }
      })

      return subjects
    },
    selectedSubject() {
      return this.subjects[this.form.subjectInput]
    },
    orders() {
      return [
        { title: '', value: '' },
        ...this.userOrders.map(order => ({
          title: formatDateParams(order.orderDate, longWeekMonthDay),
          value: order.orderId
        }))
      ]
    },
    hasOrderId() {
      return !!this.selectedOrder
    },
    showOrderlistError() {
      return !this.hasOrderId && this.userOrders.length === 0
    }
  },
  setup: () => ({ v$: useVuelidate() }),
  async beforeMount() {
    this.selectedOrder = this.$route?.query?.orderId || ''

    // reset the vuex store (since we use it in multiple screens)
    this.resetState()

    // save the user's info to the state
    if (this.loggedIn) {
      await this.getUserInfo()
    }

    // get the data we need to display the form
    await this.getSubjectsAndOrders(this.userId)
    this.formLoading = false
  },
  methods: {
    ...mapActions({
      getSubjectsAndOrders: 'ContactUs/getSubjectsAndOrders',
      postOrderContact: 'ContactUs/postOrderContact',
      resetState: 'ContactUs/resetState'
    }),
    updateCharacterCount(value) {
      this.commentLength = value.length
    },
    mapFormPayload() {
      const {
        name,
        phoneInput,
        body,
      } = this.form || {}

      const subject = this.selectedSubject?.code || 'S-011'
      const formEmail = this.form?.email || '' // 'email' was already declared above

      const payload = {
        email: formEmail, // --Required
        name,
        opco: this.opco,
        subject,
        userId: this.userId, // -- Required
        body,
        ordId: this.selectedOrder
      }

      if (phoneInput) payload.phone = phoneInput

      return payload
    },
    async handleSubmit() {
      if (!this.hasOrderId) return false
      this.submitLoading = true
      const payload = this.mapFormPayload()
      const contactResponse = await this.postOrderContact({ userId: this.userId, payload })
      this.submitLoading = false
      this.logAnalytics(contactResponse)

      return contactResponse
    },
    getUserInfo() {
      const userInfo = this.userInfoData?.information || {}
      // copy the user's account info into the state
      this.form.name = `${this.userFirstName} ${this.userLastName}`
      this.form.email = userInfo.email
      this.form.confirmEmail = this.form.email
      this.form.phoneInput = this.deliveryPhone
    },
    showInput(field, isDirty) {
      // If the user made changes to the field
      if (isDirty) {
        this.anyContactFieldsDisplayed = true
        return true
      }

      // If the user is not logged in
      if (!this.loggedIn) {
        this.anyContactFieldsDisplayed = true
        return true
      }

      // if the provided field is undefined or empty
      // remove spaces to account for the space we added in getUserInfo
      if (!field || (field.replace(/ /g, '') === '')) {
        this.anyContactFieldsDisplayed = true
        return true
      }

      return false
    },
    logAnalytics(response) {
      trackHelpSubmit({
        userLoggedIn: this.loggedIn,
        emailSubject: this.selectedSubject?.title,
        status: this.contactUsStatus
      }, {
        action: 'order history - report order issue submit',
        linkText: 'Submit',
        response,
        siteLocation: 'account - order history',
        transactionId: this.selectedOrder,
        type: 'order history',
      })
    },
    handleShopClick() {
      this.$store.commit('Modals/clearActiveModal')
      this.$router.push('/home')
    },
    handleLoginClick() {
      this.$emit('login-click')
    }
  },
  validations() {
    return ({
      form: {
        email: {
          required,
          email
        },
        confirmEmail: {
          required,
          email,
          sameAsEmail: sameAs(this.form.email)
        },
        name: { },
        phoneInput: {
          format(phone) {
            return (
              /^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/.test(
                phone
              ) || !helpers.req(phone)
            )
          },
        },
        subjectInput: {
          required
        },
        body: {
          required,
          maxLength: maxLength(this.maxCommentLength)
        }
      }
    })
  }
}
</script>
