import { GTM } from 'utils/constants'

/**
  * @param {string} error
  * @returns {void}
  */

export function trackInstacartError(error) {
  window.sharedVue.config.globalProperties.$trackGtmEvent(GTM.instacartV2Error, {
    msg: error
  })
}

export function trackInstacartSlotError(response) {
  window.sharedVue.config.globalProperties.$trackGtmEvent(GTM.sameDayErrorCode, {
    error: response
  })
}
