import { PODBAG_PROD_ID, USER_PREFERENCE_KEYS, SERVICE_TYPE_WITH_INSTORE } from 'utils/constants'
import { bulkAddToCart, parseItemsQueryString } from 'utils/services/vendor-methods'
import UserPreferencesAPI from 'api/UserPreferencesAPI'
import { trackCartBulkChange } from 'utils/tracking/product-gtm-tagging'
import CartAPI from 'api/CartAPI'
import orderAPI from 'api/orderAPI'
import orderTipApi from 'api/orderTipApi'
import { updateServiceLocationAPI } from 'utils/StoreSelection'

export default {
  namespaced: true,
  state: {
    fetchedCart: false,
    cart: {
      driverTip: 0,
      orderId: '',
      subTotalPrice: 0,
      items: [],
      total: 0,
      tax: 0,
      savings: {
        coupons: [],
        promotionSavings: []
      },
      fees: {},
      removedItems: [],
      substitutionsAllowed: false // cart-level toggle value
    },
    itemCommentsDict: {},
    basketLoading: null,
    cartResponses: {},
    summaryData: {
      vendorTotalsAndTax: [],
      lineItems: []
    },
    printSelectedSlot: {},
    refreshClicked: 0, // used to refresh the clicked variable in ProductCartField
    lockerRestrictionsActive: false,
    reducedItemsForFillCart: [], // To track the reduced items which can be added to cart from Fill cart in Home page
    cartInitialized: false,
    cartHasAlcohol: false,
    warningMessageDismissed: false,
    basketId: 1,
    workingOrderBasketId: '',
    cartIsSubmittedOrder: false,
    // continueShoppingVariant can be '', 'A', 'B', 'C', or 'D'
    // '': Continue Shopping button is hidden when minimum order met
    // A: Continue Shopping button is inline to the left of the Checkout button
    // B: Continue Shopping button is inline to the right of the Checkout button
    // C: Continue Shopping button is above the Checkout button
    // D: Continue Shopping button is below the Checkout button
    continueShoppingVariant: '',
    recommendedProductsForDYFModal: [],
    partnerQueryParams: {},
    partnerRecipeAdded: false
  },
  getters: {
    fetchedCart: state => state.fetchedCart,
    getCart: state => state.cart,
    getCartId: state => state.cart.orderId,
    getCartItems: state => state.cart.items,
    getCartSavings: state => state.cart.savings,
    getCartHasAlcohol: state => state.cartHasAlcohol,
    getSubTotal: state => state.cart.subTotalPrice,
    getCartProductById: state => id => state.cart.items.find(item => item.prodId === id),
    getCartResponseById: state => id => state.cartResponses[id],
    getOrderMinimumView: state => state.orderMinimumView,
    showToggleForSubstitutions: state => state.showToggleForSubstitutions,
    getRemovedItems: state => state.cart.removedItems,
    getSummaryData: state => state.summaryData,
    getRefreshClicked: state => state.refreshClicked,
    getPromoCode: state => state.cart.savings?.promotionSavings,
    getTotal: state => state.cart.total,
    getDeliveryFee: state => state.cart.fees?.deliveryFee,
    getTax: state => state.cart.tax,
    getOrderStatusCode: state => state.cart.orderStatusCode,
    printSelectedSlot: state => state.printSelectedSlot,
    lockerIneligibleItemsInCart: state => state.lockerRestrictionsActive,
    reducedItemsForFillCart: state => state.reducedItemsForFillCart,
    cartInitialized: state => state.cartInitialized,
    // eslint-disable-next-line max-params
    getProductsSortedByCategory: (state, getters, rootState, rootGetters) => {
      // this method will filter and groupby sorted products into regular products
      // regular products returns an object where each key is the name of a category
      // and contains an array of products that fit that category
      const { regularProducts = {} } = state.cart.items.reduce((acc, cartItem) => {
        // sample products do not show up in cart
        if (cartItem.flags.sample) {
          return {
            ...acc,
            regularProducts: {
              ...acc.regularProducts
            }
          }
        }

        const { rootCatName = '' } = cartItem
        const { regularProducts: { [rootCatName]: prevGroupedData = [] } = {} } = acc
        return {
          ...acc,
          regularProducts: { ...acc.regularProducts, [cartItem.rootCatName]: [...prevGroupedData, cartItem] }
        }
      }, {})
      const pdlProductsTitle = () => {
        const { serviceType } = rootGetters['UserProfile/deliveryServiceLocation']
        return SERVICE_TYPE_WITH_INSTORE[serviceType] || ''
      }
      return Object.keys(regularProducts).length ? {
        title: pdlProductsTitle(),
        items: regularProducts,
        isBrandAvailable: true,
      } : {}
    },
    getBasketId: state => state.basketId,
    getWorkingOrderBasketId: state => state.workingOrderBasketId,
    getCartIsSubmittedOrder: state => state.cartIsSubmittedOrder,
    getBasketPromise: state => state.basketLoading,
    warningMessageDismissed: state => state.warningMessageDismissed,
    continueShoppingVariant: state => state.continueShoppingVariant,
    cartHasItems: state => state.cart?.items?.filter(item => !item?.flags?.sample).length > 0,
    getItemCommentsDict: state => state.itemCommentsDict,
    getRecommendedProductsForDYFModal: state => state.recommendedProductsForDYFModal,
    shipMethod: state => state.shipMethod,
    deliveryPartner: state => state.deliveryPartner,
    isPfsDelivery: (state, getters, rootState, rootGetters) => {
      return rootGetters['SiteConfig/varByName']('feature_pay_4_pricing_policy')
      && getters.shipMethod === 'Delivery' && getters.deliveryPartner
    },
    substitutionType: state => state.substitutionType
  },
  mutations: {
    setFetchedCart(state, value) {
      state.fetchedCart = value
    },
    setCart(state, value) {
      state.cart = value
    },
    setCartResponses(state, value) {
      const cartResponses = {
        ...state.cartResponses
      }
      value.forEach((response) => {
        cartResponses[response.productId] = response
      })
      state.cartResponses = cartResponses
    },
    setCartHasAlcohol(state, value) {
      state.cartHasAlcohol = value
    },
    setPrintSelectedSlot(state, value) {
      state.printSelectedSlot = value
    },
    removeCartResponse(state, value) {
      const cartResponses = {
        ...state.cartResponses
      }
      delete cartResponses[value]
      state.cartResponses = cartResponses
    },
    emptyAllCartResponse(state) {
      state.cartResponses = {}
    },
    setSummaryData(state, value) {
      // ensure separately billed totals come last
      value.vendorTotalsAndTax = _.sortBy(value.vendorTotalsAndTax, item => item?.vendor?.separateBilling)
      state.summaryData = value
    },
    setRefreshClicked(state) {
      state.refreshClicked += 1
    },
    updateCartItemSubstitutionPref(state, payload) {
      const index = state.cart.items.findIndex(product => product.prodId === payload.prodId)

      if (payload.isClickAndCollect) {
        state.cart.items[index].okToSub = payload.substitutePref
      } else {
        state.cart.items[index].substitute.substitutePref = payload.substitutePref
      }
    },
    setLockerRestrictionsActive(state, payload) {
      state.lockerRestrictionsActive = payload
    },
    setReducedItemsForFillCart(state, value) {
      state.reducedItemsForFillCart = value
    },
    setCartInitialized(state, payload) {
      // payload is a Boolean
      state.cartInitialized = payload
    },
    markWarningMessageAsDismissed(state) {
      state.warningMessageDismissed = true
    },
    setBasketId(state, value) {
      state.basketId = value
    },
    setWorkingOrderBasketId(state, value) {
      state.workingOrderBasketId = value
    },
    setCartIsSubmittedOrder(state, value) {
      state.cartIsSubmittedOrder = value
    },
    setBasketLoading(state, value) {
      state.basketLoading = value
    },
    setContinueShoppingVariant(state, payload) {
      // payload is a string with either empty string, A, B, C, D
      state.continueShoppingVariant = payload
    },
    setItemComment(state, payload) {
      state.itemCommentsDict = {
        ...state.itemCommentsDict,
        [payload.productId]: payload.comment
      }
    },
    deleteItemComment(state, productId) {
      delete state.itemCommentsDict[productId]
      state.itemCommentsDict = { ...state.itemCommentsDict }
    },
    setAllItemComments(state, payload) {
      state.itemCommentsDict = payload.reduce((dict, commentObj) => {
        dict[commentObj.productId] = commentObj.comment || ''
        return dict
      }, {})
    },
    setRecommendedProductsForDYFModal(state, products) {
      state.recommendedProductsForDYFModal = products
    },
    setDriverTip(state, tipAmount) {
      state.cart.driverTip = tipAmount
    },
    setPartnerQueryParams(state, params) {
      state.partnerQueryParams = params
    },
    setPartnerRecipeAdded(state, params) {
      state.partnerRecipeAdded = params
    },
    setShipMethod(state, value) {
      state.shipMethod = value
    },
    setDeliveryPartner(state, value) {
      state.deliveryPartner = value
    },
    setSubstitutionType(state, payload) {
      state.substitutionType = payload
    }
  },
  actions: {
    async getPrimaryBasket({ rootGetters, commit, dispatch }) {
      const response = await dispatch('Order/initOrderStatus', { canRefresh: true }, { root: true })
      const { currentOrders = [] } = response?.data || {}
      const workingOrderBasketId = currentOrders.filter(order => order.status === 'WORKING')[0]?.basketId
      const isEditingSubmittedOrder = window.sessionStorage.getItem('continue_edit_pending_cart')
      const isNativeApp = window.sharedVue?.config?.globalProperties?.$store.getters['NativeContainer/isNativeApp']
      const firstOrder = currentOrders[0] || {}
      if (!rootGetters['SiteConfig/varByName']('feature_multibasket')) {
        // if not marketplace, just use the first order that's returned
        commit('setBasketId', firstOrder.basketId)
        if (firstOrder.status === 'SUBMITTED') {
          commit('setCartIsSubmittedOrder', true)
        }
      } else if (firstOrder.status !== 'WORKING' && !isEditingSubmittedOrder && !isNativeApp) {
        // primary is not a working order and not editing any submitted order
        // not a native app as isEditingSubmittedOrder will always return false
        // need to switch the session to a working basket
        if (workingOrderBasketId !== undefined) {
          await dispatch('setPrimaryCart', { basketId: workingOrderBasketId })
          commit('setBasketId', workingOrderBasketId)
          commit('setWorkingOrderBasketId', workingOrderBasketId)
        } else {
          // if a user has no working order, this creates one;
          // could happen if createNewBasket fails in checkout
          // or when feature_multibasket is turned on when a user has an order
          await dispatch('CartPaymentInformation/createNewBasket', {}, { root: true })
        }
      } else if (firstOrder.status === 'WORKING') {
        // primary is working
        // need to use that basket id for session
        commit('setBasketId', firstOrder.basketId)
        commit('setWorkingOrderBasketId', firstOrder.basketId)
      } else {
        // primary is not working
        // need to use that for session
        // also get the latest working order to switch once done updating order
        commit('setBasketId', firstOrder.basketId)
        commit('setCartIsSubmittedOrder', true)
        commit('setWorkingOrderBasketId', workingOrderBasketId)
      }


      return response
    },
    checkCartHasAlcohol({ commit, state }) {
      const hasAlcohol = state.cart.items.some((item) => {
        return item.isAlcohol === true
      })
      commit('setCartHasAlcohol', hasAlcohol)
    },
    async queryCart({
      commit, dispatch, rootGetters, state
    }, payload = {}) {
      let response
      const userId = rootGetters['UserProfile/userId']
      const defaultParams = {
        image: true,
        flags: true,
        substitute: true,
        sort: 'category asc'
      }
      // get basketId if not a ghost user and basketId has not been fetched
      if (userId !== 2 && state.basketId === 1) {
        await dispatch('getPrimaryBasket')
      }
      const params = Object.assign(defaultParams, payload)
      commit('setFetchedCart', false)
      try {
        response = await CartAPI.getCart(params, userId, state.basketId)
        const { status } = response
        const success = (status && (status >= 200 && status < 300))
        if (success && response.data.response) {
          commit('setCart', response.data.response)
          let userHasPodBag = rootGetters['Slots/hasPodBag']

          if (!userHasPodBag) {
            const podBagUserPref = await UserPreferencesAPI.get(
              USER_PREFERENCE_KEYS.HAS_POD_BAG, userId
            )
            userHasPodBag = podBagUserPref?.data?.response?.preference?.value
          }
          if (userHasPodBag) dispatch('DeliveryOptions/removePodBagFromCart', null, { root: true })
          dispatch('ProductTile/updateCompletedBMSM', {}, { root: true })
          commit('setCartInitialized', true)
        }
        commit('setFetchedCart', true)

        if (state.partnerQueryParams?.items
            && rootGetters['SiteConfig/varByName']('feature_query_string_recipes')) {
          dispatch('addRecipePartnerProducts')
        }

        return response
      } catch {
        commit('setFetchedCart', true)
        return response
      }
    },
    async updateCartItems({
      commit, dispatch, rootGetters, state
    }, payload) {
      let responseData
      const userId = rootGetters['UserProfile/userId']
      commit('setFetchedCart', false)
      try {
        responseData = await CartAPI.updateCart(payload, userId, state.basketId)
        if (responseData?.response?.status === 400 && responseData?.response?.data?.response?.cart) {
          commit('setCart', responseData.response.data.response.cart)
          dispatch('ProductTile/updateCompletedBMSM', {}, { root: true })
          commit('removeCartResponse', payload.items[0].productId)
          commit('setCartResponses', responseData.response.data.response.successes)
          commit('setCartResponses', responseData.response.data.response.errors)
          commit('setFetchedCart', true)
          return responseData.response
        }
        // error message doesn't make sense if there's no product or for bulk add
        if (responseData?.response?.status >= 400 && payload.items.length === 1) {
          const isItemInCart = state.cart.items.some(element => element.prodId === payload.items[0].productId)
          const headerMsg = isItemInCart ? `Item quantity not updated` : `Item not added to cart`
          const bodyMsg = isItemInCart
            ? `We’re unable to update the quantity of the item. Please refresh the page and try again.`
            : `We’re unable to add this item to your cart. Please refresh the page and try again.`
          commit('Alert/setAlert', {
            icon: true,
            type: 'error',
            header: headerMsg,
            body: bodyMsg,
            primary: {
              text: 'Ok',
              callback: () => {
                window.location.reload()
              }
            },
          }, { root: true })
        }

        if (responseData?.data?.response?.cart) {
          commit('setCart', responseData.data.response.cart)
          dispatch('ProductTile/updateCompletedBMSM', {}, { root: true })
          commit('removeCartResponse', payload.items[0].productId)
          commit('setCartResponses', responseData.data.response.successes)
          commit('setCartResponses', responseData.data.response.errors)
        }
        commit('setFetchedCart', true)
        return responseData
      } catch {
        commit('setFetchedCart', true)
        return responseData
      }
    },
    async deleteFromCart({
      commit, dispatch, rootGetters, state
    }, payload) {
      let responseData
      const userId = rootGetters['UserProfile/userId']
      const value = payload.items[0]
      commit('setFetchedCart', false)
      try {
        responseData = await CartAPI.deleteItemFromCart(value, userId, state.basketId)
        if (responseData.response) {
          responseData = responseData.response
        }
        const { status } = responseData
        const success = (status && (status >= 200 && status < 300))
        if (success && responseData?.data?.response?.cart) {
          commit('setCart', responseData.data.response.cart)
          dispatch('ProductTile/updateCompletedBMSM', {}, { root: true })
        }
        commit('Slots/removeSlotRestrictionItem', value, { root: true })
        commit('removeCartResponse', value.productId)
        commit('setFetchedCart', true)
        return responseData
      } catch (error) {
        commit('setFetchedCart', true)
        return error
      }
    },
    async clearCart({
      rootGetters, rootState, dispatch, commit, state
    }, cartItems) {
      commit('setFetchedCart', false)
      try {
        // Check if podbag is in the cart to know if we need to add it back in
        dispatch('DeliveryOptions/checkPodbagInCart', null, { root: true })
        const userId = rootGetters['UserProfile/userId']
        const payload = {
          userId,
          basketId: state.basketId,
        }
        let clearCartResponse
        clearCartResponse = await CartAPI.clearEntireCart(payload)
        if (clearCartResponse.response) {
          clearCartResponse = clearCartResponse.response
        }
        if (clearCartResponse.status === 200) {
          // If podbag was in the cart, add it again
          const { podBagInCart } = rootState.DeliveryOptions
          if (podBagInCart) {
            const podBagPayload = {
              items: [{
                productId: PODBAG_PROD_ID,
                quantity: 1
              }]
            }
            await dispatch('updateCartItems', podBagPayload)
          }
          await dispatch('queryCart')
          commit('SwapAndSave/toggleSwapNSave', false, { root: true })

          // GTM Filter out the podbag
          const filteredProductList = cartItems.filter(item => item.prodId !== PODBAG_PROD_ID)
          // GTM Update the removed product list and attach previousQty and newQty
          const removedItemsProductList = filteredProductList.map((item) => {
            item.previousQty = item.qty
            item.newQty = 0
            return item
          })

          trackCartBulkChange({
            direction: 'remove',
            cart: {
              products: removedItemsProductList
            }
          })

          window.sharedVue.config.globalProperties.$trackClientLog('cart_empty')

          commit('Alert/clear', null, { root: true })
          commit('setFetchedCart', true)
        } else {
          throw new Error()
        }
      } catch (error) {
        commit('setFetchedCart', true)
        commit('Alert/clear', null, { root: true })
        commit('Alert/setAlert', {
          type: 'error',
          header: 'Cart Error',
          body: 'There was an error clearing your cart.',
          primary: {
            text: 'Continue',
            callback: () => {
              commit('Alert/clear', null, { root: true })
            }
          }
        }, { root: true })
      }
    },
    updateCartItemSubstitutionPref({ commit }, payload) {
      commit('updateCartItemSubstitutionPref', payload)
    },
    async setPrimaryCart({
      commit, dispatch, rootGetters
    }, payload) {
      let responseData
      const { basketId, queryCart = true } = payload
      const userId = rootGetters['UserProfile/userId']
      commit('setFetchedCart', false)
      try {
        responseData = await CartAPI.setPrimaryCart({ userId, basketId })
        if (responseData.response) {
          responseData = responseData.response
        }
        const { status } = responseData
        const success = (status && (status >= 200 && status < 300))
        if (success && responseData?.data?.response?.toLowerCase() === 'updated' && queryCart) {
          commit('setBasketId', basketId)
          const queryResponse = await dispatch('queryCart')
          responseData.queryResponse = queryResponse
        }
        commit('setFetchedCart', true)
        return responseData
      } catch {
        commit('setFetchedCart', true)
        return responseData
      }
    },
    async saveItemComment({
      commit, rootGetters, state
    }, payload) {
      let response
      const userId = rootGetters['UserProfile/userId']
      const { basketId } = state
      const { productId, comment } = payload
      try {
        response = await orderAPI.saveItemComment(userId, basketId, productId, comment)
        const { status } = response
        if (status && (status >= 200 && status < 300)) {
          commit('setItemComment', { productId, comment })
        }
        return response
      } catch (error) {
        return error
      }
    },
    async deleteItemComment({
      commit, rootGetters, state
    }, payload) {
      let response
      const userId = rootGetters['UserProfile/userId']
      const { productId } = payload
      try {
        response = await orderAPI.deleteItemComment(userId, state.basketId, productId)
        const { status } = response
        if (status && (status >= 200 && status < 300)) {
          commit('deleteItemComment', productId)
        }
        return response
      } catch (error) {
        return error
      }
    },
    async fetchAllItemComments({
      commit, rootGetters, state
    }) {
      let response
      try {
        const userId = rootGetters['UserProfile/userId']
        response = await orderAPI.getAllItemComments(userId, state.basketId)
        const { status } = response
        const success = status && (status >= 200 && status < 300)
        if (success && response.data) {
          commit('setAllItemComments', response.data)
        }
        return response
      } catch (error) {
        return error
      }
    },
    async setCartSubstitutionPref({
      state
    }, payload) {
      const { newValue } = payload
      try {
        const { userId } = appConfig.user
        return await CartAPI.setCartSubstitutionPref(newValue, userId, state.basketId)
      } catch (error) {
        return error
      }
    },
    async putTip({ commit, rootGetters }, payload) {
      const userId = rootGetters['UserProfile/userId']
      const basketId = rootGetters['Cart/getBasketId']
      const payloadData = {
        params: { amount: +payload },
        basketId,
        userId
      }
      const response = await orderTipApi.putTip(payloadData)
      const responseData = {
        value: response?.data?.amount?.toString(),
        status: response.status || response?.response?.status,
        code: response?.response?.data?.code
      }
      if (response.status === 200) {
        commit('setDriverTip', payload)
      }
      return responseData
    },
    async deleteTip({ commit, rootGetters }) {
      const userId = rootGetters['UserProfile/userId']
      const basketId = rootGetters['Cart/getBasketId']
      const response = await orderTipApi.delete({
        userId,
        basketId
      })

      const responseData = {
        status: response.status || response?.response?.status,
      }

      const success = response?.status === 200

      if (success) {
        commit('setDriverTip', '0.00')
      }
      return responseData
    },
    async addRecipePartnerProducts({
      state, dispatch, rootGetters, getters
    }) {
      const currentServiceLocationId = rootGetters['UserProfile/serviceLocationId']
      const storeId = state.partnerQueryParams.store
      const isPartiallyRegistered = rootGetters['UserProfile/isPartiallyRegistered']
      const defaultServiceLocationId = rootGetters['SiteConfig/varByName']('domain_config_model_service_location_id')
      const isDefaultStore = defaultServiceLocationId.toString() === currentServiceLocationId.toString()
      const isANumberRegex = /^\d+$/
      // unauth logic: if basket is empty, change serviceLocation
      // OR change if service location is the default service location and received store is different
      // just make sure that the service locaation is numeric first
      if ((!getters.cartHasItems || isDefaultStore)
        && isANumberRegex.test(storeId)
        && !isPartiallyRegistered
        && storeId !== currentServiceLocationId.toString()
      ) {
        const payload = {
          dryRun: false
        }
        const response = await updateServiceLocationAPI(payload, {
          futureServiceLocationId: storeId,
          delivAreaChangeOverride: true
        })
        // wait for page to reload if service location returns successfully
        // otherwise assume that the given service location was bad and continue with the existing one
        if (response.status === 205) return
      }

      // multibasket only adds to WORKING order
      if (rootGetters['SiteConfig/varByName']('feature_multibasket')
        && state.cart?.orderStatusCode === 'SUBMITTED'
        && rootGetters['LoyaltyAccount/isLoyaltyRegistered']
        && state.basketId !== 1
      ) {
        await dispatch('setPrimaryCart', {
          basketId: state.workingOrderBasketId,
          queryCart: false
        })
        window.location.reload()
        return
      }
      const items = parseItemsQueryString(state.partnerQueryParams.items)

      let addToCartResponse = {
        items: []
      }
      if (items.length) {
        addToCartResponse = await bulkAddToCart({
          items,
          id: state.partnerQueryParams?.partner
        })
      }
      await dispatch('handleRecipeResponse', addToCartResponse)
    },
    async handleRecipeResponse({ commit, dispatch }, addToCartResponse) {
      // clear queryString and data after add to cart to avoid calling twice
      window.history.replaceState('', '', '/cart/')
      commit('setPartnerQueryParams', {})

      // need to cover cart errors by refreshing cart if there is an invalid product until cart rearch is done
      const hasAnyErrors = addToCartResponse?.items.filter(item => item.code === 'ERROR').length
      const hasAnySuccesses = addToCartResponse?.items.filter(item => item.code === 'SUCCESS').length
      if (hasAnyErrors || (!hasAnyErrors && !hasAnySuccesses)) {
        if (!hasAnySuccesses) {
          // all failures OR no items added - throw error message and run no further code
          commit('setPartnerRecipeAdded', false)
          commit('Alert/setAlert', {
            icon: true,
            type: 'error',
            header: 'Items Not Added',
            body: 'We cannot add the items from that recipe to your cart. Please try again Later',
            primary: {
              text: 'Continue Shopping'
            },
          }, { root: true })

          return
        }
        await dispatch('queryCart')
      }
      commit('setPartnerRecipeAdded', true)
    }
  }
}
