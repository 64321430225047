import { isAndroidDevice, isIOSDevice } from 'components/detect/services/detect-services'

/**
 * @typedef {import('utils/tracking/tracking.types').Header} Header
 * @typedef {import('utils/tracking/tracking.types').HeaderAvailabilty} HeaderAvailabilty
 * @typedef {import('utils/tracking/tracking.types').CartItemCountInfo} CartItemCountInfo
 * @typedef {import('utils/tracking/tracking.types').Product} Product
 * @typedef {import('utils/tracking/tracking.types').ProductDetails} ProductDetails
 * @typedef {import('utils/tracking/tracking.types').ProductDetailsForTagging} ProductDetailsForTagging
 * @typedef {import('utils/tracking/tracking.types').SlotTrackingData} SlotTrackingData
 * @typedef {import('utils/tracking/tracking.types').SlotDateData} SlotDateData
 */

/**
 * @returns {Date} A date object with today's date
 */
export function today() {
  return new Date()
}

/**
 * @returns {Date} A Date object with tomorrow's date
 */
export function tomorrow() {
  return new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
}
/**
 * returns a date format that matches the format in slot data
 * @param {any} date any parameter that can be used for a Date constructor
 * @returns {string} formatted as '2001-09-09'
 */
export function toIsoString(date) {
  return new Date(date).toISOString().substring(0, 10)
}

/**
 * @param {Header[]} headers
 * @param {string} date in iso format i.e. '2001-09-10'
 * @returns {headers}
 */
export function findHeaderDate(headers, date) {
  return headers.find(header => header.date === date)
}

/**
 * @param {Header} specificHeader
 * @returns {boolean}
 */
export function availableSlotsInDay(specificHeader) {
  if (!specificHeader) return false
  if (String(specificHeader.statusCode) === 'U') return false // unavailable
  return !!(specificHeader.attendedCount + specificHeader.unattendedCount)
}

/**
 * @param {Date} firstDate - to be compared
 * @param {Date} secondDate date - to be compared
 * @return {number} - days ellapsed between two dates
 * */
export function dateDifference(firstDate, secondDate) {
  const oneDay = 1000 * 60 * 60 * 24
  return Math.round(
    Math.abs(secondDate.getTime() - firstDate.getTime()) / oneDay
  )
}
/**
 *
 * @param {Product} product - containing: name, prodId, price, brand, rootCatName, size, bmsmTiers, flags
 * @return {productDetails} - mapped productDetails for gtm tracking
 * */
export function setupProductDetails({
  name,
  prodId,
  price,
  brand,
  rootCatName,
  size,
  bmsmTiers,
  sustainabilityRating,
  guidingStars,
  flags,
  ebtEligible,
  isMarketplaceProduct,
  position = -1,
  sponsoredProductInfo = false,
  healthAndLifestyleAttributeText = '',
}) {
  const productDetails = {
    id: prodId,
    category: rootCatName,
    variant: size,
    name,
    price,
    sustainable: sustainabilityRating > 0,
    brand,
    ebtEligible,
    isMarketplaceProduct,
    position,
    dimension156: [],
  }
  const { dimension156 } = productDetails

  const discountName = !!bmsmTiers && !!bmsmTiers[0] ? bmsmTiers[0].description : false
  const healthLifestyleAttributes = [
    ...getFormattedProductAttributes(flags),
    ...(guidingStars > 0 ? ['guiding stars'] : []),
    ...(sustainabilityRating > 0 ? ['sustainability'] : []),
  ]

  if (discountName) {
    productDetails.dimension78 = discountName
  }
  if (flags) {
    productDetails.dimension37 = flags.sale
  }

  if (sponsoredProductInfo) {
    dimension156.push('sponsored')
  }
  if (ebtEligible) {
    dimension156.push('ebt')
  }
  if (sustainabilityRating > 0) {
    dimension156.push('sustainable')
  }
  if (isMarketplaceProduct) {
    dimension156.push('marketplace')
  }
  if (healthLifestyleAttributes) {
    dimension156.push(...healthLifestyleAttributes)
  }
  if (healthAndLifestyleAttributeText) {
    productDetails.dimension137 = healthAndLifestyleAttributeText
  }
  return productDetails
}

/**
 * @param {{
 *   name:string
 *   id:string
 *   price_text:string
 *   brand:string
 *   categories:string
 *   description:string
 * }}
 * @return {{
 *   id: string,
 *   category: string,
 *   variant: string,
 *   name: string,
 *   price: string,
 *   brand: string
 * }} - mapped productDetails for gtm tracking
 * */
export function setupWeeklyAdDetails({
  name, id, priceText, brand, categories, description
}) {
  return {
    id,
    category: categories[0],
    variant: description,
    name,
    price: priceText,
    brand,
  }
}

/**
 * @function CartItemCount
 * @param {any} getProductsSortedByCategory
 * @returns {CartItemCountInfo}
 */
export function cartItemCount(getProductsSortedByCategory) {
  const itemCounter = (itemList) => {
    // Calculate total number of items
    let sum = 0
    const items = Object.entries(itemList)
    items.forEach(([, value]) => {
      sum += value.length
    })
    return sum
  }
  const itemQtyTotal = (itemList) => {
    // Calculate total number of items qty and item total
    let totalQty = 0
    let totalSum = 0
    const items = Object.entries(itemList)
    items.forEach(([, value]) => {
      Object.entries(value).forEach(([, item]) => {
        totalQty += item.qty
        totalSum += item.price * item.qty
      })
    })
    return { totalQty, totalSum }
  }
  if (
    getProductsSortedByCategory?.items
  ) {
    const itemTotals = itemQtyTotal(getProductsSortedByCategory.items)
    return {
      groceryitemCount: itemCounter(getProductsSortedByCategory.items),
      groceryitemTotal: itemTotals.totalQty,
      groceryitemValue: itemTotals.totalSum,
    }
  }

  return {
    groceryitemCount: 0,
    groceryitemTotal: 0,
    groceryitemValue: 0,
  }
}

export function isOrderStatusSubmitted(lastState, currentState) {
  return (
    _.isObject(lastState)
    && _.isObject(currentState)
    && _.isNumber(currentState.revenue)
    && lastState.orderStatus === 'WORKING'
    && currentState.orderStatus === 'SUBMITTED'
  )
}

/**
 * @param {slot} object
 * @param {Header[]} headers
 * @return {SlotTrackingData}
 * */
export function setupDataForSlot(slot, headers) {
  const dateData = setupDateForLog(slot)
  const availability = getAvailability(headers)
  const subscriptionTag = {
    subscriptionConfirm: slot.subscriptionConfirm ? 'Yes' : 'No',
    subscriptionPlan: slot.currentPlanName
  }

  return {
    ...dateData,
    ...availability,
    serviceModel: slot.serviceType,
    ...subscriptionTag
  }
}

/**
 * @param {Header[]} headers
 * @returns {HeaderAvailabilty}
 */
export function getAvailability(headers) {
  const todayISO = toIsoString(today())
  const tomorrowISO = toIsoString(tomorrow())

  const todayHeader = findHeaderDate(headers, todayISO)
  const tomorrowHeader = findHeaderDate(headers, tomorrowISO)

  const sameDayAvailable = !!(
    todayHeader && availableSlotsInDay(todayHeader)
  )
  const tomorrowAvailable = !!(
    tomorrowHeader && availableSlotsInDay(tomorrowHeader)
  )

  const currentTimestamp = Date.parse(today())

  let daysPresented = _.filter(headers, header => header.statusCode !== 'U')
  daysPresented = daysPresented.length

  return {
    tomorrowAvailable,
    sameDayAvailable,
    daysPresented,
    currentTimestamp,
  }
}
/**
 * @param {Product[]} products
 * @return {ProductDetails[]} - mapped products list, with mapped products, for gtm tracking
 * */
export function mapProductListDetails(products) {
  return _.map(products, (product) => {
    const productData = setupProductDetails(product)
    productData.quantity = product.qty

    return productData
  })
}
/**
 * @param {{ date: string }}
 * @return {SlotDateData}
 * */
export function setupDateForLog({ date }) {
  const deliverTimeSelected = !!date
  const d = date
  const days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ]
  const deliveryDay = new Date(`${d}T00:00:00`)
  const daySelected = days[deliveryDay.getDay()]
  const daysToDelivery = d === undefined ? d : dateDifference(today(), deliveryDay)
  const selectedDeliveryTimestamp = Date.parse(d) || undefined

  return {
    deliverTimeSelected,
    daySelected,
    daysToDelivery,
    selectedDeliveryTimestamp,
  }
}
/**
 * @param {boolean} isMobileApp
 * @returns {'web'|'native:unknown'|'ios'|'android'} platform string for GTM
 */
export function getPlatformString(isMobileApp) {
  let platform = 'web'
  if (isMobileApp) {
    platform = 'native:unknown' // if one these checks fail, ensure GA knows it is a native app
    if (isIOSDevice()) {
      platform = 'ios'
    }
    if (isAndroidDevice()) {
      platform = 'android'
    }
  }
  return platform
}
/**
 *
 * @param {Product} - product
 * @return {Array<"ebt"|"sustainable"|"marketplace">} array - of product attributes: ebt, marketplace and sustainable
 * */
export function buildItemVariant(product) {
  const {
    ebtEligible,
    sustainabilityRating,
    isMarketplaceProduct,
    boosted,
  } = product
  const itemVariant = [] // Product Attribute
  const isSustainable = sustainabilityRating > 0

  if (product.sponsoredProductInfo) {
    itemVariant.push('sponsored')
  }
  if (ebtEligible) {
    itemVariant.push('ebt')
  }
  if (isSustainable) {
    itemVariant.push('sustainable')
  }
  if (isMarketplaceProduct) {
    itemVariant.push('marketplace')
  }
  if (boosted) {
    itemVariant.push('Staple')
  }
  return itemVariant
}

/**
 *
 * @param {Product} - product
 * @returns {Array<>} array of product attributes such as dairy free, gluten free, vegan
 */
export function getFormattedProductAttributes(flags) {
  const productAttributesForGt = {
    dairy: 'dairy free',
    gluten: 'gluten free',
    kosher: 'kosher',
    organic: 'organic',
    vegan: 'vegan',
    vegetarian: 'vegetarian',
  }
  return Object.entries(productAttributesForGt)
    .map(([key, val]) => {
      return !_.isUndefined(flags) && flags[key] ? val : ''
    })
    .filter(Boolean)
}

/**
 *
 * @param {Product} product
 * @param {object={}} options Optional additional information
 * @return {ProductDetailsForTagging} product attributes formatted for GA4
 * */
export function buildProductDetailsForGA4Tagging(product, options = {}) {
  const {
    prodId, name, coupon, productIndex, price, regularPrice, brand, rootCatName, subcatName, listId, listName
  } = product
  const discount = price !== regularPrice ? (Math.round(regularPrice * 100) - Math.round(price * 100)) / 100 : null
  const flagsAvailable = Object.keys(product.flags || {}).filter(key => product.flags[key] === true)
  const itemCategory5 = flagsAvailable?.length >= 1 ? flagsAvailable.join() : null
  const { locationId } = options
  const itemVariant = buildItemVariant(product)?.join()
  return {
    item_id: prodId,
    item_name: name,
    ...(coupon && { coupon: coupon.id }),
    ...(discount && { discount }),
    ...(productIndex && productIndex > 0 && { index: productIndex }),
    item_brand: brand,
    item_category: rootCatName,
    item_category2: subcatName,
    ...(itemCategory5 && { item_category5: itemCategory5 }),
    item_list_id: listId,
    ...(listName && { item_list_name: listName }),
    ...(itemVariant && { item_variant: itemVariant }),
    location_id: locationId,
    price: regularPrice
  }
}
