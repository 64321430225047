import { LOGGING_TYPE } from 'utils/constants'

function trackSlotCutOffClientLog(orderStatus, event) {
  window.sharedVue.config.globalProperties.$trackClientLog('slots_cutoff', {
    orderStatus,
    event
  }, LOGGING_TYPE.event)
}

function getMinutesString(payload) {
  let minutes
  if (payload < 61) {
    minutes = Math.floor(payload / 60)
  } else {
    minutes = Math.ceil(payload / 60)
  }

  if (minutes === 0) {
    return `${payload} seconds`
  }
  return `${minutes} minute${(minutes === 1) ? '' : 's'}`
}

export default { getMinutesString, trackSlotCutOffClientLog }
