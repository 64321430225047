<template>
  <div
    :class="[
      'search-field',
      [ isBlock ? 'search-field--block' : 'search-field--inline' ]
    ]"
  >
    <div
      v-if="hasSearchIcon"
      class="search-field_pin-icon"
      @click="searchPinClicked"
      @keyup.enter.space="searchPinClicked"
    >
      <PdlIconBase
        icon-title="Search"
        :icon-class="iconClass"
        container-class="vector-icon-size--medium"
        :vb-width="25"
      >
        <PdlIconSearchPin />
      </PdlIconBase>
    </div>
    <input
      :id="id"
      ref="inputElement"
      :value="modelValue"
      class="search-field_input"
      :class="{ 'search-field_input--with-icon': hasSearchIcon }"
      type="text"
      autocomplete="off"
      :placeholder="label"
      :aria-controls="ariaControlledId"
      @input="updateValue($event.target.value)"
      @keypress.enter.prevent="submit"
    >
    <label
      :for="id"
      class="element-invisible"
    >
      Search by {{ label }}
    </label>
    <button
      v-if="modelValue"
      class="search-field_clear-button"
      @click.stop="clear"
    >
      <span class="element-invisible">
        Clear search
      </span>
      <PdlIconBase
        icon-title="Clear search"
        icon-class="vector-icon-color--darker-grey"
        container-class="search-field_clear-icon"
      >
        <PdlIconClose />
      </PdlIconBase>
    </button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  PdlIconBase,
} from '@/shared/icons'
import PdlIconClose from 'components/icons/icon/PdlIconClose'
import PdlIconSearchPin from 'components/icons/icon/PdlIconSearchPin'

export default {
  components: {
    PdlIconBase,
    PdlIconClose,
    PdlIconSearchPin
  },
  props: {
    modelValue: {
      type: String,
    },
    id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    isBlock: {
      type: Boolean,
      default: false
    },
    hasSearchIcon: {
      type: Boolean,
      default: false
    },
    controlledId: {
      type: String,
      default: undefined
    }
  },
  computed: {
    ...mapGetters({
      locationServicesEnabled: 'NativeContainer/locationServicesEnabled'
    }),
    ariaControlledId() {
      return this.controlledId ? this.controlledId : false
    },
    iconClass() {
      return `vector-icon-color--${this.locationServicesEnabled ? 'prime' : 'darker-grey'}`
    }
  },
  mounted() {
    if (this.hasSearchIcon) {
      this.checkLocationEnabled()
    }
  },
  methods: {
    async checkLocationEnabled() {
      const isLocationEnabled = await this.NativeContainer.capabilities.LocationService.isEnabled()
      if (isLocationEnabled) {
        this.searchPinClicked()
      }
    },
    updateValue(zipCode) {
      this.$emit('update:modelValue', zipCode)
    },
    submit() {
      this.$emit('submit')
    },
    clear() {
      this.$emit('clear')
      // allow moment for animation, then focus input for new search
      const vm = this
      setTimeout(() => {
        vm.$refs.inputElement.focus()
      }, 100)
    },
    async searchPinClicked() {
      this.$store.commit('NativeContainer/setLocationServicesStatus', false)
      const isLocationEnabled = await this.NativeContainer.capabilities.SoftAskService.enableSoftAskFlow('location')

      if (isLocationEnabled) {
        const storeLocatorLocation = await this.NativeContainer.capabilities.LocationService.getCurrentLocation()
        this.$store.commit('NativeContainer/setLocationServicesStatus', true)

        const latlng = {
          lat: parseFloat(storeLocatorLocation.coords.latitude),
          lng: parseFloat(storeLocatorLocation.coords.longitude)
        }

        const zipCode = await this.getZipFromLatLong(latlng)
        this.updateValue(zipCode)
        this.submit()
      }
    },
    getZipFromLatLong(latlng) {
      return new Promise((resolve, reject) => {
        const geocoder = new window.google.maps.Geocoder()
        geocoder.geocode({ location: latlng }, (results, status) => {
          if (status === 'OK') {
            const address = results[0]
            let zipCode = ''
            address.address_components.forEach((component) => {
              if (component.types[0] === 'postal_code') {
                zipCode = component.long_name
              }
            })
            resolve(zipCode)
          } else {
            reject(status)
          }
        })
      })
    },
  }
}
</script>
