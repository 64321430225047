<template>
  <div>
    <PdlSpyglassGroupInfo
      v-if="isGroup"
      v-show="showSpyglassContent"
      :content="spyglassContent"
    />
    <PdlSpyglassInfo
      v-if="!isGroup"
      v-show="showSpyglassContent"
      :content="spyglassContent"
    />
    <PdlSpyglassGenericToggle @spyglass-toggle="spyglassSwitch" />
  </div>
</template>

<script>
import PdlSpyglassGroupInfo from 'components/spyglass/PdlSpyglassGroupInfo'
import PdlSpyglassGenericToggle from 'components/spyglass/PdlSpyglassGenericToggle'
import PdlSpyglassInfo from 'components/spyglass/PdlSpyglassInfo'

export default {
  components: {
    PdlSpyglassGroupInfo,
    PdlSpyglassGenericToggle,
    PdlSpyglassInfo
  },
  props: {
    spyglassContent: {
      type: Object,
      default: () => ({
        parents: [],
        children: [],
      })
    },
    isGroup: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    showSpyglassContent: false
  }),
  methods: {
    spyglassSwitch(toState) {
      this.showSpyglassContent = toState
    }
  }
}
</script>
