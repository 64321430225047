<template>
  <div class="spyglass_container z-[97]">
    <ul
      class="scrollable-content spyglass_content"
      @click.stop=""
    >
      <li>
        <span class="spyglass_label">
          Scheduled Id:
        </span>
        <a
          class="spyglass_jira-id-link"
          :href="`http://jiracore.peapod.com/browse/${content.key || content.ScheduleId}`"
          target="_blank"
          rel="noopener noreferrer"
        >
          {{ content.key || content.ScheduleId }}
        </a>
      </li>
      <li v-if="content['Content Container Key']">
        <span class="spyglass_label">
          Content Container Id:
        </span>
        <a
          class="spyglass_jira-id-link"
          :href="`http://jiracore.peapod.com/browse/${content['Content Container Key']}`"
          target="_blank"
          rel="noopener noreferrer"
        >
          {{ content['Content Container Key'] }}
        </a>
      </li>
      <li>
        <span class="spyglass_label">
          {{ content['Content Container Key'] ? 'Subtask Id' : 'Content Id' }}:
        </span>
        <a
          class="spyglass_jira-id-link"
          :href="`http://jiracore.peapod.com/browse/${content.scheduledContentKey}`"
          target="_blank"
          rel="noopener noreferrer"
        >
          {{ content.scheduledContentKey }}
        </a>
      </li>
      <li v-if="content['fragmentParentContainers'] && content['fragmentParentContainers'].length >= 1">
        <span class="spyglass_label">
          Parent Container Scheduled Id:
        </span>
        <a
          class="spyglass_jira-id-link"
          :href="`http://jiracore.peapod.com/browse/${content['fragmentParentContainers'][0].Key}`"
          target="_blank"
          rel="noopener noreferrer"
        >
          {{ content['fragmentParentContainers'][0].Key }}
        </a>
      </li>
      <li>
        <span class="spyglass_label">
          Start Date:
        </span>
        {{ content['Start Date'] }}
      </li>
      <li>
        <span class="spyglass_label">
          End Date:
        </span>
        {{ content['End Date'] }}
      </li>
      <li>
        <span class="spyglass_label">
          Priority:
        </span>
        {{ !!content.Scheduled_Priority ? content.Scheduled_Priority : '1' }}
      </li>
      <li v-if="content.link">
        <span class="spyglass_label">
          Link:
        </span>
        {{ content.link }}
      </li>
      <li v-if="content['Customer Status'] && content['Customer Status'].myArrayList.length">
        <span class="spyglass_label">
          Customer Status:
        </span>
        {{ content['Customer Status'].myArrayList.join(', ') }}
      </li>
      <li v-if="content['Customer Type'] && content['Customer Type'].myArrayList">
        <span class="spyglass_label">
          Customer Type:
        </span>
        {{ content['Customer Type'].myArrayList.join(', ') }}
      </li>
      <li
        v-if="!!content.Opcos && !content['Store Codes']
          || !!content.Opcos && !!content['Store Codes']"
      >
        <span class="spyglass_label">
          Opcos:
        </span>
        {{ content.Opcos.myArrayList.join(', ') }}
      </li>
      <li
        v-if="content['Store Codes']"
      >
        <span class="spyglass_label">
          Store Codes:
        </span>
        {{ content['Store Codes'] }}
      </li>
      <li v-if="!content.Opcos && !content['Store Codes']">
        <span class="spyglass_label">
          Targeting:
        </span>
        All Stores
      </li>
      <li class="spyglass_module-type">
        <span class="spyglass_label">
          Module Type:
        </span>
        "{{ content['contentType'] ? content['contentType'] : content['moduleType'] }}"
      </li>
      <li class="spyglass_site-location">
        <span class="spyglass_label">
          Site_Location:
        </span>
        {{ content['Site_Location'] }}
      </li>
      <li
        v-if="content['Device Type (Screen Size)'] && content['Device Type (Screen Size)'].myArrayList.length"
        class="spyglass_screen-size"
      >
        <span class="spyglass_label">
          Device Type (Screen Size):
        </span>
        {{ content['Device Type (Screen Size)'].myArrayList.join(', ') }}
      </li>
      <li
        v-if="content['App Targeting'] && content['App Targeting'].myArrayList.length"
        class="spyglass_app-targeting"
      >
        <span class="spyglass_label">
          App Targeting:
        </span>
        {{ content['App Targeting'].myArrayList.join(', ') }}
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  props: {
    content: {
      type: Object,
      required: true
    }
  }
}
</script>
