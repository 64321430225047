
export const shouldForceRegistration = () => {
  const { $store } = window

  const isLoyaltyRegistered = $store.getters['LoyaltyAccount/isLoyaltyRegistered']
  const isLoggedIn = $store.getters['LoginStatus/isLoggedIn']
  return isLoggedIn && !isLoyaltyRegistered
}

export const redirectToRegistrationBeforeEcomm = (redirectUrl = '') => {
  const { $router } = window.sharedVue?.config?.globalProperties || {}

  // /registration?loyaltyFDLNRedirect=true&redirectUrl=https://shop.foodlion.com`)

  const query = { loyaltyFDLNRedirect: true }
  if (redirectUrl) {
    query.redirectUrl = redirectUrl
  }
  $router.push({
    path: '/registration',
    query
  })
}

// *** EXAMPLE USE ***
// myComponentMethodPopAlert() {
//   popRedirectAlertBeforeEcomm({
//     redirectUrl: this.myPreferredRedirectLink,
//     callback: this.doSomethingBeforeRedirect
//   })
// }

export const popRedirectAlertBeforeEcomm = (params = {}) => {
  const {
    redirectUrl = '',
    callback = () => {}
  } = params
  const { $store } = window
  const programShopName = $store.getters['SiteConfig/varByName']('brand_click_collect_name')

  $store.commit('Alert/setAlert', ({
    header: 'Finish Your Account',
    body: `Finish setting up your account before heading over to ${programShopName} to start shopping.`,
    primary: {
      text: 'Finish Account',
      callback: () => {
        callback()
        $store.commit('Alert/clear')
        $store.commit('Modals/clearActiveModal')
        redirectToRegistrationBeforeEcomm(redirectUrl)
      }
    }
  }))
}
