<template>
  <span
    aria-label="product is loading"
    data-test="pdl-skeleton-loader-box"
    :style="{ height, width: computedWidth }"
    class="skeleton-loader_box"
  />
</template>

<script>
export default {
  props: {
    maxWidth: {
      default: 100, // percentage
      type: Number
    },
    minWidth: {
      default: 80, // percentage
      type: Number
    },
    height: {
      // Make the box the same height as text.
      default: '1.6rem',
      type: String
    },
    width: {
      default: null,
      type: String
    }
  },
  computed: {
    computedWidth() {
      return this.width || `${Math.floor((Math.random() * (this.maxWidth - this.minWidth)) + this.minWidth)}%`
    }
  }
}
</script>
