<template>
  <div
    id="PDL-QUALTRICS-INSTANCE"
    class="relative flex row justify-center z-0 mx-auto"
  >
    <template v-if="error">
      <div
        id="qualtrics-state-error"
        class="py-16 px-4 relative"
      >
        <PdlGenericError
          class="relative top-0 right-0 mx-0 my-0 w-auto"
        />
      </div>
    </template>
    <!-- QUALTRICS - loader element -->
  </div>
</template>
<script>
import QualtricsHelper from 'components/feedback/QualtricsHelper'

export default {
  data() {
    return {
      qualtricsAvailable: false,
      error: false
    }
  },
  mounted() {
    this.startApp()
  },
  methods: {
    async startApp() {
      this.qualtricsAvailable = await QualtricsHelper.initialize()
      if (QualtricsHelper.complete && !this.qualtricsAvailable) {
        this.error = true
        return this.logError(QualtricsHelper.complete, this.qualtricsAvailable)
      }
      return QualtricsHelper.start()
    },
    logError(qualtricsCompleteLoadingAttempt, qualtricsAvailable) {
      this.$trackClientLog('qualtrics_attempt_failed', {
        qualtricsCompleteLoadingAttempt,
        qualtricsAvailable
      })
    }
  }
}
</script>
