import { GTM, GTM_GA4 } from 'utils/constants'

export function trackHelpSubmit({ userLoggedIn = false, emailSubject = '', status = false }, {
  action, linkText, response, siteLocation, transactionId, type
} = {}) {
  const vueInstance = window.sharedVue.config.globalProperties
  vueInstance.$trackGtmEvent(GTM.helpSubmit, {
    userLoggedIn,
    emailSubject,
    status
  })

  if (!response) {
    return
  }

  const responseTracking = response?.status === 201 ? 'success' : `fail: ${response.error}`

  vueInstance.$trackGA4Event(
    GTM_GA4.account,
    {
      action,
      response: responseTracking,
      site_location: siteLocation,
      transaction_id: transactionId,
      type
    }
  )
  vueInstance.$trackGA4Event(
    GTM_GA4.click,
    {
      action,
      link_text: linkText,
      site_location: siteLocation,
    }
  )
}
