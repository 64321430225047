import { GTM } from 'utils/constants'
import { getPlatformString } from 'utils/tracking/helpers'
//  pass native check for device brand ios/android
//  these checks rely on user agent and window, which may not be as accurate

/**
 * Track landing page view
 * @param {boolean} isMobileApp
 * @returns {void}
 */
export function trackInit(isMobileApp) {
  window.sharedVue.config.globalProperties.$trackGtmEvent(GTM.platform, {
    pageCategory: 'landing',
    platform: getPlatformString(isMobileApp),
  })
}
