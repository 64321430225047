import { GTM } from 'utils/constants'

class ProductContentService {
  constructor() {
    this.configParentElement = undefined
    this.configRegion = undefined
    this.loggedInterwovenIds = []
  }

  setConfig({
    parentElement,
    region,
  }) {
    this.configParentElement = parentElement
    this.configRegion = region
    this.loggedInterwovenIds.length = 0 // resets logged ids for new page
  }

  // eslint-disable-next-line class-methods-use-this
  processContentDataResponse(contents, config) {
    let interweaveRegion = []
    if (config.search_shelf_content) {
      const searchShelfContent = contents.filter(content => content.region === 'search_shelf_content')
      const globalShelfContent = contents.filter(content => content.region === 'global_shelf_content')
      interweaveRegion = globalShelfContent[0]?.contents.length ? globalShelfContent[0]?.contents : []
      if (searchShelfContent[0]?.contents.length) {
        interweaveRegion.unshift(searchShelfContent[0]?.contents[0])
      }
    } else {
      const interweaveData = contents.length ? contents[0] : []
      interweaveRegion = interweaveData?.contents ? interweaveData.contents : []
    }
    return interweaveRegion
  }

  processShelfContent(interweaveRegion, tileShown) {
    const supported = ['Content: Text Tile', 'Content: Tile']
    const availableTiles = interweaveRegion.filter(v => supported.includes(v.map.moduleType))
    const output = []
    const used = []
    const incrementNum = tileShown
    let currentTileCount = 0
    const alreadyExists = (set) => {
      if (!used.length) {
        return false
      }
      const arr = _.flatten(used)
      return arr.some(v => v.map.id === set.id)
    }
    interweaveRegion.forEach((content) => {
      const set = content.map
      const type = {}
      if (supported.includes(set.moduleType) && !alreadyExists(set)) {
        const tiles = availableTiles.slice(currentTileCount, currentTileCount + incrementNum)
        type.full = true
        type.isContentTile = true
        type.layout = []
        tiles.forEach((tile) => {
          type.layout.push(this.groupIndividualScheduledEntry(tile.map))
        })
        used.push(tiles)
        currentTileCount += incrementNum
        output.push(type)
      }
      if (set.moduleType === 'Content: Multipanel') {
        const multipanel = {
          isMultiPanel: true,
          items: set.scheduledContentData.map.parts.myArrayList,
          parent: this.getScheduledData(set)
        }
        output.push(multipanel)
      }
      if (set.moduleType === 'Content: Container') {
        const searchShelfContainer = {
          isSearchShelfContainer: true,
          contents: set,
          parent: this.getScheduledData(set)
        }
        output.push(searchShelfContainer)
      }
    })
    return output
  }

  removeContentData() {
    this.interwovenContentData = Object.create(null)
  }

  groupIndividualScheduledEntry(contentData) {
    const contentItem = {
      scheduledContentData: this.getScheduledData(contentData)
    }
    contentItem.content = {
      moduleType: contentItem.scheduledContentData.moduleType,
      ...contentData.scheduledContentData.map
    }
    return contentItem
  }

  groupScheduledEntry(contentData) {
    const groupItems = []
    if (!contentData?.contents) return groupItems
    contentData.contents.forEach((data) => {
      const contentItem = {
        scheduledContentData: this.getScheduledData(data.map) || {}
      }
      contentItem.content = {
        moduleType: contentItem?.scheduledContentData?.moduleType || {},
        ...data.map.scheduledContentData.map
      }
      groupItems.push(contentItem)
    })
    return groupItems
  }

  // eslint-disable-next-line class-methods-use-this
  getScheduledData(scheduledDataResponse) {
    const scheduledObj = {}
    Object.keys(scheduledDataResponse).forEach((key) => {
      const val = scheduledDataResponse[key]
      if (scheduledDataResponse.scheduledContentData.key) {
        scheduledObj['Content Container Key'] = scheduledDataResponse.key
      }
      if (scheduledDataResponse.Opcos) {
        scheduledObj.Opcos = scheduledDataResponse.Opcos
      }
      if (scheduledDataResponse['Customer Status']) {
        scheduledObj['Customer Status'] = scheduledDataResponse['Customer Status']
      }
      if (scheduledDataResponse['Customer Type']) {
        scheduledObj['Customer Type'] = scheduledDataResponse['Customer Type']
      }

      if (scheduledDataResponse['Device Type (Screen Size)']) {
        scheduledObj['Device Type (Screen Size)'] = scheduledDataResponse['Device Type (Screen Size)']
      }

      if (scheduledDataResponse['App Targeting']) {
        scheduledObj['App Targeting'] = scheduledDataResponse['App Targeting']
      }

      if (scheduledDataResponse['Video Category']) {
        scheduledObj['Video Category'] = scheduledDataResponse['Video Category']
      }

      if (key === 'moduleType') {
        scheduledObj.moduleType = `Content Module:${scheduledDataResponse.moduleType.split(':')[1]}`
        scheduledObj.contentType = scheduledDataResponse.moduleType
      }
      if (typeof val === 'object' || key === 'moduleType') {
        return
      }
      scheduledObj[key] = val
    })
    return scheduledObj
  }

  // everything below is used for logging and tracking and rendering ads
  sendClickTrackFromGeneric(data) {
    const {
      scheduledContentId, contentId, region, contentModuleId, fragmentKey, name
    } = data
    let bodyEvent = {
      contentModuleId,
      scheduledContentId,
      contentId,
      fragmentKey,
      region,
      name
    }
    if (data.type === 'External Link') {
      bodyEvent = {
        targetUrl: data.url,
        ...bodyEvent
      }
    }
    if (contentId && scheduledContentId) {
      window.sharedVue.config.globalProperties.$trackClientLog('content_click', bodyEvent)
      if (!data.isCategoryL1Banner) {
        this.sendGTMTrack(data)
      }
    }
  }

  // eslint-disable-next-line class-methods-use-this
  sendGTMTrack(content) {
    const actionName = 'promoClick'
    const payload = {}
    payload[actionName] = {
      promotions: [{
        id: content.contentId,
        name: content.name,
        position: content.region,
        creative: content.creative ? content.creative : null
      }]
    }
    sharedVue.config.globalProperties.$trackGtmEvent(GTM.promotionClick, { ecommerce: payload })
  }

  trackCategoryContentClicked(category) {
    if (category.scheduledContentData) {
      const payload = {
        region: category.scheduledContentData.Site_Location,
        scheduledContentId: category.scheduledContentData.key,
        contentId: category.contentData.key,
        name: category.scheduledContentData['Friendly Tracking Description']
      }
      this.sendClickTrackFromGeneric(payload)
    }
  }

  // eslint-disable-next-line class-methods-use-this
  setCMSCategoryAsCurrentScheduledContent(url, category) {
    if (category.scheduledContentData) {
      window.sharedVue.config.globalProperties.$store.commit('ScheduledContent/setCurrentScheduledContent',
        {
          url,
          type: category.contentData['Button Link Type'],
          region: category.scheduledContentData.Site_Location,
          scheduledContentId: category.scheduledContentData.key || category.scheduledContentData.ScheduleId,
          contentId: category.contentData.key,
          contentModuleId: category.contentData.key,
          fragmentKey: category.contentData['Fragment Key'],
          name: category.scheduledContentData['Friendly Tracking Description']
        })
    }
  }
}

const service = new ProductContentService()

export default service
