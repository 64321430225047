import ApiService from '@/api/'

const apiBase = '/apis/v1/partner/INSTACART'

export default {
  createUser(params) {
    const {
      userId, firstName, opcoName
    } = params

    return ApiService.post(`${apiBase}/create/user/${userId}/opco/${opcoName}`, {
      userId,
      firstName
    })
  },
  validateAddressAndCreateUser(params) {
    const {
      userId, opcoName, addressLine1, addressLine2, postalCode
    } = params

    return ApiService.post(`${apiBase}/user-and-address/user/${userId}/opco/${opcoName}`, {
      userId,
      addressLine1,
      addressLine2,
      postalCode
    })
  }
}
