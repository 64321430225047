export default {
  namespaced: true,
  state: {
    active: false,
    collapsed: true,
    miniCartEnabled: false // A/B testing for showing mini cart
  },
  mutations: {
    updateMiniCartActive(state, payload) {
      // payload is a Boolean
      state.active = payload
    },
    updateCollapsed(state, payload) {
      // payload is a Boolean
      state.collapsed = payload
    },
    setMiniCartEnabled(state, value) {
      state.miniCartEnabled = value
    }
  }
}
