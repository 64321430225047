<!-- eslint-disable max-len !-->
<template>
  <g>
    <circle
      fill="none"
      cx="13.88"
      cy="13.66"
      r="9.28"
    />
    <line
      x1="25.85"
      y1="25.63"
      x2="20.77"
      y2="20.54"
    />
  </g>
</template>
