import { LOGGING_TYPE } from 'utils/constants'
import UserDevicesApi from 'api/UserDevicesApi'
import formatPhoneNumber from 'utils/filters/formatPhoneNumber'
import { isIOSDevice } from 'components/detect/services/detect-services'

const initialState = {}

export default {
  namespaced: true,
  state: initialState,
  getters: {},
  mutations: {},
  actions: {
    async enable({ dispatch }) {
      if (!window.appConfig.devicePushEnabled) {
        return
      }

      const { NativeContainer } = window.sharedVue.config.globalProperties
      const isValidUser = await dispatch('isValidUserStatus')
      const isMobileApp = await NativeContainer.isEnabledPromise()
      if (isValidUser && isMobileApp) {
        const deviceInfo = await NativeContainer.capabilities.NotificationService.getNotificationDeviceInfo()
        if (!deviceInfo.hasPrompted) {
          await dispatch('prompt')
        } else {
          await dispatch('updateDeviceData', deviceInfo)
        }
      }
    },
    async isValidUserStatus({ dispatch, rootGetters }) {
      const opco = rootGetters['SiteConfig/opco']
      if (opco === 'PPOD') {
        await dispatch('LoginStatus/getLoginStatus', { root: true })
        const userStatus = rootGetters['LoginStatus/userStatus']
        return userStatus === 'R' && rootGetters['LoginStatus/isLoggedIn']
      }
      return rootGetters['LoyaltyAccount/isLoyaltyRegistered']
    },
    async prompt({ commit, dispatch }) {
      if (!window.sessionStorage.getItem('deeplinkTo')) {
        const { NativeContainer } = window.sharedVue.config.globalProperties
        const deviceInfo = await NativeContainer.capabilities.SoftAskService.enableSoftAskFlow('push')

        await dispatch('updateDeviceData', deviceInfo)

        if (!deviceInfo.subscribed) {
          return false
        }
      }
      const { data } = await dispatch('Communications/getCommunications', null, { root: true })
      const { alerts } = data.response
      const { alertMethod, phone } = alerts
      const ORDER_ALERTS_MAIL_SUB_ID = 104
      const orderAlertsEnabled = alerts.mailSubIds?.includes(ORDER_ALERTS_MAIL_SUB_ID)

      if (orderAlertsEnabled && (alertMethod === 'BOTH' || alertMethod === 'SMS')) {
        const phoneText = formatPhoneNumber(phone)
        if (phoneText !== '') {
          commit('Alert/setAlert', {
            type: 'warning',
            header: 'Confirmation',
            body: `Would you like to stop receiving text messages at ${phoneText} ?`,
            primary: {
              text: 'Yes',
              callback: () => {
                commit('Alert/clear', null, { root: true })

                if (alertMethod === 'BOTH') {
                  alerts.alertMethod = 'EMAIL'
                } else if (alertMethod === 'SMS') {
                  alerts.alertMethod = null
                }

                dispatch('Communications/updateCommunications', alerts, { root: true })
              }
            },
            secondary: {
              text: 'No',
              color: 'fourth',
              callback: () => commit('Alert/clear', null, { root: true })
            }
          }, { root: true })
        }
      }
      return true
    },
    async updateDeviceData({ rootGetters }, deviceInfo) {
      const userId = rootGetters['UserProfile/userId']
      if (deviceInfo.subscribed) {
        const vueInstance = window.sharedVue.config.globalProperties
        const cardNumber = rootGetters['UserProfile/retailerCardNumber']
        if (typeof cardNumber === 'string' && cardNumber !== '') {
          vueInstance.NativeContainer.capabilities.NotificationService.setCardNumber(cardNumber)
        }
        try {
          await UserDevicesApi.addDevice(deviceInfo, isIOSDevice() ? 'ios' : 'android', userId)
          const body = { deviceInfo: JSON.stringify(deviceInfo) }
          vueInstance.$trackClientLog('createDeviceNotification', body, LOGGING_TYPE.event)
        } catch (error) {
          const body = { deviceInfo: JSON.stringify(deviceInfo), error: JSON.stringify(error) }
          vueInstance.$trackClientLog('createDeviceNotificationError', body, LOGGING_TYPE.event)
          throw error
        }
      }
      return true
    }
  }
}
