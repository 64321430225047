import CartAPI from 'api/CartAPI'
import GiftCardApi from 'api/GiftCardApi'
import InstacartAPI from 'api/instacartApi'
import OrderAdjustmentsAPI from 'api/OrderAdjustmentsAPI'
import CheckoutAPI from 'api/CheckoutAPI'
import orderAPI from 'api/orderAPI'
import { GTM, ORDER_PROVIDER_IDS } from 'utils/constants'

// helper to check for deep nested properties and avoid undefined errors
const safeAccess = (fn, defaultVal) => {
  try {
    return fn()
  } catch (e) {
    return defaultVal
  }
}

const processPromos = (allPromos) => {
  let hasUserPromo = false
  let userPromoCode = null
  const promoCodes = []

  allPromos.forEach((promo) => {
    if (promo.isUserPromo) {
      const defaultText = 'Promo code applied! See Order Information for savings.'
      // overwrite promo display based on value
      promo.displayText = promo.value !== 0 ? promo.displayText : defaultText

      userPromoCode = promo
      hasUserPromo = true
    } else {
      promoCodes.push(promo)
    }
  })

  return {
    hasUserPromo,
    userPromoCode,
    promoCodes
  }
}

const formatGiftCards = (giftCards) => {
  return giftCards.map((card) => {
    return {
      cardId: card.cardNumber,
      cardAmount: card.amount,
    }
  })
}

const initializeOrderParam = (state, rootGetters, rootState) => {
  const { order } = state
  const ebtAccountBalance = rootGetters['CartPaymentInformation/ebtAccountBalance']
  const ebtAmountRefunded = rootState.EbtPayment.amountRefunded
  const billingAddress = rootGetters['ApplePaymentStore/getBillingAddress']
  const phoneNumber = rootGetters['ApplePaymentStore/getBillingPhoneNumber']
  const applePayToken = rootGetters['ApplePaymentStore/getApplePayToken']
  const paypalPaymentToken = rootGetters['PayPalPaymentStore/getPayPalToken']
  const payPalAuthorizedResponse = rootGetters['PayPalPaymentStore/getPayPalAuthorizedResponse']
  const payPalDeviceData = rootGetters['PayPalPaymentStore/getPayPalDeviceData']
  const type = state.isUnattended ? 'unattended' : 'attended'
  const instructions = type === 'attended' ? rootGetters['UserProfile/defaultAttendedInstructions']
    : rootGetters['UserProfile/defaultUnattendedInstructions']
  let capsId
  if (!_.isEmpty(applePayToken)) {
    capsId = -1
  } else if (!_.isEmpty(paypalPaymentToken)) {
    capsId = -2
  } else {
    capsId = state.selectedPaymentCapsId
  }
  const orderParams = {
    alcoholCheck: order.alcoholCheck,
    value: instructions,
    unattendedAgreement: order.unattendedAgreementAccepted,
    vendorCheck: order.vendorCheck,
    capsId,
    type
  }

  if (orderParams.capsId === -1) {
    orderParams.applePay = {
      billingContact: {
        ...billingAddress,
        phoneNumber
      },
      paymentToken: applePayToken
    }
  }
  if (orderParams.capsId === -2) {
    // eslint-disable-next-line max-len
    const fName = payPalAuthorizedResponse?.details?.payerInfo?.firstName || payPalAuthorizedResponse?.details?.firstName
    const lName = payPalAuthorizedResponse?.details?.payerInfo?.lastName || payPalAuthorizedResponse?.details?.lastName
    const phoneNo = payPalAuthorizedResponse.details?.payerInfo?.phone || payPalAuthorizedResponse?.details?.phone
    orderParams.digitalWallet = {
      walletType: 'PAYPAL',
      payPal: {
        billingContact: {
          addressLines: [payPalAuthorizedResponse.details.billingAddress.line1],
          administrativeArea: payPalAuthorizedResponse.details.billingAddress.state,
          countryCode: payPalAuthorizedResponse.details.billingAddress.countryCode,
          familyName: fName,
          givenName: lName,
          locality: payPalAuthorizedResponse.details.billingAddress.city,
          phoneticFamilyName: lName,
          phoneticGivenName: fName,
          postalCode: payPalAuthorizedResponse.details.billingAddress.postalCode,
          phoneNumber: phoneNo,
          subAdministrativeArea: '',
          subLocality: ''
        },
        paymentToken: {
          nonce: payPalAuthorizedResponse.nonce,
          deviceData: payPalDeviceData,
          version: ''
        }
      },
    }
    const vueInstance = window.sharedVue.config.globalProperties
    const paypalParams = JSON.stringify(orderParams.digitalWallet)
    const paypalParamsParse = JSON.parse(paypalParams)
    vueInstance.$trackClientLog('Paypal_Checkout_Payload', paypalParamsParse)
  }
  if (ebtAccountBalance >= 0) {
    orderParams.ebtAccountBalance = ebtAccountBalance
  }
  if (ebtAmountRefunded >= 0) {
    orderParams.ebtAmountRefunded = ebtAmountRefunded
  }
  return orderParams
}

const setupSlotRedirect = () => {
  const store = window.sharedVue.config.globalProperties.$store
  store.commit('Alert/clear', null)
  store.commit('Modals/clear', null) // needed till checkout modal goes to vue
  store.commit('ServiceSelection/setCurrentView', 'T')
  store.commit('Modals/setActiveModal', { fileName: 'PdlServiceSelectionModal' })
}

const clearAlertRedirectToPayment = () => {
  const vueInstance = window.sharedVue.config.globalProperties
  vueInstance.$store.commit('Alert/clear', null)
  vueInstance.$store.commit('Modals/clearActiveModal')
  vueInstance.$router.push('/account/payment')
}

const openMyAccountEditCardModal = paymentMethod => async () => {
  const vueInstance = window.sharedVue.config.globalProperties
  vueInstance.$store.commit('CreditCard/setMode', 'update')
  vueInstance.$store.dispatch('CreditCard/clearCreditCard')
  vueInstance.$store.dispatch('CreditCard/setCreditCard', paymentMethod)
  vueInstance.$store.commit('CreditCard/setRedirectFrom', 'summary')
  vueInstance.$store.commit('Modals/clearActiveModal')
  vueInstance.$store.commit('Alert/clear')
  vueInstance.$router.push('/account/payment/credit')
}

const setCheckoutAlert = (commit, { msg, callback }) => {
  commit('Alert/setAlert', {
    type: 'error',
    header: 'Checkout Error',
    body: msg,
    primary: {
      text: 'Continue',
      ...(callback && { callback })
    }
  }, { root: true })
}

// requires 'requiredCardAction' to be capital case to properly assemble messaging
const setCreditDebitAlert = (commit, requiredCardAction = 'Add') => {
  commit('Alert/setAlert', ({
    header: 'Credit/Debit Card Required',
    body: `You must ${requiredCardAction.toLowerCase()} a credit/debit card to place your order.`,
    primary: {
      text: `${requiredCardAction} Credit/Debit Card`,
      callback: clearAlertRedirectToPayment
    }
  }), { root: true })
}

const parseErrorMessages = (err) => {
  try {
    const parsedMessage = JSON.parse(err.msg)
    const { errorMessages } = parsedMessage

    // eslint-disable-next-line prefer-destructuring
    return errorMessages[0]
  } catch (e) {
    return {}
  }
}

const instacartMessage = (orderStatus, phoneNumber) => {
  let message = [
    'Sorry, we had trouble sending your order to Instacart.',
    `To complete checkout, please call us at ${phoneNumber}`
  ].join(' ')

  const orderID = orderStatus?.currentOrder?.orderId

  if (orderID) {
    message = `${message} and mention order ${orderID}`
  }

  return message
}

const createQueryString = (orderParams) => {
  const queryStringParams = []

  Object.keys(orderParams).forEach((param) => {
    if (param !== 'applePay' && param !== 'digitalWallet') {
      if (param === 'value') {
        queryStringParams.push(`${param}=${encodeURI(orderParams[param])}`)
      } else {
        queryStringParams.push(`${param}=${orderParams[param]}`)
      }
    }
  })
  return queryStringParams.join('&')
}

const initialState = () => ({
  custType: 'C',
  giftCards: [],
  hasAlcohol: false,
  instructions: null,
  isTipAllowed: false,
  isUnattended: false,
  unattendedAgreementAccepted: false,
  messageKeys: [],
  orderStatus: null,
  paymentMethod: null,
  paymentMethods: [],
  capsIdAttachedToOrder: undefined,
  paymentMethodLookupComplete: false,
  paymentMethodChangeError: null,
  poNum: null,
  promos: [],
  savings: {
    buyMoreSaveMoreSavingsItems: [],
    buyMoreSaveMoreSavingsTotal: 0,
    couponSavings: 0,
    coupons: [],
    delivDiscount: 0,
    hasPersonalCoupons: false,
    inStoreCouponSavings: 0,
    inStoreCoupons: [],
    pepDiscount: 0,
    promotionSavings: [],
    promotionSavingsTotal: 0,
    totalSavingsAmt: 0,
    weeklySavingsItems: [],
    weeklySavingsTotal: 0
  },
  tip: null,
  vendorMessages: [],
  lastOrderStatus: null,
  paymentChangeInProgress: false,
  // Ebt Atrributes
  applySnapchecked: false,
  ebtAccountBalance: 0,
  // Promo Code Attributes
  promoCodeCallInProgress: false,
  promoCodeApplied: false,
  promoErrorCode: null,
  userPromoCode: null,
  promoSectionReady: false,
  // Gift Card Attributes
  giftCardEntry: '', // < value entered by user
  giftCardCallInProgress: false,
  giftCardApplyInProgress: false,
  displayGiftCardManagement: false,
  giftCardErrorCode: null,
  giftCardServerErrorMessage: null,
  giftCardClientErrorCode: null,
  giftCardData: {
    cardAmount: null,
    cardID: null,
    orderAmount: null
  },
  hideServiceFee: false,
  serviceLocationID: null,
  order: {
    promoCode: '',
    giftCardNumber: '',
    giftCardAmount: '',
    poNum: '',
    paymentMethod: '',
    alcoholCheck: false,
    vendorCheck: false,
    instructions: '',
    unattendedAgreementAccepted: false,
    status: '',
    savings: 0,
    hasUserPromo: false
  },
  orderDataReady: false,
  // true values here display errors to user
  missingConfirmations: {
    alcohol: false,
    vendor: false,
    unattendedInstructions: false,
    unattendedAgreement: false
  },
  checkoutProcessing: false,
  selectedPaymentCapsId: null,
  preferredAccountData: {},
  checkoutPaymentMethod: '',
  invalidAddressOrZip: {
    isInvalidAddressOrZipcode: false
  },
  instacartPreventCheckout: false,
  showError: false,
  estimatedTotal: 0,
  mktpCheckoutError: false,
  mktpRetryLoading: false,
  applePayPerformCheckout: false,
  applePayBillingAddress: {},
  applePayBillingPhoneNumber: '',
  applePayCardUsed: '',
  checkoutResponse: {},
  payPalPerformCheckout: false
})

const getters = {
  custType: state => state.custType,
  giftCards: state => state.giftCards,
  hasAlcohol: state => state.hasAlcohol,
  instructions: state => state.instructions,
  isTipAllowed: state => state.isTipAllowed,
  isUnattended: state => state.isUnattended,
  unattendedAgreementAccepted: state => state.unattendedAgreementAccepted,
  messageKeys: state => state.messageKeys,
  orderStatus: state => state.orderStatus,
  paymentMethod: state => state.paymentMethod, // convert to grab this from PaymentMethod
  paymentMethods: state => state.paymentMethods, // convert to grab this from PaymentMethod
  capsIdAttachedToOrder: state => state.capsIdAttachedToOrder,
  paymentMethodLookupComplete: state => state.paymentMethodLookupComplete,
  paymentMethodChangeError: state => state.paymentMethodChangeError,
  primaryCard: (state) => {
    // eslint-disable-next-line arrow-body-style
    const primaryCardResults = state.paymentMethods.filter((payment) => {
      return payment.paymentMethod === 'CARD' && payment.primaryAccount
    })
    return primaryCardResults[0]
  },
  selectedCard: (state) => {
    const selectedCardResults = state.paymentMethods.filter((payment) => {
      return payment.paymentMethod === 'CARD' && payment.capsId === state.selectedPaymentCapsId
    })
    return selectedCardResults[0]
  },
  poNum: state => state.order.poNum,
  promos: state => state.promos,
  savings: state => state.savings,
  tip: state => state.tip,
  lastOrderStatus: state => state.lastOrderStatus,
  promoCodeApplied: state => state.promoCodeApplied,
  promoErrorCode: state => state.promoErrorCode,
  userPromoCode: state => state.userPromoCode,
  promoSectionReady: state => state.promoSectionReady,
  getAlcoholCheck: state => state.order.alcoholCheck,
  giftCardEntry: state => state.giftCardEntry,
  giftCardCallInProgress: state => state.giftCardCallInProgress,
  giftCardApplyInProgress: state => state.giftCardApplyInProgress,
  displayGiftCardManagement: state => state.displayGiftCardManagement,
  giftCardErrorCode: state => state.giftCardErrorCode,
  giftCardServerErrorMessage: state => state.giftCardServerErrorMessage,
  giftCardClientErrorCode: state => state.giftCardClientErrorCode,
  giftCardData: state => state.giftCardData,
  // order data
  order: state => state.order,
  orderDataReady: state => state.orderDataReady,
  // missing confirmations
  missingAlcoholConfirmation: state => state.missingConfirmations.alcohol,
  missingVendorConfirmation: state => state.missingConfirmations.vendor,
  missingUnattendedAgreement: state => state.missingConfirmations.unattendedAgreement,
  missingUnattendedInstructions: state => state.missingConfirmations.unattendedInstructions,
  // checkout process data
  checkoutProcessing: state => state.checkoutProcessing,
  serviceLocationID: state => state.serviceLocationID,
  selectedPaymentCapsId: state => state.selectedPaymentCapsId,
  preferredAccountData: state => state.preferredAccountData,
  checkoutPaymentMethod: state => state.checkoutPaymentMethod,
  isInvalidAddressOrZipcode: state => state.invalidAddressOrZip.isInvalidAddressOrZipcode,
  showError: state => state.showError,
  checkoutAddressPosition: state => state.checkoutAddressPosition, // A/B test for order summary hierarchy
  instacartPreventCheckout: state => state.instacartPreventCheckout,
  // EBT getters
  applySnapchecked: state => state.applySnapchecked,
  ebtAccountBalance: state => state.ebtAccountBalance,
  getEstimatedTotal: state => state.estimatedTotal,
  flowType: state => state.flowType,
  canViewOrderSummaryPage: state => state.canViewOrderSummaryPage,
  canViewOrderConfirmationPage: state => state.canViewOrderConfirmationPage,
  applePayPerformCheckout: state => state.applePayPerformCheckout,
  applePayBillingAddress: state => state.applePayBillingAddress,
  applePayBillingPhoneNumber: state => state.applePayBillingPhoneNumber,
  applePayCardUsed: state => state.applePayCardUsed,
  redeemCode: (state) => {
    return state.userPromoCode?.reedemCode || state.userPromoCode?.redeemCode
  },
  checkoutResponse: state => state.checkoutResponse,
  payPalPerformCheckout: state => state.payPalPerformCheckout
}

const mutations = {
  setApplePayBillingAddress(state, value) {
    state.applePayBillingAddress = value
  },
  setApplePayBillingPhoneNumber(state, value) {
    state.applePayBillingPhoneNumber = value
  },
  setCustType(state, value) {
    state.custType = value
  },
  setGiftCards(state, value) {
    state.giftCards = value
  },
  setHasAlcohol(state, value) {
    state.hasAlcohol = value
  },
  setInstructions(state, value) {
    state.instructions = value
  },
  setIsTipAllowed(state, value) {
    state.isTipAllowed = value
  },
  setIsUnattended(state, value) {
    state.isUnattended = value
  },
  setUnattendedAgreementAccepted(state, value) {
    state.unattendedAgreementAccepted = value
  },
  setMessageKeys(state, value) {
    state.messageKeys = value
  },
  setOrderStatus(state, value) {
    state.orderStatus = value
  },
  setPaymentMethod(state, value) {
    state.paymentMethod = value
  },
  setPaymentMethods(state, value) {
    state.paymentMethods = value
  },
  setCapsIdAttachedToOrder(state, value) {
    state.capsIdAttachedToOrder = value
  },
  setPaymentMethodLookupComplete(state, value) {
    state.paymentMethodLookupComplete = value
  },
  setPoNum(state, value) {
    state.poNum = value
  },
  setPromos(state, value) {
    state.promos = value
  },
  setSavings(state, value) {
    state.savings = value
  },
  setTip(state, value) {
    state.tip = value
  },
  setVendorMessages(state, value) {
    state.vendorMessages = value
  },
  setLastOrderStatus(state, value) {
    state.lastOrderStatus = value
  },
  setPaymentChangeInProgress(state, value) {
    state.paymentChangeInProgress = value
  },
  // Ebt setters
  setApplySnapchecked(state, value) {
    state.applySnapchecked = value
  },
  setEbtAccountBalance(state, value) {
    state.ebtAccountBalance = value
  },
  // Promo code setters
  setPromoCodeCallInProgress(state, value) {
    state.promoCodeCallInProgress = value
  },
  setPromoCodeApplied(state, value) {
    state.promoCodeApplied = value
  },
  setPromoErrorCode(state, value) {
    state.promoErrorCode = value
  },
  setUserPromoCode(state, payload) {
    // overwrite display message for "zero" value promo
    if (payload?.value === 0) {
      payload.displayText = 'Promo code applied! See Order Information for savings.'
    }
    state.userPromoCode = payload
  },
  setPromoSectionReady(state, value) {
    state.promoSectionReady = value
  },
  // Gift card setters
  setGiftCardEntry(state, value) {
    state.giftCardEntry = value
  },
  setGiftCardCallInProgress(state, value) {
    state.giftCardCallInProgress = value
  },
  setGiftCardApplyInProgress(state, value) {
    state.giftCardApplyInProgress = value
  },
  setDisplayGiftCardManagement(state, value) {
    state.displayGiftCardManagement = value
  },
  setGiftCardErrorCode(state, value) {
    state.giftCardErrorCode = value
  },
  setGiftCardClientErrorCode(state, value) {
    state.giftCardClientErrorCode = value
  },
  setGiftCardData(state, value) {
    state.giftCardData = value
  },
  setPaymentMethodChangeError(state, value) {
    state.paymentMethodChangeError = value
  },
  setGiftCardServerErrorMessage(state, value) {
    state.giftCardServerErrorMessage = value
  },
  initializeState(state, value) {
    Object.assign(state, value)
  },
  setHideServiceFee(state, value) {
    state.hideServiceFee = value
  },
  setServiceLocationID(state, value) {
    state.serviceLocationID = value
  },
  // order info
  setOrderAlcoholCheck(state, value) {
    state.order.alcoholCheck = value
  },
  setOrderVendorCheck(state, value) {
    state.order.vendorCheck = value
  },
  setOrderInstructions(state, value) {
    state.order.instructions = value
  },
  setOrderSavings(state, value) {
    state.order.savings = value
  },
  setOrderUnattendedAgreementAccepted(state, value) {
    state.order.unattendedAgreementAccepted = value
  },
  setOrderNestedStatus(state, value) {
    state.order.status = value
  },
  setOrderPoNum(state, value) {
    state.order.poNum = value
  },
  setOrderDataReady(state, value) {
    state.orderDataReady = value
  },
  // missing confirmations
  setAlcoholConfirmationMissing(state, value) {
    state.missingConfirmations.alcohol = value
  },
  setVendorConfirmationMissing(state, value) {
    state.missingConfirmations.vendor = value
  },
  setUnattendedInstructionsMissing(state, value) {
    state.missingConfirmations.unattendedInstructions = value
  },
  setUnattendedAgreementMissing(state, value) {
    state.missingConfirmations.unattendedAgreement = value
  },
  // checkout process info
  setCheckoutProcessing(state, value) {
    state.checkoutProcessing = value
  },
  setSelectedPaymentCapsId(state, payload) {
    state.selectedPaymentCapsId = payload
  },
  setPreferredAccountData(state, payload) {
    state.preferredAccountData = payload
  },
  setCheckoutPaymentMethod(state, payload) {
    state.checkoutPaymentMethod = payload
  },
  setIsInvalidAddressOrZipcode(state, value) {
    state.invalidAddressOrZip.isInvalidAddressOrZipcode = value
  },
  setShowError(state, value) {
    state.showError = value
  },
  setInstacartPreventCheckout(state, value) {
    state.instacartPreventCheckout = value
  },
  setEstimatedTotal(state, value) {
    state.estimatedTotal = value
  },
  setFlowType(state, value) {
    // value is a String that can either be 'modal' or 'page'
    state.flowType = value
  },
  setCanViewOrderSummaryPage(state, value) {
    // value is a Boolean
    state.canViewOrderSummaryPage = value
  },
  setCanViewOrderConfirmationPage(state, value) {
    // value is a Boolean
    state.canViewOrderConfirmationPage = value
  },
  setApplePayPerformCheckout(state, value) {
    state.applePayPerformCheckout = value
  },
  setApplePayCardUsed(state, value) {
    state.applePayCardUsed = value
  },
  setCheckoutResponse(state, value) {
    state.checkoutResponse = value
  },
  setPayPalPerformCheckout(state, value) {
    state.payPalPerformCheckout = value
  },
}

const actions = {
  resetState({ commit, rootGetters }) {
    const paymentCapsId = rootGetters['CartPaymentInformation/selectedPaymentCapsId']
    const ebtAccountBalance = rootGetters['CartPaymentInformation/ebtAccountBalance']
    const estimatedTotal = rootGetters['CartPaymentInformation/getEstimatedTotal']
    commit('initializeState', initialState())
    commit('setSelectedPaymentCapsId', paymentCapsId)
    commit('setEbtAccountBalance', ebtAccountBalance)
    commit('setEstimatedTotal', estimatedTotal)
  },
  async getOrderSummary({
    dispatch, commit, rootState, rootGetters
  }, pymtType = '') {
    try {
      const userInfo = rootGetters['UserProfile/userInfo']
      const deliveryServiceLocation = rootGetters['UserProfile/deliveryServiceLocation']

      const userId = rootGetters['UserProfile/userId']
      const basketId = rootGetters['Cart/getBasketId']
      if (!rootState.PaymentMethod.preferredMethodLoaded) {
        await dispatch('PaymentMethod/getPreferredPaymentMethod', null, { root: true })
      }
      const { preferredPaymentMethod } = rootState.PaymentMethod
      commit('setServiceLocationID', deliveryServiceLocation.serviceLocationId)
      commit('setHideServiceFee', deliveryServiceLocation.serviceType === 'B')

      const { loadedCouponsEnabled } = deliveryServiceLocation
      const retailerCardNumber = userInfo?.retailerCard?.cardNumber

      const cartBasketData = {
        basketId,
        userId
      }
      const showInStoreOffers = loadedCouponsEnabled && !!retailerCardNumber
      const data = {
        params: {
          calculateInStore: showInStoreOffers || false,
          paymentMethod: pymtType || preferredPaymentMethod
        }
      }
      const orderSummary = await orderAPI.getSummary(data, cartBasketData)
      if (orderSummary.status !== 200) {
        throw new Error(orderSummary)
      }
      // keep cart in sync with order summary
      const summaryResponse = orderSummary?.data?.response
      commit('Cart/setSummaryData', summaryResponse, { root: true })
      commit('setOrderSavings', summaryResponse?.totalSavings)
      // Set Snap Amount
      commit('EbtPayment/setSnapAmount', summaryResponse?.ebtAmountApplied, { root: true })
      return true
    } catch (e) {
      commit('Cart/setSummaryData', [], { root: true })
      setCheckoutAlert(commit, { msg: 'There was an error fetching order summary.' })
      return false
    }
  },
  async fetchOrderCheckoutAdjustments({
    state, commit, dispatch, rootGetters, rootState
  }) {
    const cartAlcoholCheckInfo = rootGetters['CartPaymentInformation/getAlcoholCheck']
    const applePayCheckout = rootGetters['CartPaymentInformation/applePayPerformCheckout']
    const payPalCheckout = rootGetters['CartPaymentInformation/payPalPerformCheckout']
    dispatch('resetState')
    commit('setApplePayPerformCheckout', applePayCheckout)
    commit('setPayPalPerformCheckout', payPalCheckout)
    const activeModal = rootState.Modals.activeComponent
    const { order } = state
    const currentPath = window.location.pathname
    const modalName = activeModal[activeModal.length - 1]?.fileName
    if (modalName === 'PdlEditOrderConfirmationModal'
        || modalName === 'PdlAddToExistingCartModal'
        || modalName === 'PdlOrderAddItemsModal'
        || modalName === 'PdlPreCutoffDetailsModal'
        || currentPath === '/order-updated') {
      dispatch('Cart/checkCartHasAlcohol', null, { root: true })
      dispatch('manageAlcoholState', true)
      commit('setOrderAlcoholCheck', cartAlcoholCheckInfo)
    }
    try {
      const basketId = rootGetters['Cart/getBasketId']
      const cartAlcoholCheck = rootGetters['Cart/getCartHasAlcohol']
      const userId = rootGetters['UserProfile/userId']
      const payload = {
        field: 'checkout',
        params: {
          alcoholCheck: order.alcoholCheck || cartAlcoholCheck
        },
        basketId,
        userId
      }
      const checkoutAdjustments = await CheckoutAPI.get(payload)

      if (checkoutAdjustments.status === 500) {
        throw new Error()
      }

      const { code } = checkoutAdjustments.data.response.result

      if (
        checkoutAdjustments.status !== 200
        || code !== 'CHECKOUT_INIT_GET_SUCCESS'
      ) {
        throw new Error(checkoutAdjustments)
      }

      const responseData = checkoutAdjustments.data.response.data
      dispatch('setAdjustments', {
        responseData
      })

      return responseData
    } catch (e) {
      commit('setOrderDataReady', true)
      return setCheckoutAlert(
        commit,
        {
          msg: 'There was an error fetching checkout information.',
          callback: () => {
            commit('setShowError', true)
            commit('Alert/clear', null, { root: true })
          }
        }
      )
    }
  },
  setAdjustments({ commit, rootGetters, dispatch }, { responseData }) {
    const {
      custType,
      giftCards,
      hasAlcohol,
      instructions,
      isTipAllowed,
      isUnattended,
      messageKeys,
      orderStatus,
      poNum,
      promos,
      savings,
      tip,
      unattendedAgreementAccepted,
      vendorMessages
    } = responseData

    // Alert user of credit-card related warnings
    if (typeof messageKeys[0] === 'string') {
      dispatch('displayWarningMessage', messageKeys)
    }

    commit('setCustType', custType)
    commit('setGiftCards', giftCards)
    commit('setHasAlcohol', hasAlcohol)
    commit('setInstructions', instructions)
    // remove covid contactless note from user view of instructions
    let orderInstructions = instructions
    const contactFreeLevel = rootGetters['UserProfile/contactFreeLevel']
    if (contactFreeLevel >= 2) {
      orderInstructions = instructions.replace(/CONTACTLESS|CONTACTLESS /g, '').trim()
    }
    commit('setOrderInstructions', orderInstructions)
    commit('setOrderUnattendedAgreementAccepted', unattendedAgreementAccepted)
    commit('setOrderNestedStatus', orderStatus)
    commit('setOrderPoNum', poNum)
    commit('setIsTipAllowed', isTipAllowed)
    commit('setIsUnattended', isUnattended)
    commit('setUnattendedAgreementAccepted', unattendedAgreementAccepted)
    commit('setMessageKeys', messageKeys)
    commit('setOrderStatus', orderStatus)
    commit('EbtPayment/setEbtCertified', responseData?.ebtCertified, { root: true })
    commit('EbtPayment/setAlreadyUsedEbtStatus', responseData?.alreadyUsedEBT, { root: true })
    commit('setCapsIdAttachedToOrder', responseData?.lastCapsIdUsed)
    commit('setPreferredAccountData', responseData?.preferredAccountData || {})
    commit('setPaymentMethod', responseData?.preferredAccountData?.paymentMethod || responseData.paymentMethod)
    commit('setPaymentMethods', responseData.accountData)
    commit('PaymentMethod/setCreditCardOptions',
      responseData.accountData.filter(payment => payment.paymentMethod === 'CARD'), { root: true })
    commit('PaymentMethod/setBankAccount',
      responseData.accountData.filter(payment => payment.paymentMethod === 'PEP')[0], { root: true })
    commit('PaymentMethod/setEbtAccount',
      responseData.accountData.filter(payment => payment.paymentMethod === 'EBT')[0], { root: true })
    if (rootGetters['CartPaymentInformation/paymentMethods']?.length === 0) {
      setCreditDebitAlert(commit, 'Add')
    }
    if (
      rootGetters['CartPaymentInformation/preferredAccountData']?.creditCardExpired
      && rootGetters['CartPaymentInformation/paymentMethods']?.length === 1
    ) {
      setCreditDebitAlert(commit, 'Update')
    }
    commit('setPaymentMethodLookupComplete', true)
    commit('setPoNum', poNum)
    commit('setPromos', promos)
    const userPromos = promos.filter(promo => promo.isUserPromo)
    if (Array.isArray(userPromos) && !!userPromos.length && userPromos[0].isUserPromo) {
      commit('setPromoCodeApplied', true)
      commit('setUserPromoCode', userPromos[0])
    }
    commit('setPromoSectionReady', true)
    commit('setSavings', savings)
    commit('setTip', tip)
    commit('setVendorMessages', vendorMessages)
    commit('setOrderDataReady', true)
  },
  displayWarningMessage({ commit }, messageKeys) {
    let checkoutWarningMessage = ''

    messageKeys.forEach((code) => {
      if (code === 'CC_EXPIRES_7_DAYS' || code === 'CHECKOUT_CUST_CARD_EXPIRED_7DAYS') {
        checkoutWarningMessage = 'Your credit card will be expiring in less than a week.'
      }
      if (code === 'CC_EXPIRES_THIS_MONTH') {
        checkoutWarningMessage = 'Your Credit Card expires this month.'
      }
    })
    commit('Alert/setAlert', {
      icon: '',
      header: 'Account Warnings.',
      body: checkoutWarningMessage,
      type: 'warning',
      primary: {
        text: 'Continue'
      }
    }, { root: true })
  },
  async getAppliedPromoCodeData({ commit, rootGetters }) {
    const basketId = rootGetters['Cart/getBasketId']
    const userId = rootGetters['UserProfile/userId']

    try {
      const payload = {
        field: 'promocode',
        params: {},
        basketId,
        userId
      }
      const appliedPromoCodes = await OrderAdjustmentsAPI.getAdjustments(payload)
      if (
        appliedPromoCodes.status !== 200
        || appliedPromoCodes.data.response.result.code !== 'PROMO_CODE_GET_SUCCESS'
      ) {
        const responseCode = safeAccess(() => appliedPromoCodes.data.response.code, 'PROMO_CODE_UNKNOWN_ERROR')
        throw new Error(responseCode)
      }

      const processedPromos = processPromos(appliedPromoCodes.data.response.promos)

      commit('setPromoCodeApplied', processedPromos.hasUserPromo)
      commit('setUserPromoCode', processedPromos.userPromoCode)
      commit('setPromos', processedPromos.promoCodes)
      commit('setPromoErrorCode', null) // clear any previous errors
      return appliedPromoCodes
    } catch (e) {
      commit('setPromoErrorCode', e.message)
      return e
    }
  },
  async applyPromoCode({ commit, dispatch, rootGetters }, promoCode) {
    try {
      commit('setPromoCodeCallInProgress', true)
      const basketId = rootGetters['Cart/getBasketId']
      const userId = rootGetters['UserProfile/userId']
      const payload = {
        field: 'promocode',
        params: { value: promoCode },
        queryString: `value=${promoCode}`,
        basketId,
        userId
      }
      let promoCodeEffect
      promoCodeEffect = await OrderAdjustmentsAPI.putAdjustments(payload)
      if (promoCodeEffect?.response) {
        promoCodeEffect = promoCodeEffect.response
      }
      commit('setPromoCodeCallInProgress', false)

      if (promoCodeEffect.status === 500) {
        throw new Error('PROMO_CODE_UNKNOWN_ERROR')
      }

      if (promoCodeEffect.status !== 200 || promoCodeEffect.data.response.code !== 'PROMO_CODE_UPDATED') {
        const responseCode = safeAccess(() => promoCodeEffect.data.response.code, 'PROMO_CODE_UNKNOWN_ERROR')
        throw new Error(responseCode)
      }

      commit('setPromoErrorCode', null) // clear any previous errors
      dispatch('getAppliedPromoCodeData')
      return true
    } catch (e) {
      commit('setPromoCodeApplied', false)
      commit('setPromoErrorCode', e.message)

      // throw site alert on unknown error
      if (e.message === 'PROMO_CODE_UNKNOWN_ERROR') {
        setCheckoutAlert(commit, { msg: 'There was an error updating the promo code.' })
      }
      return false
    }
  },
  async checkGiftCardExists({ state, commit, rootGetters }) {
    try {
      commit('setDisplayGiftCardManagement', false)
      commit('setGiftCardCallInProgress', true)
      const userId = rootGetters['UserProfile/userId']
      const defaultErrorMsg = 'Something went wrong. Please try again'
      let giftCard

      const giftCardResult = await GiftCardApi.getCardBalance({
        userId,
        giftCardNumber: state.giftCardEntry,
      })

      commit('setGiftCardCallInProgress', false)

      // clear any prior error data
      commit('setGiftCardErrorCode', null)
      commit('setGiftCardServerErrorMessage', null)

      if (giftCardResult.status === 200) {
        giftCard = {
          cardId: giftCardResult?.data?.cardNumber,
          cardAmount: giftCardResult?.data?.balance
        }
      } else if (giftCardResult?.response?.data?.status === 400) {
        throw new Error(giftCardResult?.response?.data?.code)
      } else {
        throw new Error({ message: defaultErrorMsg })
      }

      // set data from call
      commit('setGiftCardData', giftCard)
      commit('setDisplayGiftCardManagement', true)
      return giftCardResult
    } catch (e) {
      commit('setGiftCardErrorCode', e.message)
      return e.message
    }
  },
  async applyGiftCardToOrder({
    state, commit, dispatch, rootGetters
  }, amount) {
    try {
      commit('setGiftCardApplyInProgress', true)
      const basketId = rootGetters['Cart/getBasketId']
      const userId = rootGetters['UserProfile/userId']
      const params = {
        giftCardNumber: state.giftCardData.cardId,
        value: parseFloat(amount)
      }

      const giftCardApplyResult = await GiftCardApi.redeem({
        basketId,
        params,
        userId,
      })
      commit('setGiftCardApplyInProgress', false)

      if (giftCardApplyResult.status !== 200) {
        // Define status code mappings to error messages
        const statusCodes = giftCardApplyResult?.response?.data?.code
        if (giftCardApplyResult.response && statusCodes) {
          throw new Error(giftCardApplyResult?.response?.data?.code)
        } else {
          throw new Error('DEFAULT')
        }
      }
      dispatch('getGiftCardsApplied')
      commit('setGiftCardEntry', '') // clear gift card
      return true
    } catch (e) {
      commit('setGiftCardClientErrorCode', e.message)
      return false
    }
  },
  async getGiftCardsApplied({ commit, rootGetters }) {
    const basketId = rootGetters['Cart/getBasketId']
    const userId = rootGetters['UserProfile/userId']

    try {
      const giftCardsApplied = await GiftCardApi.getAppliedCards({
        basketId,
        userId
      })

      if (giftCardsApplied.status === 500) {
        return setCheckoutAlert(commit, { msg: 'There was an error finding gift cards applied to your order.' })
      }

      if (giftCardsApplied.status !== 200) {
        throw new Error(giftCardsApplied)
      }

      // insert newly applied gift-card to state
      const giftCardsData = formatGiftCards(giftCardsApplied.data.giftcards)
      return commit('setGiftCards', giftCardsData)
    } catch (e) {
      return e
    }
  },
  async persistOrderInstructions({ commit, rootGetters }, instructionsPayload) {
    const vueInstance = window.sharedVue.config.globalProperties
    const basketId = rootGetters['Cart/getBasketId']
    const userId = rootGetters['UserProfile/userId']
    const { type, instructions } = instructionsPayload

    try {
      const payload = {
        basketId,
        userId,
        field: 'field',
        params: {
          field: 'instructions',
          value: instructions,
          type
        },
      }
      let putResult
      putResult = await OrderAdjustmentsAPI.putAdjustments(payload)
      if (putResult.response) {
        putResult = putResult.response
      }
      // The order adjustments v5 api returns a 201 for success
      // and the new order adjustments v6 api returns a 200 for success
      if (putResult.status === 200 || putResult.status === 201) {
        commit('setInstructions', instructions)
      }
      if (putResult.status !== 200 && putResult.status !== 201) {
        throw new Error(putResult)
      }
      // update Profile state when get a 200 or success response
      if (type === 'attended') {
        await vueInstance.$store.dispatch('UserProfile/updateDeliveryInstructionsData', instructions)
      } else {
        await vueInstance.$store.dispatch('UserProfile/updateUnattendedDeliveryInstructionsData', instructions)
      }
      return putResult
    } catch (e) {
      setCheckoutAlert(commit, { msg: 'There was an error updating the instructions information.' })
      return e
    }
  },
  // We will revisit and remove this
  // eslint-disable-next-line
  async performCheckout({
    state, commit, dispatch, rootGetters, rootState
  }) {
    const getVarByName = rootGetters['SiteConfig/varByName']
    const userId = rootGetters['UserProfile/userId']
    const checkoutFollowup = {
      displayAlert: false,
      alertHeader: 'There was a problem processing your checkout.',
      alertMessages: [],
      primaryText: 'Continue',
      bounceToSlot: false
    }
    const isInstacartOrder = rootGetters['Slots/orderProviderId'] === ORDER_PROVIDER_IDS.INSTACART

    try {
      if (state.checkoutProcessing) {
        return false
      }
      commit('setCheckoutProcessing', true)

      // Clear any gift card error codes
      commit('setGiftCardErrorCode', null)
      commit('setGiftCardServerErrorMessage', null)

      const instacartCreateUser = getVarByName('feature_instacart_separate_user_create')
      const instacartCreateUserCombined = getVarByName('feature_instacart_combined_user_create')

      if (isInstacartOrder && instacartCreateUser && instacartCreateUserCombined) {
        await dispatch('createInstaUserCombined')
      }

      if (isInstacartOrder && instacartCreateUser && !instacartCreateUserCombined) {
        await dispatch('createInstaUser')
      }

      if (rootGetters['CartPaymentInformation/instacartPreventCheckout']) {
        throw new Error('Instacart could not verify address')
      }

      const { order } = state
      commit('setLastOrderStatus', order.status)

      const orderParams = initializeOrderParam(state, rootGetters, rootState)
      if (state.custType === 'M' && state.orderStatus === 'SUBMITTED' && state.paymentMethod === 'INV') {
        orderParams.capsId = null
      }
      dispatch('CutOff/activateCutoffAlertFlag', false, { root: true })
      const basketId = rootGetters['Cart/getBasketId']
      const queryString = createQueryString(orderParams)
      const payload = {
        params: orderParams,
        queryString,
        basketId,
        userId
      }
      const checkoutResult = await CheckoutAPI.put(payload)
      commit('setCheckoutResponse', checkoutResult)
      const appleCapsId = orderParams.capsId === -1
      if (appleCapsId) {
        dispatch('applePayHandleResponse', { checkoutResult, checkoutFollowup })
        // eslint-disable-next-line
        const paymentMethodMaskedAccountNumber = checkoutResult.data?.response?.paymentServiceResponse?.maskedAccountNumber
        const paymentMethodCreditCardIssuer = checkoutResult.data?.response?.paymentServiceResponse?.creditCardIssuer
        commit('setApplePayCardUsed', `${paymentMethodCreditCardIssuer} ${paymentMethodMaskedAccountNumber}`)
      } else {
        dispatch('handleResponse', { checkoutResult, checkoutFollowup })
      }
      commit('setCheckoutProcessing', false)
      if (rootState.PickupLockers.lockersEnabledInStore) {
        window.sharedVue.config.globalProperties.$trackGtmEvent(GTM.lockerOrderPlaced)
      }
      return checkoutFollowup
    } catch (e) {
      commit('setCheckoutProcessing', false)

      checkoutFollowup.displayAlert = true
      checkoutFollowup.alertHeader = 'Checkout Error'
      checkoutFollowup.alertMessages.push('There was an error executing the checkout.')

      // overwrite generic error for Instacart orders
      if (isInstacartOrder) {
        const customerSupportNumber = rootGetters['SiteConfig/brandContactNumber']
        const orderStatus = rootGetters['Order/orderStatus']
        checkoutFollowup.alertMessages = [`${instacartMessage(orderStatus, customerSupportNumber)}.`]
      }

      return checkoutFollowup
    }
  },
  async createInstaUserCombined(
    {
      commit, dispatch, rootGetters, rootState
    }
  ) {
    try {
      const opcoName = rootGetters['SiteConfig/opcoId']
      const deliveryAddress = rootGetters['UserProfile/deliveryAddress']
      const addresses = rootGetters['UserProfile/addresses']
      const userId = rootGetters['UserProfile/userId']

      const payload = {
        userId,
        opcoName,
        addressLine1: deliveryAddress?.addressLine1 || null,
        addressLine2: deliveryAddress?.addressLine2 || null,
        postalCode: addresses['1'].zip,
        checkout: true
      }
      await dispatch('InstacartOrders/validateAddressAndCreateUser', payload, { root: true })

      const instacartApiErrors = rootState.InstacartOrders.validAddressAndCreateUserErrorType
      const { invalidResponse, serverError } = instacartApiErrors

      if (invalidResponse || serverError) {
        throw new Error('Instacart could not verify address')
      } else {
        commit('setInstacartPreventCheckout', false)
      }
    } catch (e) {
      commit('setInstacartPreventCheckout', true)
      return { displayAlert: true }
    }
    return undefined
  },

  async createInstaUser({ rootGetters }) {
    try {
      const userId = rootGetters['UserProfile/userId']
      const orderStatus = rootGetters['Order/orderStatus']
      const orderSubmitted = orderStatus.currentOrder && orderStatus.currentOrder.status === 'SUBMITTED'
      if (!orderSubmitted) {
        const opcoName = rootGetters['UserProfile/opcoName']
        const firstName = rootGetters['UserProfile/displayFirstName']
        const createUserRequest = await InstacartAPI.createUser({
          userId: userId.toString(),
          firstName,
          opcoName
        })
        if (createUserRequest.status !== 200) {
          throw new Error('invalid status returned from Instacart user create.')
        }
      }
    } catch (e) {
      this.$trackClientLog('instacart_create_user_error', e.message)
    }
  },

  handleResponse({ dispatch }, { checkoutResult, checkoutFollowup }) {
    if (checkoutResult.status >= 500 || checkoutResult?.response?.status >= 500) {
      throw new Error(checkoutResult)
    }
    // better check here is to call: response.code !== "CHECKOUT_SUCCESS"
    if (checkoutResult.status === 400 || checkoutResult?.response?.status === 400) {
      checkoutFollowup.displayAlert = true
      const { errors } = checkoutResult?.response?.data?.response ?? checkoutResult?.response ?? {}
      const responseContainsErrors = !!errors.length
      const multipleErrors = errors.length > 1

      if (responseContainsErrors
        && !multipleErrors
        && checkoutResult?.response?.data?.response?.paymentServiceResponse?.webAccountCode === 'CE') {
        const webMessage = checkoutResult?.response?.data?.response?.paymentServiceResponse?.webMessage
        checkoutFollowup.alertMessages
          .push(webMessage)
      }

      // insert user friendly messaging for any error codes returned
      // move these messages out to FormMessages.js
      errors.forEach(err => dispatch('handleErrors', { err, checkoutFollowup }))

      if (multipleErrors) {
        checkoutFollowup.alertHeader = 'There were problems processing your checkout.'
        checkoutFollowup.primaryText = 'Continue'
        checkoutFollowup.primaryCallback = () => {
          window.sharedVue.config.globalProperties.$store.commit('Alert/clear', null, { root: true })
        }
        // This is just in case one of the errors added the secondary button
        delete checkoutFollowup.secondaryButton
        delete checkoutFollowup.secondaryCallback
      }
    }
  },
  // eslint-disable-next-line
  applePayHandleResponse({ dispatch }, { checkoutResult, checkoutFollowup }) {
    if (checkoutResult.status || checkoutResult?.response?.status) {
      const errorCode = (checkoutResult.status ?? checkoutResult?.response?.status ?? '').toString()
      switch (errorCode) {
        case '400':
          checkoutFollowup.displayAlert = true
          checkoutFollowup.alertHeader = `An error occurred while trying to process your
            request. Try refreshing your browser page and try again.`
          checkoutFollowup.primaryText = 'OK'
          break
        case '401':
          checkoutFollowup.displayAlert = true
          checkoutFollowup.alertHeader = `We are unable to process your request.
          Try signing out of your [brand_company_name] account and signing back in.`
          checkoutFollowup.primaryText = 'OK'
          break
        case '404':
          checkoutFollowup.displayAlert = true
          checkoutFollowup.alertHeader = `We are unable to process your request.
           Please try again in a few minutes.`
          checkoutFollowup.primaryText = 'OK'
          break
        case '500':
        case '502':
          checkoutFollowup.displayAlert = true
          checkoutFollowup.alertHeader = `An unknown error has occurred. Please try again later.`
          checkoutFollowup.primaryText = 'OK'
          break
        default:
          break
      }
      checkoutFollowup.primaryCallback = () => {
        window.sharedVue.config.globalProperties.$store.commit('Alert/clear', null, { root: true })
      }
      // This is just in case one of the errors added the secondary button
      delete checkoutFollowup.secondaryButton
      delete checkoutFollowup.secondaryCallback
    }
  },

  handleErrors({ rootGetters, dispatch }, { err, checkoutFollowup }) {
    const errCode = err?.code
    const errorMessages = rootGetters['FormMessages/messages']('checkout')
    const orderProviderId = rootGetters['Slots/orderProviderId']

    // special error handling for sameday messages from Instacart
    let errMsg = {}
    if (orderProviderId === ORDER_PROVIDER_IDS.INSTACART) {
      errMsg = parseErrorMessages(err)
    }

    if (errCode === 'CHECKOUT_CUST_SLOT_REJECT') {
      dispatch('handleSlotRejectError', { errMsg, checkoutFollowup })
    } else {
      if (errCode === 'CHECKOUT_CUST_PAYMENT_METHOD_NOT_AUTHED') {
        checkoutFollowup.alertMessages.push(err.msg)
      } else {
        checkoutFollowup.alertMessages.push(errorMessages[errCode]?.text || null)
      }
      checkoutFollowup.alertHeader = errorMessages[errCode]?.title || errorMessages.DEFAULT.title
      checkoutFollowup.primaryText = errorMessages[errCode]?.primaryButton || errorMessages.DEFAULT.primaryButton
    }

    if (errCode === 'CHECKOUT_CUST_NO_SLOT') {
      checkoutFollowup.bounceToSlot = true
      checkoutFollowup.primaryCallback = setupSlotRedirect
    }

    if (errCode.includes('CHECKOUT_CUST_CARD') || errCode === 'CHECKOUT_CAPSID_NOT_FOUND') {
      dispatch('handleCardErrors', { errCode, checkoutFollowup })
    }

    dispatch('handleSpecificErrors', { errCode, checkoutFollowup, errorMessages })
  },

  handleCardErrors({ rootGetters }, { errCode, checkoutFollowup }) {
    const errorMessages = rootGetters['FormMessages/messages']('checkout')

    if (errCode === 'CHECKOUT_CUST_CARD_EXPIRED_7DAYS_ON_ALT_CHECKOUT'
      || errCode === 'CHECKOUT_CUST_CARD_EXPIRED_7DAYS'
      || errCode === 'CHECKOUT_CUST_CARD_INVALID_TYPE_ON_ALT_CHECKOUT'
      || errCode === 'CHECKOUT_CUST_CARD_INVALID_TYPE'
      || errCode === 'CHECKOUT_CAPSID_NOT_FOUND'
      || errCode === 'CHECKOUT_CUST_CARD_NO_PAYMENT_METHOD') {
      checkoutFollowup.primaryCallback = clearAlertRedirectToPayment
    }

    const selectedCard = rootGetters['CartPaymentInformation/selectedCard']
    if (selectedCard && (errCode === 'CHECKOUT_CUST_CARD_INVALID'
      || errCode === 'CHECKOUT_CUST_CARD_EXPIRED')) {
      checkoutFollowup.primaryCallback = openMyAccountEditCardModal(selectedCard)
      checkoutFollowup.secondaryText = errorMessages[errCode]?.secondaryButton
      checkoutFollowup.secondaryCallback = () => {
        window.sharedVue.config.globalProperties.$store.commit('Alert/clear', null, { root: true })
      }
    }

    const primaryCard = rootGetters['CartPaymentInformation/primaryCard']
    if (primaryCard && (errCode === 'CHECKOUT_CUST_CARD_INVALID_ON_ALT_CHECKOUT'
      || errCode === 'CHECKOUT_CUST_CARD_EXPIRED_ON_ALT_CHECKOUT')) {
      checkoutFollowup.primaryCallback = openMyAccountEditCardModal(primaryCard)
      checkoutFollowup.secondaryText = errorMessages[errCode]?.secondaryButton
      checkoutFollowup.secondaryCallback = clearAlertRedirectToPayment
    }
  },
  handleSlotRejectError({ commit }, { errMsg, checkoutFollowup }) {
    const { key } = errMsg?.error ?? {}

    // default messaging when error key is not specified for Instacart
    if (!key) throw new Error(key)

    if (key === 'address') {
      checkoutFollowup.alertMessages.push(errMsg?.error ? errMsg.error.message : '')
      commit('setIsInvalidAddressOrZipcode', true)
      window.sharedVue.config.globalProperties.$trackGtmEvent(GTM.samedayAddressMismatch)
    }

    if (key === 'service_option_id') {
      checkoutFollowup.alertHeader = 'Select a New Delivery Time'
      checkoutFollowup.alertMessages.push(
        'We\'re sorry, but the delivery time you selected is no longer available.  Please select a new time.'
      )
      checkoutFollowup.primaryCallback = setupSlotRedirect
      checkoutFollowup.bounceToSlot = true
      window.sharedVue.config.globalProperties.$trackGtmEvent(GTM.samedaySlotUnavailable)
    }
  },
  handleSpecificErrors({ commit }, { errCode, checkoutFollowup }) {
    // additional state mutations for specific error codes
    if (errCode === 'CHECKOUT_CUST_UNATAGREE_NOT_CHECKED' || errCode === 'UNATTENDED_AGREEMENT_ACK_REQUIRED') {
      commit('setUnattendedAgreementMissing', true)
    }
    if (errCode === 'CHECKOUT_CUST_ALCHL_NOT_CHECKED' || errCode === 'ALCOHOL_AGE_CONFIRMATION_REQUIRED') {
      commit('setAlcoholConfirmationMissing', true)
    }
    if (errCode === 'CHECKOUT_CUST_VENDOR_NOT_CHECKED' || errCode === 'EXTERNAL_VENDOR_BILLING_ACK_REQUIRED') {
      commit('setVendorConfirmationMissing', true)
    }
    if (errCode === 'CHECKOUT_CUST_UNATTED_BLANK') {
      commit('setUnattendedInstructionsMissing', true)
    }
    if (errCode === 'ORDER_SLOT_COMMIT_ERROR') {
      checkoutFollowup.alertHeader = 'Select New Time Slot'
      checkoutFollowup.alertMessages.push(
        'Sorry the time slot you selected is no longer available. Please select a new one to complete your order.'
      )
      checkoutFollowup.primaryCallback = setupSlotRedirect
      checkoutFollowup.bounceToSlot = true
    }
  },
  async createNewBasket({
    commit, dispatch, rootGetters
  }) {
    const getVarByName = rootGetters['SiteConfig/varByName']
    const isMultiBasketEnabled = getVarByName('feature_multibasket')
    const userId = rootGetters['UserProfile/userId']
    const deliveryServiceLocation = rootGetters['UserProfile/deliveryServiceLocation']
    const serviceLocationID = deliveryServiceLocation.serviceLocationId
    const payload = {
      userId,
      serviceLocationID
    }

    if (isMultiBasketEnabled) {
      const newlyCreatedBasket = await OrderAdjustmentsAPI.createNewBasket(payload)
      if (newlyCreatedBasket.status === 200) {
        const basketId = newlyCreatedBasket?.data?.basketId
        const primaryBucket = await CartAPI.setPrimaryCart({
          userId,
          basketId,
        })
        if (primaryBucket.status === 200) {
          commit('Cart/setBasketId', basketId, { root: true })
          commit('Cart/setWorkingOrderBasketId', basketId, { root: true })
        }
      }
      await dispatch('Cart/queryCart', '', { root: true })
    }
  },
  async getPoNum({ commit, rootGetters, rootState }) {
    const basketId = rootGetters['Cart/getBasketId']
    const userId = rootGetters['UserProfile/userId']
    const payload = {
      params: {},
      basketId,
      userId
    }
    try {
      let getPoNumResult
      getPoNumResult = await orderAPI.getPurchaseOrder(payload)

      if (getPoNumResult.response) {
        getPoNumResult = getPoNumResult.response
      }

      if (getPoNumResult?.status !== 200 && getPoNumResult?.status !== 204) {
        throw new Error(getPoNumResult)
      }

      const poNumValue = getPoNumResult?.data?.purchaseOrderNumber
      if (poNumValue) {
        commit('setOrderPoNum', poNumValue)
        return poNumValue
      }
      return undefined
    } catch (e) {
      const poErrorMap = rootState?.FormMessages?.fields?.purchaseOrder
      if (poErrorMap[e.message]) {
        setCheckoutAlert(commit, { msg: poErrorMap[e.message] })
      } else { setCheckoutAlert(commit, { msg: poErrorMap.RESPONSE_NOT_FOUND }) }
      return e
    }
  },
  async putPoNum({
    commit, dispatch, rootGetters, rootState
  }, poNum) {
    const basketId = rootGetters['Cart/getBasketId']
    const userId = rootGetters['UserProfile/userId']
    const payload = {
      params: {
        purchaseOrderNumber: poNum
      },
      basketId,
      userId
    }
    try {
      let poNumResult
      poNumResult = await orderAPI.updatePurchaseOrder(payload)
      if (poNumResult.response) {
        poNumResult = poNumResult.response
      }
      const isSuccess = (poNumResult?.status === 201 || poNumResult?.status === 200)
      if (!isSuccess) {
        throw new Error(poNumResult)
      }
      dispatch('getPoNum')
      return poNumResult
    } catch (e) {
      const responseCode = e?.data?.responseCode
      const poErrorMap = rootState.FormMessages.fields.purchaseOrder
      if (poErrorMap[responseCode]) {
        setCheckoutAlert(commit, { msg: poErrorMap[responseCode] })
      } else { setCheckoutAlert(commit, { msg: poErrorMap.RESPONSE_NOT_FOUND }) }
      return e
    }
  },
  async deletePoNum({
    commit, dispatch, rootGetters, rootState
  }) {
    const basketId = rootGetters['Cart/getBasketId']
    const userId = rootGetters['UserProfile/userId']
    const payload = {
      params: {},
      basketId,
      userId
    }
    try {
      let poNumResult
      poNumResult = await orderAPI.deletePurchaseOrder(payload)

      if (poNumResult.response) {
        poNumResult = poNumResult.response
      }
      const isSuccess = poNumResult?.status === 204 || poNumResult?.status === 200
      if (!isSuccess) {
        throw new Error(poNumResult)
      }
      dispatch('getPoNum')
      return poNumResult
    } catch (e) {
      const responseCode = e?.data?.responseCode
      const poErrorMap = rootState.FormMessages.fields.purchaseOrder
      if (poErrorMap[responseCode]) {
        setCheckoutAlert(commit, { msg: poErrorMap[responseCode] })
      } else { setCheckoutAlert(commit, { msg: poErrorMap.RESPONSE_NOT_FOUND }) }
      return e
    }
  },
  manageAlcoholState({ commit }, setting) {
    commit('setOrderAlcoholCheck', setting)
    commit('setAlcoholConfirmationMissing', !setting)
  },
  manageVendorState({ commit }, setting) {
    commit('setOrderVendorCheck', setting)
    commit('setVendorConfirmationMissing', !setting)
  },
}

export default {
  namespaced: true,
  state: {
    ...initialState(),
    // flowType can either be 'modal' or 'page'
    flowType: 'modal',
    canViewOrderSummaryPage: false,
    canViewOrderConfirmationPage: false
  },
  getters,
  mutations,
  actions
}
