<template>
  <svg
    class="scale-x-100"
    viewBox="0 0 100 100"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g class="fill-none stroke-none">
      <circle
        :class="['stroke-[0.7rem]', {
          'stroke-gray-900 opacity-30' : !isHeroBannerProductAndNonDesktopDesign,
          'stroke-gray-50 opacity-30' : isHeroBannerProductAndNonDesktopDesign
        }
        ]"
        cx="50"
        cy="50"
        r="47"
      />
      <path
        id="base-timer-path-remaining"
        stroke-dasharray="283, 283"
        :class="['base-timer__path-remaining stroke-[0.8rem]', {
          'stroke-black' : !isHeroBannerProductAndNonDesktopDesign,
          'stroke-white' : isHeroBannerProductAndNonDesktopDesign
        }]"
        :style="{
          animation: manualScrollReset? '' : 'timerAnimation 8s linear infinite',
          'animation-play-state': heroBannerPaused ? 'paused' : 'running'
        }
        "
        d="
          M 50, 50
          m -45, 0
          a 45,45 0 1,0 90,0
          a 45,45 0 1,0 -90,0
        "
      />
      <path
        v-if="heroBannerPaused"
        id="hero-banner-play-icon"
        style="transform: translate(3.8rem, 3.5rem) scale(4)"
        :class="{
          'fill-white' : isHeroBannerProductAndNonDesktopDesign ,
          'fill-black' : !isHeroBannerProductAndNonDesktopDesign
        }"
        d="
          M6.5 3.13397C7.16667
          3.51887 7.16667 4.48113 6.5
          4.86603L2 7.4641C1.33333 7.849
          0.5 7.36788 0.5 6.59808V1.40192C0.5
          0.632129 1.33333 0.151003 2
          0.535903L6.5 3.13397Z"
      />
      <g v-else id="hero-banner-pause-icon">
        <path
          style="transform: translate(3.8rem, 3.5rem) scale(4)"
          :class="{
            'fill-white' : isHeroBannerProductAndNonDesktopDesign,
            'fill-black' : !isHeroBannerProductAndNonDesktopDesign
          }"
          d="
          M2 1C2 0.447715 1.55228 0 1
          0C0.447715 0 0 0.447715 0
          1V7C0 7.55228 0.447715 8
          1 8C1.55228 8 2 7.55228
          2 7V1Z"
        />
        <path
          style="transform: translate(3.8rem, 3.5rem) scale(4)"
          :class="{
            'fill-white stroke-white' : isHeroBannerProductAndNonDesktopDesign,
            'fill-black stroke-black' : !isHeroBannerProductAndNonDesktopDesign
          }"
          d="
            M5 0.5C5.27614 0.5 5.5
            0.723858 5.5 1V7C5.5 7.27614
            5.27614 7.5 5 7.5C4.72386 7.5 4.5
            7.27614 4.5 7V1C4.5 0.723858
            4.72386 0.5 5 0.5Z"
        />
      </g>
    </g>
  </svg>
</template>

<script>

export default {
  props: {
    heroBannerPaused: {
      type: Boolean,
      default: false
    },
    manualScrollReset: {
      type: Boolean,
      default: false
    },
    isHeroBannerProductAndNonDesktopDesign: {
      type: Boolean,
      default: false
    }
  }
}
</script>
