const clickaway = {
  beforeMount(el, binding) {
    // exclude the element the opens the menu/modal
    const excludeEl = binding.value.exclude
    const targetEl = binding.value.objectId
    el.clickOutsideEvent = ((event) => {
      if (!(el === event.target || el.contains(event.target) || event.target.id === excludeEl)) {
        binding.instance[binding.value.handler](event, targetEl)
      }
    })
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unmounted(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  }
}

export default clickaway
