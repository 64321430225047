export const filterBreadcrumbs = (next, breadcrumbs = []) => {
  const { path, name } = next || {}
  const firstCrumb = breadcrumbs[0]
  let results = firstCrumb ? [firstCrumb] : []

  if (path) {
    const isNextFirstCrumb = path === breadcrumbs[0]?.path

    if (name === 'home') return [next]

    if (isNextFirstCrumb) return results

    results = breadcrumbs.filter((crumb, index) => (
      index === 0 || crumb.path !== path
    ))

    const hasCrumb = breadcrumbs.findIndex(crumb => crumb.path === path) !== -1

    if (hasCrumb) return results

    results.push(next)
  }
  return results
}
