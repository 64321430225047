import { GTM, GTM_GA4 } from 'utils/constants'

/**
  * @param {{
  *   storeCheck: string,
  *   storeType: string,
  *   storeNumber: number,
  *   userId: number,
  *   ecommStatus: string
  * }}
  * @returns {void}
  */

export function trackStoreDetails({
  storeCheck, storeType, storeNumber, userId, ecommStatus, userType
}) {
  window.sharedVue.config.globalProperties.$trackGtmEvent(GTM.storeDetails, {
    storeCheck,
    storeType,
    storeNumber,
    userType
  })
  window.sharedVue.config.globalProperties.$trackGA4Event(GTM_GA4.userProperties, {
    user_id: userId,
    ecomm_status: ecommStatus,
    user_store: storeNumber,
    customer_type: userType
  })
}
