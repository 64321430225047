<template>
  <PdlBasicTextInput
    :id="id"
    v-model="value"
    type="tel"
    :field-name="fieldName"
    :input-label="inputLabel"
    :placeholder-text="placeholderText"
    :is-required="isRequired"
    :is-dirty="isDirty"
    :disabled="disabled"
    :error="error"
    :max-length="14"
    :pattern="`[0-9]{3}-[0-9]{3}-[0-9]{4}`"
    :instant-update="true"
    @update:modelValue="value = $event"
    @blur="onBlur"
  >
    <template
      v-if="error"
      #errors
    >
      <slot name="errors" />
    </template>
  </PdlBasicTextInput>
</template>

<script>
import formatPhoneField from 'utils/filters/formatPhoneField'
import PdlBasicTextInput from './PdlBasicTextInput'

export default {
  components: {
    PdlBasicTextInput
  },
  props: {
    id: {
      type: String,
      required: true
    },
    modelValue: {
      type: [String, Number],
      default: ''
    },
    fieldName: {
      type: String,
      default: 'phone'
    },
    inputLabel: {
      type: String,
      default: 'Phone Number'
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    isDirty: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholderText: {
      type: String,
      default: 'Ex: (123) 456-7890'
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      phoneFormatted: '',
    }
  },
  beforeMount() {
    this.phoneFormatted = formatPhoneField(this.modelValue) || ''
  },
  computed: {
    value: {
      get() {
        return formatPhoneField(this.modelValue)
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
  methods: {
    onBlur() {
      this.$emit('blur')
    },
  },
  emits: ['update:modelValue', 'blur', 'change']
}
</script>
