<template>
  <div
    v-if="isVisible"
    class="py-4 pl-8 pr-16 my-2 mx-0 rounded-brand bg-gray-50 relative"
  >
    <p
      v-if="title"
      class="pt-2 text-lg font-semibold"
    >
      {{ title }}
    </p>
    <button
      v-if="isDismissible"
      aria-label="dismiss"
      class="absolute right-8 top-6 leading-3"
      @click.stop="dismiss"
    >
      <PdlIconBase
        icon-title="dismiss"
        icon-class="vector-icon-stroke--light-grey"
        container-class="w-5"
      >
        <PdlIconClose />
      </PdlIconBase>
    </button>
    <div
      :class="{
        'pt-3': title
      }"
    >
      <div
        v-if="message"
        id="pdl-dismissible-message_body-text"
      >
        {{ message }}
      </div>
      <slot name="messageBody" />
    </div>
  </div>
</template>

<script>
import { PdlIconBase } from '@/shared/icons'
import PdlIconClose from 'components/icons/icon/PdlIconClose'

export default {
  components: {
    PdlIconBase,
    PdlIconClose
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      default: ''
    },
    isDismissible: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      isVisible: true
    }
  },
  methods: {
    dismiss() {
      this.$emit('dismiss')
      this.isVisible = false
    }
  }
}
</script>
