import { GTM } from 'utils/constants'

/**
 * @param {string} cardNumber can be accessed through the store getter 'UserProfile/retailerCardNumber'
 * @returns {void}
 */
export function trackLoyaltyId(cardNumber) {
  window.sharedVue.config.globalProperties.$trackGtmEvent(GTM.loyaltyId, {
    loyaltyId: !cardNumber ? undefined : cardNumber
  })
}
