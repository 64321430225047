<!-- eslint-disable max-len !-->
<template>
  <g>
    <path
      d="M4.50014731,10.6171908 C4.50014731,9.22740094 5.58355092,8.09054387 6.95182847,8.00514923 L7.11733806,8 L24.8828092,8 C26.2725991,8 27.4094561,9.08340362 27.4948508,10.4516812 L27.5,10.6171908 L27.5,15.4700418 L27.499,15.47 L27.4998527,21.8977739 C27.4998527,23.2875637 26.4164491,24.4244208 25.0481715,24.5098154 L24.8826619,24.5149647 L7.11719075,24.5149647 C5.67180935,24.5149647 4.5,23.3431553 4.5,21.8977739 L4.5,21.8977739 L4.50014731,10.6171908 Z M25.534,15.47 L6.463,15.47 L6.46412064,21.8977739 C6.46412064,22.1928328 6.65988799,22.4422472 6.92859488,22.5231894 L7.02069681,22.5437619 L7.11719075,22.550844 L24.8826619,22.550844 C25.2432895,22.550844 25.5357321,22.2584015 25.5357321,21.8977739 L25.5357321,21.8977739 L25.534,15.47 Z M24.8828092,9.96412064 L7.11733806,9.96412064 C6.78949486,9.96412064 6.51792168,10.2058087 6.47125418,10.5206968 L6.46416974,10.6171908 L6.46416974,13.5054302 L25.5358794,13.5054302 L25.5358794,10.6171908 C25.5358794,10.3221319 25.340112,10.0727174 25.0714051,9.99177531 L24.9793032,9.97120279 L24.8828092,9.96412064 Z"
      fill="#000000"
      fill-rule="nonzero"
    />
  </g>
</template>
