import FeedbackSection from 'components/feedback/FeedbackSection'
import OrderContactForm from 'components/contact/OrderContactForm'
import ProductRequestForm from 'components/contact/ProductRequestForm'
import AccountContactForm from 'components/contact/AccountContactForm'
import GeneralContact from 'components/contact/GeneralContact'
import QualtricsFeedback from 'components/feedback/QualtricsFeedback'
import PdlHelpArticle from 'components/help/PdlHelpArticle'

import ContactPage from './pages/ContactPage'

const CONTACT_ROUTE = '/contact'
export default [
  {
    path: '/modal/help/contact/email',
    redirect: `${CONTACT_ROUTE}/email`
  },
  {
    path: '/modal/help/contact/email-more',
    redirect: `${CONTACT_ROUTE}/email-more`
  },
  {
    path: '/modal/help/topic/contact-us',
    redirect: `${CONTACT_ROUTE}`
  },
  {
    path: '/help/topic/contact-us',
    redirect: `${CONTACT_ROUTE}`
  },
  {
    path: '/contact/printed-ad',
    redirect: `${CONTACT_ROUTE}`
  },
  {
    path: '/help/contact/refund',
    redirect: '/account/history/invoice'
  },
  {
    path: '/help/contact-us/refund',
    redirect: '/account/history/invoice'
  },
  {
    path: '/contact/product/request',
    redirect: `${CONTACT_ROUTE}/product-request`
  },
  {
    path: '/help/contact:pathMatch(.*)*',
    redirect: `${CONTACT_ROUTE}`
  },

  {
    path: '/modal/user-feedback-modal:pathMatch(.*)*',
    redirect: `${CONTACT_ROUTE}/feedback`
  },
  {
    path: '/user-feedback-modal:pathMatch(.*)*',
    redirect: `${CONTACT_ROUTE}/feedback`
  },
  {
    path: '/modal/feedback:pathMatch(.*)*',
    redirect: `${CONTACT_ROUTE}/feedback`
  },
  {
    path: '/modal/help/contact:pathMatch(.*)*',
    redirect: `${CONTACT_ROUTE}`
  },
  {
    path: '/help/contact:pathMatch(.*)*',
    redirect: `${CONTACT_ROUTE}`
  },
  {
    path: '/contact-us:pathMatch(.*)*',
    redirect: `${CONTACT_ROUTE}`
  },

  {
    path: `${CONTACT_ROUTE}:pathMatch(.*)*`,
    name: 'contact',
    component: ContactPage,
    children: [
      {
        path: 'feedback/give-feedback',
        name: 'give-feedback',
        component: QualtricsFeedback
      },
      {
        path: 'feedback',
        name: 'feedback',
        component: FeedbackSection,
      },
      {
        path: 'refund',
        name: 'refund',
        component: OrderContactForm
      },
      {
        path: 'product-request',
        name: 'product-request',
        component: ProductRequestForm
      },
      {
        path: 'email',
        name: 'email',
        component: AccountContactForm
      },
      {
        path: 'email-more',
        name: 'email-more',
        component: GeneralContact
      },
      {
        path: 'article/:articleRoute',
        name: 'contact-article',
        component: PdlHelpArticle
      }
    ]
  }
]
