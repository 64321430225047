import {
  DRIVER_DEPARTURE_OFFSET,
  GLOBAL_ETA_BUFFER,
  GLOBAL_LATE_MINUTES,
} from 'utils/constants'

import {
  LONG_ISO8601_DATE
} from 'utils/regex'

import { isVersionGreaterOrEqual } from 'utils/ApiVersionCheckers'

const getCurrentTime = () => (_.now())

export const isModernVersion = version => (isVersionGreaterOrEqual(version, 6))

const service = {
  trackingSteps: {
    scheduled: {
      id: 1,
      label: 'Order Scheduled',
      displayLabel: 'Order<br>Scheduled'
    },
    packed: {
      id: 2,
      label: 'Packed & Ready',
      displayLabel: 'Packed<br>& Ready'
    },
    way: {
      id: 3,
      label: 'On the Way'
    },
    delivered: {
      id: 4,
      label: 'Delivered'
    }
  },

  trackingPickupSteps: {
    received: {
      id: 1,
      label: 'Order Scheduled',
      displayLabel: 'Order<br>Scheduled'
    },
    packed: {
      id: 2,
      label: 'Order in Progress',
      displayLabel: 'Order in<br>Progress'
    },
    ready: {
      id: 3,
      label: 'Ready for Pickup',
      displayLabel: 'Ready for<br>Pickup'
    },
    complete: {
      id: 4,
      label: 'Order Complete',
      displayLabel: 'Order<br>Complete'
    }
  },

  // UTILS FOR TIME STAMP
  addMinutes: (dateTime, minute) => (window.LuxonDateTime
    .fromISO(dateTime, { setZone: true })
    .plus({ minute })
    .toString()
  ),

  isISO8601Date: dateString => (LONG_ISO8601_DATE.test(dateString)),
  getTimezoneOffset: (dateString) => {
    if (!service.isISO8601Date(dateString)) {
      return ''
    }
    const matches = dateString.match(LONG_ISO8601_DATE)
    const tz = matches[8] + matches[9] + matches[10]
    if (tz.length > 0) {
      return tz
    }
    return ''
  },

  getNumStopsAhead: () => {
    const DEFAULT_NUM_STOPS_AHEAD = 5
    const CONFIG_NUM_STOPS_AHEAD = window.$store.getters['SiteConfig/varByName']('config_num_stops_ahead')
    const parsedNumStopsAhead = parseInt(CONFIG_NUM_STOPS_AHEAD, 10)
    const parsedNumberIsANumber = Number.isNaN(parsedNumStopsAhead) ? DEFAULT_NUM_STOPS_AHEAD : parsedNumStopsAhead
    return parsedNumberIsANumber ?? DEFAULT_NUM_STOPS_AHEAD
  },

  roundDownToNearestFiveMinutes: (dateTime) => {
    const response = window.LuxonDateTime.fromISO(dateTime, { setZone: true })
    const oldMinutes = response.minute
    const minute = (Math.floor(oldMinutes / 5) * 5)
    const newTime = response.set({ minute })
    return newTime.toString()
  },

  isDelayed: (order) => {
    let response = false
    const { progress } = order?.orderProgress?.route || {}
    if (progress) {
      const realtimeEta = new Date(progress.estimatedArrivalTime).getTime()
      const plannedEtaEnd = new Date(order.timeEndWithZoneOffset).getTime()
      const difference = realtimeEta - plannedEtaEnd
      const lateMinutes = GLOBAL_LATE_MINUTES * 1000 * 60
      response = (difference > 0 && difference > lateMinutes)
    }
    return response
  },

  isNearby: (order) => {
    let response = false
    const numStopsAhead = service.getNumStopsAhead()
    const { progress } = order?.orderProgress?.route || {}
    if (progress) {
      const stopsAway = service.stopsAway(progress)
      response = (stopsAway <= numStopsAhead)
    }
    return response
  },

  stopsAway: ({ orderStop, previousStop }) => (orderStop > previousStop ? orderStop - previousStop : 0),

  getStopsAway: (order) => {
    let response = 0
    const { progress } = order?.orderProgress?.route || {}
    if (progress) {
      response = service.stopsAway(progress)
    }
    return response
  },

  isDelivered: (order) => {
    const { progress } = order?.orderProgress?.route || {}
    const { actualDepartureTime } = progress || {}

    return !!actualDepartureTime
  },

  isOnTheWay: order => (
    !service.isDelivered(order)
    && service.isNearby(order)
    && service.isPacked(order)
  ),

  isPacked: (order) => {
    let response = false
    const { routeEstDepartDatetime } = order?.orderProgress?.orderReport || {}

    if (routeEstDepartDatetime) {
      const driverEstRouteStartTime = new Date(routeEstDepartDatetime).getTime()
      const departureOffset = 1000 * 60 * 60 * DRIVER_DEPARTURE_OFFSET
      const timeDelta = driverEstRouteStartTime - getCurrentTime()
      response = ((timeDelta < departureOffset && timeDelta > 0) || timeDelta < 0)
    }

    return response
  },

  pickupIsPacked: (order) => {
    const { orderStatus } = order
    return orderStatus === 'I'
  },

  // Food Lion pickup only
  // 'Order In Progress' is checked when the shopper is in picking progress.
  // And we should see 'Chat with shopper' button in this stage.
  isPickingStarted: (order) => {
    const { pickupStatus } = order
    return pickupStatus === 'I' || pickupStatus === 'D'
  },

  isReady: (busSysOrderStatus, platformOrderStatus) => {
    const now = window.LuxonDateTime.local()
    const startTime = busSysOrderStatus.startTime || platformOrderStatus.timeStart
    const pickupStartTime = window.LuxonDateTime.fromISO(startTime, { setZone: true })
    return now >= pickupStartTime
  },

  isComplete: (order) => {
    const { orderStatus, pickupStatus } = order
    return orderStatus === 'C' || (orderStatus === 'I' && (pickupStatus === 'C' || pickupStatus === 'X'))
  },

  getCurrentStep: (order) => {
    if (service.isDelivered(order)) {
      return service.trackingSteps.delivered.id
    }

    if (service.isOnTheWay(order)) {
      return service.trackingSteps.way.id
    }

    if (service.isPacked(order)) {
      return service.trackingSteps.packed.id
    }

    return service.trackingSteps.scheduled.id
  },

  getCurrentPickupStep: (busSysOrderStatus, platformOrderStatus) => {
    const trackingSteps = service.trackingPickupSteps

    if (service.isComplete(busSysOrderStatus)) {
      return trackingSteps.complete.id
    }

    if (service.pickupIsPacked(busSysOrderStatus) && service.isReady(busSysOrderStatus, platformOrderStatus)) {
      return trackingSteps.ready.id
    }

    if ((service.pickupIsPacked(busSysOrderStatus) || service.isPickingStarted(busSysOrderStatus))
    && !service.isReady(busSysOrderStatus, platformOrderStatus)) {
      return trackingSteps.packed.id
    }

    return trackingSteps.received.id
  },

  substitutionProducts: (productReports) => {
    let response = []
    if (_.isArray(productReports)) {
      response = _.filter(productReports, ({ substituteReport, outOfStockReport }) => (
        !_.isEmpty(outOfStockReport)
        && !_.isEmpty(substituteReport)
        && substituteReport.replacedQuantity > 0
      ))
    }
    return response
  },
  // returns all products that are missing with no substitutions
  noSubstitutionProducts: (productReports) => {
    let response = []
    if (_.isArray(productReports)) {
      response = _.filter(productReports, ({ outOfStockReport, substituteReport }) => (
        !_.isEmpty(outOfStockReport)
        && (outOfStockReport.quantityMissing > 0 || outOfStockReport.quantityMissingBeforeSubstitution > 0)
        && (_.isEmpty(substituteReport) || substituteReport.replacedQuantity < 1)
      ))
    }
    return response
  },

  getSubProducts: (order) => {
    const { items } = order || {}
    return service.substitutionProducts(items) || []
  },
  getOOSProducts: (order) => {
    const { items } = order || {}
    return service.noSubstitutionProducts(items) || []
  },

  // for display - offset to local
  calcCurrentEta: (order) => {
    const { orderProgress } = order || {}
    const orderProgressStatus = orderProgress?.route?.progress || {}
    const { estimatedArrivalTime, plannedArrivalTime } = orderProgressStatus

    let response = {}
    let start = order.timeStartWithZoneOffset || ''
    let end = order.timeEndWithZoneOffset || ''
    const currentStep = service.getCurrentStep(order)
    // When roadnet takes over time and not in ready
    if (plannedArrivalTime && currentStep > 1) {
      // we prefer to use estimated arrival, but if not available use planned arrival
      start = (!estimatedArrivalTime ? plannedArrivalTime : estimatedArrivalTime)
      // Real Time ETA Window
      // Include the offset so it doesn't have to be computed later
      start = service.roundDownToNearestFiveMinutes(start)
      end = service.addMinutes(start, GLOBAL_ETA_BUFFER)
    }

    response = {
      start,
      end
    }
    return response
  },

  // for display - offset to local
  getEstimatedArrivalTime: (order) => {
    const orderProgress = order?.orderProgress?.route?.progress || {}
    const arrivalTime = orderProgress.estimatedArrivalTime || order?.timeEnd
    return (!arrivalTime) ? null : arrivalTime
  },

  // for display - offset to local
  getDeliveryTime: (order) => {
    const { actualDepartureTime } = order?.orderProgress?.route?.progress || {}
    return (!actualDepartureTime) ? null : actualDepartureTime
  },

  getLocations: (order) => {
    const {
      destinationLatitude,
      destinationLongitude,
      currentLatitude,
      currentLongitude,
      actualArrivalTime
    } = order?.orderProgress?.route?.progress || {}

    const response = {}

    const hasDestination = !!(destinationLatitude && destinationLongitude)
    const hasCurrent = !!(currentLatitude && currentLongitude)

    if (hasDestination) {
      response.destination = {
        latitude: destinationLatitude,
        longitude: destinationLongitude
      }
    }

    if (hasCurrent) {
      response.current = {
        latitude: currentLatitude,
        longitude: currentLongitude
      }
    } else if (hasDestination && !!actualArrivalTime) {
      const { latitude, longitude } = service.getPseudoCurrentLocation(destinationLatitude, destinationLongitude)

      response.current = {
        latitude,
        longitude
      }
    }

    return response
  },

  getPseudoCurrentLocation: (destinationLatitude = 0, destinationLongitude = 0) => {
    const angle = (360.0 / 2)
    const offset = 0.00005
    const latitude = destinationLatitude + offset * (Math.cos((+angle) / 180) * Math.PI)
    const longitude = destinationLongitude + offset * (Math.sin((+angle) / 180) * Math.PI)

    return {
      latitude,
      longitude
    }
  },

  isRouteServiceDown: (order) => {
    const progressErrors = order?.orderProgress?.errors
    return progressErrors && _.contains(progressErrors, 'ROUTE_SERVICE_UNAVAILABLE')
  },

  hasPassedCutoffTime: cutoffTime => (cutoffTime && getCurrentTime() > new Date(cutoffTime).getTime()),

  getDriverName: (order) => {
    const {
      firstName
    } = order?.orderProgress?.route?.driver || {}
    return firstName
  },

  shouldShowEta: (order) => {
    service.getCurrentStep()
    if (order.serviceType === 'D') {
      return (
        service.isDelayed(order)
        || service.isNearby(order)
        || service.isDelivered(order)
        || (order.eta && !!order.etaTimeStartWithZoneOffset))
    }
    return !!(order.eta && !!order.etaTimeStartWithZoneOffset)
  },

  shouldShowReducedEta: order => (order.serviceType === 'D'
      && service.isOnTheWay(order)
      && (service.isDelayed(order) || service.isNearby(order))),
  getCurrentEtaStart: (order) => {
    let response = (order.eta && !!order.etaTimeStartWithZoneOffset)
      ? order.etaTimeStartWithZoneOffset : order.timeStartWithZoneOffset
    if (order.serviceType === 'D' && (service.shouldShowReducedEta(order) || service.isDelivered(order))) {
      response = order.etaWindowStart
    }
    return response
  },

  getCurrentEtaEnd: (order) => {
    let response = (order.eta && !!order.etaTimeEndWithZoneOffset)
      ? order.etaTimeEndWithZoneOffset : order.timeEndWithZoneOffset
    if (order.serviceType === 'D' && (service.shouldShowReducedEta(order) || service.isDelivered(order))) {
      response = order.etaWindowEnd
    }
    return response
  },

  // compensate for api versions not having the same response
  parseOrderStatusResponse: (orderStatusResponse, version) => {
    return ((isModernVersion(version)) ? orderStatusResponse?.data
      : orderStatusResponse?.data?.response) ?? {}
  },

  mapOrderStatus: (response, version) => {
    const parsedResponse = service.parseOrderStatusResponse(response, version)
    const { currentOrders = [], pendingOrders = [] } = parsedResponse
    let results = {
      ...parsedResponse
    }

    if (isModernVersion(version)) {
      const [firstOrder] = currentOrders || []
      results = {
        ...parsedResponse,
        ...{
          currentOrder: firstOrder,
          pendingOrder: pendingOrders,
          pendingOrders: undefined,
          currentOrders: undefined
        }
      }
    }

    return results
  }

}

export default service
