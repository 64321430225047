import BankAccountApi from 'api/BankAccountAPI'
import excludeNilFromObj from 'utils/filters/excludeNilFromObj'
import formatPrivateString from 'utils/filters/formatPrivateString'

const defaults = {
  bankAccountNumber: '',
  bankName: '',
  routingNumber: '',
  termsAccepted: false
}

const newDefaults = {
  accountNumber: '',
  active: false,
  addressLine1: '',
  addressLine2: '',
  bankRoutingNumber: '',
  capsId: 0,
  city: '',
  contact: {
    areaCode: '',
    prefix: '',
    suffix: ''
  },
  firstName: '',
  lastName: '',
  maskedAccountNumber: '',
  nickname: '',
  paymentMethod: '',
  primaryAccount: false,
  state: '',
  zip: '',
  serviceLocationId: ''
}

export default {
  namespaced: true,
  state: {
    loading: false,
    loaded: false,
    ...defaults,
    ...newDefaults
  },
  getters: {
    bankAccountNumber: state => state.maskedAccountNumber || state.bankAccountNumber,
    routingNumber: state => state.bankRoutingNumber || state.routingNumber,
  },
  mutations: {
    setLoaded(state, payload) { state.loaded = !!payload },
    setLoading(state, payload) { state.loading = payload },
    setStoreData(state, payload) {
      _.each(payload, (value, key) => {
        if (_.has(state, key) && !_.isNull(value)) {
          const filteredFields = ['routingNumber', 'bankAccountNumber']
          value = (filteredFields.includes(key)) ? formatPrivateString(value) : value
          state[key] = value
        }
      })
    }
  },
  actions: {
    setBankAccount({ commit, rootState }) {
      const { bankAccount } = rootState.PaymentMethod
      commit('setStoreData', bankAccount || {})
      commit('setLoaded', true)
    },
    async updateBankAccount({ commit, rootGetters }, payload) {
      let response
      payload = _.pick(payload, Object.keys({ ...defaults, ...newDefaults }))
      const userId = rootGetters['UserProfile/userId']
      try {
        response = await BankAccountApi.update(excludeNilFromObj(payload), userId)
        const { status } = response
        const success = (status && (status >= 200 && status < 300))
        if (success) {
          commit('setStoreData', payload || {})
        }
        return response
      } catch {
        return response
      }
    }
  }
}
