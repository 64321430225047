import ContactUsAPI from 'api/ContactUsAPI'

const initialState = () => ({
  contactUsStatus: false,
  displayError: false,
  trackUserStatus: 'Not Registered',
  emailSubjects: [],
  userOrders: [],
  userOrderReturn: {},
  selectedProducts: {},
  sellerInformation: {},
  reasonCodeText: {
    'S-100': 'Incorrect item',
    'S-101': 'Missing item',
    'S-102': 'Item quality',
    'S-104': 'Out of stock',
    'S-103': 'Driver arrival time',
    'S-301': 'Billing question',
    'S-404': 'Technical assistance',
    'S-603': 'Promo offer',
    'S-011': 'Other',
  }
})

export default {
  state: {
    selectedOrder: '',
    ...initialState()
  },
  mutations: {
    setContactUsStatus(state, payload) {
      state.contactUsStatus = payload
    },
    setDisplayError(state, payload) {
      state.displayError = payload
    },
    setTrackUserStatus(state, payload) {
      state.trackUserStatus = payload
    },
    setEmailSubjects(state, payload) {
      state.emailSubjects = payload
    },
    setUserOrders(state, payload) {
      state.userOrders = payload
    },
    setSelectedOrder(state, payload) {
      state.selectedOrder = payload
    },
    setUserOrderReturn(state, payload) {
      state.userOrderReturn = payload
    },
    setSelectedProducts(state, payload) {
      state.selectedProducts = payload
    },
    setSellerInformation(state, payload) {
      state.sellerInformation = payload
    },
    setState(state, payload) {
      Object.assign(state, payload)
    }
  },
  actions: {
    resetState({ commit }) {
      commit('setState', initialState())
    },
    async getSubjectsAndOrders({ commit }, userId) {
      try {
        const getSubjectsAndOrdersResponse = await ContactUsAPI.getSubjectsAndOrders(userId)
        const success = getSubjectsAndOrdersResponse?.status === 200 || getSubjectsAndOrdersResponse?.status === 201
        if (success) {
          let orders = []
          const { serviceCodes, orderDetails } = getSubjectsAndOrdersResponse?.data
            || { serviceCodes: [], orderDetails: [] }
          const subjects = serviceCodes
            .filter(obj => (
              obj.rsnCd?.toLowerCase() !== 's-060'
                && obj.svcTx?.toLowerCase() !== 'ACCOUNT CANCELLATION REQUEST'
            ))
            .map(({
              rsnCd, svcTx, orderReqd, guestAllowed, svcTxShip2Me
            }) => ({
              code: rsnCd,
              title: svcTx,
              svcTxShip2Me,
              orderRequired: orderReqd,
              guestAllowed
            }))

          if (Array.isArray(orderDetails)) {
            orders = orderDetails?.map(({ dlvDt, ordId, storId }) => ({
              orderDate: dlvDt,
              orderId: ordId,
              storeId: storId
            }))
          }

          commit('setEmailSubjects', subjects)
          commit('setUserOrders', orders)
        } else {
          throw new Error('Get subjects and orders call failed')
        }
        return getSubjectsAndOrdersResponse
      } catch (err) {
        commit('setContactUsStatus', false)
        return Promise.reject(err)
      }
    },
    postAccountDeleteContact(obj, payload = {}) {
      try {
        const { userId } = payload
        if (!userId) {
          throw new Error({
            response: {
              code: 'USER_ID_REQUIRED',
              message: 'userId is a required property'
            }
          })
        }
        return ContactUsAPI.postAccount({ payload, userId })
      } catch (err) {
        return err.response
      }
    },
    async postAccountContact({ commit }, { userId, payload }) {
      try {
        if (!userId) {
          throw new Error({
            response: {
              code: 'USER_ID_REQUIRED',
              message: 'userId is a required property'
            }
          })
        }
        const response = await ContactUsAPI.postAccount({ userId, payload })
        const success = response.status > 199 && response.status < 300

        commit('setContactUsStatus', success)
        if (!success) {
          commit('setDisplayError', true)
        }
        return response
      } catch (err) {
        commit('setContactUsStatus', false)
        commit('setDisplayError', true)
        return err.response
      }
    },
    async postOrderContact({ commit }, { userId, payload }) {
      try {
        if (!userId) {
          throw new Error({
            response: {
              code: 'USER_ID_REQUIRED',
              message: `'userId' is a required property`
            }
          })
        }
        if (!payload.ordId) {
          throw new Error({
            response: {
              code: 'ORDER_ID_REQUIRED',
              message: `'ordId' is a required property`
            }
          })
        }
        const response = await ContactUsAPI.postAccount({ userId, payload })
        const success = response.status > 199 && response.status < 300

        commit('setContactUsStatus', success)
        if (!success) {
          commit('setDisplayError', true)
        }
        return response
      } catch (err) {
        commit('setContactUsStatus', false)
        commit('setDisplayError', true)
        return err.response
      }
    },
    async submitContactUsBrandsApi({ commit }, payload) {
      if (payload.opco === 'PPOD' || !payload.opco) {
        payload.opco = 'STSH'
      }
      try {
        // eslint-disable-next-line max-len
        const response = await ContactUsAPI.postGeneral(payload)
        const success = response.status === 200 || response.status === 201
        if (success) {
          commit('setContactUsStatus', true)
        } else {
          commit('setDisplayError', true)
        }
        return response
      } catch (err) {
        commit('setContactUsStatus', false)
        commit('setDisplayError', true)
        return err.response
      }
    },
    async submitShip2MeReturns({ rootGetters }, payload) {
      const userId = rootGetters['UserProfile/userId']
      return ContactUsAPI.postAccountMkt({ payload, userId })
    },
  }
}
