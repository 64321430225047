<template>
  <div>
    <PdlTitleBar
      :title="title || pageTitle"
      :has-view-options="showViewOptions || hasViewOptions"
      :hide-medium-view-options="hideMediumViewOptions"
      :has-print-button="showPrintButton"
      :show-back="showBackButton"
      @print-cart="printCart"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import PdlTitleBar from 'components/PdlTitleBar'
import { GTM_GA4 } from 'utils/constants'
import generateSiteLocation from 'utils/lib/generateSiteLocation'

export default {
  components: {
    PdlTitleBar
  },
  data() {
    return {
      title: '',
      hasViewOptions: false,
      hideMediumViewOptions: false,
      hasSortOptions: false
    }
  },
  computed: {
    ...mapGetters({
      pageTitle: 'TitleBar/pageTitle',
      enableListView: 'TitleBar/enableListView',
      hasPrintButton: 'TitleBar/hasPrintButton',
      showViewOptions: 'TitleBar/showViewOptions', // this will replace hasViewOptions
      userStatus: 'LoginStatus/userStatus',
      isMobileApp: 'NativeContainer/isMobileApp',
      selectedSlot: 'Slots/selectedSlot',
      summaryData: 'Cart/getSummaryData',
      isBackButtonHidden: 'TitleBar/isBackButtonHidden'
    }),
    viewSwitcherEnabled() {
      return this.$store.getters['TitleBar/showViewSwitcher']
        && this.$store.getters['TitleBar/enableListView']
    },
    hasProducts() {
      return this.$store.getters['ShoppingList/hasProducts']
    },
    isGuestUser() {
      return this.userStatus === 'G'
    },
    showPrintButton() {
      return this.hasPrintButton && !this.isMobileApp
    },
    showBackButton() {
      return !!this.$router?.options?.history?.state?.back && !this.isBackButtonHidden
    }
  },
  watch: {
    $route(to, from) {
      this.onUpdate(to.path, from.path)
    },
    pageTitle(newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        this.hasViewOptions = false
        this.title = this.pageTitle
      }
    },
    enableListView(newVal, oldVal) {
      this.onUpdate(newVal, oldVal)
    },
  },
  mounted() {
    this.setTitleBar()
  },
  methods: {
    setTitleBar() {
      this.hasViewOptions = false
      this.$store.commit('TitleBar/setShowViewOptions', false)
      this.title = this.pageTitle
    },
    onUpdate(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.setTitleBar()
      }
    },
    setCurrentSlotInformation() {
      if (Object.keys(this.selectedSlot).length !== 0) {
        this.$store.commit('Cart/setPrintSelectedSlot', this.selectedSlot)
      }
    },
    printCart() {
      this.setCurrentSlotInformation()
      this.$store.commit('Modals/setActiveModal', {
        fileName: 'PdlPrintCart',
        isPrintView: true
      })
      const lineItems = this.summaryData?.lineItems || []
      const totalLineItem = lineItems.find(item => item?.description === 'Total')
      const result = totalLineItem?.amount || 0
      window.sharedVue.config.globalProperties.$trackGA4Event(GTM_GA4.click, {
        result,
        action: 'print cart',
        link_id: 'qa-print-btn',
        link_text: 'Print',
        site_location: generateSiteLocation(this.$route),
        type: 'order'
      })
      setTimeout(() => { // needed for mobile devices
        window.print()
      }, 1200)
      setTimeout(() => { // close the overlay after print view
        this.$store.commit('Modals/clearActiveModal')
      }, 2000)
    }
  }
}
</script>
