function getScript(url, options) {
  return new Promise((resolve, reject) => {
    const head = document.getElementsByTagName('head')[0]
    let done = false
    const script = document.createElement('script')
    script.src = url
    script.type = 'text/javascript'
    script.async = options.async
    script.defer = options.defer
    script.id = options?.id
    if (options.fetchpriority) {
      script.fetchpriority = options.fetchpriority
    }
    if (options.crossOrigin) {
      script.crossOrigin = options.crossOrigin
    }
    if (options.dataset) {
      const keys = Object.keys(options.dataset)
      keys.forEach((key) => {
        script.dataset[key] = options.dataset[key]
      })
    }
    // eslint-disable-next-line
    script.onload = script.onreadystatechange = () => {
      const state = script.readyState
      if (!done && (!state || /loaded|complete/.test(state))) {
        done = true
        return resolve({
          url,
          loaded: true,
          script
        })
      }
    }

    script.onerror = () => reject(new Error({
      url,
      loaded: false
    }))
    // use body if available
    return (document.body || head).appendChild(script)
  })
}

export default async function (urls, options) {
  const promises = []
  options = options || { async: false, defer: false }
  if (Array.isArray(urls)) {
    urls.forEach((url) => {
      if (url !== null && url !== '') {
        promises.push(getScript(url, options))
      }
    })
  } else if (urls !== null && urls !== '') {
    promises.push(getScript(urls, options))
  }
  return Promise.all(promises)
}
