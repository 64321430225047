import { mapGetters } from 'vuex'
import formatCurrency from 'utils/filters/formatCurrency'
import setLinkOutUrl from 'utils/filters/setLinkOutUrl'

// eslint-disable-next-line
const allExpressionsRE = /{{([^{}]*)}}/g
// eslint-disable-next-line
const expressionContentRE = /.*?(content\.[\w.]*)[^|]*(\|.*)?/g
// eslint-disable-next-line no-useless-escape
const expressionMetadataRE = /.*?(metadata\.[\w.]*)[^|]*(\|.*)?/g
// eslint-disable-next-line
const expressionBrandRE = /.*?(brand\.[\w.]*)[^|]*(\|.*)?/g
const htmlTagsWhiteList = ['a', 'b', 'br', 'dd', 'dl', 'dt', 'em', 'li', 'ol',
  'p', 'strong', 'u', 'ul', 'h3', 'h4', 'h5', 'h6',
  'table', 'tr', 'td', 'th', 'tbody', 'thead', 'tfoot', 'caption', 'vector']
// eslint-disable-next-line
const htmlTagsRE = /<[\s|/]*([^>\s]*)[^>]*>/g
// eslint-disable-next-line
const htmlAttributesRE = /<\s*(\w+)([\s\S]*?)>/g
const dataElementIdWhiteList = [
  'preferencesoverview-gs-more-information',
  'preferencesoverview-dietitians-email'
]

const compileLinkVars = {
  computed: {
    ...mapGetters({
      userContent: 'ScheduledContent/userContent',
      brandVars: 'SiteConfig/brandVars'
    })
  },
  methods: {
    cleanHtml(data) {
      if (!data) {
        return data
      }
      // eslint-disable-next-line
      data = this.cleanHtmlTags(data)
      data = this.cleanHtmlAttributes(data)
      data = this.cleanAllExpressions(data)
      return data
    },
    cleanAllExpressions(link) {
      if (!link) return ''
      return link.replace(allExpressionsRE, (expression => this.cleanExpression(expression)))
    },
    cleanExpression(data) {
      if (expressionMetadataRE.test(data)) {
        return data.replace(expressionMetadataRE, expression => this.transformComputedValue(expression))
      }
      if (expressionContentRE.test(data)) {
        return data.replace(expressionContentRE, expression => this.transformContentValue(expression))
      }
      return data.replace(expressionBrandRE, expression => this.transformBrandValue(expression))
    },
    transformComputedValue(val) {
      const field = val.replace(/[{()}]/g, '').replace(/\s/g, '').split('.')
      field.shift(0) // removes 'computed'
      const { computedVars } = this
      if (computedVars) {
        const value = computedVars[field]
        return value !== undefined ? value : ''
      }
      return ''
    },
    transformContentValue(val) {
      const field = val.replace(/[{()}]/g, '').replace(/\s/g, '').split('.')
      field.shift(0) // removes 'content'
      const { userContent } = this
      if (userContent) {
        const value = this.getNestedObject(userContent, field)
        return value !== undefined ? value : ''
      }
      return ''
    },
    transformBrandValue(val) {
      const field = val.replace(/[{()}]/g, '').replace(/\s/g, '').split('.')
      field.shift(0) // removes 'brand'
      const { brandVars } = this
      if (brandVars) {
        const value = this.getNestedObject(brandVars, field)
        return value !== undefined ? value : ''
      }
      return ''
    },
    getNestedObject(nestedObj, pathArr) {
      let hasCurrency = 0
      const filteredItems = pathArr.map((val) => {
        hasCurrency = val.search('\\|currency')
        if (hasCurrency) {
          return val.split('|')[0]
        }
        return val
      })
      const value = filteredItems.reduce((obj, key) => (obj && obj[key] !== 'undefined' ? obj[key] : ''), nestedObj)
      if (typeof value !== 'undefined') {
        return hasCurrency > -1 ? `${formatCurrency(value)}` : value
      }
      return ''
    },
    cleanHtmlTags(data) {
      if (!data?.replace) {
        console.error(`Data is not type String: ${data}`)
        return ''
      }
      return data.replace(htmlTagsRE, (match, tag) => {
        if (_.contains(htmlTagsWhiteList, tag.toLowerCase())) {
          return match
        }
        return ''
      })
    },
    cleanHtmlAttributes(data) {
      return data.replace(htmlAttributesRE, (match, tag, attributes) => {
        const tagName = tag
        let attributeObject = {}
        let cleanedHtml = `<${tagName}>`
        if (attributes.length > 0) {
          attributeObject = this.createAttributeObject(attributes)
        }
        cleanedHtml = this.handleNgAttribute(attributeObject, tag, tagName, cleanedHtml)
        cleanedHtml = this.handleTagsByType(tag, tagName, attributeObject, cleanedHtml)
        return cleanedHtml
      })
    },
    createAttributeObject(attributes) {
      const obj = {}
      // eslint-disable-next-line
      const regex = /([a-z-]+)="\s*([^"]+)\s*"/gi
      let results
      // eslint-disable-next-line
      while ((results = regex.exec(attributes)) !== null) {
        const attributeName = results[1]
        const attributeValue = results[2]
        obj[attributeName] = attributeValue
      }
      return obj
    },
    handleNgAttribute(attributeObject, tag, tagName, cleanedHtml) {
      let response = cleanedHtml
      if (attributeObject['ng-if']) {
        const fields = attributeObject['ng-if'].replace(/[!?]/g, '').split('.')
        fields.shift(0) // removes 'content'
        const { userContent } = this
        const value = userContent ? this.getNestedObject(userContent, fields) : ''
        const valueEquals = attributeObject['ng-equals']
        if (valueEquals) {
          tagName = value === valueEquals ? `${tag}` : `${tag} class="display--none"`
          response = `<${tagName}>`
        } else {
          tagName = value ? `${tag}` : `${tag} class="display--none"`
          response = `<${tagName}>`
        }
      }
      return response
    },
    handleTagsByType(tag, tagName, attributeObject) {
      let response
      switch (tag) {
        case 'p':
          response = this.handleTagTypeP(tagName, attributeObject)
          break
        case 'a':
          response = this.handleTagTypeA(tagName, attributeObject)
          break
        case 'vector':
          response = this.handleTagTypeVector(tagName, attributeObject)
          break
        case 'h3':
          response = this.handleTagTypeH(tagName, attributeObject)
          break
        default:
          response = `<${tagName}>`
          break
      }
      return response
    },
    handleTagTypeVector(tagName, attributeObject) {
      return `<${tagName} role="img" aria-label="${attributeObject['svg-description']}"`
            + ` container-class="${attributeObject['container-class']}"`
            + ` svg-id="${attributeObject['svg-id']}"`
            + ` svg-role="${attributeObject['svg-role']}"`
            + ` svg-title="${attributeObject['svg-title']}"`
            + ` svg-description="${attributeObject['svg-description']}"`
            + ` svg-aria-hidden="${attributeObject['svg-aria-hidden']}"`
            + `>`
    },
    handleTagTypeP(tagName, attributeObject) {
      if (attributeObject.class === 'disclaimer-copy') {
        return `<${tagName} class="disclaimer-copy">`
      }
      return `<${tagName}>`
    },
    handleTagTypeA(tagName, attributeObject) {
      const isExternal = attributeObject['data-link-type'] === 'external'
      const whiteListedId = this.handleWhiteListedId(attributeObject)
      if (isExternal) {
        const isMobileApp = this.$store.getters['NativeContainer/isMobileApp']
        const url = isMobileApp ? setLinkOutUrl(attributeObject.href) : attributeObject.href
        return `<${tagName}${whiteListedId} class="text-link ${attributeObject.class}" href="${url}" target="_blank"
        rel="noopener noreferrer" >`
      }
      return `<${tagName}${whiteListedId} class="text-link ${attributeObject.class}" href="${attributeObject.href}" >`
    },
    handleTagTypeH(tagName, attributeObject) {
      const { id } = attributeObject
      if (id) {
        const specialCharsRegex = /[^a-z\-\s]/g
        const spacesRegex = /\s+/g
        const cleanedId = id.toLowerCase()
          .replace(specialCharsRegex, '')
          .replace(spacesRegex, '-')
        return `<${tagName} id="${cleanedId}" tabindex="-1">`
      }
      return `<${tagName}>`
    },
    handleWhiteListedId(attributeObject) {
      let safeId = ''
      dataElementIdWhiteList.forEach((whiteListedId) => {
        if (whiteListedId === attributeObject['data-element-id']) {
          // we use the string from the white list instead of the string from the data-element-id
          // to ensure that malicious code cannot be injected here through CMS
          safeId = whiteListedId
        }
      })
      return safeId ? ` id="${safeId}"` : ''
    },
    stripHtmlTags(data) {
      const cleanedData = this.cleanHtml(data)
      return cleanedData?.replace(htmlTagsRE, () => '')
    }
  }
}

export default compileLinkVars
