const patchFormatter = (obj, path) => {
  const array = Object.keys(obj).map((item) => {
    if (typeof (obj[item]) === 'object') {
      return patchFormatter(obj[item], `${path}.${item}`)
    }
    return {
      operation: 'update',
      path: `${path}.${item}`,
      value: obj[item]
    }
  })
  return array.flat()
}

export default patchFormatter
