<template>
  <div
    :id="panelId"
    :class="[
      'sidebar-content',
      { 'is-active': isActive},
    ]"
    role="tabpanel"
    :aria-labelledby="headerId"
    :aria-hidden="!isActive"
  >
    <h1
      v-if="headline"
      class="sidebar-content_headline"
    >
      {{ headline }}
    </h1>
    <!-- eslint-disable vue/no-v-html -->
    <!-- eslint-disable-next-line vuejs-accessibility/click-events-have-key-events -->
    <div
      class="sidebar-content_message"
      @click="onContentClick"
      @keyup.enter.space="onContentClick"
      v-html="content"
    />
    <!-- eslint-enable vue/no-v-html -->
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true
    },
    headerId: {
      type: String,
      default: ''
    },
    headline: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      required: true
    },
    selected: {
      type: Boolean,
      default: false
    },
    backToHeaders: {
      type: Boolean,
      default: false
    },
    isMobile: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      isActive: false
    }
  },
  computed: {
    panelId() {
      return `panel-${this.headerId}`
    }
  },
  watch: {
    isMobile(newVal) {
      if (newVal) {
        this.isActive = false
      }
    },
    backToHeaders() {
      this.isActive = false
    }
  },
  beforeMount() {
    this.isActive = this.isMobile ? false : this.selected
  },
  mounted() {
    this.$emit('tab-content-mounted', this)
  },
  beforeUpdate() {
    if (this.isActive) {
      this.$emit('before-tab-active', this.id)
    }
  },
  methods: {
    onContentClick(event) {
      this.$emit('content-click', event)
    }
  }
}
</script>
