import ApiService from '@/api/'

export default {
  get(userId, serviceLocationId, isNewProductApiServices, payload) {
    let apiPath = '/api/v4.0/user/products'
    const apiVersion = payload.apiVersion || 'v5.0'
    delete payload.apiVersion
    if (payload.semanticSearch) {
      apiPath = `/api/v7.0/products/${userId}/${serviceLocationId}`
    } else if (isNewProductApiServices) {
      apiPath = `/api/${apiVersion}/products/${userId}/${serviceLocationId}`
    }
    return ApiService.get(apiPath, {
      params: payload
    })
  },
  getProducts(userId, serviceLocationId, isNewProductApiServices, payload) {
    let apiPath = '/api/v4.0/user/products'
    if (isNewProductApiServices) {
      apiPath = `/api/v5.0/products/${userId}/${serviceLocationId}`
    }
    return ApiService.get(apiPath, {
      params: {
        circularId: payload.id,
        sort: 'bestMatch+asc,+name+asc',
        rows: payload.rows,
        start: payload.start,
        flags: payload.flags,
        filter: payload.filter,
        extendedInfo: payload.extendedInfo,
        nutrition: payload.nutrition,
        substitute: payload.substitute
      }
    })
  },
  submitProductRequest({
    description, consumerCatId, size, serviceLocationId
  }) {
    return ApiService.post(
      `/api/v4.0/user/product-request`,
      {
        description, consumerCatId, size, serviceLocationId
      }
    )
  },
  getProductsById(userId, serviceLocationId, isNewProductApiServices, payload) {
    const { ids, params } = payload
    let apiPath = `/api/v4.0/user/products/${ids.join(',')}`
    if (isNewProductApiServices) {
      apiPath = `/api/v5.0/products/info/${userId}/${serviceLocationId}/${ids.join(',')}`
    }
    return ApiService.get(apiPath, { params })
  }
}
