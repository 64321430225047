<template>
  <button
    id="back-button"
    class="modal_back"
    aria-label="Back"
    @click="backButtonClick"
    @keydown.tab.shift.prevent="focusOnLastElement"
  >
    <PdlIconBase
      icon-title="Back"
      :stroke-color="strokeColor"
      container-class="modal_left-icon"
    >
      <PdlIconCarrotLeft />
    </PdlIconBase>
  </button>
</template>

<script>
import {
  PdlIconBase,
} from '@/shared/icons'
import PdlIconCarrotLeft from 'components/icons/icon/PdlIconCarrotLeft'

export default {
  components: {
    PdlIconBase,
    PdlIconCarrotLeft
  },
  props: {
    defaultView: {
      type: String,
      default: ''
    },
    strokeColor: {
      type: String,
      default: 'black'
    }
  },
  methods: {
    backButtonClick() {
      this.$emit('change-view', this.defaultView)
    },
    focusOnLastElement() {
      const modalContainer = document.getElementsByClassName('modal_container')[0]
      const focusableElements = modalContainer
        .querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])')
      if (focusableElements[focusableElements.length - 1]?.disabled) {
        if (focusableElements[focusableElements.length - 2]) {
          focusableElements[focusableElements.length - 2].focus()
        }
      } else if (focusableElements[focusableElements.length - 1]) {
        focusableElements[focusableElements.length - 1].focus()
      }
    }
  }
}
</script>
