<template>
  <div>
    <transition
      :name="transitionType"
    >
      <aside
        v-if="component.fileName"
        :class="[
          modalStyle,
          {
            'modal_base modal_selector': !activeComponent.isPrintView,
            'print-view_wrapper': activeComponent.isPrintView,
          },
        ]"
        :aria-hidden="isAlertPresent"
        @click="clearModals($event)"
      >
        <component
          :is="component.fileName"
          v-bind="activeComponent.props"
          ref="modal"
        />
      </aside>
    </transition>
    <div
      v-if="component.fileName"
      class="modal_overlay"
    />
  </div>
</template>

<script>
import { defineAsyncComponent, markRaw } from 'vue'
import {
  mapGetters, mapState, mapMutations, mapActions
} from 'vuex'
import { MODAL_TYPE_TRACKING_LOCATION } from 'utils/constants/modal-mapping'
import trackVirtualPageView from 'utils/tracking/nav/trackVirtualPageView'

const MODAL_CLASS = 'modal_selector'

export default {
  data: () => ({
    isLoading: false,
    component: {},
    additionalClasses: ''
  }),
  computed: {
    ...mapState({
      alert: state => state.Alert.alert,
      weeklyAdOffer: state => state.WeeklyAdPDL.selectedWeeklyAdProduct,
    }),
    ...mapGetters({
      isDesktop: 'ScreenSize/isDesktop',
      activeComponent: 'Modals/activeComponent',
      weeklyAdProduct: 'WeeklyAd/selectedWeeklyAdProduct',
      workingOrderBasketId: 'Cart/getWorkingOrderBasketId',
      userInfo: 'UserProfile/userInfo'
    }),
    isAlertPresent() {
      return this.alert?.active
    },
    transitionType() {
      return this.isDesktop ? 'fade' : 'slideFromBottom'
    },
    modalStyle() {
      return this.activeComponent?.style || ''
    }
  },
  watch: {
    activeComponent: {
      handler(modal) {
        if (this.userInfo) {
          this.toggleModal(modal)
        }
      },
      immediate: true,
    },
    userInfo(currentUserInfo, prevUserInfo) {
      // Handle modals that should open on page load.
      // Wait until user profile set before opening.
      const userInfoInit = prevUserInfo === null
      if (userInfoInit && this.activeComponent) {
        this.toggleModal(this.activeComponent)
      }
    }
  },
  methods: {
    ...mapActions({
      cleanUpData: 'Modals/cleanUpData',
      setPrimaryCart: 'Cart/setPrimaryCart'
    }),
    ...mapMutations({
      clearActiveModal: 'Modals/clearActiveModal'
    }),
    async clearModals(event) {
      const elClasses = Array.from(event.target.classList)
      if (elClasses.includes(MODAL_CLASS)) {
        this.component = null
        const modalName = this.$store.getters['Modals/activeComponent']?.fileName
        // need to clean up or implement specific behavior with new Mktplc edit order flow modals
        if (modalName === 'NonEndemicAdsModal') {
          this.$refs.modal.closeModal()
          return
        }
        this.cleanUpData(modalName)
        if (modalName === 'PdlEditOrderConfirmationModal') {
          // need to close all modals instead of active modal in this case
          const payload = {
            basketId: this.workingOrderBasketId,
            queryCart: false
          }
          await this.setPrimaryCart(payload)
          window.sessionStorage.setItem('continue_edit_pending_cart', false)
          this.$store.dispatch('Modals/clear')
          this.$router.push('/home')
          setTimeout(() => {
            window.location.reload()
          }, 200)
        } else {
          // responsible for hanlding the time slots modal when clicked outside
          if (modalName === 'PdlServiceSelectionModal') {
            this.$store.dispatch('Slots/resetSelectedSlotInfo')
          }
          this.clearActiveModal()
        }
        document.body.classList.remove('hidden-overflow')
        if (this.additionalClasses) {
          document.body.classList.remove(this.additionalClasses)
          this.additionalClasses = ''
        }
      }
    },
    getModalLocation(modal) {
      let location = ''
      if (modal.fileName === 'PdlWeeklyAdDetailsModal') {
        location = `modal/weekly-ad-details/${this.weeklyAdProduct.custom_id_field_3}`
      }
      if (modal.fileName === 'WeeklyAdDetailsModal') {
        location = `modal/weekly-ad-details/${this.weeklyAdOffer.circularId}`
      } else if (MODAL_TYPE_TRACKING_LOCATION[modal.fileName]) {
        location = MODAL_TYPE_TRACKING_LOCATION[modal.fileName]
      }
      return location
    },
    trackModal(modal) {
      const modalName = modal.fileName.replace(/([A-Z])/g, ' $1').replace('Pdl', '').trim()
      const modalLocation = this.getModalLocation(modal)
      trackVirtualPageView({
        pageTitle: modalName,
        pageURL: modalLocation
      })
    },
    toggleModal(modal) {
      if (!modal) {
        this.component = {}
        document.body.classList.remove('hidden-overflow')
        if (this.additionalClasses) {
          document.body.classList.remove(this.additionalClasses)
          this.additionalClasses = ''
        }
        return
      }
      const { fileName, additionalClasses } = modal
      const sameModal = this.component?.itemId && this.component?.itemId === modal.itemId
      if (!sameModal) {
        // eslint-disable-next-line
        this.component.fileName  = markRaw(defineAsyncComponent(() => import('./type/' + fileName + '.vue')))
        this.trackModal(modal)
        document.body.classList.add('hidden-overflow')
        if (additionalClasses) {
          document.body.classList.add(additionalClasses)
          this.additionalClasses = additionalClasses
        }
      }
    }
  }
}
</script>
