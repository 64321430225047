/* eslint-disable no-useless-catch */
import couponAPI from 'api/couponApi'
import ProductsApi from 'api/ProductsAPI'
import sortAlphabetically from 'utils/filters/sortAlphabetically'
import { trackCouponSearch } from 'utils/tracking/trackCouponSearch'
import { logErrorToClient } from 'utils/services/logging/genericExceptionLog'

const getCouponCallStatus = (response, version) => {
  // the OR in this check is in case the request completed but data was not ready
  // wait for retry timer and a success before hitting the block below
  if (version === 'v7.0') {
    return response.status === 200
  }

  return ((response.data.storeCouponsLoaded && response.data.storeCouponsLoadable)
    || (!response.data.storeCouponsLoaded && !response.data.storeCouponsLoadable))
}

export default {
  namespaced: true,
  state: {
    brandCoupons: [], // all coupons (clipped and browse) combined
    couponDetailLoaded: false,
    couponDetailExpired: false,
    clippedCoupons: [], // clipped coupons
    nonClippedCoupons: [], // non clipped coupons
    clippedBrandLength: 0,
    clippedCouponsReady: false,
    nonClippedBrandLength: 0,
    isFailed: false,
    dataLoaded: false,
    dataInitialized: false,
    APITimeout: false,
    timerStarted: false,
    deepLinkingActive: false,
    deepLinkingIds: [],
    missingDeepLinkingIds: [],
    timer: () => { },
    defaultQueryInterval: 2000,
    defaultQueryDuration: 11000,
    retrievalLimit: 60,
    queryRows: 50,
    sortSelection: 'Recommended',
    sortOptions: [
      {
        title: 'Recommended',
        value: 'Recommended',
        order: 'desc',
        name: 'targeted'
      },
      {
        title: 'Expiration Date',
        value: 'Expiration Date',
        order: 'asc',
        name: 'endDate'
      },
      {
        title: 'Value (High to Low)',
        value: 'Value (High to Low)',
        order: 'desc',
        name: 'maxDiscount'
      },
      {
        title: 'Brand (A to Z)',
        value: 'Brand',
        order: 'asc',
        name: 'brand'
      }
    ],
    couponDetailID: '',
    couponDetailData: {},
    qualifyingProducts: [],
    qualifyingProductsStart: 0,
    qualifyingProductsFailedToFetch: false,
    couponRetrievalStart: 0,
    totalQualifyingProducts: 0,
    totalBrowse: 0,
    totalClipped: 0,
    categoryFilters: [],
    selectedCategories: [],
    printCategoryNames: [],
    couponSearchData: [],
    clippedCouponsArr: [], // temporary, recently clipped coupon ids, changes display when you clip
    currentTab: 'browse',
    showTargetedCoupons: false,
    couponsLoading: true,
    couponAPIError: false,
    viewStoreName: 'Coupons',
    filterSearchQuery: '',
    keyword: '',
    searchId: '',
    digitalCouponsLabelViewedThisSession: false,
    totalBrowseUI: 0
  },
  getters: {
    sortSelection: state => state.sortSelection,
    sortOptions: state => state.sortOptions,
    isClippedTab: state => state.currentTab === 'clipped',
    isBrowseTab: state => state.currentTab === 'browse',
  },
  mutations: {
    setBrandCoupons(state, payload) {
      if (payload?.loaded !== undefined) {
        if (payload.loaded === true) {
          state.clippedCoupons = payload.coupons
        } else {
          state.nonClippedCoupons = payload.coupons
        }
        state.brandCoupons = [...state.clippedCoupons, ...state.nonClippedCoupons]
      } else {
        state.brandCoupons = payload.coupons
      }
    },
    setCurrentTab(state, payload) {
      state.currentTab = payload
    },
    setSortSelection(state, payload) {
      state.sortSelection = payload
    },
    setTargetedCoupons(state, payload) {
      state.showTargetedCoupons = payload
    },
    setCouponDetailData(state, payload) {
      state.couponDetailData = payload
    },
    setQualifyingProduct(state, payload) {
      const moreQualifyingProducts = [...state.qualifyingProducts, ...payload]
      state.qualifyingProducts = moreQualifyingProducts
    },
    setCouponSearchData(state, payload) {
      state.couponSearchData = payload
    },
    increaseProductsStart(state) {
      state.qualifyingProductsStart += state.queryRows
    },
    setQualifyingProductsStartBack(state) {
      state.qualifyingProductsStart = 0
    },
    increaseCouponRetrievalStart(state) {
      state.couponRetrievalStart += state.retrievalLimit
    },
    setCouponRetrievalStartBack(state) {
      state.couponRetrievalStart = 0
      state.clippedCouponsArr = []
    },
    setCouponRetrievalStart(state, payload) {
      state.couponRetrievalStart = payload
      state.clippedCouponsArr = []
    },
    moreClippedCoupons(state, payload) {
      const merge = [...state.clippedCoupons, ...payload]
      state.clippedCoupons = merge
      state.brandCoupons = [...state.clippedCoupons, ...state.nonClippedCoupons]
    },
    moreBrowseCoupons(state, payload) {
      const merge = [...state.nonClippedCoupons, ...payload]
      state.nonClippedCoupons = merge
      state.brandCoupons = [...state.clippedCoupons, ...state.nonClippedCoupons]
    },
    resetQualifyingProducts(state) {
      state.qualifyingProducts = []
    },
    setDataLoaded(state, payload) {
      state.dataLoaded = payload
    },
    setDataInitialized(state, payload) {
      state.dataInitialized = payload
    },
    setCouponsLoading(state, payload) {
      state.couponsLoading = payload
    },
    setAPITimeout(state, payload) {
      state.APITimeout = payload
    },
    setTimerStarted(state) {
      state.timerStarted = true
    },
    setPrintCategoryNames(state, payload) {
      const categoryTreeId = payload
      if (categoryTreeId?.length !== undefined) {
        const names = categoryTreeId.map(({ name }) => name)
        state.printCategoryNames = names
      }
    },
    clearFilter(state) {
      state.categoryFilters = state.categoryFilters.map((category) => {
        category.active = false
        return category
      })
    },
    setViewStoreName(state, payload) {
      state.viewStoreName = payload
    },
    setCategoryFilters: (state, payload) => {
      const { categoryTreeId } = payload
      if (categoryTreeId) {
        const categories = categoryTreeId.map(category => ({ ...category, active: false }))
        const sortedCategories = categories.sort(sortAlphabetically('name'))
        state.categoryFilters = sortedCategories
      }
    },
    addClippedCoupon(state, payload) {
      const brandIndex = state.brandCoupons.findIndex(coupon => coupon.id === payload.id)
      if (brandIndex > -1) {
        state.brandCoupons[brandIndex].loaded = true
        state.brandCoupons[brandIndex].browsing = true
      }
      state.clippedCoupons.push(payload)
      state.clippedCouponsArr.push(payload.id)
      // eslint-disable-next-line max-len
      if (!state.printCategoryNames.includes(payload.topCategoryTreeName) && payload.topCategoryTreeName !== undefined) {
        state.printCategoryNames.push(payload.topCategoryTreeName)
      }
      state.totalClipped += 1
      state.totalBrowseUI -= 1
    },
    setFilterActive: (state, payload) => {
      state.categoryFilters[payload.obj.value].active = payload.obj.checked
    },
    setCouponDetailLoaded(state, payload) {
      state.couponDetailLoaded = payload
    },
    setTimerFunc(state, payload) {
      state.timer = payload
    },
    setTotalQualifyingProducts(state, payload) {
      state.totalQualifyingProducts = payload
    },
    setTotalCoupons(state, payload) {
      if (payload.loaded) {
        state.totalClipped = payload.length
      } else {
        state.totalBrowse = payload.length
        state.totalBrowseUI = state.totalBrowse
      }
    },
    setCouponDetailExpired(state, payload) {
      state.couponDetailExpired = payload
    },
    setCouponAPIError(state, payload) {
      state.couponAPIError = payload
    },
    shiftCoupons(state) {
      const couponList = state.deepLinkingIds
      if (state.currentTab === 'browse') {
        couponList.forEach((couponId) => {
          const index = state.nonClippedCoupons.findIndex(coupon => coupon.id === couponId)
          if (index === -1) return
          const couponState = { ...state.nonClippedCoupons[index] }
          state.nonClippedCoupons.splice(index, 1)
          state.nonClippedCoupons.unshift(couponState)
        })
      } else {
        couponList.forEach((couponId) => {
          const index = state.clippedCoupons.findIndex(coupon => coupon.id === couponId)
          if (index === -1) return
          const couponState = { ...state.clippedCoupons[index] }
          state.clippedCoupons.splice(index, 1)
          state.clippedCoupons.unshift(couponState)
        })
      }
    },
    configureDeepLinking(state, payload) {
      state.deepLinkingActive = true
      state.deepLinkingIds = payload
    },
    stopDeepLinking(state) {
      state.deepLinkingIds = []
      state.deepLinkingActive = false
    },
    setMissingDeepLinkingIds(state, payload) {
      state.missingDeepLinkingIds = payload
    },
    setFilterSearchQuery(state, payload) {
      state.filterSearchQuery = payload
    },
    setKeyword(state, payload) {
      state.keyword = payload
    },
    setSearchId(state, payload) {
      state.searchId = payload
    },
    setDigitalCouponsLabelViewedThisSession(state, payload) {
      state.digitalCouponsLabelViewedThisSession = payload
    },
    setClippedCouponsReady(state, payload) {
      state.clippedCouponsReady = payload
    },
    setQualifyingProductsFailedToFetch(state, payload) {
      state.qualifyingProductsFailedToFetch = payload
    }
  },
  actions: {
    async filterBrandCoupons({
      commit, state, dispatch, rootGetters, getters
    }, payload = {}) {
      const userId = rootGetters['UserProfile/userId']
      const couponVersion = rootGetters['SiteConfig/varByName']('coupon_version')
      const serviceLocationId = rootGetters['UserProfile/serviceLocationId']
      const cardNumber = rootGetters['UserProfile/retailerCardNumber']
      const isLoyaltyRegistered = rootGetters['LoyaltyAccount/isLoyaltyRegistered']

      const {
        couponRetrievalStart, retrievalLimit, sortSelection, showTargetedCoupons, categoryFilters, keyword, searchId
      } = state
      const [sortSelectionObj] = state.sortOptions.filter(sort => sort.value === sortSelection)
      const targeted = (keyword !== '' || showTargetedCoupons)

      // only request loaded coupons if the user is authenticated
      // cancel if unauth and only make browse calls on browse tab
      if (!isLoyaltyRegistered && (payload.loaded === true || getters.isClippedTab)) {
        return
      }

      if (payload.reloadTabs) {
        commit('setCouponsLoading', true)
      }

      const apiParams = {
        sortSelection: sortSelectionObj,
        loadable: true,
        loaded: payload.loaded === undefined ? null : payload.loaded,
        targeted,
        sourceSystems: ['QUO', 'COP', 'INM'],
        selectedCategories: categoryFilters,
        start: payload.start || couponRetrievalStart,
        size: payload.limit || retrievalLimit,
        keywords: keyword === '' ? null : keyword,
        id: searchId === '' ? null : searchId,
      }
      try {
        const coupons = await couponAPI.getCoupons(userId, serviceLocationId, cardNumber, couponVersion, apiParams)
        const dataStatus = getCouponCallStatus(coupons, couponVersion)
        if (dataStatus) {
          dispatch('handleCouponRetrievalSuccess', { loaded: payload.loaded, coupons, reloadTabs: payload.reloadTabs })
        }
      } catch (e) {
        dispatch('handleCouponRetrievalError', { loaded: payload.loaded })
      } finally {
        commit('setCouponsLoading', false)
      }
    },
    async setCouponDetail({ commit, state, dispatch }, payload) {
      commit('setQualifyingProductsFailedToFetch', false)
      if (state.deepLinkingActive) {
        commit('shiftCoupons')
      }
      await dispatch('getQualifyingProductsForCoupon', payload)
    },
    async setSlCouponDetail({ commit, dispatch }, payload) {
      // A separate method is needed for opening the coupon modal from SL
      // because state.brandCoupons may be uninitialized on this page.
      // Also, there is no need to find the coupon data since all coupon
      // data is retrieved from the SL API and passed in the payload.
      // NOTE: Coupon data from the SL API is different from the coupon
      // data from the search API.
      commit('resetQualifyingProducts')
      commit('setCouponDetailData', payload)
      await dispatch('fetchQualifyingProducts', payload)
      commit('setCouponDetailLoaded', true)
    },
    async fetchCouponDetailsApi({
      commit, state, rootGetters, dispatch
    }, payload) {
      const userId = rootGetters['UserProfile/userId']
      const { serviceLocationId } = rootGetters['UserProfile/deliveryServiceLocation']
      const couponVersion = rootGetters['SiteConfig/varByName']('coupon_version')
      try {
        const cardNumber = rootGetters['UserProfile/retailerCardNumber']
        // eslint-disable-next-line
        const couponResponse = await couponAPI.getFullCouponDetails(userId, serviceLocationId, payload.id, cardNumber, couponVersion)
        commit('setCouponDetailData', couponResponse.data.coupons[0])
        if (state.deepLinkingActive) {
          if (couponResponse.data.coupons[0].loaded) {
            commit('moreClippedCoupons', couponResponse.data.coupons)
          } else {
            commit('moreBrowseCoupons', couponResponse.data.coupons)
          }
          commit('shiftCoupons')
        }
        await dispatch('fetchQualifyingProducts', payload)
      } catch (e) {
        if (payload.isAddItemModal) {
          window.sharedVue.config.globalProperties.$store.commit('Alert/setAlert', {
            icon: false,
            type: 'error',
            header: 'Details not loading',
            body: `We're experiencing trouble retrieving the details of this offer. Please try again later.`,
            primary: {
              text: 'Ok',
              callback: () => {
                window.sharedVue.config.globalProperties.$store.commit('Alert/clear')
                window.sharedVue.config.globalProperties.$store.commit('Modals/clearActiveModal')
              }
            },
          })
        } else {
          commit('setCouponDetailExpired', true)
        }
      } finally {
        commit('setCouponDetailLoaded', true)
      }
    },
    async fetchQualifyingProducts({ commit, rootGetters }, payload) {
      const userId = rootGetters['UserProfile/userId']
      const { serviceLocationId } = rootGetters['UserProfile/deliveryServiceLocation']
      payload.isNewProductApiServices = rootGetters['SiteConfig/varByName']('feature_category_product_new_services')
      const qualifyingProductsData = await ProductsApi.getQualifyingProducts(
        userId, serviceLocationId, payload.id, payload
      )
      const success = qualifyingProductsData.status === 200
      if (!success) {
        throw qualifyingProductsData
      }
      if (payload.start === 0 || payload.start === undefined) {
        commit('setTotalQualifyingProducts', qualifyingProductsData.data.response.pagination.total)
      }
      commit('setQualifyingProduct', qualifyingProductsData.data.response.products)
    },
    async handleCouponRetrievalSuccess({
      commit, state, dispatch, getters
    }, payload) {
      dispatch('clearTimer')
      const { categoryFilters, searchId, keyword } = state
      const { loaded, coupons, reloadTabs } = payload
      const brandCoupons = coupons.data.coupons
      const { categoryTreeId = {} } = coupons.data.facets
      commit('setBrandCoupons', { coupons: brandCoupons, loaded })
      commit('setTotalCoupons', { length: coupons.data.paging.total, loaded })
      if (reloadTabs && coupons.data.facets) {
        commit('setCategoryFilters', coupons.data.facets)
      }
      // ensure the tab being viewed is the one saying it is loaded
      if ((loaded && getters.isClippedTab) || (!loaded && getters.isBrowseTab)) {
        commit('setDataLoaded', true)
        commit('setDataInitialized', true)
      }
      if (loaded) {
        commit('setPrintCategoryNames', categoryTreeId)
        commit('setClippedCouponsReady', true)
      }
      if (keyword === '' && searchId === '' && categoryFilters.length === 0) {
        commit('setFilterSearchQuery', '')
      }
      // ensure the tab being viewed is loaded
      if ((loaded && getters.isClippedTab) || (!loaded && getters.isBrowseTab)) {
        commit('setCouponAPIError', false)
      }
    },
    async handleCouponRetrievalError({
      commit, getters
    }, payload) {
      if ((payload.loaded && getters.isClippedTab) || (!payload.loaded && getters.isBrowseTab)) {
        commit('setDataLoaded', false)
        commit('setCouponAPIError', true)
      }
      commit('setCouponAPIError', true)
      commit('setClippedCouponsReady', true)
      commit('setCouponsLoading', false)
    },
    // eslint-disable-next-line
    clearInitialFilters({ commit }) {
      commit('clearFilter')
      commit('setFilterSearchQuery', '')
      commit('setKeyword', '')
      commit('setSearchId', '')
      commit('setTargetedCoupons', false)
    },
    async retrieveCarouselSet({
      state, commit, dispatch
    }, payload) {
      const params = {
        limit: payload.limit,
        sourceSystems: ['QUO', 'COP', 'INM'],
      }
      if (payload.sortByMax === true) {
        commit('setSortSelection', 'Value (Low to High)')
      } else {
        commit('setSortSelection', 'Recommended')
      }
      commit('setTargetedCoupons', payload.targeted)
      await dispatch('filterBrandCoupons', params)
      let activeTab = 'browse'
      let returnValue
      if (payload.type === 'Unclipped') {
        returnValue = state.nonClippedCoupons
      }
      if (payload.type === 'Clipped') {
        activeTab = 'clipped'
        returnValue = state.clippedCoupons
      }
      commit('setCurrentTab', activeTab)
      return returnValue || state.brandCoupons
    },
    async clipCoupon({
      commit, dispatch, rootGetters
    }, payload) {
      // Need to convert ID to GUID to load in Weekly Ad.
      // Weekly Ad has its own set of clipped coupons because Flipp uses the GUID, not QUO_ id.
      const guid = rootGetters['WeeklyAd/guidLookupByCircularId'][payload.coupon.circularId]
      const showWeeklyAd = rootGetters['SiteConfig/varByName']('feature_weekly_ad')
      const showCouponsOnAd = rootGetters['SiteConfig/varByName']('feature_weekly_ad_coupons')
      const showCouponsOnWeeklyAd = showWeeklyAd && showCouponsOnAd
      const userId = rootGetters['UserProfile/userId']

      try {
        await couponAPI.loadCoupon(userId, payload)
        commit('addClippedCoupon', payload.coupon)
        commit('CouponTotal/addToCouponTotalValue', payload.coupon, { root: true })
        if (showCouponsOnWeeklyAd && guid) {
          commit('WeeklyAd/setCouponAsLoaded', guid, { root: true })
        }
        // For adding clipped coupons to shopping-list
        const getVarByName = this.getters['SiteConfig/varByName']
        const couponAddToListEnabled = getVarByName(('feature_coupons_shopping_list'))
        if (couponAddToListEnabled) {
          dispatch('ShoppingList/handleAddClippedCouponsToSl', payload.coupon, { root: true })
        }
        return { error: false }
      } catch (e) {
        return { error: true }
      }
    },
    clearFilterSelections({ commit, getters, dispatch }) {
      commit('clearFilter')
      commit('setTargetedCoupons', false)
      commit('setCouponRetrievalStartBack')
      commit('setKeyword', '')
      commit('setSearchId', '')
      dispatch('filterBrandCoupons', { loaded: getters.isClippedTab, limit: undefined })
    },
    clearFilterPill({ commit, getters, dispatch }, payload) {
      commit('setCouponRetrievalStartBack')
      commit('setFilterActive', payload)
      dispatch('filterBrandCoupons', { loaded: getters.isClippedTab, limit: undefined })
    },
    applySelectedSort({ commit, getters, dispatch }, payload) {
      commit('setSortSelection', payload)
      commit('setCouponRetrievalStartBack')
      dispatch('filterBrandCoupons', { loaded: getters.isClippedTab, limit: undefined })
    },
    selectFilter({ commit, getters, dispatch }, payload) {
      commit('setFilterActive', payload)
      commit('setCouponRetrievalStartBack')
      dispatch('filterBrandCoupons', { loaded: getters.isClippedTab, limit: undefined })
    },
    removeCouponDetail({ commit }) {
      commit('setCouponDetailData', {})
      commit('setCouponDetailExpired', false)
      commit('setCouponDetailLoaded', false)
      commit('setQualifyingProductsStartBack')
      commit('resetQualifyingProducts')
      commit('stopDeepLinking')
    },
    setCouponSearch({ commit }, payload) {
      commit('setCouponSearchData', payload)
    },
    initiateRetrieval({
      commit, state, dispatch, getters, rootGetters
    }) {
      const couponVersion = rootGetters['SiteConfig/varByName']('coupon_version')

      dispatch('filterBrandCoupons', {
        reloadTabs: !getters.isClippedTab,
        start: 0,
        loaded: false
      })
      dispatch('filterBrandCoupons', {
        reloadTabs: getters.isClippedTab,
        start: 0,
        loaded: true
      })
      if (state.dataLoaded !== true && !state.timerStarted && couponVersion === 'v6.0') {
        commit('setTimerStarted')
        dispatch('startQueryTimer')
      }
    },
    async fetchClippedCoupons({ dispatch }) {
      await dispatch('filterBrandCoupons', {
        reloadTabs: false,
        start: 0,
        loaded: true
      })
    },
    clearTimer({ state }) {
      clearInterval(state.timer)
    },
    startQueryTimer({
      state, commit, dispatch, rootGetters, getters
    }) {
      const configQueryTimeoutInterval = rootGetters['SiteConfig/varByName']('config_query_timeout_interval')
      const configQueryDuration = rootGetters['SiteConfig/varByName']('config_query_timeout_duration')
      const queryInterval = configQueryTimeoutInterval || state.defaultQueryInterval
      const queryDuration = configQueryDuration || state.defaultQueryDuration
      const dispatchRetrieve = () => {
        dispatch('filterBrandCoupons', { reloadTabs: !getters.isClippedTab, loaded: false })
        dispatch('filterBrandCoupons', { reloadTabs: getters.isClippedTab, loaded: true })
      }
      const timerFunc = setInterval(dispatchRetrieve, queryInterval)
      commit('setTimerFunc', timerFunc)
      setTimeout(() => {
        commit('setAPITimeout', true)
        dispatch('clearTimer')
        commit('setCouponAPIError', !state.dataLoaded)
      }, queryDuration)
    },

    async retrieveMore({
      state, commit, getters, rootGetters
    }) {
      commit('increaseCouponRetrievalStart')
      const userId = rootGetters['UserProfile/userId']
      const couponVersion = rootGetters['SiteConfig/varByName']('coupon_version')
      const { serviceLocationId } = rootGetters['UserProfile/deliveryServiceLocation']
      const { sortSelection, showTargetedCoupons, categoryFilters } = state
      const cardNumber = rootGetters['UserProfile/retailerCardNumber']
      const [sortSelectionObj] = state.sortOptions.filter(sort => sort.value === sortSelection)
      const apiParams = {
        sortSelection: sortSelectionObj,
        loadable: true,
        loaded: getters.isClippedTab,
        targeted: showTargetedCoupons,
        sourceSystems: ['QUO', 'COP', 'INM'],
        selectedCategories: categoryFilters,
        start: state.couponRetrievalStart - state.clippedCouponsArr.length,
        size: state.retrievalLimit
      }
      try {
        const coupons = await couponAPI.getCoupons(userId, serviceLocationId, cardNumber, couponVersion, apiParams)
        if (state.deepLinkingIds.length > 0 && state.deepLinkingActive) {
          state.deepLinkingIds.forEach((couponId) => {
            const index = coupons.data.coupons.findIndex(coupon => coupon.id === couponId)
            if (index !== -1) {
              coupons.data.coupons.splice(index, 1)
            }
          })
        }
        if (getters.isClippedTab) {
          commit('moreClippedCoupons', coupons.data.coupons)
          if (state.deepLinkingActive && state.clippedCoupons.length === state.totalClipped) {
            commit('stopDeepLinking')
          }
        } else {
          commit('moreBrowseCoupons', coupons.data.coupons)
          if (state.deepLinkingActive && state.nonClippedCoupons.length === state.totalBrowse) {
            commit('stopDeepLinking')
          }
        }
        return { error: false }
      } catch (e) {
        return { error: true }
      }
    },
    async getCouponUsingCircularId({ rootGetters }, circularId) {
      if (circularId) {
        const payload = {
          circularId,
          includeExpired: true,
          size: 1
        }
        const couponVersion = rootGetters['SiteConfig/varByName']('coupon_version')
        const cardNumber = rootGetters['UserProfile/retailerCardNumber']
        const { serviceLocationId } = rootGetters['UserProfile/deliveryServiceLocation']
        const userId = rootGetters['UserProfile/userId']
        const response = await couponAPI.getCoupons(userId, serviceLocationId, cardNumber, couponVersion, payload)
        if (response.status === 200) {
          return response.data?.coupons[0] || {}
        }
      }

      return {}
    },
    async setCouponDetailUsingCircularId({ commit, dispatch }, payload) {
      if (payload.circularId && payload.coupon.id) {
        commit('setCouponDetailData', payload.coupon)
        await dispatch('fetchQualifyingProducts', payload.coupon)
        commit('setCouponDetailLoaded', true)
      } else {
        commit('setCouponDetailExpired', true)
      }
      commit('setCouponDetailLoaded', true)
    },
    checkForCoupons({ state, commit }, couponList) {
      const missingList = []
      couponList.forEach((couponId) => {
        const index = state.brandCoupons.findIndex(coupon => coupon.id === couponId)
        if (index === -1) {
          missingList.push(couponId)
        }
      })
      const allCouponsPresent = missingList.length === 0
      if (!allCouponsPresent) {
        commit('setMissingDeepLinkingIds', missingList)
      }
      return allCouponsPresent
    },
    async setMultiDeepLinking({
      commit, state, dispatch, rootGetters
    }, payload) {
      if (state.deepLinkingActive) {
        const couponsLoaded = await dispatch('checkForCoupons', payload)
        if (couponsLoaded) {
          commit('shiftCoupons')
        } else {
          const { userId } = rootGetters['UserProfile/userInfo'].information
          const cardNumber = rootGetters['UserProfile/retailerCardNumber']
          const couponVersion = rootGetters['SiteConfig/varByName']('coupon_version')

          const { serviceLocationId } = rootGetters['UserProfile/deliveryServiceLocation']
          // eslint-disable-next-line max-len
          const couponResponse = await couponAPI.getMultipleCouponsById(userId, serviceLocationId, state.missingDeepLinkingIds, cardNumber, couponVersion)
          couponResponse.data.coupons.forEach((coupon) => {
            if (coupon.loaded) {
              commit('moreClippedCoupons', [coupon])
            } else {
              commit('moreBrowseCoupons', [coupon])
            }
          })
          commit('shiftCoupons')
          commit('stopDeepLinking')
        }
      }
    },
    async retrieveAllForPrint({
      state, commit, getters, rootGetters
    }) {
      const userId = rootGetters['UserProfile/userId']
      const { serviceLocationId } = rootGetters['UserProfile/deliveryServiceLocation']
      const cardNumber = rootGetters['UserProfile/retailerCardNumber']
      const couponVersion = rootGetters['SiteConfig/varByName']('coupon_version')
      const { sortSelection, showTargetedCoupons } = state
      const [sortSelectionObj] = state.sortOptions.filter(sort => sort.value === sortSelection)
      const apiParams = {
        sortSelection: sortSelectionObj,
        loadable: true,
        loaded: true,
        targeted: showTargetedCoupons,
        sourceSystems: ['QUO', 'COP', 'INM'],
        start: state.clippedCoupons.length,
        size: state.totalClipped - state.clippedCoupons.length,
      }
      try {
        const coupons = await couponAPI.getCoupons(userId, serviceLocationId, cardNumber, couponVersion, apiParams)
        if (getters.isClippedTab) {
          commit('setCouponRetrievalStart', coupons.data.coupons.length)
        }
        commit('moreClippedCoupons', coupons.data.coupons)
        return { error: false }
      } catch (e) {
        return { error: true }
      }
    },

    async filterSearch({
      state, commit, getters, dispatch
    }, payload) {
      if (payload.searchType === 'category click' && !payload.payloadData.showSearchV2) {
        const obj = { ...payload.payloadData }
        commit('setFilterActive', obj)
      } else if (payload.searchTerm.startsWith('+')) {
        const id = payload.searchTerm.substring(1)
        commit('setSearchId', id)
      } else {
        commit('setKeyword', payload.searchTerm)
      }
      commit('setFilterSearchQuery', payload.searchTerm)
      await dispatch('filterBrandCoupons', {
        start: 0,
        size: 60,
        sourceSystems: ['QUO', 'COP', 'INM'],
        targeted: true,
        loadable: true,
        loaded: getters.isClippedTab
      })
      const results = getters.isClippedTab ? state.clippedCoupons : state.nonClippedCoupons
      const resultIds = results.map(coupon => coupon.id)

      trackCouponSearch({
        couponNumSearchResults: results.length,
        couponSearchItemsReturned: resultIds,
        couponSearchType: payload.searchType,
        searchTerm: payload.searchTerm,
        siteLocation: 'coupons',
        response: results.length > 0 ? 'success' : 'fail',
      })
    },
    setDigitalCouponsLabelViewedThisSession({ commit }, payload) {
      commit('setDigitalCouponsLabelViewedThisSession', payload)
    },
    async getQualifyingProductsForCoupon({ state, commit, dispatch }, payload) {
      const couponDetail = state.brandCoupons.filter(couponData => couponData.id === payload.id)
      if (!couponDetail.length) {
        dispatch('fetchCouponDetailsApi', payload)
      } else {
        try {
          commit('setCouponDetailData', couponDetail[0])
          await dispatch('fetchQualifyingProducts', payload)
        } catch (e) {
          commit('setQualifyingProductsFailedToFetch', true)
          logErrorToClient('error_fetching_qualifying_products_for_coupon', e)
        } finally {
          commit('setCouponDetailLoaded', true)
        }
      }
    }
  }
}
