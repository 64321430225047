<template>
  <div
    v-if="cutoffAlertFlag && secondsRemaining > 0"
    class="relative flex items-center justify-center p-2 font-semibold
      text-white text-center text-base bg-error z-[1040] md:p-0 md:text-xl md:h-[4.6rem]"
    role="dialog"
    tabindex="0"
  >
    <span>{{ displayText }}</span>
  </div>
</template>
<script>
import { mapMutations, mapState } from 'vuex'
import formatTimeRemaining from 'utils/filters/formatTimeRemaining'

export default {
  computed: {
    ...mapState({
      submitted: state => state.CutOff.submitted,
      secondsRemaining: state => state.CutOff.secondsRemaining,
      cutoffAlertFlag: state => state.CutOff.cutoffAlertFlag,
      cutOffStatus: state => state.CutOff.cutOffStatus,
      cutOffUpdateInProgress: state => state.CutOff.cutOffUpdateInProgress
    }),
    timeToDisplay() {
      if (this.secondsRemaining > 0) {
        return formatTimeRemaining(this.secondsRemaining)
      }
      return null
    },
    displayText() {
      if (this.submitted) {
        return `Time remaining to update order: ${this.timeToDisplay || ''}`
      }
      return `Check out within ${this.timeToDisplay || ''} to reserve your time.`
    }
  },
  watch: {
    cutOffStatus(newVal) {
      if (!this.cutOffUpdateInProgress) {
        this.setCutOffUpdateInProgress(true)
        if (newVal === 'workingCutOff') {
          this.$emit('working-cut-off')
        } else if (newVal === 'submittedCutOff') {
          this.$emit('submitted-cut-off')
        }
      }
    }
  },
  methods: {
    ...mapMutations({
      setCutOffUpdateInProgress: 'CutOff/setCutOffUpdateInProgress'
    })
  }
}
</script>
