import { GTM_GA4 } from 'utils/constants'
import { buildItemVariant } from 'utils/tracking/helpers'
import generateSiteLocation from 'utils/lib/generateSiteLocation'

const buildItemsListsForGA4Tagging = (products) => {
  const impressionItems = []
  products.forEach((product, index) => {
    const productName = product.description ? product.description : product.name
    const { coupon } = product
    const { price, regularPrice } = product
    const discount = price !== regularPrice ? (Math.round(regularPrice * 100) - Math.round(price * 100)) / 100 : null
    const flags = product.flags ? Object.keys(product?.flags).filter(key => product?.flags[key] === true) : null
    const itemVariant = buildItemVariant(product)?.join()
    const locationId = window.sharedVue.config.globalProperties.$store.getters[
      'UserProfile/deliveryServiceLocation'
    ].storeNumber
    impressionItems.push({
      item_id: product.prodId.toString(),
      item_name: productName,
      coupon: coupon ? coupon.id : null,
      currency: 'USD',
      discount: discount || null,
      index: index + 1,
      item_brand: product.brand || '',
      item_category: product.rootCatName,
      item_category2: product.subcatName,
      item_category5: flags?.length >= 1 ? flags.join() : null,
      item_list_id: 'Cart',
      item_list_name: 'Cart',
      item_variant: itemVariant,
      location_id: locationId,
      price: product.regularPrice,
      quantity: product.qty
    })
  })

  return impressionItems
}

export const trackBeginCheckout = (message) => {
  const vueInstance = window.sharedVue.config.globalProperties
  const route = vueInstance.$route
  const summaryData = vueInstance.$store.getters['Cart/getSummaryData']
  const cartBasketId = vueInstance.$store.getters['Cart/getBasketId']
  const cartItems = vueInstance.$store.getters['Cart/getCartItems']
  const promoCode = vueInstance.$store.getters['CartPaymentInformation/redeemCode'] || null
  const { substitutionsAllowed } = vueInstance.$store.state.Cart.cart

  const pickupData = _.findWhere(summaryData.lineItems, {
    description: 'Pickup Subtotal'
  })
  const deliveryData = _.findWhere(summaryData.lineItems, {
    description: 'Delivery Subtotal'
  })
  const fees = _.findWhere(summaryData.lineItems, {
    description: 'Fees'
  })
  const tax = _.findWhere(summaryData.lineItems, {
    description: 'Estimated Tax'
  })
  const deliveryTip = _.findWhere(summaryData.lineItems, {
    description: 'Delivery Driver Tip'
  })

  const value = (Math.ceil(((pickupData || deliveryData)?.amount || 0) * 100) / 100).toFixed(2)

  const ga4TagData = {
    value: +value,
    currency: 'USD',
    shipping: fees ? +fees.amount.toFixed(2) : 0,
    tax: tax ? +tax.amount.toFixed(2) : 0,
    ...(deliveryTip && { num_delivery_tip: deliveryTip.amount.toFixed(2) }),
    result: message,
    coupon: promoCode,
    basket_id: cartBasketId && cartBasketId !== '1' ? cartBasketId : null,
    substitutions: `cart level - ${substitutionsAllowed ? 'on' : 'off'}`,
    site_location: generateSiteLocation(route)
  }

  const itemDetails = buildItemsListsForGA4Tagging(cartItems)
  ga4TagData.items = itemDetails

  vueInstance.$trackGA4Event(GTM_GA4.beginCheckout, ga4TagData)
}
