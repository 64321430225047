export const initializeAccessibilityEvents = () => {
  document.addEventListener('keydown', (event) => {
    const enterKey = event.key === 'Enter' || event.code === 'Space' // code: 32 buttons can also use spacebar
    if (event.key === 'Tab') {
      document.documentElement.classList.add('tab-key--active')
    }
    if (enterKey && event.target?.dataset?.opensModal) {
      // remove class from previously focused in the case no modal was opened
      // example: skeleton tiles with buttons
      const previousFocused = document.getElementsByClassName('focus-on-modal-close')[0]
      if (previousFocused) {
        previousFocused.classList.remove('focus-on-modal-close')
      }
      event.target.classList.add('focus-on-modal-close')
    }
  })

  document.addEventListener('mousedown', (event) => {
    const opensModal = event.target?.dataset?.opensModal
    const parentOpensModal = event.target?.parentNode?.dataset?.opensModal
    if (document.documentElement.classList.contains('tab-key--active')) {
      document.documentElement.classList.remove('tab-key--active')
    }
    if (opensModal || parentOpensModal) {
      // remove class from previously focused in the case no modal was opened
      // example: skeleton tiles with buttons
      const previousFocused = document.getElementsByClassName('focus-on-modal-close')[0]
      if (previousFocused) {
        previousFocused.classList.remove('focus-on-modal-close')
      }
      if (opensModal) {
        event.target.classList.add('focus-on-modal-close')
      } else if (parentOpensModal) {
        event.target.parentNode.classList.add('focus-on-modal-close')
      }
    }
  })
}
