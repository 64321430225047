<template>
  <div :class="{ 'loader': !isHelpMenu}">
    <div
      class="loader_container"
      :class="{ 'loader_container--help-menu': isHelpMenu}"
    >
      <span
        :class="[
          'loader_spinner',
          { 'loader_spinner--themed': themed }
        ]"
      />
      <span
        v-if="themed"
        class="loader_circle"
      />
    </div>
    <span
      v-if="mask"
      class="loader_mask"
    />
  </div>
</template>

<script>
export default {
  props: {
    themed: {
      type: Boolean,
      default: false
    },
    mask: {
      type: Boolean,
      default: false
    },
    isHelpMenu: {
      type: Boolean,
      default: false
    }
  }
}
</script>
