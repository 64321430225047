import AutoLoginAPI from 'api/AutoLoginAPI'

const EXTERNAL_URLS = {
  DELI: 'deli',
  DEBUG: 'debug'
}

export const mapFooterItems = (contents) => {
  const items = []
  const isSavings = window.location.pathname.includes('/savings')
  contents.forEach((contentGroup) => {
    contentGroup.map.scheduledContentData.map.parts.myArrayList.forEach(
      (item, key) => {
        items.push({
          name: item.map['Headline Copy'],
          url: item.map['Button Link'],
          linkType: item.map['Button Link Type'],
          iconTitle: item.map['Headline Copy'],
          isActive:
            item.map['Headline Copy'] === 'Savings' && isSavings
              ? true
              : key === 0 && !isSavings,
        })
      }
    )
  })
  const index = items.findIndex(item => item.isActive)
  return { items, index }
}

export const mapMenuItems = (contents) => {
  const items = []
  contents.forEach((contentGroup) => {
    contentGroup.map.scheduledContentData.map.parts.myArrayList.forEach(
      (item) => {
        items.push({
          name: item.map['Headline Copy'],
          url: item.map['Button Link'],
          linkType: item.map['Button Link Type'],
        })
      }
    )
  })
  return items
}

export const handleDeliLink = async () => {
  await window.sharedVue.config.globalProperties.$store.dispatch('LoginStatus/getLoginStatus')
  await window.sharedVue.config.globalProperties.NativeContainer.capabilities.DelivisionService.launchDelivision()
  const isGuestUser = window.sharedVue.config.globalProperties.$store.getters['LoginStatus/isGuestUserWithNoHandle']

  if (isGuestUser) {
    window.sharedVue.config.globalProperties.$store.commit('Modals/setActiveModal', {
      fileName: 'PdlLoginFlow'
    })
  } else {
    await window.sharedVue.config.globalProperties.NativeContainer.capabilities.ScanItService.launchScanIt()
  }
}

export const handleDebugLink = async () => {
  if (window.sharedVue.config.globalProperties.NativeContainer.capabilities.DebugService) {
    await window.sharedVue.config.globalProperties.NativeContainer.capabilities.DebugService.openDebugScreen()
  }
}

export const cleanItemLegacyUrl = ({ url, linkType }) => {
  if (typeof url === 'string' && linkType === 'Modal') {
    return url.replace(/modal\/|\/modal\//, '')
  }
  return url
}

const handleRedirectUrl = async ({ url, fallbacks }) => {
  if (fallbacks?.ios && fallbacks?.android) {
    await window.sharedVue.config.globalProperties.NativeContainer.capabilities.ExternalLinkRedirectService
      .open(
        url,
        fallbacks.ios,
        fallbacks.android
      )
  } else {
    await window.sharedVue.config.globalProperties.NativeContainer.capabilities.ExternalLinkRedirectService
      .open(url)
  }
}

const handlePharmacyAppLink = ({ url, store }) => {
  const fallbackIos = store.getters['SiteConfig/varByName']('brand_pharmacy_ios_fallback_link')
  const fallbackAndroid = store.getters['SiteConfig/varByName']('brand_pharmacy_android_fallback_link')

  handleRedirectUrl({
    url,
    fallbacks: {
      ios: fallbackIos,
      android: fallbackAndroid
    }
  })
}

const handleScanItAppLink = async ({ url, store }) => {
  await window.sharedVue.config.globalProperties.$store.dispatch('LoginStatus/getLoginStatus')
  const featureEnabled = store.getters['SiteConfig/varByName']('feature_scan_it')
  const isGuestUser = store.getters['LoginStatus/isGuestUserWithNoHandle']
  const fallbackIos = store.getters['SiteConfig/varByName']('brand_scanit_ios_fallback_link')
  const fallbackAndroid = store.getters['SiteConfig/varByName']('brand_scanit_android_fallback_link')

  if (!featureEnabled) {
    handleRedirectUrl({
      url,
      fallbacks: {
        ios: fallbackIos,
        android: fallbackAndroid
      }
    })

    return
  }

  if (isGuestUser) {
    store.commit('Modals/setActiveModal', {
      fileName: 'PdlLoginFlow'
    })
  } else {
    await window.sharedVue.config.globalProperties.NativeContainer.capabilities.ScanItService.launchScanIt()
  }
}

export const handleExternalItemClick = async ({ item, store }) => {
  const {
    DELI, DEBUG
  } = EXTERNAL_URLS
  const PHARMACY_APP = store.getters['SiteConfig/varByName']('brand_pharmacy_app_link')
  const SCAN_IT_APP = store.getters['SiteConfig/varByName']('brand_scanit_app_link')
  const { url } = item
  const { currentServiceLocationId = '' } = appConfig?.user || {}
  const apiVersion = 'v3.0'
  await AutoLoginAPI.perform(apiVersion, currentServiceLocationId)

  switch (url) {
    case DELI:
      await handleDeliLink()
      break
    case DEBUG:
      await handleDebugLink()
      break
    case PHARMACY_APP:
      handlePharmacyAppLink({
        url,
        store
      })
      break
    case SCAN_IT_APP:
      handleScanItAppLink({
        url,
        store
      })
      break
    default:
      handleRedirectUrl({ url })
      break
  }
}
