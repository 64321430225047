import userPreferenceService from 'components/user/account/services/user-preference-services'
import { USER_PREFERENCE_KEYS } from 'utils/constants'

const isBusinessCustomer = async () => {
  const { $store } = window.sharedVue.config.globalProperties
  if (!$store) throw new Error('Store not available.') // Throw explicitly instead of accidentally later.

  const userInfo = $store.getters['UserProfile/information']
  if (!userInfo) {
    return
  }

  try {
    const resourceByName = $store.getters['SiteConfig/resourceByName']
    const userId = $store.getters['UserProfile/userId']
    const { value: merchAlert } = await userPreferenceService.get(
      USER_PREFERENCE_KEYS.MERCH_ALERT_SHOWN, userId
    )

    if (merchAlert && merchAlert.toLowerCase() === 'false' && userInfo.userType === 'M') {
      $store.commit('Alert/setAlert', {
        icon: 'none',
        type: 'warning',
        processAsHTML: true,
        header: resourceByName('BUSINESS_WELCOME_ALERT_HEADER'),
        body: resourceByName('BUSINESS_ALERT_WELCOME_MESSAGE'),
        primary: {
          text: 'Okay',
          callback: () => {
            userPreferenceService.update(USER_PREFERENCE_KEYS.MERCH_ALERT_SHOWN, 'TRUE', userId)
            $store.commit('Alert/clear')
          }
        }
      })
    }
  } catch (e) {
    throw new Error('There was a problem getting user preference')
  }
}

export default isBusinessCustomer
