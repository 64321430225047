<template>
  <div
    aria-live="assertive"
    :class="[
      'fixed flex flex-col items-start z-10 gap-10',
      alertStyle
    ]"
  >
    <ColorBarAlert
      v-for="(alert, index) of alerts"
      :key="index"
      :text="alert.text"
      :title="alert.title"
      :timeout="alert.timeout"
      @close="removeAlert(alert)"
    />
  </div>
</template>

<script>
import ColorBarAlert from 'components/ColorBarAlert'

export default {
  components: { ColorBarAlert },
  props: {
    alerts: {
      type: Array,
      required: true
    },
    alertStyle: {
      type: String,
      default: ''
    }
  },
  methods: {
    removeAlert(alert) {
      this.$emit('remove-alert', alert)
    }
  }
}
</script>
