const initialState = {
  flow: 'brandToBrand',
  brandLoginComplete: false,
  peapodLoginComplete: false,
  loyaltyLinked: false,
}

export default {
  namespaced: true,
  state: {
    ...initialState,
    userName: '',
    // The ppd flag is sent with the forgot username/password api call to tell the back end
    // that it should check peapod for a matching username and email address and not ATG
    shouldAddPPDFlag: false,
    // These 2 values below are for showing the PeapodWelcome component below and are only
    // for that one component to display correctly while the user is on the PPOD site
    // These 2 values do not affect the regular site transition at all
    showPeapodWelcomeScreen: false,
    // Peapod Welcome data has 3 properties
    // opco tells the component which opco we are sending the user to
    // openModalInOpco tells the component when the user is sent to the
    // opcos website if we should launch site transition or not
    // authenticated tells the component if the user is seeing this component
    // because they logged in or just because they put in a zip code on the gateway page
    peapodWelcomeData: {
      opco: 'PPOD',
      openModalInOpco: false,
      authenticated: false,
    }
  },
  mutations: {
    resetState(state) {
      Object.assign(state, initialState)
    },
    setState(state, payload) {
      Object.assign(state, payload)
    },
    setFlow(state, payload) {
      // payload is a string that contains 'peapodToBrand' or 'brandToBrand'
      state.flow = payload
    },
    setBrandLoginComplete(state, payload) {
      // payload is a Boolean
      state.brandLoginComplete = payload
    },
    setPeapodLoginComplete(state, payload) {
      // payload is a Boolean
      state.peapodLoginComplete = payload
    },
    setUserName(state, payload) {
      // payload is a string
      state.userName = payload
    },
    setPPDFlag(state, payload) {
      // payload is a Boolean
      state.shouldAddPPDFlag = payload
    },
    setShowPeapodWelcomScreen(state, payload) {
      // payload is a Boolean
      state.showPeapodWelcomeScreen = payload
    },
    setPeapodWelcomeData(state, payload) {
      // payload is an Object that can contain 3 properties
      // opco, openModalInOpco, and authenticated
      state.peapodWelcomeData = {
        opco: payload.opco,
        openModalInOpco: payload.openModalInOpco,
        authenticated: payload.authenticated
      }
    }
  }
}
