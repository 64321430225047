<!-- eslint-disable vuejs-accessibility/form-control-has-label, vuejs-accessibility/no-onchange -->
<template>
  <div class="field">
    <PdlInputLabel
      v-bind="labelProps"
    >
      <slot name="label" />
    </PdlInputLabel>
    <slot
      class="field_row"
      name="before"
    />
    <div class="field_row">
      <div
        class="select select--full-width"
        :class="inputClasses"
      >
        <select
          :id="id"
          :ref="'input'"
          :value="modelValue"
          class="select_select"
          :aria-describedby="hasMessages ? `${id}-messages` : null"
          @change="onChange"
        >
          <option
            class="select_option"
            value=""
            disabled
          >
            {{ defaultLabel }}
          </option>
          <option
            v-for="(option, i) in options"
            :key="i"
            class="select_option"
            :value="option.optionValue"
          >
            {{ option.label }}
          </option>
        </select>
        <PdlIconBase
          role="presentation"
          icon-title="Show select"
          icon-class="vector-icon-stroke--prime"
          container-class="select_caret vector-icon-size--medium"
        >
          <PdlIconCarrotDown />
        </PdlIconBase>
      </div>
    </div>
    <div
      v-if="subLabel"
      class="field_row"
    >
      <span class="form_tagline">{{ subLabel }}</span>
    </div>
    <slot
      class="field_row"
      name="after"
    />
    <InputMessages
      :id="`${id}-messages`"
      class="field_row"
      :errors="errors"
      :description="description"
      :has-errors="hasErrors"
      :has-persistent-messages="hasPersistentMessages"
      :persistent-messages="persistentMessagesList"
    />
  </div>
</template>

<script>
import { PdlIconBase } from '@/shared/icons'
import PdlIconCarrotDown from 'components/icons/icon/PdlIconCarrotDown'
import InputBase from './PdlInputBaseMixin'

export default {
  components: {
    PdlIconBase,
    PdlIconCarrotDown
  },
  mixins: [InputBase],
  props: {
    modelValue: {
      type: [String, Number, Boolean],
      default: ''
    },
    defaultLabel: {
      type: String,
      default: '- Select One -'
    },
    options: {
      type: Array,
      default() {
        return []
      }
    },
    subLabel: {
      type: String,
      default: ''
    },
    fullWidth: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    onChange(event) {
      this.$emit('update:modelValue', event.target.value)
    },
  },
  emits: ['update:modelValue']
}
</script>
