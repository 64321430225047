import { GTM } from 'utils/constants'

export default function trackNavigationUpdated(trackNavUpdatedData) {
  const {
    index, url, label, action
  } = trackNavUpdatedData
  const dataToTrack = {
    category: 'navigation',
    action,
    ...(url !== undefined && { label: url }),
    type: '2023_desktop_navigation_v1',
    navElementText: label,
    ...(index && { index })
  }
  const vueInstance = window.sharedVue.config.globalProperties
  vueInstance.$trackGtmEvent(GTM.navigationUpdated, dataToTrack)
}
