import creditCardApi from 'api/CreditCardApi'
import cardTypeByNumber from 'utils/filters/cardTypeByNumber'
import cardNameByType from 'utils/filters/cardNameByType'
import cardMaxByType from 'utils/filters/cardMaxByType'
import formatPrivateString from 'utils/filters/formatPrivateString'
import formatPhoneWhole from 'utils/filters/formatPhoneWhole'

const initialState = {
  creditCardType: '',
  creditCardNumber: '',
  expirationMonth: '',
  expirationYear: '',
  creditCardName: '',
  cardNotFound: '',
  encryptServiceError: false,
  redirectFrom: ''
}

const newIntialState = {
  active: false,
  addressLine1: '',
  addressLine2: '',
  bankName: '',
  bankRoutingNumber: '',
  capsId: null,
  cardType: '',
  city: '',
  contact: {
    areaCode: '',
    prefix: '',
    suffix: '',
    ext: null
  },
  creditCardExpired: false,
  firstName: '',
  lastName: '',
  creditCardExpiration: '',
  creditCardIssuer: '',
  giftCard: false,
  maskedAccountNumber: '',
  nameOnCreditCard: '',
  nickname: '',
  paymentMethod: '',
  primaryAccount: false,
  state: '',
  zip: ''
}

const keyMap = {
  field: {
    ccNum: 'creditCard',
    cvv: 'cvCode'
  }
}

const remapCardErrors = errors => _.map(errors, error => _.mapObject(error, (value, key) => {
  value = _.has(keyMap, key) && _.has(keyMap[key], value) ? keyMap[key][value] : value
  return value
}, {}))

const returnExpiration = (expirationString) => {
  const expiration = expirationString.split('/')
  return {
    month: expiration[0],
    year: expiration[1]
  }
}

const updateStateFromPayload = (payload, state) => {
  _.each(payload, (value, key) => {
    if (_.has(state, key) && !_.isNull(value)) {
      state[key] = value
    }
  })
}

export default {
  namespaced: true,
  state: {
    mode: 'add',
    loading: false,
    loaded: false,
    redirectFrom: '',
    ...initialState,
    ...newIntialState
  },
  getters: {
    creditCardType: state => state.creditCardType || state.creditCardIssuer,
    creditCardNumber: state => formatPrivateString(state.creditCardNumber) || state.maskedAccountNumber,
    expirationMonth: state => state.expirationMonth || returnExpiration(state.creditCardExpiration).month,
    expirationYear: state => state.expirationYear || returnExpiration(state.creditCardExpiration).year,
    creditCardName: state => state.creditCardName || state.nameOnCreditCard,
    cardTypeByNumber: () => num => cardTypeByNumber(num),
    cardNameByType: () => type => cardNameByType(type),
    cardMaxByType: () => type => cardMaxByType(type),
    cardBillingAddress: state => (
      {
        addressLine1: state.addressLine1,
        addressLine2: state.addressLine2,
        city: state.city,
        firstName: state.firstName,
        lastName: state.lastName,
        state: state.state,
        zip: state.zip,
      }
    ),
    cardPhone: state => formatPhoneWhole(state.contact),
  },
  mutations: {
    setRedirectFrom(state, value) {
      state.redirectFrom = value
    },
    setMode(state, payload) { state.mode = payload },
    setCardNotFound(state, payload) { state.cardNotFound = !!payload },
    setLoaded(state, payload) { state.loaded = !!payload },
    setLoading(state, payload) { state.loading = payload },
    setCapsId(state, payload) { state.capsId = payload },
    setEncryptServiceError(state, payload) { state.encryptServiceError = payload },
    setFilteredStoreData(state, payload) {
      const config = {
        cardType: 'creditCardType',
        ccNum: 'creditCardNumber',
        expMonth: 'expirationMonth',
        expYear: 'expirationYear',
        name: 'creditCardName'
      }

      payload = _.chain(payload)
        .reduce((memo, value, key) => {
          if (_.has(config, key)) {
            memo[config[key]] = value
          }
          return memo
        }, {})
        .mapObject((value, key) => ((key === 'creditCard') ? formatPrivateString(value) : value))
        .value()

      updateStateFromPayload(payload, state)
    },
    setStoreData(state, payload) {
      payload = _.mapObject(payload, (value, key) => ((key === 'creditCard') ? formatPrivateString(value) : value))
      updateStateFromPayload(payload, state)
    }
  },
  actions: {
    clearCreditCard({ commit }) {
      commit('setStoreData', {
        ...initialState,
        ...newIntialState
      })
      commit('setLoaded', true)
    },
    setCreditCard({ commit }, card) {
      commit('setStoreData', card)
      commit('setLoaded', true)
    },
    async getCreditCard({ state, commit }) {
      let response = Promise.resolve({})
      if (!state.loading && !state.loaded) {
        commit('setLoading', true)
        const userId = window.sharedVue.config.globalProperties.$store.getters['UserProfile/userId']
        response = await creditCardApi.get(userId)

        const success = response.status === 200
        try {
          const { data } = response
          const {
            creditCardIssuer, maskedAccountNumber, creditCardExpiration, nameOnCreditCard
          } = data
          const card = {
            creditCardType: creditCardIssuer,
            creditCardNumber: maskedAccountNumber,
            expirationMonth: creditCardExpiration.split('/')[0],
            expirationYear: creditCardExpiration.split('/')[1],
            creditCardName: nameOnCreditCard,
            ...data
          }
          if (success) {
            commit('setStoreData', card)
            commit('setLoaded', true) // Prevent reload of data
          } else {
            const { errorCode } = response
            commit('setCardNotFound', errorCode === 'PAYMENT_DATA_NOT_AVAILABLE')
          }
        } catch {
          commit('setCardNotFound', true)
        }
      }
      commit('setLoading', false)
      return response
    },
    async updateCreditCard({
      state, commit, rootGetters
    }, payload) {
      const userId = rootGetters['UserProfile/userId']
      let response = Promise.resolve({})
      if (!state.loading) {
        commit('setLoading', true)
        commit('setEncryptServiceError', false)
        response = await creditCardApi.update(payload, userId)

        const errors = (response.data?.response?.errors) ? response.data.response.errors : false
        const success = response.status >= 200 && response.status < 300
        if (success) {
          const committer = (!payload.cardNumber && payload.ccNum) ? 'setFilteredStoreData' : 'setStoreData'

          commit(committer, payload)

          commit('setLoaded', true) // Prevent reload of data
        } else if (errors) {
          response.data.response.errors = remapCardErrors(errors)
        }
      }

      commit('setLoading', false)
      return response
    }
  }
}
