/* eslint-disable class-methods-use-this */
class ProductTileService {
  incrementQuantity = (product, quantity) => {
    const newQuantity = quantity + 1
    if (newQuantity > 99) return undefined
    return this.updateCart({ type: 'update', quantity: newQuantity, product })
  }

  decrementQuantity = (product, quantity) => {
    const newQuantity = quantity - 1
    if (newQuantity < 1) {
      return this.updateCart({ type: 'delete', quantity: 0, product })
    }
    return this.updateCart({ type: 'update', quantity: newQuantity, product })
  }

  changeInputQuantity = (event, product) => {
    const { value } = event.target
    if (value < 1) {
      return this.updateCart({ type: 'delete', quantity: 0, product })
    }
    return this.updateCart({ type: 'update', quantity: Number(value), product })
  }

  updateCart = async (cart) => {
    const store = window.sharedVue.config.globalProperties.$store
    const cartItem = store.getters['Cart/getCartProductById'](cart.product.prodId)
    const payload = {
      items: [
        {
          productId: cart.product.prodId,
          quantity: cart.quantity
        }
      ],
    }
    const updatedCart = {
      productId: cart.product.prodId,
      quantity: cart.quantity,
      prevQuantity: cartItem ? cartItem.qty : 0,
      type: cart.type,
      product: cart.product
    }
    let response
    let productResponse = {}
    if (cart.type !== 'delete') {
      response = await store.dispatch('Cart/updateCartItems', payload)
    } else {
      response = await store.dispatch('Cart/deleteFromCart', payload)
    }
    const { successes, errors } = response?.data?.response || {}
    if (response.status === 200) {
      productResponse = successes.find(x => x.productId === payload?.items[0]?.productId) || {}
    } else if (errors) {
      productResponse = errors.find(x => x.productId === payload?.items[0]?.productId) || {}
    }
    return { productResponse, updatedCart }
  }

  formatProductDetailsUrlFragment = (productName = '', productSize = '') => {
    const separator = productName && productSize ? '-' : ''
    const pageName = `${productName}${separator}${productSize}`
    return pageName.replace(/[^a-zA-Z\-\d\s./]/g, '').replace(/[\s/-]+/g, '-').toLowerCase()
  }

  generateProductDetailsUrl(productId, productName, productSize) {
    const formattedPageName = this.formatProductDetailsUrlFragment(productName, productSize)
    return formattedPageName ? `/product/${formattedPageName}/${productId}` : `/product/${productId}`
  }
}

const service = new ProductTileService()

export default service
