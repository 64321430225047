import couponAPI from 'api/couponApi'

export default {
  namespaced: true,
  state: {
    couponTotalValue: -1,
    couponStart: 0,
    couponSize: 60,
    fetchingCouponTotal: false,
    couponTotalError: false
  },
  mutations: {
    addToCouponTotalValue(state, payload) {
      // Set to zero to indicate a successful fetch, even if there are no coupons
      if (state.couponTotalValue === -1) state.couponTotalValue = 0
      if (payload.length) {
        const total = payload.reduce((acc, item) => {
          let tmp = acc
          if (item.maxDiscount) tmp += item.maxDiscount
          return tmp
        }, state.couponTotalValue)
        state.couponTotalValue += total
      } else if (payload.maxDiscount) {
        state.couponTotalValue += payload.maxDiscount
      }
    },
    setFetchingCouponTotal(state, payload) {
      state.fetchingCouponTotal = payload
    },
    setCouponTotalError(state, payload) {
      state.couponTotalError = payload
    }
  },
  actions: {
    async fetchClippedCouponSet({
      state, commit, rootGetters
    }) {
      // If we're already fetching the value or have previously, don't make the request again
      if (state.fetchingCouponTotal || state.couponTotalValue !== -1) {
        return
      }
      commit('setFetchingCouponTotal', true)

      const userId = rootGetters['UserProfile/userId']
      const { serviceLocationId } = rootGetters['UserProfile/deliveryServiceLocation']
      const cardNumber = rootGetters['UserProfile/retailerCardNumber']
      const couponVersion = rootGetters['SiteConfig/varByName']('coupon_version')

      const apiParams = {
        sortSelection: {
          title: 'Recommended',
          value: 'Recommended',
          order: 'desc',
          name: 'targeted'
        },
        loadable: true,
        loaded: true,
        targeted: true,
        sourceSystems: ['QUO', 'COP', 'INM'],
        start: state.couponStart,
        size: state.couponSize
      }

      try {
        const response = await couponAPI.getCoupons(userId, serviceLocationId, cardNumber, couponVersion, apiParams)
        commit('addToCouponTotalValue', response.data.coupons)
        commit('setFetchingCouponTotal', false)
      } catch (e) {
        commit('setFetchingCouponTotal', false)
        commit('setCouponTotalError', true)
      }
    }
  }
}
