/* eslint-disable no-useless-catch */
import ApiService from '@/api/'
import excludeNilFromObj from 'utils/filters/excludeNilFromObj'
import replaceCurlyQuotes from 'utils/filters/replaceCurlyQuotes'
import { LOGGING_TYPE } from 'utils/constants'
import userProfileAPI from 'api/UserProfileAPI'
import formatAltId from 'utils/filters/formatAltId'
import FuelRewardsAPI from 'api/FuelRewardsAPI'
import { trackLoyaltyId } from 'utils/tracking/trackLoyaltyId'

const sanitizeLoyaltyPayload = (payload) => {
  let results = payload || {}
  results = excludeNilFromObj(results) // dont include undefined variables
  results = Object.keys(results).reduce((result, key) => {
    result[key] = replaceCurlyQuotes(results[key])
    return result
  }, {})
  return results
}

const initialState = {
  firstName: '',
  lastName: '',
  address: '',
  additionalAddress: '',
  city: '',
  state: '',
  zip: '',
  email: '',
  phone: '',
  atgPrivacySetting: true,
  termsAndConditionsAccepted: false,
  loyaltyAccountPending: false,
  // FORM VALIDATION
  lookupEmail: '',
  lookupPhone: '',
  successfulLookup: false,
  invalidLookup: false,
  existingCardLookup: false,
  cardUpdated: false,
  cardReplaceConfirmed: false,
  cardReplaceSuccess: false,
  cardUpdateInvalid: false,
  cardUpdateStatusCode: 0,
  cardUpdateError: false,
  cardReplaceError: false,
  cardReplaceOnce: false,
  foundLoyaltyAccount: false,
  fuelRewardsOptIn: false,
  fuelRewardsJoinURL: '',
  fuelRewardsLoginURL: '',
  fuelRewardsAccessToken: '',
  fuelRewardsLinkData: null,
  // LOADING RULES
  loaded: false,
  loading: false,
  loyaltyUserResponse: false // waiting for initial getter: false, Promise, or true
}

const validateRequiredProps = (state, payload) => {
  if (payload.email) { // must send phone if updating email address
    payload.phone = payload.phone === null || payload.phone === undefined ? state.phone : payload.phone
  }

  payload.firstName = !payload.firstName ? state.firstName : payload.firstName
  payload.lastName = !payload.lastName ? state.lastName : payload.lastName
  return payload
}

export default {
  namespaced: true,
  state: { loading: false, ...initialState },
  getters: {
    // cardNumber to point to same data point in UserProfile
    // eslint-disable-next-line max-params
    cardNumber: (state, getters, rootState, rootGetters) => rootGetters['UserProfile/retailerCardNumber'],
    firstName: state => state.firstName,
    lastName: state => state.lastName,
    address: state => state.address,
    additionalAddress: state => state.additionalAddress,
    city: state => state.city,
    state: state => state.state,
    zip: state => state.zip,
    addressCityStateZip: state => `${state.city}, ${state.state} ${state.zip}`,
    email: state => state.email,
    phone: state => state.phone,
    termsAndConditionsAccepted: state => state.termsAndConditionsAccepted,
    hasFullName: (state, getters) => (!!getters.firstName && !!getters.lastName),
    isLoyaltyRegistered: (state, getters) => !!getters.cardNumber,
    loyaltyAccountPending: state => state.loyaltyAccountPending,
    atgPrivacySetting: state => state.atgPrivacySetting,
    lookup: state => _.pick(state,
      'firstName',
      'lastName',
      'address',
      'additionalAddress',
      'city',
      'state',
      'zip',
      'email',
      'phone',
      'termsAndConditionsAccepted',
      'atgPrivacySetting'),
    // FORM VALIDATION
    isLoyaltyInfoComplete: (state, getters) => !!(getters.hasFullName && state.address
      && state.city && state.state && state.zip && state.email),
    successfulLookup: state => state.successfulLookup,
    lookupEmail: state => state.lookupEmail,
    lookupPhone: state => state.lookupPhone,
    invalidLookup: state => state.invalidLookup,
    existingCardLookup: state => state.existingCardLookup,
    cardUpdated: state => state.cardUpdated,
    cardReplaceConfirmed: state => state.cardReplaceConfirmed,
    cardReplaceSuccess: state => state.cardReplaceSuccess,
    cardUpdateInvalid: state => state.cardUpdateInvalid,
    cardUpdateError: state => state.cardUpdateError,
    cardReplaceError: state => state.cardReplaceError,
    cardReplaceOnce: state => state.cardReplaceOnce,
    fuelRewardsOptIn: state => state.fuelRewardsOptIn,
    fuelRewardsJoinURL: state => state.fuelRewardsJoinURL,
    fuelRewardsLoginURL: state => state.fuelRewardsLoginURL,
    fuelRewardsAccessToken: state => state.fuelRewardsAccessToken,
    fuelRewardsLinkData: state => state.fuelRewardsLinkData,
    foundLoyaltyAccount: state => state.foundLoyaltyAccount,

    // eslint-disable-next-line
    userDataURIString: (state) => `&firstName=${encodeURIComponent(state.firstName)}`
      + `&lastName=${encodeURIComponent(state.lastName)}`
      + `&street1=${encodeURIComponent(state.address)}`
      + (state.additionalAddress ? `&street2=${encodeURIComponent(state.address)}` : '')
      + `&city=${encodeURIComponent(state.city)}`
      + `&stateCode=${encodeURIComponent(state.state)}`
      + `&zipCode=${encodeURIComponent(state.zip)}`
      + `&userId=${state.email}`
      + `&primaryPhone=${state.phone}`,

    // LOADING RULES
    loaded: state => state.loaded,
    loading: state => state.loading,
  },
  mutations: {
    setLoyaltyAccountPending(state, payload) {
      state.loyaltyAccountPending = payload
    },
    // FORM VALIDATION
    setSuccessfulLookup(state, payload) { state.successfulLookup = payload },
    setLookupEmail(state, payload) { state.lookupEmail = payload },
    setLookupPhone(state, payload) { state.lookupPhone = payload },
    setInvalidLookup(state, payload) { state.invalidLookup = payload },
    setExistingCardLookup(state, payload) { state.existingCardLookup = payload },
    setCardUpdated(state, payload) { state.cardUpdated = payload },
    setCardReplaceConfirmed(state, payload) { state.cardReplaceConfirmed = payload },
    setCardReplaceSuccess(state, payload) { state.cardReplaceSuccess = payload },
    setCardUpdateInvalid(state, payload) { state.cardUpdateInvalid = payload },
    setCardUpdateError(state, payload) { state.cardUpdateError = payload },
    setCardUpdateStatusCode(state, payload) { state.cardUpdateStatusCode = payload },
    setCardReplaceError(state, payload) { state.cardReplaceError = payload },
    setCardReplaceOnce(state, payload) { state.cardReplaceOnce = payload },
    setTermsAccepted(state, payload) { state.termsAndConditionsAccepted = payload },
    setLoaded(state, payload) { state.loaded = !!payload },
    setLoading(state, payload) { state.loading = payload },
    setFoundLoyaltyAccount(state, payload) { state.foundLoyaltyAccount = payload },
    setStoreData(state, payload) {
      _.each(payload, (value, key) => {
        if (_.has(state, key) && !_.isNull(value)) {
          state[key] = value
        }
      })
    },
    setFuelRewardsJoinURL(state, payload) {
      state.fuelRewardsJoinURL = payload
    },
    setFuelRewardsLoginURL(state, payload) {
      state.fuelRewardsLoginURL = payload
    },
    setFuelRewardsAccessToken(state, payload) {
      state.fuelRewardsAccessToken = payload
    },
    setFuelRewardsLinkData(state, payload) {
      state.fuelRewardsLinkData = payload
    },
    setFuelRewardsOptIn(state, payload) {
      state.fuelRewardsOptIn = payload
    },
    setLoyaltyUserResponse(state, payload) {
      state.loyaltyUserResponse = payload
    },
  },
  actions: {
    // Used to reset application; Only happens during loyalty registration card status step;
    resetLoyalty({ commit }) {
      commit('setStoreData', initialState)
    },
    resetFormValidation({ commit }) {
      commit('setCardUpdateError', false)
      commit('setCardUpdated', false)
      commit('setCardUpdateInvalid', false)
      commit('setCardUpdateStatusCode', 0)
      commit('setCardReplaceSuccess', false)
      commit('setCardReplaceError', false)
    },
    confirmReplaceCard({ commit }, payload) {
      commit('setCardReplaceConfirmed', payload)
    },
    async lookupCardNumber({ dispatch, rootGetters, commit }, profile) {
      const { cardNumber, lastName } = profile
      const userId = rootGetters['UserProfile/userId']
      const payload = { lastName }
      if (cardNumber.length >= 11) {
        payload.cardNumber = cardNumber
      } else {
        payload.phoneNumber = cardNumber
      }
      commit('setInvalidLookup', false)
      commit('setExistingCardLookup', false)
      commit('setLoading', true)

      try {
        // eslint-disable-next-line max-len
        const { status, data } = await ApiService.put(`/api/v6.0/user/${userId}/loyaltycard`, payload)
        if (status !== 200) {
          if (data.response.code === 'CARD_BELONGS_TO_ANOTHER_ACCOUNT') {
            commit('setExistingCardLookup', true)
          } else {
            commit('setInvalidLookup', true)
          }
        } else {
          commit('setSuccessfulLookup', true)
          commit('setStoreData', data.response)
          commit('setLookupEmail', data.response.email)
          commit('setLookupPhone', data.response.phone)
          dispatch('UserProfile/updateUserProfileData', {
            path: 'retailerCard.cardNumber',
            value: data.response.cardNumber
          }, { root: true })
          trackLoyaltyId(data.response.cardNumber)
          commit('setLoaded', true) // Prevent reload on account page
        }
        commit('setLoading', false)
      } catch (err) {
        commit('setLoading', false)
        commit('setInvalidLookup', true)
      }
    },
    async updateLoyaltyCard({
      state, commit, getters, rootGetters, dispatch
    }, { cardNumber, lastName }) {
      let response = Promise.resolve(false)

      if (!state.loading) {
        commit('setLoading', true)
        lastName = lastName || getters.lastName
        const userId = rootGetters['UserProfile/userId']

        commit('setCardUpdateError', false)
        commit('setCardUpdated', false)
        commit('setCardUpdateInvalid', false)
        try {
          response = await ApiService.put(`/api/v6.0/user/${userId}/loyaltycard`, {
            cardNumber,
            lastName
          })

          const { status, data } = response
          commit('setCardUpdateStatusCode', status)
          // 200 - Card has been retrieved and set to the account
          // 400 - Invalid card number or card number belongs to another account
          if (status !== 200 && status !== 400) {
            throw new Error('There was a problem with the updateLoyaltyCard service')
          }
          commit('setCardUpdated', status === 200)
          commit('setCardUpdateInvalid', status !== 200)

          // response.data.response ? is this the new response?
          if (!state.cardUpdateInvalid) {
            commit('setStoreData', data.response)
            dispatch('UserProfile/updateUserProfileData', {
              path: 'retailerCard.cardNumber',
              value: cardNumber
            }, { root: true })
          }
          trackLoyaltyId(cardNumber)
        } catch (e) {
          commit('setCardUpdateError', true)
        }

        commit('setLoading', false)
      }
      return response
    },
    async replaceCard({ state, commit, rootGetters }, cardNumber) {
      if (!state.loading) {
        const opco = rootGetters['SiteConfig/opco']

        commit('setLoading', true)
        commit('setCardReplaceError', false)
        commit('setCardReplaceOnce', true)

        await ApiService.post(`/apis/loyaltyaccount/v2/replace/${opco}/${cardNumber}`)
          .then(({ status }) => {
            commit('setCardReplaceSuccess', status === 200)
            commit('setCardReplaceError', status !== 200)
          })
          .catch(() => {
            commit('setCardReplaceError', true)
            commit('setCardReplaceOnce', false)
          })
        commit('setLoading', false)
      }
    },
    async getLoyalty({
      state, commit, getters, rootGetters
    }) {
      // Only load once, unless account pending
      if (!state.loading && getters.cardNumber && !state.loaded) {
        commit('setLoading', true)
        try {
          let payload = {}
          const opco = rootGetters['SiteConfig/opco']
          const response = await ApiService.get(`/apis/loyaltyaccount/v3/${opco}/${getters.cardNumber}`)
          const success = response.status >= 200 && response.status < 300
          commit('setLoyaltyUserResponse', response)
          payload = response?.data || {}
          const { status } = response || {}
          // In the case that MDM returns a pending account, still allow users to navigate loyalty sections
          const loyaltyAccountPending = status === 202
          commit('setLoyaltyAccountPending', loyaltyAccountPending)

          const foundLoyaltyAccount = status > 199 && status < 300
          commit('setFoundLoyaltyAccount', foundLoyaltyAccount)

          commit('setStoreData', payload)
          if (success) {
            commit('setLoaded', true) // Prevent reload on account page
            if (loyaltyAccountPending) {
              window.sharedVue.config.globalProperties.$trackClientLog('loyalty_account_pending', {
                loyalty_id: getters.cardNumber,
                opco
              }, LOGGING_TYPE.event)
            }
          } else {
            throw new Error('There was a problem getting loyalty information')
          }
          commit('setLoyaltyUserResponse', true)
          commit('setLoading', false)
        } catch (err) {
          commit('setLoading', false)
          window.sharedVue.config.globalProperties.$trackClientLog('get_loyalty', {
            error: JSON.stringify(err)
          }, LOGGING_TYPE.exception)
          throw err
        }
      }
    },
    async updateLoyalty({ state, commit, dispatch }, payload) {
      const response = await dispatch('putLoyalty', payload)
      const putSuccess = response?.status === 200
      if (putSuccess) {
        if (!state.loading) {
          commit('setLoading', true) // prevent a false positive on terms confirmation modal
        }
        commit('setLoading', false)
      }
      return response
    },
    async updateLoyaltyPhone({ state, commit, dispatch }, payload) {
      if (!state.loading && (payload.phone || state.phone)) {
        // MDM always needs an email to be sent, otherwise MDM removes the email address
        // and returns the data back without an email address if a user submits an empty phone number
        payload.email = state.email
        let uniqueAltId = {
          isUnique: true
        }
        if (payload.phone) {
          commit('setLoading', true)
          uniqueAltId = await dispatch('isAltIdUnique', payload.phone)
          commit('setLoading', false)
        }
        return (uniqueAltId.isUnique === true) ? dispatch('putLoyalty', payload)
          : Promise.resolve(uniqueAltId)
      }
      return new Promise()
    },
    async deleteLoyaltyPhone({
      state, commit, getters, rootGetters
    }) {
      let response
      commit('setLoading', true)
      try {
        const payload = {
          phone: state.phone,
          email: state.email,
          firstName: state.firstName,
          lastName: state.lastName,
        }
        const opco = rootGetters['SiteConfig/opco']
        const { cardNumber } = getters
        response = await ApiService.delete(`/apis/loyaltyaccount/v1/phone/${opco}/${cardNumber}`, {
          data: payload
        })
        commit('setStoreData', { phone: '' })
      } catch {
        commit('setLoading', false)
        return Promise.reject(new Error('Delete phone failed'))
      }
      commit('setLoading', false)
      return response
    },
    // API Update for Loyalty Actions
    async putLoyalty({
      state, commit, getters, rootGetters
    }, payload) {
      if (!state.loading) {
        commit('setLoading', true)
        const opco = rootGetters['SiteConfig/opco']
        payload = validateRequiredProps(state, payload)
        payload.phone = formatAltId(payload.phone) // API requires string type
        payload = sanitizeLoyaltyPayload(payload)

        const response = await ApiService.corePut(
          `/apis/loyaltyaccount/v2/update/${opco}/${getters.cardNumber}`,
          payload
        )

        const success = response.status === 200
        if (success) {
          commit('setFoundLoyaltyAccount', true)
        }
        payload = success ? payload : {}
        commit('setStoreData', payload)
        commit('setLoaded', success) // Prevent reload on account page
        commit('setSuccessfulLookup', false)
        commit('setLoading', false)
        trackLoyaltyId(rootGetters['UserProfile/retailerCardNumber'])
        return response
      }

      return new Promise()
    },
    async isEmailUnique({ rootGetters }, payload) {
      const opco = rootGetters['SiteConfig/opco']
      const [email, domain] = payload.split('@')
      const encodedEmail = encodeURIComponent(email)
      const fullEmail = [encodedEmail, domain].join('@')
      try {
        const { status } = await ApiService.get(`/apis/loyaltyaccount/v1/email/${fullEmail}/opco/${opco}`)
        return {
          isUnique: status === 204,
          error: status !== 200 && status !== 204
        }
      } catch {
        return {
          isUnique: false,
          error: true
        }
      }
    },
    async isAltIdUnique({ rootGetters }, phone) {
      const opco = rootGetters['SiteConfig/opco']
      try {
        const { status } = await ApiService.get(`/apis/loyaltyaccount/v2/phone/${phone}/${opco}`)
        return {
          isUnique: status === 204,
          error: status !== 200 && status !== 204
        }
      } catch {
        return {
          isUnique: false,
          error: true
        }
      }
    },
    // REGISTRATION - LOYALTY ACCOUNT CREATE
    async createLoyalty({
      state, commit, rootGetters, dispatch
    }, payload) {
      if (!state.loading) {
        commit('setLoading', true)
        payload.phone = formatAltId(payload.phone)
        payload = sanitizeLoyaltyPayload(payload)
        const userId = rootGetters['UserProfile/userId']
        const opco = rootGetters['SiteConfig/opco']
        payload.opco = opco
        const response = await ApiService.post(`/api/v6.0/user/${userId}/cardAllocation`, payload)
        const { data, status } = response || {}
        const responseData = data?.response

        const success = status === 201
        commit('setFoundLoyaltyAccount', success)

        if (success) {
          // call Refresh user profile api for update delivery address
          // This will be temp solution till we have Dynamo or session-Management goes live
          // Since Dynamo session is becoming stale when user updates happen
          await userProfileAPI.refreshUserProfile(userId)
          commit('setStoreData', responseData.createAccount)
          commit('setLoaded', true) // Prevent reload on account page
          commit('setSuccessfulLookup', false)
          dispatch('UserProfile/updateUserProfileData', {
            path: 'retailerCard.cardNumber',
            value: responseData.cardNumber
          }, { root: true })
        } else {
          commit('setTermsAccepted', true)
        }
        commit('setLoading', false)

        trackLoyaltyId(responseData?.cardNumber)
        return {
          status,
          data: responseData
        }
      }
      return {}
    },
    async removeLoyalty({
      state, commit, rootGetters, dispatch
    }) {
      let results = Promise.resolve(false)
      const cardNumber = rootGetters['UserProfile/retailerCardNumber']
      if (!state.loading && cardNumber) {
        try {
          commit('setLoading', true)
          const userId = rootGetters['UserProfile/userId']

          const response = await ApiService.delete(`/api/v6.0/user/${userId}/loyaltycard`)
          const { status } = response || {}
          const success = status === 200

          if (success) {
            // call Refresh user profile api for update delivery address
            // This will be temp solution till we have Dynamo or session-Management goes live
            // Since Dynamo session is becoming stale when user updates happen
            await userProfileAPI.refreshUserProfile(userId)
            commit('setStoreData', {
              firstName: '',
              lastName: '',
              address: '',
              additionalAddress: '',
              city: '',
              state: '',
              zip: '',
              email: '',
              phone: '',
              termsAndConditionsAccepted: '',
              atgPrivacySettin: '',
            })
            dispatch('UserProfile/updateUserProfileData', {
              path: 'retailerCard.cardNumber',
              value: ''
            }, { root: true })
          }

          trackLoyaltyId('')
          results = response
        } catch (err) {
          results = err
        }
        commit('setLoading', false)
      }
      return results
    },
    // expects object with values of form email and phone fields as a payload
    // {
    //   email: 'test@test.com',
    //   phone: ''
    // }
    async checkUnique({ commit, dispatch, state }, payload) {
      commit('setLoading', true)
      payload.phone = formatAltId(payload.phone)
      const { email, phone } = payload
      const checkEmail = email !== state.lookupEmail && email !== state.email
      const checkPhone = phone && (phone !== state.lookupPhone && phone !== state.phone)
      try {
        const emailResponse = checkEmail ? await dispatch('isEmailUnique', email) : { isUnique: true }
        const phoneResponse = checkPhone ? await dispatch('isAltIdUnique', phone) : { isUnique: true }
        if (emailResponse.isUnique && phoneResponse.isUnique) {
          commit('setStoreData', payload)
        }
        commit('setLoading', false)
        return {
          uniqueEmail: emailResponse.isUnique,
          uniquePhone: phoneResponse.isUnique
        }
      } catch (err) {
        commit('setLoading', false)
        throw err
      }
    },
    async updateEmail({ state, commit, dispatch }, payload) {
      if (!state.loading && payload.email) {
        commit('setLoading', true)
        const uniqueEmail = await dispatch('isEmailUnique', payload.email)
        commit('setLoading', false)
        return (uniqueEmail.isUnique === true) ? dispatch('putLoyalty', payload)
          : Promise.resolve(uniqueEmail)
      }
      return new Promise()
    },
    sendEmail({ rootGetters }) {
      const userId = rootGetters['UserProfile/userId']
      return ApiService.post(`/api/v5.0/user/${userId}/loyaltyaccount/send-email`)
    },
    async getFuelRewardsConnectionData({ commit, rootState }) {
      const { opco } = rootState.SiteConfig
      const response = await FuelRewardsAPI.getFuelRewardsConnectionData(opco)
      if (response.status === 200) {
        commit('setFuelRewardsJoinURL', response.data.registrationUrl)
        commit('setFuelRewardsLoginURL', response.data.joinUrl)
        commit('setFuelRewardsAccessToken', response.data.accessToken)
      }
    },
    async linkFr({
      state, commit, rootState, rootGetters
    }) {
      const cardNumber = rootGetters['UserProfile/retailerCardNumber']
      const { opco } = rootState.SiteConfig
      const accountData = { opco, cardNumber, ...state.fuelRewardsLinkData }
      const response = await FuelRewardsAPI.linkFuelRewardsAccount(accountData)
      if (response.status === 200) {
        commit('setFuelRewardsLinkData', null)
      } else {
        throw new Error('Unable to link fuel rewards')
      }
    }
  }
}
