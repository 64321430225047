/* eslint-disable class-methods-use-this */
class UUID {
  set = (uuid) => {
    window.localStorage.setItem('user-uuid', uuid)
  }

  generate = () => window.generateUUID()

  getItem = () => {
    return window.localStorage.getItem('user-uuid')
  }

  init = () => {
    const userUUID = this.getItem()
    if (userUUID === null) {
      this.set(this.generate())
    } else {
      this.set(userUUID)
    }
  }

  setAndFind() {
    this.init()
    return this.getItem()
  }
}

const uuidService = new UUID()

export default uuidService
