import { GTM, GTM_GA4 } from 'utils/constants'

/**
  * @param {{
  *  couponNumSearchResults: number,
  *  couponSearchItemsReturned: Array,
  *  couponSearchType: string,
  *  searchTerm: string,
  * }}
  * @returns {void}
  */

export function trackCouponSearch({
  couponNumSearchResults,
  couponSearchItemsReturned,
  couponSearchType,
  searchTerm,
  siteLocation,
  response,
}) {
  const gtmPayload = {
    couponNumSearchResults,
    couponSearchItemsReturned,
    couponSearchType,
  }

  if (couponSearchType === 'category click') {
    gtmPayload.couponSearchCategory = searchTerm
  } else {
    gtmPayload.couponSearchTerm = searchTerm
  }

  const ga4Payload = {
    response,
    num_results: couponNumSearchResults,
    content_type: 'coupon',
    search_term: searchTerm,
    type: couponSearchType,
    site_location: siteLocation
  }

  window.sharedVue.config.globalProperties.$trackGtmEvent(GTM.couponSearch, gtmPayload)
  window.sharedVue.config.globalProperties.$trackGA4Event(GTM_GA4.search, ga4Payload)
}
