<template>
  <div class="help-article_container">
    <PdlHelpSpyglassInfo
      v-if="spyglassActive"
      :article="article"
    />
    <p
      v-if="paragraphCopy"
      class="help-article_copy select-text max-w-full mx-auto my-0"
    >
      <!-- eslint-disable vue/no-v-html -->
      <span
        id="clickId"
        @click="onContentClick"
        @keyup.enter.space="onContentClick"
        v-html="paragraphCopy"
      />
      <!-- eslint-enable vue/no-v-html -->
    </p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import compileLinkVars from 'utils/mixins/compileLinkVars'
import PdlHelpSpyglassInfo from 'components/help/PdlHelpSpyglassInfo'

export default {
  components: {
    PdlHelpSpyglassInfo
  },
  mixins: [compileLinkVars],
  props: {
    article: {
      type: Object,
      default: () => ({
        title: '',
        text: ''
      })
    },
    isSidebar: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      spyglassActive: 'ScheduledContent/spyglassActive',
      userStatus: 'LoginStatus/userStatus',
      isLoggedIn: 'LoginStatus/isLoggedIn'
    }),
    paragraphCopy() {
      return this.article.text ? this.cleanHtml(this.article.text) : ''
    }
  },
  methods: {
    onContentClick(event) {
      const url = event.target?.pathname || event.target?.offsetParent?.pathname
      if (url) {
        this.$store.dispatch('NativeContainer/setCloseMobileMoreMenu')
        this.$store.commit('Modals/clearActiveModal')
        if (url.indexOf('/modal') === 0) {
          event.preventDefault()
          if (!this.isLoggedIn && url === '/account') {
            this.$store.commit('Modals/setActiveModal', {
              fileName: 'PdlLoginFlow',
            })
          }
        }
        if (url.charAt(0) === '/') {
          this.$router.push(url)
        }
      }
      return null
    }
  }
}
</script>
