import parseShortDate from 'utils/filters/parseShortDate'

export default {
  namespaced: true,
  state: {
    notifications: '',
  },
  getters: {
    count: state => state.notifications.length
  },
  mutations: {
    setNotifications(state, payload) {
      state.notifications = payload
    }
  },
  actions: {
    async getNotifications({ commit, dispatch }) {
      const payload = {
        region: {
          notification_center_content: {
            limit: 50,
          }
        }
      }
      const responseData = await dispatch('ScheduledContent/queryRegion', payload,
        { root: true })
      const { regionContents } = responseData?.response || {}
      if (regionContents.length && regionContents[0]?.contents.length) {
        const notificationsData = regionContents.filter(val => val.region === 'notification_center_content')
        const { contents } = notificationsData[0]
        const sortedResult = _.chain(contents)
          .map(notificationValue => ({
            headline: notificationValue.map.scheduledContentData.map['Notification Headline'],
            message: notificationValue.map.scheduledContentData.map['Notification Message'],
            startDate: parseShortDate(notificationValue.map['Start Date']),
            endDate: parseShortDate(notificationValue.map['End Date'])
          }))
          .sortBy('headline')
          .reverse()
          .sortBy('startDate')
          .reverse()
          .value()
        commit('setNotifications', sortedResult)
      }
      return responseData
    }
  }
}
