<template>
  <div :class="containerClass">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      :class="iconClass"
      :aria-label="iconTitle"
      :viewBox="`0 0 ${vbWidth} ${vbHeight}`"
      :role="iconRole"
      :aria-hidden="ariaHidden"
      focusable="false"
    >
      <title lang="en">{{ iconTitle }}</title>
      <desc>{{ iconDescription }}</desc>
      <g
        :fill="iconColor"
        :stroke="strokeColor"
      >
        <slot />
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    iconTitle: {
      type: String,
      required: true,
    },
    iconDescription: {
      type: String,
      default: '',
    },
    iconRole: {
      type: String,
      default: 'presentation',
    },
    ariaHidden: {
      type: String,
      default: 'true',
    },
    iconClass: {
      type: [String, Object],
      default: '',
    },
    iconColor: {
      type: String,
      default: '',
    },
    strokeColor: {
      type: String,
      default: '',
    },
    containerClass: {
      type: [String, Array, Object],
      default: '',
    },
    vbWidth: {
      type: Number,
      default: 32
    },
    vbHeight: {
      type: Number,
      default: 32
    }
  }
}
</script>
