<template>
  <div
    v-if="hasTerms"
    @mousemove="onMouseMove"
  >
    <h3
      v-if="listName.length"
      class="text-gray-500 text-xs pl-6 font-bold pb-2"
    >
      {{ listName }}
    </h3>
    <a
      v-if="isHeaderSearchTerm"
      href="#"
      class="pl-8 text-gray-900"
      @click.prevent="selectVal(headerSearchTerm, 0)"
    >
      <span
        v-if="isHighlightable"
        class="text--bold"
      >
        <span>{{ highlight(headerSearchTerm)[0] }}</span>
        <span class="text--unbold display--table-cell align-baseline">
          {{ highlight(headerSearchTerm).length > 1 ? query.trim() : '' }}
        </span>
        <span>{{ highlight(headerSearchTerm)[1] || '' }}</span>
      </span>
      <span v-else>{{ headerSearchTerm }}</span>
    </a>
    <ul
      v-if="!isHeaderSearchTerm"
      :id="id"
      ref="search-term-list"
      :class="[
        'search-term-list_container',
        {'max-h-[calc(100vh-460px)] overflow-y-auto': useCitrusBannerXStyles}
      ]"
    >
      <li
        v-for="(item, index) in searchTerms"
        :id="`${itemIdPrefix}-${index}`"
        :key="index"
        :class="[
          activeClass(index),
          'flex'
        ]"
      >
        <a
          href="#"
          class="flex-grow"
          @mouseenter="onActive(`${itemIdPrefix}-${index}`, index)"
          @focus="onActive(`${itemIdPrefix}-${index}`, index)"
          @click.prevent="selectVal(item, index)"
        >
          <span
            v-if="isHighlightable"
            class="text--bold table"
          >
            <span>{{ highlight(item)[0] }}</span>
            <span class="text--unbold">{{ highlight(item).length > 1 ? query.trim() : '' }}</span>
            <span>{{ highlight(item)[1] || '' }}</span>
          </span>
          <span v-else>{{ item }}</span>
        </a>
        <div
          v-if="isRemovable"
          class="right-0 absolute my-4 mr-6"
        >
          <button
            :aria-label="`Remove ${item}`"
            @click.stop.prevent="removeTerm(item, index)"
          >
            <PdlIconBase
              icon-title="Clear"
              icon-class="vector-icon-color--dark-grey"
              container-class="vector-icon-size--xsmall"
            >
              <PdlIconClose />
            </PdlIconBase>
          </button>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import {
  PdlIconBase,
} from '@/shared/icons'
import PdlIconClose from 'components/icons/icon/PdlIconClose'

export default {
  components: {
    PdlIconBase,
    PdlIconClose
  },
  props: {
    isHeaderSearchTerm: {
      type: Boolean,
      default: false
    },
    isRemovable: {
      type: Boolean,
      default: false
    },
    isHighlightable: {
      type: Boolean,
      default: false
    },
    listName: {
      type: String,
      default: ''
    },
    headerSearchTerm: {
      type: String,
      default: ''
    },
    searchTerms: {
      type: Array,
      default() {
        return []
      }
    },
    query: {
      type: String,
      default: ''
    },
    currentPosition: {
      type: Number,
      default: -1
    },
    currentList: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: null
    },
    isScroll: {
      type: Boolean,
      default: false
    },
    useCitrusBannerXStyles: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hasTerms() {
      return this.searchTerms.length > 0
    },
    itemIdPrefix() {
      return this.listName.replace(/\s+/g, '-').toLowerCase()
    }
  },
  methods: {
    selectVal(currentTerm, index) {
      this.$emit('value-selected', {
        searchTermPosition: index + 1,
        selectedValue: currentTerm,
        listName: this.listName
      })
    },
    activeClass(index) {
      return {
        'search-term-list--active': this.listName === this.currentList
        && this.currentPosition === index
      }
    },
    highlight(text) {
      const placeholder = text.replace(this.query.trim(), 'match')
      return placeholder.split('match')
    },
    removeTerm(currentTerm, index) {
      this.$emit('remove-term', {
        position: index + 1,
        selectedValue: currentTerm
      })
    },
    onActive(activeDescendant, index) {
      if (!this.isScroll) {
        this.$emit('on-active', {
          activeDescendant, index, listName: this.listName
        })
      }
    },
    onMouseMove() {
      this.$emit('mouse-move-event')
    }
  }
}
</script>
