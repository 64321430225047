import ApiService from '@/api/'
import { LOGGING_TYPE } from 'utils/constants'
import CartAPI from 'api/CartAPI'
import SuperUserApi from 'api/SuperUserApi'
import { redirectToLoginWithErrorCode } from 'components/auth/services/auth-helper-service'

const disabledCartAlert = {
  header: 'Address not saved',
  body: 'You will be able to change your address after your current order has been fulfilled.',
  type: 'warning'
}

const loggedInSessionAlert = {
  icon: false,
  header: 'Sign In Error',
  body: `We've encountered a technical issue. Please sign in again to continue.`,
  type: 'warning',
  primary: {
    text: 'Continue to Sign In',
    callback: () => {
      redirectToLoginWithErrorCode('AUTOLOGIN_EXPIRE')
    }
  }
}

const handleSessionCancelStatus = () => {
  const isLoggedIn = window.$store.getters['LoginStatus/isLoggedIn']
  if (isLoggedIn) {
    window.sharedVue.config.globalProperties.$store.commit('Alert/setAlert', loggedInSessionAlert)
  } else {
    window.location.reload()
  }
}

export default {
  perform(apiVersion = 'v3.0', serviceLocationId = '') {
    return this.getCurrentUserId()
      .then((userId) => {
        if (!userId || !serviceLocationId) {
          window.sharedVue.config.globalProperties.$trackClientLog('get_currentuserid_fail', {
            userId,
            serviceLocationId
          }, LOGGING_TYPE.exception)
        }
        return this.modernAutoLogin(apiVersion, userId, serviceLocationId)
      })
  },
  async getCurrentUserId() {
    return ApiService.get('/api/v1.0/current/user')
      .then((userInfo) => {
        return userInfo?.data?.userId
      })
  },
  modernAutoLogin(apiVersion, userId, serviceLocationId) {
    const renewURL = `/api/${apiVersion}/user/${userId}/${serviceLocationId}/auto-login`

    return ApiService.post(renewURL, {})
      .then(async (resp) => {
        if (window.sharedVue) {
          const status = resp?.status || resp?.response?.status
          if (status === 503) {
            window.sharedVue.config.globalProperties.$store.commit('Alert/setAlert', disabledCartAlert)
          } else if (status === 422) {
            handleSessionCancelStatus()
          } else {
            const currentUserId = resp?.data?.response?.userId || userId
            this.handleModernAutoLoginResponse(currentUserId, resp)
            let currentBasketResponse
            const { featureApiSessionOrder } = window.appConfig.featureFlags
            if (window.appConfig.superUserEnabled && featureApiSessionOrder) {
              currentBasketResponse = await SuperUserApi.getCurrentBasketId(currentUserId)
            } else {
              currentBasketResponse = await CartAPI.getCurrentBasketId(currentUserId)
            }
            this.handleCurrentBasketIdResponse(currentBasketResponse)
          }
        }
        return resp
      })
  },
  handleModernAutoLoginResponse(currentUserId, resp = {}) {
    if (!currentUserId || currentUserId.length < 2) { // check for valid userId
      window.sharedVue.config.globalProperties.$trackClientLog('autologinrenew_missing_userId', {
        currentUserId,
        response: resp
      }, LOGGING_TYPE.exception)
    }
    window.sharedVue.config.globalProperties.$store.commit('UserProfile/setCurrentUserPromise', null)
    window.sharedVue.config.globalProperties.$store.commit('UserProfile/setCurrentUserId', currentUserId)
  },
  handleCurrentBasketIdResponse(response) {
    if (response?.data) {
      const { basketId } = response?.data || {}
      if (response.status === 200 && basketId) {
        window.sharedVue.config.globalProperties.$store.commit('Cart/setBasketId', basketId)
      }
    }
  }
}
