import AutoLoginAPI from 'api/AutoLoginAPI'

export function workingCutOff() {
  const vue = window.sharedVue.config.globalProperties
  vue.$store.dispatch('CutOff/initializeCutoff')

  vue.$trackClientLog('slots_cutoff', {
    orderStatus: 'NOT_SUBMITTED',
    event: 'SESSION_CACHE_REFRESH'
  })
  vue.$store.commit('Alert/setAlert', {
    icon: false,
    header: 'Time not reserved',
    primary: {
      text: 'Select a Time',
      callback: () => {
        vue.$store.commit('Alert/clear')
        vue.$store.commit('ServiceSelection/setCurrentView', 'T')
        vue.$store.dispatch('Slots/displaySlots')
        vue.$store.commit('Modals/setActiveModal', {
          fileName: 'PdlServiceSelectionModal',
        })
        vue.$store.dispatch('CutOff/initializeCutoff')
      }
    },
    body: 'You\'ve reached cutoff time and your selection has been lost. Please choose a new time.'
  })
  vue.$store.commit('CutOff/setCutOffUpdateInProgress', false)
}

export function submittedCutOff() {
  const vue = window.sharedVue.config.globalProperties
  vue.$store.dispatch('CutOff/initializeCutoff')

  const serviceLocationId = vue.$store.getters['UserProfile/serviceLocationId']
  const apiVersion = 'v3.0'
  vue.$store.commit('Alert/setAlert', {
    icon: false,
    header: 'Order Finalizing',
    primary: {
      text: 'Start New Order',
      callback: () => {
        AutoLoginAPI.perform(apiVersion, serviceLocationId)
          .then(() => {
            vue.$store.dispatch('CutOff/activateCutoffAlertFlag', false)
            vue.$router.push('/home')
            window.location.reload()
            vue.$store.commit('Alert/clear')
          })
      }
    },
    body: 'We are finalizing your order and can no longer accept any updates.'
  })
  vue.$store.commit('CutOff/setCutOffUpdateInProgress', false)
}
