<template>
  <div :class="wrapperClasses">
    <label
      v-if="showLabel"
      class="forms_label"
      :for="id"
    >
      {{ inputLabel }}
      <span
        v-if="secondaryLabelText"
        class="text-gray-600"
      >
        {{ secondaryLabelText }}
      </span>
      <PdlCommonTooltip
        v-if="hasTooltip"
        :message="tooltipMessage"
      />
    </label>
    <!-- normal text input -->
    <input
      :id="id"
      ref="inputTextRef"
      :value="modelValue"
      :type="computedType"
      :name="fieldName"
      :class="[
        'forms_input',
        inputClass,
        { 'error': error },
      ]"
      :required="isRequired"
      :maxlength="maxLength"
      :aria-describedby="descriptionId"
      :autocomplete="autocomplete"
      :disabled="disabled"
      :placeholder="placeholderText"
      :pattern="computedPattern"
      @input="handleInputChange($event)"
      @blur="emitBlur($event)"
      @change="emitChange"
      @keyup="onKeyup($event)"
    >
    <div :id="descriptionId">
      <p
        v-if="error"
        aria-live="polite"
        class="text--base-strong text--base-error"
      >
        <slot name="errors" />
      </p>
      <p
        v-if="hasDescription"
        class="pdl-basic-text-input_field-description leader--single"
      >
        <slot name="description" />
      </p>
      <p
        v-if="hasHelperText"
        class="pdl-basic-text-input leader--single font-size-14"
      >
        <slot name="helperText" />
      </p>
    </div>
  </div>
</template>

<script>
import PdlCommonTooltip from '@/shared/interactions/PdlCommonTooltip'
import focusRef from 'utils/mixins/focusRef'
import replaceSmartPunctuations from 'utils/filters/replaceSmartPunctuations'

export default {
  components: {
    PdlCommonTooltip
  },
  mixins: [focusRef],
  props: {
    id: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: 'text',
      validator: value => ['text', 'tel', 'number', 'password', 'email'].indexOf(value) !== -1
    },
    fieldName: {
      type: String,
      required: true
    },
    inputLabel: {
      type: String,
      required: true
    },
    inputClass: {
      type: String,
      default: ''
    },
    wrapperClass: {
      type: Array,
      default() {
        return []
      }
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    maxLength: {
      type: Number,
      default: null
    },
    hasDescription: {
      type: Boolean,
      default: false
    },
    hasHelperText: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: [String, Number],
      default: ''
    },
    autocomplete: {
      type: String,
      default: 'on'
    },
    error: {
      type: Boolean,
      default: false
    },
    isDirty: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    password: {
      type: Boolean,
      default: false
    },
    placeholderText: {
      type: String,
      default: ''
    },
    instantUpdate: {
      type: Boolean,
      default: false
    },
    hasTooltip: {
      type: Boolean,
      default: false
    },
    tooltipMessage: {
      type: String,
      default: ''
    },
    secondaryLabelText: {
      type: String,
      default: ''
    },
    showLabel: {
      type: Boolean,
      default: true
    },
    delayTimeout: {
      type: Number,
      default: 500
    },
    isMobileDevice: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      inputTimeout: null,
      currentValue: this.value,
      showPassword: false
    }
  },
  computed: {
    computedType() {
      const passwordToggle = (this.showPassword) ? 'text' : 'password'
      return (this.password || this.type === 'password') ? passwordToggle : this.type
    },
    errorMessageId() {
      return `${this.id}-errors`
    },
    descriptionId() {
      return `${this.id}-description`
    },
    wrapperClasses() {
      return [
        'forms_input-container',
        ...this.wrapperClass
      ]
    },
    passwordToggleLabel() {
      return this.showPassword ? 'Hide' : 'Show'
    },
    computedPattern() {
      return this.type === 'number' || this.type === 'tel' ? '\\d*' : ''
    }
  },
  methods: {
    // handle all validation on keypress, but emit
    // value changes after user has stopped
    // providing input for specified timeout
    handleInputChange($event) {
      const that = this
      this.currentValue = replaceSmartPunctuations($event.target.value)
      if (this.instantUpdate) {
        this.$emit('update:modelValue', this.currentValue)
      } else {
        clearTimeout(this.inputTimeout)
        this.inputTimeout = setTimeout(() => {
          that.$emit('update:modelValue', that.currentValue)
        }, this.delayTimeout)
      }
    },
    emitChange($event) {
      this.$emit('change', replaceSmartPunctuations($event.target.value))
    },
    emitBlur($event) {
      this.$emit('blur', replaceSmartPunctuations($event.target.value))
    },
    onKeyup($event) {
      if ($event.key === 'Enter') {
        return this.onEnterKey($event)
      }
      return this.$emit('keyup', $event)
    },
    onEnterKey($event) {
      const uagent = navigator.userAgent.toLowerCase()
      if ((this.isMobileApp || uagent.includes('mobile')) && $event.srcElement.form !== null) {
        this.setFocusonNext($event)
      }
      this.$emit('enter', replaceSmartPunctuations($event.target.value))
    }
  },
  emits: ['blur', 'change', 'enter', 'keyup', 'update:modelValue']
}
</script>
