<script>
/** Mixin PdlInputBaseMixin - Standardizes Input Props
* */
import checkStartsWithDollar from 'utils/filters/checkStartsWithDollar'
import checkSafeId from 'utils/filters/checkSafeId'
import formatSafeId from 'utils/filters/formatSafeId'
import replaceSmartPunctuations from 'utils/filters/replaceSmartPunctuations'
import InputMessages from './PdlInputMessages'
import PdlInputLabel from './PdlInputLabel'

export default {
  components: {
    InputMessages,
    PdlInputLabel
  },
  props: {
    type: {
      type: String,
      default: 'text',
      validator(value) {
        // The value must match one of these strings
        return (
          [
            'hidden',
            'select',
            'checkbox',
            'radio',
            'textarea',
            'text',
            'email',
            'number',
            'password',
            'search',
            'tel'
          ].indexOf(value) !== -1
        )
      }
    },
    formId: {
      type: String,
      required: true,
      validator: v => checkSafeId(v)
    },
    name: {
      type: String,
      required: true,
      validator: v => checkSafeId(v)
    },
    label: {
      type: String,
      required: true
    },
    tagLine: {
      type: String,
      default: ''
    },
    optLabel: {
      type: String,
      default: ''
    },
    subLabel: {
      type: String,
      default: ''
    },
    hideLabel: {
      type: Boolean,
      default: false
    },
    boldLabel: {
      type: Boolean,
      default: false
    },
    wrapperClasses: {
      type: [Array],
      default: () => ([])
    },
    inputClasses: {
      type: [Array],
      default: () => ([])
    },
    description: {
      type: String,
      default: ''
    },
    errorMessages: {
      type: Object,
      default: () => ({})
    },
    persistentMessages: {
      type: Array,
      default: () => ([])
    },
    v: {
      type: Object,
      default: () => ({})
    },
    attrs: {
      type: Object,
      default: () => ({})
    },
    pattern: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    id() {
      return `${this.formId}-${formatSafeId(this.name)}-${this.type}`
    },
    ariaLabel() {
      return `${this.label}`
    },
    hasErrors() {
      return this.v.$error
    },
    errors() {
      const errors = _.keys(
        _.pick(this.v, (item, key) => !checkStartsWithDollar(key) && this.v[key]?.$invalid)
      )
      return _.reduce(
        errors,
        (arr, error) => {
          if (this.errorMessages[error]) {
            arr.push({
              name: error,
              value: this.errorMessages[error]
            })
          }
          return arr
        },
        []
      )
    },
    persistentMessagesList() {
      return _.map(this.persistentMessages, (message) => {
        message.conditions = message.conditions || []
        const invalid = message.conditions.find(condition => this.v.$errors.some(er => condition === er.$validator))
        message.invalid = !!invalid
        message.dirty = this.v.$dirty
        return message
      }, this)
    },
    hasPersistentMessages() {
      return this.persistentMessages.length > 0
    },
    hasMessages() {
      return this.hasPersistentMessages || this.description || this.hasErrors
    },
    labelProps() {
      const {
        id,
        label,
        hideLabel,
        optLabel,
        tagLine,
        boldLabel
      } = this || {}

      return {
        id,
        label,
        hideLabel,
        optLabel,
        tagLine,
        boldLabel
      }
    }
  },
  methods: {
    triggerInput(event) {
      const { value } = event.target
      let newVal = ''
      if (this.type === 'email') {
        newVal = value.toLowerCase()
      } else { newVal = value }
      this.$emit('update:modelValue', replaceSmartPunctuations(newVal))
    }
  }
}
</script>
