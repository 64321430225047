<template>
  <div
    class="field"
    :class="wrapperClasses"
  >
    <PdlInputLabel
      v-bind="labelProps"
    >
      <slot name="label" />
    </PdlInputLabel>
    <slot
      class="field_row"
      name="before"
    />
    <div
      class="field_row relative"
    >
      <input
        v-bind="attrs"
        :value="modelValue"
        :id="id"
        :ref="'input'"
        :name="name"
        :aria-label="ariaLabel"
        :form-id="formId"
        :type="computedType"
        :pattern="computedPattern"
        class="forms_input forms_input--password"
        :class="inputClasses"
        :required="required"
        :maxlength="maxLength"
        autocorrect="off"
        autocapitalize="off"
        :autocomplete="autocomplete"
        :aria-describedby="hasMessages ? `${id}-messages` : null"
        :disabled="disabled"
        @input.stop="triggerInput"
        @keyup.stop="onKeyup($event)"
        @blur.stop="emitInputBlur($event)"
      >
      <button
        :id="`${id}-${passwordToggleLabel}`"
        ref="passwordToggle"
        class="button forms_password-toggle-button button--white-no-border forms_password-toggle-button--light"
        type="button"
        :aria-label="`${passwordToggleLabel} Password`"
        @click.prevent="showPassword = !showPassword"
        @blur.stop="tryDisableWarning"
      >
        {{ passwordToggleLabel }}
      </button>
    </div>
    <slot
      class="field_row"
      name="after"
    />
    <p
      v-if="error"
      aria-live="polite"
      class="text--base-strong text--base-error"
    >
      <slot name="errors" />
    </p>
    <InputMessages
      :id="`${id}-messages`"
      class="field_row"
      :errors="errors"
      :description="description"
      :has-errors="hasErrors"
      :has-persistent-messages="hasPersistentMessages"
      :persistent-messages="persistentMessagesList"
    />
    <p
      v-if="capsLockOn"
      class="pdl-basic-text-input_field-description leader--single"
    >
      <span class="vector-icon_inline--left">
        <PdlIconBase
          icon-title="warning icon"
          icon-class="vector-icon-fill--warning vector-icon_inline"
          container-class="vector-icon-size--medium"
        >
          <PdlIconWarning />
        </PdlIconBase>
      </span><span class="text--base-strong tw-warning">Caps lock is on</span>
    </p>
  </div>
</template>
<script>
import {
  PdlIconBase,
} from '@/shared/icons'
import PdlIconWarning from 'components/icons/icon/PdlIconWarning'
import { MAX_PASSWORD_LENGTH } from 'utils/constants'
import focusRef from 'utils/mixins/focusRef'
import formatSafeId from 'utils/filters/formatSafeId'
import checkSafeId from 'utils/filters/checkSafeId'
import InputBase from './PdlInputBaseMixin'

export default {
  components: {
    PdlIconBase,
    PdlIconWarning
  },
  mixins: [InputBase, focusRef],
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'password',
      validator(value) {
        // The value must match one of these strings
        return (
          [
            'password'
          ].indexOf(value) !== -1
        )
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    maxLength: {
      type: Number,
      default: MAX_PASSWORD_LENGTH
    },
    name: {
      type: String,
      default: 'password',
      validator: v => checkSafeId(v)
    },
    label: {
      type: String,
      default: 'Password'
    },
    isMobileDevice: {
      type: Boolean,
      default: false,
    },
    autocomplete: {
      type: String,
      default: 'off'
    },
    fieldId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      showPassword: false,
      capsLockOn: false
    }
  },
  computed: {
    computedPattern() {
      if (this.pattern) {
        return this.pattern
      }
      return ''
    },
    computedType() {
      if (this.showPassword) {
        return 'text'
      }
      return 'password'
    },
    passwordToggleLabel() {
      return this.showPassword ? 'Hide' : 'Show'
    },
    id() {
      return (this.fieldId) ? this.fieldId : `${this.formId}-${formatSafeId(this.name)}-${this.type}`
    },
  },
  methods: {
    emitInputBlur($event) {
      this.tryDisableWarning($event)
      this.$emit('blur', $event)
    },
    onKeyup($event) {
      if ($event.key === 'Enter') {
        return this.onEnterKey($event)
      }
      this.checkCapsLock($event)
      return this.$emit('keyup', $event)
    },
    onEnterKey($event) {
      const uagent = navigator.userAgent.toLowerCase()
      if ((this.isMobileApp || uagent.includes('mobile')) && $event.srcElement.form !== null) {
        this.setFocusonNext($event)
      }
      this.$emit('enter', $event.target.value)
    },
    checkCapsLock($event) {
      if (this.isMobileDevice) {
        return this.disableCapsLock()
      }
      const event = $event || {}

      const isCapsLockOn = event?.getModifierState('CapsLock')

      this.capsLockOn = isCapsLockOn
      this.$emit('capslock-check', isCapsLockOn)
      return isCapsLockOn
    },
    tryDisableWarning($event) {
      if (this.isMobileDevice) return this.disableCapsLock()
      const elements = Object.values(this.$refs)
      const found = elements.indexOf($event.relatedTarget)
      if (found === -1) return this.disableCapsLock()
      return true
    },
    disableCapsLock() {
      this.capsLockOn = false
      return false
    }
  },
  emits: ['update:modelValue', 'keyup', 'capslock-check', 'enter', 'blur']
}
</script>
