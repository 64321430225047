<!-- eslint-disable vue/html-closing-bracket-spacing !-->
<template>
  <g>
    <polyline
      points="5.61 10.13 5.61 5.3 10.43 5.3"
    />
    <polyline
      points="19.57 5.3 24.39 5.3 24.39 10.13"
    />
    <polyline
      points="24.39 19.87 24.39 24.7 19.57 24.7"
    />
    <polyline
      points="10.43 24.7 5.61 24.7 5.61 19.87"
    />
    <line
      x1="2.74"
      y1="15"
      x2="27.26"
      y2="15"
    />
  </g>
</template>
