import { createApp } from 'vue'
import { createStore } from 'vuex'
import Vue3TouchEvents from 'vue3-touch-events'
import VueCookies from 'vue-cookies'
import { MODAL_URL_MAPS } from 'utils/constants/modal-mapping'
import { logoutSuperUser } from 'utils/interceptors/super-user-interceptor'
import { sessionExpired, sessionTimeoutHttp } from 'utils/interceptors/session/session-expire-http-interceptor'
import { pendingRejection, pendingResponse } from 'utils/interceptors/pending-requests-http-interceptor'
import { authHttpInterceptor } from 'utils/interceptors/auth-http-interceptor'
import { sessionCacheObserverService } from 'components/cache/services/session-cache-observer-service'
import optimizelyService from 'components/user-research/services/optimizely-service'
import { initializeAccessibilityEvents } from 'shared/util/accessibility'
import { initAdobeAnalytics } from 'utils/services/adobe'
import { getNuxtRoute, shouldRerouteToNuxt } from 'utils/routing/nuxtRouteCheck'
import { routeBack } from 'utils/routing/routeBack'
import { apolloProvider } from 'api/graphql/apollo'
import { sharedInstaller } from './shared/installer'
import { pluginsInstaller } from './plugins/installer'
import { modules, plugins } from './store'
import PdlSharedApp from './PdlSharedApp'
import router from './router'

window.initPhase = {
  flag: true
}

window.$store = createStore({
  strict: !IS_PRODUCTION,
  modules,
  plugins
})


window.isPopstate = false
window.addEventListener('popstate', () => {
  window.isPopstate = true
})

const updateBreadcrumbs = ({ name, path, query }) => {
  const next = {
    name,
    path,
    query
  }
  window.$store.commit('TitleBar/storeBreadcrumb', next)
}
const breadCrumbsContain = (breadcrumbs, value) => {
  return !!(breadcrumbs.find(crumb => (crumb.path === value)))
}

if (window?.appConfig?.featureFlags?.featureBloomreachRoutes) {
  window.$store.dispatch('Nuxt/getNuxtAvailableRoutes')
}

router.beforeEach((to, from, next) => {
  if (shouldRerouteToNuxt(to.path)) {
    window.location.href = getNuxtRoute(to.path)
    return
  }

  // reset popstate (popstate occurs after routing)
  window.isPopstate = false

  if (routeBack(to, from)) {
    router.back()
    return
  }

  to.meta.navigatedFrom = from.name

  if (to.name === 'manage-cookies') {
    next()
    return
  }
  // allow for native app to identify context with 'native' query param
  // NativeContainer will pick up this session storage value
  const nativeQueryPresent = from.query.native || to.query.native
  if (nativeQueryPresent) {
    sessionStorage.setItem('nativeApp', true)
  }

  if (Object.keys(MODAL_URL_MAPS).includes(to.path)) {
    setTimeout(() => {
      window.$store.commit('Modals/setActiveModal', {
        fileName: MODAL_URL_MAPS[to.path],
      })
    })
    const currentPath = from.path || '/'
    next({ path: currentPath })
    return
  }

  const breadcrumbs = window.$store.getters['TitleBar/breadcrumbs']
  if (`${to.path}` === `${breadcrumbs[breadcrumbs.length - 2]?.path}`) {
    window.$store.commit('TitleBar/removeLastBreadcrumb')
  } else {
    const pathArr = to.path.split('/')
    const mustAddBaseCrumb = (
      `${to.path}`.includes('/help/')
      && !!pathArr[1]
      && !breadCrumbsContain(breadcrumbs, '/help')
    )

    if (mustAddBaseCrumb) {
      updateBreadcrumbs({
        name: 'help',
        path: '/help'
      })
    }
    updateBreadcrumbs(to)
  }
  next()
})

// creates a shared instance for global store access & router access
window.sharedVue = createApp({
  ...PdlSharedApp,
})
window.sharedVue.use(window.$store)
window.sharedVue.use(router)
window.sharedVue.use(apolloProvider)
window.sharedVue.use(Vue3TouchEvents)
window.sharedVue.use(sharedInstaller)
window.sharedVue.use(pluginsInstaller) // also loads GTM to window
window.sharedVue.use(VueCookies)
window.sharedVue.config.globalProperties.$store = window.$store

/* add interceptors for when in super user */
/* for each response check if session in user - otherwise logout */
if (appConfig.superUserEnabled) {
  window.http.interceptors.request.use(
    request => sessionTimeoutHttp(request),
    error => Promise.reject(error)
  )
  window.http.interceptors.response.use(
    response => response,
    error => logoutSuperUser(error)
  )
}

/* add interceptors */
window.http.interceptors.request.use(
  request => sessionTimeoutHttp(request),
  error => Promise.reject(error)
)
window.http.interceptors.response.use(
  response => response,
  error => sessionExpired(error)
)
window.http.interceptors.response.use(
  response => response,
  error => authHttpInterceptor(error)
)
window.http.interceptors.response.use(
  response => pendingResponse(response),
  error => pendingRejection(error)
)

// Add order refresh calls to the session cache observer
sessionCacheObserverService.addDependent({
  refresh: () => {
    window.sharedVue.config.globalProperties.$store.dispatch('Order/initOrderStatus', { canRefresh: true })
    window.sharedVue.config.globalProperties.$store.dispatch('Cart/queryCart')
  }
})

const appInit = () => {
  const appId = 'app'
  const appElement = document.getElementById(appId)
  if (appElement) {
    window.sharedVue.mount('#shared')
  }
}

function bootstrap(main) {
  if (document.readyState === 'complete') {
    main()
  } else {
    document.addEventListener('DOMContentLoaded', main)
  }
}

bootstrap(appInit)

let currentSpyglassDate

// Attaching event listener for plug-in events
document.addEventListener('dateSwitch', (event) => {
  const details = event.detail
  const spyglassDate = window.dateFormat(new Date(details), 'UTC:yyyy-mm-dd')
  window.sharedVue.config.globalProperties.$store.commit('ScheduledContent/updateDate', spyglassDate)
  if (currentSpyglassDate !== details) {
    currentSpyglassDate = details
    window.sharedVue.config.globalProperties.$emitter.emit('content-refresh')
    window.sharedVue.config.globalProperties.$store.commit('ScheduledContent/toggleSpyglass', true)
  }
}, false)

document.addEventListener('ppdOptimizely', (event) => {
  optimizelyService.notifyOfActiveVariation(event.detail)
}, false)


/* Accessiblity */
initializeAccessibilityEvents()
/* end of accessibility */

document.addEventListener('DOMContentLoaded', () => {
  setTimeout(initAnalytics, 4500)
})
document.addEventListener('scroll', initGTMOnEvent)
document.addEventListener('mousemove', initGTMOnEvent)
document.addEventListener('touchstart', initGTMOnEvent)

function initGTMOnEvent(event) {
  initAnalytics()
  event.currentTarget.removeEventListener(event.type, initGTMOnEvent) // remove the event listener that got triggered
}

function initAnalytics() {
  if (window.gtmDidInit) {
    return false
  }
  initAdobeAnalytics()
  window.gtmDidInit = true // flag to ensure script does not get added to DOM more than once.
  const script = document.createElement('script')
  script.type = 'text/javascript'
  script.class = 'optanon-category-C0001'
  script.async = true
  script.onload = () => {
    window.dataLayer.push({
      event: 'gtm.js',
      'gtm.start': new Date().getTime(),
    })
  } // this part ensures PageViews is always tracked
  script.src = 'https://www.googletagmanager.com/gtm.js?id=GTM-PTR8VBJ'
  document.head.appendChild(script)
  return true
}
