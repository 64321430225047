export const ORDER_HISTORY_TABS = [
  {
    id: 'online',
    title: 'Online'
  },
  {
    id: 'in-store',
    title: 'In Store'
  }
]

export const ORDER_HISTORY_DEFAULT_TAB = 'online'
