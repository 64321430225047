import ApiService from '@/api/'

export default {
  getUserSubscriptions(userId) {
    return ApiService.get(`/api/v4.0/delivery-subscriptions/${userId}/user`)
  },
  getAvailableSubscriptions(payload = {}) {
    const { userId, serviceLocationId } = payload
    const params = {
      serviceLocationId
    }
    return ApiService.get(
      `/api/v4.0/delivery-subscriptions/${userId}/user/available`, { params }
    )
  },
  purchasePodPass({
    serviceLocationId, capsId, subscriptionId, freeTrial, userId
  }) {
    const params = {
      subscriptionId,
      freeTrial,
      capsId,
      serviceLocationId
    }
    if (freeTrial) {
      params.resource = 'active'
    }
    return ApiService.put(`/api/v4.0/delivery-subscriptions/${userId}/user/active`, null, { params })
  },
  cancelRenewal(userId) {
    return ApiService.delete(`/api/v4.0/delivery-subscriptions/${userId}/user/pending`)
  },
  setPendingSubscription(payload) {
    const {
      serviceLocationId, capsId, subscriptionId, userId
    } = payload
    const params = {
      capsId,
      serviceLocationId,
      subscriptionId,
    }
    return ApiService.put(`/api/v4.0/delivery-subscriptions/${userId}/user/pending`, null, { params })
  }
}
