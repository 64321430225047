import {
  determineEnvironment,
  getEnvironmentAbbreviation,
} from 'utils/services/graphql/environment'

export const NUXT_SUB_DOMAIN = {
  prod: 'www2',
  stage: 'alpha-prism',
  qa: 'alpha-prism',
  dev: 'alpha-prism',
}

const STORE_LOCATOR_ROUTE = /^\/store-locator/
const whitelist = [STORE_LOCATOR_ROUTE]

export const getNuxtSubdomain = () => {
  const env = determineEnvironment(window.location.hostname, window.appConfig.opcoId)
  const envAbbrev = getEnvironmentAbbreviation(env)
  return NUXT_SUB_DOMAIN[envAbbrev]
}

export const getNuxtRoute = (path) => {
  const subdomain = getNuxtSubdomain()
  const splitHost = window.location.hostname.split('.')
  const splitHostLength = splitHost.length
  const query = window.location.search
  return `https://${subdomain}.${splitHost[splitHostLength - 2]}.${splitHost[splitHostLength - 1]}${path}${query}`
}

export const shouldRerouteToNuxt = (path) => {
  const nuxtAvailableRoutes = window.$store.state.Nuxt.routes
  const containsBloomreachRoutes = window?.appConfig?.featureFlags?.featureBloomreachRoutes
    && nuxtAvailableRoutes.some((route) => {
      const stringRegExp = `^\\${route}`
      const nuxtRouteRegExp = new RegExp(stringRegExp)
      return nuxtRouteRegExp.test(path)
    })
  const containsStaticNuxtRoutes = window?.appConfig?.featureFlags?.featureNuxtRoutes
    && whitelist?.some(regex => regex.test(path))

  return containsBloomreachRoutes || containsStaticNuxtRoutes
}
