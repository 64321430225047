<template>
    <svg>
        <path v-if="opco === ''" fill="#001919" d="M16 25 4.742 10.75h22.516z"/>
        <path v-if="opco === 'STSH'" fill="#E0004D" d="M16 25 4.742 10.75h22.516z"/>
        <path v-if="opco === 'MRTN' || 'GNTC'" fill="#DD1E0D" d="M16 25 4.742 10.75h22.516z"/>
        <path v-if="opco === 'GNTL'" fill="#702076" d="M16 25 4.742 10.75h22.516z"/>
        <path v-if="opco === 'FDLN'" fill="#005695" d="M16 25 4.742 10.75h22.516z"/>
        <path v-if="opco === 'HANF'" fill="#242021" d="M16 25 4.742 10.75h22.516z"/>
    </svg>
</template>
<script>
export default {
  props: {
    opco: {
      type: String,
      default: ''
    }
  }
}
</script>
