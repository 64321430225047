/* eslint no-unused-expressions: ["error", { "allowTernary": true }] */
import LoyaltyProgramsApi from 'api/LoyaltyProgramsAPI'
import ProductApi from 'api/productApi'
import dateWithinRange from 'utils/filters/dateWithinRange'
import truncateDecimals from 'utils/filters/truncateDecimals'

const normalizedBaseOffer = {
  accumGoal: null,
  accumValue: null,
  badgeTxt: null,
  conditionTxt: null,
  descTxt: null,
  earnValue: null,
  endDate: null,
  headerTxt: null,
  image2: null,
  image: null,
  legalTxt: null,
  limitsReached: null,
  nameTxt: null,
  id: null,
  type: null,
  rewardType: null,
  optedIn: null,
  percentComplete: null,
  pinPriorityId: null,
  programTypeTxt: null,
  promoClassId: null,
  redeemValue: null,
  rewardTxt: null,
  startDate: null,
  subDescTxt: null,
  titleTxt: null,
  unit: null
}

function setupOffer(offer) {
  return {
    ...normalizedBaseOffer,
    accumValue: offer.accumulationValue,
    ...offer.badge && { badgeTxt: offer.badge },
    descTxt: offer.description,
    endDate: offer.endDate,
    ...offer.header && { headerTxt: offer.header },
    ...offer.imageUrl2 && { image2: offer.imageUrl2 },
    image: offer.imageUrl,
    legalTxt: offer.legalText,
    limitsReached: offer.limitsReached,
    nameTxt: offer.name,
    id: offer.id,
    type: offer.offerType,
    rewardType: offer.rewardType,
    optedIn: offer.optedIn,
    ...offer.pinPriorityId && { pinPriorityId: offer.pinPriorityId },
    ...offer.programType && { programTypeTxt: offer.programType },
    ...offer.programType && { promoClassId: offer.promoClassId },
    startDate: offer.startDate,
    ...offer.subDescription && { subDescTxt: offer.subDescription },
    titleTxt: offer.title
  }
}

function normalizeOffers(offers, { denomination, opco }) {
  const normalizedOffers = []
  _.each(offers, (offer) => {
    const newOffer = setupOffer(offer)

    if (
      offer.accumulationType1
      && offer.trackingType?.toLowerCase() === offer.accumulationType1?.toLowerCase()
    ) {
      newOffer.accumGoal = offer.accumulationQuantity1
      newOffer.unit = offer.accumulationType1
    } else if (
      offer.accumulationType2
      && offer.trackingType?.toLowerCase() === offer.accumulationType2?.toLowerCase()
    ) {
      newOffer.accumGoal = offer.accumulationQuantity2
      newOffer.unit = offer.accumulationType2
    } else if (offer.accumulationThreshold) {
      newOffer.accumGoal = truncateDecimals(offer.accumulationThreshold, 0)
      newOffer.unit = offer.accumulationType
    }

    if (opco === 'FDLN') {
      newOffer.conditionTxt = offer.name
      newOffer.earnValue = offer.earnValue
    } else {
      newOffer.conditionTxt = offer.description
      newOffer.earnValue = offer.earnValue
    }
    const match = newOffer?.nameTxt && newOffer.nameTxt.match(/([1-9]?\d[Xx]) /)
    if (match?.[1]) {
      newOffer.rewardTxt = match?.[1] // e.g. Earn 3x points offers
    } else if (denomination === 'dollars') {
      newOffer.rewardTxt = `$${newOffer.earnValue}` // e.g. Opco uses direct dollar offers
    } else {
      newOffer.rewardTxt = newOffer.earnValue // e.g. Opco reward point offers
    }
    newOffer.percentComplete = newOffer.accumGoal ? Math.min((newOffer.accumValue / newOffer.accumGoal) * 100, 100) : 0
    normalizedOffers.push(newOffer)
  })

  return normalizedOffers
}

function sortAscending(prop) {
  return ({ [prop]: valA }, { [prop]: valB }) => (valB === null ? -1 : valA - valB)
}

function sortDescending(prop) {
  return ({ [prop]: valA }, { [prop]: valB }) => (valB === null ? -1 : valB - valA)
}

export default {
  namespaced: true,
  state: {
    initialized: false,
    fetchingOffers: false,
    bonusOffers: [],
    redeemedBonusOffers: [],
    loadedSpecialOffers: [],
    allSpecialOffers: [],
    failedToLoadBonus: false,
    failedToLoadSpecial: false,
    failedToLoadDonationsSummary: false,
    charityContent: [],
    currentOfferId: null,
    currentOfferProducts: [],
    currentOfferPagination: {},
    currentOfferDetails: normalizedBaseOffer,
    groceryPointsToRedeem: 0,
    gasPointsToRedeem: 0,
    donationsOffer: null,
    donationsPointsToRedeem: 0,
    donationsSummary: null,
    donationsUserTotal: null,
    donationsTotal: null,
    shopOffers: [],
    shopOffersInitialized: false,
    newBonusOffers: [],
    monthlyOffersSeen: null,
    bonusOffersSeen: [],
    newSpecialOffers: [],
    specialOffersSeen: [],
    offersPromise: null,
    pointsDonated: {},
  },
  getters: {
    failedToLoadDonations: state => !state.charityContent,
    hasMoreProducts: (state) => {
      const { total, start, rows } = state.currentOfferPagination
      return total > start + rows
    },
  },
  mutations: {
    setBonusOffers(state, payload) {
      // Short-circuit if we don't get any data from the end-point
      if (payload.offers === '') {
        return
      }
      const trackOffers = payload.offers
        .filter(({ type }) => type === 'continuity')
        .sort(sortDescending('earnValue'))
      const offers = payload.offers
        .filter(({ type }) => type === 'bonus')
        .sort(sortDescending('earnValue'))

      state.bonusOffers = [...trackOffers, ...offers]
        .map((offer, index) => {
          // finalize index after sorting
          // analytics wants 1-based indexing, rather than 0-based
          offer.index = index + 1
          return offer
        })
    },
    setSpecialOffers(state, payload) {
      const loaded = []

      state.allSpecialOffers = payload.offers
        .map((offer) => {
          offer.value = offer.redeemValue
          offer.titleTxt = offer.name
          offer.descTxt = offer.description
          offer.nameTxt = offer.name
          offer.type = offer.offerType
          offer.rewardType = 'SPECIAL'

          if (offer.optedIn) {
            loaded.push(offer)
          }

          return offer
        })
        .sort(sortAscending('value'))
        .map((offer, index) => {
          // finalize index after sorting
          // analytics wants 1-based indexing, rather than 0-based
          offer.index = index
          return offer
        })

      state.loadedSpecialOffers = loaded
    },
    setDonationsOffers(state, payload) {
      state.donationsOffer = payload[0] ? payload[0] : null
    },
    setCurrentOffer(state, payload) {
      state.currentOfferDetails = payload
      state.currentOfferId = payload.id
      state.currentOfferPagination = {}
      state.currentOfferProducts = []
    },
    setGroceryPointsToRedeem(state, payload) {
      state.groceryPointsToRedeem = payload
    },
    setGasPointsToRedeem(state, payload) {
      state.gasPointsToRedeem = payload
    },
    setDonationsPointsToRedeem(state, payload) {
      state.donationsPointsToRedeem = payload
    },
    setDonationsSummaryData(state, payload) {
      const {
        userTotal: donationsUserTotal,
        programTotal: donationsTotal,
        donations: donationsSummary
      } = payload

      state.donationsUserTotal = donationsUserTotal
      state.donationsTotal = donationsTotal
      state.donationsSummary = donationsSummary
    },
    setCurrentOfferProducts(state, payload) {
      state.currentOfferProducts = payload
    },
    setCurrentOfferPagination(state, payload) {
      state.currentOfferPagination = payload
    },
    setInitialized(state, payload) {
      state.initialized = payload
    },
    setShopOffersInitialized(state, payload) {
      state.shopOffersInitialized = payload
    },
    setFailedToLoadBonus(state, payload) {
      state.failedToLoadBonus = payload
    },
    setFailedToLoadSpecial(state, payload) {
      state.failedToLoadSpecial = payload
    },
    setFailedToLoadDonationsSummary(state, payload) {
      state.failedToLoadDonationsSummary = payload
    },
    setShopOfferTile(state, bonusOffers) {
      const shopOffers = []
      bonusOffers.forEach((bonus) => {
        const offer = {
          points: {},
          image: {}
        }
        offer.prodId = bonus.offerId
        offer.points.point = bonus.earnValue
        offer.points.progress = bonus.accumValue
        offer.points.unit = bonus.unit
        offer.points.goal = bonus.accumGoal
        offer.points.percentComplete = bonus.percentComplete
        offer.points.description = bonus.descTxt
        offer.image.medium = bonus.image
        offer.image.large = bonus.image
        offer.rewardValue = bonus.earnValue
        offer.bonus = bonus
        shopOffers.push([offer])
      })
      state.shopOffers = shopOffers
    },
    setProductsToShopOffers(state, payload) {
      state.shopOffers.forEach((offer, index) => {
        offer.push(...payload[index].data.response.products)
      })
    },
    setFetchingOffers(state, payload) {
      state.fetchingOffers = payload
    },
    setNewBonusOffers(state, payload) {
      state.newBonusOffers = payload
    },
    setNewSpecialOffers(state, payload) {
      state.newSpecialOffers = payload
    },
    setBonusOffersSeen(state, payload) {
      state.bonusOffersSeen = payload
    },
    setSpecialOffersSeen(state, payload) {
      state.specialOffersSeen = payload
    },
    setOffersPromise(state, payload) {
      state.offersPromise = payload
    },
    addBonusOfferSeen(state, { id, date }) {
      state.bonusOffersSeen.push({ id, date })
    },
    addSpecialOfferSeen(state, { id, date }) {
      state.specialOffersSeen.push({ id, date })
    },
    setPointsDonated(state, payload) {
      state.pointsDonated = payload
    },
    setCharityContent(state, payload) {
      state.charityContent = payload
    },
    setMonthlyOffersSeen(state, payload) {
      state.monthlyOffersSeen = payload
    }
  },
  actions: {
    async updateLoyaltyOffers({ state, commit, dispatch }) {
      if (state.fetchingOffers) {
        return
      }

      commit('setFetchingOffers', true)
      commit('setInitialized', false)
      commit('setFailedToLoadBonus', false)
      commit('setFailedToLoadSpecial', false)
      await dispatch('getLoyaltyOffers')

      commit('setFetchingOffers', false)
    },
    async storeCurrentOffer({ commit }, payload) {
      commit('setCurrentOffer', payload)
    },
    async updateGroceryPointsToRedeem({ commit }, payload) {
      commit('setGroceryPointsToRedeem', payload)
    },
    async updateGasPointsToRedeem({ commit }, payload) {
      commit('setGasPointsToRedeem', payload)
    },
    async updateDonationsPointsToRedeem({ commit }, payload) {
      commit('setDonationsPointsToRedeem', payload)
    },
    // eslint-disable-next-line
    async getLoyaltyOffers({
      commit, dispatch, rootState, rootGetters
    }) {
      const { opco } = rootState.SiteConfig
      const cardNumber = rootGetters['UserProfile/retailerCardNumber']
      const orderId = rootGetters['UserProfile/userInfo'].information.currentOrderId
      const userId = rootGetters['UserProfile/userId']
      const denomination = rootGetters['SiteConfig/varByName']('brand_rewards_points_denomination')
      const { serviceLocationId } = rootGetters['UserProfile/deliveryServiceLocation']
      const loyaltyDonations = rootGetters['SiteConfig/varByName']('feature_loyalty_donations')
      const userData = { opco, cardNumber, userId }
      const apiCalls = [
        LoyaltyProgramsApi.getOffers(userData, {
          type: 'bonus,continuity',
          serviceLocationId,
          size: rootGetters['SiteConfig/varByName']('brand_rewards_max_bonus_offers'),
        }),
      ]

      if (rootGetters['SiteConfig/varByName']('feature_special_offers_enabled')) {
        apiCalls.push(LoyaltyProgramsApi.getOffers(userData, {
          type: 'lto',
          orderId,
          serviceLocationId,
          size: rootGetters['SiteConfig/varByName']('brand_rewards_max_earn_offers'),
        }))
      }

      const offersPromise = Promise.all(apiCalls)
      commit('setOffersPromise', offersPromise)
      const [bonusOffers, specialOffers] = await offersPromise
      bonusOffers.status === 200
        ? commit('setBonusOffers', {
          offers: normalizeOffers(bonusOffers.data, {
            denomination,
            opco
          }),
          opco
        })
        : commit('setFailedToLoadBonus', true)

      if (rootGetters['SiteConfig/varByName']('feature_special_offers_enabled')) {
        specialOffers.status === 200
          ? commit('setSpecialOffers', {
            offers: specialOffers.data,
          })
          : commit('setFailedToLoadSpecial', true)
      }
      if (loyaltyDonations) {
        await dispatch('fetchCharityContent')
      }
      if (opco === 'FDLN') {
        dispatch('checkNewMonthlyOffers')
      }
      if (rootGetters['SiteConfig/varByName']('feature_rewards_new_bonus_offers_notification')) {
        const seenBonusOffers = JSON.parse(window.localStorage.getItem('seen-bonus-offers')) || []
        const seenSpecialOffers = JSON.parse(window.localStorage.getItem('seen-special-offers')) || []

        commit('setBonusOffersSeen', seenBonusOffers)
        commit('setSpecialOffersSeen', seenSpecialOffers)

        dispatch('checkNewBonusOffers')
        dispatch('checkNewSpecialOffers')
      }

      commit('setInitialized', true)
    },
    async checkNewMonthlyOffers({ commit }) {
      const seenMonth = window.localStorage.getItem('monthly-offers-seen')
      // Convert to a string, otherwise is an int and will always be false
      // (e.g. '1' returned from local storage is not === to 1)
      const currentMonth = `${window.LuxonDateTime.local().month}`

      if (seenMonth !== currentMonth) {
        commit('setMonthlyOffersSeen', false)
      } else {
        commit('setMonthlyOffersSeen', true)
      }
    },
    dismissMonthlyOffersPopup() {
      const currentMonth = window.LuxonDateTime.local().month
      window.localStorage.setItem('monthly-offers-seen', currentMonth)
    },
    async checkNewBonusOffers({ state, dispatch }) {
      // new offers local storage logic
      const lastDate = window.localStorage.getItem('offers-last-seen')
      const lastDateConverted = window.LuxonDateTime.fromISO(lastDate)

      const currentDate = window.LuxonDateTime.local()
      const currentDateString = window.LuxonDateTime.local().toLocaleString(window.LuxonDateTime.DATE_SHORT)
      // if the local storage values have not been set
      // || previous set date was more than six days ago
      // else within the week range, checking for new ones that came in since the last viewing
      const filteredOfferList = state.bonusOffers.filter(offer => dateWithinRange(offer.startDate, 7))

      if ((!lastDate)
      || ((!!lastDate && lastDate !== currentDateString)
      && !dateWithinRange(lastDate, 7))) {
        // set local storage first time, or it was over 7 days ago
        dispatch('updateNewBonusOffers', filteredOfferList)
      } else if (filteredOfferList.length > 0) {
        // get range from date previously set, then see if any offers are newer and can be notified
        const range = currentDate.diff(lastDateConverted, 'days').toObject()
        const newOffers = filteredOfferList.filter(newOffer => dateWithinRange(newOffer.startDate, range.days))
        dispatch('updateNewBonusOffers', newOffers)
      }
    },
    async checkNewSpecialOffers({ state, commit }) {
      // new offers local storage logic
      const lastDate = window.localStorage.getItem('offers-last-seen')
      const lastDateConverted = window.LuxonDateTime.fromISO(lastDate)

      const currentDate = window.LuxonDateTime.local()
      const currentDateString = window.LuxonDateTime.local().toLocaleString(window.LuxonDateTime.DATE_SHORT)
      // if the local storage values have not been set
      // || previous set date was more than six days ago
      // else within the week range, checking for new ones that came in since the last viewing
      const fOffers = state.allSpecialOffers.filter(offer => dateWithinRange(offer.startDate, 7) && !offer.optedIn)

      if ((!lastDate)
      || ((!!lastDate && lastDate !== currentDateString)
      && !dateWithinRange(lastDate, 7))) {
        // set local storage first time, or it was over 7 days ago
        commit('setNewSpecialOffers', fOffers)
      } else if (fOffers.length > 0) {
        // get range from date previously set, then see if any offers are newer and can be notified
        const range = currentDate.diff(lastDateConverted, 'days').toObject()
        const newOffers = fOffers.filter(newOffer => dateWithinRange(newOffer.startDate, range.days))
        commit('setNewSpecialOffers', newOffers)
      }
    },
    /*
      This needs to be refactored. Products should be fetched and cached,
      maybe indexed by offer ID. This current solution is brittle.
     */
    async getQualifyingProductsForOffer({ state, commit, rootGetters }, id) {
      const currentPage = state.currentOfferPagination
      const paginate = (id === state.currentOfferId)
        && Object.keys(currentPage).length

      const isNewProductApiServices = rootGetters['SiteConfig/varByName']('feature_category_product_new_services')

      const opts = {
        couponId: id,
        start: paginate ? (currentPage.start + currentPage.rows) : 0,
        flags: true,
        rows: 50,
        sort: 'bestMatch asc',
      }

      const userId = rootGetters['UserProfile/userId']
      const { serviceLocationId } = rootGetters['UserProfile/refData'].deliveryServiceLocation
      const offerResponse = await ProductApi
        .get(userId, serviceLocationId, isNewProductApiServices, opts)
      const success = offerResponse.status === 200
      if (!success) {
        commit('setCurrentOfferProducts', [])
        commit('setCurrentOfferPagination', {})
        throw offerResponse
      }

      const { products, pagination } = offerResponse.data.response
      const productSet = state.currentOfferProducts

      if (paginate) {
        commit('setCurrentOfferProducts', [...productSet, ...products])
        commit('setCurrentOfferPagination', pagination)
      } else {
        commit('setCurrentOfferProducts', products)
        commit('setCurrentOfferPagination', pagination)
      }
    },
    async redeemSpecialOffer({
      dispatch, state, rootState, rootGetters, commit
    }, offerId) {
      const newOfferId = offerId.match('[A-Z]+_([0-9]*)')[1]
      const { opco } = rootState.SiteConfig
      const cardNumber = rootGetters['UserProfile/retailerCardNumber']
      const userId = rootGetters['UserProfile/userId']
      await LoyaltyProgramsApi.redeemSpecialOffer({
        opco, cardNumber, userId, offerId: newOfferId
      })

      await Promise.all([
        dispatch('updateLoyaltyOffers'),
        dispatch('RewardsAndPrograms/getRewardsAndPrograms', null, { root: true })
      ])

      const updatedOffer = state.loadedSpecialOffers
        .filter(offer => offer.id === offerId)

      if (updatedOffer.length) {
        const productCache = state.currentOfferProducts
        dispatch('storeCurrentOffer', updatedOffer[0])
        /* Restore qualifying products after refreshing offer state */
        commit('setCurrentOfferProducts', productCache)
      }
    },
    async redeemGroceryDollars({ rootState, rootGetters, dispatch }, points) {
      const { opco } = rootState.SiteConfig
      const cardNumber = rootGetters['UserProfile/retailerCardNumber']

      await LoyaltyProgramsApi.redeemGroceryDollars({
        opco, cardNumber, points
      })

      dispatch('resetPointRedemptions')
    },
    async redeemGasSavings({ rootState, rootGetters, dispatch }, points) {
      const { opco } = rootState.SiteConfig
      const cardNumber = rootGetters['UserProfile/retailerCardNumber']

      await LoyaltyProgramsApi.redeemGasSavings({
        opco, cardNumber, points
      })

      dispatch('resetPointRedemptions')
    },
    async redeemDonations({ rootState, rootGetters, dispatch }, points) {
      const { opco } = rootState.SiteConfig
      const cardNumber = rootGetters['UserProfile/retailerCardNumber']
      const userId = rootGetters['UserProfile/userId']

      await LoyaltyProgramsApi.redeemDonation({
        opco, cardNumber, points, userId
      })

      dispatch('resetPointRedemptions')
    },
    async resetPointRedemptions({ dispatch }) {
      dispatch('updateDonationsPointsToRedeem', 0)
      dispatch('updateGroceryPointsToRedeem', 0)
      dispatch('updateGasPointsToRedeem', 0)
    },
    async fetchShopOffers({ commit, state, rootGetters }) {
      const opts = {
        start: 0,
        rows: 14,
        isNewProductApiServices: rootGetters['SiteConfig/varByName']('feature_category_product_new_services')
      }
      commit('setShopOfferTile', state.bonusOffers)
      const apiCalls = []
      const userId = rootGetters['UserProfile/userId']
      const { serviceLocationId } = rootGetters['UserProfile/refData'].deliveryServiceLocation
      state.bonusOffers.forEach((offer) => {
        apiCalls.push(ProductApi.get(userId, serviceLocationId, offer.id, opts))
      })
      const offers = await Promise.all(apiCalls)
      commit('setProductsToShopOffers', offers)
      commit('setShopOffersInitialized', true)
    },
    async updateNewBonusOffers({ commit }, newOfferList) {
      commit('setNewBonusOffers', newOfferList)
    },
    addSeenOffer({ state, commit }, { type, id, date }) {
      if (type === 'special'
        && !state.specialOffersSeen.filter(el => el.id === id).length) {
        commit('addSpecialOfferSeen', { id, date })
      } else if (type === 'bonus'
        && !state.bonusOffersSeen.filter(el => el.id === id).length) {
        commit('addBonusOfferSeen', { id, date })
      }
    },
    clearNewOffers({ state, commit }) {
      commit('setNewBonusOffers', [])
      commit('setNewSpecialOffers', [])

      // Cull offers older than a week from local storage
      const culledBOffers = state.bonusOffersSeen.filter(el => dateWithinRange(el.date, 7)) || []
      const culledSOffers = state.specialOffersSeen.filter(el => dateWithinRange(el.date, 7)) || []

      commit('setBonusOffersSeen', culledBOffers)
      commit('setSpecialOffersSeen', culledSOffers)
    },
    async fetchCharityContent({ commit, dispatch }) {
      const responseData = await dispatch('ScheduledContent/queryRegion', {
        region: {
          loyalty_donations: {}
        }
      }, { root: true })
      let regionContents = responseData.response?.regionContents
      regionContents = regionContents.length ? regionContents[0]?.contents : []
      commit('setCharityContent', regionContents)
    },
    async fetchDonationsHistory({ commit, rootGetters }) {
      const cardNumber = rootGetters['UserProfile/retailerCardNumber']

      const responseData = await LoyaltyProgramsApi.getDonationsHistory({ cardNumber })
      if (responseData.status === 200) {
        commit('setDonationsSummaryData', responseData.data)
        return responseData.data
      }
      commit('setFailedToLoadDonationsSummary', true)
      return null
    }
  },
}
