<template>
  <div>
    <form
      class="help-topic_topics-container"
      name="productRequest"
      role="list"
      aria-labelledby="help-topic-root-title"
      novalidate
      @submit="handleSubmit"
    >
      <div
        class="forms_container"
        :class="{ 'mt-4': isMobile }"
      >
        <h3 class="tw-hd-4b text--align-center display--block">
          Not finding your favorite item?
        </h3>
        <p class="text--align-center">
          Submit an item request and help us improve our selection.
        </p>

        <br>

        <PdlSelect
          id="product-request_consumer-cat-id"
          ref="selectElement"
          v-model="v$.form.consumerCatId.$model"
          :error="v$.form.consumerCatId.$error"
          :options="filteredCategories"
          field-label="Category"
          class="forms_input-container"
          full-width
          no-error-padding
          :is-required="true"
          medium-caret
        >
          <template #errors>
            <span>
              {{ categoryRequiredText }}
            </span>
          </template>
        </PdlSelect>
        <PdlBasicTextArea
          id="product-request_item-description"
          v-model="v$.form.description.$model"
          :error="v$.form.description.$error"
          field-name="description"
          input-label="Item Description"
          instant-update
          has-description
          :is-required="true"
          :max-length="maxDescriptionLength"
        >
          <template #errors>
            <div v-for="error of v$.form.description.$errors"
:key="error.$uid">
              <span v-if="error.$validator === 'required'">
                {{ descriptionRequiredText }}
              </span>
              <span v-else-if="error.$validator === 'maxLength'">
                {{ maxLengthExceededText }}
              </span>
            </div>
          </template>
          <template #description>
            <span>{{ charactersLeft }} characters left</span>
          </template>
        </PdlBasicTextArea>

        <PdlBasicTextInput
          id="product-request_size"
          v-model="form.size"
          field-name="size"
          input-label="Item Size - optional"
          :is-mobile-device="isMobileDevice"
        />

        <PdlLoadingButton
          v-if="!formSubmitted"
          id="product-request_submit-button"
          label="Submit Request"
          :is-loading="sending"
          :is-disabled="!formIsValid"
          :class="buttonClasses"
          @click="handleSubmit"
        />
      </div>
    </form>

    <div
      v-if="formSubmitted"
      class="forms_container"
    >
      <h3 class="tw-hd-4b text--align-center display--block">
        {{ thanksText }}
      </h3>
      <p class="text--align-center">
        Your suggestions help us serve you better.
      </p>

      <br>

      <button
        class="button button--third button-width--lg forms_center-button"
        @click="resetForm"
      >
        Request Another Item?
      </button>
    </div>
  </div>
</template>

<script>
import {
  mapState,
  mapGetters,
  mapActions,
  mapMutations
} from 'vuex'
import { required, maxLength } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import productApi from 'api/productApi'
import { GTM_GA4 } from 'utils/constants'

export default {
  data: () => ({
    form: {
      size: '',
      description: '',
      consumerCatId: undefined,
    },
    maxDescriptionLength: 60,
    sending: false,
    formSubmitted: false,
    sendError: false,
    consumerCatBlackList: [0, 435, 555] // 0 is invalid, 435 is Charitable Contributions, 555 is Operations
  }),
  computed: {
    ...mapState({
      categories: state => state.BrowseAislesCategories.categories,
    }),
    ...mapGetters({
      messages: 'FormMessages/messages',
      isMobile: 'ScreenSize/isMobile',
      isMobileDevice: 'ScreenSize/isMobileDevice',
      defaultBillingAddress: 'UserProfile/defaultBillingAddress',
      serviceLocationId: 'UserProfile/serviceLocationId',
    }),
    filteredCategories() {
      return this.categories
        .filter(({ consumCatId }) => !_.contains(this.consumerCatBlackList, consumCatId))
        .map(({ categoryId, categoryName }) => ({
          name: categoryName,
          value: categoryId
        }))
    },
    formIsValid() {
      return !this.v$.form.description.$invalid && !this.v$.form.consumerCatId.$invalid
    },
    categoryRequiredText() {
      return this.messages('form.productRequest.categoryRequired')
    },
    descriptionRequiredText() {
      return this.messages('form.productRequest.descriptionRequired')
    },
    maxLengthExceededText() {
      return this.messages.maxLength(this.maxDescriptionLength)
    },
    charactersLeft() {
      const charactersLeft = this.maxDescriptionLength - this.form.description.length
      return charactersLeft > 0 ? charactersLeft : 0
    },
    thanksText() {
      return this.firstName?.length
        ? `Thanks ${this.firstName}!`
        : 'Thanks!'
    },
    firstName() {
      return this.defaultBillingAddress.name.firstName
    },
    buttonClasses() {
      return {
        'button-width--med-lg': !this.isMobile,
        'button-width--full': this.isMobile,
        'button--disabled': !this.formIsValid,
      }
    },
    amountOfCharactersLeft() {
      return this.description.length > 0 ? 65 - this.description.length : 0
    },
  },
  setup: () => ({ v$: useVuelidate() }),
  created() {
    this.fetchCategories()
  },
  methods: {
    ...mapActions({
      fetchCategories: 'BrowseAislesCategories/fetchCategories',
    }),
    ...mapMutations({
      setAlert: 'Alert/setAlert'
    }),
    resetForm() {
      this.form.description = ''
      this.form.consumerCatId = undefined
      this.form.size = ''
      this.formSubmitted = false
      this.sending = false
      this.$refs.selectElement.value = undefined
      this.$nextTick(() => this.v$.$reset())
    },
    async handleSubmit() {
      if (this.sending) {
        return
      }
      this.sending = true

      try {
        const { description, consumerCatId, size } = this.form
        await productApi.submitProductRequest({
          description,
          consumerCatId,
          size,
          serviceLocationId: this.serviceLocationId
        })
        const { name } = this.filteredCategories
          ?.find(cat => cat && (Number(consumerCatId) === Number(cat.value))) || {}
        if (name) {
          this.$trackGA4Event(GTM_GA4.click, {
            result: name,
            action: 'help center contact us form submit',
            link_text: 'Submit',
            link_id: 'product-request_submit-button',
            type: 'help center',
            site_location: 'Help Center - Contact Us',
          })
        }
        this.formSubmitted = true
      } catch (error) {
        this.sendError = true
        this.setAlert({
          icon: true,
          type: 'error',
          header: 'Error',
          body: this.messages('form.generic'),
          primary: {
            text: 'Ok',
          }
        })
      } finally {
        this.sending = false
      }
    },
  },
  validations() {
    return {
      form: {
        consumerCatId: { required },
        description: {
          required,
          maxLength: maxLength(this.maxDescriptionLength),
        },
      },
    }
  },
}
</script>
