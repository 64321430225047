<template>
  <div
    class="form_container"
  >
    <form
      v-if="formLoaded"
      novalidate
      class="form_form"
      :name="formId"
      @submit.prevent="onSubmit"
    >
      <div
        v-show="!hideFields"
        class="stack"
        :aria-hidden="hideFields"
      >
        <div
          v-if="hasBeforeSlot"
          class="stack_row"
        >
          <slot
            name="before"
          />
        </div>
        <div
          v-for="(field, index) in fieldList"
          v-show="!field.hidden"
          :key="index"
          class="stack_row"
          :class="[
            {
              'stack_row--one-quarter': field.size === 'one-quarter',
              'stack_row--two-fifth': field.size === 'two-fifth',
              'stack_row--three-fifth': field.size === 'three-fifth',
              'stack_row--half': field.size === 'half',
              'stack_row--three-quarters': field.size === 'three-quarters',
              'stack_row--wrap stack_row--spaced': field.type === 'header',
              'stack_row--wrap': field.type === 'group',
              'h-0 p-0 m-0': field.type === 'hidden'
            },
            field.containerClass || ''
          ]"
        >
          <template
            v-if="field.type === 'header'"
          >
            <h3
              v-if="field.title"
              class="field_row field_title"
            >
              {{ field.title }}
            </h3>
            <h4
              v-if="field.subtitle"
              class="field_row field_subtitle"
            >
              {{ field.subtitle }}
            </h4>
            <p
              v-if="field.label"
              class="forms_label"
              :class="{
                'font-bold': boldLabels
              }"
            >
              {{ field.label }}
            </p>
            <slot
              v-if="field.slot"
              :name="field.slot"
            />
          </template>
          <template
            v-else-if="field.type === 'group'"
          >
            <div
              v-if="field.label"
              class="forms_label"
              :class="{
                'font-bold': boldLabels
              }"
            >
              {{ field.label }}
            </div>
            <div
              class="stack_group"
              :class="field.class || ''"
            >
              <component
                v-for="(f, i) in field.fields"
                v-bind="fieldProps(f)"
                v-model.trim="v$.form[f.name].$model"
                :is="getType(f.type)"
                :key="i"
                :v="v$.form[f.name]"
                :form-id="formId"
                @update:modelValue="onFieldInput($event, f.name, f.type)"
                @change="onFieldChange($event, f.name, f.type)"
              >
                <template
                  v-for="slot in fieldSlots(field)"
                  :slot="`${slot.name}`"
                >
                  <slot :name="slot.link" />
                </template>
              </component>
            </div>
            <slot
              v-if="field.slot"
              :name="field.slot"
            />
          </template>
          <component
            v-else-if="hasVModel(field)"
            v-bind="fieldProps(field)"
            v-model.trim="v$.form[field.name].$model"
            :is="getType(field.type)"
            :ref="`field${setRef(field)}`"
            :v="v$.form[field.name]"
            :form-id="formId"
            @update:modelValue="onFieldInput($event, field.name, field.type)"
            @change="onFieldChange($event, field.name, field.type)"
          >
            <template
              v-for="slot in fieldSlots(field)"
              #[slot.name]
            >
              <slot :name="slot.link" />
            </template>
          </component>
        </div>
        <div
          v-if="hasAfterSlot"
          class="stack_row"
        >
          <slot
            name="after"
          />
        </div>
      </div>
      <div
        v-if="showFormMessage"
        class="stack_row stack_row--center"
        aria-live="polite"
      >
        <p
          class="text--base-strong"
          :class="{
            'text--base-error': formError,
            'text--base-success': formSuccess,
            'trailer--double': !shortFooter
          }"
        >
          <span v-if="formSuccess">{{ formSuccessMessage }}</span>
          <span v-if="formError">{{ formErrorMessage }}</span>
        </p>
      </div>
      <div
        v-if="!hideSubmitButton"
        :aria-hidden="hideSubmitButton"
        class="stack_row stack_row--footer"
        :class="{
          'justify-center': !leftFooter,
          'text-center': isMobile,
          'mt-0': shortFooter
        }"
      >
        <PdlLoadingButton
          :id="`${formId}-submit`"
          :name="`${formId}-submit`"
          :label="submitTitle"
          button-color="third"
          :classes="[
            isMobile || fullSubmitButton ? 'button-width--full' : 'button-width--med-lg'
          ]"
          :is-loading="loading"
          :is-disabled="!formReady || loading"
          :ignore-prevent="true"
        />
      </div>
    </form>
  </div>
</template>
<script>
/** Component PdlFormBase - a reusable configurable form base
 *
 * @props formId - base name which will define the naming convention for your form
 * @props submitAction - @string must be Vuex Action
 *      or @function must contain @promise -- string should point to a store action
 * @props submitTitle - change the text of the submit button
 * @props fields - A multi-dimensional array defining the form input field;
 *                  Each entry may be a single input or a 'group', which is an array of grouped inputs
* */
import { mapGetters } from 'vuex'
import { useVuelidate } from '@vuelidate/core'
import PdlLoadingButton from '@/shared/interactions/PdlLoadingButton'
import checkSafeId from 'utils/filters/checkSafeId'
import excludeNilFromObj from 'utils/filters/excludeNilFromObj'
import checkStartsWithDollar from 'utils/filters/checkStartsWithDollar'
import { LOGGING_TYPE } from 'utils/constants'
import PdlRadioGroup from './PdlRadioGroup'
import PdlTextArea from './PdlTextArea'
import PdlSelect from './PdlSelect'
import PdlPassword from './PasswordInput'
import PdlText from './PdlText' // Text / Password / Search
import PdlToggleBox from './PdlToggleBox' // Checkbox (will also do a single radio)
import PdlHidden from './PdlHidden'

const flattenGroups = (arr, field) => {
// Flatten grouped fields for validation
  if (field.type === 'group') {
    _.each(field.fields, (f) => {
      arr.push(f)
    })
  } else if (field.type !== 'header') {
    arr.push(field)
  }
}

const FormBase = {
  components: {
    PdlLoadingButton,
    PdlTextArea,
    PdlSelect,
    PdlToggleBox,
    PdlText,
    PdlRadioGroup,
    PdlPassword,
    PdlHidden
  },
  props: {
    formId: {
      type: String,
      required: true,
      validator: v => checkSafeId(v)
    },
    submitAction: {
      type: [String, Function],
      required: true
    },
    opco: {
      type: String,
      default: ''
    },
    hideFields: {
      type: Boolean,
      default: false
    },
    hideSubmitButton: {
      type: Boolean,
      default: false
    },
    fields: {
      type: Array,
      default: () => ([])
    },
    submitTitle: {
      type: String,
      default: 'Save'
    },
    successMessage: {
      type: String,
      default: ''
    },
    errorMessage: {
      type: String,
      default: ''
    },
    formErrors: {
      type: Object,
      default: () => ({})
    },
    refinePayload: {
      type: Function,
      default: value => value
    },
    trackSuccess: {
      type: Object,
      default: () => ({})
    },
    trackError: {
      type: Object,
      default: () => ({})
    },
    logSuccess: {
      type: Object,
      default: () => ({
        name: '',
        payload: {}
      })
    },
    logError: {
      type: Object,
      default: () => ({
        name: '',
        payload: {}
      })
    },
    canShowFormMessages: {
      type: Boolean,
      default: true
    },
    leftFooter: {
      type: Boolean,
      default: false
    },
    shortFooter: {
      type: Boolean,
      default: false
    },
    fullSubmitButton: {
      type: Boolean,
      default: false
    },
    boldLabels: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    types: {
      radio: PdlRadioGroup,
      text: PdlText,
      select: PdlSelect,
      textarea: PdlTextArea,
      checkbox: PdlToggleBox,
      password: PdlPassword,
      hidden: PdlHidden
    },
    form: {},
    formValidations: {
      form: {}
    },
    loading: false,
    formError: false,
    formSuccess: false,
    errorCode: '',
    responseErrors: [],
    slotNames: ['label', 'before', 'after']
  }),
  setup: () => ({ v$: useVuelidate() }),
  computed: {
    fieldList() {
      return this.getFieldList(this.fields)
    },
    formLoaded() {
      return !_.isEmpty(this.form)
    },
    hasOnlyHiddenFields() {
      return this.fieldList.filter(({ type }) => (type === 'hidden')).length === this.fieldList.length
    },
    formReady() {
      return (this.v$.$anyDirty && !this.v$.$invalid) || this.hasOnlyHiddenFields
    },
    fieldErrors() {
      let response = {}
      if (this.v$.$anyDirty && this.v$.$invalid) {
        response = _.reduce(this.v$.form, (obj, field, key) => {
          if (field.$invalid === true) {
            obj[key] = _.keys(
              _.pick(field, (item, k) => !checkStartsWithDollar(k) && !field[k])
            )
          }
          return obj
        }, {})
      }
      return response
    },
    hasAfterSlot() {
      return 'after' in this.$slots
    },
    hasBeforeSlot() {
      return 'before' in this.$slots
    },
    ...mapGetters('ScreenSize', [
      'isMobile'
    ]),
    formRef() {
      return `${this.formId}-form`
    },
    showFormMessage() {
      return this.canShowFormMessages && !this.loading && (this.formError || this.formSuccess)
    },
    formSuccessMessage() {
      return (this.successMessage) ? this.successMessage : this.messages('form').success
    },
    formErrorMessage() {
      if (this.formErrors && Object.keys(this.formErrors).includes(this.errorCode?.code || this.errorCode)) {
        return this.formErrors[this.errorCode.code || this.errorCode]
      }
      return (this.errorMessage) ? this.errorMessage : this.messages('form').error
    },
    messages() {
      // return a function that can retrieve a message from either $store messages or local
      const defaultMessages = (message) => {
        let response = this.defaultMessages
        if (message) {
          const arr = message.split('.')
          response = arr.reduce((acc, currentValue) => acc?.[currentValue], response)
        }
        return response
      }

      return this.$store?.getters['FormMessages/messages'] || defaultMessages
    },
    defaultMessages() {
      return {
        form: {
          generic: 'Something went wrong. Please try again.',
          success: 'Your account has been updated.',
          error: 'There was a problem updating your account.'
        },
        fields: {
          generic: {
            required: 'This field is required.',
            format: 'Please enter a valid value.'
          }
        }
      }
    }
  },
  watch: {
    loading(val) {
      this.$emit('loading-change', this.formId, val)
    },
    formReady(val) {
      this.$emit('ready-change', this.formId, val)
    },
    fields: {
      handler(newValue) {
        const { formData, formValidations } = this.initFormData(newValue)
        this.formData = formData
        this.formValidations = formValidations
      },
      deep: true,
      immediate: true,
    },
    fieldErrors(errorFields) {
      const hasErrors = errorFields && Object.keys(errorFields).length > 0
      this.$emit('field-errors', this.formId, hasErrors, errorFields, this.v$)
    },
    form: {
      deep: true,
      handler() {
        this.resetFormMessages()
      }
    }
  },
  beforeCreate() {
    const { formData, formValidations } = FormBase.methods.initFormData.call(this, this.$options.props.fields, {})
    this.formData = formData
    this.formValidations = formValidations
  },
  created() {
    this.form = this.formData
  },
  mounted() {
    if (this.$route.path.includes('/modal')) {
      this.setFirstFocus()
    }
  },
  methods: {
    initFormData(fields) {
      const formValidations = { form: {} }
      const getResponseErrors = () => (this.responseErrors || [])

      const formData = _.chain(fields)
        .reduce((arr, field) => {
          flattenGroups(arr, field)
          return arr
        }, [])
        .reduce((obj, field) => {
          field = _.defaults(field, {
            validation: {},
            errorMessages: {}
          })
          if (_.isUndefined(field.validation.responseError) && !_.isArray(field.errorMessages.responseError)) {
            field.validation.responseError = () => {
              const matchResponseError = _.findWhere(getResponseErrors(), { field: field.name })
              return !matchResponseError
            }
          }
          if (_.isArray(field.errorMessages.responseError)) {
            field.errorMessages.responseError.forEach((name) => {
              field.validation[name] = () => {
                const matchResponseError = _.find(getResponseErrors(), (err) => {
                  const splitPath = err?.path?.split('.') || []
                  const path = splitPath.length > 0 ? splitPath[splitPath.length - 1] : ''
                  return field.name === err.field || (!!path && field.name === path)
                })
                return matchResponseError?.code !== name
              }
            })
          }
          obj[field.name] = field.value
          formValidations.form[field.name] = field.validation
          return obj
        }, {}).value()

      return { formData, formValidations }
    },
    getFieldList(fields) {
      const defaultMessage = this.messages('fields.generic.format')
      if (!fields || fields.length < 1) return []
      return _.reduce(fields, (arr, field) => {
        if (field.type === 'secret') return arr // exclude secret fields - they do not need messages
        field = _.defaults(field, {
          errorMessages: {},
          opco: this.opco,
          boldLabel: this.boldLabels
        })

        const messageGroup = this.messages(`fields.${field.name}`)
        if (!field.errorMessages.responseError) {
          field.errorMessages.responseError = (messageGroup?.format) ? messageGroup.format
            : defaultMessage
        }
        if (_.isArray(field.errorMessages.responseError)) {
          const predefinedMessages = _.pick(messageGroup, field.errorMessages.responseError)
          field.errorMessages = _.omit(_.extend({}, predefinedMessages, field.errorMessages), 'responseError')
        }

        arr.push(field)
        return arr
      }, [])
    },
    hasVModel(field) {
      const { name } = field || {}
      return !!name && !!this.v$.form[name]
    },
    setFirstFocus() {
      const [firstField] = this.$refs?.field0 || []
      const firstFocusEl = firstField?.$refs?.input || firstField?.$refs?.input0
      if (firstFocusEl?.focus) firstFocusEl.focus()
    },
    setRef(field) {
      const inputList = _.filter(this.fieldList, f => !!this.getType(f.type))
      const key = _.findIndex(inputList, f => f.name === field.name)
      return `${key}`
    },
    updateFormValues(values) {
      if (
        _.isObject(values)
        && !_.isArray(values)
        && !_.isMatch(this.form, values)) {
        values = excludeNilFromObj(values)
        this.form = _.extend({}, this.form, values)
        this.v$.$touch()
      }
    },
    resetFormMessages() {
      this.formSuccess = false
      this.formError = false
    },
    async triggerSubmit(force = false) {
      let attempt = Promise.resolve(false)

      if (force || this.formReady) {
        attempt = await this.onSubmit()
      }

      return attempt
    },
    fieldProps(field) {
      return _.omit(field, 'value', 'validation', 'containerClass')
    },
    fieldSlots(field) {
      const slots = field.slots || {}
      const scopedSlots = this.$slots || []
      return _.chain(slots)
        .pick(this.slotNames)
        .pick(value => (_.has(scopedSlots, value)))
        .reduce((list, value, key) => ({
          name: key,
          link: value
        }), [])
    },
    getType(type) {
      // If this type exists get component, otherwise return basic text input
      if (type === 'secret') return null // exclude secret fields
      return (_.has(this.types, type)) ? this.types[type] : this.types.text
    },
    getResponseCode(response) {
      // Recursive test for nested data value
      // handle various api response from PRISM platform
      const val = (
        response?.data?.webMessage
        || response?.data?.response?.code
        || response?.data?.response?.results?.[0]?.code
        || response?.status
        || ''
      )

      return _.isObject(val) ? val : { code: val }
    },
    onFieldChange(value, name, type) {
      if (type === 'checkbox') {
        this.onCheckbox(value, name)
      }
      this.onFieldInput(value, name, type)
    },
    onFieldInput(value, name, type) {
      this.updateResponseErrors(name)
      this.emitFieldEvent(name, value, type)
    },
    onCheckbox(value, name) {
      if (this.v$.form[name]) {
        this.v$.form[name].$model = value
      }
    },
    updateResponseErrors(name) {
      // Clears errors after update
      if (this.responseErrors && this.responseErrors?.length > 0) {
        this.responseErrors = _.reduce(this.responseErrors, (arr, field) => {
          if (field !== name) {
            this.v$.form[name].$reset()
          } else {
            arr[name] = field
          }
        }, [])
      }
    },
    async onSubmit() {
      let response = Promise.resolve(false)

      this.v$.$touch()

      if (this.formReady) {
        this.errorCode = ''
        this.responseErrors = []
        this.formError = false
        this.formSuccess = false
        this.loading = true
        const filteredFields = await this.refinePayload(this.form)
        this.emitEvent('attempt', filteredFields)

        response = await this.getResponseAction(filteredFields)

        this.setResponseState(response)
      }
      this.loading = false
      return response
    },
    getResponseAction(formFields = {}) {
      let response = Promise.resolve(false)
      if (_.isString(this.submitAction)) {
        response = this.$store.dispatch(`${this.submitAction}`, formFields)
      }
      if (_.isFunction(this.submitAction)) {
        response = this.submitAction(formFields)
      }
      return response
    },
    setResponseState(response = {}) {
      const { status } = response || {}
      const success = (status && (status >= 200 && status < 300))
      // capture the error message when response is successful
      const code = this.getResponseCode(response)
      const eventName = (success) ? 'success' : 'error'

      if (!success) {
        this.errorCode = code
        const dataResponse = response?.data?.response || {}
        this.responseErrors = dataResponse.errors || dataResponse.results || []
      } else {
        // If successful reset the form validations (fields no longer considered dirty)
        this.$nextTick(() => this.v$.$reset())
      }
      // check if webAccountCode equals 'CE" then set formError True
      if (response?.data?.webAccountCode === 'CE') {
        this.errorCode = response?.data?.webAccountCode
        this.formSuccess = false
        this.formError = true
      } else {
        this.formSuccess = success
        this.formError = !success
      }
      this.emitEvent(eventName, response)
      this.trackGtm(this.formSuccess, this.errorCode, code, this.errorMessage)
      this.trackClient(this.formSuccess)
    },
    trackGtm(success, error, code, errorMessage) {
      const trackConfig = (success) ? this.trackSuccess : this.trackError
      const payload = trackConfig.payload || {}
      payload.success = success
      if (!success) {
        payload.error = error || code
        payload.failReason = errorMessage
      } else {
        payload.error = null
        payload.failReason = null
      }
      this.$trackGtmEvent(trackConfig.event, payload)
    },
    trackClient(success) {
      const type = (success) ? LOGGING_TYPE.event : LOGGING_TYPE.exception
      const name = (success) ? this.logSuccess.name : this.logError.name
      const payload = (success) ? this.logSuccess.payload : this.logError.payload

      const body = {
        ...payload
      }
      this.$trackClientLog(name, body, type)
    },
    emitEvent(eventName, response = {}) {
      const payload = {
        formId: this.formId,
        data: this.form,
        response
      }
      this.$emit(`${eventName}`, payload)
    },
    emitFieldEvent(field, value, type) {
      const validation = this.v$.form[field]
      this.$emit('field-change', {
        formId: this.formId,
        field,
        value,
        type,
        validation
      })
    }
  },
  validations() {
    return this.formValidations || {}
  }
}

export default FormBase
</script>
