<template>
  <div>
    <div class="text-xl font-semibold text-center mt-12 mb-5">
      Thank You{{ formatName }}
    </div>
    <div class="text-center">
      <span class="sm:block">
        A representative will be in touch as soon as possible.
      </span>
      <span class="sm:block">
        For immediate assistance, please call
        <a
          class="link"
          :href="`tel://+${brandContactNumber}`"
        >{{ brandContactNumber }}</a>.
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    name: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters('SiteConfig', ['brandContactNumber']),
    formatName() {
      return (this.name) ? ` ${this.name}` : ''
    }
  }
}
</script>
