import {
  API_MICROSERVICE_REGEX,
  API_REGEX,
  API_AUTOLOGIN_REGEX,
  API_AUTOLOGIN_V3_REGEX,
  API_CURRENTUSER_REGEX,
  API_CLIENTINFO_REGEX,
} from 'utils/constants'
import DeferredSingleActionService from 'utils/interceptors/services/deferred-single-action'
import userAPI from 'api/UserAPI'
import { redirectToLogin } from 'components/auth/services/auth-helper-service'
import { dispatchActionForAllModules } from 'utils/vuex/vuexUtils'
/**
* This interceptor checks for 409 SESSION_EXPIRE scenario. Api calls will
* be retried after relogin api is called.

* Also check if it has a response code of either 401 or 403.  If it does it should
* retry the promise once.  This is used so that if a user's cookie expires it will cause
* the 401/403, however on the failed request the cookie will be recreated.
*
*/
const INVALID_URLS = [
  '/payment',
  '/creditcard'
]

const SESSION_TIMEOUT = appConfig.sessionTimeout - 10000
const factory = {
  maxInterceptions: 1,
  interceptions: 0
}

const renewSession = (rejection) => {
  return new Promise((resolve, reject) => {
    return DeferredSingleActionService.execute()
      .then((userInfo) => {
        const { config } = rejection
        if (!config) {
          reject(rejection)
          return
        }
        DeferredSingleActionService.reset()
        // userId, orderId and basketId would change after ghost to guest transfer
        if (config.isTemplateUrlPath) {
          if (userInfo?.data?.response?.userId) {
            config.pathVariables.userId = userInfo?.data?.response.userId
          }
          const results = window.sharedVue.config.globalProperties.$store.dispatch('Registration/refreshProfile')
          if (results.userInfo?.data?.response?.information?.currentOrderId) {
            config.pathVariables.orderId = results.userInfo.data.response.information.currentOrderId
          }

          config.pathVariables.basketId = window.sharedVue.config.globalProperties.$store.state.Cart.basketId
        }
        resolve(window.http(config))
      })
      .catch((error) => {
        reject(error)
      })
      .then(() => dispatchActionForAllModules('refresh'))
  })
}
export function sessionExpired(rejection) {
  if (window.initPhase.flag || !rejection?.response?.status) {
    return Promise.reject(rejection)
  }
  // Session is expired - queue up relogin api call with relogin service.
  if (rejection.response.status === 409
      && rejection.response.data.response.code === 'SESSION_EXPIRED'
      && !INVALID_URLS.includes(rejection.response.config.url)
      && !DeferredSingleActionService.isLocked()
  ) {
    DeferredSingleActionService.setLock(true)
    return renewSession(rejection)
  }

  if ( // Defer for session expired during microservice calls
    rejection.response.status === 403
    && (
      API_MICROSERVICE_REGEX.test(rejection.config.url)
      || (API_REGEX.test(rejection.response.config.url) && rejection.response.data.response.code === 'USER_ID_INVALID')
    )
  ) {
    return renewSession(rejection)
  }

  if (checkSessionInvalid(rejection)) {
    return logoutUser()
  }
  trackUnauthorized(rejection)
  return Promise.reject(rejection)
}

const trackUnauthorized = (rejection) => {
  if (API_MICROSERVICE_REGEX.test(rejection?.config?.url)
  && rejection.response.status === 403
  && rejection.response.data.response.code === 'UNAUTHORIZED') {
    window.sharedVue.config.globalProperties.$trackClientLog('session_event', {
      event: 'MICROSERVICE_UNAUTHORIZED',
      time: new Date().getTime()
    })
  }
}

const checkSessionInvalid = (rejection) => {
  return (rejection.response.status === 403 || rejection.response.status === 401)
    && (rejection.response.data.response?.code === 'SESSION_INVALID'
    || rejection.response.data.response?.code === 'REQUEST_INVALID')
    && factory.interceptions <= factory.maxInterceptions
}

const logoutUser = () => {
  factory.interceptions += 1
  return userAPI.logOut({ skipLogoutResponse: true })
    .finally(() => {
      redirectToLogin()
    })
}

// Autologin the user after they have been idle past the SESSION_TIMEOUT
export function sessionTimeoutHttp(request) {
  const isValidApi = API_REGEX.test(request.url)
    && !API_AUTOLOGIN_REGEX.test(request.url)
    && !API_AUTOLOGIN_V3_REGEX.test(request.url)
    && !API_CURRENTUSER_REGEX.test(request.url)
    && !API_CLIENTINFO_REGEX.test(request.url)

  if (!isValidApi) {
    return Promise.resolve(request)
  }

  const currentTime = new Date().getTime()
  const { lastApiRequestTime } = DeferredSingleActionService
  if (!lastApiRequestTime) {
    DeferredSingleActionService.setLastApiRequestTime(currentTime)
    return Promise.resolve(request)
  }

  const timeSinceLastApiRequest = currentTime - lastApiRequestTime
  if ((timeSinceLastApiRequest > SESSION_TIMEOUT) && !DeferredSingleActionService.isLocked()) {
    DeferredSingleActionService.setLastApiRequestTime(currentTime)
    return new Promise((resolve, reject) => {
      return DeferredSingleActionService.execute()
        .then(() => {
          DeferredSingleActionService.reset()
          resolve(request)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  DeferredSingleActionService.setLastApiRequestTime(currentTime)

  return Promise.resolve(request)
}
