import DeliverySubscriptionsAPI from 'api/DeliverySubscriptionsAPI'
import CouponApi from 'api/couponApi'
import LoyaltyProgramsApi from 'api/LoyaltyProgramsAPI'
import { trackSubscriptionPlanPurchase } from '@/utils/tracking/podpass/trackSubscriptionPlanPurchase'

/**
 * @function expandSubscriptionData
 *
 * Legacy app was making methods accessible to the template to expand
 * on data while iterating through it.  Solution here is to perform
 * those expansions prior to the template receiving data.
 *
 * @param {array} plans - list of available PodPass plans for user
 */
function expandSubscriptionData(plans) {
  const expandedPlans = plans.map((plan) => {
    // test 3 fields for mention of MidWeek to set flag
    plan.isMidweek = !![
      /midweek/gi.test(plan.description),
      /midweek/gi.test(plan.name),
      /midweek/gi.test(plan.subscriptionId)
    ].find(r => r)
    plan.valueRatio = plan.price / plan.termQty
    return plan
  })

  const valueSortedMidweek = _.sortBy(expandedPlans.filter(plan => plan.isMidweek), 'valueRatio')
  const valueSortedFullWeek = _.sortBy(expandedPlans.filter(plan => !plan.isMidweek), 'valueRatio')

  if (valueSortedMidweek.length) {
    valueSortedMidweek[0].additional = 'BEST VALUE'
  }

  if (valueSortedFullWeek.length) {
    // if no mid-week options, replace Most Flexible with best value
    const fullWeekAdditional = valueSortedMidweek.length ? 'MOST FLEXIBLE' : 'BEST VALUE'
    valueSortedFullWeek[0].additional = fullWeekAdditional
  }

  return expandedPlans
}

function planType(description) {
  const regex = /(Midweek)|(Pickup Only)|(Anytime)/
  const matchPlanType = description?.match(regex)
  return matchPlanType?.length ? matchPlanType[0] : ''
}

const initialState = {
  currentNode: 'pod-pass',
  hasActiveSubscription: false,
  eligibleForFreeTrial: false,
  fetchingPaymentMethod: false,
  purchaseInProgress: false,
  completedPurchase: false,
  unsuccessfulPurchase: false,
  cancellationComplete: false,
  pendingInProgress: false,
  pendingSubID: null,
  pendingComplete: false,
  freeTrialInProgress: false,
  freeTrialComplete: false,
  // current plan details
  currentPlan: {
    name: null,
    description: '',
    price: 0,
    expirationDate: null,
    startDate: null,
    multiPaymentCapsID: null,
  },
  pendingPlan: {
    name: null,
    id: null,
    price: 0,
    termQty: null
  },
  // pending plan data
  monthlyOffer: null,
  monthlyOfferLoadFailure: false,
  monthlyOfferOptInFail: false,
  monthlyOfferPromise: null,
  availableSubscriptions: [],
  freeTrialDetails: {
    autoRenewFlag: null,
    description: '',
    freeTrialDaysQty: null,
    name: null,
    price: null,
    subscriptionId: null,
    termQty: null,
    freeTrialFlag: null,
    startDate: null,
    endDate: null
  },
  potentialPlanForPurchase: {
    autoRenewFlag: null,
    description: '',
    endDate: null,
    freeTrialDaysQty: null,
    freeTrialFlag: null,
    name: null,
    price: null,
    startDate: null,
    subscriptionId: null,
    termQty: null
  },
  userSubscriptionRequestPending: false,
  userSubscriptionRequestTimeout: false,
  availableSubscriptionRequestPending: false,
  availableSubscriptionRequestTimeout: false,
  modalTitle: 'Pod Pass',
  error: null
}

const initialGetters = {
  currentPlanTitle: (state) => {
    const planName = state.currentPlan.name
    const currPlanType = state.currentPlanType
    if (planName != null && currPlanType) {
      return `${planName} ${currPlanType}`
    }
    return 'No subscription plan'
  },
  currentPlanType: state => planType(state.currentPlan.description),
  pendingPlanType: state => planType(state.pendingPlan.description),
  potentialPlanType: state => planType(state.potentialPlanForPurchase.description),
  monthlyOfferPromise: state => state.monthlyOfferPromise,
  updatedSubscriptionData: (state) => {
    const { availableSubscriptions } = state
    if (!availableSubscriptions.length) {
      return []
    }
    // replicate legacy app method to determine pending plan for user
    return availableSubscriptions.slice().map((option) => {
      const optionCopy = JSON.parse(JSON.stringify(option))
      optionCopy.isPendingPlan = option.subscriptionId === state.pendingPlan.id
      || (option.termQty === state.pendingPlan.termQty && option.price === state.pendingPlan.price)
      return option
    })
  },
  pairedSubscriptionData: (state, getters) => {
    const groupedPlans = {}
    const pairedPlans = []
    if (getters.updatedSubscriptionData.length <= 3) {
      return [[...getters.updatedSubscriptionData]]
    }

    getters.updatedSubscriptionData.forEach((subscriptionPlan) => {
      const termKey = `_${subscriptionPlan.termQty}`
      if (groupedPlans[termKey] === undefined) {
        groupedPlans[termKey] = []
      }
      groupedPlans[termKey].push(subscriptionPlan)
    })

    Object.keys(groupedPlans).forEach(group => [
      pairedPlans.push(groupedPlans[group])
    ])
    return pairedPlans
  },
}

const mutations = {
  setCurrentPlan(state, value) {
    state.currentPlan = {
      id: value.deliverySubscription.subscriptionId,
      name: value.deliverySubscription.name,
      description: value.deliverySubscription.description,
      price: value.price,
      startDate: value.startDate,
      expirationDate: value.expirationDate,
      termQty: value.deliverySubscription.termQty,
      isFreeTrial: value.freeTrialFlag === 'Y',
      tax: value.tax,
      autorenew: value.autoRenewFlag,
      multiPaymentCapsID: value?.capsId || null,
    }
  },
  setPendingPlan(state, value) {
    const {
      name, id, price, termQty, description
    } = value
    state.pendingPlan = {
      name,
      id,
      price,
      termQty,
      description
    }
  },
  setCurrentNode(state, value) {
    state.currentNode = value
  },
  setActiveSubscription(state, value) {
    state.hasActiveSubscription = value
  },
  setEligibleForFreeTrial(state, value) {
    state.eligibleForFreeTrial = value
  },
  setFreeTrialDetails(state, payload) {
    state.freeTrialDetails.autoRenewFlag = payload.autoRenewFlag
    state.freeTrialDetails.description = payload.description
    state.freeTrialDetails.freeTrialDaysQty = payload.freeTrialDaysQty
    state.freeTrialDetails.name = payload.name
    state.freeTrialDetails.price = payload.price
    state.freeTrialDetails.subscriptionId = payload.subscriptionId
    state.freeTrialDetails.termQty = payload.termQty
    state.freeTrialDetails.freeTrialFlag = payload.freeTrialFlag
    state.freeTrialDetails.startDate = payload.startDate
    state.freeTrialDetails.endDate = payload.endDate
  },
  setPotentialPlanForPurchase(state, payload) {
    state.potentialPlanForPurchase.autoRenewFlag = payload.autoRenewFlag
    state.potentialPlanForPurchase.description = payload.description
    state.potentialPlanForPurchase.endDate = payload.endDate
    state.potentialPlanForPurchase.freeTrialDaysQty = payload.freeTrialDaysQty
    state.potentialPlanForPurchase.freeTrialFlag = payload.freeTrialFlag
    state.potentialPlanForPurchase.name = payload.name
    state.potentialPlanForPurchase.price = payload.price
    state.potentialPlanForPurchase.startDate = payload.startDate
    state.potentialPlanForPurchase.subscriptionId = payload.subscriptionId
    state.potentialPlanForPurchase.termQty = payload.termQty
  },
  setAvailableSubscriptions(state, payload) {
    state.availableSubscriptions = payload
  },
  setError(state, payload) {
    state.error = payload
  },
  setMultiPaymentCapsID(state, payload) {
    state.currentPlan.multiPaymentCapsID = payload
  },
  setFetchingPaymentMethod(state, payload) {
    state.fetchingPaymentMethod = payload
  },
  setPurchaseInProgress(state, payload) {
    state.purchaseInProgress = payload
  },
  setCompletedPurchase(state, payload) {
    state.completedPurchase = payload
  },
  setUnsuccessfulPurchase(state, payload) {
    state.unsuccessfulPurchase = payload
  },
  setCancellationComplete(state, payload) {
    state.cancellationComplete = payload
  },
  setPendingInProgress(state, payload) {
    state.pendingInProgress = payload
  },
  setPendingComplete(state, payload) {
    state.pendingComplete = payload
  },
  setPendingSubID(state, payload) {
    state.pendingSubID = payload
  },
  setFreeTrialInProgress(state, payload) {
    state.freeTrialInProgress = payload
  },
  setFreeTrialComplete(state, payload) {
    state.freeTrialComplete = payload
  },
  setUserSubscriptionRequestPending(state, payload) {
    state.userSubscriptionRequestPending = payload
  },
  setUserSubscriptionRequestTimeout(state, payload) {
    state.userSubscriptionRequestTimeout = payload
  },
  setAvailableSubscriptionRequestPending(state, payload) {
    state.availableSubscriptionRequestPending = payload
  },
  availableSubscriptionRequestTimeout(state, payload) {
    state.availableSubscriptionRequestTimeout = payload
  },
  setModalTitle(state, payload) {
    state.modalTitle = payload
  },
  setMonthlyOffer(state, payload) {
    state.monthlyOffer = payload
  },
  setMonthlyOfferLoadFailure(state, payload) {
    state.monthlyOfferLoadFailure = payload
  },
  setMonthlyOfferOptInFail(state, payload) {
    state.monthlyOfferOptInFail = payload
  },
  setMonthlyOfferPromise(state, payload) {
    state.monthlyOfferPromise = payload
  }
}

const actions = {
  async retrieveAndSetUserSubscriptionData({ state, commit, rootGetters }) {
    const fetchTimeout = setTimeout(() => {
      if (state.userSubscriptionRequestPending) {
        commit('setUserSubscriptionRequestTimeout', true)
        commit('setUserSubscriptionRequestPending', false)
        // notify user
        commit('Alert/setAlert', {
          type: 'error',
          header: 'Subscription Error',
          body: 'One or more requests timed out, please try again',
          primary: {
            text: 'Ok'
          }
        }, { root: true })
      }
    }, 20000)

    try {
      commit('setUserSubscriptionRequestPending', true)
      const userId = rootGetters['UserProfile/userId']
      const {
        status,
        data,
        data: {
          response: {
            current,
            pending
          }
        }
      } = await DeliverySubscriptionsAPI.getUserSubscriptions(userId)
      clearTimeout(fetchTimeout)

      if (status !== 200) {
        throw new Error({ status, data })
      }

      const activeSubscription = !!current
      const pendingSubscription = !!pending

      // active subscription based on existence of "current" key
      commit('setActiveSubscription', activeSubscription)

      // CURRENT PLAN
      if (activeSubscription) {
        commit('setCurrentPlan', current)
      } else {
        commit('setCurrentPlan', {})
      }

      // PENDING PLAN
      if (pendingSubscription) {
        commit('setPendingSubID', pending.deliverySubscription.subscriptionId)
        commit('setPendingPlan', {
          name: pending.deliverySubscription.name,
          id: pending.deliverySubscription.subscriptionId,
          price: pending.price,
          termQty: pending.deliverySubscription.termQty,
          description: pending.deliverySubscription.description
        })
      } else {
        commit('setPendingPlan', {})
      }
      commit('setUserSubscriptionRequestPending', false)
    } catch (e) {
      clearTimeout(fetchTimeout)
      commit('setUserSubscriptionRequestPending', false)
      commit('setError', e)
    }
  },
  async getAvailableSubscriptions({ state, commit, rootGetters }) {
    const fetchTimeout = setTimeout(() => {
      if (state.availableSubscriptionRequestPending) {
        commit('availableSubscriptionRequestTimeout', true)
        commit('setAvailableSubscriptionRequestPending', false)
      }
    }, 20000)

    try {
      const payload = {
        userId: rootGetters['UserProfile/userId'],
        serviceLocationId: rootGetters['UserProfile/serviceLocationId']
      }
      commit('setAvailableSubscriptionRequestPending', true)
      const subscriptionData = await DeliverySubscriptionsAPI.getAvailableSubscriptions(payload)
      clearTimeout(fetchTimeout)

      if (subscriptionData.status !== 200) {
        throw new Error(subscriptionData)
      }

      const fullSubscriptionData = expandSubscriptionData(subscriptionData.data.response.availableSubscriptions)
      commit('setAvailableSubscriptions', fullSubscriptionData)
      commit('setEligibleForFreeTrial', subscriptionData.data.response.customerEligibleForFreeSubscription)
      const freeTrialSub = subscriptionData.data.response.availableSubscriptions.find(sub => sub.freeTrialFlag)

      if (freeTrialSub) {
        commit('setFreeTrialDetails', freeTrialSub)
      }
      commit('setAvailableSubscriptionRequestPending', false)
    } catch (e) {
      clearTimeout(fetchTimeout)
      commit('setAvailableSubscriptionRequestPending', false)
      commit('setError', e)
    }
  },
  async purchaseSubscription({
    state, commit, dispatch, rootGetters,
  }) {
    const { userType } = rootGetters['UserProfile/information']
    const monthlyOfferEnabled = userType === 'M'
      ? !!rootGetters['SiteConfig/varByName']('feature_podpass_m_offer')
      : !!rootGetters['SiteConfig/varByName']('feature_podpass_u_offer')
    const payload = {
      serviceLocationId: rootGetters['UserProfile/serviceLocationId'],
      capsId: state.currentPlan.multiPaymentCapsID,
      subscriptionId: state.potentialPlanForPurchase.subscriptionId,
      freeTrialFlag: state.potentialPlanForPurchase.freeTrialFlag,
      userId: rootGetters['UserProfile/userId'],
    }
    trackSubscriptionPlanPurchase({
      subscriptionPlan: state.potentialPlanForPurchase.description
    })
    try {
      commit('setPurchaseInProgress', true)
      const purchaseResult = await DeliverySubscriptionsAPI.purchasePodPass(
        payload
      )

      if (purchaseResult?.status === 200) {
        // reset podpass data
        dispatch('retrieveAndSetUserSubscriptionData')
        dispatch('getAvailableSubscriptions')
        commit('setPurchaseInProgress', false)
        commit('setCompletedPurchase', true)
      } else {
        let errMessage = purchaseResult?.data?.response
        if (!errMessage) {
          // eslint-disable-next-line max-len
          errMessage = 'Sorry, we\'re having technical difficulties processing your transaction. Please try again later.'
        }
        throw new Error(errMessage)
      }
    } catch (e) {
      commit('setUnsuccessfulPurchase', true)
      commit('setError', e)
      commit('Alert/setAlert', {
        icon: '',
        type: 'error',
        header: 'Primary Card Required',
        // eslint-disable-next-line max-len
        body: 'It looks like your Primary Card is expired. We require a valid credit/debit card on file as an alternative payment method to complete the transaction.',
        primary: {
          text: 'Update Primary Card Preference',
          callback: () => {
            commit('Alert/clear', null, { root: true })
            commit('Modals/clearActiveModal', null, { root: true })
            window.sharedVue.config.globalProperties.$router.push('/account/payment')
          }
        }
      }, { root: true })
    }
    if (state.completedPurchase && monthlyOfferEnabled) {
      await dispatch('optUserIntoMonthlyOffer')
      if (!state.monthlyOfferOptInFail) {
        await dispatch('initMonthlyOffer', { refreshCache: true })
      }
    }
  },
  async optUserIntoMonthlyOffer({
    commit, rootGetters, rootState
  }) {
    const { userType } = rootGetters['UserProfile/information']
    const cardNumber = rootGetters['UserProfile/retailerCardNumber']
    const monthlyOfferId = userType === 'M'
      ? rootGetters['SiteConfig/varByName']('feature_podpass_m_offer')
      : rootGetters['SiteConfig/varByName']('feature_podpass_u_offer')
    const { opco } = rootState.SiteConfig

    try {
      await CouponApi.optIn(
        cardNumber,
        opco,
        {
          isDummyOffer: true,
          offerNumber: monthlyOfferId,
          offerSource: 'COPIENT'
        }
      )
    } catch (e) {
      commit('setMonthlyOfferOptInFail', true)
    }
  },
  async loadMonthlyOffer({
    state, rootGetters, rootState, commit
  }) {
    const userId = rootGetters['UserProfile/userId']
    const { opco } = rootState.SiteConfig
    const cardNumber = rootGetters['UserProfile/retailerCardNumber']

    try {
      const monthlyOfferId = state.monthlyOffer.id
      await CouponApi.loadCoupon(userId, { couponId: monthlyOfferId })
      await LoyaltyProgramsApi.refreshOffersCache({ opco, cardNumber })
      const updatedOffer = { ...state.monthlyOffer, optedIn: true }
      commit('setMonthlyOffer', updatedOffer)
    } catch (e) {
      commit('setMonthlyOfferLoadFailure', true)
    }
  },
  async acceptFreeTrial({ state, commit, rootGetters }) {
    try {
      const userId = rootGetters['UserProfile/userId']
      commit('setFreeTrialInProgress', true)
      const freeTrialResult = await DeliverySubscriptionsAPI.purchasePodPass({
        subscriptionId: state.freeTrialDetails.subscriptionId,
        freeTrial: true,
        userId
      })

      if (freeTrialResult.status === 200) {
        commit('setFreeTrialInProgress', false)
        commit('setFreeTrialComplete', true)
      }
    } catch (e) {
      commit('setError', e)
    }
  },
  async cancelRenewal({ commit, rootGetters }) {
    try {
      const userId = rootGetters['UserProfile/userId']
      const cancelResult = await DeliverySubscriptionsAPI.cancelRenewal(userId)
      if (cancelResult?.status === 200) {
        commit('setCancellationComplete', true)
      }
    } catch (e) {
      commit('setError', e)
    }
  },
  async setPendingSubscription({ state, commit, rootGetters }, data) {
    try {
      const payload = {
        serviceLocationId: rootGetters['UserProfile/serviceLocationId'],
        capsId: state.currentPlan.multiPaymentCapsID,
        subscriptionId: data.subscription.subscriptionId,
        userId: rootGetters['UserProfile/userId']
      }
      commit('setPendingInProgress', true)
      commit('setPendingSubID', data.subscription.subscriptionId)
      const setResult = await DeliverySubscriptionsAPI.setPendingSubscription(payload)

      if (setResult.status === 200) {
        commit('setPendingPlan', {
          name: data.subscription.name,
          id: data.subscription.subscriptionId,
          price: data.subscription.price,
          termQty: data.subscription.termQty,
          description: data.subscription.description
        })
        commit('setPendingInProgress', false)
        commit('setPendingComplete', true)
      }
    } catch (e) {
      commit('setError', e)
    }
  },

  /* Wrapper function to capture the full promise chain of offer retrieval */
  async initMonthlyOffer({
    state, getters, dispatch, rootGetters, commit
  }, { refreshCache = false, reset = false } = {}) {
    const { userType } = rootGetters['UserProfile/information']
    const activeSubscription = state.hasActiveSubscription
    const monthlyOfferEnabled = userType === 'M'
      ? !!rootGetters['SiteConfig/varByName']('feature_podpass_m_offer')
      : !!rootGetters['SiteConfig/varByName']('feature_podpass_u_offer')

    if (reset) {
      commit('setMonthlyOfferPromise', null)
      commit('setMonthlyOffer', null)
    }

    if (getters.monthlyOfferPromise) {
      return getters.monthlyOfferPromise
    }

    // Make sure we have an active subscription before we make some expensive calls
    if (!activeSubscription || state.monthlyOffer || !monthlyOfferEnabled) {
      return state.monthlyOffer
    }

    commit('setMonthlyOfferPromise', dispatch('getMonthlyOffer', refreshCache))

    return getters.monthlyOfferPromise
  },

  async getMonthlyOffer({
    commit, state, dispatch, rootState, rootGetters
  }, refreshCache) {
    const { opco } = rootState.SiteConfig
    const cardNumber = rootGetters['UserProfile/retailerCardNumber']
    const userId = rootGetters['UserProfile/userId']
    const { serviceLocationId } = rootGetters['UserProfile/deliveryServiceLocation']
    const userData = { opco, cardNumber, userId }

    if (refreshCache) {
      await LoyaltyProgramsApi.refreshOffersCache({ opco, cardNumber })
    }

    let offer = await LoyaltyProgramsApi.getOffers(userData, {
      type: 'subscription',
      serviceLocationId,
      size: 1,
    })

    // If there are no offers, attempt to opt the user in
    if (offer.status < 300 && !offer?.data?.length) {
      await dispatch('optUserIntoMonthlyOffer')
      if (!state.monthlyOfferOptInFail) {
        await LoyaltyProgramsApi.refreshOffersCache({ opco, cardNumber })
        offer = await LoyaltyProgramsApi.getOffers(userData, {
          type: 'subscription',
          serviceLocationId,
          size: 1,
        })
      }
    }

    if (offer?.data?.length) {
      commit('setMonthlyOffer', offer.data[0])
      return state.monthlyOffer
    }
    commit('setMonthlyOfferLoadFailure', true)
    return null
  },
}

export default {
  namespaced: true,
  state: initialState,
  getters: initialGetters,
  mutations,
  actions
}
