import { GTM, GTM_GA4 } from 'utils/constants'

export function trackPageView({ url, title, type }) {
  const payload = {
    pageURL: url,
    pageTitle: title || '',
    pageCategory: 'ecommerce'
  }
  if (type) payload.type = type
  window.sharedVue.config.globalProperties.$trackGtmEvent(GTM.pageView, payload)
  window.sharedVue.config.globalProperties.$trackGA4Event(GTM_GA4.viewPage, payload)
}
