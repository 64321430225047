import { isObject, isArray } from 'utils/basic/ref-checkers'

/**
 * Returns a query string from an object.
 * If an object's properties are arrays, it will list them in the form a=a1&a=a2&a=a3, etc.
 *
 * @param queryObj
 * @returns {string|string}
 */
export default queryObj => (
  isObject(queryObj)
    ? Object
      .entries(queryObj)
      .reduce((accum, [name, _value]) => {
        const value = isArray(_value) ? _value.join(`&${name}=`) : _value
        return `${accum}${accum && '&'}${name}=${value}`
      }, '')
    : ''
)
