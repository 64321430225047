import ApiService from '@/api/'

export default {
  getFuelRewardsConnectionData(opco) {
    return ApiService.get(`/apis/balances/program/v1/fuelrewards/connectiondetails/${opco}`)
  },
  linkFuelRewardsAccount({
    opco, cardNumber, accountNumber, securityCode
  }) {
    const fuelRewardsString = `${opco}/${cardNumber}/${accountNumber}/${securityCode}`
    return ApiService.put(`/apis/balances/program/v1/fuelrewards/${fuelRewardsString}`, null)
  }
}
