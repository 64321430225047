/** function roundToDecimal - Round a number to the nearest defined decimal
 *
 * @param value - value Number to round
 * @param place - place Integer to which value will be rounded
 * @return Number
 * ex:  3.454321 === 3.454
* */

export default (value, place = 2) => {
  const rex = new RegExp(/^(0|[1-9]\d*)(\.\d+)?$/)
  let response = 0
  if (rex.test(value) && rex.test(place)) {
    const x = Math.round(Number(`${value}e${place}`))
    const y = `e-${place}`
    response = Number(x + y)
  }
  return response
}
