<template>
  <!-- eslint-disable vuejs-accessibility/mouse-events-have-key-events -->
  <div
    :class="[
      'star-toggle',
      { 'star-toggle--brand' : brandColorStars }
    ]"
  >
    <p
      v-if="title"
      class="order-rating_component-title order-rating_component-title--lg"
    >
      {{ title }}
    </p>
    <ul
      :class="[
        'order-rating_inline-list'
      ]"
      @mouseleave="onItemHover(null)"
      @blur="onItemHover(null)"
    >
      <li
        v-for="(item, index) in items"
        :key="index"
        :class="[
          'order-rating_inline-item',
          {'order-rating_inline-item': leftAligned},
          {'flex-none h-10 w-10 !m-0 !mr-2': isPdpRedesign}
        ]"
      >
        <button
          :ref="`starButton${index}`"
          class="button--reset star-toggle_trigger vector-icon_outer-wrapper"
          type="button"
          :aria-label="'Select ' + item"
          @click="onItemClick(index)"
          @mouseover="onItemHover(index)"
        >
          <PdlIconBase
            :icon-title="item"
            :vb-width="isPdpRedesign ? 34 : 260"
            :vb-height="isPdpRedesign ? 34 : 245"
            :container-class="[
              'vector-icon_container',
              'star-toggle_icon',
              {'star-toggle_icon--active': (isActive(index) || isHover(index)) && !isPdpRedesign },
              {'fill-white vector-icon-stroke--dark-goldenrod': isPdpRedesign },
              {
                'vector-icon-color--dark-tangerine vector-icon-stroke--dark-goldenrod':
                (isActive(index) || isHover(index)) && isPdpRedesign
              }
            ]"
          >
            <PdlIconStarSmall v-if="isPdpRedesign" />
            <PdlIconStar v-else />
          </PdlIconBase>
        </button>
      </li>
    </ul>
    <p
      v-if="!hideSelectedValueTitle"
      class="order-rating_component-title
      order-rating_component-title--md order-rating_component-title--semi-bold star-toggle_feedback"
    >
      {{ selectedValueTitle }}
    </p>
  </div>
</template>

<script>
import { PdlIconBase } from '@/shared/icons'
import PdlIconStar from 'components/icons/icon/PdlIconStar'
import PdlIconStarSmall from 'components/icons/icon/PdlIconStarSmall'

export default {
  components: {
    PdlIconBase,
    PdlIconStar,
    PdlIconStarSmall
  },
  props: {
    title: {
      type: String,
      default: null
    },
    items: {
      type: Array,
      required: true
    },
    rateThreshold: {
      type: Number,
      default: 3
    },
    selection: {
      type: Number,
      default: null
    },
    hideSelectedValueTitle: {
      type: Boolean,
      default: false
    },
    leftAligned: {
      type: Boolean,
      default: false
    },
    brandColorStars: {
      type: Boolean,
      default: false
    },
    isPdpRedesign: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedKey: this.selection,
      hoverKey: null
    }
  },
  computed: {
    selected() {
      return this.items[this.selectedKey]
    },
    positive() {
      return this.hasSelectedValue && (this.selectedKey + 1) > this.rateThreshold
    },
    hasSelectedValue() {
      return !!this.selected
    },
    selectedValueTitle() {
      return (this.hasSelectedValue) ? this.selected : '- - -'
    }
  },
  methods: {
    onItemClick(key) {
      this.selectedKey = key
      this.$emit('star-toggle-selected', this.selectedKey + 1)
    },
    onItemHover(key) {
      this.hoverKey = key
    },
    isHover(key) {
      return this.hoverKey !== null && key <= this.hoverKey
    },
    isActive(key) {
      return this.selectedKey !== null && key <= this.selectedKey
    }
  }
}
</script>
