/* eslint-disable */
import dateFormat from 'dateformat'
import parseDateTime from 'utils/filters/parseDateTime'

function validAddressFieldsReceived(addressObj) {
  const validKeys = 'addr|city|state|zip'.split('|')
  let validPayload = true

  Object.keys(addressObj).some(key => {
    if (!validKeys.includes(key)) {
      validPayload = false
      return true
    }
  })

  return validPayload
}

function formatAddressString(addressObj) {
  return `${addressObj.addr} ${addressObj.city}, ${addressObj.state} ${addressObj.zip}`
}

export default {
  namespaced: true,
  state: {
    pickupAddress: {
      addr: '',
      city: '',
      state: '',
      zip: '',
      name: ''
    },
    browsingAddress: {
      addr: '',
      city: '',
      state: '',
      zip: ''
    },
    defaultDeliveryZip: null,
    deliveryStreetAddress: null,
    orderWeekday: '',
    orderDayOfMonth: '',
    orderStartTimestamp: '',
    orderEndTimestamp: '',
    orderTimeRange: '',
    orderTimeRangeMin: '',
    actionRequests: 0,
    actionClicked: '',
    serviceTypeSet: '',
    widgetReady: false,
    displayDateAs: 'day',
    helpModeActive: false,
    storeConfirmationActive: false,
    userSelectedService: false, // < store wether user has set their own service type or if they are on default
    serviceSelectionBypassed: false,
    widgetWidth: 0,
    widgetModeWidth: 0
  },
  getters: {
    browseStoreAddress: state => formatAddressString(state.browsingAddress),
    browseAddressDisplay: state => state.browsingAddress.addr ? state.browsingAddress.addr : state.browsingAddress.zip,
    dateDisplay: state => {
      return state.displayDateAs === 'day' ? state.orderWeekday : state.orderDayOfMonth
    },
    deliveryAddressText: state => {
      return state.deliveryStreetAddress ? state.deliveryStreetAddress : state.defaultDeliveryZip
    },
    pickupStreetAddress: state => state.pickupAddress.addr,
    prefferedPickupAddress: state => {
      return `${state.pickupAddress.addr} ${state.pickupAddress.city}, ${state.pickupAddress.state} ${state.pickupAddress.zip}`
    },
    zipcode: state => {
      if (state.serviceTypeSet === 'B') {
        return state.browsingAddress.zip
      }
      if (state.serviceTypeSet === 'P') {
        return state.pickupAddress.zip
      }
      return state.defaultDeliveryZip
    },
    displayCity: state => state.browsingAddress.city ? state.browsingAddress.city : state.pickupAddress.city
  },
  mutations: {
    writePickupAddress(state, validatedPayload) {
      Object.keys(validatedPayload).forEach(key => {
        state.pickupAddress[key] = validatedPayload[key]
      })
    },
    writeBrowseAddress(state, validatedPayload) {
      Object.keys(validatedPayload).forEach(key => {
        state.browsingAddress[key] = validatedPayload[key]
      })
    },
    setSlotDayOfMonth(state, payload) {
      state.orderDayOfMonth = payload
    },
    setSlotWeekday(state, payload) {
      state.orderWeekday = payload
    },
    setOrderTimeRange(state, payload) {
      state.orderTimeRange = payload
    },
    setOrderTimeRangeMin(state, payload) {
      state.orderTimeRangeMin = payload
    },
    setDefaultDeliveryZip(state, payload) {
      state.defaultDeliveryZip = payload
    },
    setDeliveryAddress(state, payload) {
      state.deliveryStreetAddress = payload
    },
    setNextActionRequest(state, payload) {
      state.actionRequests += 1
      state.actionClicked = payload.tileMode
      state.serviceTypeSet = payload.serviceType
    },
    setDateFavor(state, payload) {
      state.displayDateAs = payload
    },
    setWidgetReady(state) {
      state.widgetReady = true
    },
    setHelpMode(state, payload = false) {
      state.helpModeActive = payload
    },
    setStoreConfirmationState(state, payload) {
      state.storeConfirmationActive = payload
    },
    setServiceType(state, payload) {
      state.serviceTypeSet = payload
    },
    setUserSelected(state, payload) {
      state.userSelectedService = payload
    },
    setServiceTypeBypass(state, payload = false) {
      state.serviceSelectionBypassed = payload
    },
    setWidgetWidth(state, payload) {
      state.widgetWidth = payload
    },
    setWidgetModeWidth(state, payload) {
      state.widgetModeWidth = payload
    },
    setOrderStartTimestamp(state, payload) {
      state.orderStartTimestamp = payload
    },
    setOrderEndTimestamp(state, payload) {
      state.orderEndTimestamp = payload
    }
  },
  actions: {
    setPickupAddress({ commit }, payload) {
      const validKeys = 'addr|city|state|zip|name'.split('|')
      let validPayload = true
      Object.keys(payload).some(key => {
        if (!validKeys.includes(key)) {
          validPayload = false
          return true
        }
      })

      if (validPayload) {
        commit('writePickupAddress', payload)
      }
    },
    setBrowseAddress({ commit }, payload) {
      if (validAddressFieldsReceived(payload)) {
        commit('writeBrowseAddress', payload)
      }
    },
    clearCurrentSlot({ commit }) { // slots may also need to be cleared by [Slots/clearSelectedSlot]
      commit('setOrderTimeRange', '')
      commit('setOrderTimeRangeMin', '')
      commit('setSlotDayOfMonth', '')
      commit('setSlotWeekday', '')
      commit('setDateFavor', 'day')
      commit('setOrderStartTimestamp', '')
      commit('setOrderEndTimestamp', '')
    },
    setTimeSlotValues({ dispatch, commit }, payload) {
      const validDatePattern = new RegExp(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/)
      // fail back if invalid date patterns are detected
      if (!(validDatePattern.test(payload.startTime) && validDatePattern.test(payload.endTime))) {
        return dispatch('clearCurrentSlot')
      }

      function formatTimeRange(startDate, endDate, longForm = true) {
        function formatTime(timeString, annotation = true) {
          const parsedTime = parseDateTime(timeString)
          return dateFormat(parsedTime, `h:MM${annotation ? 'TT' : ''}`)
        }
        return `${formatTime(startDate, longForm)}-${formatTime(endDate)}`
      }

      commit('setSlotDayOfMonth', dateFormat(payload.startTime, 'mmm d'))
      commit('setSlotWeekday', dateFormat(payload.startTime, 'ddd'))
      commit('setOrderStartTimestamp', payload.startTime)
      commit('setOrderEndTimestamp', payload.endTime)
      commit('setOrderTimeRange', formatTimeRange(payload.startTime, payload.endTime).toLowerCase())
      commit('setOrderTimeRangeMin', formatTimeRange(payload.startTime, payload.endTime, false).toLowerCase())

      const oneWeek = 1000 * 60 * 60 * 24 * 7
      const startTimeEpoch = new Date(payload.startTime).getTime()
      const oneWeekFromNow = new Date(new Date().getTime() + oneWeek)

      commit('setDateFavor', startTimeEpoch > oneWeekFromNow ? 'date' : 'day')
    },
    setDeliveryDefaultZip({ commit }, payload) {
      commit('setDefaultDeliveryZip', payload)
    },
    setDeliveryAddress({ commit }, payload) {
      commit('setDeliveryAddress', payload)
    },
    optionClick({ commit }, payload) {
      commit('setNextActionRequest', payload)
    },
    setHelpMode({ commit }, payload) {
      commit('setHelpMode', payload)
    },
    setStoreConfirmationState({ commit }, payload) {
      commit('setStoreConfirmationState', payload)
    },
    setWidgetWidth({ commit }, payload) {
      commit('setWidgetWidth', payload.template)
      commit('setWidgetModeWidth', payload.shopping_mode)
    }
  }
}
