export default (category) => {
  if (!category) {
    return ''
  }
  if (category.categoryName === 'Dairy') {
    return 'Dairy & Eggs'
  }
  if (category.categoryName === 'Rice, Grains, Pasta & Beans') {
    return 'Rice, Pasta & Beans'
  }
  if (category.categoryName === 'Baking & Cooking Needs') {
    return 'Baking & Cooking'
  }
  if (category.categoryName === 'Alcoholic Beverages') {
    return 'Adult Beverages'
  }
  return category.categoryName
}
