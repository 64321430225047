import orderStatusAPI from 'api/orderStatusAPI'
import orderAPI from 'api/orderAPI'
import { LOGGING_TYPE, ORDER_STATUS, ORDER_SERVICE_TYPES } from 'utils/constants'
import formatPhoneNumber from 'utils/filters/formatPhoneNumber'
import helpers from './helpers/OrderStatusHelpers'

export default {
  namespaced: true,
  state: {
    loading: false,
    loadingError: false,
    pickupStatusError: false,
    orderStatus: {},
    pickupStatus: {},
    cancelOrderEbtPayment: {},
    previousOrderStatusDetails: {},
    allowSMSPickup: false,
    trackOrderId: null,
    trackingSteps: helpers.trackingSteps,
    trackingPickupSteps: helpers.trackingPickupSteps,
    initialized: false,
    initResponse: null,
    orderNoticeViewed: false,
    orderProviderId: '',
    pickupStatusLoaded: false,
    multiBasketEnabled: true,
    submittedPendingOrders: [],
    suppressAddToExistingOrderModal: false,
    canceledOrderInfo: {}
  },
  getters: {
    // eslint-disable-next-line  max-params
    configOrdersAhead: rootGetters => (
      // Get from Profile : Determines how we compute that an order is nearby
      rootGetters['UserProfile/configOrdersAhead'] || 5
    ),
    orderStatus: state => state.orderStatus,
    pickupStatus: state => state.pickupStatus,
    cancelOrderEbtPayment: state => state.cancelOrderEbtPayment,
    previousOrderStatusDetails: state => state.previousOrderStatusDetails,
    allowSMSPickup: state => state.allowSMSPickup,
    currentOrder: state => (state.orderStatus?.currentOrder || null),
    currentOrderId: (state, getters) => (getters.currentOrder?.orderId || null),
    currentOrderType: state => (state.orderStatus?.currentOrder?.serviceType || null),
    currentOrderSubmitted: (state, getters) => (getters.currentOrder?.status === 'SUBMITTED'),
    currentOrderHasTime: (state, getters) => (!!getters.currentOrder?.timeStartWithZoneOffset),
    suppressAddToExistingOrderModal: state => state.suppressAddToExistingOrderModal,
    trackingOrderId: state => (state.trackOrderId),
    trackingOrder: (state, getters) => {
      const orderId = state.trackOrderId
      return _.findWhere(getters.pendingOrders, { orderId })
    },
    hasTrackingOrder: (state, getters) => !!getters.trackingOrder,
    trackingOrderDestination: (state, getters) => ((!getters.hasTrackingOrder) ? {}
      : getters.trackingOrder.deliveryLocation),
    trackingOrderPickupLocation: (state, getters) => ((!getters.hasTrackingOrder) ? {}
      : getters.trackingOrder.pickupLocation),
    trackingCurrentStep: (state, getters) => ((getters.hasTrackingOrder)
      ? helpers.getCurrentStep(getters.trackingOrder, getters.configOrdersAhead)
      : 1),
    trackingCurrentPickupStep: (state, getters) => (getters.pickupStatus
      ? helpers.getCurrentPickupStep(getters.pickupStatus, getters.trackingOrder) : 1),
    trackingStopsAway: (state, getters) => {
      return ((getters.hasTrackingOrder)
        ? helpers.getStopsAway(getters.trackingOrder)
        : null)
    },
    trackingSteps: state => state.trackingSteps,
    trackingPickupSteps: state => state.trackingPickupSteps,
    trackingSubProducts: (state, getters) => {
      return ((getters.hasTrackingOrder)
        ? helpers.getSubProducts(getters.trackingOrder)
        : [])
    },
    trackingOOSProducts: (state, getters) => {
      return ((getters.hasTrackingOrder)
        ? helpers.getOOSProducts(getters.trackingOrder)
        : [])
    },
    trackingLocations: (state, getters) => ((getters.hasTrackingOrder)
      ? helpers.getLocations(getters.trackingOrder)
      : {}),
    trackingArrivalWindow: (state, getters) => ((getters.hasTrackingOrder)
      ? helpers.calcCurrentEta(getters.trackingOrder)
      : {}),
    trackingArrivalTime: (state, getters) => ((getters.hasTrackingOrder)
      ? helpers.getDeliveryTime(getters.trackingOrder)
      : 0),
    trackingOrderDriverName: (state, getters) => (
      getters.hasTrackingOrder ? helpers.getDriverName(getters.trackingOrder)
        : ''
    ),
    trackingDelayed: (state, getters) => getters.hasTrackingOrder && helpers.isDelayed(getters.trackingOrder),
    isDelivery: (state, getters) => getters.trackingOrder?.serviceType === 'D',
    isPickup: (state, getters) => getters.trackingOrder?.serviceType === 'P',
    trackingOrderProvider: (state, getters) => (
      getters.trackingOrder ? getters.trackingOrder.providerId : state.orderProviderId
    ),
    trackingOrderExternalStatusUrl: (_, getters) => (
      getters.trackingOrder?.orderProgress?.route?.progress?.externalProviderOrderStatusUrl
    ),
    initialized: state => !!(state.orderStatus && Object.keys(state.orderStatus).length > 0),
    loading: state => state.loading,
    loadingError: state => state.loadingError,
    orderProgressByObject: (state, getters) => (order) => {
      if (!order || _.isEmpty(order)) return null
      const currentStep = helpers.getCurrentStep(order, getters.configOrdersAhead)
      const step = _.find(state.trackingSteps, s => s.id === currentStep) || ''
      const statusLabel = step.label
      const { trackingSteps } = state
      const statusId = _.findKey(trackingSteps, s => s.id === currentStep)
      const { serviceType } = order

      return {
        serviceType,
        statusId,
        trackingSteps,
        statusLabel,
        arrivalTime: helpers.getDeliveryTime(order),
        isDelayed: helpers.isDelayed(order)
      }
    },
    // eslint-disable-next-line  max-params
    orderMinimum: (state, getters) => {
      const storeData = getters.orderStatus?.store || {}
      const { deliveryMinOrder, pickupMinOrder, isPickup } = storeData
      return (!isPickup ? deliveryMinOrder : pickupMinOrder)
    },
    hasMetOrderMinimum: (state, getters, rootState, rootGetters) => {
      const amount = getters.orderMinimum
      const { subTotalPrice } = rootGetters['Cart/getCart'] || 0
      return subTotalPrice >= amount || false
    },
    orderNoticeViewed: state => (state.orderNoticeViewed),
    helperByName: () => (name, order) => {
      if (!helpers[name]) return undefined
      return helpers[name](order)
    },
    fulfillmentItems: (state) => {
      if (_.isEmpty(state.pickupStatus)) return {}
      return state.pickupStatus.items.reduce((acc, currentItem) => {
        acc[currentItem.podId] = currentItem
        return acc
      }, {})
    },
    pickupStatusLoaded: state => state.pickupStatusLoaded,
    pickupStatusError: state => state.pickupStatusError,
    getSubmittedPendingOrders: state => state.submittedPendingOrders,
    // eslint-disable-next-line
    multiBasketEnabled: (state, getters, rootState, rootGetters) => rootGetters['SiteConfig/varByName']('feature_multibasket'),
    pendingOrdersPlaceholder: (state) => {
      const { orderStatus: { pendingOrder = [] } = {}, submittedPendingOrders = [], multiBasketEnabled = false } = state
      if (multiBasketEnabled) {
        return [
          ...submittedPendingOrders,
          ...pendingOrder, // post-cut off orders
        ]
      }
      return pendingOrder
    },
    get pendingOrders() {
      return this.pendingOrdersPlaceholder
    },
    set pendingOrders(value) {
      this.pendingOrdersPlaceholder = value
    },
    canceledOrderInfo: state => state.canceledOrderInfo,
  },
  mutations: {
    setInitResponse(state, payload) {
      state.initResponse = payload || Promise.resolve(false)
    },
    setLoading(state, payload) {
      state.loading = !!payload
    },
    setLoadingError(state, payload) {
      state.loadingError = !!payload
    },
    setPickupStatusError(state, payload) {
      // Payload is a Boolean
      state.pickupStatusError = payload
    },
    setOrderStatus(state, payload) {
      state.orderStatus = payload
    },
    setPickupStatus(state, payload) {
      // payload is the object from the response
      // when we make the fulfillment api call
      state.pickupStatus = payload
    },
    setCancelOrderEbtPayment(state, payload) {
      state.cancelOrderEbtPayment = payload
    },
    setPreviousOrderStatusDetails(state, payload) {
      state.previousOrderStatusDetails = payload
    },
    setAllowSMSPickup(state, payload) {
      state.allowSMSPickup = payload
    },
    // reference: PdlAddToExistingCartModal
    setSuppressAddToExistingOrderModal(state, payload) {
      state.suppressAddToExistingOrderModal = payload
    },
    setTrackOrderId(state, id) {
      state.trackOrderId = id
    },
    setOrderNoticeViewed(state, payload) {
      state.orderNoticeViewed = payload
    },
    setOrderProviderId(state, payload) {
      state.orderProviderId = payload
    },
    setPickupStatusLoaded(state, payload) {
      state.pickupStatusLoaded = payload
    },
    updateSubItemStatus(state, payload) {
      const item = _.findWhere(state.pickupStatus.items, { podId: Number(payload.productId) })
      item.substituteItem.subRejectCd = payload.subRejectStatusCd
      const itemIndex = state.pickupStatus.items.findIndex(
        product => product.podId === item.podId
      )
      state.pickupStatus.items.splice(itemIndex, 1, item)
    },
    setSubmittedPendingOrders(state, payload) {
      state.submittedPendingOrders = payload
    },
    setCanceledOrderInfo(state, payload) {
      state.canceledOrderInfo = payload
    },
  },
  actions: {
    async initOrderStatus({
      dispatch, state, commit, getters, rootGetters
    }, params = {}) {
      const { canRefresh = false } = params
      const version = 'v6.0'
      const userStatus = rootGetters['LoginStatus/userStatus']
      const isGuest = userStatus === 'G'
      const userId = rootGetters['UserProfile/userId']
      const multiBasketEnabled = rootGetters['SiteConfig/varByName']('feature_multibasket')
      await rootGetters['Cart/getBasketPromise']

      let caller
      let response = Promise.resolve(false)
      commit('setLoadingError', false)
      if ((canRefresh || !getters.initialized)) {
        try {
          if (!getters.loading) {
            caller = orderStatusAPI.getFullOrderStatus(userId, !isGuest)

            commit('setInitResponse', caller)
          } else {
            caller = state.initResponse
          }

          commit('setLoading', true)
          response = await caller

          commit('setLoading', false)
          if (response.status === 200) {
            const orderStatus = helpers.mapOrderStatus(response, version)
            if (multiBasketEnabled) dispatch('handleSubmittedPendingOrders', response)
            commit('setOrderStatus', orderStatus)
            dispatch('allowSMSPickup', orderStatus)
            dispatch('checkForOrderProviderId', orderStatus)
            const { currentOrder } = orderStatus
            dispatch('setPickupAddress', orderStatus)
            this.dispatch('ShoppingMode/setTimeSlotValues', {
              startTime: currentOrder.timeStart,
              endTime: currentOrder.timeEnd
            })
          } else {
            commit('setLoading', false)
            commit('setLoadingError', true)
          }
        } catch (e) {
          commit('setLoading', false)
          commit('setLoadingError', true)
        }
      }
      return response
    },
    setPickupAddress(_, orderStatus) {
      const { currentOrder, store } = orderStatus
      if (currentOrder.serviceType === ORDER_SERVICE_TYPES.PICKUP) {
        const {
          address, city, state, zip, title
        } = store
        this.dispatch('ShoppingMode/setPickupAddress', {
          addr: address,
          city,
          state,
          zip,
          name: title,
        })
      }
    },
    setTrackOrder({ commit }, payload) {
      commit('setTrackOrderId', payload)
    },
    checkForOrderProviderId({ commit }, payload) {
      try {
        const pendingOrderList = payload?.pendingOrder
        if (
          Array.isArray(pendingOrderList)
          && pendingOrderList.length
        ) {
          commit('setOrderProviderId', pendingOrderList[0]?.providerId || '')
        }
      } catch (e) {
        commit('setOrderProviderId', '')
      }
    },
    async allowSMSPickup({ commit, dispatch }, { currentOrder }) {
      let featureFlag = false
      if ((currentOrder.status === 'SUBMITTED' || currentOrder.status === 'WORKING')
        && currentOrder.serviceType === 'P') {
        const enabledAtStore = await dispatch('SiteConfig/getStoreConfigValue', {
          attribute: 'feature_fly_buy_enabled'
        }, { root: true })
        featureFlag = enabledAtStore
      }
      commit('setAllowSMSPickup', featureFlag)
    },
    async cancelOrder({
      commit, dispatch, getters, rootGetters
    }) {
      const userId = rootGetters['UserProfile/userId']
      const basketId = rootGetters['Cart/getBasketId']
      const payload = {
        userId,
        basketId,
      }
      commit('setPreviousOrderStatusDetails', getters.orderStatus)
      const response = await orderAPI.cancelOrder(payload)
      const ebtPayment = response?.data?.response?.ebtPayment
      commit('setCancelOrderEbtPayment', ebtPayment)
      await dispatch('initOrderStatus', { canRefresh: true })
      return response
    },
    async initPickupData({
      commit, rootGetters, getters
    }) {
      try {
        const { userId } = rootGetters['UserProfile/information']
        const { orderId } = getters.trackingOrder
        const response = await orderStatusAPI.getFulfillmentOrderStatus(userId, orderId)
        if (response.status === 200) {
          commit('setPickupStatus', response.data)
          commit('setPickupStatusLoaded', true)
          return
        }
        throw new Error()
      } catch (error) {
        commit('setPickupStatusLoaded', true)
        commit('setPickupStatusError', true)
        window.sharedVue.config.globalProperties.$trackClientLog('feature-cnc-order-status',
          { error: 'Something went wrong fetching fulfillment items.' }, LOGGING_TYPE.exception)
      }
    },
    handleSubmittedPendingOrders({ commit }, payload) {
      const { SUBMITTED } = ORDER_STATUS

      const submittedPendingOrders = payload?.data?.currentOrders?.filter((order) => {
        return order.status === SUBMITTED
      })
      commit('setSubmittedPendingOrders', submittedPendingOrders)
    },
    async submitSubPref({ commit, getters, rootGetters }, payload) {
      const userId = rootGetters['UserProfile/userId']
      const { orderId, pickupLocation } = getters.trackingOrder
      try {
        const response = await orderStatusAPI.postSubstitutionPreference(userId, orderId, payload)
        if (response.status === 200 || response.response.status === 200) {
          commit('updateSubItemStatus', {
            orderId,
            productId: payload[0].origPodId,
            subRejectStatusCd: payload[0].subRejectStatusCd
          })
          return true
        }

        const error = {
          400: {
            message: 'We had trouble updating your substitutions. Please try again.'
          },
          401: {
            message: 'Please refresh the page to sign in and try updating your substitutions again.'
          },
          423: {
            message: `We can't update substitutions less than 15 minutes before your pickup time begins.`
          },
          500: {
            message: `
              Sorry, we can't update your substitutions right now.
              Please call the store at ${formatPhoneNumber(pickupLocation?.phoneNumber)} to make updates.
            `
          },
          503: {
            message: `
              We had trouble updating your substitutions. Please try again. If the problem persists,
              please call the store at ${formatPhoneNumber(pickupLocation?.phoneNumber)} to make updates.
            `
          },
        }

        throw error[response.response.status]
      } catch (error) {
        commit('Alert/setAlert', {
          icon: '',
          type: 'error',
          header: 'Unable to Update Substitutions',
          body: error.message,
          primary: {
            text: 'Ok',
            callback: () => {
              commit('Alert/clear', null, { root: true })
            }
          }
        }, { root: true })
        return false
      }
    },
    async getOrderDetails({
      state
    }) {
      const { trackOrderId, submittedPendingOrders = [] } = state
      return submittedPendingOrders.find(({ orderId = '' } = {}) => orderId === trackOrderId)
    },
  }
}
