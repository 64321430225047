<template>
  <div
    :class="[
      'global-header_account-container spyglass-nav-group_wrapper',
      {
        'flex items-center space-evenly': isModernLayout
      }
    ]"
  >
    <PdlAccountButton
      :is-new-nav-design-enabled="isModernLayout"
      :opco="opco"
      v-show="!isMobileApp"
      ref="accountButton"
      button-id="header-account-button"
      :first-name="displayFirstName"
      @account-button-clicked="onAccountButtonClick"
      @focus-on-account-menu="focusOnAccountMenu"
      @close-menu="closeMenu"
    />
    <PdlAccountMenu
      ref="accountMenu"
      v-clickaway="{
        exclude: 'header-account-button',
        handler: 'closeMenu'
      }"
      :is-new-nav-design-enabled="isModernLayout"
      :account-menu-items="accountMenuItems"
      @nav-item-clicked="onNavItemClick"
      @change-city="onChangeCity"
      @close-menu="closeMenu"
      @logout="onLogout"
      @track-nav-updated="trackNavUpdated"
    />
    <template
      v-if="isModernLayout"
    >
      <div class="hidden lg:block border-l border-solid border-gray-100 h-10"/>
      <PdlMainNavItem
        class='hidden lg:block pl-4 font-normal text-sm'
        :link-class="'!font-normal my-0 text-black'"
        :label="'Shopping List'"
        :url="'/personal-list'"
        :link-type="'Internal'"
        @nav-item-clicked="onNavItemClick"
      />
    </template>
    <PdlSpyglass
      v-if="spyglassActive"
      :spyglass-content="menuSpyGlassData"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import clickaway from 'utils/custom-directives/clickaway'
import PdlAccountMenu from 'components/navigation/PdlAccountMenu'
import PdlMainNavItem from 'components/navigation/nav-items/PdlMainNavItem'
import PdlAccountButton from 'components/navigation/PdlAccountButton'
import PdlSpyglass from 'components/spyglass/PdlSpyglass'

export default {
  components: {
    PdlAccountMenu,
    PdlAccountButton,
    PdlSpyglass,
    PdlMainNavItem
  },
  directives: { clickaway },
  props: {
    isNewNavDesignEnabled: {
      type: Boolean,
      default: false
    },
    isMobileApp: {
      type: Boolean,
      default: false
    },
    opco: {
      type: String,
      required: true
    },
    spyglassActive: {
      type: Boolean
    },
  },
  computed: {
    ...mapGetters({
      isPartiallyRegistered: 'UserProfile/isPartiallyRegistered',
      isAccountMenuOpen: 'GlobalHeader/isAccountMenuOpen',
      accountMenuItems: 'GlobalHeader/accountMenuItems',
      displayFirstName: 'UserProfile/displayFirstName'
    }),
    isModernLayout() {
      return this.isNewNavDesignEnabled || this.isMobileNavABTest
    },
    menuSpyGlassData() {
      return this.accountMenuItems?.spyGlassData || {}
    }
  },
  methods: {
    ...mapActions({
      toggleAccountMenu: 'GlobalHeader/toggleAccountMenu',
      setCloseAccountMenu: 'GlobalHeader/setCloseAccountMenu'
    }),
    onAccountButtonClick() {
      this.toggleAccountMenu()
      this.$emit('account-menu-clicked')
      if (this.isAccountMenuOpen) {
        this.trackNavUpdated({
          action: this.isLoggedIn ? 'utility header - signed in' : 'utility header - signed out',
          url: 'expand account',
          label: 'account'
        })
      } else {
        this.trackNavUpdated({
          action: this.isLoggedIn ? 'utility header - signed in' : 'utility header - signed out',
          url: 'collapse account',
          label: 'account'
        })
      }
    },
    onNavItemClick(label, url, linkType, elementId) {
      this.$emit('nav-item-clicked', label, url, linkType, elementId)
    },
    onLogout() {
      this.$emit('logout')
      this.trackNavUpdated({
        action: this.isLoggedIn ? 'utility header - signed in' : 'utility header - signed out',
        label: 'Log Out'
      })
    },
    focusOnAccountMenu() {
      const focusableElement = this.$refs.accountMenu.$el.querySelector('button, [href]')
      focusableElement.focus()
    },
    closeMenu() {
      this.setCloseAccountMenu()
    },
    trackNavUpdated(data) {
      this.$emit('track-nav-updated', data)
    },
    onChangeCity() {
      this.$emit('change-city')
    }
  }
}
</script>
