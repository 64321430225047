import { GTM } from 'utils/constants'

export default function trackNavigationGtm({ navLocation = '', navElementText = '', navURL = '' }) {
  const vueInstance = window.sharedVue.config.globalProperties
  vueInstance.$trackGtmEvent(GTM.navigation, {
    navLocation,
    navElementText: `${navElementText}`,
    navURL: `${navURL}`
  })
}
