import couponAPI from 'api/couponApi'

const setApiParams = (searchKeyword, state, rootGetters) => {
  const { couponFilter = [], sortSelection } = state
  const couponSearch = rootGetters['SiteConfig/varByName']('feature_coupon_search')
  const apiParams = {
    selectedCategories: couponFilter,
    loadable: true,
    ...(searchKeyword?.startsWith('+')
      ? { id: searchKeyword.substring(1) } : { keywords: searchKeyword }),
    ...(couponSearch && { sourceSystems: ['QUO', 'COP', 'INM'] }),
  }
  if (sortSelection && sortSelection !== 'Recommended') {
    const [sortSelectionObj] = state.sortOptions.filter(sort => sort.value === sortSelection)
    apiParams.sortSelection = sortSelectionObj
  }

  return { apiParams, couponFilter }
}

export default {
  namespaced: true,
  state: {
    totalCoupons: [],
    limit: 60,
    couponLimit: 60,
    sortSelection: 'Recommended',
    searchKeyword: '',
    sortOptions: [
      {
        title: 'Recommended',
        value: 'Recommended',
        order: '',
        name: ''
      },
      {
        title: 'Expiration Date',
        value: 'Expiration Date',
        order: 'asc',
        name: 'endDate'
      },
      {
        title: 'Value (High to Low)',
        value: 'Value (High to Low)',
        order: 'desc',
        name: 'maxDiscount'
      },
      {
        title: 'Brand (A to Z)',
        value: 'Brand',
        order: 'asc',
        name: 'brand'
      },
    ],
    couponFilter: [],
    shouldShowOtherCoupons: false,
    dataLoaded: false
  },
  getters: {
    totalCoupons: (state) => {
      const { totalCoupons: coupon = [] } = state
      return state.couponLimit ? coupon.slice(0, state.couponLimit) : coupon
    },
    totalCouponLimit(state) {
      const { totalCoupons = [] } = state
      return totalCoupons.length
    },
    sortSelection: state => state.sortSelection,
    sortOptions: state => state.sortOptions,
  },
  mutations: {
    setShouldShowOtherCoupons(state, payload) {
      state.shouldShowOtherCoupons = payload
    },
    setSortSelection(state, payload) {
      state.sortSelection = payload
    },
    clearFilter(state) {
      state.couponFilter = state.couponFilter.map((category) => {
        category.active = false
        return category
      })
    },
    setTotalCoupons(state, payload) {
      state.totalCoupons = payload
    },
    setCouponFilters: (state, payload) => {
      if (payload?.facets) {
        const { categoryTreeId } = payload.facets
        const categories = categoryTreeId.map(category => ({ ...category, active: false }))
        state.couponFilter = categories
      }
      if (Array.isArray(payload) && payload.length === 0) {
        state.couponFilter = []
      }
    },
    removeFilterSelection(state, payload) {
      state.couponFilter.splice(payload, 1)
    },
    setFilterActive: (state, payload) => {
      state.couponFilter[payload.obj.value].active = payload.obj.checked
    },
    setCouponLimit(state) {
      state.couponLimit += state.limit
    },
    resetCouponLimit(state) {
      // state.clippedLimit = state.limit
      state.couponLimit = state.limit
    },
    setSearchKeyword: (state, payload) => {
      state.searchKeyword = payload
    },
    setDataLoaded(state, payload) {
      state.dataLoaded = payload
    }
  },
  actions: {
    allCouponApi({
      commit, state, rootGetters, dispatch
    }, searchKeyword) {
      dispatch('checkSearchKeyword', searchKeyword)
      const userId = rootGetters['UserProfile/userId']
      const { serviceLocationId } = rootGetters['UserProfile/deliveryServiceLocation']
      const cardNumber = rootGetters['UserProfile/retailerCardNumber']
      const couponVersion = rootGetters['SiteConfig/varByName']('coupon_version')

      return new Promise((resolve, reject) => {
        (async () => {
          // eslint-disable-next-line
          let { apiParams, couponFilter } = setApiParams(searchKeyword, state, rootGetters)
          try {
            // eslint-disable-next-line
            let allCouponsAPI = await couponAPI.getCoupons(userId, serviceLocationId, cardNumber, couponVersion, apiParams)
            if (allCouponsAPI.status === 200) {
              let totalCoupons = allCouponsAPI.data.coupons
              if (totalCoupons.length === 0) {
                apiParams = {
                  loaded: false, loadable: true, targeted: true, size: 25
                }
                // eslint-disable-next-line
              allCouponsAPI = await couponAPI.getCoupons(userId, serviceLocationId, cardNumber, couponVersion, apiParams)
                totalCoupons = allCouponsAPI.data.coupons
                commit('setShouldShowOtherCoupons', true)
                commit('setDataLoaded', true)
              } else {
                commit('setShouldShowOtherCoupons', false)
              }
              commit('setTotalCoupons', totalCoupons)
              commit('setDataLoaded', true)
              if (couponFilter.length === 0) {
                commit('setCouponFilters', allCouponsAPI.data)
              }
              dispatch('Coupons/setCouponSearch', totalCoupons, { root: true })
            }
          } catch (error) {
            commit('setFailed', true)
            commit('setDataLoaded', true)
            reject(error)
            return
          }
          resolve(true)
        })()
      })
    },
    checkSearchKeyword({ commit, state }, searchKeyword) {
      if (searchKeyword && state.searchKeyword !== '' && state.searchKeyword !== searchKeyword) {
        commit('setSearchKeyword', searchKeyword)
        commit('resetCouponLimit')
        commit('setCouponFilters', [])
        commit('setFailed', false)
      } else {
        commit('setSearchKeyword', searchKeyword)
      }
    },
    clearFilterSelections({ commit, dispatch, state }) {
      commit('clearFilter')
      dispatch('allCouponApi', state.searchKeyword)
    },
    applySelectedSort({ commit, state, dispatch }, payload) {
      commit('setSortSelection', payload)
      dispatch('allCouponApi', state.searchKeyword)
    },
    selectFilter({ commit, dispatch, state }, payload) {
      commit('setFilterActive', payload)
      dispatch('allCouponApi', state.searchKeyword)
    },
    allCouponsLimit({ commit }) {
      commit('setCouponLimit')
    },
    clearFilterPill({ commit, dispatch, state }, payload) {
      commit('setFilterActive', payload)
      commit('removeFilterSelection', payload.obj.label)
      dispatch('allCouponApi', state.searchKeyword)
    },
    clearSearchResults({ commit }) {
      commit('setTotalCoupons', [])
      commit('setDataLoaded', false)
    }
  }
}
