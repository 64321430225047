/**
 * /** function timeAfterTime - determine if current time is past the param "time"
 * @param {*} time - date time string
 */

export default function (time) {
  const now = new Date()
  const currentTime = window.dateFormat(now, 'isoUtcDateTime')
  const timeToCompare = window.dateFormat(time, 'isoUtcDateTime')
  return currentTime > timeToCompare
}
