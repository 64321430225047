/* eslint-disable no-useless-catch */
import LoyaltyProgramsAPI from 'api/LoyaltyProgramsAPI'
import RewardsPreferenceAPI from 'api/RewardsPreferenceAPI'
import UserPreferencesAPI from 'api/UserPreferencesAPI'
import ServiceLocationsAPI from 'api/ServiceLocationsAPI'
import { USER_PREFERENCE_KEYS } from 'utils/constants'

const expiringPointsReducer = (accum, entry) => {
  if (daysTillExpiration(entry.expirationDate) < 6) {
    accum += entry.balance
  }
  return accum
}

const expiringDaysReducer = (accum, entry) => {
  const days = daysTillExpiration(entry.expirationDate)
  if (days < 6 && days > accum) {
    accum = days
  }
  return accum
}

function initialState() {
  return {
    combinedBalances: [],
    copientBalancesRaw: [],
    copientBalancesById: {},
    excentusBalances: {},
    initialized: false,
    balancesError: {},
    preferenceError: false,
    excentusError: false,
    copientError: false,
    currentStoreNumber: null,
    // Prevent double calls and race conditions
    fetchingData: false,
    fetchingPreference: false,
  }
}

function daysTillExpiration(date) {
  const endDate = window.LuxonDateTime.fromISO(date).endOf('day')
  const currentDate = window.LuxonDateTime.local()
  const daysApart = endDate.diff(currentDate, ['days', 'hours']).toObject()
  return daysApart.days
}

export default {
  namespaced: true,
  state: {
    combinedBalances: [],
    copientBalancesRaw: [],
    copientBalancesById: {},
    excentusBalances: {},
    initialized: false,
    balancesError: {},
    rewardsPreference: null,
    currentStoreNumber: null,
    preferenceError: false,
    excentusError: false,
    copientError: false,
    // Prevent double calls and race conditions
    fetchingData: false,
    fetchingPreference: false,
  },
  getters: {
    // eslint-disable-next-line  max-params
    currentRewardsBalance: (state, getters, rootState, rootGetters) => {
      const rewardsId = rootGetters['SiteConfig/varByName']('brand_rewards_points_id')
      const rewardsProgram = state.copientBalancesById[rewardsId]
      if (state.balancesError.copientError) {
        return -1
      }
      return rewardsProgram ? rewardsProgram.balance : -1
    },
    // eslint-disable-next-line  max-params
    currentQuarterRewardsBalance: (state, getters, rootState, rootGetters) => {
      const rewardsId = rootGetters['SiteConfig/varByName']('brand_rewards_points_current_quarter_id')
      const rewardsProgram = state.copientBalancesById[rewardsId]
      if (state.balancesError.copientError) {
        return -1
      }
      return rewardsProgram ? rewardsProgram.balance : -1
    },
    // eslint-disable-next-line  max-params
    previousQuarterRewardsBalance: (state, getters, rootState, rootGetters) => {
      const rewardsId = rootGetters['SiteConfig/varByName']('brand_rewards_points_previous_quarter_id')
      const rewardsProgram = state.copientBalancesById[rewardsId]
      if (state.balancesError.copientError) {
        return -1
      }
      return rewardsProgram ? rewardsProgram.balance : -1
    },
    // eslint-disable-next-line  max-params
    previousQuarterRewardsBalanceActivated: (state, getters, rootState, rootGetters) => {
      const rewardsId = rootGetters['SiteConfig/varByName']('brand_rewards_points_previous_quarter_id')
      const rewardsProgram = state.copientBalancesById[rewardsId]
      if (state.balancesError.copientError) {
        return -1
      }
      return rewardsProgram ? rewardsProgram.activated : false
    },
    // eslint-disable-next-line  max-params
    currentGroceryBalance: (state, getters, rootState, rootGetters) => {
      const groceryId = rootGetters['SiteConfig/varByName']('brand_rewards_grocery_id')
      const groceryProgram = state.copientBalancesById[groceryId]
      if (state.balancesError.copientError) {
        return -1
      }
      return groceryProgram ? groceryProgram.balance / 100 : -1
    },
    // eslint-disable-next-line  max-params
    currentGasBalance: (state, getters, rootState, rootGetters) => {
      const { opco } = rootState.SiteConfig
      if (opco === 'STSH' || opco === 'GNTL') {
        if (state.balancesError.excentusError) {
          return -1
        }

        return state.excentusBalances.calculatedRate !== undefined ? state.excentusBalances.calculatedRate
          : -1
      }
      const rewardsId = rootGetters['SiteConfig/varByName']('brand_rewards_points_id')
      const rewardsProgram = state.copientBalancesById[rewardsId]

      if (state.balancesError.copientError) {
        return -1
      }

      return rewardsProgram ? Math.floor(rewardsProgram.balance / 100) / 10 : -1
    },
    // eslint-disable-next-line  max-params
    expiringRewardsPoints: (state, getters, rootState, rootGetters) => {
      const rewardsId = rootGetters['SiteConfig/varByName']('brand_rewards_points_id')
      const rewardsProgram = state.copientBalancesById[rewardsId]
      if (state.balancesError.copientError) {
        return 0
      }

      if (!(rewardsProgram?.detail?.gasPoints.length)) {
        return 0
      }

      return rewardsProgram.detail.gasPoints.reduce(expiringPointsReducer, 0)
    },
    // eslint-disable-next-line  max-params
    expiringGroceryPoints: (state, getters, rootState, rootGetters) => {
      const groceryId = rootGetters['SiteConfig/varByName']('brand_rewards_grocery_id')
      const groceryProgram = state.copientBalancesById[groceryId]
      if (state.balancesError.copientError) {
        return 0
      }

      if (!(groceryProgram?.detail?.gasPoints.length)) {
        return 0
      }

      return groceryProgram.detail.gasPoints.reduce(
        expiringPointsReducer, 0
      )
    },
    // eslint-disable-next-line  max-params
    expiringGasPoints: (state, getters, rootState, rootGetters) => {
      if (rootGetters['SiteConfig/varByName']('feature_rewards_zone_fuel')) {
        if (state.balancesError.excentusError || !(state.excentusBalances?.gasPoints?.length)) {
          return 0
        }

        return state.excentusBalances.gasPoints.reduce(
          (accum, entry) => {
            if (daysTillExpiration(entry.expirationDate) < 6) {
              if (entry.rewardType === 'CPG') {
                accum += entry.balanceToExpire * 1000
              } else {
                accum += entry.balanceToExpire
              }
            }
            return accum
          }, 0
        )
      }
      return getters.expiringRewardsPoints
    },
    // Returns in days the furthest expiration date within 6 days
    // eslint-disable-next-line  max-params
    expiringRewardsDays: (state, getters, rootState, rootGetters) => {
      const rewardsId = rootGetters['SiteConfig/varByName']('brand_rewards_points_id')
      const rewardsProgram = state.copientBalancesById[rewardsId]
      if (state.balancesError.copientError) {
        return -1
      }

      if (!(rewardsProgram?.detail?.gasPoints.length)) {
        return -1
      }

      return rewardsProgram.detail.gasPoints.reduce(expiringDaysReducer, -1)
    },
    // Returns in days the furthest expiration date within 6 days
    // eslint-disable-next-line  max-params
    expiringGroceryDays: (state, getters, rootState, rootGetters) => {
      const groceryId = rootGetters['SiteConfig/varByName']('brand_rewards_grocery_id')
      const groceryProgram = state.copientBalancesById[groceryId]
      if (state.balancesError.copientError) {
        return -1
      }

      if (!(groceryProgram?.detail?.gasPoints.length)) {
        return -1
      }

      return groceryProgram.detail.gasPoints.reduce(
        expiringDaysReducer, -1
      )
    },
    // Returns in days the furthest expiration date within 6 days
    // eslint-disable-next-line  max-params
    expiringGasDays: (state, getters, rootState, rootGetters) => {
      if (rootGetters['SiteConfig/varByName']('feature_rewards_zone_fuel')) {
        if (state.balancesError.excentusError || !(state.excentusBalances?.gasPoints.length)) {
          return -1
        }

        return state.excentusBalances.gasPoints.reduce(
          expiringDaysReducer, -1
        )
      }
      return getters.expiringRewardsDays
    },
    copientBalancesById: state => id => state.copientBalancesById[id]
  },
  mutations: {
    setCopientBalances(state, payload) {
      const balances = payload.reduce(
        (obj, entry) => {
          obj[entry.id] = ({
            name: entry.name,
            balance: entry.balance
          })
          if (entry.detail) {
            obj[entry.id].detail = entry.detail
          }
          if (entry.activated !== undefined) {
            obj[entry.id].activated = entry.activated
          }
          return obj
        }, {}
      )
      state.copientBalancesById = { ...state.copientBalancesById, ...balances }
      state.copientBalancesRaw = payload
    },
    setExcentusBalances(state, payload) {
      state.excentusBalances = payload
    },
    setCombinedBalances(state, payload) {
      state.combinedBalances = payload
    },
    updateCombinedBalance(state, payload) {
      const programIndex = state.combinedBalances.findIndex(program => program.name === payload.name)
      if (programIndex !== -1) {
        state.combinedBalances.splice(programIndex, 1, { ...state.combinedBalances[programIndex], ...payload })
      } else {
        state.combinedBalances.push(payload)
      }
    },
    setCalculatedRate(state, payload) {
      state.calculatedRate = payload
    },
    setInitialized(state, payload) {
      state.initialized = payload
    },
    setError(state, payload) {
      state.balancesError[payload.name] = payload.hasError
      state[payload.name] = payload.hasError
    },
    setRewardsPreference(state, payload) {
      state.rewardsPreference = payload
    },
    setCurrentStoreNumber(state, payload) {
      state.currentStoreNumber = payload
    },
    setFetchingData(state, payload) {
      state.fetchingData = payload
    },
    setFetchingPreference(state, payload) {
      state.fetchingPreference = payload
    },
    reset(state) {
      Object.assign(state, initialState())
    }
  },
  actions: {
    async getCopientBalances({ commit, rootState, rootGetters }) {
      // Check user profile for physical store number
      const cardNumber = rootGetters['UserProfile/retailerCardNumber']
      let { storeNumber } = rootGetters['UserProfile/deliveryServiceLocation']
      const userId = rootGetters['UserProfile/userId']
      // If not, check the preferred in store location
      if (!storeNumber) {
        const pref = await UserPreferencesAPI.get(
          USER_PREFERENCE_KEYS.PREFERRED_IN_STORE, userId
        )

        if (pref.status === 200 && pref.data.response.preference.value !== '') {
          const storeData = await ServiceLocationsAPI.lookupById(
            pref.data.response.preference.value
          )
          if (storeData.status === 200 && storeData.data.response.locations.length > 0) {
            storeNumber = storeData.data.response.locations[0].location.locationNumber
            storeNumber = (`0000${storeNumber}`).slice(-4)
          } else {
            storeNumber = null
          }
        } else {
          storeNumber = null
        }
      }

      // If not, use the model store
      if (!storeNumber) {
        const modelServiceLocationId = rootGetters['SiteConfig/varByName']('domain_config_model_service_location_id')
        const storeData = await ServiceLocationsAPI.lookupById(
          modelServiceLocationId
        )
        storeNumber = storeData.data.response.locations[0].location.locationNumber
        storeNumber = (`0000${storeNumber}`).slice(-4)
      }

      commit('setCurrentStoreNumber', storeNumber)

      try {
        const { opco } = rootState.SiteConfig
        const response = opco === 'HNFD'
          ? await LoyaltyProgramsAPI.getCopientBalancesV2(cardNumber, opco)
          : await LoyaltyProgramsAPI.getCopientBalances(cardNumber, storeNumber)
        commit('setCopientBalances', response.data.balances)
      } catch (e) {
        commit('setError', {
          name: 'copientError',
          hasError: true
        })
        commit('setCopientBalances', [])
      }
    },

    async getRewardsPreference({
      state, commit, rootState, rootGetters
    }) {
      if (state.fetchingPreference || state.rewardsPreference !== null) {
        return
      }

      commit('setFetchingPreference', true)
      const cardNumber = rootGetters['UserProfile/retailerCardNumber']
      const { opco } = rootState.SiteConfig

      // Only STSH and GNTL can have fuel user states
      if (!rootGetters['SiteConfig/varByName']('feature_rewards_zone_fuel')) {
        commit('setRewardsPreference', 'flex')
        commit('setFetchingPreference', false)
        return
      }

      try {
        const response = await RewardsPreferenceAPI.get(opco, cardNumber)
        commit('setRewardsPreference', response.data.value)
      } catch (e) {
        commit('setError', {
          name: 'preferenceError',
          hasError: true
        })
        commit('setRewardsPreference', null)
      } finally {
        commit('setFetchingPreference', false)
      }
    },

    async setRewardsPreferenceToFlex({ commit, rootState, rootGetters }) {
      const cardNumber = rootGetters['UserProfile/retailerCardNumber']
      const { opco } = rootState.SiteConfig

      const response = await RewardsPreferenceAPI.update(opco, cardNumber, 'flex')
      commit('setRewardsPreference', 'flex')

      const rewardsProgram = {
        name: 'Rewards',
        selected: true
      }
      commit('updateCombinedBalance', rewardsProgram)
      commit('setFetchingPreference', false)

      /* This response status is successful (200) but needs to result in a specific sad path */
      if (response.data.status.code === '-2') {
        throw new Error('UpgradeError')
      }
    },

    async getExcentusBalances({ state, commit, rootGetters }) {
      // Use storeNumber stored from previous action
      const cardNumber = rootGetters['UserProfile/retailerCardNumber']
      const storeNumber = state.currentStoreNumber

      try {
        const response = await LoyaltyProgramsAPI.getExcentusBalance(cardNumber, storeNumber)
        commit('setExcentusBalances', response.data)
      } catch (e) {
        commit('setError', {
          name: 'excentusError',
          hasError: true
        })
        commit('setExcentusBalances', {})
      }
    },

    async unifyBalances({
      state, commit, rootState, rootGetters
    }) {
      // Whitelist of balances primarily used in my account section
      const { opco } = rootState.SiteConfig
      const varByName = rootGetters['SiteConfig/varByName']
      const rewardsProgram = state.copientBalancesById[varByName('brand_rewards_points_id')]
      const groceryProgram = state.copientBalancesById[varByName('brand_rewards_grocery_id')]
      const gasProgram = (opco === 'STSH' || opco === 'GNTL')
        ? state.excentusBalances
        : rewardsProgram
      const rewardsPreference = varByName('feature_rewards_zone_fuel') ? state.rewardsPreference : 'flex'

      const comboBal = [
        {
          name: 'Rewards',
          balance: rewardsProgram ? rewardsProgram.balance : 0,
          selected: rewardsPreference === 'flex'
        },
        {
          name: 'Gas',
          balance: gasProgram ? gasProgram.calculatedRate || Math.floor(gasProgram.balance / 100) / 10 || 0 : 0,
          selected: true
        },
        {
          name: 'Grocery',
          balance: groceryProgram ? groceryProgram.balance : 0,
          selected: true
        }
      ]
      commit('setCombinedBalances', comboBal)
    },

    async getRewardsAndPrograms({
      state, commit, dispatch, rootGetters
    }) {
      if (state.fetchingData) {
        return
      }

      await commit('setFetchingData', true)
      await dispatch('getCopientBalances')
      await dispatch('getRewardsPreference')

      if (rootGetters['SiteConfig/varByName']('feature_rewards_zone_fuel')) {
        await dispatch('getExcentusBalances')
      }

      await dispatch('unifyBalances')
      commit('setInitialized', true)
      commit('setFetchingData', false)
    },
  }
}
