import ApiService from '@/api/'

const buildWeeklyAdRequestData = (parameters) => {
  const {
    start, size, sortSelection, preview, keyword, category, currentlyEffectiveAds
  } = parameters
  const assembledRequest = {
    query: {
      start: start || 0,
      size: size || 60,
      keyword,
      ...(category !== '' ? { categoryIds: [category] } : {})
    },
    preview,
    currentlyEffectiveAds
  }
  if (sortSelection && Object.hasOwn(sortSelection, 'title')) {
    const { value, order = 'ASC' } = sortSelection
    assembledRequest.sorts = [{
      sortType: value,
      sortDirection: order
    }]
  }
  return assembledRequest
}

const buildWeeklyAdCategoryRequestData = (parameters) => {
  const {
    start, categoryIds, sortSelection, preview, keyword, currentlyEffectiveAds, adsSizePerCategory, size, filterOptions
  } = parameters
  return {
    query: {
      cursor: start,
      size,
      adsSizePerCategory,
      keyword,
      categoryIds
    },
    preview,
    currentlyEffectiveAds,
    sorts: sortSelection,
    filter: filterOptions
  }
}

export default {
  async getWeeklyAdPDL(userId, serviceLocationId, parameters = {}) {
    const apiParams = buildWeeklyAdRequestData(parameters)
    const url = `api/v1.0/weekly/circular/users/${userId}/${serviceLocationId}/ads`
    const weeklyAdPDLResponse = await ApiService.post(url, apiParams)
    if (weeklyAdPDLResponse.status === 200) {
      return weeklyAdPDLResponse
    }
    throw weeklyAdPDLResponse
  },
  async getWeeklyAdCategory(userId, serviceLocationId, parameters = {}) {
    const apiParams = buildWeeklyAdCategoryRequestData(parameters)
    const url = `api/v1.0/weekly/circular/users/${userId}/${serviceLocationId}/categorized/ads`
    const weeklyAdPDLResponse = await ApiService.post(url, apiParams)
    if (weeklyAdPDLResponse.status === 200) {
      return weeklyAdPDLResponse
    }
    throw weeklyAdPDLResponse
  }
}
