import generateUID from 'utils/generateUID'
import NativeLogin from './NativeLogin'

export default class NativeContainer {
  MAX_INTERVAL_CHECK = 6

  timeout = 100

  initChecksMade = 0

  readyChecks = 0

  capabilities = null

  isNativeContainerReady = false

  callbacks = {}

  userAgentRegex = /(App\/PNA)$/

  sendMessage = inputParams => new Promise((resolve, reject) => {
    if (typeof inputParams === 'undefined') {
      reject(new Error('Invalid Input Params'))
    }
    // payload structure for communicating
    const messageObject = {
      msgId: generateUID(),
      capability: inputParams.capability || '',
      method: inputParams.method || '',
      data: inputParams.data || [],
    }

    if (!messageObject.capability || !messageObject.method) {
      reject(new Error('Invalid Capability or Method name'))
    }

    // mapping the callback functions for the same promise
    this.callbacks[messageObject.msgId] = {
      onsuccess: resolve,
      onerror: reject
    }

    // triggering the post message function to Native Container
    window.ReactNativeWebView.postMessage(JSON.stringify(messageObject))
  })

  receiveMessage = (event) => {
    // check for origin and return is not known
    if (!event.origin || event.origin !== `${window.location.protocol}//${window.location.hostname}`) {
      return
    }

    let message
    try {
      message = event.data
    } catch (err) {
      return
    }
    // trigger callback
    if (message.args && this.callbacks[message.msgId]) {
      if (message.success) {
        this.callbacks[message.msgId].onsuccess.apply(null, message.args)
      } else {
        this.callbacks[message.msgId].onerror.apply(null, message.args)
      }
      delete this.callbacks[message.msgId]
    }
  }

  init = async () => {
    // add event listeners for the communication back from react native
    document.addEventListener('message', this.receiveMessage) // Android only
    window.addEventListener('message', this.receiveMessage) // iOS only
    const sendMessage = this.sendMessage.bind(this)
    // function to retrieve all the caps and generate a wrapper for all the caps
    const capabilities = await sendMessage({ capability: 'GetCapabilities', method: 'getAllCapabilities' })
    const capabilityKeys = Object.keys(capabilities)
    capabilityKeys.forEach((capability) => {
      capabilities[capability].methods.forEach((method) => {
        capabilities[capability][method] = (...args) => {
          const messageObject = {
            capability: capabilities[capability].name,
            method,
            data: args
          }
          return sendMessage(messageObject)
        }
      }) // end
    }) // end

    this.timeout = 100
    this.initChecksMade = 0
    this.capabilities = capabilities
    this.capabilities.NativeLogin = new NativeLogin()
    window.sharedVue.config.globalProperties.$store.commit('NativeContainer/setMobileApp', true)
    this.isNativeContainerReady = true

    // start to listen for Android Back Button Event
    window.addEventListener('BackButtonEvent', () => {
      window.sharedVue.config.globalProperties.$store.dispatch('NativeContainer/handleBackHandler')
    })

    window.addEventListener('OrderStatusEvent', () => {
      window.sharedVue.config.globalProperties.$store.dispatch('NativeContainer/handleOrderStatusUpdate')
    })
  }

  initContainer = () => {
    setTimeout(() => {
      if (typeof window.ReactNativeWebView === 'object') {
        this.init()
      } else if (this.userAgentRegex.test(navigator.userAgent) || sessionStorage.getItem('nativeApp')) {
        this.timeout = 100
        this.initChecksMade = 0
        window.sharedVue.config.globalProperties.$store.commit('NativeContainer/setNativeApp', true)
        this.isNativeContainerReady = true
      } else if (this.initChecksMade < this.MAX_INTERVAL_CHECK) {
        this.timeout += 100
        this.initChecksMade += 1
        this.initContainer()
      } else {
        this.isNativeContainerReady = true
      }
    }, this.timeout)
  }

  checkNativeContainer = (promise) => {
    setTimeout(() => {
      if (!this.isNativeContainerReady && this.readyChecks < 15) {
        this.readyChecks += 1
        this.checkNativeContainer(promise)
      } else {
        this.readyChecks = 0
        promise.resolve(window.sharedVue.config.globalProperties.$store.getters['NativeContainer/isMobileApp'])
      }
    }, 100)
  }

  isEnabledPromise = () => new Promise((resolve, reject) => {
    this.checkNativeContainer({ resolve, reject })
  })
}
