<template>
  <div
    id="FeedbackView"
  >
    <div
      class="relative flex flex-col w-full justify-center items-start p-4 lg:pb-20 lg:flex-row"
    >
      <div class="p-4 w-full lg:w-1/2 border-b border-solid border-gray-700 lg:border-r lg:border-b-0">
        <div class="w-full lg:w-[90%]">
          <div class="font-bold pb-4 pl-2">
            Need a response? Contact us for help.
          </div>
          <div class="py-0 px-4 min-h-[12rem]">
            <ul class="p-2">
              <li class="ml-8 list-disc">
                Report issues with your pickup or delivery.
              </li>
              <li class="ml-8 list-disc">
                Request a refund for missing/damaged products.
              </li>
              <li class="ml-8 list-disc">
                Get help updating payment information.
              </li>
              <li class="ml-8 list-disc">
                Report issues or ask questions about our stores.
              </li>
            </ul>
          </div>
          <div class="w-full my-0 mx-auto py-12 px-4">
            <div class="pb-4">
              <button
                id="Feedback-ContactTrigger"
                class="button button--third button-width--full"
                @click="goToContact"
              >
                {{ contactButtonText }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="hidden font-bold mt-60 -ml-9 p-4 bg-white lg:block">
        OR
      </div>
      <div class="p-4 w-full lg:w-1/2">
        <div class="w-full lg:w-[90%]">
          <div class="font-bold pb-4 pl-2">
            Give us feedback or suggestions.
          </div>
          <div class="py-0 px-4 min-h-[12rem]">
            Your thoughts and comments help us improve your shopping experience.
            <ul class="p-2">
              <li class="ml-8 list-disc">
                Give feedback on our website and app.
              </li>
              <li class="ml-8 list-disc">
                Report technical glitches or bugs.
              </li>
              <li class="ml-8 list-disc">
                Make suggestions on how to improve.
              </li>
            </ul>
          </div>
          <div class="w-full my-0 mx-auto py-12 px-4">
            <div
              class="pb-4"
            >
              <button
                id="Feedback-GiveFeedbackTrigger"
                class="button button--third button-width--full"
                @click="openFeedbackWindow"
              >
                {{ feedbackButtonText }}
              </button>
            </div>
            <p class="text-center text-sm italic">
              We are unable to respond to these comments.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GTM_GA4 } from 'utils/constants'

export default {
  props: {
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      contactButtonText: 'Contact Us',
      feedbackButtonText: 'Give Website/App Feedback'
    }
  },
  methods: {
    goToContact() {
      this.$emit('contact-triggered')
      const destination = '/contact'
      this.$router.push(destination)
      this.trackContactClick(destination)
    },
    openFeedbackWindow() {
      this.$emit('contact-triggered')
      const destination = '/contact/feedback/give-feedback'
      this.$router.push(destination)
      this.trackFeedbackClick(destination)
    },
    trackContactClick(destination) {
      this.$trackGA4Event(GTM_GA4.click, {
        site_location: 'Contact Us - Give Us Feedback',
        link_text: this.contactButtonText,
        link_url: destination,
        type: 'contact us'
      })
    },
    trackFeedbackClick(destination) {
      this.$trackGA4Event(GTM_GA4.click, {
        site_location: 'Contact Us - Give Us Feedback',
        link_text: this.feedbackButtonText,
        link_url: destination,
        type: 'contact us'
      })
    },
  }
}
</script>
