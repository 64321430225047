<template>
  <div class="form_container px-4">
    <div v-if="formLoading">
      <div class="loading leader--double">
        <div class="loading-spinner-block">
          <PdlLoadingSpinner />
        </div>
      </div>
    </div>
    <div v-else-if="contactUsStatus">
      <ContactConfirm :name="form.name" />
    </div>
    <form
      v-else
      name="contactUs"
      aria-description="Account contact form"
      novalidate
      @submit.prevent="handleSubmit"
    >
      <PdlSelect
        id="contact-us_subject"
        v-model="v$.form.subjectInput.$model"
        :error="v$.form.subjectInput.$error"
        :options="subjects"
        field-label="Choose a topic"
        class="forms_input-container"
        full-width
        no-error-padding
        :is-required="true"
        medium-caret
      >
        <template #errors>
          <span v-if="v$.form.subjectInput.$error">{{ subjectMessage.required }}</span>
        </template>
      </PdlSelect>
      <PdlSelect
        v-if="(form.subjectInput.additionalData
          && form.subjectInput.additionalData.orderRequired)"
        id="contact-us_orderId"
        v-model="v$.form.orderId.$model"
        :error="v$.form.orderId.$error"
        :options="orders"
        field-label="Order Date"
        wrapper-class="forms_input-container"
        full-width
        no-error-padding
        medium-caret
      >
        <template #errors>
          <span v-if="v$.form.orderId.$error">{{ genericMesssage.required }}</span>
        </template>
      </PdlSelect>
      <div v-if="anyContactFieldsDisplayed">
        <p class="mt-12 mb-6 border-b border-solid border-gray-100" />

        <div class="mt-12 mb-7 font-semibold">
          Enter Your Contact Information
        </div>
      </div>

      <PdlBasicTextInput
        v-if="shouldDisplay(form.email, v$.form.email.$dirty)"
        id="contact-us_email"
        v-model="v$.form.email.$model"
        :error="v$.form.email.$error"
        field-name="email"
        input-label="Email"
        is-required
        :is-mobile-device="isMobileDevice"
      >
        <template #errors>
          <div v-for="error of v$.form.email.$errors"
:key="error.$uid">
            <span v-if="error.$validator === 'required'">{{ emailMessage.required }}</span>
            <span v-else-if="error.$validator === 'format'">{{ emailMessage.format }}</span>
          </div>
        </template>
      </PdlBasicTextInput>
      <PdlBasicTextInput
        v-if="shouldDisplay(form.email, v$.form.confirmEmail.$dirty)"
        id="contact-us_confirmEmail"
        v-model="v$.form.confirmEmail.$model"
        :error="v$.form.confirmEmail.$error"
        field-name="confirmEmail"
        input-label="Confirm Email"
        is-required
        :is-mobile-device="isMobileDevice"
      >
        <template #errors>
          <div v-for="error of v$.form.confirmEmail.$errors"
:key="error.$uid">
            <span v-if="error.$validator === 'required'">{{ emailMessage.required }}</span>
            <span v-else-if="error.$validator === 'format'">{{ emailMessage.format }}</span>
            <span v-else-if="error.$validator === 'sameAsEmail'">{{ emailMessage.match }}</span>
          </div>
        </template>
      </PdlBasicTextInput>
      <PdlBasicTextInput
        v-if="shouldDisplay(form.name, v$.form.name.$dirty)"
        id="contact-us_name"
        v-model="v$.form.name.$model"
        :error="v$.form.name.$error"
        field-name="name"
        input-label="Name (optional)"
        :is-mobile-device="isMobileDevice"
      />
      <PdlBasicTextInput
        v-if="shouldDisplay(form.phoneInput, v$.form.phoneInput.$dirty)"
        id="contact-us_phone"
        v-model="v$.form.phoneInput.$model"
        :error="v$.form.phoneInput.$error"
        field-name="phone"
        input-label="Contact Phone (optional)"
        :is-mobile-device="isMobileDevice"
      >
        <template #errors>
          <div v-for="error of v$.form.phoneInput.$errors"
:key="error.$uid">
            <span v-if="error.$validator === 'format'">{{ phoneMessage.format }}</span>
          </div>
        </template>
      </PdlBasicTextInput>

      <p class="mt-12 mb-6 border-b border-solid border-gray-100" />

      <PdlBasicTextArea
        id="contact-us_body"
        v-model="v$.form.body.$model"
        :error="v$.form.body.$error"
        field-name="body"
        input-label="Comments"
        instant-update
        has-description
        :is-required="true"
        @input="updateCharacterCount"
      >
        <template #errors>
          <div v-for="error of v$.form.body.$errors"
:key="error.$uid">
            <span v-if="error.$validator === 'required'">{{ commentMessage.required }}</span>
            <span v-else-if="error.$validator === 'maxLength'">{{ commentMessage.maxLength(maxCommentLength) }}</span>
          </div>
        </template>
        <template #description>
          <span>{{ charactersLeft }} characters left</span>
        </template>
      </PdlBasicTextArea>

      <div class="text--align-center">
        <PdlLoadingButton
          id="contact-us_submit"
          label="Submit"
          button-color="third"
          :classes="[
            isMobile ? 'button-width--full' : 'button-width--med'
          ]"
          :is-loading="submitLoading"
          :is-disabled="v$.form.$invalid"
          :ignore-prevent="true"
        />
        <p
          v-if="displayError && !contactUsStatus"
          aria-live="polite"
          class="forms_confirmation text--base-strong text--base-error"
        >
          There was an error loading this feature.<br>
          Please try again, or for immediate assistance call
          <a
            class="link"
            :href="`tel://+${getBrandHelpPhoneNumber}`"
          >{{ getBrandHelpPhoneNumber }}</a>
        </p>
      </div>
    </form>
  </div>
</template>

<script>
import {
  required,
  requiredIf,
  email,
  maxLength,
  sameAs,
  helpers
} from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { mapState, mapGetters, mapActions } from 'vuex'
import recaptchaLoaderService from 'utils/services/script-loader/recaptcha-loader'
import { trackHelpSubmit } from 'utils/tracking/help/trackHelpSubmit'
import formatDateParams, { longWeekMonthDay } from 'utils/filters/formatDateParams'
import { GTM_GA4 } from 'utils/constants'
import ContactConfirm from './ContactConfirm'

export default {
  components: {
    ContactConfirm
  },
  data: () => ({
    form: {
      email: '',
      confirmEmail: '',
      name: '',
      phoneInput: '',
      subjectInput: 0,
      orderId: '',
      body: '',
    },
    commentLength: 0,
    maxCommentLength: 500,
    submitLoading: false,
    formLoading: true,
    anyContactFieldsDisplayed: false
  }),
  computed: {
    ...mapState({
      contactUsStatus: state => state.ContactUs.contactUsStatus,
      displayError: state => state.ContactUs.displayError,
      emailSubjects: state => state.ContactUs.emailSubjects,
      userOrders: state => state.ContactUs.userOrders,
    }),
    ...mapGetters({
      deliveryPhone: 'UserProfile/deliveryPhone',
      getBrandHelpPhoneNumber: 'SiteConfig/brandContactNumber',
      isMobile: 'ScreenSize/isMobile',
      isMobileDevice: 'ScreenSize/isMobileDevice',
      loggedIn: 'LoginStatus/isLoggedIn',
      messages: 'FormMessages/messages',
      opco: 'SiteConfig/opco',
      userFirstName: 'UserProfile/displayFirstName',
      userId: 'UserProfile/userId',
      userInfoData: 'UserProfile/userInfo',
      userLastName: 'UserProfile/displayLastName',
    }),
    commentMessage() {
      return this.messages('fields.comments')
    },
    subjectMessage() {
      return this.messages('fields.emailSubject')
    },
    genericMesssage() {
      return this.messages('fields.generic')
    },
    charactersLeft() {
      const charactersLeft = this.maxCommentLength - this.form.body.length
      return charactersLeft > 0 ? charactersLeft : 0
    },
    selectedSubject() {
      return this.subjects[this.form.subjectInput]
    },
    emailMessage() {
      return this.messages('fields.email')
    },
    phoneMessage() {
      return this.messages('fields.phone')
    },
    subjects() {
      const { emailSubjects } = this
      const subjects = emailSubjects.filter(subj => (subj.orderRequired === false))
      subjects.unshift({ title: '' })
      return subjects.map((subj, index) => ({ ...subj, value: index }))
    },
    orders() {
      return [
        { title: '', value: '' },
        ...this.userOrders.map(order => ({
          title: formatDateParams(order.orderDate, longWeekMonthDay),
          value: order.orderId
        }))
      ]
    }
  },
  setup: () => ({ v$: useVuelidate() }),
  async beforeMount() {
    recaptchaLoaderService.load()

    // reset the vuex store (since we use it in multiple screens)
    this.resetState()

    // save the user's info to the state
    if (this.loggedIn) {
      await this.getUserInfo()
    }

    // get the data we need to display the form
    await this.getSubjectsAndOrders(this.userId)

    this.formLoading = false
  },
  methods: {
    ...mapActions({
      getSubjectsAndOrders: 'ContactUs/getSubjectsAndOrders',
      postAccountContact: 'ContactUs/postAccountContact',
      resetState: 'ContactUs/resetState'
    }),
    updateCharacterCount(value) {
      this.commentLength = value.length
    },
    mapFormPayload() {
      const {
        name,
        phoneInput,
        body,
      } = this.form || {}

      const formEmail = this.form?.email || '' // 'email' was already declared above

      const subject = this.selectedSubject?.code || 'S-011'

      const payload = {
        email: formEmail, // --Required
        name,
        opco: this.opco,
        subject,
        userId: this.userId, // -- Required
        body
      }

      if (phoneInput) payload.phone = phoneInput

      return payload
    },
    async handleSubmit() {
      this.submitLoading = true
      const payload = this.mapFormPayload()
      const contactResponse = await this.postAccountContact({ userId: this.userId, payload })
      this.submitLoading = false
      this.logAnalytics()
      this.$trackGA4Event(GTM_GA4.click, {
        action: 'help center contact us form submit',
        result: this.selectedSubject?.title,
        link_text: 'Submit',
        link_id: 'contact-us_submit',
        type: 'help center',
        site_location: 'Help Center - Contact Us',
      })
      return contactResponse
    },
    getUserInfo() {
      const userInfo = this.userInfoData?.information || {}
      // copy the user's account info into the state
      this.form.name = `${this.userFirstName} ${this.userLastName}`
      this.form.email = userInfo.email
      this.form.confirmEmail = this.form.email
      this.form.phoneInput = this.deliveryPhone
    },
    shouldDisplay(field, isDirty) {
      // If the user made changes to the field
      if (isDirty) {
        this.anyContactFieldsDisplayed = true
        return true
      }

      // If the user is not logged in
      if (!this.loggedIn) {
        this.anyContactFieldsDisplayed = true
        return true
      }

      // if the provided field is undefined or empty
      // remove spaces to account for the space we added in getUserInfo
      if (!field || (field.replace(/ /g, '') === '')) {
        this.anyContactFieldsDisplayed = true
        return true
      }

      return false
    },
    logAnalytics() {
      trackHelpSubmit({
        userLoggedIn: this.loggedIn,
        emailSubject: this.form.subjectInput.title,
        status: this.contactUsStatus
      })
    },
    handleShopClick() {
      this.$store.commit('Modals/clearActiveModal')
      this.$router.push('/home')
    },
    handleLoginClick() {
      this.$emit('login-click')
    }
  },
  validations() {
    return ({
      form: {
        email: {
          required,
          email
        },
        confirmEmail: {
          required,
          email,
          sameAsEmail: sameAs(this.form.email)
        },
        name: { },
        phoneInput: {
          format(phone) {
            return (
              /^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/.test(
                phone
              ) || !helpers.req(phone)
            )
          },
        },
        subjectInput: {
          required
        },
        body: {
          required,
          maxLength: maxLength(this.maxCommentLength)
        },
        orderId: {
          required: requiredIf(() => this.form.subjectInput.additionalData?.orderRequired)
        }
      }
    })
  }
}
</script>
