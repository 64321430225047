function initialState() {
  return {
    selectedTab: {},
    lastTab: null,
    tabs: []
  }
}
export default {
  namespaced: true,
  state: initialState,
  mutations: {
    setTab(state, payload) {
      state.tabs = payload
    },
    setSelectedTab(state, payload) {
      const { tabs } = state
      tabs.forEach((tab) => {
        tab.isActive = (tab.name === payload.name)
        if (tab.name === payload.name) {
          state.selectedTab = tab
        }
      })
    },
    backToPreviousTab(state) {
      const previousTab = state.lastTab ? state.lastTab : state.tabs[0]

      const { tabs } = state
      tabs.forEach((tab) => {
        tab.isActive = (tab.name === previousTab.name)
        if (tab.name === previousTab.name) {
          state.selectedTab = tab
          state.lastTab = null
        }
      })
    },
    tabChangeListener(state, path) {
      state.tabs.forEach((item) => {
        const subpaths = path.split('/')
        if ((path === '/home' || path === '/') && item.name === 'Home') {
          item.isActive = true
          state.lastTab = item
          return
        }
        if ((subpaths.length > 1 && subpaths[1].includes(item.url.split('/')[1])) && item.name !== 'Home') {
          // item.name !== 'Home' check is made to avoid the Home Tab to reamin active
          item.isActive = true
          state.lastTab = item
          return
        }
        item.isActive = false
      })
    }
  }
}
