<template>
  <div
    class="spyglass_container spyglass_container--nav-group-type z-[9999]"
  >
    <ul>
      <PdlSpyglassItemRow
        v-for="(datapoint, key) in content.parent"
        :key="key"
        :spyglass-row="datapoint"
      />
    </ul>
    <div
      v-if="hasSubTasks"
    >
      <h3>Content Modules:</h3>
      <ul>
        <li
          v-for="(datapoint, key) in content.children"
          :key="key"
        >
          <ul>
            <PdlSpyglassItemRow
              v-for="(item, i) in datapoint"
              :key="i"
              :spyglass-row="item"
            />
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import PdlSpyglassItemRow from 'components/spyglass/PdlSpyglassItemRow'

export default {
  components: {
    PdlSpyglassItemRow
  },
  props: {
    content: {
      type: Object,
      default: () => ({
        parents: [],
        children: [],
      })
    }
  },
  computed: {
    hasSubTasks() {
      if (this.content.children) {
        return this.content.children.length
      }
      return false
    }
  }
}
</script>
