/*
 * Can be passed to array.sort() to sort alphabetically by given prop value
 * @param prop - the property to use for comparisons
 */
export default function (property) {
  return (itemA, itemB) => {
    if (itemA[property] === itemB[property]) { return 0 }
    return itemA[property] < itemB[property] ? -1 : 1
  }
}
