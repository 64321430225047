<template>
  <button
    class="btn btn--primary cart-btn"
    :class="{
      'btn--small' : isSmall,
      'cart-btn_expanded-width' : expandedWidthCartButton
    }"
    :aria-label="ariaLabelText"
    @click="cartButtonClicked"
    @focusin="emitFocusIn($event)"
  >
    <div
      class="cart-button-content"
    >
      <template v-if="shouldShowShopOnlineText">
        Shop Online
      </template>
      <template v-if="!shouldShowShopOnlineText">
        <div
          v-if="shouldShowPrice"
          class="cart-button-content_text"
        >
          {{ formatCurrency(cartAmount) }}
        </div>
        <PdlIconBase
          icon-title="cart"
          container-class="shrink-0 h-7 w-8 self-baseline"
          icon-color="white"
        >
          <PdlIconCart />
        </PdlIconBase>
      </template>
    </div>
  </button>
</template>

<script>
import { mapGetters } from 'vuex'
import formatCurrency from 'utils/filters/formatCurrency'
import PdlIconCart from 'components/icons/icon/PdlIconCart'
import { currentStoreIsOnSpectrum, openUnataFromAppToBrowser, openUnataFromWebToBrowser } from 'utils/StoreTransition'
import { ORDER_SERVICE_TYPES, SERVICE_TYPE_TEXT } from 'utils/constants'
import optimizelyFullstack from 'components/user-research/services/optimizely-fullstack-service'
import {
  popRedirectAlertBeforeEcomm, shouldForceRegistration
} from 'components/registration/helpers/EcommRedirectHelpers'

export default {
  components: {
    PdlIconCart
  },
  props: {
    iconColor: {
      type: String,
      default: 'white'
    },
    isSmall: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      cartAmount: 'Cart/getSubTotal',
      isMobileApp: 'NativeContainer/isMobileApp',
      opco: 'SiteConfig/opco',
      transitionInProgress: 'FoodLionTransition/transitionInProgress',
      transitionCompleted: 'FoodLionTransition/transitionCompleted',
      storeIsP3: 'FoodLionTransition/storeIsP3',
      deliveryServiceLocation: 'UserProfile/deliveryServiceLocation',
      currentServiceType: 'UserProfile/serviceType',
      unataExperience: 'UserProfile/unataExperience',
      isDesktop: 'ScreenSize/isDesktop',
      isLoggedIn: 'LoginStatus/isLoggedIn',
      isLoyaltyRegistered: 'LoyaltyAccount/isLoyaltyRegistered'
    }),
    shouldShowPrice() {
      return this.isMobileApp ? this.cartAmount > 0 : true
    },
    shouldShowShopOnlineText() {
      return !currentStoreIsOnSpectrum(this.serviceLocationId)
    },
    ariaLabelText() {
      const total = formatCurrency(this.cartAmount)
      return `Cart Subtotal ${total}`
    },
    expandedWidthCartButton() {
      return !this.shouldShowPrice || this.shouldShowShopOnlineText
    },
    serviceLocationId() {
      return this.deliveryServiceLocation?.serviceLocationId
    },
  },
  methods: {
    async cartButtonClicked() {
      const isFDLN = this.opco === 'FDLN'

      if (!this.isDesktop) {
        optimizelyFullstack.trackEvent('Cart')
      }
      this.$emit('track-nav-updated', {
        action: 'utility header',
        url: 'cart',
        label: 'cart'
      })

      // Dont redirect user if they arent fully registered
      // Remove after FDLN is completely Shoppable
      if (isFDLN && shouldForceRegistration() && !(currentStoreIsOnSpectrum(this.serviceLocationId))) {
        popRedirectAlertBeforeEcomm()
        return
      }

      // PRISM InStore ShopperUI
      if (currentStoreIsOnSpectrum(this.serviceLocationId)) {
        this.$router.push('/cart')
        return
      }

      if (this.transitionInProgress) {
        this.$store.commit('Modals/setActiveModal', {
          fileName: 'PdlServiceSelectionModal',
        })
        return
      }

      this.openExternalLink()
    },
    openExternalLink() {
      const { zip, storeNumber } = this.deliveryServiceLocation
      const linkParams = {
        serviceType: SERVICE_TYPE_TEXT[this.currentServiceType],
        zipOrStoreNumber: this.currentServiceType === ORDER_SERVICE_TYPES.DELIVERY ? zip : storeNumber
      }

      return this.isMobileApp ? openUnataFromAppToBrowser(linkParams) : openUnataFromWebToBrowser(linkParams)
    },
    emitFocusIn(event) {
      const previousActiveElement = event?.relatedTarget?.id || ''
      if (previousActiveElement.includes('nav-categories') || previousActiveElement.includes('nav-shopping-list')) {
        this.$emit('focus-in', 'keyboard-navigation')
      } else {
        this.$emit('focus-in')
      }
    },
    formatCurrency(val) {
      return formatCurrency(val)
    }
  }
}
</script>
