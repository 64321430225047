import ObserverFactory from 'components/global/factories/observer-factory'
import { sessionCacheObserverService } from 'components/cache/services/session-cache-observer-service'
import { LOGGING_TYPE } from 'utils/constants'
import getScript from 'utils/services/script-loader/get-script'

/**
 * Example of activating DOM element on activation of an Optimizely experiment variation:
 * 1. Add custom code to Optimizely variation that creates a custom event
 *    -include the name of the experiement namespaced with optimizely on the 'detail' property
 *
 *   (function(document){
 *     var customEvent = new CustomEvent("ppdOptimizely",
 *       { "detail": "optimizelyHomePageProductBrowse" })
 *       document.dispatchEvent(customEvent)
 *       })(document)
 *
 * 2. Register an observer dependency with OptimizelyService from the controller of experimental element.
 *
 *    controller.optimizelyHomePageProductBrowse = false
 *
 *    optimizelyService.observer.addDependent({update: optimizelyUpdate})
 *    function optimizelyUpdate(activeVariation) {
 *        if(activeVariation.value === 'optimizelyHomePageProductBrowse') {
 *            controller.optimizelyHomePageProductBrowse = true
 *        }
 *    }
 *
 * 3. Set experiment name as controller property and set element to show when property is true.
 *    <div ng-if="ctrl.optimizelyHomePageProductBrowse">Experimental Content</div>
 * */
function OptimizelyService() {
  const service = this
  service.observer = ObserverFactory()

  const optimizelyReinitializer = {
    refresh: reinitializeOptimizely
  }

  sessionCacheObserverService.addDependent(optimizelyReinitializer)
  // Reference: https://help.optimizely.com/Build_Campaigns_and_Experiments/Single_Page_Applications_in_Optimizely_X
  service.init = function () {
    if (window?.appConfig?.optimizelyEnabled) {
      service.loadOptimizelyScript()
    }
  }

  service.notifyOfActiveVariation = function (activeVariationName) {
    service.observer.notify(activeVariationName)
  }

  service.activatePage = function (pageName) {
    window.optimizely = window.optimizely || []
    window.optimizely.push({
      type: 'page',
      pageName
    })
  }

  service.deactivatePage = function (pageName) {
    window.optimizely = window.optimizely || []
    window.optimizely.push({
      type: 'page',
      pageName,
      isActive: false
    })
  }

  service.trackEvent = function (eventName, payload) {
    window.optimizely = window.optimizely || []
    window.optimizely.push({
      type: 'event',
      eventName,
      payload
    })
  }

  /* eslint-disable */
  service.trackPage = function (pageName) {
    pageName = pageName.replace(/[/-]/g, '_')
      .toLowerCase()
      .split('?')[0]

    // set to inactive and activate to ensure the page will be re-evaluated when route changes and
    service.deactivatePage(pageName)
    service.activatePage(pageName)

    return pageName
  }
  /* eslint-enable */

  service.loadOptimizelyScript = function () {
    window.optimizely = window.optimizely || []
    window.optimizely.push({
      type: 'addListener',
      filter: {
        type: 'lifecycle',
        name: 'campaignDecided'
      },
      handler(event) {
        logOptimizelyData(event)
      }
    })

    return getScript(window?.appConfig?.optimizelyUrl || '', {
      async: true
    })
      .then(() => {
        return true
      })
  }

  function reinitializeOptimizely() {
    // If window.optimizely is an array instead of an object, optimizely hasn't finished it's initialization
    if (typeof window.optimizely !== 'undefined' && !Array.isArray(window.optimizely)) {
      window.optimizely = []
      service.init()
    }
  }

  function logOptimizelyData(event) {
    if (window.optimizely) {
      const experimentStates = window.optimizely.get('state').getExperimentStates({ isActive: true })
      const { experimentId } = event.data.decision
      if (experimentId in experimentStates && !event.data.decision.isCampaignHoldback) {
        window.sharedVue.config.globalProperties.$trackClientLog({
          type: LOGGING_TYPE.event,
          name: 'optimizely_experiment',
          body: {
            experimentName: experimentStates[experimentId].experimentName,
            experimentId,
            variationName: experimentStates[experimentId].variation.name,
            variationId: experimentStates[experimentId].variation.id
          }
        })
      }
    }
  }
}

export { OptimizelyService }

const optimizelyService = new OptimizelyService()
export default optimizelyService
