export default function (categoryList = []) {
  if (categoryList.length < 4) {
    return categoryList
  }
  // 4th item is always the special seasonal item
  // Need to show in second position
  // Swap with second position
  const categoryItem = categoryList[3]
  categoryList.splice(3, 1)
  return [
    // part of the array before the specified index
    ...categoryList.slice(0, 1),
    // inserted item
    categoryItem,
    // part of the array after the specified index
    ...categoryList.slice(1)
  ]
}
