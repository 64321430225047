<template>
  <PdlNavItemBase
    :id-prefix="idPrefix"
    :list-item-class="listItemClass"
    :button-class="buttonClass"
    :link-class="linkClass"
    :url="url"
    :label="cleanedLabel(label)"
    :link-type="linkType"
    :lowercase-label="false"
    :spyglass-data="spyglassData"
    :content-data="contentData"
    :scheduled-data="scheduledData"
    :is-new-nav-design-enabled="isNewNavDesignEnabled"
    @nav-item-base-clicked="onNavItemClick"
    @focus-out="$emit('focus-out')"
  >
    <div
      v-if="image"
      class="nav-item_image-container"
    >
      <img
        class="nav-item_image"
        alt=""
        aria-hidden="true"
        role="presentation"
        :src="image"
      >
    </div>
    <div
      v-if="hasIcon"
      class="nav-item_image-container"
    >
      <slot />
    </div>
    {{ cleanedLabel(label) }}
  </PdlNavItemBase>
</template>

<script>
import { mapGetters } from 'vuex'
import compileLinkVars from 'utils/mixins/compileLinkVars'
import PdlNavItemBase from './PdlNavItemBase'

export default {
  components: {
    PdlNavItemBase
  },
  mixins: [compileLinkVars],
  props: {
    listItemClass: {
      type: [String, Array],
      default() {
        return [
          'nav-item',
          { 'nav-item--with-image': 'image' }
        ]
      }
    },
    buttonClass: {
      type: [String, Array],
      default: 'nav-item_button'
    },
    linkClass: {
      type: [String, Array],
      default: 'nav-item_link'
    },
    image: {
      type: String,
      default: ''
    },
    hasIcon: {
      type: Boolean,
      default: false
    },
    url: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    linkType: {
      type: String,
      default: 'Internal'
    },
    idPrefix: {
      type: String,
      default: 'nav'
    },
    spyglassData: {
      type: Object,
      default: null
    },
    contentData: {
      type: Object,
      default: null
    },
    scheduledData: {
      type: Object,
      default: null
    },
    isNewNavDesignEnabled: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapGetters({
      brandVars: 'SiteConfig/brandVars'
    }),
  },
  methods: {
    cleanedLabel(val) {
      return this.cleanAllExpressions(val)
    },
    onNavItemClick(label, url, linkType, elementId) {
      // Prevent default here instead of template so that
      // anchors still link when there is no click handler.
      // Do not prevent default for external links because
      // they should still open the external page.
      this.$emit('nav-item-clicked', label, url, linkType, elementId, this.index)
    }
  }
}
</script>
