<template>
  <li>
    <span class="spyglass_label">{{ spyglassRow.name }}</span>:
    <a
      v-if="isJiraLink"
      :href="`http://jiracore.peapod.com/browse/${spyglassRow.value}`"
      class="spyglass_jira-id-link"
      target="_blank"
      rel="noopener noreferrer"
    >
      {{ spyglassRow.value }}
    </a>
    <a
      v-if="isGenericLink"
      :href="spyglassRow.value"
      target="_blank"
      rel="noopener noreferrer"
    >
      {{ spyglassRow.value }}
    </a>
    <span v-if="!isJiraLink && !isGenericLink">{{ ensureString(spyglassRow.value) }}</span>
  </li>
</template>

<script>
export default {
  props: {
    spyglassRow: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    isJiraLink: false,
    isGenericLink: false
  }),
  created() {
    this.setRowType()
  },
  methods: {
    /**
     * @method setRowType
     *
     * Slightly different templating required for different
     * content types.  This is called at creation to set the type
     * of template required
     */
    setRowType() {
      this.isJiraLink = /^CS[\w-]{4,10}$/i.test(this.spyglassRow.value)
      this.isGenericLink = /^http*/.test(this.spyglassRow.value)
    },
    ensureString(val) {
      if (val === null) {
        val = ''
      }
      if (Array.isArray(val)) {
        return val.join(', ')
      }
      if (typeof val === 'object') {
        const fullGroup = []
        Object.keys(val).forEach((itemKey) => {
          fullGroup.push(val[itemKey])
        })
        return fullGroup.join(', ')
      }
      return val
    }
  }
}
</script>
