import ApiService from '@/api/'
import { REVIEW_ORDER_DAY_SPAN } from 'utils/constants'

export default {
  userOrdersQuery(userId) {
    const endpoint = `/api/v6.0/user/${userId}/orders/query`

    return ApiService.post(endpoint, {
      completedOrders: {
        numberOfDays: REVIEW_ORDER_DAY_SPAN
      }
    })
  }
}
