<template>
  <div>
    <a
      v-if="external"
      :target="target"
      :rel="target === '_blank' ? 'noopener noreferrer' : ''"
      :href="location"
      :class="anchorClass"
      :aria-label="ariaLabel"
    >
      {{ linkTitle }}
    </a>
    <!-- eslint-disable-next-line vuejs-accessibility/click-events-have-key-events -->
    <a
      v-else
      href
      :class="anchorClass"
      :aria-label="ariaLabel"
      @click.prevent="propogateLinkClick"
    >
      {{ linkTitle }}
    </a>
  </div>
</template>

<script>
/**
 * use case of this would be if you are iterating a list
 * of URLs which might lead to external sites or trigger
 * actions within the Peapod App.  This handles differentiation
 * of templates for those scenarios, original use case was
 * the footer.
 */
export default {
  props: {
    external: {
      type: Boolean,
      default: true,
    },
    linkTitle: {
      type: String,
      required: true
    },
    anchorClass: {
      type: String,
      required: false,
      default: null
    },
    // used only for internal routing, supplies navType and route
    navigationInfo: {
      type: Object,
      required: false,
      default: null
    },
    // used for external routing, just a url
    location: {
      type: String,
      required: false,
      default: null
    },
    target: {
      type: String,
      required: false,
      default: '_blank'
    }
  },
  computed: {
    ariaLabel() {
      return `Footer ${this.linkTitle}`.toLowerCase()
    }
  },
  methods: {
    propogateLinkClick() {
      this.$emit('link-clicked', this.navigationInfo, this.linkTitle)
    }
  }
}
</script>
