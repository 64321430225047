import { GTM, GTM_GA4 } from 'utils/constants'
import { buildItemsListsForProducts } from 'utils/tracking/product/buildItemsListsForProducts'
import { getJiracoreInfo } from 'utils/tracking/buildJiracoreInfo'
import { getFormattedProductAttributes } from 'utils/tracking/helpers'

const buildAndTrackGtm = (products, sourceInfo) => {
  const vueInstance = window.sharedVue.config.globalProperties
  const {
    personalizationImpressionFlag,
    recommendationAlgorithm,
    modelId,
    invocationId,
    runId,
    dimension8,
    listType,
    listName,
    impressionLocation,
    transactionId,
    addToCartLocationdetail,
    searchTerm,
    userHealthPreferences,
  } = sourceInfo

  const impressionsList = products.map(product => ({
    id: product.prodId,
    name: product.name,
    category: product.rootCatName,
    variant: product.size,
    price: product.price,
    brand: product.brand,
    list: product.list,
    position: product.position,
    dimension37: product?.flags?.sale,
    ...(product.dimension90 !== undefined && { dimension90: product.dimension90 }),
    dimension38: product.regularPrice,
    dimension156: [
      ...product.ebtEligible ? ['ebt'] : [],
      ...product.sustainabilityRating > 0 ? ['sustainability'] : [],
      ...product.isMarketplaceProduct ? ['marketplace'] : [],
      ...product.sponsoredProductInfo ? ['sponsored'] : [],
      ...getFormattedProductAttributes(product.flags),
      ...product.guidingStars > 0 ? ['guiding stars'] : [],
    ]
  }))

  const tagData = {
    ecommerce: {
      currencyCode: 'USD',
      ...(impressionsList && { impressions: impressionsList })
    },
    personalizationImpressionFlag,
    ...(recommendationAlgorithm && { recommendationAlgorithm }),
    ...(modelId && { modelId }),
    ...(invocationId && { invocationId }),
    ...(runId && { runId }),
    ...(dimension8 && { dimension8 }),
    ...(listType && { listType }),
    ...(listName && { listName }),
    ...(impressionLocation && { impressionLocation }),
    ...(transactionId && { transactionId }),
    ...(addToCartLocationdetail && { addToCartLocationdetail }),
    ...(searchTerm && { searchTerm }),
    ...(userHealthPreferences && { userHealthPreferences })
  }

  vueInstance.$trackGtmEvent(GTM.productImpression, tagData)
}

const buildAndTrackGtmGA4 = (products, sourceInfo) => {
  const vueInstance = window.sharedVue.config.globalProperties
  const {
    reranked,
    rerankedInput,
    runId,
    recommendationAlgorithm,
    modelId,
    invocationId,
    impressionLocation,
    content
  } = sourceInfo
  const itemDetails = buildItemsListsForProducts(products, sourceInfo)
  const cartBasketId = vueInstance.$store.getters['Cart/getBasketId']
  const mlAlgo = recommendationAlgorithm && recommendationAlgorithm !== 'no cms value'
    ? recommendationAlgorithm : null
  const jiracoreInfo = getJiracoreInfo(content)
  const ga4TagData = {
    content_type: 'product',
    basket_id: cartBasketId && cartBasketId !== '1' ? cartBasketId : null,
    site_location: impressionLocation,
    reranked,
    rerankedInput,
    ml_run_id: runId,
    ml_algo: mlAlgo,
    ml_invocation_id: invocationId || null,
    ml_model_id: modelId || null,
    jiracore: jiracoreInfo,
    jiracore_referral: jiracoreInfo,
    jiracore_sitelocation: content?.Site_Location
  }

  ga4TagData.items = itemDetails
  vueInstance.$trackGA4Event(GTM_GA4.viewItemList, ga4TagData)
}

export function trackProductImpression({ products, sourceInfo = {} } = {}) {
  buildAndTrackGtm(products, sourceInfo)
  buildAndTrackGtmGA4(products, sourceInfo)
}
