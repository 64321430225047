
// QualtricsHelper
// Based on the following documentation:
//  https://www.qualtrics.com/support/website-app-feedback/common-use-cases/single-page-application/
class QualtricsHelper {
  library = {}

  complete = false

  retry() {
    return new Promise((resolve) => {
      let timeout = 14 // 7 seconds w/interval every half second
      const t = setInterval(() => {
        timeout -= 1
        const { API } = window.QSI || {}
        this.enabled = !!(API)
        this.library = API
        if (this.enabled || timeout < 1) {
          this.complete = true
          resolve(this.complete)
          clearInterval(t)
        }
      }, 500)
    })
  }

  // Upon page load, call this to ensure Qualtrics property exists on the Window object
  initialize() {
    return new Promise((resolve) => {
      (async () => {
        let results = false
        const { API } = window.QSI || {}
        this.enabled = !!(API)
        this.library = API
        // Wait for requirements to load
        if (!this.enabled) {
          await this.retry()
        } else {
          this.complete = true
        }

        if (this.enabled) {
          this.library.unload()
          this.library.load()
          results = true
        }
        resolve(results)
      })()
    })
  }

  // Upon Vue event redraw, call this to tell Qualtrics something has changed, and it can look for Elements to populate
  start() {
    if (this.enabled) {
      this.library.run()
    }
  }

  stop() {
    if (this.enabled) {
      this.library.unload()
    }
  }

  reload() {
    if (this.enabled) {
      this.library.unload()
      this.library.load()
    }
  }
}

const helper = new QualtricsHelper()

export default helper
