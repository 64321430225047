import { GTM, GTM_GA4 } from 'utils/constants'
import generateSiteLocation from 'utils/lib/generateSiteLocation'

export default {
  methods: {
    trackAccountSubmit(options = {}) {
      const {
        formSaved, navCategory, success
      } = options
      this.$trackGtmEvent(GTM.accountSave, {
        formSaved,
        navCategory,
        success,
        ...options
      })
    },
    accountNavClick(formTitle, navCategory, {
      linkId, linkText, linkUrl, index, navRoot, route = ''
    } = {}) {
      this.$trackGtmEvent(GTM.accountNav, {
        formTitle,
        navCategory
      })
      const titleMap = {
        '/account/': 'Account',
      }
      const typeMap = {
        '/account/': 'account',
      }
      const isTopLevel = linkUrl && ((linkUrl.split('/').length - 1) <= 2)
      const isPasswordRequest = linkUrl === '/password-request'
      const isUserInformation = route.startsWith('user')
      const isOrderHistory = route.startsWith('history')
      let siteLocationPath

      if (isTopLevel) {
        siteLocationPath = '/account'
      } else if (isUserInformation || isPasswordRequest) {
        siteLocationPath = '/account/user'
      } else if (isOrderHistory) {
        siteLocationPath = '/account/history'
      }

      if (titleMap[navRoot] && typeMap[navRoot]) {
        this.$trackGA4Event(GTM_GA4.navigation, {
          link_id: linkId,
          type: typeMap[navRoot],
          link_text: linkText,
          link_url: linkUrl,
          index: index + 1,
          site_location: generateSiteLocation({ path: siteLocationPath })
        })
      }
    },
    accountNavClickSubscriptionPlan(formTitle, navCategory, subscriptionPlan) {
      this.$trackGtmEvent(GTM.accountNav, {
        formTitle,
        navCategory,
        subscriptionPlan
      })
    },
    trackAccountLoad(formTitle, navCategory, options = {}) {
      this.$trackGtmEvent(GTM.accountTypes, {
        formTitle,
        navCategory,
        ...options
      })
    }
  }
}
