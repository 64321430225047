/**
 * Finds the first element with an 'ng-app' attribute, then returns that element's value.
 * @returns {string}
 */
export const getAppName = () => {
  const nodes = document.querySelectorAll('[ng-app]')
  if (!nodes.length) return ''
  if (nodes.length > 1) console.error(`getAppName found ${nodes.length} ng-apps on page.`)

  return nodes[0].attributes['ng-app'].value
}
