<template>
  <button
    v-if="!isMobileApp"
    class="btn btn--fourth pdl-print-btn"
    @click="printCart"
  >
    <PdlIconBase
      icon-title="Print"
      container-class="vector-icon-size--small"
    >
      <PdlIconPrint />
    </PdlIconBase>
    <span>{{ text }}</span>
  </button>
</template>
<script>
import { mapGetters } from 'vuex'
import { PdlIconBase } from '@/shared/icons'
import PdlIconPrint from 'components/icons/icon/PdlIconPrint'

export default {
  components: {
    PdlIconBase,
    PdlIconPrint
  },
  props: {
    text: {
      type: String,
      default: 'Print'
    }
  },
  computed: {
    ...mapGetters({
      isMobileApp: 'NativeContainer/isMobileApp'
    })
  },
  methods: {
    printCart() {
      this.$emit('print')
    }
  }
}
</script>
