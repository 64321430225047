<template>
  <nav
    aria-label="breadcrumbs"
    role="navigation"
    class="breadcrumb-nav"
  >
    <ol class="breadcrumb-nav--list">
      <li
        v-for="breadcrumb, index in breadcrumbs"
        :key="index"
        class="breadcrumb-nav--list-item"
      >
        <a
          :href="breadcrumb.path"
          target="_blank"
          rel="noopener noreferrer"
          class="breadcrumb-nav--link"
          :class="{
            'hover:underline underline-offset-[5px]': breadcrumb.path
          }"
          @click.stop.prevent="onClick(breadcrumb.path)"
        >
          {{ breadcrumb.label }}
        </a>
        <span
          v-if="!isLastBreadcrumb(index)"
          class="breadcrumb-nav--separator"
          aria-hidden="true"
        >
          /
        </span>
      </li>
    </ol>
  </nav>
</template>
<script>

export default {
  emits: ['click'],
  props: {
    // Breadcrumbs should show the hierarchy of the site, not show the pages the
    // user navigated through to get to the page. Do not pass TitleBar/breadcrumbs
    // here. Breadcrumbs should show the way to find the page even if the user
    // navigated via an external link.
    breadcrumbs: {
      type: Array,
      default() {
        return [
          {
            label: 'Home',
            path: '/'
          }
        ]
      }
    }
  },
  methods: {
    isLastBreadcrumb(index) {
      return index === this.breadcrumbs.length - 1
    },
    onClick(path) {
      this.$emit('click', path)
    }
  }
}
</script>
