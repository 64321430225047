import { PP_TIMES } from 'utils/constants'

export function getPastPurchasesTabName() {
  const { ppTime } = window.sharedVue.config.globalProperties.$store.state.ProductTile
  let tabName
  if (ppTime) {
    tabName = PP_TIMES.find(time => ppTime === time.value)?.name
  }
  return tabName || ''
}
