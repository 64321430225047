<template>
  <div
    v-if="isAppLoaded"
    id="contact-page"
    class="mx-auto screen-limit-lg my-10 help-view_content-container relative flex pb-8"
    :class="{
      'px-8': !isDesktop
    }"
  >
    <div
      v-if="showMainNav"
      id="contact-menu"
      class="help-view_sidebar-container overflow-auto shrink-0"
      :class="{
        'max-w-lg': !isMobile,
        'flex-grow w-full': isMobile
      }"
    >
      <div
        class="help-menu_wrapper relative"
      >
        <PdlMenuList
          route="contact-us"
          nav-root="/help/"
          :nav-items="mainTopicLinks"
          :border-container="true"
          :is-sidebar="true"
          :is-help-menu="true"
          name="contact-help-menu"
          @change="onMainItemAction"
        />
      </div>
    </div>
    <div
      class="relative grow"
      :class="{
        'pl-8': !isMobile,
        'max-w-full': isMobile
      }"
    >
      <div
        v-if="showSubcontentLoader"
        class="loading-spinner h-80 my-8 width-full flex items-center justify-center top-[40%]"
      >
        <PdlLoader
          :themed="true"
          :is-help-menu="true"
        />
      </div>
      <h2
        v-if="showTitle"
        class="tw-hd-5 pb-4 border-solid border-0 border-gray-100 border-b mb-8"
      >
        {{ titleCopy }}
      </h2>
      <PdlMenuList
        v-if="showTopicList"
        name="contact-help-submenu"
        :nav-root="baseUrl"
        :url="url"
        :nav-items="subTopicList"
        :border-container="true"
        :is-help-menu="true"
        :light-theme="true"
        @change="onListItemAction"
      />
      <router-view
        v-if="!showSubcontentLoader"
        :="activeContent"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import compileLinkVars from 'utils/mixins/compileLinkVars'
import formatSafeKey from 'utils/filters/formatSafeKey'
import PdlMenuList from 'components/PdlMenuList'
import { GTM, GTM_GA4 } from 'utils/constants'
import generateSiteLocation from '@/utils/lib/generateSiteLocation'

export default {
  mixins: [compileLinkVars],
  components: { PdlMenuList },
  data() {
    return {
      baseUrl: '/contact/',
      contactTopicContent: null,
      activeContent: {},
      canHasStaticLinks: true
    }
  },
  computed: {
    ...mapState({
      isAppLoaded: state => state.Application.isAppLoaded,
      helpContentLoading: state => state.Help?.helpContentLoading,
    }),
    ...mapGetters({
      isMobile: 'ScreenSize/isMobile',
      isDesktop: 'ScreenSize/isDesktop',
      mainTopics: 'Help/mainTopics',
      varByName: 'SiteConfig/varByName'
    }),
    url() {
      return this.$route.path
    },
    isRoot() {
      return this.$route.name === 'contact'
    },
    typeActions() {
      return [
        {
          routes: ['/chatbot'],
          action: this.launchChatBot
        }
      ]
    },
    mainTopicLinks() {
      const items = this.mainTopics || []
      return items.map((item) => {
        // Expected Schema: {
        //   title: 'User Information', icon: 'user', description: 'Username, password, email', route: 'user'
        // }
        const {
          title, id, key, type, subtitle
        } = item
        const route = this.safeId(title)
        return {
          title: this.cleanAllExpressions(title),
          id,
          key,
          type,
          description: this.cleanAllExpressions(subtitle),
          route
        }
      })
    },
    contactTopicConfig() {
      const found = Object.keys(this.mainTopicLinks).find((key) => {
        const { route = '' } = this.mainTopicLinks[key] || {}
        return route === 'contact-us' || route === 'contact'
      })
      return found ? this.mainTopicLinks[found] : {}
    },
    staticLinks() {
      if (!this.canHasStaticLinks) return []
      return [
        {
          helpTopicRoute: '/contact/product-request',
          id: 'product-request',
          title: 'Product Request',
          type: 'topic'
        },
        {
          helpTopicRoute: '/contact/feedback',
          id: 'feedback',
          title: 'Give Us Feedback',
          type: 'topic'
        }
      ]
    },
    subTopicList() {
      const { formatRoute } = this
      const { links = [] } = this.contactTopicContent || {}

      const groupLinks = [
        ...links,
        ...this.staticLinks
      ]

      return groupLinks.map((item) => {
        const {
          title, id, key, type, subtitle, helpTopicRoute, text
        } = item

        const hasDeferredAction = !!this.typeActions.find((configObj) => {
          return configObj.routes.includes(helpTopicRoute)
        })

        let route = type !== 'topic' ? `${this.baseUrl}${type}/${this.safeId(title)}` : helpTopicRoute
        const isDirectRoute = !route?.includes('contact/')
        route = formatRoute(route, isDirectRoute)

        return {
          title: this.cleanAllExpressions(title),
          id,
          key,
          type,
          description: this.cleanAllExpressions(subtitle),
          route,
          text,
          isDirectRoute,
          hasDeferredAction
        }
      })
    },
    activeItem() {
      const { $route, baseUrl } = this
      return this.subTopicList?.find((item) => {
        return item.route === $route.path || `${baseUrl}${item.route}` === $route.path
      }) || {}
    },
    type() {
      return this.activeItem?.type || ''
    },
    titleCopy() {
      return this.activeItem?.title || ''
    },
    showMainNav() {
      return this.mainTopicLinks.length > 0
        && (!this.isMobile)
    },
    showTopicList() {
      return !this.showSubcontentLoader && this.isRoot && this.subTopicList.length > 0
    },
    showSubcontentLoader() {
      return this.isRoot && (!this.isAppLoaded || this.helpContentLoading)
    },
    showTitle() {
      return !this.showSubcontentLoader && !this.showTopicList && this.titleCopy
    }
  },
  watch: {
    isAppLoaded(newVal, oldVal) {
      // only initialize on change from FALSY to TRUTHY, prevents duplicate calls
      if (!oldVal && newVal) {
        this.initialize()
      }
    }
  },
  created() {
    if (this.isAppLoaded) {
      this.initialize()
    }
  },
  methods: {
    ...mapActions({
      getContent: 'Help/getContent'
    }),
    safeId(value) {
      if (typeof value !== 'string') return ''
      let safeId = value.trim()
      safeId = `${formatSafeKey(safeId)}`
      safeId = safeId.split(/\s+/g).join('-')
      return safeId?.toLowerCase() || ''
    },
    async initialize() {
      this.$store.commit('TitleBar/setPageTitle', 'Contact Us')
      await this.$store.dispatch('Help/getHelpMainTopics')

      this.getContactTopic()
    },
    async getContactTopic() {
      if (!this.contactTopicContent && this.contactTopicConfig?.id) {
        this.contactTopicContent = await this.getContent({ id: this.contactTopicConfig?.id, type: 'topic' })
      }
      this.tryGetAdditionalContent()
    },
    typeIs(which) {
      return this.type === which
    },
    async tryGetAdditionalContent() {
      const { type } = this.activeItem || {}
      if (type === 'article') {
        const articleContent = await this.getArticleContent(this.activeItem)
        this.activeContent = {
          article: articleContent
        }
        return
      }
      this.activeContent = {}
    },
    getArticleContent(item = {}) {
      if (!item?.id) return {}
      if (item.text) {
        return item
      }
      return this.getContent({ id: item.id, type: 'article' })
    },
    formatRoute(url = '', isDirectRoute = false) {
      url = url.replaceAll(/\/modal\/help\/contact\/|\/help\/contact|\/modal\/help\/|\/contact\//g, '')
      url = !isDirectRoute ? url.replace(/^(\/)/, '') : url
      return url
    },
    onMainItemAction(item, index, destination, elemId) {
      this.trackNav(item, index, destination, elemId)
    },
    onListItemAction(item, index, destination, elemId) {
      if (item?.hasDeferredAction) {
        const foundItem = this.typeActions.find(configObj => configObj.routes.includes((item.route)))
        if (foundItem?.action) { foundItem.action() }
      }
      this.trackNav(item, index, destination, elemId)
    },
    launchChatBot() {
      this.$store.commit('Chatbot/setIsOpen', true)
      this.close()
      return false
    },
    close() {
      this.$store.commit('Modals/clearActiveModal')
    },
    trackNav({ title, type, id }, index, destination, elemId = null) {
      const isMainTopic = this.mainTopicLinks.findIndex(topicLink => topicLink.id === id) !== -1
      const activeTopicTitle = this.activeMainTopic?.title || ''
      const navCategory = isMainTopic ? title : activeTopicTitle

      this.$trackGtmEvent(GTM.helpCenterTracking, {
        formTitle: title,
        navCategory,
        type,
      })

      this.$trackGA4Event(
        GTM_GA4.navigation,
        {
          link_id: elemId,
          type: 'help center',
          link_text: title,
          link_url: destination,
          index: index + 1, // GA4 requires Index starts at 1, not 0
          site_location: generateSiteLocation({ path: '/contact' })
        }
      )
    },
  }
}
</script>
