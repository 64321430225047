<template>
  <div
    ref="map"
    class="h-full"
    :style="`height: ${size.height}; width=${size.width}`"
  />
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    locations: {
      type: Array,
      required: true
    },
    size: {
      type: Object,
      default: () => ({
        width: '100%',
        height: '100%'
      })
    },
    // corresponds to pin that is in active state
    activeResultIndex: {
      type: Number,
      default: undefined
    },
    clickablePins: {
      type: Boolean,
      default: true
    },
    zoomOnMarker: {
      type: Boolean,
      default: false
    },
    isStatic: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      bounds: undefined,
      map: null,
      markers: [],
      defaultPinColor: '#63666A'
    }
  },
  computed: {
    ...mapGetters({
      opco: 'SiteConfig/opco'
    }),
    pin() {
      // google api docs say it will anchor a custom svg icon at its bottom center point,
      // instead it is anchoring at top left corner, so we use anchor and labelOrigin to adjust
      const { maps } = window.google
      return {
        anchor: new maps.Point(15, 22),
        labelOrigin: new maps.Point(16, 13),
        path: 'M16.133 4.8c-4.485 0-8.133 3.649-8.133 8.133 0 4.207 6.54 13.875 8.133 13.875s8.133-9.668 8.133-13.875c0-4.485-3.649-8.133-8.133-8.133z', // eslint-disable-line max-len
        fillColor: this.defaultPinColor,
        fillOpacity: 1,
        scale: 1.6,
        strokeWeight: 0
      }
    },
    customIcon() {
      return {
        truck: { // truck w/ circle background
          front: 'M21.397 18.64c-1.486 0-2.691 1.186-2.691 2.649s1.205 2.649 2.691 2.649c1.486 0 2.691-1.186 2.691-2.649s-1.205-2.649-2.691-2.649zM21.397 19.817c0.826 0 1.495 0.659 1.495 1.472s-0.669 1.472-1.495 1.472c-0.826 0-1.495-0.659-1.495-1.472s0.669-1.472 1.495-1.472z M10.784 18.64c-1.486 0-2.691 1.186-2.691 2.649s1.205 2.649 2.691 2.649c1.486 0 2.691-1.186 2.691-2.649s-1.205-2.649-2.691-2.649zM10.784 19.817c0.826 0 1.495 0.659 1.495 1.472s-0.669 1.472-1.495 1.472c-0.826 0-1.495-0.659-1.495-1.472s0.669-1.472 1.495-1.472z M18.89 7.385c0.304 0 0.554 0.234 0.589 0.536l0.004 0.071-0 1.356 2.258 0.001c0.15 0 0.294 0.058 0.404 0.163 0.561 0.536 0.988 1.144 1.276 1.82l0.411 0.982 0.627 1.534 2.161 0.516c0.246 0.059 0.426 0.269 0.454 0.522l0.004 0.070v5.95c0 0.311-0.229 0.568-0.523 0.603l-0.069 0.004-1.822 0.001c0.009-0.1 0.013-0.202 0.013-0.304 0-1.844-1.459-3.339-3.259-3.339s-3.259 1.495-3.259 3.339c0 0.103 0.005 0.204 0.013 0.304h-4.174c0.009-0.1 0.013-0.202 0.013-0.304 0-1.844-1.459-3.339-3.259-3.339s-3.259 1.495-3.259 3.339c0 0.103 0.005 0.204 0.013 0.304l-1.361-0.001c-0.304 0-0.554-0.234-0.589-0.536l-0.004-0.071v-12.985c0-0.311 0.229-0.498 0.523-0.533l0.069-0.004h12.745zM22.196 11.063c-0.18-0.293-0.573-0.421-0.917-0.287v0l-0.076 0.035c-0.211 0.111-0.352 0.394-0.423 0.85v0 2.479c0 0.372 0.326 0.673 0.728 0.673v0h1.312l0.085-0.004c0.474-0.050 0.777-0.512 0.585-0.931v0l-1.257-2.744z', // eslint-disable-line max-len
          back: 'M32 16c0 8.837-7.163 16-16 16s-16-7.163-16-16c0-8.837 7.163-16 16-16s16 7.163 16 16z' // eslint-disable-line max-len
        },
        home: { // star w/ pin background
          front: 'M18.705 11.758c0 0.14-0.104 0.273-0.207 0.374l-2.89 2.761 0.685 3.899c0.008 0.055 0.008 0.101 0.008 0.156 0 0.203-0.096 0.39-0.326 0.39-0.111 0-0.223-0.039-0.319-0.094l-3.575-1.841-3.575 1.841c-0.104 0.055-0.207 0.094-0.319 0.094-0.231 0-0.334-0.187-0.334-0.39 0-0.055 0.008-0.101 0.016-0.156l0.685-3.899-2.898-2.761c-0.096-0.101-0.199-0.234-0.199-0.374 0-0.234 0.247-0.328 0.446-0.359l3.997-0.569 1.792-3.549c0.072-0.148 0.207-0.32 0.39-0.32s0.319 0.172 0.39 0.32l1.792 3.549 3.997 0.569c0.191 0.031 0.446 0.125 0.446 0.359z', // eslint-disable-line max-len
          back: 'M11.886 0c-6.554 0-11.886 5.292-11.886 11.797 0 6.102 9.558 20.125 11.886 20.125s11.886-14.023 11.886-20.125c0-6.505-5.332-11.797-11.886-11.797zM18.705 11.758c0 0.14-0.104 0.273-0.207 0.374l-2.89 2.761 0.685 3.899c0.008 0.055 0.008 0.101 0.008 0.156 0 0.203-0.096 0.39-0.326 0.39-0.111 0-0.223-0.039-0.319-0.094l-3.575-1.841-3.575 1.841c-0.104 0.055-0.207 0.094-0.319 0.094-0.231 0-0.334-0.187-0.334-0.39 0-0.055 0.008-0.101 0.016-0.156l0.685-3.899-2.898-2.761c-0.096-0.101-0.199-0.234-0.199-0.374 0-0.234 0.247-0.328 0.446-0.359l3.997-0.569 1.792-3.549c0.072-0.148 0.207-0.32 0.39-0.32s0.319 0.172 0.39 0.32l1.792 3.549 3.997 0.569c0.191 0.031 0.446 0.125 0.446 0.359z' // eslint-disable-line max-len
        }
      }
    },
    brandPinColor() {
      switch (this.opco) {
        case 'STSH':
          return '#e0004d'
        case 'GNTL':
          return '#702779'
        case 'GNTC':
        case 'MRTN':
          return '#dd1e0d'
        case 'FDLN':
          return '#005695'
        default:
          return '#577926'
      }
    },
    activePin() {
      return {
        ...this.pin,
        fillColor: this.brandPinColor
      }
    },
    hasLocations() {
      return this.locations.length
    },
    mapCenter() {
      const { maps } = window.google
      // default to center of U.S.
      return this.hasMarkers ? this.bounds.getCenter() : new maps.LatLng(40, -96)
    },
    zoom() {
      return this.hasLocations ? 14 : 4
    }
  },
  watch: {
    locations: {
      handler(newVal, oldVal) {
        if (newVal.length !== oldVal.length) {
          this.updateMap()
        }
      },
      deep: true
    },
    activeResultIndex() {
      this.updateMarkerIcons()
    }
  },
  mounted() {
    const { maps } = window.google
    this.setMapBounds()
    this.map = new maps.Map(this.$refs.map, {
      center: this.mapCenter,
      zoom: this.hasLocations ? this.zoom : 4,
      maxZoom: 17,
      mapTypeControl: false,
      streetViewControl: false,
      gestureHandling: this.isStatic ? 'none' : 'cooperative',
      zoomControl: !this.isStatic
    })
    if (this.hasLocations) {
      this.map.fitBounds(this.bounds)
    }
    this.updateMap()
    this.setMarkers()
    window.addEventListener('resize', _.debounce(() => {
      maps.event.trigger(this.map, 'resize')
    }, 500))
  },
  beforeUnmount() {
    this.clearMapListeners()
  },
  methods: {
    updateMap() {
      this.setMapBounds()
      this.map.setCenter(this.mapCenter)
      this.map.setZoom(this.zoom)
      if (this.hasLocations) {
        this.map.fitBounds(this.bounds)
      }
      this.resetMarkers()
      this.setMarkers()
    },
    customPin(icon, part) {
      const { maps } = window.google
      return {
        anchor: new maps.Point(15, 22),
        path: this.customIcon[icon][part],
        fillColor: part === 'front' ? '#fff' : '#333',
        fillOpacity: 1,
        scale: 1,
        strokeWeight: 0
      }
    },
    resetMarkers() {
      this.clearMarkers()
      this.clearMapListeners()
    },
    newMarker(loc) {
      const { maps } = window.google
      return new maps.Marker({
        position: {
          lat: parseFloat(loc.latitude),
          lng: parseFloat(loc.longitude),
        },
        map: this.map,
      })
    },
    // creates either a custom marker if iconType is specified on loc
    // or defaults to numbered pin if not
    createMarker(loc, index) {
      if (loc.iconType) {
        const markerFront = this.newMarker(loc)
        markerFront.setOptions({
          icon: this.customPin(loc.iconType, 'front'),
          zIndex: 1
        })
        const markerBack = this.newMarker(loc)
        markerBack.setOptions({
          icon: this.customPin(loc.iconType, 'back'),
          zIndex: 0
        })
        return [markerFront, markerBack]
      }
      const pinMarker = this.newMarker(loc)
      pinMarker.setOptions({
        icon: this.pin,
        label: {
          text: (index + 1).toString(),
          color: 'white',
          fontWeight: '600'
        }
      })
      return [pinMarker]
    },
    setMarkers() {
      this.locations.forEach((loc, index) => {
        const markers = this.createMarker(loc, index)
        if (this.clickablePins) {
          this.createMarkerListener(markers[0], index)
        }
        this.markers.push(...markers)
      })
    },
    createMarkerListener(marker, index) {
      marker.addListener('click', (markerData) => {
        marker.setIcon(this.activePin)
        this.$emit('marker-click', {
          ...markerData,
          index
        })
      })
    },
    updateMarkerIcons() {
      this.markers.forEach((marker, index) => {
        marker.setIcon(this.activeResultIndex === index ? this.activePin : this.pin)
        if (this.zoomOnMarker && this.activeResultIndex === index) {
          this.map.setZoom(17)
          this.map.panTo(marker.position)
        }
      })
    },
    clearMarkers() {
      this.markers.forEach((marker) => {
        marker.setMap(null)
      })
      this.markers = []
    },
    clearMapListeners() {
      const { clearInstanceListeners } = window.google?.maps?.event || {}
      if (clearInstanceListeners) {
        this.markers.forEach(clearInstanceListeners)
      }
    },
    setMapBounds() {
      const { maps } = window.google || {}
      this.bounds = new maps.LatLngBounds()
      this.locations.forEach((loc) => {
        this.bounds.extend(new maps.LatLng(parseFloat(loc.latitude), parseFloat(loc.longitude)))
      })
    },
  },
}
</script>
