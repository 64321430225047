// When smart punctuation feature is ON , iOS changes some default chars
// This function is to replace such characters

export default function (value) {
  const hasBacktick = new RegExp(
    /[“”‘’]/
  ).test(value)
  if (hasBacktick) {
    return value?.replace(/[“”]/g, '"')?.replace(/[‘’]/g, '\'')
  }
  return value
}
