import ApiService from '@/api/'

export default {
  getOrderTipInfo(userId, orderId) {
    return ApiService.get(`/apis/fulfillment/${userId}/driver/v1/tips/${orderId}`)
  },
  postTipToOrder(userId, ordId, tipAmount) {
    return ApiService.post(`/apis/fulfillment/${userId}/driver/v1/tips/${ordId}`, {
      ordId,
      tipAmount
    })
  },
  postApplePayPostDeliveryTip(userId, orderId, payload) {
    return ApiService.post(`api/v6.0/user/${userId}/order/${orderId}/postdeliverytip`, payload)
  },
  postLastMileTip(userId, ordId, tipAmount) {
    return ApiService.put(`/apis/v1/partner/INSTACART/user/${userId}/driver/${ordId}/tip`, {
      tipAmountCents: tipAmount
    })
  }
}
