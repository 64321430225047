import optimizely from '@optimizely/optimizely-sdk'
import { isIOSDevice, isIOSBrowser } from 'components/detect/services/detect-services'
import UUID from 'utils/services/UUID'

optimizely.setLogLevel('null')


if (process.env.NODE_ENV === 'development') {
  optimizely.setLogLevel('info')
}

class OptimizelyFullstackService {
  loaded = false

  dataFile

  optimizelyClientInstance

  userId

  screenSize

  hasProvidedDietaryPreference = false

  attributes

  error

  /*
   * This mocks the optimizelyFullStack api:
   * https://docs.developers.optimizely.com/full-stack/v4.0/docs/example-usage-javascript
   * to call this method, import service then call service.onReady().then(callback)
   */

  async init() {
    this.error = {}
    this.getUserId()
    this.getScreenSize()
    await this.getUserDietaryPreference()
    await this.getAttributes()
    await this.getDataFile()
    await this.createInstance()
  }

  async createInstance() {
    this.optimizelyClientInstance = optimizely.createInstance({ datafile: this.dataFile })
    const { success } = await this.optimizelyClientInstance.onReady()
    if (!success) {
      window.$store.commit('Optimizely/setFullstackFailed', true)
      throw new Error('failed to load instance')
    } else {
      window.$store.commit('Optimizely/setFullstackInitialized', true)
      this.loaded = true
    }
  }
  // For future use when we upgrade
  // async createUserContext() {
  //  this.optimizelyClientInstance = optimizely.//({ userId: this.userId, attributes: this.attributes })
  //   const { success } = await this.optimizelyClientInstance.onReady()
  //   if (!success) {
  //     window.$store.commit('Optimizely/setFullstackFailed', true)
  //     throw new Error('failed to load instance')
  //   } else {
  //     window.$store.commit('Optimizely/setUserContext', { userId: this.userId, attributes: this.attributes })
  //     this.loaded = true
  //   }
  // }

  async getDataFile() {
    const response = await fetch('/apis/site-config-api/v1/experiment/datafile')
    if (!response.ok && response.status !== 304) {
      window.$store.commit('Optimizely/setFullstackFailed', true)
    }
    try {
      this.dataFile = await response.json()
    } catch {
      window.$store.commit('Optimizely/setFullstackFailed', true)
    }
  }

  getUserId() {
    this.userId = UUID.setAndFind()
  }


  getScreenSize() {
    if (window.$store.getters['ScreenSize/isMobile']) {
      this.screenSize = 'mobile'
    }
    if (window.$store.getters['ScreenSize/isDesktop']) {
      this.screenSize = 'desktop'
    }
    if (window.$store.getters['ScreenSize/isTablet']) {
      this.screenSize = 'tablet'
    }
  }

  async getUserDietaryPreference() {
    await window.$store.dispatch('HealthPreferences/getHealthPreferences')
    const dietaryPreferences = window.$store.state.HealthPreferences.healthPreferences
    const selectedPreferences = dietaryPreferences?.filter(preference => preference.selected)
    this.hasProvidedDietaryPreference = selectedPreferences?.length > 0
  }

  async getAttributes(eventName) {
    const isNativeApp = await window.$store?.getters['NativeContainer/isNativeApp']
    const isMobileApp = await window.$store?.getters['NativeContainer/isMobileApp']
    const platform = (isNativeApp || isMobileApp) ? 'app' : 'web'
    const route = window.sharedVue.config.globalProperties.$route

    this.attributes = {
      numberOrders: window.appConfig.timeOrdQy,
      device: this.screenSize,
      opcoId: window.appConfig?.opcoId?.toLowerCase(),
      hasProvidedDietaryPreference: this.hasProvidedDietaryPreference,
      log_in: await window.$store?.getters['LoginStatus/isLoggedIn'],
      basket_value: window.$store?.getters['Cart/getCartItems']?.length,
      service_type: window.$store?.getters['UserProfile/serviceType'],
      pageView: route?.fullPath || route?.path,
      isIOSDevice: isIOSDevice(),
      isIOSBrowser: isIOSBrowser(),
      platform
    }
    if (eventName === 'Order_Placed') {
      this.attributes = { ...this.attributes, orderId: window.$store?.getters['Cart/getCartId'] }
    }
  }

  async getFullConfigsForFeature(featureName, featureVariableList) {
    const configs = {
      enabled: false,
      featureVariables: {}
    }

    if (!this.loaded) {
      return configs
    }

    try {
      configs.enabled = await this.optimizelyClientInstance.isFeatureEnabled(featureName, this.userId)
      if (!configs.enabled) {
        return configs
      }
      configs.featureVariables = await this.getFeatureVariables(featureName, featureVariableList)
    } catch (error) {
      this.error = error
    }
    return configs
  }

  async isFeatureEnabled(featureName) {
    let isFeatureEnabled

    if (!this.loaded) {
      return isFeatureEnabled
    }

    try {
      isFeatureEnabled = await this.optimizelyClientInstance.isFeatureEnabled(featureName, this.userId, this.attributes)
    } catch (error) {
      this.error = error
    }
    return isFeatureEnabled
  }

  async getVariation(featureName) {
    let variation

    if (!this.loaded) {
      return variation
    }

    try {
      variation = await this.optimizelyClientInstance.activate(featureName, this.userId, this.attributes)
    } catch (error) {
      this.error = error
    }
    return variation
  }

  // new method please review
  async getFeatureVariableBoolean(featureName, featureVariable) {
    let getFeatureVariableBoolean

    if (!this.loaded) {
      return getFeatureVariableBoolean
    }

    try {
      getFeatureVariableBoolean = await this.optimizelyClientInstance
        .getFeatureVariableBoolean(featureName, featureVariable, this.userId, this.attributes)
    } catch (error) {
      this.error = error
    }
    return getFeatureVariableBoolean
  }

  async getFeatureVariableInteger(featureName, featureVariable) {
    let getFeatureVariableInteger

    if (!this.loaded) {
      return getFeatureVariableInteger
    }

    try {
      getFeatureVariableInteger = await this.optimizelyClientInstance
        .getFeatureVariableInteger(featureName, featureVariable, this.userId, this.attributes)
    } catch (error) {
      this.error = error
    }
    return getFeatureVariableInteger
  }

  async getFeatureVariableString(featureName, featureVariable) {
    let getFeatureVariableString

    if (!this.loaded) {
      return getFeatureVariableString
    }

    try {
      getFeatureVariableString = await this.optimizelyClientInstance
        .getFeatureVariableString(featureName, featureVariable, this.userId, this.attributes)
    } catch (error) {
      this.error = error
    }
    return getFeatureVariableString
  }

  async getSingleFeatureVariable(featureName, featureVariable) {
    let singleFeatureVariable

    if (!this.loaded) {
      return singleFeatureVariable
    }

    try {
      singleFeatureVariable = await this.optimizelyClientInstance
        .getFeatureVariable(featureName, featureVariable, this.userId)
    } catch (error) {
      this.error = error
    }
    return singleFeatureVariable
  }

  async getFeatureVariables(featureName, featureVariableList) {
    let featureVariables

    if (!this.loaded) {
      return featureVariables
    }

    try {
      featureVariables = await featureVariableList.reduce(async (dict, featureVariable) => {
        dict[featureVariable] = await this.optimizelyClientInstance
          .getFeatureVariable(featureName, featureVariable, this.userId)
        return dict
      }, {})
    } catch (error) {
      this.error = error
    }
    return featureVariables
  }

  async instanceMethod(cb, ...args) {
    let instanceMethod
    try {
      instanceMethod = this.optimizelyClientInstance[cb](...args, this.userId)
    } catch (error) {
      this.error = error
    }
    return instanceMethod
  }

  async trackEvent(eventName, tags = {}) {
    await this.getAttributes(eventName)
    let trackEvent
    try {
      trackEvent = await this.optimizelyClientInstance.track(eventName, this.userId, this.attributes, tags)
    } catch (error) {
      this.error = error
    }
    return trackEvent
  }
}
const optimizelyFullstackService = new OptimizelyFullstackService()

export default optimizelyFullstackService
