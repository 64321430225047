/*
  FormMessages
  Form messages to unify the messages displayed to the user
  @param {string}(optional) pass in string of the message you need to get.
  If no param, will return the whole object of messages
  example: messages('form') for the form object messages
  example: messages('fields') for the fields object messages
  example: messages('fields.zipCode.required') for the zip code required messaging
  Getter will return undefined if the store object property has a value of null or undefined
*/
/* eslint-disable max-len */
import { MAX_EMAIL_LENGTH } from 'utils/constants'
import capitalize from 'utils/filters/capitalize'
import formatSafeId from 'utils/filters/formatSafeId'

const partials = {
  phoneNumberLink: (number = '1-800-573-2763') => `<a class="text-link no-wrap" href="tel:${formatSafeId(number)}">${number}</a>`,
  phoneAlt: number => ((number) ? `<b>${number}</b> ` : '')
}

const initialState = ({ phone, email, brandName }) => {
  const isPeapod = brandName === 'Peapod'
  const phoneAlt = ((isPeapod) ? this.getters['SiteConfig/brandContactNumberLink'] : '')
  phone = !phone ? '1-800-573-2763' : phone
  email = !email ? 'service@peapod.com' : email
  const phoneNumberLink = partials.phoneNumberLink(phone)

  return {
    form: {
      generic: 'Something went wrong. Please try again.',
      success: 'Your account has been updated.',
      error: 'There was a problem updating your account.',
      cardUpdateInvalid: 'The card number entered was invalid.',
      cardReplacementSuccess: 'Your card replacement request was successful.',
      cardReplaceError: 'There was a problem replacing your card.',
      deliveryAddress: {
        invalidAddress: 'Delivery address must match with zipcode entered.',
        billingSyncError: 'Go to payments to update your billing address.',
        loyaltySyncError: 'Update your rewards address in user information.',
        DEFAULT_CONTACT: `Sorry, we are unable to save your information at this time. Please contact customer care at ${phone} for further assistance.`,
        DELIVERY_ZIP_NO_SERVICE: `Sorry, we are unable to save your address change at this time. Please contact customer care at ${phone} for further assistance.`,
        DELIVERY_ADDR_OUT_OF_MARKET: 'We currently don\'t deliver to your new address. We will email you when delivery becomes available.',
        DELIVERY_ADDR_SLOT_DROPPED: 'Due to a change in address, we have cancelled your delivery order. Please select a new time.',
        DELIVERY_ADDR_ORDER_DROPPED: 'Due to a change in address, we have cancelled your delivery order. Please select a new time.',
        DELIVERY_ADDR_MARKET_CHANGE: `We deliver to your new address, but from a different fulfillment center. For delivery to your new address, please contact Customer Care at ${phone}.`,
        DELIVERY_ADDR_HAS_PENDING_ORDER: 'You will be able to change your address the day after your current order has been fulfilled.',
        DELIVERY_ADDR_SUBMITTED_PICKUP_ORDER: 'You will be able to change your address the day after your current order has been fulfilled.',
        DELIVERY_ADDR_SUBMITTED_REDELIVERY_ORDER: 'You will be able to change your address the day after your current order has been fulfilled.',
        DELIVERY_ADDR_WITHIN_CUTOFF_LIMIT: 'The cutoff time for your current order is approaching. You can change your address the day after your order is received.',
        CART_CANNOT_BE_MODIFIED: 'We are having some technical issues right now. Please check back later.'
      },
      accountInit: {
        existingUsername: `This username already exists. For help, call ${phone} and give this reference code:`
      },
      login: {
        LOGIN_INPUT_INVALID: 'Invalid username or password. Please try again.',
        LOGIN_FAILURE: 'Invalid username or password. Please try again.',
        LOGIN_INACTIVE: `Your account is currently not active. Please call ${brandName} Customer Care at ${phoneNumberLink} for assistance.`,
        LOGIN_UNCV: `We're sorry, the username and password you entered are no longer valid.  Sign up for a new account by closing this box & entering your zip code in the "New to ${brandName}?" box.`,
        LOGIN_ST_CL: `The store you've selected is currently unavailable. Contact our Customer Care Center by <a href="mailto:${email}">email</a> or phone, ${phoneAlt}(${phoneNumberLink}).`,
        STORE_CLOSED_PICKUP_UNAVAILABLE: `The store you've selected is currently unavailable. Contact our Customer Care Center by <a href="mailto:${email}" class="text-link">email</a> or phone, ${phoneAlt}(${phoneNumberLink}).`,
        LOGIN_LOCKOUT: 'Customer Care is looking into resolving your issue. Please wait a few minutes and try logging in again.',
        LOGIN_ERROR: `We were unable to log you into the ${brandName} store. Please call ${brandName} Customer Care at ${phoneNumberLink} for assistance.`,
        LOGIN_SCODEN: `We were unable to log you into the ${brandName} store. Please call ${brandName} Customer Care at ${phoneNumberLink} for assistance.`,
        SERVER_ERROR: `We were unable to log you into the ${brandName} store. Please call ${brandName} Customer Care at ${phoneNumberLink} for assistance.`,
        SU_USER_LOGIN_MISS_DLV_ST: 'We are unable to recognize the Username entered.',
        LOGIN_MIGR: `Your store is no longer available on Peapod.com.`,
        PRISM_ACCOUNT_EXISTS: 'Sorry there is no user with this username and password. Please try again.',
        NO_ATG_USER: 'Sorry there is no user with this username and password. Please try again.',
        LOGIN_INVALID_P: `Hey, looks like this is a Peapod account.`,
        CARD_BELONGS_TO_ANOTHER_ACCOUNT: 'Card belongs to another account.',
        NO_PRISM_ATG_ACC_EXISTS: 'This user does not have a Prism account, they do have an ATG account. Superuser cannot login to an ATG account. This user must complete site transition.',
        INVALID_ATG_PASS: 'This password is incorrect. Click Forgot Password to reset.',
        LOGIN_INVALID: 'The sign in information you entered does not match our records. Please re-enter your information and try again.',
        RELOGIN_INVALID: 'The sign in information you entered does not match our records. Please re-enter your information and try again.',
        ATG_COMM_ERROR: `We were unable to log you in. Please call Peapod Customer Care at ${partials.phoneNumberLink(phone)} for assistance.`,
        CART_CANNOT_BE_MODIFIED: 'We’re having trouble signing you in right now. Please try again later.'
      },
      forgot: {
        passwordSuccess: 'An email has been sent to the primary email address for this account. You will receive a link to reset your password shortly.',
        usernameSuccess: 'We\'ve just sent you an email with your username.',
        PARAMETER_BAD_FORMAT: 'Please enter a valid email address.',
        FORGOT_PASSWORD_USERNAME_NOT_FOUND: 'This username was not found in our records',
        FORGOT_PASSWORD_EMAIL_NO_MATCH: 'This email does not match the entered username',
        FORGOT_USERNAME_EMAIL_NOT_FOUND: 'This email was not found in our records'
      },
      creditCard: {
        internalServerError: `We are unable to process your payment information at this time. Try again in a few minutes, or contact us at ${phoneNumberLink} for assistance.`,
        success: 'You can use this card for your next order. The payment method used for any pending orders will not change.',
        preferredError: 'Card was successfully saved. Something went wrong saving your preferred payment method. Please try again.'
      },
      checking: {
        success: 'You can use your checking account for your next order. The payment method used for any pending orders will not change.',
        preferredError: 'Checking Account was successfully saved. Something went wrong saving your preferred payment method. Please try again.'
      },
      productRequest: {
        descriptionRequired: 'Item description required',
        categoryRequired: 'Category required'
      },
      resetPassword: {
        RESET_PASSWORD_INVALID_TOKEN: 'Your password link has expired. Please request another password reset link.',
        RESET_PASSWORD_ERROR: 'An unknown error has occurred. Please try again.',
        RESET_PASSWORD_MATCHES_OLD_PASSWORD: 'Password must not be a previously used password.',
        RESET_PASSWORD_USER_PW_MATCH: 'Password must not be a previously used password.',
      }
    },
    fields: {
      generic: {
        required: 'This field is required.',
        format: 'Please enter a valid value.'
      },
      username: {
        required: 'Username is required.',
        format: 'Please enter a valid email address.',
        invalid: 'Email address invalid.',
        match: 'Usernames must match.',
        existing: 'Username is already in use.',
        confirm: 'Please confirm your username.'
      },
      email: {
        required: 'Email address is required.',
        format: 'Please enter a valid email address.',
        unique: 'This email address is already in use. Please try another.',
        loyaltyUnique: 'The email address is already linked to another loyalty card.',
        maxLength: `Use ${MAX_EMAIL_LENGTH} characters or less.`,
        match: 'Email addresses must match.',
        USER_EMAIL_IN_USE: 'This email address is already in use. Please try another.',
      },
      password: {
        required: 'Password is required.',
        match: 'Passwords must match.',
        confirm: 'Please confirm your password.',
        notUsername: 'Password must not match username.'
      },
      firstName: {
        required: 'First name is required.',
        format: 'Please enter a first name using only letters, apostrophes, or hyphens.'
      },
      lastName: {
        required: 'Last name is required.',
        format: 'Please enter a last name using only letters, apostrophes, or hyphens.'
      },
      phone: {
        required: 'Phone number is required.',
        format: 'Please enter a valid 10-digit phone number.',
        unique: 'This phone number is already in use.'
      },
      phoneExt: {
        format: 'Please enter a valid phone extension with digits only.'
      },
      time: {
        format: 'Time format invalid. Enter Time in this format: HH:MM am/pm.'
      },
      date: {
        format: 'Date format invalid. Enter Date in this format: DD/MM/YYYY.'
      },
      cardNumber: {
        required: 'Card number is required.',
        format: 'Please enter a valid card number.'
      },
      creditCard: {
        required: 'Card number is required.',
        format: 'Please enter a valid credit card number.'
      },
      ebtCardNumber: {
        invalid: 'Incorrect EBT card details. Please make sure you entered the correct information and try again.',
        required: 'EBT card number is required.',
        format: 'Enter a 19-digit number using numerals only.'
      },
      bankName: {
        required: 'Bank name is required.'
      },
      expMonth: {
        required: 'Month required.'
      },
      expYear: {
        required: 'Year required.'
      },
      cvCode: {
        required: 'Code is required.',
        format: 'Numbers only.',
        minLength: '3-4 digits.',
        maxLength: '3-4 digits.',
      },
      cardHolderName: {
        required: 'Cardholder name is required.',
        format: 'Please enter a cardholder name using only letters, apostrophes, or hyphens.'
      },
      cardNickName: {
        format: 'Please enter a card nickname using only letters, apostrophes, or hyphens.'
      },
      exp: {
        expired: 'Expiration is too old.'
      },
      bankAccountNumber: {
        required: 'Account number is required.',
        match: 'Account numbers must match.',
        format: 'Please enter a valid account number.',
        confirm: 'Please confirm your account number.'
      },
      routingNumber: {
        required: '9-digit routing number required.',
        format: 'Please enter a valid 9-digit routing number.'
      },
      userAgreement: {
        required: 'User agreement is required.'
      },
      emailSubject: {
        required: 'Please select a reason.'
      },
      contactUsError: {
        required: 'Sorry, We are facing some technical difficulties.'
      },
      companyName: {
        required: 'Company name is required.',
        format: 'Please enter a valid company name.'
      },
      comments: {
        required: 'Please enter some comments',
        maxLength: length => `Invalid comment length. Please enter a maximum of ${length} characters`,
        format: 'Please enter alphanumeric characters only'
      },
      address: {
        required: 'Street address is required.',
        format: 'Please enter a valid street address.',
        minLength: 'Street address is too short.'
      },
      additionalAddress: {
        format: 'Please enter valid address details.',
        minLength: 'Street address is too short.'
      },
      state: {
        required: 'State is required.'
      },
      city: {
        required: 'City is required.',
        format: 'Please enter a valid city.'
      },
      zip: {
        required: 'Zip code is required.',
        format: 'Please enter a valid 5-digit zip code.'
      },
      loyaltyZip: {
        required: 'Zip code is required.',
        format: 'Please enter a valid zip code in either a 5-digit or 9-digit format (for example 12345 or 12345-6789).'
      },
      orderTip: {
        DRIVER_TIP_PAST_CUTOFF: 'Your order is past cutoff.',
        DRIVER_TIP_INVALID_AMT: 'This amount is invalid.',
        DRIVER_TIP_PERCENTAGE_INVALID: 'Please enter an amount less than $10,000.',
        DRIVER_TIP_TO_LARGE: 'Please enter an amount less than $10,000.',
        DRIVER_TIP_PERCENTAGE_NOT_UPDATED: 'There was an error updating driver tip.',
        NOT_DELIVERY_ORDER: 'This Order is not a delivery order.',
        ORDER_NOT_FOUND: `We apologize for the inconvenience this has caused. Please check back later. If the issue persists call Customer Care at ${phone}.`,
        ORDER_PAST_CUTOFF: {
          title: `Can't update`,
          text: 'Your order is past the cut-off time.',
          primaryButton: 'OK',
        },
        TIP_TOO_SMALL: {
          title: 'Please tip your driver',
          text: 'Increase the delivery driver tip amount to place the order.',
          primaryButton: 'Change Tip Amount',
          redText: 'The driver tip must be at least $0.02',
        },
        TIP_TOO_LARGE: {
          title: `Can't place the order`,
          text: `The delivery driver tip amount exceeds the maximum of $10,000 for online orders. Lower the driver tip amount to place the order or call ${phone} for assistance.`,
          primaryButton: 'Change Tip Amount',
          redText: 'The driver tip cannot exceed $10,000',
        }
      },
      promoCode: {
        PROMO_CODE_EXPIRED: 'This code is expired',
        PROMO_CODE_UNKNOWN_ERROR: 'We didn\'t recognize the code you entered',
        PROMO_CODE_ORDER_NAOT_AVAIL: 'This code is currently unavailable',
        PROMO_CODE_TOT_QTY_EXCEED: 'Allowed redemptions for this code have been exceeded',
        PROMO_CODE_ALREADY_REDEEMED: 'This code has already been redeemed',
        PROMO_CODE_USER_QTY_EXCEED: 'The allowed redemptions for this code have been exceeded',
        PROMO_CODE_INVALID_BUS_RULE: 'This code is unavailable due to restrictions',
        PROMO_CODE_REFER_CODE_REDEEMED: 'This code has already been redeemed',
        PROMO_CODE_VIP_EXISTS: 'This code has already been redeemed',
        PROMO_CODE_USER_OUT_SD: 'This code is invalid',
        PROMO_CODE_PAST_CUTOFF: 'This code is no longer valid',
        PROMO_CODE_VIP_NOT_FIRST: 'This code is valid for first orders only',
        PROMO_CODE_INVALID: 'This code is invalid',
        required: 'Promo code required'
      },
      giftCardCartEntry: {
        GIFT_CARD_INVALID: 'Please check your card number and try again.',
        GIFT_CARD_INVALID_REEDEM_AMT: 'This amount is invalid',
        GIFT_CARD_REDEEM_GT_AVAIL: 'This amount exceeds the card balance',
        GIFT_CARD_REDEEM_GT_ORD_TOT: 'The redemption amount can\'t exceed the order total',
        GIFT_CARD_SERVICE_ERROR: 'An error was encountered while processing this payment. Please try again.',
        DEFAULT: 'Something went wrong. Please try again.',
        required: 'Please enter a valid dollar amount'
      },
      giftCardStatusErrorMessages: {
        INSUFFICIENT_FUNDS: 'Amount to redeem exceeds gift card balance',
        MISSING_AMOUNT: 'Amount to redeem is null',
        AMOUNT_TOO_SMALL: 'Amount to redeem is <0.01',
        AMOUNT_TOO_LARGE: 'Amount to redeem exceeds order total',
        GIFT_CARD_INVALID: 'Gift card number is null',
        INVALID_GIFT_CARD_RESPONSE: 'Gift card balance is unknown (null)',
        ORDER_STATUS_INVALID: 'Order not WORKING or SUBMITTED - cannot be edited',
        DEFAULT: 'There was an error applying the gift card balance.'
      },
      paymentMethod: {
        PAYMENT_METHOD_INVALID: 'The payment method is invalid.',
        TIME_TO_CUTOFF_PAST: 'Your order is past cutoff.'
      },
      invoices: {
        INVOICE_SERVICE_DISABLED: 'Invoice service is currently disabled',
        GUEST_ACCOUNT: `Sorry, you are currently a guest and therefore have no orders.`,
        INVOICE_NO_DATA: 'We don\'t have any order invoices on file',
        INVOICE_GET_FAILURE: 'Sorry, we are having trouble retrieving all of your orders. Please try again later.'
      },
      customerCare: 'If this is a mistake, please call Customer Care',
      reviews: {
        rating: {
          ERROR_FORM_REQUIRED: 'A rating is required.'
        },
        title: {
          ERROR_FORM_PATTERN_MISMATCH: 'The review headline is invalid.',
          ERROR_FORM_PROFANITY: 'The review headline contains profanity.',
          ERROR_FORM_TOO_LONG: 'The review headline must be less than 50 characters.'
        },
        reviewtext: {
          ERROR_FORM_PATTERN_MISMATCH: 'The review is invalid.',
          ERROR_FORM_PROFANITY: 'The review contains profanity.',
          ERROR_FORM_TOO_LONG: 'The review must be less than 10000 characters.'
        }
      },
      purchaseOrder: {
        PAST_CUTOFF: 'Your order is past the cut-off time.',
        PO_INVALID: 'Purchase order numbers should be 1-20 characters long using only alpha-numeric values. Do not use special characters or symbols.',
        RESPONSE_NOT_FOUND: 'There was an error fetching the purchase order.'
      }
    },
    accountBalance: {
      PENDING_ORDER: `We're sorry, you currently have an outstanding order that has not been processed. Therefore,
      your account balance is not available at this time. Please try again later. If you have any questions,
      call Customer Care at ${phoneNumberLink}. Thank you.`,
      UNAVAILIBLE: `We're sorry, your account balance is not available at this time. Please try again later.
      If you have any questions, call Customer Care at ${phoneNumberLink}. Thank you.`,
      NO_DATA: `We're sorry, your account balance is not available at this time. Please try again later.
      If you have any questions, call Customer Care at ${phoneNumberLink}. Thank you.`,
      UNKNOWN_RETAILER: `The retailer for this user is unknown.`,
      GUEST_ACCOUNT: `We're sorry, but you are currently a guest and therefore have no balance.`,
      NO_BALANCE: `We're sorry, but you have no balance.`
    },
    checkout: {
      DEFAULT: {
        title: 'Error',
        text: 'There was a problem processing your checkout.',
        primaryButton: 'Continue',
        secondaryButton: ''
      },
      CHECKOUT_CUST_AGREE_NOT_CHECKED: {
        title: 'Error',
        text: `There was an error processing your order. Please call Customer Care at ${phone}.`,
        primaryButton: 'Continue',
        secondaryButton: ''
      },
      CHECKOUT_CUST_UNATAGREE_NOT_CHECKED: {
        title: 'Accept Terms and Conditions',
        text: 'Please accept the Unattended Delivery Terms and Conditions.',
        primaryButton: 'Continue',
        secondaryButton: ''
      },
      UNATTENDED_AGREEMENT_ACK_REQUIRED: {
        title: 'Accept Terms and Conditions',
        text: 'Please accept the Unattended Delivery Terms and Conditions.',
        primaryButton: 'Continue',
        secondaryButton: ''
      },
      CHECKOUT_CUST_ALCHL_NOT_CHECKED: {
        title: 'Certify Age',
        text: 'Please certify that you are over 21 years of age.',
        primaryButton: 'Continue',
        secondaryButton: ''
      },
      ALCOHOL_AGE_CONFIRMATION_REQUIRED: {
        title: 'Certify Age',
        text: 'Please certify that you are over 21 years of age.',
        primaryButton: 'Continue',
        secondaryButton: ''
      },
      CHECKOUT_CUST_VENDOR_NOT_CHECKED: {
        title: 'Acknowledge Billing Confirmation',
        text: 'Please accept the Billing Confirmation statement.',
        primaryButton: 'Continue',
        secondaryButton: ''
      },
      EXTERNAL_VENDOR_BILLING_ACK_REQUIRED: {
        title: 'Acknowledge Billing Confirmation',
        text: 'Please accept the Billing Confirmation statement.',
        primaryButton: 'Continue',
        secondaryButton: ''
      },
      CHECKOUT_CUST_CART_EMPTY: {
        title: 'Cart is Empty',
        text: 'Your cart is empty. Please add items to continue.',
        primaryButton: 'OK',
        secondaryButton: ''
      },
      CHECKOUT_CUST_NO_SLOT: {
        title: 'Time Slot Expired',
        text: 'Please select a new time slot to continue.',
        primaryButton: 'Select a Time',
        secondaryButton: ''
      },
      CHECKOUT_CUST_CANNOT_SHOP: {
        title: 'There was an issue placing your order',
        text: `Please call ${phone} to resolve the issue and place your order.`,
        primaryButton: 'OK',
        secondaryButton: ''
      },
      CUSTOMER_CANNOT_SHOP: {
        title: 'There was an issue placing your order',
        text: `Please call ${phone} to resolve the issue and place your order.`,
        primaryButton: 'OK',
        secondaryButton: ''
      },
      CHECKOUT_CUST_MISSING_EMAIL: {
        title: 'Missing Email',
        text: `You do not have an email on file. Please review your account settings or call Customer Care at ${phone}.`,
        primaryButton: 'Continue',
        secondaryButton: ''
      },
      CHECKOUT_CUST_CARD_EXPIRED: {
        title: 'Update Credit/Debit Card',
        text: 'We need a valid credit/debit card to complete the transaction. Edit your selected credit/debit card or select a different payment method to place your order.',
        primaryButton: 'Edit Selected Card',
        secondaryButton: 'Use Other Method'
      },
      CHECKOUT_CUST_CARD_INVALID: {
        title: 'Update Credit/Debit Card',
        text: 'We need a valid credit/debit card to complete the transaction. Edit your selected credit/debit card or select a different payment method to place your order.',
        primaryButton: 'Edit Selected Card',
        secondaryButton: 'Use Other Method'
      },
      CHECKOUT_CUST_UNATTED_BLANK: {
        title: 'Enter Delivery Instructions',
        text: 'Please enter unattended delivery instructions for your driver.',
        primaryButton: 'Continue',
        secondaryButton: ''
      },
      CHECKOUT_CUST_CARD_EXPIRED_7DAYS: {
        title: 'Credit/Debit Card Expiring Within 7 Days',
        text: 'We need a valid credit/debit card on file in case there is an issue with your current payment method.',
        primaryButton: 'Update Credit/Debit Card',
        secondaryButton: ''
      },
      CHECKOUT_CUST_CARD_INVALID_TYPE: {
        title: 'Update Credit/Debit Card',
        text: 'We require a valid credit/debit card on file to complete the transaction.',
        primaryButton: 'Update Credit/Debit Card',
        secondaryButton: ''
      },
      CHECKOUT_CUST_CARD_NO_PAYMENT_METHOD: {
        title: 'Payment Method Needed',
        text: 'Add a credit/debit card to complete your order.',
        primaryButton: 'Add Credit/Debit Card',
        secondaryButton: ''
      },
      CHECKOUT_CUST_CARD_EXPIRED_ON_ALT_CHECKOUT: {
        title: 'Update Primary Card',
        text: 'We need a valid credit/debit card on file as your primary card to complete your order. Update your primary card or add a new credit/debit card to save as your primary card.',
        primaryButton: 'Edit Primary Card',
        secondaryButton: 'Add New Card'
      },
      CHECKOUT_CUST_CARD_EXPIRED_7DAYS_ON_ALT_CHECKOUT: {
        title: 'Primary Card Expiring Within 7 Days',
        text: 'We need a valid primary credit/debit card on file in case there is an issue with your selected payment method.',
        primaryButton: 'Update Primary Card',
        secondaryButton: ''
      },
      CHECKOUT_CUST_CARD_INVALID_ON_ALT_CHECKOUT: {
        title: 'Update Primary Card',
        text: 'We need a valid credit/debit card on file as your primary card to complete your order. Update your primary card or add a new credit/debit card to save as your primary card.',
        primaryButton: 'Edit Primary Card',
        secondaryButton: 'Add New Card'
      },
      CHECKOUT_CUST_CARD_INVALID_TYPE_ON_ALT_CHECKOUT: {
        title: 'There was a problem processing your checkout.',
        text: 'We require a valid credit/debit card on file for your primary card to complete the transaction.',
        primaryButton: 'Continue',
        secondaryButton: ''
      },
      CHECKOUT_CAPSID_NOT_FOUND: {
        title: 'Cannot Place Order',
        text: `There was an error processing your order. Please call Customer Care at ${phone}.`,
        primaryButton: 'Continue',
        secondaryButton: ''
      },
      MERCHANT_CAPSID_NOT_FOUND: {
        title: 'Cannot Place Order',
        text: `There was an error processing your order. Please call Customer Care at ${phone}.`,
        primaryButton: 'Continue',
        secondaryButton: ''
      },
      CHECKOUT_CUST_PAYMENT_METHOD_NOT_AUTHED: {
        title: 'Cannot Place Order'
      },
      CHECKOUT_CUST_CARD_INSUFFICIENT_FUNDS: {
        title: 'Insufficient balance for this order',
        text: 'The payment method on file doesn’t have sufficient balance for this order. Please update your payment information.',
        primaryButton: 'Ok',
        secondaryButton: ''
      },
    },
    slotSelection: `Your selected time isn't reserved until you check out. You can update your order after checkout.`
  }
}

export default {
  namespaced: true,
  state() {
    return initialState({})
  },
  getters: {
    messages: state => (message) => {
      let response = state
      if (message) {
        const arr = message.split('.')
        response = arr.reduce((acc, currentValue) => {
          acc = (!acc[currentValue]) ? undefined : acc[currentValue]
          return acc
        }, response)
      }
      return response
    },
    messagesRaw: state => (JSON.stringify(state)),
    required: state => name => (name ? `${capitalize(name)} is required.` : state.fields.generic.required),
    format: state => id => (state.fields[id]?.format ? `${state.fields[id].format}` : state.fields.generic.format),
    minLength: () => value => `Use at least ${value} characters.`,
    maxLength: () => value => `Use at most ${value} characters.`,
    slotSelectionNotice: state => `${state.slotSelection}`
  },
  mutations: {
    setStoreData(state) {
      const payload = {
        phone: this.getters['SiteConfig/brandContactNumber'],
        email: this.getters['SiteConfig/varByName']('brand_help_email'),
        brandName: this.getters['SiteConfig/varByName']('brand_name')
      }

      _.each(initialState(payload), (value, key) => {
        state[key] = value
      })
    }
  }
}
