/* eslint-disable */
import { isDefined, isObject } from 'utils/basic/ref-checkers'
import { BASE_PAGE_URL, LOGIN_PAGE, REGISTRATION_PAGE, OPCO_ID, OPCO_THEME } from 'utils/constants'
import initialLocation from 'components/global/services/initial-location'
import { navigateTo } from 'utils/basic/url'
import { getAppName } from 'utils/basic/dom'
import { getStore } from 'utils/basic/vue'
import queryString from 'shared/util/queryString'
import queryData from 'shared/util/queryData'
import queryStringFromQueryData from 'shared/util/queryStringFromQueryData'
import combineQueryData from 'shared/util/combineQueryData'

const isUserApp = () => getAppName() === 'userApp'
const isSuperUserApp = () => getAppName() === 'superUserApp'
const isUserOnGateway = () => document.getElementById('gatewayApp') !== null

const initialLocationHasPath = ({ path, query } = {}) => (!!path && path !== '/') || isDefined(query?.redirectTo)
const initialLocationHasQuery = ({ query } = {}) => isObject(query)

const getPathname = pathname => pathname.replace(/(;)*$/, '')
const getPathString = ({ path = '' } = {}) => path

const getVersionQueryString = (search) => {
  const versionQueryVal = queryString('v', search)
  return versionQueryVal === null ? '' : `v=${versionQueryVal}`
}

const isLoginEnabled = () => ((OPCO_ID !== 'PPOD' || OPCO_THEME !== 'PPOD') || isSuperUserApp())

/**
 * Returns a combined query string from the supplied arguments.
 *
 * @param search - normal '?'-prepended query string
 * @param baseLocation - object with query child object: { name: 'value' }
 * @param extraParams - any additional params to add
 * @returns {string|string} - a normal '?'-prepended query string
 */
const baseGetCombinedQueryString = (search, baseLocation = {}, extraParams = {}) => {
  const versionQueryString = getVersionQueryString(search)
  const versionQueryData = queryData(versionQueryString)
  const query = isObject(baseLocation?.query) ? baseLocation.query : {}
  const combinedQueryData = combineQueryData(query, versionQueryData, extraParams)
  const parsedQueryString = queryStringFromQueryData(combinedQueryData)
  return parsedQueryString ? `?${parsedQueryString}` : ''
}

/**
 * Parses multiple items along with window.location.search into a single query string.
 *
 * @param initialLocation
 * @param extraParams
 * @returns {string|string} - a normal '?'-prepended query string
 */
const getCombinedQueryString = (initialLocation, extraParams) => {
  return baseGetCombinedQueryString(window.location.search, initialLocation, extraParams)
}

/**
 * Can be called after a successful logout.
 */
 const onLogoutResponse = () => {
  const $store = getStore()
  const isMobileApp = $store.getters['NativeContainer/isMobileApp']
  const isGatewayDisabled = !$store.getters['SiteConfig/varByName']('domain_config_gateway_enabled')

  // Note: super user cannot go home after customer logout. Home has a redirect to super-user login
  if (isSuperUserApp() || !isGatewayDisabled && isMobileApp) return redirectToLogin()
  if (isGatewayDisabled) return redirectToHome()
  return redirectToGateway()
}

/**
 * Redirects based on the specified deepLinkTo
 *
 * @param deepLinkTo
 * @returns {Promise<void>}
 * @private
 */
const baseRedirectToHome = async (deepLinkTo) => {
  const queryString = getCombinedQueryString()
  if (deepLinkTo === 'PUP') {
    const $store = getStore({ throwError: true }) // Throw explicit error instead of later error.
    await $store.dispatch('Order/initOrderStatus')

    const currentOrder = $store.getters['Order/currentOrder']
    navigateTo((currentOrder?.status === 'SUBMITTED' && currentOrder?.serviceType === 'P')
      ? '/modal/pickupPreferences'
      : BASE_PAGE_URL + queryString)
  } else {
    navigateTo(`${BASE_PAGE_URL}${queryString}`)
  }
}

/**
 * Navigates to the home page.
 *
 * @returns {Promise<void>}
 */
const redirectToHome = () => {
  return baseRedirectToHome(window.sessionStorage.getItem('deeplinkTo'))
}

/**
 * Navigates to the specified searchQuery.
 *
 * @param searchQuery
 * @returns {*}
 */
const redirectToSearch = searchQuery => navigateTo(searchQuery)

/**
 * Redirects to the home page, but with an error code.
 *
 * @param errorCode
 */
const redirectToHomeWithErrorCode = (errorCode) => {
  const queryString = getCombinedQueryString(null, { error: errorCode })
  navigateTo(`${BASE_PAGE_URL}${queryString}`)
}

/**
 * Redirects to home, but with an initialLocation specified.
 *
 * @param host
 * @param search
 * @param baseLocation
 * @param extraParams
 * @returns {Promise<void>}
 * @private
 */
const baseRedirectToHomeWithInitialLocation = ({ host, search }, baseLocation, extraParams) => {
  // START: only allow redirects if the redirectTo url param is the same as our sites
  const hostArr = host.split('.')
  const urlParams = new URLSearchParams(search)
  const redirectParam = urlParams.get('redirectTo')
  const hostString = hostArr.slice(hostArr.length - 2).join('.')
  const hostMatchRedirect = !redirectParam ? false : redirectParam.includes(hostString)
  // END: if redirectTo does not match host then goes to else and redirects to homepage
  if ((initialLocationHasPath(baseLocation) || initialLocationHasQuery(baseLocation)) && hostMatchRedirect) {
    let path = getPathString(baseLocation)
    if (isDefined(redirectParam)) {
      path = redirectParam
      delete baseLocation.query.redirectTo
    }
    const combinedQueryString = getCombinedQueryString(baseLocation, extraParams)
    navigateTo(`${path}${combinedQueryString}`)
  } else {
    return redirectToHome()
  }
}

/**
 * Navigates to home, but with an initialLocation specified.
 *
 * @param extraParams
 * @returns {Promise<void>}
 */
const redirectToHomeWithInitialLocation = (extraParams) => {
  return baseRedirectToHomeWithInitialLocation(window.location, initialLocation, extraParams)
}

/**
 * Navigates to the login page.
 */
const redirectToLogin = () => {
  if (isLoginEnabled()) {
    const combinedQueryString = getCombinedQueryString()
    const nextRoute = `${BASE_PAGE_URL}${LOGIN_PAGE}${combinedQueryString}`
    navigateTo(nextRoute)
  }
}

/**
 * Navigates to login with the specified initialLocation.
 *
 * @param baseLocation
 * @param extraParams
 * @private
 */
const baseRedirectToLoginWithInitialLocation = (baseLocation, extraParams) => {
  if (
    initialLocationHasPath(baseLocation)
    || initialLocationHasQuery(baseLocation)
    || isObject(extraParams)
  ) {
    const path = getPathString(baseLocation)
    const combinedQueryString = getCombinedQueryString(baseLocation, { ...extraParams, redirectTo: path })
    navigateTo(`${BASE_PAGE_URL}${LOGIN_PAGE}${combinedQueryString}`)
  } else {
    redirectToLogin()
  }
}

/**
 * Navigates to the login page, but with the current initialLocation.
 *
 * @param extraParams
 */
const redirectToLoginWithInitialLocation = (extraParams) => {
  return baseRedirectToLoginWithInitialLocation(initialLocation, extraParams)
}

const redirectToRegistration = () => {
  const { host, protocol } = window.location
  const basePage = `${protocol}//${host}`
  return navigateTo(`${basePage}${REGISTRATION_PAGE}`)
}

/**
 * Navigates to gateway page.
 */
const redirectToGateway = () => {
  const queryString = getCombinedQueryString()
  navigateTo(`${BASE_PAGE_URL}${queryString}`)
}

/**
 * Navigates to login with the specified errorCode.
 *
 * @param errorCode
 */
const redirectToLoginWithErrorCode = (errorCode) => {
  const queryString = getCombinedQueryString(null, { error: errorCode })
  navigateTo(`${BASE_PAGE_URL}${LOGIN_PAGE}${queryString}`)
}



/**
 * These methods are exported for testing, but are not intended to be used.
 * Use the public methods to access this functionality.
 * @private
 */
const internal = {
  baseGetCombinedQueryString,
  baseRedirectToHome,
  baseRedirectToHomeWithInitialLocation
}

export {
  internal,

  isUserApp,
  isSuperUserApp,
  isUserOnGateway,
  isLoginEnabled,

  initialLocationHasPath,
  initialLocationHasQuery,

  onLogoutResponse,

  redirectToRegistration,
  redirectToHome,
  redirectToSearch,
  redirectToHomeWithErrorCode,
  redirectToHomeWithInitialLocation,
  redirectToLogin,
  redirectToLoginWithInitialLocation,
  redirectToGateway,
  redirectToLoginWithErrorCode,

  getPathname,
  getPathString,
  getVersionQueryString,
}
/* eslint-enable */
