import { NOT_NUMBER, NOT_NUMBER_OR_LETTER } from 'utils/regex'

const removeCountryCode = (tel) => {
  if (tel.length >= 11 && tel.indexOf('1') === 0) {
    tel = tel.substring(1)
  }
  return tel
}

const formatNotNumber = tel => tel.replace(new RegExp(NOT_NUMBER), '')

const formatExtension = (ext) => {
  const notExtension = new RegExp(NOT_NUMBER_OR_LETTER)
  if (_.isUndefined(ext) || _.isNull(ext)) {
    ext = null
  } else {
    ext = ext.replace(notExtension, '')
  }
  return ext
}

export default function (tel, ext) {
  if (!tel || tel.length < 10) {
    return {}
  }
  ext = formatExtension(ext)
  tel = formatNotNumber(tel)
  tel = removeCountryCode(tel)

  return {
    areaCode: tel.substring(0, 3),
    prefix: tel.substring(3, 6),
    suffix: tel.substring(6),
    ext
  }
}
