<template>
  <PdlNavItemBase
    v-if="!isHiddenItem"
    :id-prefix="idPrefix"
    :list-item-class="listItemClass"
    :button-class="buttonClass"
    :link-class="linkClass"
    :url="url"
    :label="label"
    :link-type="linkType"
    :is-active="isActive"
    :is-new-nav-design-enabled="isNewNavDesignEnabled"
    @nav-item-base-clicked="onNavItemClick"
  >
    {{ label }}
    <span
      v-if="hasBadge"
      :class="['nav-item_badge',
        {'rounded-full py-[0.2rem] px-2' : isNewNavDesignEnabled }
      ]"
    >
      {{ badgeAmount }}
    </span>
  </PdlNavItemBase>
</template>

<script>
import PdlNavItemBase from './PdlNavItemBase'

export default {
  components: {
    PdlNavItemBase
  },
  props: {
    listItemClass: {
      type: [String, Array],
      default: 'nav-item'
    },
    buttonClass: {
      type: [String, Array],
      default: 'nav-item_button'
    },
    linkClass: {
      type: [String, Array],
      default: 'nav-item_link'
    },
    url: {
      type: String,
      default: ''
    },
    hasBadge: {
      type: Boolean,
      default: false
    },
    badgeAmount: {
      type: Number,
      default: 0
    },
    label: {
      type: String,
      default: ''
    },
    linkType: {
      type: String,
      default: 'Internal'
    },
    idPrefix: {
      type: String,
      default: 'nav-account-menu'
    },
    isHiddenItem: {
      type: Boolean,
      default: false
    },
    isNewNavDesignEnabled: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      default: 0,
    }
  },
  data() {
    return {
      isActive: false
    }
  },
  methods: {
    onNavItemClick(label, url, linkType, elementId) {
      // Prevent default here instead of template so that
      // anchors still link when there is no click handler.
      // Do not prevent default for external links because
      // they should still open the external page.
      this.isActive = !this.isActive
      this.$emit('nav-item-clicked', label, url, linkType, elementId)
      this.$emit('track-nav-updated', {
        label,
        url,
        index: this.index + 1,
        elementId
      })
    }
  }
}
</script>
