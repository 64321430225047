<template>
  <div class="field">
    <PdlInputLabel
      v-bind="labelProps"
    >
      <slot name="label" />
    </PdlInputLabel>
    <slot
      class="field_row"
      name="before"
    />
    <div
      class="field_row"
      :class="{'relative': type === 'password'}"
    >
      <input
        v-bind="attrs"
        :id="id"
        :value="modelValue"
        :ref="'input'"
        :name="name"
        :aria-label="ariaLabel"
        :form-id="formId"
        :type="computedType"
        :pattern="computedPattern"
        class="forms_input"
        :class="inputClasses"
        :disabled="disabled"
        :aria-describedby="hasMessages ? `${id}-messages` : null"
        @keyup.enter="enterClicked"
        @input="triggerInput"
      >
      <button
        v-if="type === 'password'"
        class="button forms_password-toggle-button"
        :class="[
          { 'button--light-grey': opco === 'PPOD' },
          { 'button--white-no-border': opco !== 'PPOD' },
          { 'forms_password-toggle-button--light': opco !== 'PPOD' }
        ]"
        type="button"
        :aria-label="`${passwordToggleLabel} Password`"
        @click="showPassword = !showPassword"
      >
        {{ passwordToggleLabel }}
      </button>
    </div>
    <slot
      class="field_row"
      name="after"
    />
    <InputMessages
      :id="`${id}-messages`"
      class="field_row"
      :errors="errors"
      :description="description"
      :has-errors="hasErrors"
      :has-persistent-messages="hasPersistentMessages"
      :persistent-messages="persistentMessagesList"
    />
  </div>
</template>
<script>
import focusRef from 'utils/mixins/focusRef'
import InputBase from './PdlInputBaseMixin'

export default {
  mixins: [InputBase, focusRef],
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    opco: {
      type: String,
      default: '',
      required: true
    },
    isMobileDevice: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: [String, Number],
      default: ''
    },
  },
  data() {
    return {
      showPassword: false
    }
  },
  computed: {
    computedPattern() {
      if (this.pattern) {
        return this.pattern
      }
      return this.type === 'number' || this.type === 'tel' ? `\\d*` : ''
    },
    computedType() {
      if (this.type === 'password' && this.showPassword) {
        return 'text'
      }
      return this.type
    },
    passwordToggleLabel() {
      return this.showPassword ? 'Hide' : 'Show'
    },
  },
  methods: {
    enterClicked($event) {
      const uagent = navigator.userAgent.toLowerCase()
      if (this.isMobileApp || uagent.includes('mobile')) {
        this.setFocusonNext($event)
      }
    }
  },
  emits: ['change', 'update:modelValue']
}
</script>
