import ApiService from '@/api/'

export default function (userId, serviceLocationId, isNewTopKeywordServices, size) {
  const params = {
    size
  }
  if (isNewTopKeywordServices) {
    return ApiService.get(`/api/v5.0/user/topkeywords/${userId}/${serviceLocationId}`, { params })
  }
  return ApiService.get('/api/v3.0/user/topKeywords', { params })
}
