export default {
  namespaced: true,
  state: {
    facets: {},
    currentFilters: [],
    categorySearchValue: {},
    collectionCache: {
      'past-purchases': {
        productData: {},
        products: [],
        currentFilters: []
      },
      'product-search': {
        productData: {},
        products: [],
        currentFilters: [],
        sortString: '',
        logData: {}
      },
      'all-specials': {
        productData: {},
        products: [],
        currentFilters: []
      },
      'browse-aisles': {
        productData: {},
        products: [],
        currentFilters: [],
        categorySearchValue: {}
      }
    }
  },
  mutations: {
    setFacets(state, payload) {
      state.facets = payload
    },
    addFilter(state, payload) {
      // filter may already exist if back button press
      const filterExists = !!state.currentFilters.find(filter => filter.value === payload.value)
      if (!filterExists) {
        state.currentFilters.push(payload)
      }
    },
    currentFilters(state, payload) {
      state.currentFilters = payload
    },
    categorySearchValue(state, payload) {
      state.categorySearchValue = payload
    },
    setPastPurchasesCache(state, payload) {
      state.collectionCache['past-purchases'] = payload
    },
    setOmnisearchCache(state, payload) {
      state.collectionCache['product-search'] = payload
    },
    setAllSpecialsCache(state, payload) {
      state.collectionCache['all-specials'] = payload
    },
    setBrowseAislesCache(state, payload) {
      state.collectionCache['browse-aisles'] = payload
    },
    clearCollectionCache(state) {
      state.collectionCache = {
        'past-purchases': {
          productData: {},
          products: [],
          currentFilters: []
        },
        'product-search': {
          productData: {},
          products: [],
          currentFilters: [],
          sortString: '',
          logData: {}
        },
        'all-specials': {
          productData: {},
          products: [],
          currentFilters: []
        },
        'browse-aisles': {
          productData: {},
          products: [],
          currentFilters: [],
          categorySearchValue: {}
        }
      }
    }
  }
}
