import { GTM } from 'utils/constants'
import { setupProductDetails } from './helpers'

const RECIPES = {
  FAVORITES: 'favorites',
  ORDER_STARTER: 'order starter',
}

const mapProductsForCartBulkChange = (products, direction) => {
  const vueInstance = window.sharedVue.config.globalProperties
  return _.map(products, ({
    newQty, previousQty, flags, bmsmTiers, name, prodId, price, brand, rootCatName, size, bmsmPodGroupId
  }) => {
    const quantity = (direction === 'add') ? (newQty - previousQty)
      : previousQty - newQty
    const isOnSale = (flags) ? flags.sale : false
    const discountName = (!!bmsmTiers && !!bmsmTiers[0]) ? bmsmTiers[0].description
      : false

    const productDetails = {
      name,
      id: prodId,
      price,
      quantity,
      brand,
      category: rootCatName,
      constiant: size,
      dimension37: isOnSale
    }

    if (discountName) {
      productDetails.offerType = 'bmsm'
      const completedBmsmGroup = vueInstance.$store.state.ProductTile.bmsmCompletedGroupIds
      const isCompleted = completedBmsmGroup.includes(bmsmPodGroupId)
      productDetails.dimension78 = discountName
      productDetails.offerLimitReached = isCompleted
    }

    return productDetails
  })
}

export const trackMiniProductImpression = ({ sourceInfo = {} } = {}) => {
  const vueInstance = window.sharedVue.config.globalProperties
  const {
    recommendationAlgorithm,
    listType,
    location,
    addToCartLocationdetail,
    searchTerm
  } = sourceInfo
  const tagData = {
    ecommerce: {
      currencyCode: 'USD'
    },
    ...(recommendationAlgorithm && { recommendationAlgorithm }),
    ...(listType && { listType }),
    ...(location && { location }),
    ...(addToCartLocationdetail && { addToCartLocationdetail }),
    ...(searchTerm && { searchTerm })
  }
  vueInstance.$trackGtmEvent(GTM.miniListImpression, tagData)
}

export const trackProductExpand = ({ products, sourceInfo = {} } = {}) => {
  const vueInstance = window.sharedVue.config.globalProperties
  const {
    personalizationImpressionFlag,
    recommendationAlgorithm,
    modelId,
    invocationId,
    listType,
    expandClickLocation,
    originalItemId,
    addToCartLocationdetail,
    searchTerm
  } = sourceInfo

  const tagData = {
    ecommerce: {
      currencyCode: 'USD',
      ...(products && { impressions: products })
    },
    personalizationImpressionFlag,
    ...(recommendationAlgorithm && { recommendationAlgorithm }),
    ...(modelId && { modelId }),
    ...(invocationId && { invocationId }),
    ...(listType && { listType }),
    ...(expandClickLocation && { expandClickLocation }),
    ...(originalItemId && { originalItemId }),
    ...(addToCartLocationdetail && { addToCartLocationdetail }),
    ...(searchTerm && { searchTerm })
  }
  vueInstance.$trackGtmEvent(GTM.productExpand, tagData)
}
export const trackProductDetail = (product) => {
  const vueInstance = window.sharedVue.config.globalProperties
  const productDetails = setupProductDetails(product)
  vueInstance.$trackGtmEvent(GTM.productDetailView, {
    ecommerce: {
      currencyCode: 'USD',
      detail: {
        actionField: {},
        products: [
          productDetails
        ]
      }
    },
    indexPosition: product.position || -1
  })
}

export const trackCartBulkChange = ({ direction, cart }) => {
  const vueInstance = window.sharedVue.config.globalProperties
  const eventId = (direction === 'remove') ? GTM.removeFromCart : GTM.addToCart

  const products = mapProductsForCartBulkChange(cart.products, direction)

  const tagData = {
    ecommerce: {
      currencyCode: 'USD'
    },
    ...(cart.recommendationAlgorithm && { recommendationAlgorithm: cart.recommendationAlgorithm }),
    ...(cart.listType && { listType: cart.listType }),
    ...(cart.modelId && { modelId: cart.modelId }),
    ...(cart.invocationId && { invocationId: cart.invocationId }),
  }

  if (direction === 'add') {
    tagData.ecommerce.actionField = {}
    tagData.addToCartLocation = cart.logLocation
    const recipe = RECIPES[cart.logLocation]
    if (recipe) {
      tagData.recipeAddToCart = true
      tagData.recipeName = recipe
    }
  }

  if (cart.personalizationActionFlag !== undefined) {
    tagData.personalizationActionFlag = cart.personalizationActionFlag
  }
  tagData.ecommerce[direction] = {
    products
  }

  vueInstance.$trackGtmEvent(eventId, tagData)
}

export const trackProductBadgeImpression = (product, sourceInfo) => {
  const vueInstance = window.sharedVue.config.globalProperties
  const payload = {
    itemId: product.prodId,
    itemName: product.name,
    itemPrice: product.price,
    itemCategory: product.rootCatName,
    itemBrand: product.brand,
    productListName: sourceInfo?.productListName,
    indexPosition: sourceInfo?.indexPosition,
    itemBadge: product.healthAndLifestyleAttributeText,
    itemAttributes: sourceInfo?.itemAttributes
  }
  vueInstance.$trackGtmEvent(GTM.productBadgeImpression, payload)
}
