import ApiService from '@/api/'

export default {
  namespaced: true,
  state: {
    loading: true,
    bulkAddLoading: false,
    favoritesListId: null,
    activeBMSMProduct: {},
    oosProduct: {},
    activeView: 'grid',
    imageFailed: false,
    bmsmCompletedGroupIds: [],
    ppTime: '',
    optlyShowCarousel: false
  },
  getters: {
    loading: state => state.loading,
  },
  mutations: {
    setLoading(state, value) {
      state.loading = value
    },
    setBulkAddLoading(state, value) {
      state.bulkAddLoading = value
    },
    setBMSMProduct(state, value) {
      state.activeBMSMProduct = value
    },
    setFavoritesListId(state, value) {
      state.favoritesListId = value
    },
    setActiveView(state, value) {
      state.activeView = value
    },
    setImageFailed(state, value) {
      state.imageFailed = value
    },
    setBMSMGroupIDs(state, value) {
      state.bmsmCompletedGroupIds = value
    },
    setPPTime(state, value) {
      state.ppTime = value
    },
    setOosProduct(state, value) {
      state.oosProduct = value
    },
    setOptlyShowCarousel(state, value) {
      state.optlyShowCarousel = value
    }
  },
  actions: {
    setBulkAddLoading({ commit }, payload) {
      commit('setBulkAddLoading', payload)
    },
    setFavoritesListId({ commit }, payload) {
      commit('setFavoritesListId', payload)
    },
    async queryOosProduct({ commit, rootGetters }, payload) {
      const userId = rootGetters['UserProfile/userId']
      const { serviceLocationId } = rootGetters['UserProfile/deliveryServiceLocation']
      const isNewProductApiServices = rootGetters['SiteConfig/varByName']('feature_category_product_new_services')
      let apiPath = `/api/v3.0/user/products/${payload}`
      if (isNewProductApiServices) {
        apiPath = `/api/v5.0/products/info/${userId}/${serviceLocationId}/${payload}`
      }
      const responseData = await ApiService.get(apiPath, {
        params: {
          flags: true
        }
      })
      if (responseData.status === 200) {
        const productInfo = responseData.data.response.products[0]
        commit('setOosProduct', productInfo)
      }
      return responseData
    },
    queryOosProductForServiceLocation({ rootGetters }, payload) {
      const userId = rootGetters['UserProfile/userId']
      const apiPath = `/api/v5.0/products/info/${userId}/${payload.serviceLocationId}/${payload.productId}`
      return ApiService.get(apiPath, {
        params: {
          flags: true
        }
      })
    },
    async removeFromPastPurchase({ rootGetters, commit }, payload) {
      const userId = rootGetters['UserProfile/userId']
      const response = await ApiService.delete(`/api/v4.0/user/${userId}/past-purchases`, {
        params: {
          productIds: payload.productIds,
          opco: payload.opco
        }
      })
      commit('setLoading', false)
      return response
    },
    updateCompletedBMSM({ commit, state, rootGetters }) {
      const cart = rootGetters['Cart/getCart']
      const bmsmSavingItems = cart?.savings?.buyMoreSaveMoreSavingsItems || {}

      if (bmsmSavingItems.length > 0) {
        const { items } = cart
        const groups = bmsmSavingItems.map((bmsm) => {
          let matchingItem = items.find(item => item.description === bmsm.displayText)
          if (typeof matchingItem === 'undefined') {
            matchingItem = items.find(item => item.name === bmsm.displayText)
          }
          return matchingItem.bmsmPodGroupId
        })
        commit('setBMSMGroupIDs', groups)
      }
      if (bmsmSavingItems.length === 0 && state.bmsmCompletedGroupIds.length > 0) {
        commit('setBMSMGroupIDs', [])
      }
    },
  }
}
