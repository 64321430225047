<template>
  <span
    class="loading-spinner"
    :class="inline ? 'loading': ''"
  >
    <div class="loading-spinner_container">
      <span class="spinner" />
      <span class="circle" />
      <p
        v-if="adaMessage"
        aria-live="polite"
        class="element-invisible"
      >
        {{ adaMessage }}
      </p>
    </div>
  </span>
</template>

<script>
export default {
  props: {
    adaMessage: {
      type: String,
      default: ''
    },
    inline: {
      type: [Boolean],
      default: false,
      required: false
    }
  }
}
</script>
