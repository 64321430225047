/** function formatPhoneField - format phone number for display
 *
 * @param phone - phone string or number to be formatted ex. (312) 555-4455
 * @return {Number} - ex. 3125554455
* */


export default function (phone) {
  if (!phone) return ''
  const regexToMatch = /(\d{0,3})(\d{0,3})(\d{0,4})/
  const phoneFormat = regexToMatch.exec(String(phone).replace(/\D/g, ''))
  const lastThreeDigits = `-${phoneFormat[3]}`
  const formatThree = phoneFormat[3] ? lastThreeDigits : ''
  return !phoneFormat[2]
    ? phoneFormat[1] : `(${phoneFormat[1]}) ${phoneFormat[2]}${formatThree}`
}
