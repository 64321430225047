import CommunicationsAPI from 'api/CommunicationsAPI'
import ConfirmRegistrationAPI from 'api/ConfirmRegistrationAPI'
import userProfileAPI from 'api/UserProfileAPI'
import phoneNumParts from 'utils/filters/phoneNumParts'
import { whichAlertMethod } from './helpers/CommunicationsHelpers'

const initialState = {
  orderChangeEmail: false,
  mailSubIds: [],
  availableSubscriptions: [],
  alertMethod: null,
  phone: {},
  paperLessInvoice: false,
  smsProviderOptedOut: false,
  optInMessageSent: false,
  orderAlertsLocation: '',
  digitalReceipts: false
}

export default {
  namespaced: true,
  state: {
    loading: false,
    loaded: false,
    ...initialState
  },
  getters: {
    serviceUpdates: state => state.mailSubIds.includes(101),
    weeklySpecials: state => state.mailSubIds.includes(102),
    orderStatus: state => state.mailSubIds.includes(104),
    serviceUpdatesAvailable: state => state.availableSubscriptions.includes(101),
    weeklySpecialsAvailable: state => state.availableSubscriptions.includes(102),
    orderStatusAvailable: state => state.availableSubscriptions.includes(104),
    phone: (state) => {
      const { phone } = state
      return `${phone.areaCode || ''}${phone.prefix || ''}${phone.suffix || ''}`
    },
  },
  mutations: {
    setLoaded(state, payload) { state.loaded = !!payload },
    setLoading(state, payload) { state.loading = payload },
    setAlertMethod(state, payload) { state.alertMethod = payload },
    setStoreData(state, payload) {
      _.each(payload, (value, key) => {
        if (_.has(state, key) && !_.isNull(value)) {
          state[key] = value
        }
      })
    },
    setOrderAlertsLocation(state, value) {
      state.orderAlertsLocation = value
    },
    setDigitalReceipts(state, value) {
      state.digitalReceipts = value === 'true' || value === true
    }
  },
  actions: {
    async getCommunications({ state, commit, rootGetters }) {
      let response = Promise.resolve({})
      if (!state.loading && !state.loaded) {
        commit('setLoading', true)
        const userId = rootGetters['UserProfile/userId']
        response = await CommunicationsAPI.getCommunications(userId)
        const success = response.status === 200
        const { alerts } = response.data.response
        if (success) {
          commit('setStoreData', alerts)
          commit('setLoaded', true) // Prevent reload of data
        }
      }

      commit('setLoading', false)
      return response
    },
    async updateCommunications({
      state, commit, dispatch, rootGetters
    }, data) {
      let response = Promise.resolve({})
      if (!state.loading) {
        commit('setLoading', true)
        const {
          orderChange, email, sms, phone
        } = data
        const payload = {
          orderChangeEmail: orderChange
        }
        const subs = {
          serviceUpdates: 101,
          weeklySpecials: 102,
          orderStatus: 104
        }
        payload.mailSubIds = Object.keys(subs).reduce((acc, key) => {
          if (data[key]) {
            acc.push(subs[key])
          }
          return acc
        }, [])

        payload.alertMethod = whichAlertMethod(email, sms)

        payload.phone = phone ? phoneNumParts(phone) : {}

        if (Object.hasOwn(data, 'deals')) {
          dispatch('LoyaltyAccount/updateLoyalty', {
            atgPrivacySetting: data.deals
          }, { root: true })
        }

        if (Object.hasOwn(data, 'digitalReceipts')) {
          dispatch('updateDigitalReceipts', data.digitalReceipts)
        }

        const userId = rootGetters['UserProfile/userId']

        response = await CommunicationsAPI.updateCommunications(payload, userId)

        const success = response.status >= 200 || response.status < 300
        if (success) {
          commit('setStoreData', payload)
          commit('setAlertMethod', payload.alertMethod)
        }
      }

      commit('setLoading', false)
      return response
    },
    async getDigitalReceipts({ commit, rootGetters }) {
      let response
      try {
        const userId = rootGetters['UserProfile/userId']
        response = await CommunicationsAPI.getDigitalReceipts(userId)
        const success = response.status >= 200 && response.status < 300
        if (!success) {
          throw response
        }
        commit('setDigitalReceipts', response.data?.prefVal)
        return response
      } catch (err) {
        return err
      }
    },
    async updateDigitalReceipts({ commit, rootGetters }, payload) {
      let response
      try {
        const userId = rootGetters['UserProfile/userId']
        response = await CommunicationsAPI.updateDigitalReceipts(userId, payload)
        const success = response.status >= 200 && response.status < 300
        if (!success) {
          throw response
        }
        commit('setDigitalReceipts', payload)
        return response
      } catch (err) {
        return err
      }
    },
    async submitFinalRegistration({ state, commit, rootGetters }, data) {
      let response = Promise.resolve({})
      if (!state.loading) {
        commit('setLoading', true)
        let alertMethod = null
        const {
          sms, email, termsAndConditionsAccepted
        } = data
        const userId = rootGetters['UserProfile/userId']

        alertMethod = whichAlertMethod(email, sms)

        const apiData = {
          value: termsAndConditionsAccepted,
          userId
        }
        response = await ConfirmRegistrationAPI.put(apiData)
        const success = response.status >= 200 || response.status < 300
        if (success) {
          // call Refresh user profile api as temp solution till we have session-Management goes live
          // this is to make sure session data is not stale for the user-registration process
          await userProfileAPI.refreshUserProfile(userId)
          commit('setAlertMethod', alertMethod)
        }
      }
      commit('setLoading', false)
      return response
    }
  }
}
