import getScript from 'utils/services/script-loader/get-script'
import {
  determineEnvironment,
  getEnvironmentAbbreviation
} from 'utils/services/graphql/environment'

const adobeConfigs = {
  dev: {
    url: 'https://assets.adobedtm.com/1e8ffcde074e/e0dfff12f41b/launch-58b4607541c6-development.min.js',
  },
  qa: {
    url: 'https://assets.adobedtm.com/1e8ffcde074e/e0dfff12f41b/launch-58b4607541c6-development.min.js',
  },
  stage: {
    url: 'https://assets.adobedtm.com/1e8ffcde074e/e0dfff12f41b/launch-09fcaeeece30-staging.min.js',
  },
  prod: {
    url: 'https://assets.adobedtm.com/1e8ffcde074e/e0dfff12f41b/launch-43b34c910ed6.min.js',
  },
}

const getEnvConfig = () => {
  const env = determineEnvironment(window.location.hostname, window.appConfig.opcoId)
  const envAbbrev = getEnvironmentAbbreviation(env)
  return adobeConfigs[envAbbrev]
}

const initAdobeAnalytics = () => {
  const { url } = getEnvConfig()

  getScript(url, {
    async: true,
  })
}

export {
  initAdobeAnalytics
}
