<template>
  <PdlMenu>
    <template #nav>
      <nav>
        <ul>
          <PdlMenuNavItem
            v-for="(category, jIndex) in categories"
            :key="jIndex"
            :label="category.categoryName"
            :link-type="category.categoryLinkType"
            :spyglass-data="category.spyGlassData"
            :content-data="category.contentData"
            :scheduled-data="category.scheduledContentData"
            :list-item-class="['nav-item','nav-item--dropdown-item']"
            :button-class="['flex w-full p-6 text-gray-900 text-sm font-normal hover:bg-gray-50']"
            @nav-item-clicked="categoryClicked"
          />
        </ul>
      </nav>
    </template>
  </PdlMenu>
</template>

<script>
import { mapGetters } from 'vuex'
import PdlMenu from 'components/navigation/PdlMenu'
import PdlMenuNavItem from 'components/navigation/nav-items/PdlMenuNavItem'

export default {
  components: {
    PdlMenu,
    PdlMenuNavItem
  },
  props: {
    index: {
      type: Number,
      default: 0
    },
    categories: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      isDesktop: 'ScreenSize/isDesktop'
    })
  },
  methods: {
    categoryClicked(label) {
      const categoryClicked = this.categories.filter(category => category.categoryName === label)
      const position = this.categories.indexOf(categoryClicked[0])
      this.$emit('category-clicked', categoryClicked[0], this.index + position)
    }
  }
}
</script>
