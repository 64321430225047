import ApiService from '@/api/'

const opcoMatrix = {
  STSH: {
    cardPrefix: '22',
    cardPrefixes: ['22'],
    cardLength: 13,
    cardLengths: [13]
  },
  GNTL: {
    cardPrefix: '44',
    cardPrefixes: ['44'],
    cardLength: 12,
    cardLengths: [12]
  },
  GNTC: {
    cardPrefix: '48',
    cardPrefixes: ['48'],
    cardLength: 11,
    cardLengths: [11]
  },
  FDLN: {
    cardPrefix: '46',
    cardPrefixes: ['46', '41'],
    cardLength: 12,
    cardLengths: [11, 12]
  },
  MRTN: {
    cardPrefix: '48',
    cardPrefixes: ['48'],
    cardLength: 11,
    cardLengths: [11]
  }
}

// eslint-disable-next-line
const expressionBrandRE = /^brand_/i
// eslint-disable-next-line
const camelCaseRE = /(_)./g

export default {
  namespaced: true,
  state: {
    opco: '',
    opcoId: '',
    vars: {},
    brandVars: {},
    brandResources: {},
    resourceSpyglass: {},
    configPromise: null,
    storeConfig: {}
  },
  getters: {
    opco: state => state.opco,
    opcoId: state => state.opcoId,
    vars: state => state.vars,
    brandVars: state => state.brandVars,
    resourceByName: state => name => (state.brandResources[name] ? state.brandResources[name] : null),
    resourceSpyglass: state => name => (state.resourceSpyglass[name] ? state.resourceSpyglass[name] : null),
    varByName: state => name => state.vars[name],
    cardPrefix: state => ((state.opco in opcoMatrix) ? opcoMatrix[state.opco].cardPrefix : '22'),
    cardPrefixes: state => ((state.opco in opcoMatrix) ? opcoMatrix[state.opco].cardPrefixes : ['22']),
    cardLength: state => ((state.opco in opcoMatrix) ? opcoMatrix[state.opco].cardLength : 13),
    cardLengths: state => ((state.opco in opcoMatrix) ? opcoMatrix[state.opco].cardLengths : [13]),
    cardName: (state, getters) => (getters.resourceByName('brand_card_name') || 'Loyalty Card'),
    configPromise: state => state.configPromise,
    brandContactNumber: (state, getters, rootState, rootGetters) => (
      (rootGetters['UserProfile/information']?.userType === 'M')
        ? getters.varByName('brand_business_affairs_number') : getters.varByName('brand_consumer_affairs_number')
    ),
    brandContactNumberLink: (state, getters, rootState, rootGetters) => (
      (rootGetters['UserProfile/information']?.userType === 'M')
        ? getters.varByName('brand_business_affairs_number_link_text') : getters.varByName('brand_help_phone_link_text')
    ),
    storeConfig: state => state.storeConfig,
  },
  mutations: {
    setOpco(state, payload) {
      state.opco = payload
    },
    setOpcoId(state, payload) {
      state.opcoId = payload
    },
    setVars(state, payload) {
      const configObj = {}
      const brandConfigObj = {}

      payload.forEach((obj) => {
        let { value } = obj
        if (value === 'true') {
          value = true
        } else if (value === 'false') {
          value = false
        }

        configObj[obj.name] = value

        if (expressionBrandRE.test(obj.name)) {
          let transformedName = obj.name.slice(6) // remove brand from name
          transformedName = transformedName.replace(camelCaseRE, token => token.slice(-1).toUpperCase())
          brandConfigObj[transformedName] = value
        }
      })

      state.vars = configObj

      state.brandVars = brandConfigObj
      this.commit('FormMessages/setStoreData')
    },
    setResources(state, payload) {
      state.brandResources = payload
    },
    setResourceSpyglass(state, payload) {
      state.resourceSpyglass = payload
    },
    setConfigPromise(state, payload) {
      state.configPromise = payload
    },
    setStoreConfig(state, payload) {
      state.storeConfig[payload.name] = payload.value
    }
  },
  actions: {
    async getConfig({ state, commit, rootGetters }) {
      try {
        const { storeNumber } = rootGetters['UserProfile/deliveryServiceLocation']
        const siteConfigAPI = '/apis/site-config-api/opcos'
        let response = ''

        if (state.configPromise) {
          response = await state.configPromise
        } else {
          const storeNumberRequestString = storeNumber ? `stores/${storeNumber}/` : ''
          const request = `${siteConfigAPI}/${state.opco || appConfig.opcoTheme}/${storeNumberRequestString}`
          response = await ApiService.get(request)
          commit('setConfigPromise', response)
        }

        commit('setVars', response.data)
        const contactFreeLevel = rootGetters['SiteConfig/varByName']('feature_contact_free_level')
        commit('UserProfile/setContactFreeLevel', contactFreeLevel, { root: true })
        return { success: true }
      } catch {
        commit('setConfigPromise', null)
        return { success: false }
      }
    },
    async getStoreConfigValue({
      state, rootGetters, getters, commit
    }, { attribute }) {
      try {
        const opcoLevelValue = rootGetters['SiteConfig/varByName'](attribute)
        if (typeof opcoLevelValue !== 'undefined') {
          return opcoLevelValue
        }
        const { storeNumber } = rootGetters['UserProfile/deliveryServiceLocation']
        if (!storeNumber) {
          return false
        }
        const storeAttribute = `${attribute}_${storeNumber}`
        if (getters.storeConfig[storeAttribute]) {
          return getters.storeConfig[storeAttribute]
        }
        const response = await ApiService.get(
          `/apis/site-config-api/opcos/${state.opco}/stores/${storeNumber}/attributes/${attribute}`
        )
        const attrValue = response.data[0].value
        if (attrValue === 'false') {
          commit('setStoreConfig', { name: storeAttribute, value: false })
          return false
        }
        if (attrValue === 'true') {
          commit('setStoreConfig', { name: storeAttribute, value: true })
          return true
        }
        if (typeof attrValue === 'boolean') {
          commit('setStoreConfig', { name: storeAttribute, value: attrValue })
          return attrValue
        }
        return false
      } catch {
        return false
      }
    },
    async getConfigValue(context, { opco, attribute }) {
      let response
      try {
        response = await ApiService.get(`/apis/site-config-api/opcos/${opco}/attributes/${attribute}`)
        const attrValue = response.data[0].value
        if (attrValue === 'false') {
          return false
        }
        if (attrValue === 'true') {
          return true
        }
        return attrValue
      } catch {
        return false
      }
    },
    async fetchResources({ commit, dispatch }) {
      const resourceBundleData = await dispatch('ScheduledContent/queryRegion',
        { region: { resource_bundle: {} } },
        { root: true })

      if (!resourceBundleData?.response?.regionContents) {
        return
      }

      const resourceContent = resourceBundleData.response.regionContents[0].contents[0].map
        .scheduledContentData.map.parts.myArrayList
        .reduce((data, el) => (
          {
            ...data,
            [el.map['Message Key']]: el.map['Message Text']
          }), {})
      const resourceSpyglass = resourceBundleData.response.regionContents[0].contents[0].map
        .scheduledContentData.map.parts.myArrayList
        .reduce((data, el) => (
          {
            ...data,
            [el.map['Message Key']]: {
              parent: [
                { name: 'Resource Bundle', value: resourceBundleData.response.regionContents[0].contents[0].map.key },
                { name: 'Scheduled Id', value: el.map.key },
                { name: 'Module Type', value: el.map.moduleType },
                { name: 'Message Key', value: el.map['Message Key'] }
              ]
            }
          }), { parent: resourceBundleData.response.regionContents[0].contents[0].map })

      commit('setResources', resourceContent)
      commit('setResourceSpyglass', resourceSpyglass)
      // insert pickup store Covid bypasses if they exist
      if (resourceContent.AVAILABLE_STORES_PICKUP) {
        const stores = resourceContent.AVAILABLE_STORES_PICKUP.split(',').map(storeNum => parseInt(storeNum, 10))
        commit('UserProfile/setAvailablePickupStores', stores, { root: true })
      }
      if (resourceContent.CNC_LOCKER_STORE_NUMBERS) {
        const stores = resourceContent.CNC_LOCKER_STORE_NUMBERS.split(',').map(storeNum => parseInt(storeNum, 10))
        dispatch('PickupLockers/insertStoreNumberSupport', { pod: false, stores }, { root: true })
        dispatch('PickupLockers/setCurrentSupport', null, { root: true })
      }
      if (resourceContent.CNC_POD_STORE_NUMBERS) {
        const stores = resourceContent.CNC_POD_STORE_NUMBERS.split(',').map(storeNum => parseInt(storeNum, 10))
        dispatch('PickupLockers/insertStoreNumberSupport', { pod: true, stores }, { root: true })
        dispatch('PickupLockers/setCurrentSupport', null, { root: true })
      }
      // set EBT Service Locations
      if (resourceContent.EBT_SERVICE_LOCATIONS) {
        const locationIds = resourceContent.EBT_SERVICE_LOCATIONS.split(',').map(location => parseInt(location, 10))
        commit('EbtPayment/setServiceLocationIds', locationIds, { root: true })
      }
      // set EBT States
      if (resourceContent.EBT_STATES) {
        const stateNames = resourceContent.EBT_STATES.split(',').map(state => state)
        commit('EbtPayment/setStatesNames', stateNames, { root: true })
      }
    }
  }
}
