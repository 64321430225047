export default function (Obj) {
  const isObject = v => (v !== null && typeof v === 'object' && Array.isArray(v) === false)

  const flatten = (origin, response, key) => {
    if (isObject(origin)) {
      _.each(origin, (obj, k) => {
        flatten(obj, response, k)
      })
    } else {
      response[key] = origin
    }
    return response
  }
  return isObject(Obj) ? flatten(Obj, {}) : {}
}
