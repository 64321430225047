/** function isValid - checks if today's date valid comparing it to date of expiration
 *
 * @param valid_to - string, last day of date range
 * @return boolean - if today's date is valid
* */

export default function (validTo) {
  // validTo is inclusive
  if (validTo) {
    const today = new Date()
    const currentDate = window.dateFormat(today, 'isoDate')
    return (currentDate <= validTo)
  }
  return false
}
