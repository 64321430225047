import ApiService from '@/api/'

export default {
  get(config = {}, userId = '') {
    return ApiService.get(`/api/v4.0/user/${userId}/profile`, config)
  },
  refreshUserProfile(userId) {
    const sessionUpdatesEnabled = window.sharedVue.config.globalProperties.$store.getters['SiteConfig/varByName'](
      'feature_session_updates'
    )
    if (sessionUpdatesEnabled) {
      return ApiService.post(`/api/v1.0/user/${userId}/profile/refresh`)
    }
    return Promise.resolve(false)
  },
}
