<template>
  <!--
    * GENERIC SEARCH FORM
    *
    * Intended to be used as a starting point for search forms.
    *
  -->
  <form
    class="flex flex-row flex-nowrap"
    novalidate
    @submit.prevent="beforeSubmit"
  >
    <div
      class="pdl-search flex-auto overflow-hidden"
      :class="{
        'w-full': !isActive
      }"
    >
      <PdlIconBase
        v-if="!isActive"
        :icon-title="`${name} Icon`"
        container-class="vector-icon-size--small pdl-search_center pdl-search_icon pdl-search_search-icon left-4"
        icon-class="pdl-search_icon-sr"
        :vb-width="28"
        :vb-height="28"
        aria-hidden="true"
      >
        <PdlIconSearchIcon />
      </PdlIconBase>
      <button
        v-if="isActive"
        type="button"
        class="pdl-search_clear-button pdl-search_center"
        :disabled="!isActive"
        aria-label="Clear Search"
        @click.prevent="clear"
      >
        <PdlLoadingSpinner
          v-if="loading"
        />
        <PdlIconBase
          v-if="!loading"
          :icon-title="`Clear ${name} Field`"
          container-class="pdl-search_icon vector-icon-size--xsmall"
        >
          <PdlIconClose />
        </PdlIconBase>
      </button>
      <input
        :id="id"
        :ref="id"
        v-model="query"
        class="pdl-search_input"
        :class="{
          'pdl-search_input--with-icon': !isActive,
          'rounded-tr-none rounded-br-none border-r-0': isActive
        }"
        type="search"
        autocomplete="off"
        :placeholder="name"
        aria-label="Input search query"
      >
    </div>
    <button
      v-if="isActive"
      type="submit"
      :disabled="!isActive"
      class="button button--prime flex-initial px-5 rounded-tl-none rounded-bl-none"
      aria-label="Submit Search"
    >
      <PdlIconBase
        :icon-title="`${name} Icon`"
        container-class="pdl-search_icon vector-icon-size--small"
        icon-class="pdl-search_icon-sr pdl-search_icon-sr--lt"
        :vb-width="28"
        :vb-height="28"
      >
        <PdlIconSearchIcon />
      </PdlIconBase>
    </button>
    <button
      v-if="isActive && isMobile"
      type="button"
      :disabled="!isActive"
      class="button button--white-prime-no-border flex-initial px-5"
      @click.prevent="onCancel"
    >
      Cancel
    </button>
  </form>
</template>
<script>
import {
  PdlIconBase,
} from '@/shared/icons'
import PdlIconSearchIcon from 'components/icons/icon/PdlIconSearchIcon'
import PdlIconClose from 'components/icons/icon/PdlIconClose'
import PdlLoadingSpinner from '@/shared/interactions/PdlLoadingSpinner'
import clickaway from 'utils/custom-directives/clickaway'

export default {
  components: {
    PdlIconBase,
    PdlIconClose,
    PdlIconSearchIcon,
    PdlLoadingSpinner
  },
  directives: { clickaway },
  props: {
    isMobile: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: 'Search'
    },
    id: {
      type: String,
      default: 'searchInput'
    },
    loading: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      query: ''
    }
  },
  computed: {
    isActive() {
      return !!this.query
    },
    isFocused() { return false },
    isDirty() { return false },
  },
  watch: {
    value(newV) {
      this.query = newV || ''
    },
    query(newQ, oldQ) {
      if (oldQ.length > 0 && newQ.length < 1) {
        this.reset()
      }
    }
  },
  beforeMount() {
    this.query = this.value || ''
  },
  methods: {
    beforeSubmit() {
      if (this.isActive) {
        this.submit()
      } else {
        this.reset()
      }
    },
    submit() {
      this.$emit('form-submit', `${this.query}`)
    },
    onCancel() {
      this.clear()
      this.reset()
    },
    clear() {
      this.query = ''
    },
    // reset: remove all search results
    reset() {
      this.$emit('form-reset')
    }
  }
}
</script>
