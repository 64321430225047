export function checkFireIsland(locationZip) {
  const resourceByName = window.sharedVue.config.globalProperties.$store.getters['SiteConfig/resourceByName']

  const fireIslandZips = resourceByName('FIRE_ISLAND_FERRY_ZIPS')
  const zipCondition = !!(fireIslandZips?.includes(locationZip))

  const fireIslandLocations = resourceByName('FIRE_ISLAND_FERRY_LOCATIONS')
  const locationCondition = !!(fireIslandLocations?.includes(locationZip))

  return (zipCondition || locationCondition)
}
