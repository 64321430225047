<template>
  <div>
    <form
      v-if="pageLoaded"
      class="form trailer--double"
      aria-description="General contact form"
      novalidate
      @submit.prevent="onSubmit"
    >
      <!-- The below loyalty information fields needs to be hidden when hasLoyaltyAccount is true -->
      <div :class="[hasLoyaltyAccount ? 'display--none' : 'display--block']">
        <p class="mt-12 mb-6 border-b border-solid border-gray-100" />
        <div class="mt-12 mb-7">
          <h3 class="font-semibold">
            Enter Your Contact Information
          </h3>
        </div>
        <PdlBasicTextInput
          v-for="field in formConfig"
          :id="`${field.name}-input`"
          :key="field.name"
          v-model="v$.contactData[field.name].$model"
          :field-name="field.name"
          :input-label="field.label"
          :max-length="field.maxLength"
          :disabled="field.disabled"
          :error="v$.contactData[field.name].$error"
          :is-mobile-device="isMobileDevice"
        >
          <template
            v-if="field.errors"
            #errors
          >
            <div v-for="error of v$.contactData[field.name].$errors"
:key="error.$uid">
              <span
                v-if="error.$validator === 'required'"
              >{{ field.errors.required }}</span>
              <span v-else-if="error.$validator === 'format'">{{ field.errors.format }}</span>
            </div>
          </template>
        </PdlBasicTextInput>
        <div class="form_container_horizontal-divider">
          <div class="form_container_horizontal-divider_half">
            <label
              class="forms_label"
              aria-label="State Dropdown"
              for="state-select"
            >
              State
            </label>
            <PdlSelect
              v-if="Array.isArray(stateOptions)"
              id="state-select"
              v-model="v$.contactData.state.$model"
              :label="'- Select One -'"
              :options="stateOptions"
              :is-required="true"
              :error="v$.contactData.state.$error"
              @update:modelValue="updateStateSelection"
            >
              <template #errors>
                <span>{{ stateMessage.required }}</span>
              </template>
            </PdlSelect>
          </div>
          <div class="form_container_horizontal-divider_half">
            <PdlBasicTextInput
              id="zip-input"
              v-model="v$.contactData.zip.$model"
              field-name="zip"
              input-label="Zip Code"
              :is-required="true"
              :max-length="10"
              :error="v$.contactData.zip.$error"
              :is-mobile-device="isMobileDevice"
            >
              <template #errors>
                <div v-for="error of v$.contactData.zip.$errors"
:key="error.$uid">
                  <span v-if="error.$validator === 'required'">{{ zipMessage.required }}</span>
                  <span
                    v-if="error.$validator === 'format'"
                  >{{ zipMessage.format }}</span>
                </div>
              </template>
            </PdlBasicTextInput>
          </div>
        </div>
        <PdlBasicTextInput
          id="phone-id"
          v-model="v$.contactData.dayTimePhone.$model"
          field-name="phoneId"
          input-label="Phone (optional)"
          autocomplete="off"
          :max-length="20"
          :error="v$.contactData.dayTimePhone.$error"
          :is-mobile-device="isMobileDevice"
        >
          <template #errors>
            <div v-for="error of v$.contactData.dayTimePhone.$errors"
:key="error.$uid">
              <span v-if="error.$validator === 'format'">{{ dayTimePhoneMessage.format }}</span>
            </div>
          </template>
        </PdlBasicTextInput>
        <PdlBasicTextInput
          id="brand-loyalty-no"
          v-model="v$.contactData.loyalty.$model"
          field-name="loyalty"
          :input-label="`${cardName} Number (optional)`"
          autocomplete="off"
          :max-length="13"
          :error="v$.contactData.loyalty.$error"
          :is-mobile-device="isMobileDevice"
        >
          <template #errors>
            <div v-for="error of v$.contactData.dayTimePhone.$errors"
:key="error.$uid">
              <span v-if="error.$validator === 'format'">{{ loyaltyMessage.format }}</span>
            </div>
          </template>
        </PdlBasicTextInput>
      </div>
      <p class="mt-12 mb-6 border-b border-solid border-gray-100" />
      <div class="mt-12 mb-7">
        <h3 class="font-semibold">
          Email Address We'll Respond To
        </h3>
      </div>
      <PdlBasicTextInput
        id="email-input"
        key="email"
        v-model="v$.contactData.email.$model"
        field-name="email"
        input-label="Email Address"
        :is-required="true"
        :error="v$.contactData.email.$error"
        :is-mobile-device="isMobileDevice"
      >
        <template
          #errors
        >
          <div v-for="error of v$.contactData.email.$errors"
:key="error.$uid">
            <span
              v-if="error.$validator === 'required'"
            >{{ emailMessage.required }}</span>
            <span v-else-if="error.$validator === 'format'">{{ emailMessage.format }}</span>
          </div>
        </template>
      </PdlBasicTextInput>
      <p class="mt-12 mb-6 border-b border-solid border-gray-100" />
      <div class="mt-12 mb-7">
        <h3 class="font-semibold">
          Comment
        </h3>
      </div>
      <PdlCheckbox
        id="show-location-time-date"
        name="show-location-time-date"
        label="Location, time, date"
        :checked="false"
        :value="false"
        @change="hideShowLocation"
      />
      <div class="pb-6" />
      <!-- The below fields needs to be shown when above checkBox(hideShowLocation) is checked -->
      <div :class="[hideShowLocationFields ? 'display--block' : 'display--none']">
        <label
          v-if="hasStoreOptions"
          class="forms_label"
          aria-label="Store Location Drop Down"
          for="store-location"
        >
          Store Location (optional)
        </label>
        <PdlSelect
          v-if="hasStoreOptions"
          id="store-location"
          v-model="contactData.storeShopped"
          :full-width="true"
          :options="storeByStoreType"
          class="width--full m-0"
          :is-required="false"
        />
        <div class="pb-6" />
        <PdlBasicTextInput
          id="date-id"
          v-model="v$.contactData.dateOfEvent.$model"
          field-name="dateId"
          input-label="Date of Event (optional)"
          autocomplete="off"
          :max-length="10"
          :error="v$.contactData.dateOfEvent.$error"
          :placeholder-text="'DD/MM/YYYY'"
          :is-mobile-device="isMobileDevice"
        >
          <template #errors>
            <div v-for="error of v$.contactData.dateOfEvent.$errors"
:key="error.$uid">
              <span v-if="error.$validator === 'format'">{{ dateOfEventMessage.format }}</span>
            </div>
          </template>
        </PdlBasicTextInput>
        <PdlBasicTextInput
          id="time-id"
          v-model="v$.contactData.timeOfEvent.$model"
          field-name="timeId"
          input-label="Time (optional)"
          autocomplete="off"
          :max-length="8"
          :error="v$.contactData.timeOfEvent.$error"
          :placeholder-text="'HH:MM am/pm'"
          :is-mobile-device="isMobileDevice"
        >
          <template #errors>
            <div v-for="error of v$.contactData.timeOfEvent.$errors"
:key="error.$uid">
              <span v-if="error.$validator === 'format'">{{ timeOfEventMessage.format }}</span>
            </div>
          </template>
        </PdlBasicTextInput>
      </div>
      <PdlCheckbox
        id="product-related-comment"
        name="product-related-comment"
        label="My comment involves a specific product"
        :checked="false"
        :value="false"
        @change="hideShowProductSpecific"
      />
      <div class="pb-6" />
      <!-- The below fields needs to be shown when above checkBox(hideShowProductSpecific) is checked -->
      <div :class="[hideShowProductSpecificFields ? 'display--block' : 'display--none']">
        <PdlBasicTextInput
          id="upc-no"
          v-model="v$.contactData.universalProductCode.$model"
          field-name="upcNo"
          input-label="UPC Number (optional)"
          autocomplete="off"
          :max-length="20"
          :is-mobile-device="isMobileDevice"
        />
        <PdlBasicTextInput
          id="product-description"
          v-model="v$.contactData.productDescription.$model"
          field-name="productDescription"
          input-label="Product Description (optional)"
          autocomplete="off"
          :max-length="20"
          :is-mobile-device="isMobileDevice"
        />
      </div>
      <PdlBasicTextArea
        id="comments-id"
        v-model="v$.contactData.comments.$model"
        field-name="commentsId"
        :is-required="true"
        placeholder="Enter Comment Here (10,000 Max Characters)"
        :max-length="10000"
        aria-label="Contact Us Comments"
        input-class="h-72 w-full"
        :error="v$.contactData.comments.$error"
      >
        <template #errors>
          <div v-for="error of v$.contactData.comments.$errors"
:key="error.$uid">
            <span v-if="error.$validator === 'required'">{{ commentMessage.required }}</span>
            <span
              v-if="error.$validator === 'format'"
            >{{ commentMessage.format }}</span>
          </div>
        </template>
      </PdlBasicTextArea>
      <div class="pb-6" />
      <slot name="after" />
      <div class="pb-6" />
      <RecaptchaBlock
        id="contact-us"
        :ref="recaptchaBlockID"
        @recaptcha-loaded="onRecaptchaLoaded"
        @recaptcha-verify-change="onRecaptchaChange"
      />
      <div class="pb-6" />
      <div class="text--align-center">
        <button
          id="submit-comment"
          class="button"
          :disabled="submitDisabled"
          :class="[
            'button-width--full',
            submitDisabled ? 'button--disabled' : 'button--prime'
          ]"
        >
          Submit Comment
        </button>
        <div class="pb-6" />
        <p
          v-if="displayError && !isValidContactInformation"
          class="text--base-strong text--align-center trailer--double text--base-error"
          aria-live="polite"
        >
          {{ contactUsError.required }}
        </p>
      </div>
    </form>
    <div
      v-if="!pageLoaded"
      class="loading loading--full-width"
    >
      <div class="loading-spinner-block">
        <PdlLoadingSpinner />
        <div class="loading-spinner-block_whitewash" />
      </div>
    </div>
  </div>
</template>
<script>

import { mapState, mapGetters, mapActions } from 'vuex'
import { required, email, helpers } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import RecaptchaMixin from 'components/recaptcha/RecaptchaMixin'
import {
  firstNameValidation, lastNameValidation, phoneValidation, loyaltyZipValidation
} from 'utils/validations'
import locationsByQuery from 'api/locationsByQueryApi'

export default {
  mixins: [RecaptchaMixin],
  props: {
    opco: {
      type: String,
      required: true
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    isTablet: {
      type: Boolean,
      default: false
    },
    contactEmailSubject: {
      type: String,
      default: ''
    },
    isMobileDevice: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      contactData: {
        firstName: '',
        lastName: '',
        address: '',
        city: '',
        state: '',
        email: '',
        zip: '',
        comments: '',
        emailSubject: '',
        timeOfEvent: '',
        dateOfEvent: '',
        dayTimePhone: '',
        storeShopped: '',
        universalProductCode: '',
        productDescription: '',
        loyalty: ''
      },
      stateMessage: {},
      zipMessage: {},
      emailMessage: {},
      emailSubjectMessage: {},
      commentMessage: {},
      loyaltyMessage: {},
      dayTimePhoneMessage: {},
      timeOfEventMessage: {},
      dateOfEventMessage: {},
      formConfig: [
        {
          name: 'firstName',
          label: 'First Name',
          required: true,
          maxLength: 20
        },
        {
          name: 'lastName',
          label: 'Last Name',
          required: true,
          maxLength: 30
        },
        {
          name: 'address',
          label: 'Address Line',
          required: true,
          maxLength: 30
        },
        {
          name: 'city',
          label: 'City',
          required: true,
          maxLength: 30
        }
      ],
      printedCircularSelected: false,
      hideShowLocationFields: false,
      hideShowProductSpecificFields: false,
      loading: false,
      pageLoaded: false,
      storeByStoreType: []
    }
  },
  setup: () => ({ v$: useVuelidate() }),
  computed: {
    ...mapState({
      contactUsStatus: state => state.ContactUs.contactUsStatus,
      displayError: state => state.ContactUs.displayError,
      trackUserStatus: state => state.ContactUs.trackUserStatus,
      stateOptions: state => state.FormDataStructures.stateOptions,
    }),
    ...mapGetters('FormMessages', [
      'messages'
    ]),
    ...mapGetters('SiteConfig', [
      'varByName'
    ]),
    ...mapGetters('LoyaltyAccount', [
      'isLoyaltyRegistered',
      'firstName',
      'lastName',
      'address',
      'city',
      'state',
      'zip',
      'isLoyaltyInfoComplete',
      'cardNumber'
    ]),
    ...mapGetters({
      loyaltyLoaded: 'LoyaltyAccount/loaded'
    }),
    hasStoreOptions() {
      return Array.isArray(this.storeByStoreType) && this.storeByStoreType.length > 0
    },
    isValidContactInformation() {
      // validation for rest of email subjects contact-us form
      return !this.v$.contactData.$invalid && this.isCaptchaComplete
    },
    submitDisabled() {
      return this.loading
        || !(this.isValidContactInformation)
        || !this.recaptchaLoaded
    },
    hasLoyaltyAccount() {
      // check if guest/ authenticated/ missingInfo_authenticated user
      // AUSA brands requires user to check termsAndConditions in order to be registered/authenticated
      return this.isLoyaltyInfoComplete && this.isLoyaltyRegistered
    },
    cardName() {
      return this.varByName('brand_rewards_program_name')
    },
    getName() {
      // Send user's name to confirmation Component
      return `${this.v$.contactData.firstName.$model} ${this.v$.contactData.lastName.$model}`
    }
  },
  watch: {
    loyaltyLoaded(newVal, oldVal) {
      this.onloadingUpdate(newVal, oldVal)
    }
  },
  created() {
    // Fetch the grocery stores for store location drop-down
    this.getStoresByStoreType()
  },
  beforeMount() {
    this.loading = true
    this.completePageLoading()
    // reset the vuex store (since we use it in multiple screens)
    this.resetState()
    this.contactData.emailSubject = this.contactEmailSubject
    // Set all the validation error messages for the forms
    this.setMessages()
  },
  methods: {
    ...mapActions({
      submitContactUsBrandsApi: 'ContactUs/submitContactUsBrandsApi',
      resetState: 'ContactUs/resetState'
    }),
    onloadingUpdate(newVal, oldVal) {
      // only initialize on change from FALSY to TRUTHY, prevents duplicate calls
      if (!oldVal && newVal) {
        this.completePageLoading()
      }
    },
    completePageLoading() {
      if (this.loyaltyLoaded || !this.cardNumber) {
        this.loading = false
        this.pageLoaded = true
        this.setFormValsFromLoyalty()
      }
    },
    setFormValsFromLoyalty() {
      // pre-fill the required fields for authenticated user
      if (this.isLoyaltyRegistered) {
        this.contactData.firstName = this.firstName
        this.contactData.lastName = this.lastName
        this.contactData.address = this.address
        this.contactData.city = this.city
        this.updateStateSelection(this.state)
        this.contactData.zip = this.zip
      }
    },
    setMessages() {
      const messages = this.messages('fields')
      this.formConfig.forEach((el) => {
        el.errors = messages[el.name]
      })
      this.stateMessage = messages.state
      this.emailSubjectMessage = messages.emailSubject
      this.emailMessage = messages.email
      this.zipMessage = messages.zip
      this.loyaltyMessage = messages.cardNumber
      this.commentMessage = messages.comments
      this.dayTimePhoneMessage = messages.phone
      this.timeOfEventMessage = messages.time
      this.dateOfEventMessage = messages.date
      this.contactUsError = messages.contactUsError
    },
    async getStoresByStoreType() {
      const { opco } = this
      const defaultDeliveryAddress = this.$store.getters['UserProfile/defaultDeliveryAddress']
      const queryResponse = await locationsByQuery({
        opco,
        type: 'GROCERY',
        query: `${defaultDeliveryAddress?.zip}`,
        details: false,
        maxDistance: 50
      })
      if (queryResponse.status === 200) {
        const stores = queryResponse?.data?.stores || []
        this.storeByStoreType = stores.map((store) => {
          const {
            storeNo = '',
            address1 = '',
            city = '',
            state = '',
            zip = ''
          } = store

          return {
            value: `${storeNo}`,
            title: `${address1} ${city}, ${state}, ${zip}`
          }
        })
      }
    },
    hideShowLocation() {
      // This will allow to hide/show Location, Time, Date fields
      this.hideShowLocationFields = !this.hideShowLocationFields
    },
    hideShowProductSpecific() {
      // This will allow to hide/show UPC and Product Description
      this.hideShowProductSpecificFields = !this.hideShowProductSpecificFields
    },
    updateStateSelection(stateSelection) {
      // Update selected state in drop-down
      this.contactData.state = stateSelection
    },
    async onSubmit() {
      this.$emit('name-change', this.getName)
      if (!this.loading) {
        this.loading = true
        this.contactData.emailSubject = this.contactEmailSubject
        // Trigger Contact Us Form Api
        await this.submitContactUsBrandsApi({
          opco: this.opco,
          payload: this.contactData,
          captcha: this.recaptchaResponse
        })
        // disable the button
        this.resetCaptcha()
        this.loading = false
        this.logAnalytics(this.contactEmailSubject)
      }
    },
    logAnalytics(emailSubject) {
      this.$emit('log', emailSubject)
    },
  },
  validations: {
    // Validations for Contact Us form fields
    contactData: {
      firstName: firstNameValidation,
      lastName: lastNameValidation,
      address: {
        required
      },
      city: {
        required
      },
      state: {
        required
      },
      zip: loyaltyZipValidation,
      email: {
        required,
        email
      },
      comments: {
        required
      },
      timeOfEvent: {
        timeOfEvent(timeOfEvent) {
          return (
            new RegExp(/^((1[0-2]|0?[1-9]):([0-5]\d) ([AaPp][Mm]))$/).test(
              timeOfEvent
            ) || !helpers.req(timeOfEvent)
          )
        }
      },
      dateOfEvent: {
        date(dateOfEvent) {
          return (
            new RegExp(/^([0-2]\d|(3)[0-1])\/(((0)[1-9])|((1)[0-2]))\/\d{4}$/).test(
              dateOfEvent
            ) || !helpers.req(dateOfEvent)
          )
        }
      },
      dayTimePhone: phoneValidation,
      universalProductCode: {},
      productDescription: {},
      loyalty: {
        loyalty(loyalty) {
          if (this.opco === 'STSH') {
            return (
              loyalty.startsWith('22') && loyalty.length <= 13
            ) || !helpers.req(loyalty)
          }
          return (
            (loyalty.startsWith('44') || loyalty.startsWith('48')) && loyalty.length <= 13
          ) || !helpers.req(loyalty)
        }
      }
    }
  }
}
</script>
