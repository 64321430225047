<template>
  <div>
    <span
      v-if="isDesktop || hoverEnabled"
      class="mt-1"
    >
      {{ toolTip.title }}
    </span>
    <button
      aria-label="infoLabel"
      :class="[{
        'pdl-tab-tooltip_absolute': !isDesktop && hoverEnabled
      }]"
      @mouseover="onItemHover(true)"
      @mouseleave="onItemHover(false)"
      @focusin="onItemHover(true)"
      @focusout="onItemHover(false)"
      @click="onShowHelp()"
    >
      <PdlIconBase
        v-if="isDesktop || hoverEnabled"
        icon-title="title"
        icon-color="darkest-grey"
        icon-class="pdl-common-tooltip_color--brand vector-icon-stroke--lightest-grey"
        container-class="pdl-common-tooltip_icon pdl-common-tooltip_sizing--medium"
      >
        <PdlIconInfo />
      </PdlIconBase>
      <div
        v-if="hoverEnabled && !isDesktop && (showTooltipOnClick)"
        :class="[
          'pdl-tab-tooltip pdl-tab-tooltip_arrow-mobile pdl-tab-tooltip_mobile',
          'pdl-tab-tooltip_mobile--info pdl-tab-tooltip_mobile--info-history'
        ]"
      >
        {{ toolTip.message }}
      </div>
    </button>
    <div
      v-if="!hoverEnabled"
      v-clickaway="
        {
          handler: 'handleClickOutside'
        }"
      class="pdl-tab-tooltip"
      :class="[{
        'pdl-tab-tooltip_desktop': !isMobile && showTooltipThisSession,
        'pdl-tab-tooltip_mobile': isMobile,
        'pdl-tab-tooltip_hidden pdl-tab-tooltip_desktop':
          !isMobile && !showTooltipThisSession,
        'pdl-tab-tooltip_hidden': !showTooltipThisSession && isMobile
      }]"
    >
      <p>{{ toolTip.message }}</p>
      <em />
    </div>
    <div
      v-if="hoverEnabled && isDesktop && (showTooltipOnClick || hover)"
      :class="['pdl-tab-tooltip pdl-tab-tooltip_history-info',
               'pdl-tab-tooltip_desktop--info pdl-tab-tooltip_desktop--info-history'
      ]"
    >
      {{ toolTip.message }}
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import PdlIconInfo from 'components/icons/icon/PdlIconInfo'
import clickaway from 'utils/custom-directives/clickaway'

export default {
  directives: { clickaway },
  components: {
    PdlIconInfo
  },
  props: {
    fullWidth: {
      type: Boolean,
      default: false
    },
    toolTip: {
      type: Object,
      default: () => ({}),
    },
    showTooltipThisSession: {
      type: Boolean,
      default: false
    },
    hoverEnabled: {
      type: Boolean,
      default: false
    },
    showTooltipOnClick: {
      type: Boolean,
      default: false
    },
  },
  data: () => ({
    hover: false
  }),
  computed: {
    ...mapGetters({
      isMobile: 'ScreenSize/isMobile',
      isDesktop: 'ScreenSize/isDesktop'
    }),
    title() {
      return this.toolTip.title
    }
  },
  methods: {
    handleClickOutside() {
      this.$emit('clickaway')
    },
    onShowHelp() {
      this.$emit('show-help')
    },
    onItemHover(toggleVal) {
      if (this.hoverEnabled) {
        this.hover = toggleVal
      }
    }
  }
}
</script>
