import mitt from 'mitt'

const emitter = mitt()
const install = (app) => {
  app.config.globalProperties.$emitter = emitter
}

export default {
  install
}
