function intailState() {
  return {
    selectedRecall: {}
  }
}
export default {
  namespaced: true,
  state: intailState,
  mutations: {
    setSelectedRecall(state, payload) {
      state.selectedRecall = payload
    }
  },
}
