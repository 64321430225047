const initialPageConfig = {
  linkedFromProduct: null,
  recommendationInfo: {},
  cartLogInformation: {}
}

export default {
  namespaced: true,
  state: {
    pageConfig: initialPageConfig,
    currentLocationStore: {}
  },
  getters: {
    currentLocationStore: state => state.currentLocationStore,
  },
  mutations: {
    setPageConfig(state, config) {
      state.pageConfig = {
        ...initialPageConfig,
        ...config
      }
    },
    resetPageConfig(state) {
      state.pageConfig = initialPageConfig
    },
    setCurrentLocationStore(state, payload) {
      state.currentLocationStore = payload
    },
  }
}
