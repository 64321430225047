<template>
  <label
    class="field_row forms_label"
    :class="{
      'mb-0': hideLabel,
      'font-bold': boldLabel
      }"
    :for="id"
  >
    <span v-if="!hideLabel">
      {{ label }}
      <span
        v-if="optLabel"
        class="form_optlabel"
      >{{ optLabel }}</span>
      <span
        v-if="tagLine"
        class="form_tagline"
      >{{ tagLine }}</span>
    </span>
    <slot />
  </label>
</template>
<script>

export default {
  props: {
    id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    hideLabel: {
      type: Boolean,
      required: true
    },
    optLabel: {
      type: String,
      default: ''
    },
    tagLine: {
      type: String,
      default: ''
    },
    boldLabel: {
      type: Boolean,
      required: false
    },
  }
}
</script>
