<template>
  <div
    v-show="isActive"
    :id="panelId"
    role="tabpanel"
    :aria-labelledby="id"
    :aria-hidden="!isActive"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true
    },
    selected: {
      type: Boolean,
      default: false
    },
    count: {
      type: Number,
      default: 0
    },
    position: {
      type: Number,
      default: null
    },
    value: {
      type: [Number, String, Boolean],
      default: ''
    }
  },
  emits: ['before-tab-active', 'tab-mounted'],
  mounted() {
    this.$emit('tab-mounted', this)
  },
  data() {
    return {
      isActive: false
    }
  },
  computed: {
    id() {
      return this.name.toLowerCase().replace(/ /g, '-').replace(/\$/g, 'option-')
    },
    panelId() {
      return `panel-${this.id}`
    }
  },
  beforeMount() {
    this.isActive = this.selected
  },
  beforeUpdate() {
    if (this.isActive) {
      this.$emit('before-tab-active', this.id)
    }
  }
}
</script>
