<template>
  <li
    v-if="hasLinkDescription"
    v-content-tracker="{
      contentData,
      scheduledData,
      getContentLogBody,
      isTrackingEnabled
    }"
    :class="listItemClass"
    @mouseenter="hoverClass = true"
    @mouseleave="hoverClass = false"
    @focusin="hoverClass = true"
    @focusout="$emit('focus-out')"
  >
    <div
      v-if="hasHoverIcon && isNewNavDesignEnabled"
      class="absolute left-0.5 bottom-1 pb-5 text-primary"
      :class="{
        'invisible' : !hoverClass,
        'visible' : hoverClass
      }"
    >
      &#x2022;
    </div>
    <button
      v-if="isButton"
      :id="itemId"
     :class="[
        buttonClass,
        {
          '!text-gray-900 flex py-6 !px-[1rem] decoration-current hover:!text-gray-900 !text-sm'
          : isNewNavDesignEnabled,
        }
      ]"
      :aria-haspopup="ariaHasPopup"
      :aria-expanded="linkType === 'Dropdown Menu' && isActive ? 'true' : false"
      @click.stop="onNavItemClick($event)"
      @focusin="emitFocusIn"
    >
      <slot />
    </button>
    <a
      v-else
      :id="itemId"
      :class="[
        linkClass,
        {
          '!text-gray-900 flex py-6 !px-[1rem] decoration-current hover:!text-gray-900 !text-sm'
          : isNewNavDesignEnabled,
        }
      ]"
      :href="href"
      :target="linkType === 'External' ? '_blank' : false"
      :rel="linkType === 'External' ? 'noopener noreferrer' : ''"
      :aria-haspopup="ariaHasPopup"
      :aria-expanded="linkType === 'Dropdown Menu' && isActive ? 'true' : false"
      @click.stop="onNavItemClick($event)"
      @focusin="emitFocusIn"
    >
      <slot />
    </a>
    <PdlSpyglass
      v-if="spyglassActive && spyglassData"
      :spyglass-content="spyglassData"
      :is-group="false"
    />
    <slot name="navDropDown" />
  </li>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import compileLinkVars from 'utils/mixins/compileLinkVars'
import contentTracker from 'utils/custom-directives/contentTracker'
import PdlSpyglass from 'components/spyglass/PdlSpyglass'

export default {
  components: {
    PdlSpyglass
  },
  directives: {
    contentTracker
  },
  mixins: [compileLinkVars],
  props: {
    listItemClass: {
      type: [String, Array],
      default: 'nav-item'
    },
    buttonClass: {
      type: [String, Array],
      default: 'nav-item_button'
    },
    linkClass: {
      type: [String, Array],
      default: 'nav-item_link'
    },
    url: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    linkType: {
      type: String,
      default: 'Internal'
    },
    idPrefix: {
      type: String,
      default: 'nav'
    },
    isActive: {
      type: Boolean,
      default: false
    },
    lowercaseLabel: {
      type: Boolean,
      default: true
    },
    renderAsButton: {
      type: Boolean,
      default: false
    },
    spyglassData: {
      type: Object,
      default: null
    },
    isTrackingEnabled: {
      type: Boolean,
      default: false
    },
    contentData: {
      type: Object,
      default: null
    },
    scheduledData: {
      type: Object,
      default: null
    },
    isNewNavDesignEnabled: {
      type: Boolean,
      default: false
    },
    hasHoverIcon: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      hoverClass: false
    }
  },
  computed: {
    ...mapGetters({
      spyglassActive: 'ScheduledContent/spyglassActive'
    }),
    ...mapState({
      hideRecommendedForYou: state => state.Optimizely.hideRecommendedForYou
    }),
    isButton() {
      if (this.renderAsButton) {
        return true
      }
      return (!this.url || this.linkType === 'Dropdown Menu' || this.linkType === 'Modal' || this.linkType === 'None')
    },
    hasLinkDescription() {
      // removing "recommended for you for challenger testing"
      if (this.hideRecommendedForYou && this.label === 'Recommended For You') {
        return false
      }
      return this.label.length > 0
        && this.cleanAllExpressions(this.label).trim().replace(/[^a-z0-9]+/ig, '-').length > 0
    },
    itemId() {
      const trimLabel = this.cleanAllExpressions(this.label).trim().replace(/[^a-z0-9]+/ig, '-')
      const label = this.lowercaseLabel ? trimLabel.toLowerCase() : trimLabel
      return `${this.idPrefix}-${label}`
    },
    href() {
      if (this.linkType === 'Internal') {
        return '#'
      }
      if (this.linkType === 'External' && this.url) {
        return this.cleanAllExpressions(this.url)
      }

      return false
    },
    ariaHasPopup() {
      if (this.linkType === 'Dropdown Menu') {
        return 'menu'
      }
      if (this.linkType === 'Modal') {
        return 'dialog'
      }
      return ''
    }
  },
  methods: {
    onNavItemClick($event) {
      if (this.linkType === 'Internal' && this.url) {
        $event.preventDefault()
      }
      this.trackCategoryContentClicked()
      // Emit click even for External links in case something
      // needs to be toggled or tagged.
      this.$emit('nav-item-base-clicked',
        this.cleanAllExpressions(this.label),
        this.cleanAllExpressions(this.url),
        this.linkType,
        $event.target.id)
    },
    getContentLogBody(payload) {
      this.logData = payload
    },
    emitFocusIn() {
      this.$emit('focus-in')
    },
    trackCategoryContentClicked() {
      if (this.scheduledData) {
        const payload = {
          region: this.scheduledData.Site_Location,
          scheduledContentId: this.scheduledData.key,
          contentId: this.contentData.key,
          name: this.scheduledData['Friendly Tracking Description']
        }
        this.$trackClientLog('content_click', payload)
        this.$store.commit('ScheduledContent/setCurrentScheduledContent',
          {
            url: this.url,
            type: this.contentData['Button Link Type'],
            ...this.logData
          })
      }
    }
  }
}
</script>
