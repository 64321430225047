import PaymentMethodsAPI from 'api/PaymentMethodsAPI'
import UserPreferencesAPI from 'api/UserPreferencesAPI'

function determineApplePaySupported() {
  /**
   * NOTE: this is the correct method for detecting capability in web
   * but will need to be expanded once native capability can be detected
   */
  return !!window.ApplePaySession
}

const initialState = {
  value: '',
  paymentMethods: [],
  // NOTE: all state below this line is for next-gen payment capabilities
  creditCardOptions: [],
  bankAccount: {},
  ebtAccount: {},
  applePaySupported: determineApplePaySupported(),
  applePayIsAccountDefault: false,
  selectedApplePayDefault: false, // reference on checkout to determine if preference should be set
  preferredPaymentMethod: 'CARD',
  preferredMethodLoaded: false,
  // replacement for existing key once new capabilities are available
  preferredPaymentOption: {
    type: null,
    id: null
  }
}
export default {
  namespaced: true,
  state: {
    loading: false,
    paymentOptionsLoaded: false,
    ...initialState
  },
  getters: {
    hasCreditCardOptions: state => state.creditCardOptions.length > 0,
    primaryCard: state => state.creditCardOptions.find(card => card.primaryAccount),
    paymentMethods: state => state.paymentMethods
  },
  mutations: {
    setLoading(state, payload) { state.loading = payload },
    setpaymentOptionsLoaded(state, payload) { state.paymentOptionsLoaded = payload },
    setStoreData(state, payload) {
      _.each(payload, (value, key) => {
        if (_.has(state, key) && !_.isNull(value)) {
          state[key] = value
        }
      })
    },
    setPaymentMethods(state, payload) {
      state.paymentMethods = payload
    },
    setCreditCardOptions(state, payload) {
      state.creditCardOptions = payload
    },
    setBankAccount(state, payload) {
      state.bankAccount = payload
    },
    setEbtAccount(state, payload) {
      state.ebtAccount = payload
    },
    toggleSelectedApplePayDefault(state) {
      state.selectedApplePayDefault = !state.selectedApplePayDefault
    },
    setApplePayIsAccountDefault(state, payload) {
      state.applePayIsAccountDefault = payload
    },
    setPreferredPaymentOption(state, payload) {
      state.preferredPaymentOption = payload
    },
    setPreferredPaymentMethod(state, payload) {
      state.preferredPaymentMethod = payload
    },
    setPreferredMethodLoaded(state, payload) {
      state.preferredMethodLoaded = payload
    },
  },
  actions: {
    async updatePaymentMethod({ state, commit, rootGetters }, data) {
      let response = Promise.resolve({})
      const userId = rootGetters['UserProfile/userId']
      if (!state.loading) {
        commit('setLoading', true)
        const { value } = data
        response = await UserPreferencesAPI.update('payment-method', value, userId)
        const success = response.status === 200
        if (success) {
          commit('setStoreData', data)
        }
      }

      commit('setLoading', false)
      return response
    },
    async getPaymentOptions({ commit, rootGetters }) {
      const userId = rootGetters['UserProfile/userId']
      const response = await PaymentMethodsAPI.getPaymentmethods(userId)
      const pms = response?.data?.paymentMethods || response?.data

      try {
        const creditCards = pms.filter(
          paymentOption => paymentOption.paymentMethod === 'CARD'
        )
        const bankAccount = pms.filter(
          paymentOption => paymentOption.paymentMethod === 'PEP'
        )
        const ebtAccount = pms.filter(
          paymentOption => paymentOption.paymentMethod === 'EBT'
        )
        if (pms) {
          commit('setPaymentMethods', pms)
        }

        if (creditCards) {
          commit('setCreditCardOptions', creditCards)
        }
        if (bankAccount) {
          commit('setBankAccount', bankAccount[0])
        }
        if (ebtAccount) {
          commit('setEbtAccount', ebtAccount[0])
        }
        commit('setpaymentOptionsLoaded', true) // Prevent reload of data
        return response
      } catch {
        return response
      }
    },
    async getPreferredPaymentMethod({ rootGetters, commit }) {
      const userId = rootGetters['UserProfile/userId']
      try {
        const response = await UserPreferencesAPI.get('payment-method', userId)

        if (response.status === 200) {
          commit('setPreferredPaymentMethod', response.data.response.preference.value)
          commit('setPreferredMethodLoaded', true)
          return
        }

        throw new Error(response)
      } catch (error) {
        commit('Alert/setAlert', {
          type: 'error',
          header: 'Preferred Payment Method Error',
          body: 'Something went wrong trying to get the preferred payment method.',
          primary: {
            text: 'Continue',
            callback: async () => {
              window.sharedVue.config.globalProperties.$store.commit('Alert/clear')
            }
          }
        }, { root: true })
      }
    },
    /**
     * The below actions make assumptions for now about
     * what the data returned from upcoming API will look
     * like, and will need to be refactored once the API
     * is available.
     */
    // API call will provide either a specific credit card or other option
    getPreferredPaymentOption({ commit }, data) {
      // STUB: no API for this yet
      commit('setPreferredPaymentOption', data)
      commit('setApplePayIsAccountDefault', data.type === 'APPLE')
    }
  },
}
