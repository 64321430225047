/* eslint-disable no-underscore-dangle */

import { getUserProfileAndEcommStatus, bulkAddToCart } from './vendor-methods'

class ConstantCommerceAddToBasketService {
  constructor() {
    this.loaded = false
  }

  loadCC() {
    if (this.loaded) {
      return
    }
    const store = window.sharedVue.config.globalProperties.$store
    const cdnUrl = store.getters['SiteConfig/varByName']('config_cc_recipe_modal_url')
    window.constantco = function (...args) {
      window.constantco.q = window.constantco.q || []
      window.constantco.q.push(args)
    }
    window.constantco('get-user-profile', getUserProfileAndEcommStatus)
    window.constantco('on-a2b', bulkAddToCart)
    this._injectScript(cdnUrl)
  }

  loadRecipe(recipeId) {
    this.loadCC()
    window.constantco('open-recipe', recipeId)
  }

  _injectScript(cdnUrl) {
    // loading script given to us by Constant Commerce
    // eslint-disable-next-line
    (function(a,b,c,d,e, ...args){
      a[d] = a[d] || function () { (a[d].q = a[d].q || []).push(args) }
      const scriptElement = b.createElement(c) // Introduced new variable scriptElement
      scriptElement.src = `${e}?${(Date.now() / 3.6e+6).toFixed()}`
      const firstScript = b.querySelector(c) // Use of initial value before reassigning
      firstScript.parentNode.insertBefore(scriptElement, firstScript)
    })(window, document, 'script', 'constantco', `${cdnUrl}`)
    this.loaded = true
  }
}

const constantCommerceAddToBasketService = new ConstantCommerceAddToBasketService()

export default constantCommerceAddToBasketService
