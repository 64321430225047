import ApiService from '@/api/'

export default {
  sendMessage({
    userId, uniqueId, message, opco, isMobile
  }) {
    return ApiService.post(`/apis/chat/bot/${userId}/${opco}/v1/webhook`, {
      sender: uniqueId,
      message,
      metadata: {
        isMobile
      }
    })
  }
}
