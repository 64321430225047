const ObserverFactory = () => {
  let dependents = []
  let currentValue = null
  let previousValue = null

  const getValueObject = function () {
    return { prevValue: previousValue, value: currentValue }
  }

  return {
    /**
         * @ngdoc method
         * @name notify
         * @methodOf observer.factory:ObserverFactory
         *
         * @description
         * Notifies the dependents with a new value iteratively.  Each dependent function will receive an object
         * that follows this format:
         * ```js
         * { prevValue: previousValue, value: currentValue }
         * ```
         *
         * @returns {promise} result
         */
    notify(newValue) {
      previousValue = currentValue
      currentValue = newValue
      _.invoke(dependents, 'update', getValueObject())
    },

    /**
         * @ngdoc method
         * @name addDependent
         * @methodOf observer.factory:ObserverFactory
         *
         * @param {object} dependent An object that must contain a single property of update.  This property should
         * be a function.
         *
         * @description
         * Subscribes the dependent to notification events from this observer instance.
         *
         * @returns {promise} result
         */
    addDependent(dependent) {
      dependents.push(dependent)

      if (currentValue !== null) {
        dependent.update(getValueObject())
      }
    },

    /**
         * @ngdoc method
         * @name isEmpty
         * @methodOf observer.factory:ObserverFactory
         *
         * @description
         * A check to see if this observer has any dependents.
         *
         * @returns {boolean} result
         */
    isEmpty() {
      return dependents.length === 0
    },

    /**
         * @ngdoc method
         * @name removeDependent
         * @methodOf observer.factory:ObserverFactory
         *
         * @param {object} dependent An object that must contain a single property of update.  This property should
         * be a function.
         *
         * @description
         * Removes a dependent from the internal dependency array by value of the specified update property
         *
         */
    removeDependent(dependent) {
      dependents = _.filter(dependents, (iterDependent) => { return dependent.update !== iterDependent.update })
    }
  }
}

export default ObserverFactory
