<template>
  <g>
    <component
      :is="icon"
      :opco="opco"
      :stroke-width="strokeWidth"
    />
  </g>
</template>
<!-- Icons reserved for only my account -->

<script>
import PdlIconSettings from 'components/icons/icon/PdlIconSettings'
import PdlIconChat from 'components/icons/icon/PdlIconChat'
import PdlIconPayment from 'components/icons/icon/PdlIconPayment'
import PdlIconAccount from 'components/icons/icon/PdlIconAccount'
import PdlIconPdf from 'components/icons/icon/PdlIconPdf'
import PdlIconFlexRewards from 'components/icons/icon/PdlIconFlexRewards'
import PdlIconWallet from 'components/icons/icon/PdlIconWallet'
import PdlIconDollar from 'components/icons/icon/PdlIconDollar'
import PdlIconHeart from 'components/icons/icon/PdlIconHeart'


const iconMap = {
  settings: 'PdlIconSettings',
  chat: 'PdlIconChat',
  payment: 'PdlIconPayment',
  pdf: 'PdlIconPdf',
  user: 'PdlIconAccount',
  star: 'PdlIconFlexRewards',
  wallet: 'PdlIconWallet',
  dollar: 'PdlIconDollar',
  heart: 'PdlIconHeart'
}

export default {
  components: {
    PdlIconSettings,
    PdlIconChat,
    PdlIconPayment,
    PdlIconAccount,
    PdlIconPdf,
    PdlIconFlexRewards,
    PdlIconWallet,
    PdlIconDollar,
    PdlIconHeart
  },
  props: {
    svgId: {
      type: String,
      required: true
    },
    opco: {
      type: String,
      required: true
    },
    strokeWidth: {
      type: String,
      default: '3'
    }
  },
  computed: {
    icon() {
      return iconMap[this.svgId] ? iconMap[this.svgId] : 'PdlIconWarning'
    }
  },
  methods: {
    checkName(id) {
      return id === this.svgId
    }
  }
}
</script>
