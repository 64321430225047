import {
  USER_PREFERENCE_KEYS,
  ORDER_SERVICE_TYPES,
  SERVICE_TYPE_TEXT
} from 'utils/constants'
import UserPreferencesAPI from 'api/UserPreferencesAPI'
import ServiceLocationsAPI from 'api/ServiceLocationsAPI'
import AutoLoginAPI from 'api/AutoLoginAPI'
import DeferredSingleActionService from 'utils/interceptors/services/deferred-single-action'
import {
  selectedLocationIsOnSpectrum,
  openUnataFromAppToBrowser,
  openUnataFromWebToBrowser
} from 'utils/StoreTransition'
import { trackCancelOrderPrompt } from 'utils/tracking/order/trackCancelOrderPrompt'

const {
  BROWSE, DELIVERY, PICKUP
} = ORDER_SERVICE_TYPES
let delivAreaChange = false

const selectService = async (type, {
  selectedLocation, futureServiceLocationId, customerType, orderArea, removedProducts,
  hasServiceLocationChanged, orderSlotParams
}, diffLocation = false) => {
  const store = window.sharedVue?.config.globalProperties.$store
  delivAreaChange = type === 'D'

  if (!diffLocation) {
    const { futureServiceLoc, hasLocationChanged } = await processServiceLocationChange(type, futureServiceLocationId)
    futureServiceLocationId = futureServiceLoc
    hasServiceLocationChanged = hasLocationChanged
  }
  store.commit('ServiceSelection/setServiceSelectionLoading', true)
  const params = { futureServiceLocationId, customerType, orderArea }
  const responseData = await updateServiceLocationAPI({ dryRun: true }, params)

  if (responseData?.status !== 200) {
    store.commit('ServiceSelection/setServiceSelectionLoading', false)
    const code = responseData?.data?.response?.code
    const EBT_UNAVAILABLE = 'EBT_NOT_CERTIFIED_IN_REQUESTED_SERVICE_LOCATION'
    if (code === EBT_UNAVAILABLE) {
      const alertDetails = {
        header: 'SNAP Payments Coming Soon',
        body: 'SNAP is not available for online orders at this location.',
      }
      const primaryAction = {
        text: 'OK',
        callback: () => {
          store.commit('Alert/clear')
          store.commit('UserPref/setPrefPup', {})
        }
      }
      promptAlert(alertDetails, primaryAction)
      return {}
    }
    const alertDetails = {
      header: 'Couldn\'t Update Shopping Method',
      body: 'Sorry, we currently don\'t deliver to this location. Please edit your delivery address.'
    }
    const primaryAction = {
      text: 'OK',
      callback: () => store.commit('Alert/clear')
    }
    promptAlert(alertDetails, primaryAction)
    return {}
  }

  // payload for promptAlertOrderCancellation
  const payloadForPrompt = {
    removedProducts,
    selectedLocation,
    futureServiceLocationId,
    customerType,
    orderArea,
    orderSlotParams
  }

  let cartWillChange = true
  removedProducts = responseData?.data?.response?.changes?.cart.removedCartProducts
  if (!hasServiceLocationChanged) {
    cartWillChange = false
  } else {
    cartWillChange = removedProducts?.length > 0
  }
  const orderStatus = store.getters['Order/orderStatus']
  if (orderStatus.currentOrder?.status === 'SUBMITTED') {
    promptAlertOrderCancellation(type, cartWillChange, payloadForPrompt)
    return {}
  }
  const cartHasItems = store.getters['Cart/cartHasItems']
  if (type !== 'B' && cartHasItems && !selectedLocationIsOnSpectrum(selectedLocation)) {
    return {
      nonSpectrumView: true,
      locationSelected: selectedLocation,
      typeOfService: type,
      futureServiceLocationId
    }
  }

  // payload for updateServiceLocationID
  const payloadForLocationIdUpdate = {
    removedProducts, selectedLocation, futureServiceLocationId, customerType, orderArea
  }
  if (cartWillChange) {
    return {
      showItemAvailabilityChangedModalValue: true,
      orderUpdateFunction: () => updateServiceLocationID(type, payloadForLocationIdUpdate),
      removeProducts: removedProducts
    }
  }
  await updateServiceLocationID(type, payloadForLocationIdUpdate)
  return {}
}

/**
* Setup data for the requested service location change.
*
* @param {string} type requested new service type
*/
const processServiceLocationChange = async (type, futureServiceLocationId) => {
  let result = {}
  if (type === PICKUP) { result = checkPickUpCartChange(futureServiceLocationId) }
  if (type === DELIVERY) { result = await checkDeliveryCartChange(futureServiceLocationId) }
  if (type === BROWSE) { result = await checkStoreCartChange(futureServiceLocationId) }
  return result
}

const checkPickUpCartChange = (futureServiceLocationId) => {
  const store = window.sharedVue?.config.globalProperties.$store
  const currentServiceLocationId = store.getters['UserProfile/serviceLocationId']
  const prefPup = store.getters['UserPref/prefPup']
  const isFutureServiceLocationIdFromPDP = window.location.pathname.includes('/product/')
  let result = {}
  if (prefPup.addr && prefPup.active && !isFutureServiceLocationIdFromPDP) {
    result = {
      futureServiceLoc: prefPup.serviceLocationId,
      hasLocationChanged: currentServiceLocationId !== prefPup.serviceLocationId
    }
  }
  if (isFutureServiceLocationIdFromPDP) {
    result = {
      futureServiceLoc: futureServiceLocationId,
      hasLocationChanged: currentServiceLocationId !== futureServiceLocationId
    }
  }
  return result
}

const checkDeliveryCartChange = async (futureServiceLocationId) => {
  const store = window.sharedVue?.config.globalProperties.$store
  const currentServiceLocationId = store.getters['UserProfile/serviceLocationId']
  const userId = store?.getters['UserProfile/userId']
  const { customerDefaultDeliveryServiceLocation } = store.getters['UserProfile/userInfo'].refData
  const deliveryResponse = await UserPreferencesAPI.get(USER_PREFERENCE_KEYS.PREFERRED_DELIVERY, userId)
  const deliveryPreference = deliveryResponse?.data?.response?.preference || {}
  if (deliveryPreference.value) {
    return {
      futureServiceLoc: deliveryPreference.value,
      hasLocationChanged: currentServiceLocationId !== futureServiceLocationId
    }
  }
  if (customerDefaultDeliveryServiceLocation.serviceType !== 'D') {
    store.commit('Alert/setAlert', {
      type: 'warning',
      header: 'We\'re sorry',
      body: 'We don\'t have delivery service in this location',
      primary: {
        text: 'Ok'
      }
    })
  }
  return {
    futureServiceLoc: deliveryPreference.value,
    hasLocationChanged: currentServiceLocationId !== futureServiceLocationId
  }
}

const checkStoreCartChange = async (futureServiceLocationId) => {
  const store = window.sharedVue?.config.globalProperties.$store
  const currentServiceLocationId = store.getters['UserProfile/serviceLocationId']
  const userId = store?.getters['UserProfile/userId']
  const inStoreResponse = await UserPreferencesAPI.get(USER_PREFERENCE_KEYS.PREFERRED_IN_STORE, userId)
  const inStorePreference = inStoreResponse?.data?.response?.preference || {}
  let result = {}
  if (inStorePreference.value) {
    result = {
      futureServiceLoc: inStorePreference.value,
      hasLocationChanged: currentServiceLocationId !== futureServiceLocationId
    }
  }
  return result
}

/**
     * Prompt alert for the user.
     *
     * @param {{ type: string, header: string, body: string }} details data defining the error message
     * @param {{ text: string, color: string, callback: Function }} primary data defining the primary button
     * @param {{ text: string, color: string, callback: Function }} secondary data defining the secondary button
     */
const promptAlert = (details = {}, primary = {}, secondary = {}) => {
  const store = window.sharedVue?.config.globalProperties.$store
  const {
    type = 'warning',
    header = 'Error Processing This Request',
    body = 'Unable to process your current request. Please try again.'
  } = details
  store.commit('Alert/setAlert', {
    type, header, body, primary, secondary
  })
}

/**
* Prompt alert for cancelling an order
*
* @param {string} type service location type
* @param {boolean} cartWillChange flag determining if service location/method will result in a cart items change
*/
const promptAlertOrderCancellation = (type, cartWillChange, payload) => {
  const details = {
    type: 'warning',
    header: 'Cancel your current order to update your shopping mode',
    body: 'The items in your cart will be saved, but pricing may vary and you will have to resubmit your order.',
  }
  const primary = {
    text: 'Cancel Order',
    callback: () => alertCancelOrder(type, cartWillChange, payload)
  }
  const secondary = {
    text: 'Don\'t Cancel',
    color: 'fourth',
    callback: () => preventCancelOrder(type)
  }
  promptAlert(details, primary, secondary)
}

const alertCancelOrder = async (type, cartWillChange, {
  removedProducts, selectedLocation, futureServiceLocationId, customerType, orderArea, orderSlotParams
}) => {
  const store = window.sharedVue?.config.globalProperties.$store
  trackCancelOrderPrompt('cancel order')
  const payload = {
    removedProducts, selectedLocation, futureServiceLocationId, customerType, orderArea
  }

  if (cartWillChange) {
    const orderUpdateFunction = async () => {
      const cancelOrderResponse = await store.dispatch('Order/cancelOrder')
      if (cancelOrderResponse?.status >= 400) {
        handleCancelOrderError()
        return
      }
      if (_.isEmpty(orderSlotParams)) {
        updateServiceLocationID(type, payload)
      } else {
        const orderSlotParamsCopy = { ...orderSlotParams }
        const orderStatus = store.getters['Order/orderStatus']
        orderSlotParamsCopy.apiParams.orderId = orderStatus?.currentOrder?.orderId
        orderSlotParamsCopy.basketId = orderStatus?.currentOrder?.basketId
        store.dispatch('Slots/reserveTimeSlot', orderSlotParamsCopy)
      }
    }
    await orderUpdateFunction()
  } else {
    const cancelOrderResponse = await store.dispatch('Order/cancelOrder')
    if (cancelOrderResponse?.status >= 400) {
      this.handleCancelOrderError()
      return
    }
    updateServiceLocationID(type, payload)
  }
  store.commit('Alert/clear')
}

const preventCancelOrder = (type) => {
  const store = window.sharedVue?.config.globalProperties.$store
  store.dispatch('ServiceSelection/modifyOptionLoadingState', { serviceType: type, loading: false })
  store.commit('Alert/clear')
  trackCancelOrderPrompt('do not cancel')
  window.sharedVue.config.globalProperties.$trackClientLog('update_service_location',
    {
      type,
      cancel: 1,
      willCartChange: 1
    })
}

const handleCancelOrderError = () => {
  const store = window.sharedVue?.config.globalProperties.$store
  store.commit('Alert/setAlert', {
    icon: false,
    type: 'error',
    header: 'Order Not Canceled',
    body:
      `We're unable to cancel your order right now. Please try again later.`,
    primary: {
      text: 'OK',
    },
  })
}

const updateServiceLocationAPI = async (payload, {
  futureServiceLocationId, customerType, orderArea, delivAreaChangeOverride = false
}) => {
  const store = window.sharedVue?.config.globalProperties.$store
  const currentServiceLocationId = store.getters['UserProfile/serviceLocationId']
  const userId = store?.getters['UserProfile/userId']
  const allowAutoLogin = store?.getters['SiteConfig/varByName'](('feature_allow_sl_auto_login'))
  const { currentOrderId } = store.getters['UserProfile/userInfo'].information
  const basketId = store.getters['Cart/getBasketId']
  const query = {
    currentServiceLocationId,
    serviceLocationId: futureServiceLocationId,
    userId,
    orderId: currentOrderId,
    delivAreaChange: delivAreaChange || delivAreaChangeOverride,
    custTypeChange: customerType === 'business',
    basketId,
    ...payload
  }
  const result = await ServiceLocationsAPI.update(query, allowAutoLogin)
  if (result?.status === 205) {
    // renew session and reload the page
    const apiVersion = 'v3.0'
    await AutoLoginAPI.perform(apiVersion, futureServiceLocationId)

    if (!payload?.dryRun) {
      await updateUserPreferenceWhenNeeded(payload.serviceType, orderArea)
    }
    DeferredSingleActionService.reset()
    DeferredSingleActionService.setLock(true)
    // setTimeout to resolve NS_BINDING_ABORTED in firefox
    setTimeout(() => {
      window.location.reload()
    }, 1000)
  }
  if (!payload?.dryRun && result?.status !== 205) {
    await updateUserPreferenceWhenNeeded(payload.serviceType, orderArea)
  }
  if (store?.getters['SiteConfig/varByName'](('feature_express_delivery'))) {
    localStorage.setItem('shopMethodChanged', true)
  }
  return result
}

const updateServiceLocationID = (type, {
  removedProducts, selectedLocation, futureServiceLocationId, customerType, orderArea
}) => {
  trackServiceChange(type, removedProducts)
  localStorage.setItem('serviceTypeChange', new Date().getTime())
  if (!_.isEmpty(selectedLocation)
    && !selectedLocationIsOnSpectrum(selectedLocation)
    && type !== ORDER_SERVICE_TYPES.BROWSE
  ) {
    openExternalLink(type, selectedLocation)
  }
  const params = { futureServiceLocationId, customerType, orderArea }
  return updateServiceLocationAPI({ serviceType: type }, params)
}

const trackServiceChange = (type, removedProducts) => {
  return window.sharedVue.config.globalProperties.$trackClientLog('update_service_location', {
    serviceType: type,
    willCartChange: removedProducts?.length ? 1 : 0
  })
}

const openExternalLink = (serviceType, selectedLocation) => {
  const store = window.sharedVue?.config.globalProperties.$store
  const { zip, locationNumber } = selectedLocation
  const isAnyApp = store.getters['NativeContainer/isAnyApp']
  const linkParams = {
    serviceType: SERVICE_TYPE_TEXT[serviceType],
    zipOrStoreNumber: serviceType === ORDER_SERVICE_TYPES.DELIVERY ? zip : locationNumber
  }
  return isAnyApp ? openUnataFromAppToBrowser(linkParams) : openUnataFromWebToBrowser(linkParams)
}

const updateUserPreferenceWhenNeeded = async (serviceType, orderArea) => {
  let response = {}
  if (!_.isEmpty(orderArea)) {
    response = await updateUserPref(serviceType, orderArea)
  }
  return response
}

const updateUserPref = (type, area) => {
  const store = window.sharedVue?.config.globalProperties.$store
  const userId = store?.getters['UserProfile/userId']
  const prefKeys = {
    P: USER_PREFERENCE_KEYS.PREFERRED_PUP,
    D: USER_PREFERENCE_KEYS.PREFERRED_DELIVERY,
    B: USER_PREFERENCE_KEYS.PREFERRED_IN_STORE
  }
  const id = area.serviceType === 'P' ? area.prefPup : area.id
  const prefMerch = store.getters['UserPref/prefMerch']
  if (prefMerch !== 'true' && (type === 'P' || type === 'D')) {
    return UserPreferencesAPI.update(
      USER_PREFERENCE_KEYS.MERCH_OPTION_SHOWN, 'true', userId
    )
  }
  return UserPreferencesAPI.update(prefKeys[type], id, userId)
}

export {
  selectService,
  processServiceLocationChange,
  updateServiceLocationAPI,
  updateServiceLocationID,
  promptAlertOrderCancellation,
}
