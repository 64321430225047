import { LOGGING_TYPE } from 'utils/constants'

/**
 * @param {string} label - used to filter error logs
 * @param {any} error - aditional error information
 * @returns {void}
 */
export function logErrorToClient(label, error) {
  window.sharedVue.config.globalProperties.$trackClientLog(label, { error }, LOGGING_TYPE.exception)
}
