export default {
  namespaced: true,
  state: {
    monthOptions: [
      {
        number: '01'
      },
      {
        number: '02'
      },
      {
        number: '03'
      },
      {
        number: '04'
      },
      {
        number: '05'
      },
      {
        number: '06'
      },
      {
        number: '07'
      },
      {
        number: '08'
      },
      {
        number: '09'
      },
      {
        number: '10'
      },
      {
        number: '11'
      },
      {
        number: '12'
      }
    ],
    yearOptions: [],
    stateOptions: [
      {
        title: 'Alabama',
        value: 'AL'
      },
      {
        title: 'Alaska',
        value: 'AK'
      },
      {
        title: 'Arizona',
        value: 'AZ'
      },
      {
        title: 'Arkansas',
        value: 'AR'
      },
      {
        title: 'California',
        value: 'CA'
      },
      {
        title: 'Colorado',
        value: 'CO'
      },
      {
        title: 'Connecticut',
        value: 'CT'
      },
      {
        title: 'Delaware',
        value: 'DE'
      },
      {
        title: 'District Of Columbia',
        value: 'DC'
      },
      {
        title: 'Florida',
        value: 'FL'
      },
      {
        title: 'Georgia',
        value: 'GA'
      },
      {
        title: 'Hawaii',
        value: 'HI'
      },
      {
        title: 'Idaho',
        value: 'ID'
      },
      {
        title: 'Illinois',
        value: 'IL'
      },
      {
        title: 'Indiana',
        value: 'IN'
      },
      {
        title: 'Iowa',
        value: 'IA'
      },
      {
        title: 'Kansas',
        value: 'KS'
      },
      {
        title: 'Kentucky',
        value: 'KY'
      },
      {
        title: 'Louisiana',
        value: 'LA'
      },
      {
        title: 'Maine',
        value: 'ME'
      },
      {
        title: 'Maryland',
        value: 'MD'
      },
      {
        title: 'Massachusetts',
        value: 'MA'
      },
      {
        title: 'Michigan',
        value: 'MI'
      },
      {
        title: 'Minnesota',
        value: 'MN'
      },
      {
        title: 'Mississippi',
        value: 'MS'
      },
      {
        title: 'Missouri',
        value: 'MO'
      },
      {
        title: 'Montana',
        value: 'MT'
      },
      {
        title: 'Nebraska',
        value: 'NE'
      },
      {
        title: 'Nevada',
        value: 'NV'
      },
      {
        title: 'New Hampshire',
        value: 'NH'
      },
      {
        title: 'New Jersey',
        value: 'NJ'
      },
      {
        title: 'New Mexico',
        value: 'NM'
      },
      {
        title: 'New York',
        value: 'NY'
      },
      {
        title: 'North Carolina',
        value: 'NC'
      },
      {
        title: 'North Dakota',
        value: 'ND'
      },
      {
        title: 'Ohio',
        value: 'OH'
      },
      {
        title: 'Oklahoma',
        value: 'OK'
      },
      {
        title: 'Oregon',
        value: 'OR'
      },
      {
        title: 'Pennsylvania',
        value: 'PA'
      },
      {
        title: 'Rhode Island',
        value: 'RI'
      },
      {
        title: 'South Carolina',
        value: 'SC'
      },
      {
        title: 'South Dakota',
        value: 'SD'
      },
      {
        title: 'Tennessee',
        value: 'TN'
      },
      {
        title: 'Texas',
        value: 'TX'
      },
      {
        title: 'Utah',
        value: 'UT'
      },
      {
        title: 'Vermont',
        value: 'VT'
      },
      {
        title: 'Virginia',
        value: 'VA'
      },
      {
        title: 'Washington',
        value: 'WA'
      },
      {
        title: 'West Virginia',
        value: 'WV'
      },
      {
        title: 'Wisconsin',
        value: 'WI'
      },
      {
        title: 'Wyoming',
        value: 'WY'
      }
    ],
    emailSubjects: [
      {
        title: 'Sign In/Account Assistance',
        value: 'Sign In or Online Account Assistance',
        supportedOpcos: ['STSH', 'GNTL', 'GNTC', 'MRTN', 'FDLN', 'HNFD']
      },
      {
        title: 'Password Assistance',
        value: 'Password Assistance',
        supportedOpcos: ['STSH', 'GNTL', 'GNTC', 'MRTN', 'HNFD']
      },
      {
        title: 'Web Ad Circular',
        value: 'Web Circular',
        supportedOpcos: ['STSH', 'GNTL', 'GNTC', 'MRTN', 'FDLN', 'HNFD']
      },
      {
        title: 'Printed Ad Circular',
        value: 'Printed Circular',
        supportedOpcos: ['STSH', 'GNTL']
      },
      {
        title: 'Web Errors/Technical Support',
        value: 'Web Errors / Technical Support',
        supportedOpcos: ['STSH', 'GNTL', 'GNTC', 'MRTN', 'HNFD']
      },
      {
        title: 'Loyalty Card Updates',
        value: 'Card Updates',
        supportedOpcos: ['STSH', 'GNTL', 'GNTC', 'MRTN', 'HNFD']
      },
      {
        title: 'Rewards and Points',
        value: 'Gas Rewards and Points',
        supportedOpcos: ['STSH', 'GNTL', 'GNTC', 'MRTN', 'HNFD']
      },
      {
        title: 'Digital Coupons',
        value: 'Digital Coupons',
        supportedOpcos: ['STSH', 'GNTL', 'GNTC', 'MRTN', 'HNFD']
      },
      {
        title: 'Pharmacy',
        value: 'Pharmacy',
        supportedOpcos: ['STSH', 'GNTL', 'GNTC', 'MRTN', 'HNFD']
      },
      {
        title: 'Private Brands/Product Quality',
        value: 'Private Brands / Product Quality',
        supportedOpcos: ['STSH', 'GNTL', 'GNTC', 'MRTN', 'FDLN', 'HNFD']
      },
      {
        title: 'Product Requests/Suggestions',
        value: 'Product Requests / Suggestions',
        supportedOpcos: ['STSH', 'GNTL', 'GNTC', 'MRTN', 'FDLN', 'HNFD']
      },
      {
        title: 'Store/Service',
        value: 'Store / Service',
        supportedOpcos: ['STSH', 'GNTL', 'GNTC', 'MRTN', 'FDLN', 'HNFD']
      },
      {
        title: 'Praise/Compliments',
        value: 'Praise / Compliments',
        supportedOpcos: ['STSH', 'GNTL', 'GNTC', 'MRTN', 'FDLN', 'HNFD']
      },
      {
        title: 'General Product Information',
        value: 'General Product Information',
        supportedOpcos: ['FDLN']
      },
      {
        title: 'Other',
        value: 'Other',
        supportedOpcos: ['STSH', 'GNTL', 'GNTC', 'MRTN', 'FDLN', 'HNFD']
      }
    ]
  },
  getters: {
    emailSubjects: state => (opco, mobile = false, tablet = false) => {
      let response = state.emailSubjects
      response = _.filter(response, (({ title, supportedOpcos }) => {
        const notDesktop = mobile || tablet
        const isPrintedAdCircular = (title === 'Printed Ad Circular')
        return notDesktop && isPrintedAdCircular ? false : supportedOpcos.includes(opco)
      }))
      return response
    },
    yearOptions: () => {
      const date = new Date()
      const yearList = []
      const currentYearDate = date.getUTCFullYear() - 2000
      for (let i = currentYearDate; i < (currentYearDate + 10); i += 1) {
        yearList.push({ number: i.toString() })
      }
      return yearList
    },
  }
}
