<template>
  <div class="flex-row h-16 mx-16 justify-center">
    <div
      class="grow-0 shrink basis-[16%] border-solid border-gray-100 border-2"
      :class="[
        { 'rounded-xl rounded-tr-none rounded-br-none': index == 0 },
        { 'rounded-xl rounded-tl-none rounded-bl-none': index == maxLength - 1 },
        { 'border-r-0 ': index !== maxLength - 1 }
      ]"
      v-for='(entry, index) in maxLength'
      :key="index"
    >
      <input
        class="pdl-text-input_simple min-w-0 w-full text-center"
        :ref="`input${index}`"
        inputmode="numeric"
        type="text"
        min="0"
        max="9"
        maxlength="1"
        pattern="[0-9]"
        v-model="digits[index]"
        @input.stop="handleInputChange(index)"
        @keydown="handleKeyDown($event, index)"
        @paste="handlePaste($event)"
        :aria-label="`One time password input ${entry}`"
      >
    </div>
  </div>
</template>

<script>
import { OTP_LENGTH } from 'utils/constants'

export default {
  props: {
    modelValue: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      currentValue: null,
      maxLength: OTP_LENGTH,
      digits: new Array(6),
    }
  },
  methods: {
    handleKeyDown($event, index) {
      if ($event.code === 'Backspace') {
        $event.preventDefault()
        this.clearInput(index)
      } else if ($event.code === 'ArrowLeft') {
        $event.preventDefault()
        this.focusPrev(index)
      } else if ($event.code === 'ArrowRight' || $event.code === 'Space') {
        $event.preventDefault()
        this.focusNext(index)
      }
    },
    handleInputChange(index) {
      this.updateCurrentValue()
      this.focusNext(index)
    },
    handlePaste($event) {
      $event.preventDefault()
      const paste = $event.clipboardData.getData('text/plain').slice(0, this.maxLength)
      this.digits = paste.split('')
      this.updateCurrentValue()
    },
    focusNext(index) {
      if (index < this.digits.length - 1) {
        this.$refs[`input${index + 1}`][0].focus()
      } else {
        this.$refs[`input${index}`][0].blur()
      }
    },
    focusPrev(index) {
      if (index > 0) {
        this.$refs[`input${index - 1}`][0].focus()
      }
    },
    clearInput(index) {
      if (!this.digits[index]?.length && index > 0) {
        this.digits[index - 1] = ''
      } else {
        this.digits[index] = ''
      }

      this.updateCurrentValue()
      this.focusPrev(index)
    },
    updateCurrentValue() {
      this.currentValue = this.digits.join('')
      this.$emit('update:modelValue', this.currentValue)
    },
    resetInput() {
      this.currentValue = null
      this.maxLength = OTP_LENGTH
      this.digits = new Array(6)
    }
  }
}
</script>
