// this maps the url to the file name for lazy loading our modals

const MODAL_URL_MAPS = {
  '/modal/notifications-modal': 'PdlNotificationModal',
  '/modal/weekly-ad-selector': 'PdlWeeklyAdSelectorModal',
  '/modal/new-weekly-ad-selector': 'PdlNewWeeklyAdSelectorModal',
  // need to get these multiple routes figured out. Why are we using different routes for login?
  'guest-transfer-modal': 'PdlLoginFlow',
  'modal/guest-transfer-modal': 'PdlLoginFlow',
  '/modal/guest-transfer-modal': 'PdlLoginFlow',
  '/modal/login': 'PdlLoginFlow',
  '/modal/pod-pass': 'PdlPodPassModal',
  '/modal/rewards/grocery-dollars': 'PdlRewardsGroceryDollarsModal',
  '/modal/rewards/gas-savings': 'PdlRewardsGasSavingsModal',
  '/modal/rewards/special-offers-details': 'PdlSpecialOffersDetailsModal',
  '/modal/rewards/rewards-grocery-dollars-modal': 'PdlRewardsGroceryDollarsModal',
  '/modal/rewards/wallet-grocery-dollars-modal': 'PdlWalletGroceryDollarsModal',
  '/modal/rewards/redeemed-special-offers': 'PdlRedeemedSpecialOffersModal',
  '/modal/rewards/wallet-gas-savings': 'PdlWalletGasSavingsModal'
}

const MODAL_TYPE_TRACKING_LOCATION = {
  PdlCartModal: '/modal/view-cart',
  PdlNotificationModal: '/modal/notifications-modal',
  PdlWeeklyAdSelectorModal: '/modal/weekly-ad-selector',
  PdlNewWeeklyAdSelectorModal: '/modal/new-weekly-ad-selector',
  PdlPodPassModal: '/modal/pod-pass',
  PdlRewardsGroceryDollarsModal: '/modal/rewards/grocery-dollars',
  PdlRewardsGasSavingsModal: '/modal/rewards/gas-savings',
  PdlSpecialOffersDetailsModal: '/modal/rewards/special-offers-detail',
  PdlWalletGroceryDollarsModal: '/modal/rewards/wallet-grocery-dollars-modal',
  PdlRedeemedSpecialOffersModal: '/modal/rewards/redeemed-special-offers',
  PdlWalletGasSavingsModal: '/modal/rewards/wallet-gas-savings',
  PdlCheckoutModal: '/modal/checkout-modal',
  PdlCouponDetails: '/modal/savings/coupon-details'
}

export {
  MODAL_TYPE_TRACKING_LOCATION,
  MODAL_URL_MAPS
}
