import { isAndroidDevice } from 'components/detect/services/detect-services'
import shouldAppForceUpdate from 'utils/native/shouldAppForceUpdate'

export const openAppLink = () => {
  const $store = window?.sharedVue?.config?.globalProperties?.$store
  if (!$store) throw new Error('Store is not available')

  // Additional URL linking logic. This is just to fix the deep-linking issue (linking the app out) to support old
  // and new container and should be removed post version 1
  window.open(
    isAndroidDevice()
      ? $store.getters['SiteConfig/varByName']('brand_android_app_link').replace(/^(https?).*$/, 'loadUrl')
      : $store.getters['SiteConfig/varByName']('brand_ios_app_link')
  )
}

export const checkForceUpdate = (mobileVersion) => {
  if (!mobileVersion) return
  const { $store, NativeContainer } = window?.sharedVue?.config?.globalProperties || {}
  if (!$store || !NativeContainer) return

  if (shouldAppForceUpdate(mobileVersion)) {
    $store.commit('Alert/setAlert', {
      type: 'error',
      header: 'New version available!',
      body: 'There is a newer version of the app available for download. Please update to the latest version.',
      primary: {
        text: 'Update',
        callback: () => {
          openAppLink()
          // re-check on click
          checkForceUpdate(mobileVersion)
        }
      }
    })
  }
}
