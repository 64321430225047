import { helpers } from '@vuelidate/validators'
import { PHONE_NUMBER } from 'utils/regex'

export default {
  format(phone) {
    return (
      new RegExp(PHONE_NUMBER).test(
        phone
      ) || !helpers.req(phone)
    )
  }
}
