<template>
  <div class="checkbox"
    :class="{
      'pl-20': !mainCheckboxVisibility,
      'pl-0': mainCheckboxVisibility
    }"
  >
    <input
      :id="id"
      :name="name"
      :class="[
        'checkbox_input',
        inputClass,
        {
          'opacity-0': mainCheckboxVisibility,
          'opacity-100': !mainCheckboxVisibility
        }
      ]"
      type="checkbox"
      :checked="checked"
      :aria-checked="isChecked.toString()"
      :disabled="isDisabled"
      :value="value"
      @click="onClick"
      @change="onChange"
    >
    <label
      :for="id"
      :class="[
        'checkbox_label ',
        {'checkbox_label--dropdown': isComponentStyle,
         'checkbox_label--normal-line-height': normalLineHeight,
         'checkbox_label--short': label === ''}
      ]"
    >
      <div
        v-if="!hideLabel"
        :class="{
          'checkbox_text--left': !alignTextRight,
          'checkbox_text--bold': isBold,
          'checkbox_text--direction-column': !textDirectionRow,
        }"
        class="checkbox_text"
      >
        <div
          class="checkbox_text--direction-row"
        >
          <span> {{ label }} </span>
          <slot name="infoCount" />
          <slot name="infoIcon" />
        </div>
        <slot /><slot name="subLabel" />
      </div>
      <slot
        v-if="isComponentStyle"
        name="labelIcon"
      />
      <div
        :class="[
          'checkbox_box',
          boxClass,
          {'opacity-0' : !mainCheckboxVisibility}]"
      >
        <PdlIconBase
          icon-title="Checked"
          icon-class="vector-icon-stroke--prime"
          container-class="checkbox_icon vector-icon-size--medium"
        >
          <PdlIconCheckmark />
        </PdlIconBase>
      </div>
    </label>
    <slot
      v-if="!isComponentStyle"
      name="labelIcon"
    />
  </div>
</template>
<script>
import {
  PdlIconBase,
} from '@/shared/icons'
import PdlIconCheckmark from 'components/icons/icon/PdlIconCheckmark'

export default {
  components: {
    PdlIconBase,
    PdlIconCheckmark
  },
  emits: ['change', 'click'],
  props: {
    label: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      required: true,
    },
    inputClass: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      required: true
    },
    checked: {
      type: Boolean,
      default: false
    },
    value: {
      type: [String, Number, Boolean],
      required: true
    },
    isComponentStyle: {
      type: Boolean,
      default: false,
    },
    hideLabel: {
      type: Boolean,
      default: false
    },
    alignTextRight: {
      type: Boolean,
      default: true
    },
    normalLineHeight: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    isBold: {
      type: Boolean,
      default: false
    },
    textDirectionRow: {
      type: Boolean,
      default: false
    },
    mainCheckboxVisibility: {
      type: Boolean,
      default: true
    },
    boxClass: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      isChecked: false
    }
  },
  beforeMount() {
    this.isChecked = this.checked
  },
  beforeUpdate() {
    this.isChecked = this.checked
  },
  methods: {
    onClick(event) {
      this.$emit('click', event)
    },
    onChange(event) {
      const { checked, value } = event.target
      this.isChecked = !this.isChecked
      this.$emit('change', {
        value,
        checked,
        name: this.label,
        key: this.name
      })
    }
  }
}
</script>
