import ApiService from '@/api/'
import { SLOTS_CACHE_EXPIRY_IN_MILLISECONDS } from '@/utils/constants'
import { isCacheExpired, getCache, setCache } from '@/utils/cache/cacheSlots'

export const fetchSlots = (payload = {}, cacheSlots = false, correlationID = '') => {
  const { userId, basketId } = payload
  let endpoint = `/api/v6.0/user/${payload.userId}/${payload.serviceLocationId}/slots`
  const ortecEnabled = window.sharedVue.config.globalProperties.$store.getters['SiteConfig/varByName'](
    'feature_ortec_mapper'
  )
  if (ortecEnabled) {
    endpoint = `/api/v7.0/user/${payload.userId}/${payload.serviceLocationId}/slots`
  }
  const method = 'get'
  const expectedSuccessCodes = [200, 201, 205]
  const expectedErrorCodes = [400, 500, 302]
  const headers = { 'X-Correlation-Id': correlationID }

  return {
    getInfo: () => ({
      userId, basketId, endpoint, method, expectedSuccessCodes, expectedErrorCodes
    }),
    execute: () => {
      let key = `${userId}${payload?.serviceLocationId}${payload?.viewDate}`
      if (cacheSlots) {
        const cache = getCache(key)
        if (cache && !isCacheExpired(cache)) {
          return cache.data
        }
        window.sessionStorage.removeItem(key)
      }
      return ApiService.get(endpoint, {
        params: {
          viewDate: payload.viewDate,
          serviceType: payload.serviceType,
          headers: payload.headers,
          selected: payload.selected,
          delivAvail: payload.delivAvail,
          pupAvail: payload.pupAvail,
          pupId: payload.pupId
        },
        headers
      }).then((data) => {
        if (!payload?.viewDate && !!data?.data?.response?.viewDate) {
          key = `${userId}${payload?.serviceLocationId}${data?.data?.response?.viewDate}`
        }
        if (cacheSlots && data?.status === 200) {
          setCache(key, data, SLOTS_CACHE_EXPIRY_IN_MILLISECONDS)
        }
        return data
      })
    }
  }
}

export const reserveSlots = (payload = {}) => {
  const { userId } = payload
  const orderOrBasketId = payload.basketId
  const endpoint = `/api/v6.0/user/${userId}/orders/${orderOrBasketId}/slot`
  const method = 'post'
  const expectedSuccessCodes = [200, 201, 205]
  const expectedErrorCodes = [400, 500]
  return {
    getInfo: () => ({
      userId, orderOrBasketId, endpoint, method, expectedSuccessCodes, expectedErrorCodes
    }),
    execute: () => ApiService.post(endpoint, {
      slotId: payload.apiParams.slotId,
      serviceLocationId: payload.apiParams.serviceLocationId,
      userId: payload.apiParams.userId,
      orderId: payload.apiParams.orderId
    })
  }
}

export const cancelSlot = (params = {}) => {
  const { userId, basketId } = params
  const endpoint = `/api/v6.0/user/${userId}/orders/${basketId}/slot`
  const method = 'delete'
  const expectedSuccessCodes = [200, 201, 205]
  const expectedErrorCodes = [400, 500]
  return {
    getInfo: () => ({
      userId, basketId, endpoint, method, expectedSuccessCodes, expectedErrorCodes
    }),
    execute: () => ApiService.delete(endpoint)
  }
}
