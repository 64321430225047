<template>
  <Transition name="slideFromRight">
    <div
      :class="[
        `flex bg-white shadow-lg z-10 items-center
        border-solid border-gray-200 border-b border-t border-r`
      ]"
    >
      <div class="w-4 self-stretch bg-[#428202] border-[#428202] border-solid border-b border-t border-l"/>
      <div class="px-6 py-8 font-size-16">
        <h3 class="font-bold pb-2">
          {{ title }}
        </h3>
        <p> {{ text }}</p>
      </div>
      <button
        class="h-fit px-8"
        @click.stop="close"
      >
        <PdlIconBase
          container-class="vector-icon-size--small vector-icon-color--darker-grey"
          icon-title="Close"
        >
          <PdlIconClose/>
        </PdlIconBase>
      </button>
    </div>
  </Transition>
</template>

<script>
import PdlIconClose from 'components/icons/icon/PdlIconClose'

export default {
  components: {
    PdlIconClose
  },
  props: {
    title: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    timeout: {
      type: Number,
      default: null
    }
  },
  created() {
    if (this.timeout) {
      setTimeout(() => {
        this.close()
      }, this.timeout)
    }
  },
  methods: {
    close() {
      this.$emit('close')
    }
  }
}
</script>
