<template>
  <div class="flex flex-col mr-2 w-full sm:w-9/12">
    <div class="flex">
      <button
        class="rounded-l-2xl border-solid border-2 border-gray-100 border-r-0 px-6 w-auto"
        aria-label="Decrease Points"
        :disabled="decrementDisabled"
        @click="decrement"
      >
        <PdlIconBase
          icon-title="Minus Icon"
          :icon-class="{
            'mt-2 fill-current h-6 w-6': true,
            'text-gray-300' : decrementDisabled,
            'text-increment' : !decrementDisabled
          }"
        >
          <PdlIconMinus />
        </PdlIconBase>
      </button>
      <div
        class="font-bold text-center text-black bg-gray-50 w-full p-4 text-sm
          sm:text-base border-solid border-2 border-gray-100 leading-8"
      >
        {{ displayText }}
      </div>
      <button
        class="rounded-r-2xl border-solid border-2 border-gray-100 border-l-0 px-6 w-auto"
        aria-label="Add Points"
        :disabled="incrementDisabled"
        @click="increment"
      >
        <PdlIconBase
          icon-title="Plus Icon"
          :icon-class="{
            'mt-2 fill-current h-6 w-6': true,
            'text-gray-300' : incrementDisabled,
            'text-increment' : !incrementDisabled
          }"
        >
          <PdlIconPlus />
        </PdlIconBase>
      </button>
    </div>
    <div class="p-4 text-center mb-16 md:mb-0">
      <button
        class="text-base font-bold"
        :class="{
          'text-primary' : !maxDisabled,
          'text-gray-300' : maxDisabled
        }"
        :disabled="maxDisabled"
        @click="max"
      >
        {{ maxDisplayText }}
      </button>
    </div>
    <div class="text-sm pt-1 text-center text-gray-600">
      {{ subText }}
    </div>
  </div>
</template>

<script>
import PdlIconPlus from 'components/icons/icon/PdlIconPlus'

export default {
  components: {
    PdlIconPlus
  },
  props: {
    displayText: {
      type: String,
      required: true
    },
    maxDisplayText: {
      type: String,
      default: 'Select maximum amount'
    },
    subText: {
      type: String,
      default: ''
    },
    decrementDisabled: {
      type: Boolean,
      default: false
    },
    incrementDisabled: {
      type: Boolean,
      default: false
    },
    maxDisabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    increment() {
      this.$emit('increment')
    },
    decrement() {
      this.$emit('decrement')
    },
    max() {
      this.$emit('max')
    }
  }
}
</script>
