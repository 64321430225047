<template>
  <div>
    <!-- Confirmation Component -->
    <ContactConfirm
      v-if="isSuccess"
      :name="formatName"
    />
    <div
      v-else
      class="form_container"
    >
      <label
        class="mb-7 font-semibold"
        aria-label="Contact Us Email Subjects Dropdown"
        for="emailSubject-codes"
      >
        Choose a topic
      </label>
      <PdlSelect
        id="emailSubject-codes"
        :model-value="v$.contactEmailSubject.$model"
        :options="filterEmailSubjects"
        class="width--full m-0"
        :error="v$.contactEmailSubject.$error"
        :is-required="true"
        :full-width="true"
        @update:modelValue="updateEmailSubjectSelection"
      >
        <template #errors>
          <span>{{ emailSubjectMessage.required }}</span>
        </template>
      </PdlSelect>
      <GeneralContactForm
        class="form trailer--double"
        :opco="opco"
        :contact-email-subject="contactEmailSubject"
        :is-mobile="isMobile"
        :is-tablet="isTablet"
        :is-mobile-device="isMobileDevice"
        @log="logAnalytics"
        @name-change="onNameChange"
      />
      <!-- Common Content -->
      <p class="pt-7">
        Because of the large volume of emails we receive, it may
        take up to 7 business days to respond. If your issue requires
        more immediate attention, please call us  at {{ brandContactNumber }}.
        Or call your local store directly.
      </p>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { GTM_GA4 } from 'utils/constants'
import { trackHelpSubmit } from 'utils/tracking/help/trackHelpSubmit'
import ContactConfirm from './ContactConfirm'
import GeneralContactForm from './GeneralContactForm'

export default {
  components: {
    ContactConfirm,
    GeneralContactForm
  },
  data: () => ({
    formUserName: '',
    contactEmailSubject: '',
    emailSubjectMessage: {},
    printedCircularSelected: false,
    loading: false
  }),
  computed: {
    ...mapState({
      contactUsStatus: state => state.ContactUs.contactUsStatus,
      displayError: state => state.ContactUs.displayError,
      trackUserStatus: state => state.ContactUs.trackUserStatus
    }),
    ...mapGetters('FormMessages', [
      'messages'
    ]),
    ...mapGetters('SiteConfig', [
      'opco',
      'varByName',
      'brandContactNumber'
    ]),
    ...mapGetters('ScreenSize', [
      'isMobile',
      'isTablet',
      'isMobileDevice'
    ]),
    ...mapGetters('LoyaltyAccount', [
      'isLoyaltyRegistered',
      'firstName',
      'lastName',
      'isLoyaltyInfoComplete'
    ]),
    hasLoyaltyAccount() {
      // check if guest/ authenticated/ missingInfo_authenticated user
      // AUSA brands requires user to check termsAndConditions in order to be registered/authenticated
      return this.isLoyaltyInfoComplete && this.isLoyaltyRegistered
    },
    cardName() {
      return this.varByName('brand_rewards_program_name')
    },
    filterEmailSubjects() {
      return this.$store.getters['FormDataStructures/emailSubjects'](
        this.opco, this.isMobile, this.isTablet
      ) || []
      // Hide EmailSubject Printed Ad Circular for MRTN/GNTC and mobile breakpoint
    },
    isSuccess() {
      // Show confirmation Component
      return this.contactUsStatus
    },
    formatName() {
      const loyaltyFullName = (this.isLoyaltyRegistered) ? `${this.firstName} ${this.lastName}` : ''
      return (this.formUserName) ? this.formUserName : loyaltyFullName
    }
  },
  setup: () => ({ v$: useVuelidate() }),
  created() {
    this.formUserName = ''
    // Fetch the grocery stores for store location drop-down
    // reset the vuex store (since we use it in multiple screens)
    this.resetState()
  },
  mounted() {
    this.contactEmailSubject = this.filterEmailSubjects[0].value
  },
  watch: {
    isSuccess() {
      if (this.$route.path === '/contact/email-more') {
        this.logAnalytics(this.contactEmailSubject)
      }
    }
  },
  methods: {
    ...mapActions({
      resetState: 'ContactUs/resetState'
    }),
    updateEmailSubjectSelection(codeSelection) {
      // Update selected emailSubject in drop-down and show respective form
      this.$store.commit('ContactUs/setDisplayError', false)
      if (codeSelection === 'Printed Circular') {
        this.printedCircularSelected = true
        // go to printed ad page
        this.$emit('go-to', 'printedAd')
      } else {
        this.printedCircularSelected = false
      }
      this.contactEmailSubject = codeSelection
    },
    onNameChange(name) {
      this.formUserName = name || ''
    },
    logAnalytics(emailSubject) {
      trackHelpSubmit({
        userLoggedIn: this.trackUserStatus,
        emailSubject,
        status: this.contactUsStatus
      })
      this.$trackGA4Event(GTM_GA4.click, {
        action: 'help center contact us form submit',
        result: emailSubject,
        link_text: 'Submit',
        link_id: 'submit-comment',
        type: 'help center',
        site_location: 'Help Center - Contact Us',
      })
    }
  },
  validations: {
    contactEmailSubject: {
      required
    },
  }
}
</script>
