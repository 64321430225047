import ApiService from '@/api/'

const PATH = '/api/v4.0/user/payment'
const route = '/api/v2.0/user/card'
const newRoute = '/api/v4.0/user/payment'

export default {
  // Get multiple payment methods
  getPaymentmethods(userId) {
    return ApiService.get(`/api/v6.0/user/payment/${userId}/paymentmethods/all`)
  },
  delete(userId, payload) {
    return ApiService.delete(`${PATH}/${userId}/paymentmethod`, payload)
  },
  getApplePaymentSession(userId, payload) {
    return ApiService.post(`/api/v1.0/user/wallet/${userId}/apple/paymentsession`, payload)
  },
  getPaymentSession(userId, payload) {
    return ApiService.post(`/api/v2.0/user/wallet/${userId}/wallet/paymentsession`, payload)
  },
  getPublicKey(userId) {
    return ApiService.get(`${PATH}/${userId}/ebt/publickey`)
  },
  addEbtCard(userId, payload) {
    return ApiService.put(`${PATH}/${userId}/ebt`, payload)
  },
  ebtInitiate(userId, payload) {
    return ApiService.corePost(`${PATH}/${userId}/ebt/initiate`, payload)
  },
  get(version = '', userId = 0) {
    let versionedRoute
    if (version === 'v6.0') {
      if (!userId) return Promise.reject(new Error('User Id required for V6 api'))
      versionedRoute = `/api/v6.0/user/payment/${userId}/paymentmethods/primarycard`
    } else {
      versionedRoute = route
    }
    return ApiService.get(versionedRoute)
  },
  update(payload, userId) {
    return ApiService.put(`${newRoute}/${userId}/creditcard`, payload)
  }
}
