<template>
  <div
    :class="{'feedback--hidden': !visible}"
    class="feedback"
    @keyup.esc="onOtherClick()"
  >
    <p
      class="order-rating_component-title"
    >
      {{ feedbackTitle }}
    </p>
    <div
      class="feedback_block"
      aria-live="polite"
    >
      <ul
        :class="{'feedback_list--active': !otherVisible && visible}"
        class="feedback_list"
      >
        <li
          v-for="(option, key) in options"
          :key="key"
          class="feedback_list-item"
        >
          <button
            :aria-label="option"
            :class="['button--reset feedback_trigger', {'feedback_trigger--active': isActive(key)}]"
            @click="onItemClick(key)"
          >
            {{ option }}
          </button>
        </li>
        <li class="feedback_list-item">
          <button
            :class="['button--reset feedback_trigger', {'feedback_trigger--active': otherSelected}]"
            @click="onOtherClick()"
          >
            {{ otherTitle }}
          </button>
        </li>
      </ul>
      <button
        :disabled="!showPseudoField"
        :aria-label="`Click to ${otherFormActionTitle}`"
        :class="['button--reset feedback_pseudo', {'feedback_pseudo--hidden': !showPseudoField || otherVisible }]"
        @click="toggleOther()"
      >
        {{ otherInputValue ? otherInputValue : `${otherFormTitle}...` }}
      </button>
      <div
        :class="{'feedback_other--active': otherVisible}"
        class="feedback_other"
      >
        <button
          aria-label="close other feedback"
          class="button--reset feedback_close"
          tabindex="0"
          @click="closeOther()"
        >
          <PdlIconBase
            icon-title="Close Icon"
            icon-class="vector-icon-color--gray"
            container-class="vector-icon-size--xxsmall"
          >
            <PdlIconClose />
          </PdlIconBase>
        </button>
        <label
          for="feedback-textarea"
          class="sr-only"
        >{{ otherFormTitle }}</label>
        <textarea
          id="feedback-textarea"
          ref="feedbackotherfield"
          v-model="otherInputValue"
          class="feedback_other-input"
          :maxlength="otherFormLimit"
          :placeholder="`${otherFormTitle}... (limit ${otherFormLimit} characters)`"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  PdlIconBase,
} from '@/shared/icons'
import PdlIconClose from 'components/icons/icon/PdlIconClose'

export default {
  components: {
    PdlIconBase,
    PdlIconClose
  },
  props: {
    positiveMessage: {
      type: String,
      default: 'Give positive feedback'
    },
    negativeMessage: {
      type: String,
      default: 'Give negative feedback'
    },
    otherTitle: {
      type: String,
      default: 'Other'
    },
    otherFormActionTitle: {
      type: String,
      default: 'expand on your experience'
    },
    otherFormTitle: {
      type: String,
      default: 'Please expand on your experience'
    },
    visible: {
      type: Boolean,
      default: true
    },
    otherFormLimit: {
      type: Number,
      default: 255
    },
    positiveOptions: {
      type: Array,
      default: () => ([
        'Friendly',
        'Great Conversation',
        'Well informed of out-of-stock or subs',
        'On-Time',
        'Very knowledgeable'
      ])
    },
    negativeOptions: {
      type: Array,
      default: () => ([
        'Wasn\'t friendly',
        'Groceries damaged',
        'Not informed of out-of-stock or subs',
        'Late',
        'Not knowledgeable'
      ])
    },
    positive: {
      type: Boolean,
      default: false
    },
    selections: {
      type: Array,
      default: () => []
    },
    comment: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      selectedKeys: [],
      otherVisible: false,
      otherInputValue: ''
    }
  },
  computed: {
    otherSelected() {
      return this.otherInputValue || this.otherVisible
    },
    showPseudoField() {
      return this.otherInputValue || this.selectedKeys.length > 0
    },
    selected() {
      const map = this.selectedKeys.map(item => this.options[item])

      return {
        reasons: map,
        comment: this.otherInputValue
      }
    },
    feedbackTitle() {
      return (this.positive) ? this.positiveMessage : this.negativeMessage
    },
    options() {
      return (this.positive) ? this.positiveOptions : this.negativeOptions
    }
  },
  watch: {
    positive() {
      this.resetFields()
    },
    otherInputValue(value) {
      this.onInputChange(value)
    },
    otherVisible(value) {
      if (value) {
        window.setTimeout(() => {
          this.$refs.feedbackotherfield.focus()
        }, 200)
      }
    }
  },
  created() {
    this.selectedKeys = this.selections.map(v => this.options.indexOf(v))
    this.otherInputValue = this.comment
  },
  methods: {
    resetFields() {
      this.selectedKeys = []
    },
    onItemClick(key) {
      const index = this.selectedKeys.indexOf(key)

      if (index !== -1) {
        this.selectedKeys.splice(index, 1)
      } else {
        this.selectedKeys.push(key)
      }

      this.onSelected(this.selected)
    },
    onOtherClick() {
      if (this.otherInputValue) {
        this.otherVisible = false
      } else {
        this.otherVisible = true
      }
    },
    closeOther() {
      this.otherVisible = false
    },
    toggleOther() {
      this.otherVisible = !this.otherVisible
    },
    onInputChange() {
      this.onSelected(this.selected)
    },
    isActive(key) {
      return this.selectedKeys.includes(key)
    },
    onSelected(payload) {
      this.$emit('rating-feedback-selected', payload)
    }
  }
}
</script>
