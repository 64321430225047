<template>
  <div :class="{ 'container--full-width': fullWidth }">
    <div
      v-if="tabs.length >= 2"
      class="tabs-wrapper"
      :class="[
        { 'tabs-wrapper--remove-side-padding': !fullWidth && !flex && removeSidePadding },
        { 'tabs-wrapper--flex': !fullWidth && !removeSidePadding && flex },
        { 'tabs-wrapper--centered' : isCentered },
        { 'tabs-wrapper--gray' : hasGrayBackground },
        { 'hidden-on-mobile' : hideTabsOnMobile },
        wrapperClassOverride ? wrapperClassOverride : ''
      ]"
    >
      <ul
        class="tablist"
        :class="[
          { 'container--full-width': fullWidth && !flex },
          { 'tablist--flex': flex }
        ]"
        role="tablist"
      >
        <!-- eslint-disable-next-line vuejs-accessibility/interactive-supports-focus -->
        <li
          v-for="(tab, index) in tabs"
          :id="`${tab.url}-tab`"
          :key="index"
          role="tab"
          :aria-controls="`${tab.url}-panel`"
          :tabindex="tab.url === selectedTab ? 0 : -1"
          :class="[
            'tab',
            activeClass,
            { 'tab--primary-disabled': !isUtility && loading},
            { 'tab--utility-disabled': isUtility && loading},
            { 'tab--shrink': tabShrink },
            { 'is-active': $route.name === tab.url },
            { 'container--full-width': fullWidth },
            { 'tab--flex': flex }
          ]"
          @click="dispatchChange(tab)"
          @keyup.left.right="onArrowKeyUp(tab, $event)"
        >
          {{ tab.name }}{{ tab.count ? ` (${tab.count})` : '' }}
        </li>
      </ul>
      <slot name="sideButton" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PdlControlledTabs',
  props: {
    isUtility: { type: Boolean, default: false },
    fullWidth: { type: Boolean, default: false },
    isCentered: { type: Boolean, default: false },
    hideTabsOnMobile: { type: Boolean, default: false },
    hasGrayBackground: { type: Boolean, default: false },
    removeSidePadding: { type: Boolean, default: false },
    wrapperClassOverride: { type: String, default: '' },

    flex: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    tabShrink: { type: Boolean, default: false },

    /**
     * An array of tab data objects.
     */
    tabs: {
      type: Array,
      default: () => ([])
    },

    /**
     * The selected tab is controlled externally.
     */
    selectedTab: { type: String, default: '' }
  },
  data() {
    return {}
  },
  computed: {
    selectedTabIndex() {
      return this.tabs.findIndex(tab => tab.url === this.$route.name)
    },
    activeClass() {
      if (this.isUtility) return 'tab--utility'
      if (this.isPrimaryAlt) return 'tab--primary-alt'
      return 'tab--primary'
    }
  },
  methods: {
    dispatchChange({ url }) {
      if (url !== this.selectedTab) this.$emit('tab-change', { url })
    },
    onArrowKeyUp(tab, event) {
      const mod = (x, m) => ((x % m) + m) % m
      const delta = { ArrowRight: 1, ArrowLeft: -1 }[event.key]
      const newIndex = mod(this.selectedTabIndex + delta, this.tabs.length)
      this.dispatchChange({ url: this.tabs[newIndex].url })
    },
  }
}
</script>
