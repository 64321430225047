// temporarily store the search information when switching the modal
export default {
  namespaced: true,
  state: {
    suggestedKeyword: '',
    currentFiltersInAddItem: [],
    sortString: 'bestMatch asc',
    editOrderModals: [
      'PdlOrderAddItemsModal',
      'PdlPreCutoffDetailsModal',
      'PdlPastPurchaseModal'
    ]
  },
  mutations: {
    setSuggestedKeyword(state, payload) {
      state.suggestedKeyword = payload
    },
    setCurrentFiltersInAddItem(state, payload) {
      state.currentFiltersInAddItem = payload
    },
    setSortString(state, payload) {
      state.sortString = payload
    }
  },
  actions: {
    cleanData({ commit }) {
      commit('setSuggestedKeyword', '')
      commit('setCurrentFiltersInAddItem', [])
      commit('setSortString', 'bestMatch asc')
    }
  }
}
