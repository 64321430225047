import { sessionCacheObserverService } from 'components/cache/services/session-cache-observer-service'
import { isDefined } from 'utils/basic/ref-checkers'
import UserPreferencesAPI from 'api/UserPreferencesAPI'

/**
 * @description This service contains common CRUD operations for user preferences.
 */
function UserPreferenceService() {
  let preferenceCache = {}
  sessionCacheObserverService.addDependent(this)

  const saveResponseDataToCache = function (preferenceName, responseData) {
    preferenceCache[preferenceName] = { value: responseData?.data?.response?.preference?.value || null }
  }

  /**
   * Gets the value of a preference.
   * @param preferenceName
   * @returns {Promise<*>}
   */
  const get = async (preferenceName, userId = '') => {
    if (!isDefined(preferenceCache[preferenceName])) {
      const responseData = await UserPreferencesAPI.get(preferenceName, userId)
      saveResponseDataToCache(preferenceName, responseData)
    }

    return preferenceCache[preferenceName]
  }

  /**
   * Updates and returns the new value for a given preference.
   * @param preferenceName
   * @param value
   * @returns {Promise<*>}
   */
  const update = async (preferenceName, value, userId = '') => {
    await UserPreferencesAPI.update(preferenceName, value, userId)
    preferenceCache[preferenceName] = { value }
    return preferenceCache[preferenceName]
  }

  /**
   * Refreshes the given preference.
   *
   * @param preferenceName
   * @returns {Promise<*>}
   */
  const refreshPreference = async (preferenceName, userId = '') => {
    const responseData = await UserPreferencesAPI.get(preferenceName, userId)
    saveResponseDataToCache(preferenceName, responseData)
    return preferenceCache[preferenceName]
  }

  /**
   * Refreshes all preferences.
   * @returns {Promise<unknown[]>}
   */
  const refresh = async () => {
    return Promise.all(Object.keys(preferenceCache).map(key => refreshPreference(key)))
  }

  /**
   * Clears all preferences.
   */
  const clear = () => {
    preferenceCache = {}
  }

  return {
    update, get, refreshPreference, refresh, clear
  }
}

const userPreferenceService = new UserPreferenceService()
export default userPreferenceService
