import AutoLoginAPI from 'api/AutoLoginAPI'
import { redirectToHome, redirectToHomeWithErrorCode } from 'components/auth/services/auth-helper-service'
import { sessionCacheObserverService } from 'components/cache/services/session-cache-observer-service'

class DeferredSingleAction {
  constructor() {
    this.initPhase = false
    this.lock = false
    this.lastApiRequestTime = null
    this.sessionExpireCount = 0
    this.httpBuffer = []
    this.PAGE_RELOAD_TIMEOUT = 24 * 60 * 60 * 1000 // 24 hours
    this.MAX_RETRY_AUTO_LOGIN_COUNT = 5
  }

  isLocked() {
    return this.lock
  }

  setLastApiRequestTime(time) {
    this.lastApiRequestTime = time
  }

  isComplete() {
    return this.initPhase
  }

  execute() {
    this.lock = true
    this.sessionExpireCount += 1
    const currentTime = new Date().getTime()
    const appStartTime = Number(window.sessionStorage.getItem('app_start_time'))
    const timeSinceAppStart = currentTime - appStartTime
    // prevent loop of auto-login and/or reload screen for idle 24 hours
    if (this.sessionExpireCount > this.MAX_RETRY_AUTO_LOGIN_COUNT || timeSinceAppStart > this.PAGE_RELOAD_TIMEOUT) {
      window.initPhase.flag = true
      this.setSessionExpiredAlert()
      return Promise.reject(new Error('Session Expired'))
    }
    const { currentServiceLocationId = '' } = appConfig?.user || {}
    const apiVersion = 'v3.0'
    return AutoLoginAPI.perform(apiVersion, currentServiceLocationId)
      .then((response) => {
        sessionCacheObserverService.notify()
        return response
      })
  }

  setLock(val) {
    this.lock = val
  }

  reset() {
    this.lock = false
  }

  setInit() {
    this.initPhase = true
  }

  setSessLockout() {
    window.sharedVue.config.globalProperties.$store.commit('Alert/setAlert', {
      icon: false,
      header: 'Hang tight!',
      body: 'Customer Care is looking into resolving your issue. Please wait a few minutes and try again.',
      type: 'error',
      primary: {
        text: 'Continue Shopping',
        callback: () => {
          this.execute().then(() => {
            window.sharedVue.config.globalProperties.$trackClientLog('session_event', {
              event: 'LOCKOUT_RESUME',
            })
            redirectToHome()
          })
        }
      }
    })
  }

  setSessionAbortAlert() {
    window.sharedVue.config.globalProperties.$store.commit('Alert/setAlert', {
      icon: false,
      header: 'We\'re Sorry!',
      body: `You have been logged in on another device. Don't worry!
          We saved any items you placed in your cart. If you selected a time slot
          but didn't checkout, you will need to select a time slot.`,
      type: 'error',
      primary: {
        text: 'Continue Shopping',
        callback: () => {
          redirectToHomeWithErrorCode('SESS_ABORT_KEY')
          this.reset()
        }
      }
    })
  }

  setCFLockoutCallback() {
    window.sharedVue.config.globalProperties.$store.commit('Alert/setAlert', {
      icon: false,
      header: 'We\'re Sorry!',
      body: 'Something went wrong and we were unable to load the page',
      type: 'error',
      primary: {
        text: 'Continue Shopping',
        callback: () => {
          redirectToHomeWithErrorCode('SESS_ABORT_KEY')
          this.reset()
        }
      }
    })
  }

  setSessionExpiredAlert() {
    window.sharedVue.config.globalProperties.$store.commit('Alert/setAlert', {
      icon: false,
      header: 'Session Expired',
      body: 'Select continue to refresh your session',
      type: 'warning',
      primary: {
        text: 'Continue',
        callback: () => {
          this.setLock(true)
          redirectToHomeWithErrorCode('SESS_EXPIRE_KEY')
        }
      }
    })
  }
}

const deferredSingleAction = new DeferredSingleAction()

export default deferredSingleAction
