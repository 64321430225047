/**
 * Creates an object containing data parsed from the specified query string.
 * @param {string} queryString - the query string to parse.
 *
 * @return {object} queryData
 */
export default (queryString) => {
  return Array
    .from(new URLSearchParams(queryString))
    .reduce((accum, [name, value]) => ({ ...accum, [name]: value }), {})
}
