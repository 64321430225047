export const getJiracoreInfo = (content) => {
  let jiracoreInfo
  if (content !== undefined) {
    const scheduleId = content.key || content.ScheduleId
    const contentId = content['Content Container Key'] ? content['Content Container Key'] : content.scheduledContentKey
    const subtaskId = content['Content Container Key'] ? content.scheduledContentKey : 'null'
    jiracoreInfo = `${scheduleId} | ${contentId} | ${subtaskId}`
  }
  return jiracoreInfo
}
