<template>
  <PdlNavItemBase
    :id-prefix="idPrefix"
    :list-item-class="listItemClass"
    :button-class="buttonClass"
    :link-class="linkClass"
    :url="url"
    :label="label"
    :link-type="linkType"
    :is-active="isActive"
    :render-as-button="renderAsButton"
    @nav-item-base-clicked="onNavItemClick"
    @focus-in="emitFocusIn"
  >
    {{ label }}
    <span
      v-if="linkType === 'Dropdown Menu' && isMobile"
      class="caret caret--right float-right"
    />
    <div
      v-if="hasRightCaret"
      v-show="!mainNavActive"
      class="nav-item_caret"
    >
      <PdlIconBase
        icon-title="down arrow"
        container-class="vector-icon-size--medium vector-icon-stroke--prime"
      >
        <PdlIconCarrotDown />
      </PdlIconBase>
    </div>
    <div
      v-if="hasRightCaret"
      v-show="mainNavActive"
      class="nav-item_caret"
    >
      <PdlIconBase
        icon-title="down up"
        container-class="vector-icon-size--medium vector-icon-stroke--prime"
      >
        <PdlIconCarrotUp />
      </PdlIconBase>
    </div>
    <template #navDropDown>
      <slot />
    </template>
  </PdlNavItemBase>
</template>

<script>
import PdlIconCarrotDown from 'components/icons/icon/PdlIconCarrotDown'
import PdlIconCarrotUp from 'components/icons/icon/PdlIconCarrotUp'
import PdlNavItemBase from './PdlNavItemBase'

export default {
  components: {
    PdlIconCarrotDown,
    PdlIconCarrotUp,
    PdlNavItemBase
  },
  props: {
    listItemClass: {
      type: [String, Array],
      default() {
        return [
          'nav-item',
          'global-header_nav-item'
        ]
      },
    },
    mainNavActive: {
      type: Boolean,
      default: false
    },
    buttonClass: {
      type: [String, Array],
      default: 'nav-item_button'
    },
    linkClass: {
      type: [String, Array],
      default: 'nav-item_link'
    },
    url: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    linkType: {
      type: String,
      default: 'Internal'
    },
    idPrefix: {
      type: String,
      default: 'nav'
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    hasRightCaret: {
      type: Boolean,
      default: false
    },
    renderAsButton: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      isActive: false
    }
  },
  methods: {
    onNavItemClick(label, url, linkType, elementId) {
      // Prevent default here instead of template so that
      // anchors still link when there is no click handler.
      // Do not prevent default for external links because
      // they should still open the external page.
      this.isActive = !this.isActive
      this.$emit('nav-item-clicked', label, url, linkType, elementId, this.index)
    },
    emitFocusIn() {
      this.$emit('focus-in')
    }
  }
}
</script>
