<template>
  <ul
    class="sub-menu"
    :class="{ 'sub-menu--bordered': borderContainer }"
  >
    <li
      v-for="(item, index) in filteredNavItems"
      :id="item.id"
      :key="item.title"
      class="sub-menu_item"
      :class="[
        {'sub-menu_item--active': isSelected(item.route)},
      ]"
    >
      <button
        :id="itemElementId(item)"
        :data-href="item.route"
        class="sub-menu_item-outer-container"
        :class="{ 'sub-menu_item-outer-container-active': isHelpMenu && isSelected(item.route) }"
        :aria-label="label(item)"
        tabindex="0"
        role="link"
        @click.prevent="handleClick(item, index, { linkId: itemElementId(item) })"
      >
        <div
          class="sub-menu_item-inner-container"
          :class="{ 'sub-menu_item-inner-container--no-icon': isHelpMenu }"
        >
          <div
            v-if="item.icon"
            class="sub-menu_item-icon"
          >
            <PdlIconBase
              v-if="item.icon"
              :icon-title="item.icon"
              :icon-class="isSelected(item.route) ?
                'vector-icon-color--prime' : 'vector-icon-color--dark-grey'"
              :container-class="iconContainerClass(item.icon)"
            >
              <PdlMyAccountIds
                :svg-id="item.icon"
                :opco="opco"
              />
            </PdlIconBase>
          </div>
          <div class="sub-menu_item-content">
            <span
              v-if="item.title"
              :id="`${itemElementId(item)}--mainText`"
              class="sub-menu_item-title"
              :class="{
                'text-lg font-normal tracking-widest pl-px':
                !item.description && !item.subtitle && !lightTheme,
                'tw-pg-1 select-text': lightTheme
              }"
            >
              {{ item.title }}
            </span>
            <span
              v-if="item.description"
              :id="`${itemElementId(item)}--subText`"
              :class="{
                'sub-menu_item-description font-size-16': !lightTheme,
                'text-gray-600 text-sm leading-8': lightTheme
              }"
            >
              {{ item.description }}
            </span>
            <span
              v-if="item.subtitle"
              class="help-topic_subtext text-2xl leading-8"
            >
              {{ subTitleClean(item.subtitle) }}
            </span>
          </div>
        </div>
        <PdlIconBase
          role="presentation"
          icon-title="right arrow"
          :icon-class="isSelected(item.route) ?
            'vector-icon-stroke--prime' : 'vector-icon-stroke--dark-grey'"
          container-class="vector-icon_container vector-icon-size--medium"
        >
          <PdlIconCarrotRight />
        </PdlIconBase>
      </button>
    </li>
  </ul>
</template>

<script>
import { mapGetters } from 'vuex'
import PdlIconCarrotRight from 'components/icons/icon/PdlIconCarrotRight'
import PdlMyAccountIds from 'components/account/PdlMyAccountIds'
import accountTracking from 'utils/mixins/tracking/accountTracking'
import compileLinkVars from 'utils/mixins/compileLinkVars'
import formatSafeKey from 'utils/filters/formatSafeKey'
import { GTM_GA4 } from 'utils/constants'

export default {
  components: {
    PdlIconCarrotRight,
    PdlMyAccountIds
  },
  mixins: [accountTracking, compileLinkVars],
  props: {
    navItems: {
      type: Array,
      required: true,
      // title, description, id, route
    },
    navRoot: {
      type: String,
      default: ''
    },
    url: {
      type: String,
      default: ''
    },
    route: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      required: true
    },
    borderContainer: {
      type: Boolean,
      default: false
    },
    activeId: {
      type: String,
      default: ''
    },
    isSidebar: {
      type: Boolean,
      default: false
    },
    isHelpMenu: {
      type: Boolean,
      default: false
    },
    lightTheme: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    id: ''
  }),
  computed: {
    ...mapGetters({
      opco: 'SiteConfig/opco',
      brandVars: 'SiteConfig/brandVars',
    }),
    filteredNavItems() {
      return this.navItems.filter(item => item.show || item.show === undefined)
    },
  },
  methods: {
    safeId(value) {
      if (typeof value !== 'string') return ''
      let safeId = value.trim()
      safeId = `${formatSafeKey(safeId)}`
      safeId = safeId.split(/\s+/g).join('-')
      return safeId?.toLowerCase() || ''
    },
    itemElementId(item) {
      let id = ''
      if (item) {
        id = `${this.safeId(this.name)}--${this.safeId(item.route)}`
      }
      return id
    },
    subTitleClean(subTitle) {
      return this.cleanHtml(subTitle)
    },
    isSelected(route) {
      return route === this.route
    },
    iconContainerClass(icon) {
      if (icon === 'user') {
        return 'vector-icon_container vector-icon-size--medium'
      }
      return 'vector-icon_container vector-icon-size--mediumlarge'
    },
    label(navItem) {
      const {
        title, description, id, route
      } = navItem
      const active = this.isHelpMenu ? id === this.activeId : this.isSelected(route)
      const activeLabel = active ? 'Selected - ' : ''
      const detail = this.cleanHtml(description)
      const detailLabel = detail ? ` ${detail}` : ''
      return activeLabel + title + detailLabel
    },
    handleClick(navItem, index, { linkId } = {}) {
      return this.emitNavChange({ linkId }, index, navItem)
    },
    emitNavChange({ linkId }, index = null, navItem = {}) {
      const {
        route, title, isDirectRoute, hasDeferredAction
      } = navItem

      const destination = isDirectRoute ? `${route}` : `${this.navRoot}${route}`
      if (this.url && (this.url === destination)) return navItem
      const sectionRoot = route.split('/')[0]
      if (sectionRoot === 'pod-pass') {
        this.$trackGA4Event(GTM_GA4.navigation, {
          index,
          link_id: linkId,
          type: 'account',
          link_text: navItem.title,
          link_url: destination,
          site_location: 'Account',
        })
      }
      if (!this.isHelpMenu && sectionRoot !== 'pod-pass') {
        this.accountNavClick(title, sectionRoot, {
          linkId,
          linkText: navItem.title,
          linkUrl: destination,
          index,
          navRoot: this.navRoot,
          navItem,
          route,
        })
      }
      this.$emit('change', navItem, index, destination, this.itemElementId(navItem))
      if (!hasDeferredAction) {
        this.$router.push(destination)
      }
      return navItem
    }
  }
}
</script>
