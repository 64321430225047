import UserPreferencesAPI from 'api/UserPreferencesAPI'

export default {
  namespaced: true,
  state: {
    prefZip: '',
    prefPup: {},
    prefMerch: '',
    prefPupStoreNumber: '',
    selectedSortValue: '',
    selectedPPSortValue: '',
    selectedBASortValue: '',
    isStorePref: '',
    useSnap: true,
    // Do not set default value of empty string for landingPageId.
    // To prevent unnecessary calls, it is null when uninitialized
    // and an empty string when initialized but no landingPageId.
    landingPageId: null
  },
  getters: {
    prefZip: state => state.prefZip,
    prefPup: state => state.prefPup,
    prefMerch: state => state.prefMerch,
    prefPupStoreNumber: state => state.prefPupStoreNumber,
    selectedSortValue: state => state.selectedSortValue,
    selectedPPSortValue: state => state.selectedPPSortValue,
    selectedBASortValue: state => state.selectedBASortValue,
    inStorePref: state => state.inStorePref,
    landingPageId: state => state.landingPageId,
    getUseSnap: state => state.useSnap,
  },
  mutations: {
    setPreferredZip(state, value) {
      state.prefZip = value
    },
    setPrefPup(state, value) {
      state.prefPup = value
    },
    setPrefMerch(state, value) {
      state.prefMerch = value
    },
    setPrefPupStoreNumber(state, value) {
      state.prefPupStoreNumber = value
    },
    setSelectedSortValue(state, payload) {
      state.selectedSortValue = payload
    },
    setSelectedPPSortValue(state, payload) {
      state.selectedPPSortValue = payload
    },
    setSelectedBASortValue(state, payload) {
      state.selectedBASortValue = payload
    },
    setInStorePref(state, value) {
      state.inStorePref = value
    },
    setLandingPageId(state, value) {
      if (value) {
        state.landingPageId = value
      } else {
        state.landingPageId = ''
      }
    },
    setUseSnap(state, value) {
      state.useSnap = value
    },
  },
  actions: {
    async getUserPreference({ rootGetters }, payload) {
      const userId = rootGetters['UserProfile/userId']
      const response = await UserPreferencesAPI.get(payload, userId)
      return response?.data?.response?.preference || {}
    },
    async getUserPrefResponse({ rootGetters, commit }, payload) {
      const userId = rootGetters['UserProfile/userId'] || appConfig.user.userId
      const response = await UserPreferencesAPI.get(payload.userPref, userId)
      const preference = response?.data?.response?.preference || {}
      if (payload.type === 'B') {
        commit('setInStorePref', preference.value)
      }
      return preference
    },
    async updateUserPreference({ rootGetters }, { name, value }) {
      const userId = rootGetters['UserProfile/userId']
      return UserPreferencesAPI.update(name, value, userId)
    }
  }
}
