import ApiService from '@/api/'

export default {
  /**
  *   Returns whether the given user is default flex or fuel
  *   Determines which bucket points earned at point-of-sale go into and access to loyalty program
  *   @param {String} opco - Required. Valid for STSH and GNTL only
  *   @param {String} cardNumber - Required. Loyalty account to fetch preference for
  */
  get: async (opco, cardNumber) => {
    const response = await ApiService.coreGet(`/apis/rewards/v1/preferences/${opco}/${cardNumber}`)
    if (response.status !== 200
      || response.data === undefined) {
      throw new Error('Generic Error')
    }
    return response
  },
  /**
  *   Sets the given user to default flex or fuel
  *   Currently do not support allowing the user to revert to fuel via web, must call into Customer Support
  *   @param {String} opco - Required. Valid for STSH and GNTL only
  *   @param {String} cardNumber - Required. Loyalty account to fetch preference for
  *   @param {String} val - Required. Valid for 'flex' or 'fuel'
  */
  update: async (opco, cardNumber, val) => {
    const response = await ApiService.put(`/apis/rewards/v1/preferences/${opco}/${cardNumber}?value=${val}`)
    if (response.status !== 200
      || response.data === undefined) {
      throw new Error('Generic Error')
    }
    return response
  }
}
