<template>
  <!-- Component is deprecated. It will soon be removed -->
  <button
    :id="id"
    :class="[
      'btn',
      'btn--' + styling,
      { 'btn--small' : isSmall },
      { 'btn--block': isBlock }
    ]"
    :disabled="disabled"
    :type="type"
    @click.prevent="onClick($event)"
  >
    <slot />
  </button>
</template>

<script>
export default {
  emits: ['click'],
  props: {
    id: {
      type: String,
      default: undefined
    },
    styling: {
      type: String,
      default: 'primary'
    },
    isSmall: {
      type: Boolean,
      default: false
    },
    isBlock: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'button'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    classes() {
      let classes = `btn--${this.styling}`
      if (this.isBlock) {
        classes += ' btn--block'
      }
      return classes
    },
  },
  methods: {
    onClick($event) {
      this.$emit('click', $event)
    }
  }
}
</script>
