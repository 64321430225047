<template>
  <PdlNavItemBase
    :id-prefix="idPrefix"
    :list-item-class="listItemClass"
    :button-class="buttonClass"
    :link-class="linkClass"
    :url="subNavItemWithLinkVar(url)"
    :label="subNavItemWithLinkVar(label)"
    :link-type="linkType"
    :is-active="isSubNavMenuOpen || isCategoryMenuOpen"
    :content-data="contentData"
    :scheduled-data="scheduledData"
    :is-new-nav-design-enabled="isNewNavDesignEnabled"
    :has-hover-icon="hasHoverIcon"
    @nav-item-base-clicked="onNavItemClick"
  >
    {{ subNavItemWithLinkVar(label) }}
    <SubNavigationMenu
      v-show="isSubNavMenuOpen && hasRightCaret && !isCategoryMenuButton"
      v-clickaway="{
        handler: 'closeMenu'
      }"
      :is-new-nav-design-enabled="isNewNavDesignEnabled"
      :menu-items="menuItems"
      @nav-item-clicked="onNavItemClick"
      @last-item-focus-out="closeMenu"
    />
  <span
      v-if="linkType === 'Dropdown Menu' && isMobile"
      class="caret caret--right float-right"
    />
    <div
      v-if="hasRightCaret && isCategoryMenuButton"
      v-show="!isCategoryMenuOpen"
      class="nav-item_caret ml-1 mt-0 pt-1"
    >
      <PdlIconBase
        icon-title="down arrow"
        :container-class="'vector-icon-size--xsmall vector-icon-stroke--prime'"
      >
        <PdlIconCarrotDown />
      </PdlIconBase>
    </div>
    <div
      v-if="hasRightCaret && isCategoryMenuButton"
      v-show="isCategoryMenuOpen"
      class="nav-item_caret ml-1 mt-0 pt-1 stroke-4"
    >
      <PdlIconBase
        icon-title="down up"
        :container-class="'vector-icon-size--xsmall vector-icon-stroke--prime'"
      >
        <PdlIconCarrotUp />
      </PdlIconBase>
    </div>
    <div
      v-if="hasRightCaret && !isCategoryMenuButton"
      v-show="!isSubNavMenuOpen"
      class="nav-item_caret ml-1 mt-0 pt-1"
    >
      <PdlIconBase
        icon-title="down arrow"
        :container-class="'vector-icon-size--xsmall stroke-black'"
      >
        <PdlIconCarrotDown />
      </PdlIconBase>
    </div>
    <div
      v-if="hasRightCaret && !isCategoryMenuButton"
      v-show="isSubNavMenuOpen"
      class="nav-item_caret ml-1 mt-0 pt-1 stroke-3"
    >
      <PdlIconBase
        icon-title="down up"
        :container-class="'vector-icon-size--xsmall stroke-black'"
      >
        <PdlIconCarrotUp />
      </PdlIconBase>
    </div>
    <template #navDropDown>
      <slot />
    </template>
  </PdlNavItemBase>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import clickaway from 'utils/custom-directives/clickaway'
import compileLinkVars from 'utils/mixins/compileLinkVars'
import PdlIconCarrotDown from 'components/icons/icon/PdlIconCarrotDown'
import PdlIconCarrotUp from 'components/icons/icon/PdlIconCarrotUp'
import SubNavigationMenu from '../SubNavigationMenu'
import PdlNavItemBase from './PdlNavItemBase'

export default {
  components: {
    PdlNavItemBase,
    SubNavigationMenu,
    PdlIconCarrotDown,
    PdlIconCarrotUp
  },
  mixins: [compileLinkVars],
  directives: { clickaway },
  props: {
    listItemClass: {
      type: [String, Array],
      default: 'nav-item'
    },
    buttonClass: {
      type: [String, Array],
      default: 'nav-item_button'
    },
    linkClass: {
      type: [String, Array],
      default: 'nav-item_link'
    },
    url: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    linkType: {
      type: String,
      default: 'Internal'
    },
    idPrefix: {
      type: String,
      default: 'nav'
    },
    contentData: {
      type: Object,
      default: null
    },
    scheduledData: {
      type: Object,
      default: null
    },
    hasRightCaret: {
      type: Boolean,
      default: false
    },
    isNewNavDesignEnabled: {
      type: Boolean,
      default: false
    },
    hasHoverIcon: {
      type: Boolean,
      default: false
    },
    isCategoryMenuButton: {
      type: Boolean,
      default: false
    },
    menuItems: {
      type: Array,
      default: () => []
    },
    index: {
      type: Number,
      default: 0,
    }
  },
  computed: {
    ...mapGetters({
      brandVars: 'SiteConfig/brandVars',
      isSubNavMenuOpen: 'GlobalHeader/isSubNavMenuOpen',
      isCategoryMenuOpen: 'GlobalHeader/isCategoryMenuOpen',
      isMobile: 'ScreenSize/isMobile',
      isAccountMenuOpen: 'GlobalHeader/isAccountMenuOpen'
    })
  },
  methods: {
    ...mapActions({
      setCloseAccountMenu: 'GlobalHeader/setCloseAccountMenu'
    }),
    ...mapMutations({
      toggleSubNavMenu: 'GlobalHeader/toggleSubNavMenu',
    }),
    subNavItemWithLinkVar(val) {
      return this.cleanAllExpressions(val)
    },
    setActive() {
      this.isActive = !this.isActive
    },
    onNavItemClick(label, url, linkType, elementId) {
      // Prevent default here instead of template so that
      // anchors still link when there is no click handler.
      // Do not prevent default for external links because
      // they should still open the external page.
      if (this.isAccountMenuOpen) {
        this.setCloseAccountMenu()
      }
      this.$emit('nav-item-clicked', label, url, linkType, elementId)
      this.$emit('track-nav-updated', {
        label,
        url,
        elementId,
        ...(!((label === 'Pharmacy' || label === 'Wellness') || this.isMobile) && { index: this.index + 1 })
      })
    },
    closeMenu() {
      if (this.isSubNavMenuOpen) {
        this.toggleSubNavMenu()
      }
      if (this.isAccountMenuOpen) {
        this.setCloseAccountMenu()
      }
    },

  }
}
</script>
