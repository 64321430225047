<template>
  <div class="superuser_status-display-wrapper">
    <div class="superuser_status-display">
      <p class="superuser_status-mode">
        {{ status }}
      </p>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      isRedeliveryMode: state => state.SuperUser.redeliveryMode
    }),
    status() {
      return this.isRedeliveryMode ? 'Redelivery Order Mode' : 'Regular Order Mode'
    }
  }
}
</script>
