<template>
  <div
    class="display--inline-block"
    :class="outerWrapperClass"
  >
    <div class="pdl-common-tooltip">
      <span
        v-if="titleText"
        :class="titleClass"
      >
        {{ titleText }}
      </span>
      <PdlCommonTooltipPopover
        :ref="'tooltipPopoverEl'"
        :icon-size="iconSize"
        :mode="`${hover && !isMobile ? 'hover' : 'click'}`"
        :placement="placement"
        :popover-base-class="popoverBaseClasses"
        :popover-inner-class="popoverInnerClasses"
        :popover-arrow-class="popoverArrowClasses"
        :class="'flex flex-row flex-nowrap'"
        @show="$emit('show')"
        @hide="$emit('hide')"
      >
        <span
          class="pdl-common-tooltip_icon-wrapper"
          :aria-label="ariaLabel"
        >
          <PdlIconBase
            :icon-title="labelIcon"
            :icon-class="brandIconFill ? 'pdl-common-tooltip_fill--brand' : 'pdl-common-tooltip_fill'"
            :container-class="iconContainerClass"
          >
            <PdlIconInfo />
          </PdlIconBase>
        </span>
        <template #popover>
          <!-- eslint-disable vue/no-v-html -->
          <span>
            <span
              v-if="cleanMessage"
              v-html="cleanMessage"
            /><slot /><!-- slot: add content without filter -->
          </span>
          <!-- eslint-enable vue/no-v-html -->
          <a
            v-if="directLink"
            :class="linkStyle"
            href="#"
            @click.prevent="onDirectLink(directLink)"
          >{{ directLink.text }}</a>
        </template>
      </PdlCommonTooltipPopover>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  PdlIconBase,
} from '@/shared/icons'
import PdlIconInfo from 'components/icons/icon/PdlIconInfo'
import PdlCommonTooltipPopover from '@/shared/interactions/PdlCommonTooltipPopover'
import compileLinkVars from 'utils/mixins/compileLinkVars'

export default {
  components: {
    PdlIconBase,
    PdlIconInfo,
    PdlCommonTooltipPopover
  },
  mixins: [compileLinkVars],
  props: {
    titleText: {
      type: [String, Number],
      default: ''
    },
    message: {
      type: String,
      default: ''
    },
    labelIcon: {
      type: String,
      default: ''
    },
    titleClass: {
      type: String,
      default: ''
    },
    mobileContentClass: {
      type: String,
      default: ''
    },
    outerWrapperClass: {
      type: String,
      default: ''
    },
    /**
     * Set to `true` if the tooltip should show/hide on hover
     */
    hover: {
      type: Boolean,
      default: false,
    },
    brandIconFill: {
      type: Boolean,
      default: false,
    },
    iconSize: {
      type: String,
      default: 'x-small'
    },
    /**
     * bottom, right
     */
    placement: {
      type: String,
      default: 'bottom'
    },
    backgroundColor: {
      type: String,
      default: 'darkest-grey',
      validator: prop => [
        'white',
        'darkest-grey'
      ].includes(prop)
    },
    directLink: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      status: false
    }
  },
  computed: {
    ...mapGetters({
      isMobile: 'ScreenSize/isMobile',
      brandVars: 'SiteConfig/brandVars'
    }),
    ariaLabel() {
      return `${this.titleText} tooltip: ${this.cleanMessage}`
    },
    cleanMessage() {
      return this.cleanHtml(this.message)
    },
    iconContainerClass() {
      return `pdl-common-tooltip_icon pdl-common-tooltip_sizing--${this.iconSize}`
    },
    popoverInnerClasses() {
      // eslint-disable-next-line max-len
      return `tooltip-inner popover-inner popover-inner--${this.backgroundColor} ${this.isMobile ? this.mobileContentClass : ''}`
    },
    popoverArrowClasses() {
      return `popover-inner--${this.backgroundColor}`
    },
    popoverBaseClasses() {
      return `tooltip popover popover-inner--${this.backgroundColor}`
    },
    linkStyle() {
      if (this.backgroundColor === 'darkest-grey') {
        return `font-bold text-white underline hover:underline
        focus:underline decoration-solid decoration-white visited:text-white`
      }
      return 'link no-underline hover:no-underline focus:no-underline link--inline'
    }
  },
  methods: {
    onDirectLink(directLink) {
      const { link, closeModal } = directLink
      if (link) {
        if (typeof link === 'string') {
          if (closeModal) this.$store.commit('Modals/clearActiveModal')
          this.$router.push(link)
        } else if (typeof link === 'function') {
          link()
        }
      }
    },
  }
}
</script>
