import getScript from 'utils/services/script-loader/get-script'

const RECAPTCHA_URL = `https://www.google.com/recaptcha/enterprise.js`
class RecaptchaLoaderService {
  constructor() {
    this.scriptLoaded = false
  }

  async load() {
    if (this.scriptLoaded) {
      return Promise.resolve(true)
    }
    const that = this
    return getScript(RECAPTCHA_URL, {
      async: true
    })
      .then(() => {
        that.scriptLoaded = true
        return true
      })
  }
}

const recaptchaLoaderService = new RecaptchaLoaderService()

export default recaptchaLoaderService
