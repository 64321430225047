<template>
  <div
    v-if="recaptchaLoaded"
    :id="`${id}_captcha`"
    :ref="refID"
    :class="`${id}_captcha`"
    :data-sitekey="recaptchaKey"
  />
</template>
<script>
import recaptchaLoaderService from 'utils/services/script-loader/recaptcha-loader'
import {
  RECAPTCHA_KEY
} from 'utils/constants'

window.grecaptcha = (window.grecaptcha || {})

export default {
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      verifiedCaptcha: false,
      recaptchaResponse: null,
      rcaptID: 0,
      recaptcha: {},
      recaptchaKey: RECAPTCHA_KEY,
      recaptchaLoaded: false,
      refID: 'recaptcha-elem',
      intervalId: undefined
    }
  },
  computed: {
    recaptchaElement() {
      return this.$refs[this.refID]
    }
  },
  watch: {
    recaptchaLoaded(val) {
      if (val) {
        this.$emit('recaptcha-loaded', this.recaptcha)
      }
    },
    verifiedCaptcha(val) {
      this.$emit('recaptcha-verify-change', val, this.recaptchaResponse)
    },
  },
  async mounted() {
    this.recaptchaLoaded = false
    await recaptchaLoaderService.load()
    this.recaptcha = window.grecaptcha?.enterprise || {}
    this.recaptchaLoaded = true
    this.waitForRender()
  },
  methods: {
    // This function is required because the recaptcha data prop
    // does not contain the render function until later on.
    // In vue 2 the watcher was being triggered but this is not the case in vue 3,
    // so added the interval until the render function is available
    waitForRender() {
      this.intervalId = window.setInterval(() => {
        if ('render' in this.recaptcha) {
          this.updateRecaptchaElement(this.recaptcha)
          window.clearInterval(this.intervalId)
        }
      }, 200)
    },
    updateRecaptchaElement(recaptcha) {
      // ensures recaptcha object and dom element are available
      this.$nextTick(() => {
        if (recaptcha?.render && this.recaptchaElement) {
          this.rcaptID = recaptcha.render(this.recaptchaElement, {
            sitekey: this.recaptchaKey,
            callback: this.correctCaptcha,
            'expired-callback': this.incorrectCaptcha,
            'error-callback': this.incorrectCaptcha
          })
        }
      })
    },
    correctCaptcha(response) {
      // call-back fuction for correct captcha
      this.recaptchaResponse = response
      this.verifiedCaptcha = true
    },
    incorrectCaptcha() {
      // call-back fuction for incorrect captcha
      this.recaptchaResponse = null
      this.verifiedCaptcha = false
    },
    resetCaptcha() {
      // fuction to reset captcha after submission
      if (this.recaptcha) {
        this.verifiedCaptcha = false
        this.recaptcha.reset()
      }
    }
  }
}
</script>
