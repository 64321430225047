/** Return url for browse aisles categories
 * @module browseAislesGetCategoryUrl
 * @param categoryName - string, category name value
 * @param categoryTreeId - string, categoryTree id
 * @return String - formatted with appropriate url path
* */

export default (category) => {
  if (category.cmsContentKey) {
    return `/browse-aisles/categories/1/${category.cmsContentKey}`
  }
  if (category.categoryLink) {
    return category.categoryLink
  }
  if (category.categoryName && category.categoryTreeId) {
    const categoryName = category.categoryName.replace(/[^\w ]| [&-]/g, '').replace(/ /g, '-').toLocaleLowerCase()
    return `/browse-aisles/categories/1/categories/${category.categoryTreeId}-${categoryName}`
  }
  return '/browse-aisles/categories/1'
}
