export const initialSuperUserState = Object.freeze({
  enabled: false,
  hasToken: false,
  redeliveryMode: false,
  statusVisible: false
})

export default {
  namespaced: true,
  state: { ...initialSuperUserState },
  mutations: {
    setIsEnabled(state, payload) { state.enabled = payload === true },
    setHasToken(state, payload) { state.hasToken = payload === true },
    setIsRedeliveryMode(state, payload) { state.redeliveryMode = payload === true },
    setIsStatusVisible(state, payload) { state.statusVisible = payload === true }
  },
  actions: {
    /**
     * Initialize the super user state.
     *
     * @param {Object} context vuex context
     * @param {any} context.commit
     * @param {Object} payload super user content
     * @param {boolean} payload.enabled
     * @param {boolean} payload.hasToken
     * @param {boolean} payload.redeliveryMode
     * @param {boolean} payload.statusVisible
     */
    async init(context, payload) {
      if (typeof payload !== 'object' || payload === null) return
      const { commit } = context
      const {
        enabled,
        hasToken,
        redeliveryMode,
        statusVisible
      } = payload

      await commit('setIsEnabled', enabled)
      await commit('setHasToken', hasToken)
      await commit('setIsRedeliveryMode', redeliveryMode)
      await commit('setIsStatusVisible', statusVisible)
    },
  }
}
