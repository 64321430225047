import { GTM, GTM_GA4 } from 'utils/constants'
import { buildItemVariant, getFormattedProductAttributes } from 'utils/tracking/helpers'
import optimizelyFullstack from 'components/user-research/services/optimizely-fullstack-service'
import { getJiracoreInfo } from 'utils/tracking/buildJiracoreInfo'
import capitalize from 'utils/filters/capitalize'

const RECIPES = {
  FAVORITES: 'favorites',
  ORDER_STARTER: 'order starter',
}
const shouldCapitalize = () => {
  const { $route } = window.sharedVue.config.globalProperties
  return $route.name !== 'product-search' && $route.name !== 'past-purchases'
}

const buildProductDetails = (cart, product) => {
  const productName = product.description ? product.description : product.name
  const productQuantity = cart.quantityIncrement || Math.abs(cart.prevQuantity - cart.qty)
  const productIndex = cart.productIndex > -1 ? cart.productIndex : ''
  const isOnSale = product?.flags?.sale || false
  const dimension156 = [...buildItemVariant(product),
    ...getFormattedProductAttributes(product.flags),
    ...product.guidingStars > 0 ? ['guiding stars'] : [],
    ...product.sustainabilityRating > 0 ? ['sustainability'] : []
  ]
  const dimension137 = product.healthAndLifestyleAttributeText
  return {
    name: productName,
    id: cart.productId,
    price: product.price,
    brand: product.brand || '',
    category: product.rootCatName,
    constiant: product.size,
    quantity: productQuantity,
    position: productIndex,
    dimension37: isOnSale,
    dimension38: product.regularPrice,
    dimension156,
    dimension137
  }
}

const buildProductDetailsForGA4Tagging = (cart, product) => {
  const store = window.sharedVue.config.globalProperties.$store
  const productName = product.description ? product.description : product.name
  const productQuantity = cart.quantityIncrement || Math.abs((cart.prevQuantity || 0) - product.qty)
  const productIndex = cart.productIndex > -1 ? cart.productIndex : ''
  const { coupon } = product
  const discount = ((product.regularPrice.toFixed(2) * 100) - (product.price.toFixed(2) * 100)) / 100
  const flagsAvailable = product.flags
    ? Object.keys(product.flags).filter(key => product.flags[key] === true)
    : []
  const itemVariant = buildItemVariant(product)?.join()
  const locationId = store.getters['UserProfile/storeId']
  return {
    item_id: cart.productId.toString() || product.prodId.toString(),
    item_name: productName,
    coupon: coupon ? coupon.id : null,
    currency: 'USD',
    discount,
    index: productIndex,
    item_brand: product.brand || '',
    item_category: product.rootCatName,
    item_category2: product.subcatName,
    item_category5: flagsAvailable?.length >= 1 ? flagsAvailable.join() : null,
    item_list_id: shouldCapitalize() ? capitalize(cart.list) || null : cart.list,
    item_list_name: shouldCapitalize() ? capitalize(cart.listType) || null : cart.listType,
    item_variant: itemVariant,
    location_id: locationId || null,
    price: product.regularPrice,
    quantity: productQuantity
  }
}

const buildOfferDetails = (cart, product) => {
  const { coupon } = product
  if (!coupon) {
    return {
      offerApplied: 'no',
      offerLimitReached: 'no',
      offerType: 'no offer',
      couponId: 'no id'
    }
  }
  return {
    offerApplied: 'yes',
    offerLimitReached: cart.couponFulfilled || !coupon.multiQty ? 'yes' : 'no',
    offerType: coupon.multiQty ? 'multi' : 'single',
    couponId: coupon.id
  }
}

const getHealthPreferences = (userHealthPreferences) => {
  return userHealthPreferences ? { userHealthPreferences } : {}
}

const buildAddToCartTagData = (cart, tagData) => {
  const {
    modelId,
    listType,
    invocationId,
    runId,
    originalItemId,
    addToCartType,
    addToCartLocation,
    recommendationAlgorithm,
    addToCartLocationdetail,
    userHealthPreferences,
    indexPosition,
    searchTerm
  } = cart
  const cartLogLocation = cart.splashLogLocation ? cart.splashLogLocation : cart.logLocation
  const recipe = RECIPES[cartLogLocation]
  tagData = updateTagData(cart, tagData, cartLogLocation)

  return {
    ...tagData,
    ...(recipe && { recipeAddToCart: true }),
    ...(recipe && { recipeName: recipe }),
    ...(modelId && { modelId }),
    ...(listType && { listType }),
    ...(invocationId && { invocationId }),
    ...(runId && { runId }),
    ...(originalItemId && { originalItemId }),
    ...(addToCartType && { addToCartType }),
    ...(addToCartLocation && { addToCartLocation }),
    ...(recommendationAlgorithm && { recommendationAlgorithm }),
    ...(addToCartLocationdetail && { addToCartLocationdetail }),
    ...getHealthPreferences(userHealthPreferences),
    ...(indexPosition && { indexPosition }),
    ...(searchTerm && { searchTerm })
  }
}

const updateTagData = (cart, tagData, cartLogLocation) => {
  tagData.addToCartLocation = cartLogLocation
  if (cart.useAddToCartLocation) {
    tagData.addToCartLocation = cart.addToCartLocation
  }
  if (cart.personalizationActionFlag !== undefined) {
    tagData.personalizationActionFlag = cart.personalizationActionFlag
  }
  return tagData
}

const buildAddToCartTagDataForGA4Tagging = (cart, tagData) => {
  const {
    modelId,
    invocationId,
    runId,
    recommendationAlgorithm,
    reranked,
    rerankedInput
  } = cart
  let cartLogLocation = ''
  if (cart.splashLogLocation) {
    cartLogLocation = cart.splashLogLocation
  } else if (cart.logLocation && !cart.logLocation.includes('SPLASH_PRODUCT_SET')) {
    cartLogLocation = capitalize(cart.logLocation.toLowerCase())
  } else {
    cartLogLocation = cart.list
  }
  const mlAlgo = recommendationAlgorithm && recommendationAlgorithm !== 'no cms value'
    ? recommendationAlgorithm : null
  // To DO: remove cartLogLocation and cart.useAddToCartLocation as we clean up tagging
  if (cart.siteLocation) {
    tagData.site_location = cart.siteLocation
  } else if (cart.useAddToCartLocation) {
    tagData.site_location = cart.addToCartLocation
  } else {
    tagData.site_location = cartLogLocation
  }

  return {
    ...tagData,
    ml_algo: mlAlgo,
    ml_invocation_id: invocationId || null,
    ml_model_id: modelId || null,
    ml_run_id: runId || null,
    reranked: reranked || null,
    rerankedInput: rerankedInput || null
  }
}

const buildAndTrackGtm = (cart) => {
  const {
    transactionId,
    priceChange,
    removeFromCartType,
    removeFromCartLocation
  } = cart
  const vueInstance = window.sharedVue.config.globalProperties
  const eventId = getEventId(cart)
  const directionKey = (eventId === GTM.addToCart) ? 'add' : 'remove'
  const product = cart.productData || {}
  const orderId = vueInstance.$store.getters['Order/currentOrderId']
  const orderStatus = vueInstance.$store.getters['Order/orderStatus']
  const discountName = (product?.bmsmTiers?.length) ? product.bmsmTiers[0].description : false
  const productDetails = buildProductDetails(cart, product)
  const isSubmittedOrder = orderStatus.currentOrder.status === 'SUBMITTED'

  let tagData = {
    ecommerce: {
      currencyCode: 'USD'
    },
    offerDetails: buildOfferDetails(cart, product),
    priceChange: priceChange || null,
    // set the transaction id when editing submitted order for all cart updates
    transactionId: transactionId || (isSubmittedOrder && orderId) || null,
    ...(removeFromCartType && { removeFromCartType }),
    ...(removeFromCartLocation && { removeFromCartLocation })
  }

  if (eventId === GTM.addToCart) {
    tagData = buildAddToCartTagData(cart, tagData)
  }

  if (discountName) {
    const completedBmsmGroup = vueInstance.$store.state.ProductTile.bmsmCompletedGroupIds
    const isCompleted = completedBmsmGroup.includes(product.bmsmPodGroupId) ? 'yes' : 'no'
    productDetails.offerType = 'bmsm'
    productDetails.dimension78 = discountName
    productDetails.offerLimitReached = isCompleted
    tagData.offerDetails = {
      offerType: 'bmsm',
      offerLimitReached: isCompleted,
      offerApplied: 'yes'
    }
  }
  tagData.ecommerce[directionKey] = {
    actionField: {
      list: cart.list
    },
    products: [
      productDetails
    ],
  }

  vueInstance.$trackGtmEvent(eventId, tagData)
}

const getEventId = (cart) => {
  if (cart.eventType === 'cart_item_delete' || cart.priceDifference < 0 || cart.qty < cart.prevQuantity) {
    return GTM.removeFromCart
  }
  return GTM.addToCart
}

const jiracoreReferralInfo = (cart) => {
  let referralData
  if (cart.scheduledContentId && cart.scheduledContentId !== cart.content?.key) {
    const referralScheduledId = cart.scheduledContentId ?? undefined
    const referralContentId = cart.contentId ?? undefined
    const referralSubtaskId = cart.contentModuleId ?? null
    referralData = `${referralScheduledId} | ${referralContentId} | ${referralSubtaskId}`
  }
  return referralData
}

export const buildAndTrackGtmGA4 = (cart) => {
  const { transactionId } = cart
  const vueInstance = window.sharedVue.config.globalProperties
  const ga4eventId = (cart.eventType === 'cart_item_delete' || cart.priceDifference < 0
    || cart.qty < cart.prevQuantity)
    ? GTM_GA4.removeFromCart
    : GTM_GA4.addToCart
  const product = cart.productData || {}
  const nativeJiraData = vueInstance.$store.getters['ScheduledContent/nativeScheduledContentQueryData']
  const orderId = vueInstance.$store.getters['Order/currentOrderId']
  const orderStatus = vueInstance.$store.getters['Order/orderStatus']
  const cartBasketId = vueInstance.$store.getters['Cart/getBasketId']
  const itemDetails = buildProductDetailsForGA4Tagging(cart, product)
  const isSubmittedOrder = orderStatus?.currentOrder?.status === 'SUBMITTED'
  const jiracoreData = getJiracoreInfo(cart.content)
  const jiracoreReferralData = jiracoreReferralInfo(cart)
  const jiracoreLocation = cart.addToCartLocation ?? undefined

  const jiraScheduledContent = {
    jiracore: jiracoreData,
    jiracore_referral: nativeJiraData || jiracoreReferralData,
    jiracore_sitelocation: jiracoreLocation
  }

  let ga4TagData = {
    value: product.price * cart.quantityIncrement,
    currency: 'USD',
    transaction_id: transactionId || (isSubmittedOrder && orderId) || null,
    basket_id: cartBasketId && cartBasketId !== '1' ? cartBasketId : null,
    ...jiraScheduledContent
  }
  ga4TagData = buildAddToCartTagDataForGA4Tagging(cart, ga4TagData)
  ga4TagData.items = [itemDetails]

  vueInstance.$trackGA4Event(ga4eventId, ga4TagData)
}

const trackOptimizelyATC = (cart) => {
  if (getEventId(cart) === GTM.addToCart) {
    Object.assign(optimizelyFullstack.attributes, {
      product_category: cart.productData?.rootCatName,
      guiding_stars_rating: cart.productData?.guidingStars
    })
    if (qtyIncreasedByOne(cart)) {
      optimizelyFullstack.trackEvent('Site ATC', { value: cart.productData.price })
    }
  }
}

export const trackOptimizelyATCTypedQty = (cart, newQty, prevQty, atcText) => {
  let itr = prevQty
  while (itr < newQty) {
    itr += 1
    optimizelyFullstack.trackEvent(atcText, { value: cart.price })
    optimizelyFullstack.trackEvent('Site ATC', { value: cart.price })
  }
}

export const trackOptimizelyATCForProductTile = (hAndWAttribute, isProductTileClick, guidingStarRating, price) => {
  if (isProductTileClick) {
    optimizelyFullstack.trackEvent('PDP Clickthrough - Product Tile')
  } else {
    optimizelyFullstack.trackEvent('Product Tile ATC', { value: price })
    if (hAndWAttribute.includes('Guiding Stars')) {
      optimizelyFullstack.trackEvent('Guiding Stars Product ATC')
      switch (guidingStarRating) {
        case 1:
          optimizelyFullstack.trackEvent('Guiding Stars Rating One ATC')
          break
        case 2:
          optimizelyFullstack.trackEvent('Guiding Stars Rating Two ATC')
          break
        case 3:
          optimizelyFullstack.trackEvent('Guiding Stars Rating Three ATC')
          break
        default:
          break
      }
    } else if (hAndWAttribute) {
      optimizelyFullstack.trackEvent('Health_Lifestyle Product ATC')
    }
  }
}

export const trackCartChange = (cart) => {
  buildAndTrackGtm(cart)
  buildAndTrackGtmGA4(cart)
  trackOptimizelyATC(cart)
}

export const trackMultipleCartChange = (cartItems, isRemove = false) => {
  if (!cartItems || (Array.isArray(cartItems) && !!cartItems[0])) {
    return
  }
  const vueInstance = window.sharedVue.config.globalProperties
  const cart = cartItems[0]
  const { transactionId } = cart
  const event = isRemove ? GTM_GA4.removeFromCart : GTM_GA4.addToCart
  const orderId = vueInstance.$store.getters['Order/currentOrderId']
  const cartIsSubmittedOrder = vueInstance.$store.getters['Cart/getCartIsSubmittedOrder']
  const cartBasketId = vueInstance.$store.getters['Cart/getBasketId']
  const items = []
  let value = 0
  cartItems.forEach((item) => {
    const product = item.productData || {}
    value += product.price * item.quantityIncrement
    items.push(buildProductDetailsForGA4Tagging(cart, product))
  })
  const ga4TagData = {
    items,
    value,
    currency: 'USD',
    transaction_id: transactionId || (cartIsSubmittedOrder && orderId) || null,
    basket_id: cartBasketId && cartBasketId !== '1' ? cartBasketId : null,
  }
  vueInstance.$trackGA4Event(event, ga4TagData)
}

export const qtyIncreasedByOne = (cartItem = {}) => {
  const hasQuantity = Object.hasOwn(cartItem, 'qty')
  const hasPrevQuantity = Object.hasOwn(cartItem, 'prevQuantity')
  if (hasQuantity && hasPrevQuantity) {
    const quantity = cartItem.qty
    const { prevQuantity } = cartItem
    return quantity > prevQuantity && (quantity - prevQuantity === 1)
  }
  return cartItem?.newQuantity && cartItem?.quantityIncrement === 1
}
