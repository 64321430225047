import ApiService from '@/api/'

export default function ({
  opco, query, type, filters = [], maxDistance = 20, details = true
}) {
  const config = {
    params: {
      storeType: type,
      q: query,
      maxDistance,
      details
    }
  }

  let filterString = ''
  filters.forEach((filter, index) => {
    filterString += (filters.length - 1) === index ? filter : `${filter};`
  })

  if (filterString) {
    config.params.filters = filterString
  }
  // define default for PPOD version that doesn't have stores
  if (opco === 'PPOD' || !opco) {
    opco = 'STSH'
  }

  return ApiService.get(`/apis/store-locator/locator/v1/stores/${opco}`, config)
}
