import { gql } from '@apollo/client'

export const chatAvailability = gql`
  query chatAvailability($opco: Opco) {
    liveChatAvailability(opco: $opco)
  }
`

export const getChatHours = gql`
  query getChatHours {
    liveChatHours {
      holidayHours
      reducedHours {
        date
        closeTime {
          hour
          minute
        }
      }
      normalHours {
        weekdayOpen
        weekdayClose
        saturdayOpen
        saturdayClose
      }
      isOpen
    }
  }`

export const getLiveChatSession = gql`
  query liveChatSession {
    liveChatSession {
      sessionId
      sessionKey
      affinityToken
      clientPollTimeout
    }
  }`

export const getLiveChatMessages = gql`
  query liveChatMessages(
    $affinityToken: String!
    $sessionKey: ID!,
    $messageOrder: Int,
  ){
    liveChatMessages (
      affinityToken: $affinityToken,
      sessionKey: $sessionKey,
      messageOrder: $messageOrder
    ){
      messages {
        type
        message
      },
      sequence
    }
  }`

export const initializeLiveChat = gql`
  query initializeLiveChat(
    $opco: Opco,
    $affinityToken: String!,
    $sessionKey: ID!,
    $prechatDetails: PrechatDetails
  ) {
    liveChatInitialize (
      opco: $opco,
      affinityToken: $affinityToken,
      sessionKey: $sessionKey,
      prechatDetails: $prechatDetails
    )
  }`


export const endLiveChat = gql`
  mutation endLiveChat(
    $affinityToken: String!,
    $sessionKey: ID!,
    $sequence: Int
  ) {
    endLiveChat(
      affinityToken: $affinityToken,
      sessionKey: $sessionKey,
      sequence: $sequence
    )
  }`

// MUTATIONS
export const sendChatMessage = gql`
  mutation sendChatMessage (
    $affinityToken: String!
    $sessionKey: ID!
    $text: String!
    $sequence: Int!
  ) {
    sendChatMessage(
      affinityToken: $affinityToken
      sessionKey: $sessionKey
      text: $text
      sequence: $sequence
    )
  }`

export const updateChatUserTyping = gql`
  mutation updateChatUserTyping (
    $affinityToken: String!
    $sessionKey: ID!
    $sequence: Int!
  ){
    updateChatUserIsTyping(
      affinityToken: $affinityToken
      sessionKey: $sessionKey
      sequence: $sequence
    )
  }`

export const updateChatUserNotTyping = gql`
  mutation updateChatUserNotTyping (
    $affinityToken: String!
    $sessionKey: ID!
    $sequence: Int!
  ){
    updateChatUserIsNotTyping(
      affinityToken: $affinityToken
      sessionKey: $sessionKey
      sequence: $sequence
    )
  }`
