const initialAlertState = () => ({
  active: false,
  type: 'warning',
  icon: true,
  header: '',
  body: '',
  accessibleBody: '',
  processAsHTML: false,
  primary: {
    text: 'Ok',
    color: 'prime',
    isDisabled: 'false'
  },
  secondary: {
    text: '',
    color: '',
    isDisabled: 'false'
  }
})

export default {
  namespaced: true,
  state: {
    alert: initialAlertState(),
    primaryBtnLoading: false,
    secondaryBtnLoading: false,
    isPrimaryBtnDisabled: false,
    isSecondaryBtnDisabled: false
  },
  mutations: {
    setAlert(state, value) {
      const defaultValue = initialAlertState()
      const newAlert = {
        ...defaultValue,
        ...value,
        active: true
      }
      if (value) {
        // hide overflow of body to prevent background scrolling when alert is active
        document.body.classList.add('hidden-overflow')
        state.alert = newAlert
      }
    },
    clear(state) {
      document.body.classList.remove('hidden-overflow')
      state.alert = initialAlertState()
      state.secondaryBtnLoading = false
      state.primaryBtnLoading = false
    },
    setLoadingState(state, payload) {
      if (payload === 'primary') {
        state.primaryBtnLoading = true
      }
      if (payload === 'secondary') {
        state.secondaryBtnLoading = true
      }
    },
    setPrimaryBtnState(state, payload) {
      state.alert.primary.isDisabled = payload
      state.isPrimaryBtnDisabled = payload
    },
    setSecondaryBtnState(state, payload) {
      state.alert.secondary.isDisabled = payload
      state.isSecondaryBtnDisabled = payload
    }
  },
}
