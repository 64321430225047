<template>
  <span
    :class="[
      'flag_label',
      'flag_label--' + flagColor,
    ]"
  >
    {{ flagLabel }}
  </span>
</template>
<script>
export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: ''
    }
  },
  computed: {
    flagLabel() {
      return this.label
    },
    flagColor() {
      return this.color.toLowerCase().split(' ').join('-')
    }
  },
}
</script>
