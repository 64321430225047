const isLoggedIn = async () => {
  const store = window.sharedVue.config.globalProperties.$store
  await store.dispatch('LoginStatus/getLoginStatus')
  return store.getters['LoginStatus/secureLogin']
}

const emit = (eventName, response = {}, hasError = false) => {
  const payload = {
    detail: {
      eventName,
      hasError,
      response
    }
  }
  window.dispatchEvent(new CustomEvent('NativeLoginEvent', payload))
}
export default class NativeLogin {
  SOURCES = {
    'peapod-gateway': 'peapod-gateway',
    'guest-transfer-modal': 'menu-sign-in',
    'user-account-modal': 'my-account',
  }

  EVENT_NAMES = {
    OPEN_REGISTRATION: 'open-registration',
    OPEN_SITE_TRANSITION: 'open-site-transition',
    FINISH_LOADING: 'finish-loading',
    GUEST_TRANSFER_LOGIN: 'guest-transfer-login',
    PEAPOD_GATEWAY_LOGIN: 'peapod-gateway-login',
    MY_ACCOUNT_LOGIN: 'my-account-login-error'
  }

  isNativeLoginEnabled = (sourceKey) => {
    const store = window.sharedVue.config.globalProperties.$store
    const loginFeatureFlag = store.getters['SiteConfig/varByName']('feature_native_login')
    const enabled = store.getters['NativeContainer/isMobileApp']
    return loginFeatureFlag && enabled && typeof this.SOURCES[sourceKey] === 'string'
  }

  doNativeLogin = async (sourceKey) => {
    const enabled = await this.isNativeLoginEnabled(sourceKey)
    if (!enabled) {
      return false
    }

    switch (this.SOURCES[sourceKey]) {
      case 'menu-sign-in':
        return this.handleGuestTransferLogin(this.SOURCES[sourceKey])
      case 'peapod-gateway':
        return this.handlePeapodGatewayLogin(this.SOURCES[sourceKey])
      case 'my-account':
        return this.handleMyAccountReLogin(this.SOURCES[sourceKey])
      default:
        return false
    }
  }

  handleGuestTransferLogin = async (source) => {
    try {
      const vueInstance = window.sharedVue.config.globalProperties
      const response = await vueInstance.NativeContainer.capabilities.LoginServices.launchLogin(source)
      // update LoginStatus
      await vueInstance.$store.dispatch('LoginStatus/getLoginStatus')
      emit(this.EVENT_NAMES.GUEST_TRANSFER_LOGIN, response)
    } catch (error) {
      if (error) {
        const response = {
          data: error.response.body
        }
        const { code } = response.data.response
        if (code === 'STORE_CLOSED_PICKUP_AVAILABLE' || code === 'LOGIN_MIGR') {
          emit(this.EVENT_NAMES.GUEST_TRANSFER_LOGIN, response, true)
        } else {
          this.handleError(code)
        }
      }
    }
    return false
  }

  handlePeapodGatewayLogin = async (source) => {
    try {
      const vueInstance = window.sharedVue.config.globalProperties
      const response = await vueInstance.NativeContainer.capabilities.LoginServices.launchLogin(source)
      emit(this.EVENT_NAMES.PEAPOD_GATEWAY_LOGIN, response)
    } catch (error) {
      const response = {
        data: error.response.body
      }

      const { code } = response.data.response
      if (code === 'LOGIN_MISS_DLV_ST' || code === 'OPEN_REGISTRATION') {
        // user should input zip code
        return 'guest'
      }

      emit(this.EVENT_NAMES.PEAPOD_GATEWAY_LOGIN, response, true)
    }
    return false
  }

  handleMyAccountReLogin = async (source) => {
    try {
      const userLoggedIn = await isLoggedIn()
      if (!userLoggedIn) {
        const vueInstance = window.sharedVue.config.globalProperties
        // if not logged in authenticate first and then open my account modal
        await vueInstance.NativeContainer.capabilities.LoginServices.launchLogin(source)
        // update LoginStatus
        await vueInstance.$store.dispatch('LoginStatus/getLoginStatus')
        this.finishLoading()
      }
      return 'user-account-modal'
    } catch (error) {
      const response = {
        data: error.response.body
      }
      emit(this.EVENT_NAMES.MY_ACCOUNT_LOGIN, response, true)
      this.finishLoading()
      return false
    }
  }

  handleError = (errorCode) => {
    if (errorCode === 'LOGIN_INVALID_P') {
      emit(this.EVENT_NAMES.OPEN_SITE_TRANSITION)
    } else if (errorCode === 'OPEN_REGISTRATION') {
      emit(this.EVENT_NAMES.OPEN_REGISTRATION)
    }
    this.finishLoading()
  }

  finishLoading = () => {
    emit(this.EVENT_NAMES.FINISH_LOADING)
  }
}
