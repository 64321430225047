import ApiService from '@/api/'

export default {
  getCardBalance({ userId, giftCardNumber }) {
    return ApiService.post(`/api/v6.0/user/${userId}/giftcard/search`, {
      cardNumber: giftCardNumber
    })
  },
  getAppliedCards({ userId, basketId }) {
    return ApiService.get(`/api/v6.0/user/${userId}/order/${basketId}/giftcards`)
  },
  redeem({
    basketId, params, userId,
  }) {
    return ApiService.post(`/api/v6.0/user/${userId}/order/${basketId}/giftcards`, {
      cardNumber: params.giftCardNumber,
      amount: params.value,
    })
  },
}
