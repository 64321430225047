/** function getContentImgSrc - format cdn image url
 *
 * @param cdnUrl - string, cdn host value
 * @param id - string, path to image in format `contentId:GUIDfile`
 * @return String - formatted with appropriate cdn path
* */
import { DEFAULT_IMG } from 'utils/constants'

function getContentImgSrc(cdnUrl, id) {
  if (!id) {
    return DEFAULT_IMG
  }
  const imageUrl = id.split(':')
  return `${cdnUrl}/c/${imageUrl[1].substr(0, 2)}/${imageUrl[1]}`
}
export default getContentImgSrc
