import optimizelyFullstack from 'components/user-research/services/optimizely-fullstack-service'

export default {
  namespaced: true,
  state: {
    fullstackFailed: false,
    fullstackInitialized: false,
    showTileRatingsABTest: false,
    tileRatingsCategories: {},
    showSearchTrendPillsABTest: false,
    searchTrendPillsABTestVariation: '',
    showWeeklyAdQuotientAd: false,
    showDidYouForgetModal: false,
    showRedeemInCart: false,
    showPromoCode: false,
    shortFormVideoABTestVariation: false,
    hideRecommendedForYou: false,
    searchShelfLargeImageTile: false,
    searchShelfHeaderEnabled: false,
    showBannerXAtSearchDropdown: false,
    userContext: {},
    showNewNavigationMobileDesign: false,
    showShopByLifestyle: false,
    timeSlotNudge: {},
    timeSlotNudgeFinalWarning: false,
    pdpRedesignEnabled: false,
    subsRecommendationsABTestEnabled: false,
    isItemLevelSubsRecommendationsToggleABTestOn: false,
    isItemLevelSubsRecommendationsSelectSubABTestOn: false,
    itemLevelSubsRecommendationsABTestLoaded: false,
    showBannerXAtWeeklyAdTop: false,
    showBannerXAtWeeklyAdAfter20th: false,
    showBannerXAtWeeklyAdTopMobile: false,
    showBannerXAtAllSpecialsTop: false,
    showBannerXAtAllSpecialsTopMobile: false,
    showBannerXAtAllSpecialsAfter20th: false,
    showBannerXAtCouponsTop: false,
    showBannerXAtCouponsTopMobile: false,
    showBannerXAtCouponsAfter20th: false,
    subsToggleABTestLoaded: false,
    globalSubstitutionsControlABTestEnabled: false,
    hideGlobalSubstitutionsControlABTest: false,
    isGlobalSubstitutionsControlABTestToggleOn: false,
    semanticSearchFeatureEnabled: false,
    semanticSearchV7Enabled: false,
    searchFilterRedesignEnabled: false,
    searchFilterRedesignControl: false,
    searchSponsoredAdPositionsEnabled: false,
    searchSponsoredAdPositions: '',
    searchSponsoredAdPositionsInitialized: false,
    mobileWebContentTile: {},
    weeklyAdV2Enabled: false
  },
  mutations: {
    setFullstackFailed(state, value) {
      state.fullstackFailed = value
    },
    setUserContext(state, value) {
      state.userContext = value
    },
    setFullstackInitialized(state, fullstackInitialized = false) {
      state.fullstackInitialized = !!fullstackInitialized
    },
    setShowTileRatingsABTest(state, showTileRatingsABTest = false) {
      state.showTileRatingsABTest = !!showTileRatingsABTest
    },
    setTileRatingsCategories(state, categoriesString = '') {
      const categoriesLookup = {}
      if (categoriesString) {
        const categories = categoriesString.toLowerCase().replace(/\s*;\s*/g, ';').split(';')
        categories.forEach((category) => {
          categoriesLookup[category] = true
        })
      }
      state.tileRatingsCategories = categoriesLookup
    },
    setShowSearchTrendPillsABTest(state, showSearchTrendPillsABTest = false) {
      state.showSearchTrendPillsABTest = showSearchTrendPillsABTest
    },
    setSearchTrendPillsABTestVariation(state, searchTrendPillsABTestVariation = '') {
      state.searchTrendPillsABTestVariation = searchTrendPillsABTestVariation
    },
    setShowWeeklyAdQuotientAd(state, showWeeklyAdQuotientAd = false) {
      state.showWeeklyAdQuotientAd = showWeeklyAdQuotientAd
    },
    setShowDidYouForgetModal(state, showDidYouForgetModal = false) {
      state.showDidYouForgetModal = showDidYouForgetModal
    },
    setShowRedeemInCart(state, showRedeemInCart = false) {
      state.showRedeemInCart = showRedeemInCart
    },
    setShowPromoCode(state, showPromoCode = false) {
      state.showPromoCode = showPromoCode
    },
    setShortFormVideoABTestVariation(state, shortFormVideoABTestVariation = '') {
      state.shortFormVideoABTestVariation = shortFormVideoABTestVariation
    },
    setHideRecommendedForYou(state, hideRecommendedForYou = false) {
      state.hideRecommendedForYou = hideRecommendedForYou
    },
    setSearchShelfLargeImageTile(state, searchShelfLargeImageTile = false) {
      state.searchShelfLargeImageTile = searchShelfLargeImageTile
    },
    setSearchShelfHeaderEnabled(state, searchShelfHeaderEnabled = false) {
      state.searchShelfHeaderEnabled = searchShelfHeaderEnabled
    },
    setSemanticSearchFeatureEnabled(state, semanticSearchFeatureEnabled = false) {
      state.semanticSearchFeatureEnabled = semanticSearchFeatureEnabled
    },
    setSemanticSearchV7Enabled(state, semanticSearchV7Enabled = false) {
      state.semanticSearchV7Enabled = semanticSearchV7Enabled
    },
    setSearchFilterRedesignEnabled(state, searchFilterRedesignEnabled = false) {
      state.searchFilterRedesignEnabled = searchFilterRedesignEnabled
    },
    setSearchFilterRedesignControl(state, searchFilterRedesignControl = false) {
      state.searchFilterRedesignControl = searchFilterRedesignControl
    },
    setShowBannerXAtSearchDropdown(state, showBannerXAtSearchDropdown = false) {
      state.showBannerXAtSearchDropdown = showBannerXAtSearchDropdown
    },
    setShowBannerXAtWeeklyAdTop(state, showBannerXAtWeeklyAdTop = false) {
      state.showBannerXAtWeeklyAdTop = showBannerXAtWeeklyAdTop
    },
    setShowBannerXAtWeeklyAdTopMobile(state, showBannerXAtWeeklyAdTopMobile = false) {
      state.showBannerXAtWeeklyAdTopMobile = showBannerXAtWeeklyAdTopMobile
    },
    setShowBannerXAtWeeklyAdAfter20th(state, showBannerXAtWeeklyAdAfter20th = false) {
      state.showBannerXAtWeeklyAdAfter20th = showBannerXAtWeeklyAdAfter20th
    },
    setShowBannerXAtAllSpecialsTop(state, showBannerXAtAllSpecialsTop = false) {
      state.showBannerXAtAllSpecialsTop = showBannerXAtAllSpecialsTop
    },
    setShowBannerXAtAllSpecialsTopMobile(state, showBannerXAtAllSpecialsTopMobile = false) {
      state.showBannerXAtAllSpecialsTopMobile = showBannerXAtAllSpecialsTopMobile
    },
    setShowBannerXAtAllSpecialsAfter20th(state, showBannerXAtAllSpecialsAfter20th = false) {
      state.showBannerXAtAllSpecialsAfter20th = showBannerXAtAllSpecialsAfter20th
    },
    setShowBannerXAtCouponsTop(state, showBannerXAtCouponsTop = false) {
      state.showBannerXAtCouponsTop = showBannerXAtCouponsTop
    },
    setShowBannerXAtCouponsTopMobile(state, showBannerXAtCouponsTopMobile = false) {
      state.showBannerXAtCouponsTopMobile = showBannerXAtCouponsTopMobile
    },
    setShowBannerXAtCouponsAfter20th(state, showBannerXAtCouponsAfter20th = false) {
      state.showBannerXAtCouponsAfter20th = showBannerXAtCouponsAfter20th
    },
    setShowNewNavigationMobileDesign(state, showNewNavigationMobileDesign = false) {
      state.showNewNavigationMobileDesign = showNewNavigationMobileDesign
    },
    setShowShopByLifestyle(state, showShopByLifestyle = false) {
      state.showShopByLifestyle = showShopByLifestyle
    },
    setTimeSlotNudge(state, timeSlotNudge = {}) {
      state.timeSlotNudge = timeSlotNudge
    },
    setMobileWebContentTile(state, mobileWebContentTile = {}) {
      state.mobileWebContentTile = mobileWebContentTile
    },
    setTimeSlotNudgeFinalWarning(state, timeSlotNudgeFinalWarning = false) {
      state.timeSlotNudgeFinalWarning = timeSlotNudgeFinalWarning
    },
    setPdpRedesignEnabled(state, pdpRedesignEnabled = false) {
      state.pdpRedesignEnabled = pdpRedesignEnabled
    },
    setSubsRecommendationsABTestEnabled(state, subsRecommendationsABTestEnabled = false) {
      state.subsRecommendationsABTestEnabled = subsRecommendationsABTestEnabled
    },
    setIsItemLevelSubsRecommendationsToggleABTestOn(state, isItemLevelSubsRecommendationsToggleABTestOn = false) {
      state.isItemLevelSubsRecommendationsToggleABTestOn = isItemLevelSubsRecommendationsToggleABTestOn
    },
    setIsItemLevelSubsRecommendationsSelectSubABTestOn(state, isItemLevelSubsRecommendationsSelectSubABTestOn = false) {
      state.isItemLevelSubsRecommendationsSelectSubABTestOn = isItemLevelSubsRecommendationsSelectSubABTestOn
    },
    setItemLevelSubsRecommendationsABTestLoaded(state, itemLevelSubsRecommendationsABTestLoaded = false) {
      state.itemLevelSubsRecommendationsABTestLoaded = itemLevelSubsRecommendationsABTestLoaded
    },
    setSubsToggleABTestLoaded(state, subsToggleABTestLoaded = false) {
      state.subsToggleABTestLoaded = subsToggleABTestLoaded
    },
    setGlobalSubstitutionsControlABTestEnabled(state, globalSubstitutionsControlABTestEnabled = false) {
      state.globalSubstitutionsControlABTestEnabled = globalSubstitutionsControlABTestEnabled
    },
    setHideGlobalSubstitutionsControlABTest(state, hideGlobalSubstitutionsControlABTest = false) {
      state.hideGlobalSubstitutionsControlABTest = hideGlobalSubstitutionsControlABTest
    },
    setIsGlobalSubstitutionsControlABTestToggleOn(state, isGlobalSubstitutionsControlABTestToggleOn = false) {
      state.isGlobalSubstitutionsControlABTestToggleOn = isGlobalSubstitutionsControlABTestToggleOn
    },
    setSearchSponsoredAdPositionsEnabled(state, searchSponsoredAdPositionsEnabled = false) {
      state.searchSponsoredAdPositionsEnabled = searchSponsoredAdPositionsEnabled
    },
    setSearchSponsoredAdPositions(state, sponsoredAdPositions = '') {
      state.searchSponsoredAdPositions = sponsoredAdPositions
    },
    setSearchSponsoredAdPositionsInitialized(state) {
      state.searchSponsoredAdPositionsInitialized = true
    }
  },
  actions: {
    async getOptimizelyDataForSubABTest({ commit, state }) {
      if (state.fullstackInitialized) {
        commit('setItemLevelSubsRecommendationsABTestLoaded', false)
        const subsRecommendationsABTestEnabled = await optimizelyFullstack
          .isFeatureEnabled('subs_recommendations')
        const isItemLevelSubsRecommendationsToggleABTestOn = await optimizelyFullstack
          .getFeatureVariableBoolean('subs_recommendations',
            'item_level_toggle')
        const isItemLevelSubsRecommendationsSelectSubABTestOn = await optimizelyFullstack
          .getFeatureVariableBoolean('subs_recommendations',
            'subs_recommendations')

        commit('setSubsRecommendationsABTestEnabled', subsRecommendationsABTestEnabled)
        commit('setIsItemLevelSubsRecommendationsToggleABTestOn', isItemLevelSubsRecommendationsToggleABTestOn)
        commit('setIsItemLevelSubsRecommendationsSelectSubABTestOn', isItemLevelSubsRecommendationsSelectSubABTestOn)

        commit('setItemLevelSubsRecommendationsABTestLoaded', true)
      }
    },
    async getOptimizelyDataForSubsToggleABTest({ commit, state }) {
      if (state.fullstackInitialized) {
        commit('setSubsToggleABTestLoaded', false)
        const globalSubstitutionsControlABTestEnabled = await optimizelyFullstack
          .isFeatureEnabled('cart_level_substitutions_toggle')
        const hideGlobalSubstitutionsControlABTest = await optimizelyFullstack
          .getFeatureVariableBoolean('cart_level_substitutions_toggle',
            'cart_level_substitutions_toggle_hidden')
        const isGlobalSubstitutionsControlABTestToggleOn = await optimizelyFullstack
          .getFeatureVariableBoolean('cart_level_substitutions_toggle',
            'cart_level_substitutions_toggle_on')
        const isGlobalSubstitutionsControlABTestToggleOff = await optimizelyFullstack
          .getFeatureVariableBoolean('cart_level_substitutions_toggle',
            'cart_level_substitutions_toggle_off')

        commit('setGlobalSubstitutionsControlABTestEnabled', globalSubstitutionsControlABTestEnabled)
        commit('setHideGlobalSubstitutionsControlABTest', hideGlobalSubstitutionsControlABTest)

        if (isGlobalSubstitutionsControlABTestToggleOn && !isGlobalSubstitutionsControlABTestToggleOff) {
          commit('setIsGlobalSubstitutionsControlABTestToggleOn', true)
        }
        if (!isGlobalSubstitutionsControlABTestToggleOn && isGlobalSubstitutionsControlABTestToggleOff) {
          commit('setIsGlobalSubstitutionsControlABTestToggleOn', false)
        }

        commit('setSubsToggleABTestLoaded', true)
      }
    }
  }
}
