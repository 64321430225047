import axios from 'axios'

const http = axios.create()

// expose http instance for client app
window.http = http

http.interceptors.request.use((config) => {
  if (!config.url) {
    return config
  }
  if (!config.isTemplateUrlPath) {
    return config
  }
  if (!config.baseURL) {
    config.baseURL = window.location.href
  }
  if (config?.isTemplateUrlPath && !config?.clonedTemplatePath) {
    // keep a clone of the URL template
    config.clonedTemplatePath = config.url.slice()
  }
  const currentUrl = new URL(config.clonedTemplatePath, config.baseURL)
  // parse pathName to implement variables
  Object.entries(config.pathVariables || {}).forEach(([
    k,
    v,
  ]) => {
    currentUrl.pathname = currentUrl.pathname.replace(`:${k}`, encodeURIComponent(v))
  })
  return {
    ...config,
    url: currentUrl.toString(),
  }
})

export default {
  getInstance() {
    return http
  },
  async put(url, data, config) {
    try {
      return await http.put(url, data, config)
    } catch (error) {
      return error
    }
  },
  async post(url, data, config) {
    try {
      return await http.post(url, data, config)
    } catch (error) {
      return error
    }
  },
  async patch(url, data, config) {
    try {
      return await http.patch(url, data, config)
    } catch (error) {
      return error
    }
  },
  async get(url, config) {
    try {
      return await http.get(url, config)
    } catch (error) {
      return error
    }
  },
  async delete(url, config) {
    try {
      return await http.delete(url, config)
    } catch (error) {
      return error
    }
  },
  // This fixes an issue where base fuctions for PUT, POST, and GET return does not provide response object,
  //    but only provides axios error message string 'Error: Request failed with status code {CODE}'
  //    response can be found on err.response property
  // usage: try { ApiService.corePut(url, data, config) } catch (err) { return err.response }
  async coreGet(url, config) {
    return http.get(url, config)
  },
  async corePut(url, data, config) {
    return http.put(url, data, config)
  },
  async corePost(url, data, config) {
    return http.post(url, data, config)
  }
}
