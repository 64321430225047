/** function formatTimeRemaining - format totalSeconds into minutes:seconds for display
 *
 * @param totalSeconds - seconds to be formatted
 * @return timeRemaining - formatted time Remaining (Minutes:Seconds)
* */

export default function (totalSeconds) {
  if (!totalSeconds) return ''

  const minutesLeft = Math.floor((totalSeconds % 3600) / 60)
  const secondsLeft = Math.floor((totalSeconds % 3600) % 60)
  const minutesDisplay = minutesLeft < 10 ? `0${minutesLeft}` : minutesLeft
  const secondsDisplay = secondsLeft < 10 ? `0${secondsLeft}` : secondsLeft
  return `${minutesDisplay}:${secondsDisplay}`
}
