import { mapMutations } from 'vuex'

export default {
  data: () => ({
    debounceTime: 200,
    onResize: null
  }),
  created() {
    this.setSizes()
    this.onResize = _.debounce(this.setSizes, this.debounceTime)
    window.addEventListener('resize', this.onResize)
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onResize)
    this.onResize = null
  },
  methods: {
    ...mapMutations('ScreenSize', [
      'setSizes'
    ])
  }
}
