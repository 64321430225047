const defaultSize = ['mobile']

export default {
  namespaced: true,
  state: {
    // Derived from legacy Angular config view-helper/directives/view-directives.js
    queries: {
      xxsmallMobile: '(max-width:320px)',
      xsmallMobile: '(max-width:380px)',
      smallMobile: '(max-width:430px)',
      mobileMini: '(max-width: 600px)',
      mobile: '(max-width: 680px)',
      tablet: '(max-width: 1200px) and (min-width: 681px)', // changed from Angular to prevent false 'mobile' truthy
      tabletMini: '(max-width: 870px) and (min-width: 681px)',
      tabletMicro: '(max-width: 768px) and (min-width: 681px)',
      tabletWithMax1045: '(max-width: 1045px) and (min-width: 681px)',
      browseMobile: '(max-width: 960px)',
      browseTablet: '(max-width: 1200px) and (min-width: 1151px)',
      browseTabletMini: '(max-width: 1150px) and (min-width: 961px)',
      browseSmallDesktop: '(min-width: 1201px) and (max-width: 1230px)',
      desktop: '(min-width:1201px)',
      desktopSmall: '(min-width: 1201px) and (max-width: 1250px)',
      desktopWithMax1375: '(min-width: 1201px) and (max-width: 1375px)',
      desktopWithMax1432: '(min-width: 1201px) and (max-width: 1432px)',
      desktopWithMax1501: '(min-width: 1201px) and (max-width: 1501px)',
      largeDesktop: '(min-width:1202px) and (max-width: 1321px)',
      xlargeDesktop: '(min-width: 1400px)',
      xlargeDesktopHeroBannerOnly: '(min-width: 1601px)',
      xxlargeDesktop: '(min-width: 1921px)'
    },
    sizes: defaultSize
  },
  getters: {
    queries: state => state.queries,
    screenSize: state => size => state.sizes.includes(size),
    isSmallMobile: state => state.sizes.includes('smallMobile'),
    isExtraSmallMobile: state => state.sizes.includes('xsmallMobile'),
    isExtraExtraSmallMobile: state => state.sizes.includes('xxsmallMobile'),
    isMobileMini: state => state.sizes.includes('mobileMini'),
    isMobile: state => state.sizes.includes('mobile'),
    isTabletMini: state => state.sizes.includes('tabletMini'),
    isTabletMicro: state => state.sizes.includes('tabletMicro'),
    isTablet: state => state.sizes.includes('tablet'),
    isDesktop: state => state.sizes.includes('desktop'),
    isDesktopSmall: state => state.sizes.includes('desktopSmall'),
    isTabletWithMax1045: state => state.sizes.includes('tabletWithMax1045'),
    isDesktopWithMax1375: state => state.sizes.includes('desktopWithMax1375'),
    isDesktopWithMax1432: state => state.sizes.includes('desktopWithMax1432'),
    isDesktopWithMax1501: state => state.sizes.includes('desktopWithMax1501'),
    isLargeDesktop: state => state.sizes.includes('largeDesktop'),
    isXlargeDesktop: state => state.sizes.includes('xlargeDesktop'),
    isXLargeDesktopHeroBannerOnly: state => state.sizes.includes('xlargeDesktopHeroBannerOnly'),
    isXXLargeDesktop: state => state.sizes.includes('xxlargeDesktop'),
    isTabletOrSmaller: state => state.sizes.includes('tablet') || state.sizes.includes('mobile'),
    isTabletMiniOrSmaller: state => state.sizes.includes('tabletMini') || state.sizes.includes('mobile'),
    isTabletMicroOrSmaller: state => state.sizes.includes('tabletMicro') || state.sizes.includes('mobile'),
    isMobileDevice: () => (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)),
    isBrowseMobile: state => state.sizes.includes('browseMobile')
  },
  mutations: {
    setSizes(state) {
      state.sizes = Object.keys(state.queries)
        .filter(key => window.matchMedia(state.queries[key]).matches)
    }
  }
}
