import ApiService from '@/api/'

export default {
  getReviews(config) {
    const bazaarVoiceConfig = window.appConfig.bazaarVoice || {}

    const params = new URLSearchParams({
      limit: bazaarVoiceConfig.pageSize,
      method: 'reviews.json',
      include: 'Products',
      stats: 'Reviews', // Used to get rating distribution
      sort: config.sortType,
      offset: config.offset
    })

    if (config.productId) {
      params.append('filter', `ProductId:${config.productId}`)
    }

    if (config.userId) {
      params.append('filter', `AuthorId:${config.userId}`)
    }

    const queryParams = {
      params
    }

    return ApiService.get('/apis/bazaarvoice/product/v1/reviews', queryParams)
  },
  submitReview(config) {
    const data = new URLSearchParams({
      userEmail: config.userEmail,
      userId: config.userId,
      action: config.action, // Action=preview or Action=submit
      rating: config.rating,
      title: config.title,
      reviewText: config.reviewtext,
      productId: config.productId,
      userNickname: config.userNickname,
      additionalField_viewName: config.viewName
    })

    const queryParams = {
      params: {
        productId: config.productId
      }
    }

    return ApiService.post(`/apis/bazaarvoice/product/v1/submit/reviews`, data, queryParams)
  }
}
