<template>
  <div
    v-if="isActive"
    role="menu"
    aria-labelledby="menuId"
    tabindex="-1"
    :class="[
      'tile-menu',
      { 'tile-menu--mobile' : isMobile },
      { 'tile-menu--list-view' : isListView },
      { 'tile-menu--item-details' : isItemDetails }
    ]"
  >
    <div
      v-clickaway="{
        exclude: `${toggleButtonId}`,
        handler: 'closeOnClickaway'
      }"
    >
      <div class="menutip tile-menu_tip" />
      <header class="tile-menu_header">
        <h2
          id="menuId"
          class="tile-menu_heading"
        >
          {{ title }}
        </h2>
      </header>
      <slot />
      <button
        aria-label="close dialog"
        class="tile-menu_close-button"
        @click.stop="closeWithButton"
      >
        <PdlIconBase
          icon-title="Close"
          container-class="modal_close-icon"
          :icon-color="closeIconColor"
        >
          <PdlIconClose />
        </PdlIconBase>
      </button>
    </div>
  </div>
</template>
<script>
import { PdlIconBase } from '@/shared/icons'
import PdlIconClose from 'components/icons/icon/PdlIconClose'
import clickaway from 'utils/custom-directives/clickaway'

export default {
  components: {
    PdlIconBase,
    PdlIconClose
  },
  directives: { clickaway },
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    isListView: {
      type: Boolean,
      default: false
    },
    isItemDetails: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: true
    },
    toggleButtonId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      closeIconColor: '#63666A'
    }
  },
  computed: {
    label() {
      return this.title.replace(/\s/g, '-').toLowerCase()
    },
    menuId() {
      const uniqueId = Date.now() + (Math.random() * 1000).toString().slice(-4)
      return `${this.label}-${uniqueId}`
    }
  },
  methods: {
    closeOnClickaway() {
      this.$emit('close-menu', 'clickaway')
    },
    closeWithButton() {
      // Creating two distinct close methods was the simplest way to emit
      // the focus argument since arguments cannnot be passed to clickaway.
      this.$emit('close-menu', 'closeButton')
    }
  }
}
</script>
