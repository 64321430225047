const GTM = {
  accountCreation: 'gt-account-creation',
  accountHistoryPdf: 'gt-account-history-pdf',
  accountNav: 'gt-account-nav',
  accountSave: 'gt-account-save',
  accountTypes: 'gt-account-types',
  addToCart: 'gt-add-to-cart',
  agentChat: 'gt-agent-chat',
  articleLoadMore: 'gt-article-load-more',
  articleSearch: 'gt-article-search',
  availableTimesShow: 'gt-available-times-show',
  browseAisles: 'gt-browse-aisles',
  browseAislesSearch: 'gt-browse-aisles-search',
  cancelOrderPrompt: 'gt-cancel-order-prompt',
  changeHealthPreference: 'healthlifestyle-pref-change',
  cartUpdateExistingOrder: 'gt-cart-update-existing-order',
  changeOrderInfo: 'gt-change-order-info',
  charityDonationThankYouModal: 'charity-donation-thank-you-modal',
  charityDonationsDetailsModal: 'charity-donations-details-modal',
  charityDonationsViewHistoryClick: 'charity-donation-view-history',
  checkoutStep: 'gt-checkout-step',
  checkoutOption: 'gt-checkout-option',
  communicationsSubmit: 'gt-communications-submit',
  communicationsToggle: 'gt-communications-toggle',
  couponInfoClick: 'gt-coupon-info-click',
  couponInfoFaqClick: 'gt-coupon-info-faq-click',
  couponSearch: 'gt-coupon-search',
  couponView: 'gt-coupon-view',
  dataDogSession: 'gt-data-dog-session',
  deliveryCity: 'gt-delivery-city',
  deliveryZip: 'gt-delivery-zip',
  deliveryModuleLoaded: 'gt-delivery-module-loaded',
  deliverySelection: 'gt-delivery-selection',
  ebtAddEbtClick: 'gt-ebt-addebt-click',
  ebtFaqQuestion: 'gt-ebt-faq-question',
  ebtSnapAmountUsed: 'gt-ebt-snap-amount-used',
  ebtSnapBalance: 'gt-ebt-snap-balance',
  ebtSnapError: 'gt-ebt-snap-error',
  ebtSnapErrorAction: 'gt-ebt-snap-error-action',
  filterResults: 'gt-filter-results',
  firstDeliverySelection: 'gt-first-delivery-selection',
  gateway: 'gt-gateway',
  giftcardGetBalance: 'gt-giftcard-get-balance',
  giftcardPurchaseClick: 'gt-giftcard-purchase-click',
  giftcardApplyBalance: 'gt-giftcard-apply-balance',
  giftcardCheckBalance: 'gt-giftcard-check-balance-click',
  guidingStarsLearnMoreClick: 'gt-guiding-stars-learn-more',
  helpCenterTracking: 'gt-help-center-tracking',
  helpSubmit: 'gt-help-submit',
  hpmodalOrderStatus: 'gt-hpmodal-orderStatus',
  hpmodalOrderStatusView: 'gt-hpmodal-orderStatus-view',
  homePage: 'gt-home-page',
  instacartV2Error: 'instacart-v2-error',
  itemNotesModal: 'gt-item-notes-modal',
  jumpPointClicked: 'gt-jump-point-clicked',
  linkedProductClick: 'gt-linkedproduct-click',
  loadCoupon: 'gt-load-coupon',
  lockerAdaOptionModified: 'gt-locker-ada-option-modified',
  lockerLearnMoreClicked: 'gt-locker-learn-more-clicked',
  lockerOrderPlaced: 'gt-locker-order-placed',
  lockerSmsNumberModified: 'gt-locker-sms-number-modified',
  lockerPrepForOrder: 'gt-locker-prepare-for-order',
  loyaltyId: 'gt-loyalty-id',
  marketplaceClickLearnMoreLink: 'gt-marketplace-click-learn-more-link',
  miniListImpression: 'gt-mini-list-impression',
  navigation: 'gt-navigation',
  navigationUpdated: 'gt-navigation-updated',
  oosSubProductDetailView: 'gt-oos-sub-product-detail-view',
  orderAlertsGetFailure: 'gt-order-alerts-get-failure',
  orderCancelled: 'gt-order-canceled',
  orderDetails: 'gt-order-details-track',
  orderDetailsFaq: 'gt-order-details-faq',
  orderDetailContact: 'gt-order-details-contact',
  orderPlaced: 'gt-order-placed',
  orderRating: 'gt-order-rating',
  orderRatingNotificationClick: 'gt-order-rating-notification-click',
  orderStatus: 'gt-order-status',
  orderSummarySwitchPaymentMethod: 'gt-order-summary-switch-payment-method',
  orderUpdated: 'gt-order-update',
  providerSwitch: 'gt-provider-switch',
  orderUpdateProductSearch: 'gt-order-update-product-search',
  pageView: 'gt-pageview',
  platform: 'gt-platform',
  podpassMonthlyOfferClick: 'gt-pass-monthly-offer',
  pointsDetailClick: 'points-detail-click',
  postDeliveryTipAdded: 'gt-post-delivery-tip-submitted',
  postDeliveryTipError: 'post-delivery-tip-error',
  productBadgeImpression: 'gt-product-badge-impression',
  productClick: 'gt-product-click',
  productDetailView: 'gt-product-detail-view',
  productExpand: 'gt-product-expand',
  productImpression: 'gt-product-impression',
  promotionClick: 'gt-promotion-click',
  promoCodeEntry: 'gt-promo-code-entry',
  promotionImpression: 'gt-promotion-impression',
  redeemPoints: 'gt-redeem-points',
  registrationStep: 'gt-registration-step',
  registrationSubmit: 'gt-registration-submit',
  removeFromCart: 'gt-remove-from-cart',
  reserveTimeSlot: 'gt-reserve-time',
  rewardsActivationCancellation: 'rewards-activation-cancellation',
  rewardsActivationConfirmation: 'rewards-activation-confirmation',
  rewardsProgramDetailsClick: 'gt-rewards-program-details-click',
  rewardsRedeemInCartImpression: 'gt-points-cart-redeem-impression',
  rewardsRedeemInCartRedemptionClick: 'gt-points-cart-redeem-redemption',
  rewardsTypeButtonClick: 'gt-rewards-type-button-click',
  rewardsTypePillboxClick: 'gt-rewards-type-pillbox-click',
  samedayAddressMismatch: 'gt-sameday-address-mismatch-error',
  samedaySlotUnavailable: 'gt-sameday-slot-unavailable-error',
  samedayFaqLinkClicked: 'gt-sameday-faq-link-clicked',
  savingsSearch: 'gt-savings-search',
  selectServiceType: 'gt-select-service-type',
  selectStore: 'gt-select-store',
  ship2MeOrderPlaced: 'gt-ship2me-order-placed',
  shoppableRecipe: 'gt-shoppable-recipe',
  shoppingList: 'gt-shopping-list',
  shoppingListPage: 'gt-shopping-list-page',
  showMore: 'gt-show-more',
  signInAttempt: 'gt-signin-attempt',
  signInForgotInfo: 'gt-signin-forgot-info',
  siteEntryNav: 'gt-site-entry-nav',
  siteSearch: 'gt-site-search',
  siteSearchPillClick: 'gt-site-search-pill-click',
  shoppingMethod: 'gt-shopping-method',
  slotReservationFailure: 'gt-slot-reservation-failure',
  sortIntent: 'gt-sort-intent',
  sortResults: 'gt-sort-results',
  storeDetails: 'gt-store-details',
  storeLocatorSearch: 'gt-store-locator-search',
  storeLocatorMapClick: 'gt-store-locator-map-click',
  storeLocatorResultClick: 'gt-store-locator-result-click',
  storeNumber: 'gt-store-number',
  storeSearch: 'gt-store-search',
  subscriptionPlanChange: 'gt-subscription-plan-change',
  subscriptionPlanChangeClick: 'gt-subscription-plan-change-click',
  subscriptionPlanCancelRenewalConfirm: 'gt-subscription-plan-cancel-renewal-confirm',
  subscriptionPlanClick: 'gt-subscription-plan-click',
  subscriptionPlanPurchase: 'gt-subscription-plan-purchase',
  sustainabilityHover: 'gt-sustainability-hover',
  sustainabilityLearnMoreClick: 'gt-sustainability-learn-more',
  substitutions: 'gt-substitutions',
  substitutionsSubmit: 'gt-substitutions-submit',
  swapDetail: 'gt-swap-detail',
  swapImpression: 'gt-swap-impression',
  swapProduct: 'gt-swap-product',
  unattendedDeliverySelection: 'gt-unattended-delivery-selection',
  userInformationCardAction: 'gt-user-information-card-action',
  viewAdditionalImages: 'gt-view-additional-images',
  viewCart: 'gt-view-cart',
  viewMarketplaceSeller: 'gt-view-marketplace-seller',
  viewSavings: 'view-savings',
  virtualPageview: 'gt-virtual-pageview',
  weeklyAdSearch: 'gt-weekly-ad-search',
  weeklyAdSearchError: 'gt-weekly-ad-search-error',
  sameDayErrorCode: 'gt-sameday-error-code',
  weeklySpecialEvent: 'gt-weekly-special-event',
  weeklySpecialPrintPdf: 'gt-weekly-special-print-pdf',
  workingOrders: 'gt-working-orders',
  generic: 'gt-generic-event'
}

const GTM_GA4 = {
  account: 'GA4-account',
  accountSignup: 'GA4-sign_up',
  accountSubscriptionPlanChange: 'GA4-user_params',
  addPaymentMethod: 'GA4-add_payment_info',
  addToCart: 'GA4-add_to_cart',
  addToWishList: 'GA4-add_to_wishlist',
  beginCheckout: 'GA4-begin_checkout',
  click: 'GA4-click',
  login: 'GA4-login',
  navigation: 'GA4-navigation',
  mobileCheck: 'GA4-mobile_check',
  orderPlaced: 'GA4-purchase',
  ecommerce: 'GA4-ecommerce',
  logout: 'GA4-logout',
  rewards: 'GA4-rewards',
  refund: 'GA4-refund',
  removeFromCart: 'GA4-remove_from_cart',
  search: 'GA4-search',
  searchFilter: 'GA4-search_filter',
  selectItem: 'GA4-select_item',
  selectPromotion: 'GA4-select_promotion',
  userProperties: 'GA4-user_properties',
  video: 'GA4-video',
  viewCart: 'GA4-view_cart',
  viewItem: 'GA4-view_item',
  viewItemList: 'GA4-view_item_list',
  viewPage: 'GA4-page_view',
  viewPromotion: 'GA4-view_promotion',
  rewardsProgramDetailsClick: 'GA4-rewards_program_details_click',
  pointsDetailClick: 'GA4-points_detail_click',
  optimizelyDecision: 'GA4-optimizely-decision'
}

export {
  GTM,
  GTM_GA4
}
