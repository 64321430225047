<template>
  <div class="menu_sidebar-content">
    <h2
      v-if="sidebarHead"
      class="menu_sidebar-head"
    >
      {{ sidebarHead }}
    </h2>
    <div class="menu_sidebar-card">
      <slot />
    </div>
    <a
      v-if="sidebarLink.url"
      class="menu_link menu_link--sidebar"
      :href="sidebarLink.url"
    >
      {{ sidebarLink.text }}
    </a>
  </div>
</template>

<script>
export default {
  props: {
    sidebarHead: {
      type: String,
      default: ''
    },
    sidebarLink: {
      type: Object,
      default() {
        return {
          url: '',
          text: ''
        }
      }
    }
  }
}
</script>
