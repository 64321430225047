import ApiService from '@/api/'

export default {
  put(data = {}) {
    const { value = true, userId = '' } = data
    return ApiService.put(`/api/v3.0/user/${userId}/attributes/confirm`, null, {
      params: {
        value
      },
    })
  }
}
