<template>
  <div
    class="flex-content_row"
  >
    <div
      v-if="showSpyGlassInfo"
      class="spyglass_container z-[9999]"
      :style="spyglassDebugStyle"
    >
      <button
        class="spyglass_container_close"
        aria-label="close dialog"
        @click.stop="close"
      >
        <PdlIconBase
          icon-title="Close"
          container-class="spyglass_container_close-icon"
        >
          <PdlIconClose />
        </PdlIconBase>
      </button>
      <div class="spyglass_container_content">
        <p
          class="select-text"
        >
          Branch Name: <strong>{{ branchName }}</strong>
        </p>
        <p v-if="screenSize">
          Screen Size: <strong>{{ screenSize }}</strong>
        </p>
        <button
          class="button button-width--flex"
          :class="[{
            'button--prime': isMobileApp,
            'button--fourth': !isMobileApp
          }]"
          @click="switchAppView('native')"
        >
          Native
        </button>
        <button
          class="button button-width--flex"
          :class="[{
            'button--prime': !isMobileApp,
            'button--fourth': isMobileApp
          }]"
          @click="switchAppView('web')"
        >
          Web
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import PdlIconClose from 'components/icons/icon/PdlIconClose'

export default {
  components: {
    PdlIconClose
  },
  data() {
    return {
      branchName: BRANCH_NAME,
      spyglassDebugStyle: {
        width: '25rem',
        height: '14rem',
        top: 'inherit',
        left: 'inherit',
        bottom: '2rem',
        right: '3rem',
        zIndex: '99999',
        position: 'fixed'
      },
      showSpyGlassInfo: true
    }
  },
  computed: {
    ...mapGetters({
      isMobile: 'ScreenSize/isMobile',
      isTablet: 'ScreenSize/isTablet',
      isDesktop: 'ScreenSize/isDesktop',
      isMobileApp: 'NativeContainer/isMobileApp'
    }),
    screenSize() {
      if (this.isMobile) {
        return 'mobile'
      }
      if (this.isTablet) {
        return 'tablet'
      }
      if (this.isDesktop) {
        return 'desktop'
      }
      return ''
    }
  },
  methods: {
    ...mapMutations({
      setMobileApp: 'NativeContainer/setMobileApp',
    }),
    switchAppView(appView) {
      this.setMobileApp(appView === 'native')
      this.$emit('content-refresh')
    },
    close() {
      this.showSpyGlassInfo = false
    }
  }
}

</script>
