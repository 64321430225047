/* eslint-disable complexity */
import UserPreferencesAPI from 'api/UserPreferencesAPI'
import { USER_PREFERENCE_KEYS } from 'utils/constants'

const initialState = {
  isMobileApp: false,
  isNativeApp: false,
  error: false,
  isMobileMoreMenuOpen: false,
  locationServicesEnabled: false,
  activeModalView: '',
  hidePhoneNumber: false,
  flybuyOrderFetchInProgress: false
}

const goBackPeapodClient = (isModalOpened = false) => {
  window.dispatchEvent(new CustomEvent('BackButtonHandlerEvent', { detail: { isModalOpened } }))
}

export default {
  namespaced: true,
  state: initialState,
  getters: {
    activeModalView: state => state.activeModalView,
    isMobileApp: state => state.isMobileApp, // React Native webview context
    isNativeApp: state => state.isNativeApp, // signifies new native app context
    isAnyApp: state => state.isNativeApp || state.isMobileApp, // for any app experience
    error: state => state.error,
    isMobileMoreMenuOpen: state => state.isMobileMoreMenuOpen,
    locationServicesEnabled: state => state.locationServicesEnabled,
    hidePhoneNumber: state => state.hidePhoneNumber,
    flybuyOrderFetchInProgress: state => state.flybuyOrderFetchInProgress
  },
  mutations: {
    setMobileApp(state, payload) {
      state.isMobileApp = payload
    },
    setNativeApp(state, payload) {
      window.localStorage.setItem('helpModeViewed', true)
      window.sharedVue.config.globalProperties.$store.commit('ShoppingMode/setHelpMode', false)
      window.sharedVue.config.globalProperties.$store.dispatch('GlobalHeader/removeMaskOverlay')
      state.isNativeApp = payload
    },
    setLocationServicesStatus(state, payload) {
      state.locationServicesEnabled = payload
    },
    setError(state, payload) {
      state.error = payload
    },
    setOpenMobileMoreMenu(state, value) {
      state.isMobileMoreMenuOpen = value
    },
    setCloseMobileMoreMenu(state, value) {
      state.isMobileMoreMenuOpen = value
    },
    setActiveModalView(state, value) {
      state.activeModalView = value
    },
    setHidePhoneNumber(state, value) {
      state.hidePhoneNumber = value
    },
    setFlybuyOrderFetchInProgress(state, value) {
      state.flybuyOrderFetchInProgress = value
    }
  },
  actions: {
    logNativeContainerError({ commit }) {
      commit('setError', true)
    },
    setOpenMobileMoreMenu({ commit }) {
      commit('setOpenMobileMoreMenu', true)
    },
    setCloseMobileMoreMenu({ commit }) {
      commit('setCloseMobileMoreMenu', false)
      commit('MobileAppNavigation/tabChangeListener', window.location.pathname, { root: true })
    },
    handleBackHandler({ commit, state, rootGetters }) {
      // any opened modal must be closed first
      if (state.activeModalView !== '') {
        goBackPeapodClient(true)
      } else if (state.isMobileMoreMenuOpen) {
        commit('setCloseMobileMoreMenu', false)
        commit('MobileAppNavigation/backToPreviousTab', null, { root: true })
      } else if (rootGetters['SearchTerms/isVisible']) {
        commit('SearchTerms/setIsVisible', false, { root: true })
      } else if (window.location.pathname !== '/') {
        goBackPeapodClient()
      } else {
        window.sharedVue.config.globalProperties.NativeContainer.capabilities.BackHandlerService.exitApp()
      }
    },
    async handleOrderStatusUpdate({
      getters, rootGetters, dispatch
    }) {
      try {
        if (getters.flybuyOrderFetchInProgress) {
          return false
        }

        const flyBuyEnabledForStoreOrOpco = await dispatch('SiteConfig/getStoreConfigValue', {
          attribute: 'feature_fly_buy_enabled'
        }, { root: true })

        if (!flyBuyEnabledForStoreOrOpco) {
          return false
        }
        const pendingOrderList = rootGetters['Order/pendingOrders'] || []
        if (pendingOrderList && pendingOrderList.length > 0) {
          let currentPendingOrder = null
          pendingOrderList.forEach((order) => {
            const { timeStartWithZoneOffset, timeEndWithZoneOffset } = order
            const timeNow = new Date().getTime()
            if (timeNow >= new Date(timeStartWithZoneOffset).getTime()
              && timeNow < new Date(timeEndWithZoneOffset).getTime()) {
              currentPendingOrder = order
            }
          })
          const {
            serviceType, orderId
          } = currentPendingOrder || {}
          if (serviceType === 'P') {
            dispatch('setFlyBuyUserPref', orderId)
            return true
          }
        }
        return false
      } catch (e) {
        return false
      }
    },
    async setFlyBuyUserPref({
      rootGetters, commit
    }, orderId) {
      commit('setFlybuyOrderFetchInProgress', true)
      const userId = rootGetters['UserProfile/userId']
      const { data, status } = await UserPreferencesAPI.get(
        USER_PREFERENCE_KEYS.PUP_CUSTOMER_TOKEN, userId
      )
      let customerToken = ''
      if (status === 200 && data.response.preference.value) {
        customerToken = data.response.preference.value
      }
      const payload = {
        customerToken, orderId
      }
      const vueInstance = window.sharedVue.config.globalProperties
      await vueInstance.NativeContainer.capabilities.ClickAndCollectPickupService.onmyway(payload)
      commit('setFlybuyOrderFetchInProgress', false)
    },
    async showSMSPickupAlert({
      rootGetters, commit
    }) {
      let shouldShowSmsPickup = true

      // we don't wanna show SMS at all if this is false
      if (!rootGetters['Order/allowSMSPickup']) {
        return false
      }

      const { NativeContainer } = window.sharedVue.config.globalProperties
      const isMobileApp = await NativeContainer.isEnabledPromise()
      if (isMobileApp) {
        const isTablet = await NativeContainer.capabilities.DeviceInfo.isTablet()
        if (!isTablet) {
          const isLocationEnabled = await NativeContainer.capabilities.SoftAskService.enableSoftAskFlow('location')
          if (isLocationEnabled) {
            await NativeContainer.capabilities.SoftAskService.enableSoftAskFlow('push')
          }
          shouldShowSmsPickup = !isLocationEnabled
        }
      }
      commit('setHidePhoneNumber', !shouldShowSmsPickup)
      return shouldShowSmsPickup
    }
  }
}
