import { isObject, isArray } from 'utils/basic/ref-checkers'

/**
 * Creates an aggregate query data object based on any number of parameters. This also converts each
 * property's value to be an array, then combines all arrays.
 *
 * @param baseQueryData
 * @param [otherQueryDatas*] any number of other query data arguments
 *
 * @returns {object} queryData
 */
export default function (baseQueryData, ...rest) {
  return isObject(baseQueryData)
    ? [baseQueryData, ...rest]
      .filter(isObject)
      .reduce((accum, obj) => Object
        .entries(obj)
        .reduce((objAccum, [name, value]) => ({
          ...objAccum,
          [name]: [...accum[name] || [], ...(isArray(value) ? value : [value])]
        }), accum),
      {})
    : {}
}
