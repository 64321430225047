<template>
  <div
    class="togglebox"
    :class="{ 'togglebox--menu': menuStyle }"
  >
    <slot name="before" />
    <label
      :for="toggleId"
      class="togglebox_wrap"
    >
      <input
        v-if="type === 'radio'"
        v-bind="attrs"
        :id="toggleId"
        :value="optionValue"
        :checked="isChecked"
        :name="name"
        :type="type"
        :aria-describedby="hasMessages ? `${toggleId}-messages` : null"
        class="togglebox_input togglebox_input--radio"
        :class="inputClasses"
        :disabled="disabled"
        @change="tempValue = $event.target.value"
      >
      <input
        v-else
        v-model="tempValue"
        v-bind="attrs"
        :id="toggleId"
        :name="name"
        :type="type"
        :aria-checked="isChecked"
        :aria-describedby="hasMessages ? `${toggleId}-messages` : null"
        class="togglebox_input"
        :class="inputClasses"
        :disabled="disabled"
      >
      <div
        class="togglebox_text"
        :class="{ 'togglebox_text--disabled': disabled }"
      >
        <span v-if="!hideLabel">{{ label }}</span>
        <slot name="label" />
      </div>
      <div
        class="togglebox_block"
        :class="[`togglebox_block--${type}`]"
      >
        <div
          v-if="type === 'radio'"
          class="togglebox_icon radio_dot"
        />

        <PdlIconBase
          v-else
          icon-title="checkbox icon"
          container-class="togglebox_icon vector-icon-size--medium"
          :icon-class="iconColor"
        >
          <PdlIconCheckmark class="togglebox_icon" />
        </PdlIconBase>
      </div>
    </label>
    <slot
      class="field_row"
      name="after"
    />
    <InputMessages
      :id="`${toggleId}-messages`"
      class="field_row"
      :errors="errors"
      :description="description"
      :has-errors="hasErrors"
      :has-persistent-messages="hasPersistentMessages"
      :persistent-messages="persistentMessagesList"
    />
  </div>
</template>
<script>
import { PdlIconBase } from '@/shared/icons'
import PdlIconCheckmark from 'components/icons/icon/PdlIconCheckmark'
import InputBase from './PdlInputBaseMixin'

export default {
  components: {
    PdlIconCheckmark,
    PdlIconBase
  },
  emits: ['change', 'input', 'update:modelValue'],
  mixins: [InputBase],
  props: {
    modelValue: {
      type: [String, Number, Boolean],
    },
    disabled: {
      type: Boolean,
      default: false
    },
    menuStyle: {
      type: Boolean,
      default: false
    },
    optionValue: {
      type: [String, Number, Boolean],
      default: ''
    },
    groupKey: {
      type: [String, Number],
      default: '0'
    },
    type: {
      type: String,
      default: 'radio',
      validator(value) {
        // The value must match one of these strings
        return (
          [
            'checkbox',
            'radio'
          ].indexOf(value) !== -1
        )
      }
    },
  },
  computed: {
    iconColor() {
      return this.disabled ? 'vector-icon-stroke--light-grey' : 'vector-icon-stroke--prime'
    },
    isChecked() {
      return !_.isUndefined(this.optionValue) ? this.optionValue === this.tempValue : this.tempValue
    },
    toggleId() {
      return (this.type === 'radio') ? `${this.id}-${this.groupKey}` : this.id
    },
    tempValue: {
      get() {
        return this.modelValue
      },
      set(value) {
        const emitter = (this.type === 'radio') ? 'input' : 'update:modelValue'
        this.$emit(emitter, value)
      }
    }
  }
}
</script>
