import swapNSaveApi from 'api/swapAndSave'

export default {
  namespaced: true,
  state: {
    productId: 0,
    isSwapNSave: false,
    swapNSaveProductList: [],
    offer: {}
  },
  mutations: {
    setOffer(state, value) {
      state.offer = value
    },
    setProductId(state, value) {
      state.productId = value
    },
    toggleSwapNSave(state, value) {
      state.isSwapNSave = value
    },
    setSwapNSaveProductList(state, value) {
      state.swapNSaveProductList = value
    },
  },
  actions: {
    async getSwapAndSaveProducts({ commit, rootGetters }, product) {
      const userId = rootGetters['UserProfile/userId']
      const { serviceLocationId } = rootGetters['UserProfile/refData'].deliveryServiceLocation
      const isNewProductApiServices = rootGetters['SiteConfig/varByName']('feature_category_product_new_services')
      const isSponsoredAdsFromCitrus = rootGetters['SiteConfig/varByName']('feature_citrus_sponsored_ad')
      const apiPathParams = {
        userId,
        serviceLocationId,
        isNewProductApiServices,
        isSponsoredAdsFromCitrus
      }
      const response = await swapNSaveApi(
        apiPathParams, product.unitPrice, product.productCategoryId, product.price
      )
      try {
        if (response.status === 200 && response.data.response.products.length > 0) {
          commit('setSwapNSaveProductList', response.data.response.products)
          commit('setProductId', product.prodId)
        } else {
          commit('setSwapNSaveProductList', [])
        }
      } catch (err) {
        commit('toggleSwapNSave', false)
        commit('setSwapNSaveProductList', [])
      }
    },
    toggleSwapNSave({ commit }, payload) {
      commit('toggleSwapNSave', payload)
    },
    setSwapNSaveProductList({ commit }, payload) {
      commit('setSwapNSaveProductList', payload)
    }
  }
}
