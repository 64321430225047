import RecaptchaBlock from 'components/recaptcha/RecaptchaBlock'

export default {
  components: {
    RecaptchaBlock
  },
  data() {
    return {
      verifiedCaptcha: false,
      recaptchaLoaded: false,
      recaptchaBlockID: 'recaptchaBlock',
      recaptchaResponse: {}
    }
  },
  props: {
    recaptchaAllowed: {
      type: Boolean,
      default: true
    }
  },
  mounted() {
    if (!this.recaptchaAllowed) {
      this.verifiedCaptcha = true
    }
  },
  computed: {
    isCaptchaComplete() {
      return !this.recaptchaAllowed || this.verifiedCaptcha
    }
  },
  methods: {
    onRecaptchaLoaded() {
      this.recaptchaLoaded = true
    },
    onRecaptchaChange(val, response) {
      this.verifiedCaptcha = val
      this.recaptchaResponse = response
      if (this.v$) this.v$.$touch()
    },
    resetCaptcha() {
      if (this.$refs[this.recaptchaBlockID]) {
        this.$refs[this.recaptchaBlockID].resetCaptcha()
      }
    }
  }
}
