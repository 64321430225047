const MAX_USED_SEARCH_TERMS = 3
const USED_SEARCH_TERMS_KEY = 'recent_keywords'
export default {
  namespaced: true,
  state: {
    topSearchTerms: [],
    usedSearchTerms: [],
    initialized: false,
    isVisible: false,
  },
  getters: {
    topSearchTerms: state => state.topSearchTerms,
    usedSearchTerms: state => state.usedSearchTerms,
    initialized: state => state.initialized,
    isVisible: state => state.isVisible
  },
  mutations: {
    setTopSearchTerms(state, value) {
      state.topSearchTerms = value
    },
    addUsedSearchTerms(state, value) {
      if (!value) {
        return
      }
      if (state.usedSearchTerms.length > 0 && state.usedSearchTerms[0] === value) {
        return
      }
      if (state.usedSearchTerms.length >= MAX_USED_SEARCH_TERMS) {
        state.usedSearchTerms.pop()
      }
      state.usedSearchTerms.unshift(value)
      window.localStorage.setItem(USED_SEARCH_TERMS_KEY, JSON.stringify(state.usedSearchTerms))
    },
    removeUsedSearchTerms(state, position) {
      if (position && state.usedSearchTerms.length) {
        const value = state.usedSearchTerms[position - 1]
        if (value) {
          state.usedSearchTerms.splice(position - 1, 1)
        }
        window.localStorage.setItem(USED_SEARCH_TERMS_KEY, JSON.stringify(state.usedSearchTerms))
      }
    },
    setInitialized(state) {
      state.initialized = true
    },
    restoreTerms(state, value) {
      state.usedSearchTerms = value
    },
    setIsVisible(state, value) {
      state.isVisible = value
    }
  },
  actions: {
    fetchUsedTerms({ commit }) {
      const anyStoredItems = window.localStorage.getItem(USED_SEARCH_TERMS_KEY)
      const currentStoredTerms = JSON.parse(anyStoredItems || '[]')
      commit('restoreTerms', currentStoredTerms)
    },
    clearUsedKeywords({ commit }) {
      const anyStoredItems = window.localStorage.getItem(USED_SEARCH_TERMS_KEY)
      if (anyStoredItems) {
        window.localStorage.removeItem(USED_SEARCH_TERMS_KEY)
      }
      commit('restoreTerms', [])
    }
  }
}
