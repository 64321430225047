import ApiService from '@/api/'

export default {
  /**
  *   Retrieves different preferences related to the user account
  *   @param {string} preferenceName - Required. Specific user preference name. Available preferences in constants.js
  *   @param {string} userId - Required. Necessary for api call
  */
  get: async (preferenceName, userId) => {
    return ApiService.get(`/api/v3.0/user/${userId}/preferences/${preferenceName}`)
  },
  /**
  *   Retrieves different preferences related to the user account
  *   @param {string} preferenceName - Required. Specific user preference name. Available preferences in constants.js
  *   @param {string} userId - Required. Necessary for api call
  */
  update: async (preferenceName, value, userId) => {
    return ApiService.post(`/api/v3.0/user/${userId}/preferences/${preferenceName}?value=${value}`)
  }
}
