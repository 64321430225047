export default function (tel, extension) {
  const regex = /\D/g
  const extensionRegex = /[^0-9a-zA-Z]/g

  if (!tel || tel.length < 10) {
    return {}
  }

  if (!extension) {
    extension = null
  } else {
    extension = extension.replace(extensionRegex, '')
  }

  tel = tel.replace(regex, '')

  if (tel.length >= 11 && tel.indexOf('1') === 0) {
    tel = tel.substring(1)
  }

  return {
    areaCode: tel.substring(0, 3),
    prefix: tel.substring(3, 6),
    suffix: tel.substring(6),
    ext: extension
  }
}
