const focusRef = {
  methods: {
    refExists(element) {
      return !!(element && this.$refs[element])
    },
    childRefExists(element, childElement) {
      const hasAllParams = !!(this.refExists(element) && childElement)
      return !!(hasAllParams && this.$refs[element].$refs && this.$refs[element].$refs[childElement])
    },
    setFocus(element, childElement, timeout) {
      timeout = timeout || 300
      setTimeout(() => {
        if (this.refExists(element)) {
          if (this.childRefExists(element, childElement)) {
            this.$refs[element].$refs[childElement].focus()
          } else {
            this.$refs[element].focus()
          }
        }
      }, timeout)
    },
    setFocusonNext($event) {
      let elem = ''
      const pdlInputElements = ['text',
        'password',
        'select-one',
        'checkbox',
        'radio',
        'textarea',
        'email',
        'number',
        'search',
        'tel']
      let count = 0
      const inputElements = []
      while (count < $event.srcElement.form.length) {
        elem = $event.srcElement.form[count].type
        if (pdlInputElements.includes(elem)) {
          inputElements.push($event.srcElement.form[count])
        }
        count += 1
      }
      const indexOfCurrentElement = inputElements.indexOf($event.target)
      if (indexOfCurrentElement < inputElements.length) {
        inputElements[indexOfCurrentElement + 1].focus()
      }
    }
  }
}

export default focusRef
