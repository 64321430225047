import { GTM_GA4 } from 'utils/constants'

export const trackProductSearch = ({
  length = 0,
  term = '',
  response = 'success',
  type,
  misspelledTerm,
  recommendationAlgorithm,
  invocationId,
  modelId,
  reranked,
  rerankedInput,
  runId,
  site
} = {}) => {
  const vueInstance = window.sharedVue.config.globalProperties
  let responseText = 'fail'
  if (response === 'success' && misspelledTerm && length > 0) {
    responseText = `autocorrected (${term})`
  } else if (response === 'success' && length > 0) {
    responseText = response
  }
  const mlAlgo = recommendationAlgorithm && recommendationAlgorithm !== 'no cms value'
    ? recommendationAlgorithm : null
  vueInstance.$trackGA4Event(GTM_GA4.search, {
    type,
    response: responseText,
    search_term: term,
    content_type: 'product',
    num_results: length,
    ...(site ? { site_location: site } : {}),
    reranked,
    rerankedInput,
    ml_run_id: runId,
    ml_algo: mlAlgo,
    ml_invocation_id: invocationId || null,
    ml_model_id: modelId || null
  })
}
