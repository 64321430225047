<template>
  <a
    class="btn btn--primary inline-block"
    :href="modalUrl"
  >
    {{ text }}
  </a>
</template>
<script>
export default {
  props: {
    text: {
      type: String,
      required: true
    },
    yPosition: {
      type: Number,
      default: 0
    },
    productId: {
      type: Number,
      default: 0
    }
  },
  computed: {
    modalUrl() {
      return `/product/${this.productId}/?source=static-page`
    }
  },
  methods: {
    scroll() {
      window.scroll({ top: this.yPosition, left: 0, behavior: 'smooth' })
      if (window.scrollY !== this.yPosition) {
        return window.setTimeout(() => this.scroll(), 350)
      }
      return this.$emit('scrolled')
    }
  }
}
</script>
