import { GTM } from 'utils/constants'

export default (sessionId = '', viewId = '') => {
  const vueInstance = window.sharedVue.config.globalProperties
  if (sessionId) {
    vueInstance.$trackGtmEvent(GTM.dataDogSession, {
      sessionId,
      viewId
    })
  }
}
