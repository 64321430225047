<template v-slot="slotProps">
  <div
    v-if="isSelected"
    :id="`${url}-panel`"
    :aria-hidden="!isSelected"
    :aria-labelledby="`${url}-tab`"
    role="tabpanel"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'PdlControlledPanel',
  props: {
    name: { type: String, required: true },
    url: { type: String, required: true }
  },
  computed: {
    isSelected() {
      return this.$route.name === this.url
    }
  }
}
</script>
