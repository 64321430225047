<template>
  <div
    v-if="hasErrors || description || hasPersistentMessages"
    class="field_messages"
  >
    <div
      v-if="hasErrors"
      role="alert"
      class="field_message field_message--error"
    >
      <div v-if="errors[0]">
        <span class="text--base-strong text--base-error">{{ errors[0].value }}</span>
      </div>
    </div>
    <div
      v-if="description"
      class="field_message"
    >
      <p class="pdl-basic-text-input_field-description leader--single">
        <span>{{ description }}</span>
      </p>
    </div>
    <div
      v-if="hasPersistentMessages"
      class="field_message pdl-basic-text-input_field-description leader--single"
    >
      <p class="text--base-prime">
        Must Contain:
      </p>
      <ul>
        <li
          v-for="(message, index) in persistentMessages"
          :key="index"
          class="field_message field_message--persistent"
        >
          <PdlValidationMessage
            :is-dirty="message.dirty"
            :is-valid="!message.invalid"
          >
            {{ message.value }}
          </PdlValidationMessage>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import PdlValidationMessage from '@/shared/form/PdlValidationMessage'

export default {
  components: {
    PdlValidationMessage
  },
  props: {
    hasErrors: {
      type: Boolean,
      default: false
    },
    description: {
      type: String,
      default: ''
    },
    hasPersistentMessages: {
      type: Boolean,
      default: false
    },
    errors: {
      type: Array,
      default: () => ([])
    },
    persistentMessages: {
      type: Array,
      default: () => ([])
    }
  }
}
</script>
