<template>
  <div :class="{ 'container--full-width': fullWidth }">
    <div
      v-if="tabs.length >= 2"
      class="tabs-wrapper"
      :class="[
        {'tabs-wrapper--remove-side-padding': !fullWidth && !flex && removeSidePadding },
        {'tabs-wrapper--flex': !fullWidth && !removeSidePadding && flex},
        {'tabs-wrapper--centered' : isCentered && !showTooltip},
        {'tabs-wrapper--gray' : hasGrayBackground},
        {'hidden-on-mobile' : hideTabsOnMobile},
        wrapperClassOverride ? wrapperClassOverride : ''
      ]"
    >
      <div
        v-if="showTooltip"
        class="tablist w-96"
      />
      <ul
        class="tablist"
        :class="[
          { 'container--full-width': fullWidth && !flex },
          { 'tablist--flex': flex }
        ]"
        role="tablist"
      >
        <!-- eslint-disable-next-line vuejs-accessibility/interactive-supports-focus -->
        <li
          v-for="(tab, index) in sortedTabs"
          :id="tab.id"
          :key="index"
          :ref="tab.id"
          role="tab"
          :aria-selected="tab.isActive"
          :aria-controls="tab.panelId"
          :tabindex="tab.isActive || tabIndexStayZero ? 0 : -1"
          :class="[
            'tab',
            activeClass,
            {'tab--primary-disabled': !isUtility && loading},
            {'tab--utility-disabled': isUtility && loading},
            { 'tab--shrink': tabShrink },
            { 'is-active': tab.isActive },
            { 'container--full-width': fullWidth },
            { 'tab--flex': flex }
          ]"
          @click="selectTab(tab)"
          @keyup.left.right="handleArrowKeyUp(tab, $event)"
          @keyup.enter.space="selectTab(tab)"
        >
          {{ tab.name }}{{ tab.count ? ` (${tab.count})` : '' }}
        </li>
      </ul>
      <div
        v-if="showTooltip"
        class="tablist text-gray-800 mr-8 relative"
      >
        <PdlTabTooltip
          :full-width="fullWidth"
          :tool-tip="toolTipDetails"
          :show-tooltip-this-session="showTooltipThisSession"
          @show-help="onShowHelp"
          @clickaway="handleClickOutside"
        />
      </div>
      <slot name="sideButton" />
    </div>
    <div
      v-if="isMobile && showTooltip"
    >
      <PdlTabTooltip
        :tool-tip="toolTipDetails"
        :show-tooltip-this-session="showTooltipThisSession"
        @show-help="onShowHelp"
        @clickaway="handleClickOutside"
      />
    </div>
    <div class="tabs-details">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isMobile: {
      type: Boolean,
      default: false
    },
    isUtility: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    removeSidePadding: {
      type: Boolean,
      default: false
    },
    flex: {
      type: Boolean,
      default: false
    },
    isCentered: {
      type: Boolean,
      default: false
    },
    hideTabsOnMobile: {
      type: Boolean,
      default: false
    },
    hasGrayBackground: {
      type: Boolean,
      default: false
    },
    wrapperClassOverride: {
      type: String,
      default: ''
    },
    tabShrink: {
      type: Boolean,
      default: false
    },
    canDeselect: {
      type: Boolean,
      default: false
    },
    tabIndexStayZero: {
      type: Boolean,
      default: false
    },
    isPrimaryAlt: {
      type: Boolean,
      default: false
    },
    isManualActivation: {
      type: Boolean,
      default: false
    },
    toolTip: {
      type: Array,
      default: () => [{ }]
    },
    showTooltipThisSession: {
      type: Boolean,
      default: false
    },
    tabs: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    activeClass() {
      if (this.isUtility) return 'tab--utility'
      if (this.isPrimaryAlt) return 'tab--primary-alt'
      return 'tab--primary'
    },
    sortedTabs() {
      const tablist = this.tabs.filter(child => !!child.id)
      return _.sortBy(tablist, 'position')
    },
    showTooltip() {
      return this.toolTipDetails?.tabid
        && this.activeTab[0] && this.activeTab[0].id === this.toolTipDetails.tabid
    },
    toolTipDetails() {
      let toolTipTab = {}
      if (this.activeTab?.[0]?.id) {
        toolTipTab = this.toolTip.filter(tab => tab.tabid === this.activeTab[0].id)
        return toolTipTab[0]
      }
      return toolTipTab
    },
    activeTab() {
      return this.tabs.filter(child => child.isActive === true)
    }
  },
  methods: {
    selectTab(selectedTab) {
      if (this.loading) return
      this.tabs.forEach((tab) => {
        const lastActiveState = !!tab.isActive
        if (this.canDeselect && tab.name === selectedTab.name && tab.isActive) {
          tab.isActive = false
        } else {
          tab.isActive = tab.name === selectedTab.name
        }
        // only emit if there was a change
        if (tab.name === selectedTab.name && lastActiveState !== tab.isActive) {
          this.emitTabChange(tab)
        }
      })
    },
    handleArrowKeyUp(selectedTab, $event) {
      if (this.loading) return
      const activeIndex = this.tabs.indexOf(selectedTab)
      let newIndex
      if ($event.key === 'ArrowRight') {
        newIndex = activeIndex + 1 < this.tabs.length ? activeIndex + 1 : 0
      }
      if ($event.key === 'ArrowLeft') {
        newIndex = activeIndex > 0 ? activeIndex - 1 : this.tabs.length - 1
      }
      if (!this.isManualActivation) {
        this.tabs.forEach((tab, i) => {
          tab.isActive = i === newIndex
          if (i === newIndex) {
            this.emitTabChange(tab)
          }
        })
      }
      this.shiftFocus(newIndex)
    },
    emitTabChange(tab) {
      this.$emit('tab-change', tab)
    },
    shiftFocus(newIndex) {
      if (this.$refs[this.tabs[newIndex].id]) {
        this.$refs[this.tabs[newIndex].id][0].focus()
      }
    },
    onShowHelp() {
      this.$emit('show-help')
    },
    handleClickOutside() {
      this.$emit('clickaway')
    },
  }
}
</script>
