export default {
  state: {
    isAppLoaded: false,
    isAppLoading: false,
    appLoadPromise: null
  },
  mutations: {
    setIsAppLoaded(state, payload) {
      if (payload === true) {
        state.appLoadPromise = null
      }
      state.isAppLoaded = !!payload
    },
    setIsAppLoading(state, payload) {
      state.isAppLoading = !!payload
    },
    setAppLoadPromise(state, payload) {
      state.appLoadPromise = payload
    },
  }
}
