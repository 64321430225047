const camelCase = (name = '') => {
  let response = ''
  if (name) {
    response = name.split('_').map((word, key) => (
      (key !== 0) ? (
        [...word].map(
          (char, index) => (index ? char : char.toUpperCase())
        ).join('')
      ) : word.toLowerCase()
    )).join('')
  }
  return response
}

export default {
  state: {
    clientId: '',
    redirectUri: '', // from query params
    successRedirectUri: '', // from GRANT API SUCCESS
    responseType: '',
    state: '',
    userStatusInitCode: '',
    externalCompany: '',
    companyNameError: '',
    secureEnabled: '',
    missingDeliveryStore: false,
    oauthGrantError: false
  },
  getters: {
    companyNameError: state => (!!state.companyNameError),
    oauthGrantError: state => (!!state.oauthGrantError),
    hasSiteErrors: state => (!!state.oauthGrantError || !!state.companyNameError),
    isOauthAllowed: state => (state.userStatusInitCode === 3),
    storedQuery: state => ({
      client_id: state.clientId,
      redirect_uri: state.redirectUri,
      state: state.state,
      external_company: state.externalCompany,
      response_type: state.responseType
    }),
  },
  mutations: {
    initFromQuery(state, payload) {
      _.each(payload, (value, key) => {
        key = camelCase(key)
        if (_.has(state, key) && !_.isNull(value)) {
          if (key === 'redirectUri') {
            value = decodeURIComponent(value)
          }
          state[key] = value
        }
      })
    },
    setSuccessRedirectUri(state, payload) {
      state.successRedirectUri = (payload) ? `${payload}` : ''
    },
    setUserStatusCode(state, payload) {
      state.userStatusInitCode = payload
    },
    initFromAppConfig(state, payload) {
      _.each(payload, (value, key) => {
        if (_.has(state, key) && !_.isNull(value)) {
          state[key] = value
        }
      })
    },
    setMissingDeliveryStore(state, payload) {
      state.missingDeliveryStore = !!payload
    },
    setOauthGrantError(state, payload) {
      state.oauthGrantError = !!payload
    }
  }
}
