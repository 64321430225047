import { ORDER_HISTORY_DEFAULT_TAB } from 'components/order-history/utils/order-history-constants'
import modalRoutes from './modal-routes'
import contactRoutes from './contact-routes'
import helpRoutes from './help-routes'

const PdlShoppingListPage = () => import('./pages/shopping-list/PdlShoppingListPage')
const PdlSavingsPageContainer = () => import('./pages/PdlSavingsPageContainer')
const PdlBrowseAislesPage = () => import('./pages/PdlBrowseAislesPage')
const PdlStoreLocatorPage = () => import('./pages/store-locator/PdlStoreLocatorPage')
const PdlProductRecallsPage = () => import('./pages/product-recall/PdlProductRecallsPage')
const PdlOmniSearchPage = () => import('./pages/omni-search/PdlOmniSearchPage')
const PdlRecommendedForYouPage = () => import('./pages/recommended-for-you/PdlRecommendedForYouPage')
const PdlPastPurchasePage = () => import('./pages/past-purchases/PdlPastPurchasePage')
const PdlProductGroup = () => import('./pages/product-group-eci/PdlProductGroup')
const PdlSplashPage = () => import('./pages/PdlSplashPage')
const HomePage = () => import(/* webpackChunkName: 'home' */ './pages/HomePage')
const RegistrationPage = () => import('./pages/RegistrationPage')
const AccountPage = () => import('./pages/AccountPage')
const LoyaltyRewardsPage = () => import('./pages/rewards/LoyaltyRewardsPage')
const PdlRewardsOverviewPage = () => import('./pages/rewards/PdlRewardsOverviewPage')
const TEMPToggleRewardsUpdate = () => import('./pages/rewards/TEMPToggleRewardsUpdate')
const CartPage = () => import('./pages/cart/CartPage')
const OrderConfirmationPage = () => import('./pages/checkout/OrderConfirmationPage')
const OrderSummaryPage = () => import('./pages/checkout/OrderSummaryPage')
const OrderCanceledPage = () => import('./pages/checkout/OrderCanceledPage')
const OrderUpdatedPage = () => import('./pages/checkout/OrderUpdatedPage')
const PharmacyPage = () => import('./pages/iframes/PharmacyPage')
const PharmacySurvey = () => import('./pages/iframes/PharmacySurvey')
const CateringPage = () => import('./pages/iframes/CateringPage')
const ProductDetailsPage = () => import('./pages/product-details-page/ProductDetailsPage')
const PrivacyCenterPage = () => import('./pages/privacy/PrivacyCenterPage')
const PrivacyStatementPage = () => import('./pages/privacy/PrivacyStatementPage')
const ResetPasswordForm = () => import('components/auth/PdlForgotPassword')
const PdlWalletLandingPage = () => import('./pages/wallet/PdlWalletLandingPage')
const WalletPaymentMethodLandingPage = () => import('./pages/wallet/PaymentMethodLandingPage')
const WalletPolling = () => import('components/wallet/PdlWalletPolling')

let productRecalls = []
if (window.appConfig.opcoId !== 'FDLN') {
  productRecalls = [
    {
      name: 'recall',
      path: '/recall',
      component: PdlProductRecallsPage,
      meta: {
        pageTitle: 'Product Recalls'
      }
    }
  ]
}

const accountHistoryTabsRedirect = (window.appConfig?.featureFlags?.featureInstoreOrderHistory)
  ? [
    {
      path: '/account/history/invoice',
      redirect: `/account/history/invoice/${ORDER_HISTORY_DEFAULT_TAB}`
    }
  ] : []

export default [
  ...contactRoutes,
  ...helpRoutes,
  {
    path: '/modal/my-account:pathMatch(.*)*',
    redirect: '/account'
  },
  {
    path: '/modal/user-account-modal:pathMatch(.*)*',
    redirect: '/account'
  },
  {
    path: '/modal/address/delivery',
    redirect: '/account/user/delivery-address'
  },
  {
    path: '/account/user/password',
    redirect: '/reset-password'
  },
  ...accountHistoryTabsRedirect,
  {
    name: 'account',
    path: '/account:pathMatch(.*)*',
    component: AccountPage,
    meta: {
      pageTitle: 'My Account'
    }
  },
  {
    name: 'home',
    path: '/home',
    alias: ['/'],
    component: HomePage,
    meta: {
      pageTitle: '',
      pageType: 'home'
    }
  },
  {
    path: '/user-registration-modal',
    redirect: '/registration'
  },
  {
    path: '/modal/registration',
    redirect: '/registration'
  },
  {
    path: '/modal/create-account',
    redirect: '/registration'
  },
  {
    path: '/create-account',
    redirect: '/registration'
  },
  {
    name: 'registration',
    path: '/registration',
    component: RegistrationPage,
    meta: {
      pageTitle: 'Create Account',
    },
    beforeEnter: (to, from, next) => {
      const { $store } = window
      const { nextRoute, finishMode } = to?.query || {}
      const nextRouteString = (typeof nextRoute === 'string') ? `${nextRoute}` : ''
      const prefix = nextRouteString ? '&' : ''
      const finishModeString = finishMode !== undefined ? `${prefix}finishMode=${finishMode}` : ''
      $store.commit('Registration/setRedirectUrl',
        `${nextRouteString}${finishModeString}`)
      next()
    }
  },
  {
    name: 'product-search',
    path: '/product-search/:keywords?',
    component: PdlOmniSearchPage,
    meta: {
      pageTitle: 'Search Results',
      pageType: 'search'
    }
  },
  {
    name: 'article-search',
    path: '/article-search/:keywords?',
    component: PdlOmniSearchPage,
    meta: {
      pageTitle: 'Search Results'
    }
  },
  {
    name: 'savings-search',
    path: '/savings-search/:keywords?',
    component: PdlOmniSearchPage,
    meta: {
      pageTitle: 'Search Results',
      pageType: 'savings'
    }
  },
  {
    name: 'recommended-for-you',
    path: '/recommended-for-you',
    component: PdlRecommendedForYouPage,
    meta: {
      pageTitle: 'Recommended For You'
    }
  },
  {
    name: 'did-you-forget',
    path: '/did-you-forget',
    component: CartPage,
    meta: {
      pageTitle: 'Did You Forget?'
    },
    props: route => ({
      isDidYouForgetPage: true,
      ...route.params
    })
  },
  {
    name: 'personal-list',
    path: '/personal-list',
    component: PdlShoppingListPage,
    meta: {
      pageTitle: 'Shopping List'
    }
  },
  {
    name: 'past-purchases',
    path: '/past-purchases/:months?',
    component: PdlPastPurchasePage,
    meta: {
      pageTitle: 'Past Purchases'
    }
  },
  {
    name: 'chat',
    path: '/chat',
    beforeEnter(to, from, next) {
      const { $store } = window
      $store.commit('Chatbot/setIsOpen', true)
      return next('/home')
    }
  },
  {
    name: 'coupon-category',
    path: '/product-eci/coupon/:coupon/category/:category',
    component: PdlProductGroup
  },
  {
    name: 'coupon',
    path: '/product-eci/coupon/:coupon',
    component: PdlProductGroup
  },
  {
    name: 'product-group',
    path: '/product-groups/:id',
    component: PdlProductGroup,
    props: { isProductGroup: true }
  },
  {
    name: 'savings',
    path: '/savings/:tab?/:secondaryTab?/:id?',
    component: PdlSavingsPageContainer,
    meta: {
      pageTitle: 'Savings'
    }
  },
  {
    name: 'browse-aisle',
    path: '/browse-aisles/:categories*',
    component: PdlBrowseAislesPage,
    meta: {
      pageTitle: 'Browse Aisles',
    }
  },
  {
    name: 'store-locator',
    path: '/store-locator',
    component: PdlStoreLocatorPage,
    meta: {
      pageTitle: 'Store Locator'
    }
  },
  ...productRecalls,
  {
    name: 'pages-splash',
    path: '/pages/:splashPageUrlKey',
    component: PdlSplashPage,
    meta: {
      isSplashPage: true
    }
  },
  {
    name: 'vipea',
    path: '/vipea',
    component: PdlSplashPage,
    meta: {
      isSplashPage: true
    }
  },
  {
    name: 'rewards-update',
    path: '/rewards-update/:subPage?',
    component: LoyaltyRewardsPage,
    meta: {
      isRewardsPage: true,
      keepAliveGroup: 'rewards',
      pageTitle: 'Rewards'
    },
    props: true
  },
  {
    name: 'rewards',
    path: '/rewards/:firstItem?/:secondItem?/:thirdItem?',
    component: TEMPToggleRewardsUpdate,
    meta: {
      isRewardsPage: true,
      keepAliveGroup: 'rewards'
    },
    props: true
  },
  {
    name: 'rewards-overview',
    path: '/rewards-overview',
    component: PdlRewardsOverviewPage,
    meta: {
      pageTitle: 'Rewards Overview'
    }
  },
  {
    name: 'wallet',
    path: '/wallet',
    component: PdlWalletLandingPage,
    meta: {
      pageTitle: 'Wallet'
    },
    beforeEnter(to, from, next) {
      const store = window?.sharedVue?.config?.globalProperties?.$store || {}
      const isAllowed = store?.getters['SiteConfig/varByName'](
        'feature_digital_wallet'
      )
      if (isAllowed) {
        return next()
      }
      return next('/')
    }
  },
  {
    name: 'cart',
    path: '/cart',
    component: CartPage,
    meta: {
      pageTitle: 'Cart'
    },
    beforeEnter(to) {
      if (!to.query?.items) return
      window?.$store.commit('Cart/setPartnerQueryParams', to.query)
    }
  },
  {
    name: 'order-summary',
    path: '/order-summary',
    component: OrderSummaryPage,
    meta: {
      pageTitle: 'Order Summary'
    },
    beforeEnter(to, from, next) {
      const store = window?.sharedVue?.config?.globalProperties?.$store || {}
      const isAllowed = store?.getters['CartPaymentInformation/canViewOrderSummaryPage']
      if (isAllowed) {
        return next()
      }

      return next('/cart')
    }
  },
  {
    name: 'order-confirmation',
    path: '/order-confirmation',
    component: OrderConfirmationPage,
    meta: {
      pageTitle: 'Order Confirmation'
    },
    beforeEnter(to, from, next) {
      const store = window?.sharedVue?.config?.globalProperties?.$store || {}
      const isAllowed = store?.getters['CartPaymentInformation/canViewOrderConfirmationPage']
      if (isAllowed) {
        return next()
      }

      if (!isAllowed && from.path !== '/') {
        return next(false)
      }

      return next('/home')
    }
  },
  {
    name: 'order-canceled',
    path: '/order-canceled',
    component: OrderCanceledPage,
    meta: {
      pageTitle: 'Order Canceled',
    },
    beforeEnter(to, from, next) {
      const store = window?.sharedVue?.config?.globalProperties?.$store || {}
      if (Object.keys(store?.getters['Order/previousOrderStatusDetails']).length) {
        return next()
      }
      return next('/home')
    }
  },
  {
    name: '/order-updated',
    path: '/order-updated',
    component: OrderUpdatedPage,
    meta: {
      pageTitle: 'Order Updated',
    }
  },
  {
    path: '/:catchAll(.*)',
    component: HomePage,
    meta: {
      pageTitle: ''
    }
  },
  {
    name: 'wallet-polling',
    path: '/wallet/polling',
    component: WalletPolling,
    meta: {
      pageTitle: 'Wallet Polling'
    },
  },
  {
    name: 'pharmacy',
    path: '/pharmacy',
    component: PharmacyPage,
    meta: {
      pageTitle: 'Pharmacy'
    }
  },
  {
    name: 'patient-survey',
    path: '/PatientSurvey',
    component: PharmacySurvey
  },
  {
    path: '/gift-cards:pathMatch(.*)*',
    redirect: '/pages/gift-cards'
  },
  {
    name: 'catering',
    path: '/catering',
    component: CateringPage,
    meta: {
      pageTitle: 'Catering'
    }
  },
  {
    name: 'fuel-rewards',
    // eslint-disable-next-line
    path: '/fuel-rewards/link-account/accountNumber/:accountNumber/securityCode/:securityCode/actionType/:actionType/partUserId/:userId',
    beforeEnter: (to, from, next) => {
      setTimeout(() => {
        window.sharedVue.config.globalProperties.$store.commit('LoyaltyAccount/setFuelRewardsLinkData', to.params)
      })
      next('/rewards')
    }
  },
  {
    name: 'product-details',
    path: '/product/:productName?/:productId',
    component: ProductDetailsPage,
    meta: {
      pageTitle: 'Item Detail'
    }
  },
  {
    name: 'privacy-center',
    path: '/privacy-center',
    component: PrivacyCenterPage,
    meta: {
      pageTitle: 'Privacy Center'
    }
  },
  {
    name: 'privacy-statement',
    path: '/privacy-statement',
    component: PrivacyStatementPage,
    meta: {
      pageTitle: 'Privacy Statement'
    }
  },
  {
    name: 'password-request',
    path: '/password-request',
    component: ResetPasswordForm,
    props: {
      showHeading: true,
      backUsingHistory: true
    },
    beforeEnter: (to, from, next) => {
      window.$store.commit('TitleBar/setPageTitle', 'Reset Password')
      next()
    }
  },
  {
    name: 'wallet-payment-method',
    path: '/wallet/payment-method',
    component: WalletPaymentMethodLandingPage,
    beforeEnter: (to, from, next) => {
      window.$store.commit('TitleBar/setPageTitle', 'Payment Method')
      next()
    }
  },
  ...modalRoutes
]
