import { required } from '@vuelidate/validators'

export default {
  required,
  format(zip) {
    return (
      /^(?!(11111|22222|33333|55555|66666|77777|99999|00000))\d{5}(?:[-\s]\d{4})?$/.test(zip)
    )
  }
}
