const adapterMap = (adap, params) => {
  if (adap) {
    /* Put exceptions for openNamedView here */
    let payload
    if (params[0] === 'user-registration-modal') {
      payload = params.slice(1)[0] || {}
      adap.launchRegistration(payload)
    } else {
      adap.openNamedView(...params)
    }
  }
}

const openModal = _.debounce(({ commit }, modalData) => {
  if (!Array.isArray(modalData)) {
    modalData = [modalData]
  }
  commit('setModal', modalData)
}, 500, true)

/* Angular adapter, should be removed once we transition to Vue */
let adapter = null

export default {
  namespaced: true,
  state: {
    activeModal: [],
    activeComponent: [],
    modalId: '',
    idToFocusOnOpen: ''
  },
  getters: {
    modals: state => state.activeComponent,
    modalId: state => state.modalId,
    idToFocusOnOpen: state => state.idToFocusOnOpen,

    currentModal: state => state.activeModal[0],
    hasPreviousModal: state => state.activeComponent.length > 1,
    activeComponent: state => state.activeComponent[state.activeComponent.length - 1],
    previousModal: (state) => {
      if (state.activeComponent.length > 1) {
        return state.activeComponent[state.activeComponent.length - 2]
      }
      return {}
    },
  },
  mutations: {
    setActiveModal(state, modal) {
      state.idToFocusOnOpen = ''
      state.activeComponent.push(modal)
    },
    clearActiveModal(state, config = {}) {
      state.idToFocusOnOpen = config?.idToFocusOnOpen || ''
      state.activeComponent.pop()
    },
    /* The below methods are the older, outdated way of loading modals. */
    /* For new modal please use the above methods inside PdlModalView in the main repo */
    /* Pass in modal name or array of parameters for openNamedView */
    setModal(state, modalData) {
      state.activeModal.unshift(modalData)
      adapterMap(adapter, modalData)
    },
    closeModal(state) {
      state.idToFocusOnOpen = ''
      state.activeModal.shift()
      adapterMap(
        adapter,
        state.activeModal.length ? state.activeModal[0] : ['']
      )
    },
    clear(state) {
      state.idToFocusOnOpen = ''
      state.activeModal = []
      state.activeComponent = []
      adapterMap(adapter, [''])
    },
    reset(state) {
      state.idToFocusOnOpen = ''
      state.activeModal = []
    },
    setModalId(state, payload) {
      state.modalId = payload
    },
    setIdToFocusOnOpen(state, id = '') {
      state.idToFocusOnOpen = id
    },
    updateCurrentModalConfig(state, updates = {}) {
      const index = state.activeComponent.length - 1
      state.activeComponent[index] = {
        ...state.activeComponent[index],
        ...updates
      }
    }
  },
  actions: {
    openModal,
    closeModal({ commit }) {
      commit('closeModal')
    },
    clear({ commit }) {
      commit('clear')
    },
    reset({ commit }) {
      commit('reset')
    },
    setAdapter(opts, ng) {
      adapter = ng
    },
    launchRegistration({ commit }, {
      nextRoute = null,
      finishMode = false
    } = {}) {
      const nextRouteString = _.isString(nextRoute) ? `${nextRoute}` : ''
      const prefix = nextRouteString ? '&' : ''
      const finishModeString = `${prefix}finishMode=${finishMode}`
      commit('Registration/setRedirectUrl', `${nextRouteString}${finishModeString}`, { root: true })
      window.sharedVue.config.globalProperties.$router.push('/registration')
    },
    cleanUpData({
      dispatch, rootGetters
    }, modalName) {
      // this is used to clean/reset any data when outside area of modal is clicked which clears the modal.
      // this is triggered from clearModals method in PdlModals.vue
      if (modalName === 'PdlOrderAddItemsModal'
        || modalName === 'PdlPastPurchaseModal') {
        dispatch('AddItemModal/cleanData', {}, { root: true })
      }
      if (modalName === 'PdlPreCutoffDetailsModal') {
        const workingOrderBasketId = rootGetters['Cart/getWorkingOrderBasketId']
        dispatch('Cart/setPrimaryCart', { basketId: workingOrderBasketId }, { root: true })
      }
    },
    setModalId({ commit }, payload) {
      commit('setModalId', payload)
    },
    setIdToFocusOnOpen({ commit }, id) {
      commit('setIdToFocusOnOpen', id)
    }
  }
}
