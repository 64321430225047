/**
 * Overview:
 *
 * storeNumbers supporting locker pickup for CNC will be inserted at runtime
 * for the entire OPCO.  The specific store a user is shopping in can change
 * over the course of the session so anytime service location is changed we
 * will need to run a check here to determine if the current store is supporting
 * lockers and then update value for: lockersEnabledInStore
 */
import CommunicationsAPI from 'api/CommunicationsAPI'
import ApiService from '@/api/'
import {
  MY_ACCOUNT_COMMUNICATIONS_ALERT_METHODS as ALERT_METHODS, LOCKER_DESCRIPTORS,
  LOCKER_PICKUP_PAGE_URL, SELF_SERVICE_PICKUP_PAGE_URL, GTM
} from 'utils/constants'

const {
  BOTH, EMAIL, SMS
} = ALERT_METHODS

const initialState = {
  storeNumbers: [],
  podStoreNumbers: [],
  lockersEnabledInStore: false, // < user shopping pickup in locker/locker-pod store
  isPodLocker: false,
  lowLockersRequested: false,
  // contact info is required to place order (only SMS for now but email will follow)
  contactNumber: {
    validNumberOnAccount: false,
    failedUpdate: false,
    missingRequired: false
  }
}

const getters = {
  lockerDescriptor: state => (state.isPodLocker ? LOCKER_DESCRIPTORS.pod : LOCKER_DESCRIPTORS.default),
  pickUpPageUrl: state => (state.isPodLocker ? SELF_SERVICE_PICKUP_PAGE_URL : LOCKER_PICKUP_PAGE_URL),
  // NOTE: this will soon encompass email contact as well
  validLockerContact: state => state.contactNumber.validNumberOnAccount,
  failedContactNumberUpdate: state => state.contactNumber.failedUpdate,
  missingRequiredContactNumber: state => state.contactNumber.missingRequired
}

const mutations = {
  setStoreNumbers(state, payload) {
    if (Array.isArray(payload)) {
      state.storeNumbers = payload
    }
  },
  setPodStoreNumbers(state, payload) {
    if (Array.isArray(payload)) {
      state.podStoreNumbers = payload
    }
  },
  setLockersEnabledInStore(state, payload) {
    state.lockersEnabledInStore = payload
  },
  setIsPodLocker(state, payload) {
    state.isPodLocker = payload
  },
  setLowLockersRequested(state, payload) {
    state.lowLockersRequested = payload
  },
  setLockerContactNumberValidity(state, payload) {
    state.contactNumber.validNumberOnAccount = payload
  },
  setLockerContactNumberFailedUpdate(state, payload) {
    state.contactNumber.failedUpdate = payload
  },
  setLockerContactNumberMissingRequirement(state, payload) {
    state.contactNumber.missingRequired = payload
  }
}

const actions = {
  setCurrentSupport({ state, commit, rootGetters }) {
    const deliveryServiceLocation = rootGetters['UserProfile/deliveryServiceLocation']

    const { serviceType, storeNumber } = deliveryServiceLocation

    if (!deliveryServiceLocation.storeNumber) {
      commit('setLockersEnabledInStore', false)
      return
    }


    if (serviceType !== 'P') {
      commit('setLockersEnabledInStore', false)
      return
    }

    const combinedLockerStores = [...state.storeNumbers, ...state.podStoreNumbers]
    const lockersLiveForOpco = rootGetters['SiteConfig/varByName']('feature_cnc_lockers_live')
    const currentStoreSupportsLockers = combinedLockerStores.includes(parseInt(storeNumber, 10))
    commit('setLockersEnabledInStore', currentStoreSupportsLockers && lockersLiveForOpco)
    commit('setIsPodLocker', state.podStoreNumbers.includes(parseInt(storeNumber, 10)))
  },
  async updateCommunicationsPhoneNumber({ commit, rootState, rootGetters }, phoneNum) {
    try {
      const phone = { ext: null, ...phoneNum }
      // unfortunately this API requires all params present despite the fact we only want to change
      // phone number. Need to pull over existing values from Communications store and pass in phone
      const { alertMethod } = rootState.Communications

      const data = {
        phone,
        alertMethod: alertMethod === EMAIL
          ? BOTH
          : SMS,
        availableSubscriptions: rootState.Communications.availableSubscriptions,
        mailSubIds: rootState.Communications.mailSubIds
      }

      const userId = rootGetters['UserProfile/userId']

      const { status } = await CommunicationsAPI.updateCommunications(data, userId)
      if (status >= 200 || status < 300) {
        commit('setLockerContactNumberValidity', true)
        commit('Communications/setStoreData', data, { root: true })
        window.sharedVue.config.globalProperties.$trackGtmEvent(GTM.lockerSmsNumberModified)
      } else {
        commit('setLockerContactNumberValidity', false)
        commit('setLockerContactNumberFailedUpdate', true)
      }
    } catch (e) {
      commit('setLockerContactNumberValidity', false)
      commit('setLockerContactNumberFailedUpdate', true)
    }
  },
  async getLockerAccessibilityStatus({ commit }, payload) {
    try {
      const { userId, listId } = payload
      const result = await ApiService.get(`/apis/order/${userId}/attribute/v1/${listId}/accessibleLocker`)
      const { status, data } = result

      if (status !== 200) {
        throw new Error('api call failed')
      }

      const lowerLockersRequested = data.value === 'true'
      commit('setLowLockersRequested', lowerLockersRequested)
    } catch (e) {
      commit('setLowLockersRequested', false)
    }
  },
  async toggleAccessbileLockersSetting({ state, commit }, payload) {
    try {
      const { userId, listId } = payload
      const adaLockerNeeded = !state.lowLockersRequested
      const headers = {
        Accept: 'application/json',
        'Content-type': 'application/json'
      }

      const result = await ApiService.post(
        `/apis/order/${userId}/attribute/v1/${listId}/accessibleLocker`,
        `"${adaLockerNeeded}"`,
        { headers }
      )

      if (result.status !== 200) {
        throw new Error('received invalid response for accessible locker setting.')
      }

      window.sharedVue.config.globalProperties.$trackGtmEvent(GTM.lockerAdaOptionModified, { value: adaLockerNeeded })
      commit('setLowLockersRequested', adaLockerNeeded)
    } catch (e) {
      commit('Alert/setAlert', {
        icon: '',
        header: 'Could Not Save ADA Preference',
        body: 'We had some trouble saving your preference for a lower locker. Please try checking out again.',
        type: 'warning',
        primary: {
          text: 'Continue'
        }
      }, { root: true })
    }
  },
  async insertStoreNumberSupport({ commit, dispatch }, payload) {
    if (payload.pod) {
      commit('setPodStoreNumbers', payload.stores)
    } else {
      commit('setStoreNumbers', payload.stores)
    }

    dispatch('setCurrentSupport')
  }
}

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
}
