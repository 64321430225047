import timeSlotTrim15 from 'utils/filters/timeSlotTrim15'
import { formatDate, formatTime } from 'utils/filters/formatDateTimeWithLuxon'

const createOrderAlert = () => {
  const $store = window?.sharedVue?.config?.globalProperties?.$store
  const responseData = $store.getters['Order/orderStatus'] || {}

  const data = {
    ...responseData,
    lockersEnabledInStore: $store.state.PickupLockers.lockersEnabledInStore
  }
  // Only resolve 1 order related alert if applicable, current scenarios include:
  //  1) submitted order approaching cutoff, 2) working order approaching cutoff, and 3) submitted order alert
  const { WORKING_CUTOFF } = responseData?.alerts || {}
  if (!$store.state.CutOff.cutoffAlertFlag && !!WORKING_CUTOFF) {
    $store.dispatch('CutOff/createCutoffAlert', responseData)
  } else if ($store.getters['Order/currentOrderSubmitted']) {
    if (!$store.getters['Order/allowSMSPickup']) {
      $store.dispatch('Order/allowSMSPickup', data)
        .then(() => {
          const response = generateOrderAlert(data, $store.getters['Order/allowSMSPickup'])
          $store.commit('Alert/setAlert', response)
        })
    } else {
      const response = generateOrderAlert(data, $store.getters['Order/allowSMSPickup'])
      $store.commit('Alert/setAlert', response)
    }
  }
  return null
}

const generateOrderAlert = (data, showAlert) => {
  const alert = {
    header: `${(data.currentOrder.serviceType === 'D' ? 'Delivery ' : 'Pickup ')} Status`,
    type: 'warning',
    processAsHTML: true,
    primary: {
      text: 'Continue Shopping',
      callback: () => {
        window.sharedVue.config.globalProperties.$store.commit('Alert/clear')
      }
    }
  }

  if (showAlert) {
    alert.primary = {
      text: 'Update Order',
      callback: () => {
        const { $router, $store } = window?.sharedVue?.config?.globalProperties ?? {}
        $store.commit('Modals/clearActiveModal')
        $store.commit('Alert/clear')
        $router.push('/cart')
      }
    }
    alert.secondary = {
      text: 'Continue Shopping',
      callback: () => {
        window.sharedVue.config.globalProperties.$store.commit('Alert/clear')
      }
    }
  }

  let endTimestamp = data.currentOrder.timeEnd

  if (data.lockersEnabledInStore) {
    endTimestamp = timeSlotTrim15(endTimestamp)
  }

  const ordinalDate = formatDate(data.currentOrder.timeStart, { weekday: 'short' })
  const ordinalCutoffDate = formatDate(data.currentOrder.cutoffTime, { weekday: 'short' })
  const ordinalCutoffTime = formatTime(data.currentOrder.cutoffTime)
  const startTime = formatTime(data.currentOrder.timeStart)
  const endTime = formatTime(endTimestamp)

  /* eslint-disable */
  /* fix eslint-disable and use template string */
  /* CHANGE THIS TO VUE TEMPLATE TS-6644 */
  alert.body = 'You have a ' +
      ( data.currentOrder.serviceType === 'D' ? 'delivery ' : 'pickup ' ) +
      'scheduled on <br> <span>' +
      ordinalDate + ', ' + startTime + ' - ' + endTime + '.' +
      '</span>' +
      '</div><br>' +
      '<div>' +
      'You can update your order until ' + ordinalCutoffDate + ', ' + ordinalCutoffTime + '.'
  /* eslint-enable */

  if (window.sharedVue.config.globalProperties.$store.state.CutOff.cutoffAlertFlag) {
    return null
  }

  return alert
}

export {
  createOrderAlert
}
