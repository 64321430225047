import { ALERT_METHODS } from 'utils/constants'

const {
  BOTH, EMAIL, NEITHER, SMS
} = ALERT_METHODS

export const whichAlertMethod = (email, sms) => {
  const conditions = [
    {
      test: (!!email && !!sms),
      res: BOTH
    },
    {
      test: (!!email),
      res: EMAIL
    },
    {
      test: (!!sms),
      res: SMS
    }
  ]
  return conditions.find(obj => (!!obj.test))?.res || NEITHER
}
