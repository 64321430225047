/**
 * Scroll Item to Center on click in list
 * @module scrollElementToCenter
 */

export default (container, item) => {
  const containerWidth = container?.offsetWidth
  const containerLeft = container?.offsetLeft
  const activeItemOffset = item?.offsetLeft
  const activeItemWidth = item?.offsetWidth
  const containerCenter = containerWidth / 2
  if (activeItemOffset && activeItemWidth) {
    container.scrollLeft += activeItemOffset - containerLeft - containerCenter + (activeItemWidth / 2)
  }
}
