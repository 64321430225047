/*
* Replace curly quote marks with universal straight quote marks
* @param value - String
* @response value - String
*/

export default function (value = '') {
  value = (typeof value === 'string') ? value
    .replace(/[\u2018\u2019]/g, `'`)
    .replace(/[\u201C\u201D]/g, `"`) : value
  return value
}
