import { v4 as uuidv4 } from 'uuid'
import { reserveSlots, fetchSlots } from 'api/slotsApi'
import {
  RESTRICTED_PRODUCT_TYPES,
  ORDER_SERVICE_TYPES,
  CUTOFF_MAX_TIME,
  ORDER_PROVIDER_IDS,
  USER_PREFERENCE_KEYS
} from 'utils/constants'
import { trackCartBulkChange } from 'utils/tracking/product-gtm-tagging'
import ebtFeatureEnabled from 'utils/services/ebt/ebtFeatureEnabled'
import addressSyncService from 'utils/services/AddressSync'
import { trackSlotReservationFailure } from 'utils/tracking/slots/trackSlotReservationFailure'
import { trackSlotSelection } from 'utils/tracking/slots/trackSlotSelection'
import { trackInstacartSlotError } from 'utils/tracking/trackInstacartError'
import UserPreferencesAPI from 'api/UserPreferencesAPI'

function initialState() {
  return {
    selectedSlotId: 0,
    slotChangeMade: false,
    viewDate: '',
    headers: [],
    slots: [],
    attendedSlots: [],
    unAttendedSlots: [],
    restrictedSlots: [],
    activeHeader: {},
    serviceType: '',
    tempServiceLocationId: null,
    delivAvail: true,
    pupAvail: true,
    intermediatePup: '',
    intermediatePupDetails: {},
    intermediateServiceType: '',
    selectedSlot: {},
    promotionalSlot: {},
    slotsLoading: false,
    slotsAvailable: true,
    unAttendedSlotsAvailable: true,
    sameDayDelivAvailable: false,
    isAttendedSlots: true,
    restrictedSlotInfo: {
      restrictedSlotType: [],
      restrictedSlotProductId: [],
      restrictedSlotProductName: []
    },
    isRestricted: false,
    refreshSlots: true,
    shouldContinueCheckout: false,
    hasChosenPackagingMethod: false,
    deliveryFee: 0,
    packagingMethod: '',
    hasPodBag: false,
    singlePackagingMethodMarket: false,
    podBagInfo: {},
    hasForceRemovedItems: false,
    slotForPackagingModal: {},
    hasStoredPickupLocation: false,
    slotTimeLoading: false,
    restrictedItemsRemoved: false,
    tomorrowAvailable: false,
    isFirstSelectedSlot: false,
    isContactFreeAndRestricted: false,
    slotsCount: {},
    isInitialized: false,
    hasNoSlots: false,
    initializeSlotsModal: false,
    enableRadioButtonsForAttendedSlots: false,
    warningMessageDismissed: false,
    fromOrderStatus: false,
    failedReservationId: null,
    hideExternalProviders: false,
    daySelection: '',
    reservedSlot: {}
  }
}
/**
 * @function isolateRestrictedSlots
 *
 * This function receives an array of slot objects and will return
 * either an array of those slots that are restricted or an empty
 * array.
 *
 * Restricted slots are defined as a slot object with a 'restrict'
 * object that has one or more keys within it.
 *
 * @param {Array.<Object>} slots
 *
 * @returns {Array.<Object>} restrictedSlots || []
 */
function isolateRestrictedSlots(slots) {
  if (!Array.isArray(slots)) return []

  return slots.filter((slot) => {
    const slotRestrictions = slot?.restrict
    if (typeof slotRestrictions !== 'object') return false

    return !!Object.keys(slotRestrictions).length
  })
}
/**
 * @function isPromotionalSlot
 *
 * This determines if a slot object is considered 'promotional', this
 * is based on the value of several properties within the object. Slot
 * must be in the value status, match the maximum discount, and also be
 * available & incentivized.
 *
 * @param {Object} slot
 * @param {Number} maxDiscount
 */
function isPromotionalSlot(slot, maxDiscount) {
  if (typeof slot !== 'object') return false

  const {
    statusCode,
    discount,
    available,
    incentivized
  } = slot

  if (statusCode !== 'V') return false
  if (discount !== maxDiscount) return false

  return available && incentivized
}

/**
 * @function datesArray
 *
 * This returns an array of dates starting from todays date
 * with the amount of dates as the input
 *
 * @param {Numbers} days
 * @returns {Array} array of days ["2023-05-04", "2023-05-05", "2023-05-06"]
 */

const createDatesArray = days => [...Array(days).keys()].map((index) => {
  const date = new Date()
  date.setDate(date.getDate() + index)

  return date.toISOString().slice(0, 10)
})

/**
 * @function clearSlotsCache
 *
 * Checks session storage for key (${userId}${serviceLocationId}${date})
 * and removes it
 *
 * @param {Numbers} userId
 * @param {Numbers} serviceLocationId
 */

const clearSlotsCache = (userId, serviceLocationId) => {
  const datesArray = createDatesArray(14)
  datesArray.forEach((date) => {
    const key = `${userId}${serviceLocationId}${date}`
    window.sessionStorage.removeItem(key)
  })
}
const updateServiceLocationChange = async (status) => {
  if (status === 205) {
    const { $store } = window.sharedVue.config.globalProperties
    const serviceType = $store.getters['UserProfile/serviceType']

    $store.commit('ShoppingMode/setServiceType', serviceType)
    const preferedPickupLocation = await $store.dispatch('UserPref/getUserPreference', 'preferred-pup')
    if (preferedPickupLocation.value) {
      $store.commit('UserPref/setPrefPup', preferedPickupLocation.value)
    }
  }
}

// Method reponsible for reloading the page on items availability changed
const itemsAvailabilityChangeHandler = (status, code) => {
  if (status === 205 && !code) {
    setTimeout(() => {
      window.location.reload()
    }, 1000)
  }
}

const checkForSlotReservationFailure = (expectedSuccessCodes, status, code, results) => {
  if (!expectedSuccessCodes.includes(status) && !code) {
    trackSlotReservationFailure(`improper status: "${status}" with no code returned from slot reservation`)
    throw new Error(results)
  }
}

export default {
  namespaced: true,
  state: initialState(),
  getters: {
    // eslint-disable-next-line  max-params
    minimumAmount: (state, getters, rootState, rootGetters) => {
      let response = 0
      const feeAmounts = rootGetters['UserProfile/feeAmounts']
      const { serviceType } = state.selectedSlot || {}
      if (feeAmounts) {
        response = (serviceType === 'P')
          ? (feeAmounts.minimumPickupAmount)
          : (feeAmounts.minimumDeliveryAmount)
      }
      return response
    },
    isAboveMinimum: (state, getters, rootState, rootGetters) => {
      let response = false
      const cartTotal = rootGetters['Cart/getSubTotal']
      const cartItems = rootGetters['Cart/getCartItems']
      const noMinimum = getters.minimumAmount === 0
      if (noMinimum) {
        response = true
      }

      if (cartItems && cartItems.length > 0) {
        response = noMinimum || cartTotal >= getters.minimumAmount
      }
      return response
    },
    orderProviderId: (state) => {
      // For regular order cases.
      const selectedProviderId = state.selectedSlot?.providerId
      const defaultProviderId = state.slots.length ? state.slots[0].providerId : ''
      return selectedProviderId || defaultProviderId
    },
    slots: state => state.slots,
    activeHeader: state => state.activeHeader,
    selectedSlot: state => state.selectedSlot,
    hasReservedSlot: state => !!state.selectedSlotId,
    promotionalSlot: state => state.promotionalSlot,
    intermediateServiceType: state => state.intermediateServiceType,
    intermediatePup: state => state.intermediatePup,
    intermediatePupDetails: state => state.intermediatePupDetails,
    slotsLoading: state => state.slotsLoading,
    slotsAvailable: state => state.slotsAvailable,
    unAttendedSlotsAvailable: state => state.unAttendedSlotsAvailable,
    sameDayDelivAvailable: state => state.sameDayDelivAvailable,
    slotServiceType: state => state.intermediateServiceType,
    isAttendedSlots: state => state.isAttendedSlots,
    restrictedSlotInfo: state => state.restrictedSlotInfo,
    isRestricted: state => state.isRestricted,
    refreshSlots: state => state.refreshSlots,
    shouldContinueCheckout: state => state.shouldContinueCheckout,
    hasChosenPackagingMethod: state => state.hasChosenPackagingMethod,
    deliveryFee: state => state.deliveryFee,
    packagingMethod: state => state.packagingMethod,
    hasPodBag: state => state.hasPodBag,
    singlePackagingMethodMarket: state => state.singlePackagingMethodMarket,
    podBagInfo: state => state.podBagInfo,
    hasForceRemovedItems: state => state.hasForceRemovedItems,
    slotForPackagingModal: state => state.slotForPackagingModal,
    hasStoredPickupLocation: state => state.hasStoredPickupLocation,
    slotTimeLoading: state => state.slotTimeLoading,
    restrictedItemsRemoved: state => state.restrictedItemsRemoved,
    tomorrowAvailable: state => state.tomorrowAvailable,
    isFirstSelectedSlot: state => state.isFirstSelectedSlot,
    isContactFreeAndRestricted: state => state.isContactFreeAndRestricted,
    slotsCount: state => state.slotsCount,
    isInitialized: state => state.isInitialized,
    hasNoSlots: state => state.hasNoSlots,
    initializeSlotsModal: state => state.initializeSlotsModal,
    enableRadioButtonsForAttendedSlots: state => state.enableRadioButtonsForAttendedSlots,
    fromOrderStatus: state => state.fromOrderStatus,
    warningMessageDismissed: state => state.warningMessageDismissed,
    failedReservationId: state => state.failedReservationId,
    hideExternalProviders: state => state.hideExternalProviders,
    headers: state => state.headers,
    slotChangeMade: state => state.slotChangeMade,
    selectedSlotInfo: state => state.slots.find(slot => slot.selected)
  },
  mutations: {
    setHeaders(state, payload) {
      state.headers = payload
    },
    setActiveHeader(state, payload) {
      state.activeHeader = payload
    },
    setSlots(state, payload) {
      state.slots = payload
    },
    setAttendedSlots(state, payload) {
      state.attendedSlots = payload
    },
    setUnAttendedSlots(state, payload) {
      state.unAttendedSlots = payload
    },
    setRestrictedSlots(state, payload) {
      state.restrictedSlots = payload
    },
    setServiceType(state, payload) {
      state.serviceType = payload
    },
    setTempServiceLocationId(state, payload) {
      state.tempServiceLocationId = payload
    },
    setPupAvail(state, payload) {
      state.pupAvail = payload
    },
    setDelivAvail(state, payload) {
      state.delivAvail = payload
    },
    setSelectedSlotId(state, payload) {
      state.selectedSlotId = payload
    },
    setSlotChangeMade(state, payload) {
      state.slotChangeMade = payload
    },
    setSelectedSlot(state, payload) {
      state.selectedSlot = payload
    },
    setPromotionalSlot(state, payload) {
      state.promotionalSlot = payload
    },
    setIntermediateServiceType(state, payload) {
      // this should be triggered when we change the service type while reserving time
      state.intermediateServiceType = payload
    },
    setIntermediatePup(state, payload) {
      // this should be triggered when we change the pickup store while reserving time
      state.intermediatePup = payload
    },
    setIntermediatePupDetails(state, payload) {
      state.intermediatePupDetails = payload
    },
    setSlotsLoading(state, payload) {
      state.slotsLoading = payload
    },
    setSlotsAvailable(state, payload) {
      state.slotsAvailable = payload
    },
    setUnAttendedSlotsAvailable(state, payload) {
      state.unAttendedSlotsAvailable = payload
    },
    setSameDayDelivAvailable(state, payload) {
      state.sameDayDelivAvailable = payload
    },
    setIsAttendedSlots(state, payload) {
      state.isAttendedSlots = payload
    },
    setRestrictedSlotInfo(state, payload) {
      state.restrictedSlotInfo = payload
    },
    setIsRestricted(state, payload) {
      state.isRestricted = payload
    },
    setRefreshSlots(state, payload) {
      state.refreshSlots = payload
    },
    setShouldContinueCheckout(state, payload) {
      state.shouldContinueCheckout = payload
    },
    setDeliveryFee(state, payload) {
      state.deliveryFee = payload
    },
    setPackagingMethod(state, payload) {
      state.packagingMethod = payload
    },
    setHasChosenPackagingMethod(state, payload) {
      state.hasChosenPackagingMethod = payload
    },
    setHasPodBag(state, payload) {
      state.hasPodBag = payload
    },
    setSinglePackagingMethodMarket(state, payload) {
      state.singlePackagingMethodMarket = payload
    },
    setPodBagInfo(state, payload) {
      state.podBagInfo = payload
    },
    setHasForceRemovedItems(state, payload) {
      state.hasForceRemovedItems = payload
    },
    setSlotForPackagingModal(state, payload) {
      state.slotForPackagingModal = payload
    },
    setHasStoredPickupLocation(state, payload) {
      state.hasStoredPickupLocation = payload
    },
    setLoadingSlot(state, payload) {
      state.slotTimeLoading = payload
    },
    setRestrictedItemsRemoved(state, payload) {
      state.restrictedItemsRemoved = payload
    },
    setTomorrowAvailable(state, payload) {
      state.tomorrowAvailable = payload
    },
    setIsFirstSelectedSlot(state, payload) {
      state.isFirstSelectedSlot = payload
    },
    setIsContactFreeAndRestricted(state, payload) {
      state.isContactFreeAndRestricted = payload
    },
    setSlotsCount(state, payload) {
      state.slotsCount = payload
    },
    setIsInitialized(state, payload) {
      state.isInitialized = payload
    },
    setNoSlots(state, hasNoSlots) {
      state.hasNoSlots = hasNoSlots
    },
    setInitializeSlotsModal(state, payload) {
      state.initializeSlotsModal = payload
    },
    removeSlotRestrictionItem(state, prodId) {
      const prodIndex = state.restrictedSlotInfo.restrictedSlotProductId.indexOf(prodId)
      state.restrictedSlotInfo.restrictedSlotProductId.splice(prodIndex, 1)
    },
    setEnableRadioButtonsForAttendedSlots(state, isEnabled) {
      state.enableRadioButtonsForAttendedSlots = isEnabled
    },
    markWarningMessageAsDismissed(state) {
      state.warningMessageDismissed = true
    },
    setFromOrderStatus(state, payload) {
      state.fromOrderStatus = payload
    },
    setFailedReservationId(state, payload) {
      state.failedReservationId = payload
    },
    setHideExternalProviders(state, payload) {
      state.hideExternalProviders = payload
    },
    toggleSlotSelectionInList(state, selectedSlotId) {
      state.slots.map((slot) => {
        if (slot.id === selectedSlotId) {
          slot.selected = true
        }
        return slot
      })
    },
    toggleSlotDisabledWhileLoadingSlot(state) {
      state.slots.map((slot) => {
        slot.disabled = !slot.disabled
        return slot
      })
    },
    setDaySelection(state, daySelection) {
      state.daySelection = daySelection
    },
    setReservedSlot(state, payload) {
      state.reservedSlot = payload
    }
  },
  actions: {
    /**
     * @action displaySlots
     *
     * params were added during logic change of how slot promotion
     * retrieves its data.  Rather than slot promotion make redundant
     * calls the data is now passed to it through the params here
     * to speed up initializaiton and reduce logic in slot promotion
     *
     * @param params {initialCall: <Boolean>, serviceType: <String>}
     */
    async displaySlots({ dispatch, commit, state }, params = {}) {
      const { config } = params
      commit('setFailedReservationId', null)
      if (state.headers.length <= 0) {
        await dispatch('getSlotHeaders', config)
        await dispatch('getActiveSlotHeader')
      } else {
        await dispatch('getActiveSlotHeader')
        await dispatch('getSlots', { headers: true, config })
      }
      await dispatch('DeliveryOptions/initialize', null, { root: true })
      await dispatch('getRestrictedSlotInformation')
      dispatch('checkContactFreeAndRestricted')
      await dispatch('checkMinCartRequirement')
      commit('setRefreshSlots', false)
      commit('setIsFirstSelectedSlot', Object.keys(state.selectedSlot).length === 0)
      commit('setIsInitialized', true)
    },
    async initializeSlots({
      dispatch, state, rootGetters, getters
    }, config) {
      const deliveryServiceLocation = rootGetters['UserProfile/deliveryServiceLocation']
      const uuid = uuidv4()
      const isSlotLogsEnabled = rootGetters['SiteConfig/varByName']('feature_slot_logs')
      const correlationID = `${config}-'initializeSlots'-${uuid}`
      const { slotServiceType } = getters
      const payload = {
        serviceLocationId: deliveryServiceLocation.serviceLocationId || null,
        serviceType: slotServiceType || deliveryServiceLocation.serviceType,
        delivAvail: state.delivAvail,
        pupAvail: state.pupAvail,
        headers: true,
        selected: true,
        userId: rootGetters['UserProfile/userId'],
        basketId: rootGetters['Cart/getBasketId'],
        correlationID
      }
      const vueInstance = window.sharedVue?.config?.globalProperties || {}
      if (isSlotLogsEnabled) {
        vueInstance.$trackClientLog('initializeSlots-Request', { correlationID, ...payload })
      }

      const responseData = await fetchSlots(payload, correlationID).execute()
      if (isSlotLogsEnabled) {
        vueInstance.$trackClientLog('initializeSlots-Response', { correlationID, status: responseData.status })
      }

      if (responseData.status === 200) {
        const { response } = responseData?.data || {}
        dispatch('updateState', response)
      }
    },
    /**
     * @note
     *
     * the v6 version of GET /slots no longer returns an alerts object that can trigger the
     * cutoff warning.  This alert can still be triggered from order status but this function
     * acts as a pre-caution to check the cutoff time in slot data and open the cutoff warning
     * if necessary.
     */
    async checkSlotCutoffWarning({ rootGetters, commit, dispatch }, selectedSlot) {
      const { currentOrder } = rootGetters['Order/orderStatus']
      const { cutoffTime } = selectedSlot
      const currentTime = Date.now()
      const cutoffEpoch = new Date(cutoffTime).getTime()
      const timeToCutoff = cutoffEpoch - currentTime

      if (timeToCutoff < CUTOFF_MAX_TIME) {
        commit('setCutoffAlertFlag', true)
        const currentOrderStatus = currentOrder?.status || null

        const secondsToCutoff = Math.round(timeToCutoff) / 1000

        if (currentOrderStatus === 'SUBMITTED') {
          await dispatch('CutOff/processCutoffAlertResponse', {
            alerts: { SUBMITTED_CUTOFF: { secondsToCutoff } }
          }, { root: true })
        }
        if (currentOrderStatus === 'WORKING') {
          await dispatch('CutOff/processCutoffAlertResponse', {
            alerts: { WORKING_CUTOFF: { secondsToCutoff } }
          }, { root: true })
        }
      }
    },
    async getSlotHeaders({
      dispatch, state, getters, rootGetters, commit
    }, config) {
      const { delivAvail, pupAvail, intermediatePup: pupId = '' } = state
      const { slotServiceType } = getters
      const uuid = uuidv4()
      const isSlotLogsEnabled = rootGetters['SiteConfig/varByName']('feature_slot_logs')
      const correlationID = `${config}-'getSlotHeaders'-${uuid}`
      let serviceLocation = null
      if (slotServiceType === 'D') {
        const deliveryResponse = await UserPreferencesAPI.get(
          USER_PREFERENCE_KEYS.PREFERRED_DELIVERY, rootGetters['UserProfile/userId']
        )
        serviceLocation = deliveryResponse?.data?.response?.preference?.value
      }
      if (slotServiceType === 'P') {
        serviceLocation = rootGetters['UserPref/prefPup']?.serviceLocationId
      }
      if (state.tempServiceLocationId) {
        serviceLocation = state.tempServiceLocationId
      }
      const payload = {
        headers: true,
        selected: true,
        serviceType: slotServiceType,
        serviceLocationId: serviceLocation,
        delivAvail,
        pupAvail,
        pupId,
        userId: rootGetters['UserProfile/userId'],
        basketId: rootGetters['Cart/getBasketId']
      }

      const vueInstance = window.sharedVue?.config?.globalProperties || {}
      if (isSlotLogsEnabled) {
        vueInstance.$trackClientLog('getSlotHeaders-Request', { correlationID, ...payload })
      }
      const { data: response, status } = await fetchSlots(payload, false, correlationID).execute()
      const { response: data = {} } = response
      if (isSlotLogsEnabled) {
        vueInstance.$trackClientLog('getSlotHeaders-Response', { correlationID, status })
      }
      if (!status || status !== 200) {
        throw new Error(`Invalid http response status: ${status}`)
      }

      const { selectedSlotId, selectedSlot } = data

      if (selectedSlotId !== 0 && !!selectedSlot) {
        dispatch('checkSlotCutoffWarning', selectedSlot)
      }

      dispatch('updateState', data)
      dispatch('checkTomorrowAvailable')
      commit('setInitializeSlotsModal', false)
    },
    async getSlots({
      dispatch, commit, state, getters, rootGetters
    }, params = {}) {
      const { headers, futureDate, config } = params
      const { currentOrder } = rootGetters['Order/orderStatus']
      const {
        activeHeader: { date: viewDate } = {}, delivAvail, pupAvail, intermediatePup: pupId = ''
      } = state
      const { slotServiceType } = getters
      const uuid = uuidv4()
      const isSlotLogsEnabled = rootGetters['SiteConfig/varByName']('feature_slot_logs')
      const correlationID = `${config}-'getSlots'-${uuid}`
      let serviceLocation = null
      if (slotServiceType === 'D') {
        const deliveryResponse = await UserPreferencesAPI.get(
          USER_PREFERENCE_KEYS.PREFERRED_DELIVERY, rootGetters['UserProfile/userId']
        )
        serviceLocation = deliveryResponse?.data?.response?.preference?.value
      }
      if (slotServiceType === 'P') {
        serviceLocation = rootGetters['UserPref/prefPup']?.serviceLocationId
      }
      if (state.tempServiceLocationId) {
        serviceLocation = state.tempServiceLocationId
      }
      const slotDate = currentOrder?.orderStatusInfo?.date

      const payload = {
        headers,
        selected: true,
        serviceType: slotServiceType,
        serviceLocationId: serviceLocation,
        delivAvail,
        pupAvail,
        viewDate: viewDate || futureDate,
        pupId,
        userId: rootGetters['UserProfile/userId'],
        basketId: rootGetters['Cart/getBasketId']
      }
      const vueInstance = window.sharedVue?.config?.globalProperties || {}
      const isSuperUserApp = window.appConfig.superUserEnabled
      if (isSuperUserApp && slotDate && params.config !== 'PdlSlotHeader-displaySlots') {
        payload.viewDate = slotDate
        dispatch('setSelectedSlotDate', slotDate)
      }

      if (isSlotLogsEnabled) {
        vueInstance.$trackClientLog('getSlots-Request', { correlationID, ...payload })
      }

      try {
        const { data: response, status } = await fetchSlots(payload, !headers, correlationID).execute()
        const { response: data = {} } = response

        if (isSlotLogsEnabled) {
          vueInstance.$trackClientLog('getSlots-Response', { correlationID, status })
        }

        if (!status || status !== 200) {
          throw new Error(`Invalid http response status: ${status}`)
        }

        dispatch('updateState', data)
        dispatch('checkSameDayDeliv', data)
        await dispatch('getRestrictedSlotInformation')
        dispatch('checkContactFreeAndRestricted')
        return data
      } finally {
        commit('setInitializeSlotsModal', false)
        commit('setSlotsLoading', false)
      }
    },
    /**
     * future enhancemnt: this does not need to traverse
     * the entire list of slots and count them all, its just checking
     * that at least one slot exists and can be written to perform
     * more effeciently.
     */
    async getSlotTotalCounts({ commit, state }) {
      const slotCountsByDay = state.headers || {}
      const slotTotalCount = Object.values(slotCountsByDay)
        .map(header => header.attendedCount + header.unattendedCount)
        .reduce((current, memo) => current + memo, 0)
      if (slotTotalCount > 0) {
        commit('setNoSlots', false)
      } else {
        commit('setNoSlots', true)
      }
    },
    getActiveSlotHeader({ commit, state, rootGetters }) {
      commit('setActiveHeader', {}) // initialize active header as empty
      const slotCountEnabled = rootGetters['SiteConfig/varByName']('feature_slot_count')
      const availableHeaders = state.headers?.filter(header => header.id !== 0)
      // set Active Header with selected slot
      if (Object.keys(state.selectedSlot).length) {
        const selectedSlotHeader = availableHeaders.filter(header => header.date === state.selectedSlot.date)
        if (selectedSlotHeader.length) {
          commit('setActiveHeader', selectedSlotHeader[0])
          return
        }
      }
      // set Active Header with available slot
      if (slotCountEnabled) {
        for (let i = 0; i < availableHeaders.length; i += 1) {
          if ((availableHeaders[i]?.attendedCount + availableHeaders[i]?.unattendedCount) > 0) {
            commit('setActiveHeader', availableHeaders[i])
            return
          }
        }
      }
      if (availableHeaders.length) {
        commit('setActiveHeader', availableHeaders[0])
      }
    },
    checkSameDayDeliv({ commit }, payload) {
      const sameDay = payload.slots?.some(slot => slot.sameDay && slot.premium)
      commit('setSameDayDelivAvailable', sameDay)
    },
    setSelectedSlotDate({ commit, state }, date) {
      const availableHeaders = state.headers?.filter(header => header.id !== 0)
      // set Active Header with selected slot
      if (Object.keys(state.selectedSlot).length) {
        const selectedSlotHeader = availableHeaders.filter(header => header.date === date)
        if (selectedSlotHeader.length) {
          commit('setActiveHeader', selectedSlotHeader[0])
        }
      }
    },
    /**
     * @action getPromotionalSlot
     *
     * Traverses the array of slots in state and sets first available promo slot
     * to it's own property in state.  If no promotional slot is found then it
     * defaults to an empty object.
     */
    getPromotionalSlot({ commit, state }) {
      const { maxDiscount } = state.activeHeader
      const promotionalSlot = state.slots.find(slot => isPromotionalSlot(slot, maxDiscount)) || {}

      commit('setPromotionalSlot', promotionalSlot)
    },
    /**
     * @action getRestrictedSlotInformation
     *
     * This function inserts the restricted slots into it's own piece of state
     * and categorizes the products causing any restrictions to hold place
     */
    getRestrictedSlotInformation({ commit, state }) {
      const restrictedSlots = isolateRestrictedSlots(state.slots)
      commit('setRestrictedSlots', restrictedSlots)
      if (restrictedSlots.length > 0) {
        commit('setIsRestricted', true)
        const restrictedTypeName = []
        const restrictedItemsName = []
        const restrictedItemsId = []

        // for each restricted slot pull out restricted product warnings
        restrictedSlots.forEach((restrictedSlot) => {
          const restrictedProductGroupings = restrictedSlot.restrict
          const restrictedProductCategories = Object.keys(restrictedProductGroupings)
          const restrictionCategoryNames = restrictedProductCategories.map(
            category => RESTRICTED_PRODUCT_TYPES[category]
          )

          // save all slot restriction categories for the day
          restrictedTypeName.push(...restrictionCategoryNames)

          const restrictedSlotProducts = Object.values(restrictedProductGroupings)
          for (let i = 0; i < restrictedSlotProducts.length; i += 1) {
            for (let j = 0; j < restrictedSlotProducts[i].length; j += 1) {
              restrictedItemsName.push(restrictedSlotProducts[i][j].name)
              restrictedItemsId.push(restrictedSlotProducts[i][j].prodId)
            }
          }
        })
        commit('setRestrictedSlotInfo', {
          restrictedSlotType: _.uniq(restrictedTypeName),
          restrictedSlotProductName: _.uniq(restrictedItemsName),
          restrictedSlotProductId: _.uniq(restrictedItemsId)
        })
      } else {
        commit('setIsRestricted', false)
        commit('setRestrictedItemsRemoved', false)
      }
    },
    async removeRestrictedItems({
      dispatch, state, commit, rootGetters
    }) {
      const restrictedSlotProductId = JSON.parse(JSON.stringify(state.restrictedSlotInfo.restrictedSlotProductId))
      const payload = {
        items: [{
          productId: restrictedSlotProductId.join()
        }]
      }
      const storedProducts = JSON.parse(JSON.stringify(rootGetters['Cart/getCartItems']))
      const removedProducts = _.map(restrictedSlotProductId, (productId) => {
        const product = _.findWhere(storedProducts, { prodId: productId })
        product.previousQty = product.qty
        product.newQty = 0
        return product
      })
      const gtmOptions = {
        direction: 'remove',
        cart: {
          products: removedProducts
        }
      }
      await dispatch('Cart/deleteFromCart', payload, { root: true })
      await dispatch('getSlots', { config: 'Slots-removeRestrictedItems', headers: false })
      dispatch('checkMinCartRequirement')
      commit('setRestrictedSlotInfo', {
        restrictedSlotType: [],
        restrictedSlotProductName: [],
        restrictedSlotProductId: []
      })
      commit('setRestrictedItemsRemoved', true)
      trackCartBulkChange(gtmOptions)
    },
    updateState({ commit, state }, payload) {
      commit('setServiceType', payload.serviceType)
      commit('setPupAvail', payload.pupAvail)
      commit('setDelivAvail', payload.delivAvail)
      commit('setSelectedSlotId', payload.selectedSlotId || 0)
      if (payload.selectedSlot) {
        commit('setSelectedSlot', payload.selectedSlot)
        commit('setIsAttendedSlots', !payload.selectedSlot.unattended)
      }
      if (payload.headers.length > 0) {
        commit('setHeaders', payload.headers)
      }
      commit('setSlots', payload.slots || [])
      commit('setAttendedSlots', payload.slots ? payload.slots.filter(slot => !slot.unattended) : [])
      commit('setUnAttendedSlots', payload.slots ? payload.slots.filter(slot => slot.unattended) : [])
      commit('setSlotsAvailable', state.slots.length !== 0)
      commit('setUnAttendedSlotsAvailable', state.unAttendedSlots.length !== 0)
    },
    checkMinCartRequirement({
      commit, state, getters, rootGetters
    }) {
      // No order Minimum for EBT user who has EBT card in account
      const hasEBT = rootGetters['UserProfile/hasEBT']
      const ebtActive = hasEBT && ebtFeatureEnabled()
      const value = ebtActive ? true : getters.isAboveMinimum
      if (state.isContactFreeAndRestricted) {
        commit('setShouldContinueCheckout', false)
      } else {
        commit('setShouldContinueCheckout', value)
      }
    },
    async addressSync({ state, rootGetters }) {
      // ensure matched service location for registered user selecting delivery slot
      const userInfo = rootGetters['UserProfile/userInfo']
      const deliveryServiceLocation = rootGetters['UserProfile/deliveryServiceLocation']
      if (rootGetters['SiteConfig/varByName']('feature_extra_delivery_pref_sync')
        && state.serviceType === ORDER_SERVICE_TYPES.DELIVERY
        && !rootGetters['LoginStatus/isGuestUser']
      ) {
        addressSyncService.saveLocationByAddress(userInfo, deliveryServiceLocation)
      }
    },
    handleReserveSlotsAPIError({ commit }, payload) {
      const code = payload?.response?.data?.response?.code
      if (code && code !== 'SLOTS_RESERVE_SUCCESS') {
        trackSlotReservationFailure(`received status other than SLOTS_RESERVE_SUCCESS from slot reservation`)

        if (code === 'INSTACART_SLOTS_RESERVE_ERROR') {
          commit('Alert/setAlert', {
            icon: true,
            type: 'error',
            header: 'Time Unavailable',
            body: `We're sorry but there was a problem with the slot you selected. Please select another option.`,
            primary: {
              text: 'Ok',
            },
          }, { root: true })

          commit('setHideExternalProviders', true)
          trackInstacartSlotError(payload?.response.data.response)
        }
        throw new Error(code)
      }
    },
    async reserveTimeSlot(
      {
        commit,
        dispatch,
      },
      payload
    ) {
      let success = false
      commit('setFailedReservationId', null)
      dispatch('addressSync')
      const { slotInfo: { unattended } } = payload

      // this is the proper moment to add/remove podbag from cart in user experience
      await dispatch('DeliveryOptions/configurePodbagInCart', unattended, { root: true })
      commit('toggleSlotDisabledWhileLoadingSlot')
      try {
        const { expectedSuccessCodes } = reserveSlots(payload).getInfo()
        const reservationResult = await reserveSlots(payload).execute()
        const { status } = reservationResult
        const code = reservationResult?.response?.data?.response?.code
        dispatch('trackReserveSlotInfo', payload)

        checkForSlotReservationFailure(expectedSuccessCodes, status, code, reservationResult)

        dispatch('handleReserveSlotsAPIError', reservationResult)

        itemsAvailabilityChangeHandler(status, code)

        await dispatch('checkMinCartRequirement')
        commit('setServiceType', payload.serviceType)
        const slotInfo = _.clone(payload.slotInfo)
        slotInfo.selected = true
        commit('setSelectedSlot', slotInfo || {})
        commit('setSelectedSlotId', payload.slotInfo.id || 0)
        commit('toggleSlotSelectionInList', payload.slotInfo.id)
        commit('setReservedSlot', slotInfo || {})
        commit('setSlotChangeMade', false)
        commit('toggleSlotDisabledWhileLoadingSlot')
        dispatch('CutOff/activateCutoffAlertFlag', false, { root: true })
        clearSlotsCache(payload?.userId, payload?.apiParams?.serviceLocationId)
        await updateServiceLocationChange(status)
        success = true
      } catch (e) {
        const { id } = payload.slotInfo
        commit('setFailedReservationId', id)
        const code = e?.data?.response?.code
        if (code) {
          dispatch('processSlotReservationError', code)
        } else if (this.selectedProviderId === ORDER_PROVIDER_IDS.INSTACART) {
          trackInstacartSlotError(e)
        } else {
          trackSlotReservationFailure(`ambiguous error returned from slot reservation`)
        }
      } finally {
        commit('setLoadingSlot', false)
        if (payload.slotInfo.unattended) {
          commit('setIsFirstSelectedSlot', false)
        }
        if (success) {
          this.dispatch('Order/initOrderStatus', { canRefresh: true })
        }
      }
    },
    async processSlotReservationError({ dispatch }, errorCode) {
      trackSlotReservationFailure(`error code: "${errorCode}" returned from slot reservation`)
      switch (errorCode) {
        case 'SLOTS_SLOT_PAST_CUTOFF':
          await dispatch('displaySlots', { config: 'Slots-processSlotReservationError-pastCutOff' })
          break
        case 'SLOTS_SLOTID_NOT_FOUND':
        case 'SLOTS_SOLD_OUT':
        case 'SLOTS_RESERVE_ERROR':
          await dispatch('getSlots', { config: 'Slots-processSlotReservationError' })
          await dispatch('checkMinCartRequirement')
          break
        default:
          trackSlotReservationFailure('ambiguous error returned from slot reservation')
          break
      }
    },
    trackReserveSlotInfo({ state, rootState }, payload) {
      const { lockersEnabledInStore } = rootState.PickupLockers
      trackSlotSelection({
        slotData: {
          serviceType: lockersEnabledInStore ? 'L' : payload.slotInfo.serviceType,
          tomorrowAvailable: state.tomorrowAvailable,
          ...payload.slotInfo,
        },
        headers: state.headers,
        name: state.packagingMethod,
        selectionLocation: payload.selectionLocation,
        isFirstSelectedSlot: state.isFirstSelectedSlot
      })
    },
    async clearSelectedSlot({ commit }) { // Slots may also need to be cleared by [ShoppingMode/clearCurrentSlot]
      commit('setSelectedSlotId', 0)
      commit('setSelectedSlot', {})
    },
    checkTomorrowAvailable({ commit, state }) {
      const tomorrow = new Date()
      tomorrow.setDate(tomorrow.getDate() + 1)
      const tomorrowDate = new Date(tomorrow)
      const tomorrowAsDateString = window.dateFormat(tomorrowDate, 'UTC:yyyy-mm-dd')
      const slotForTomorrow = state.headers.find(header => header.date === tomorrowAsDateString)
      commit('setTomorrowAvailable', slotForTomorrow ? slotForTomorrow.statusCode !== 'U' : false)
    },
    checkContactFreeAndRestricted({ rootGetters, state, commit }) {
      const contactFreeLevel = rootGetters['UserProfile/contactFreeLevel']
      const serviceType = rootGetters['UserProfile/serviceType']

      if (
        contactFreeLevel === '2'
        && localStorage.getItem('contactFree') === 'true'
        && state.isRestricted && serviceType === 'D'
      ) {
        commit('setIsContactFreeAndRestricted', true)
      } else if (contactFreeLevel === '3' && state.isRestricted && serviceType === 'D') {
        commit('setIsContactFreeAndRestricted', true)
      } else {
        commit('setIsContactFreeAndRestricted', false)
      }
    },
    setOrderUpdateAlert({ commit, rootGetters }) {
      if (rootGetters['Order/currentOrderSubmitted']) {
        commit('Alert/setAlert', {
          icon: true,
          type: 'warning',
          header: 'Update Order Required',
          body: `The time change you selected will not be applied until you update your order. \
          Please update your order via the Cart to make this change.`,
          primary: {
            text: 'Continue',
          },
        }, { root: true })
      }
    },
    resetSelectedSlotInfo({ commit, state }) {
      if (state.reservedSlot) {
        commit('setSelectedSlotId', state.reservedSlot?.id)
        commit('setSelectedSlot', state.reservedSlot)
      } else {
        commit('setSelectedSlotId', 0)
        commit('setSelectedSlot', {})
      }
      commit('setSlotChangeMade', false)
    }
  }
}
