import { GTM, GTM_GA4 } from 'utils/constants'

export function trackSubscriptionPlanPurchase(plan) {
  const vueInstance = window.sharedVue.config.globalProperties
  const { subscriptionPlan = '' } = plan
  const subscriptionName = vueInstance.$store.getters['SiteConfig/varByName']('brand_subscription_program_name')
  vueInstance.$trackGtmEvent(GTM.subscriptionPlanPurchase, {
    subscriptionPlan
  })
  vueInstance.$trackGA4Event(GTM_GA4.account, {
    action: 'subscription - plan purchase',
    result: subscriptionPlan,
    site_location: `${vueInstance.$store.getters['TitleBar/pageTitle'].toLowerCase()} - ${subscriptionName}`
  })
}
