<template>
  <ul
    v-if="active.length > 0"
    class="pill-box-list"
  >
    <li v-show="filtersLabel"
class="pill-box-list_label">
      <span tabindex="0">{{ filtersLabel }}:</span>
    </li>
    <li
      class="pill-box-list_pill"
      :class="{
        'ml-0': !filtersLabel
      }"
    >
      <PdlPillBox
        v-for="filter in active"
        :key="filter.name"
        :filter="filter"
        :label="filter.name"
        :x-only="xOnly"
        @clear-filter="clearFilters"
      />
    </li>
    <li class="pill-box-list_pill">
      <PdlPillBox
        label="Clear All"
        :is-last="true"
        :x-only="xOnly"
        @clear-filter="clearFilters"
        @clear-all="onClearAll"
      />
    </li>
  </ul>
</template>

<script>
import PdlPillBox from './PdlPillBox'

export default {
  components: {
    PdlPillBox
  },
  props: {
    filterOptions: {
      type: Array,
      default: () => []
    },
    xOnly: {
      type: Boolean,
      default: false
    },
    filtersLabel: {
      type: String,
      default: ''
    }
  },
  computed: {
    active() {
      return this.filterOptions.filter(filter => filter.active || filter.checked)
    }
  },
  methods: {
    clearFilters(payload) {
      this.$emit('clear-filter', payload)
    },
    onClearAll() {
      this.$emit('clear-all')
    },
  }
}
</script>
