import PaymentMethodsApi from 'api/PaymentMethodsAPI'

const initialState = {
  showApplePay: false,
  currentApplePaySession: {},
  applePayAuthorizedResponse: {},
  applePayToken: {},
  paymentTypesEnabled: true,
  isApplePayOptimizelyEnabled: false
}
export default {
  namespaced: true,
  state: {
    ...initialState,
    applePayBrandText: 'Apple Pay®',
  },
  getters: {
    getApplePayToken: state => state.applePayToken,
    getShowApplePay: (state, getters, rootState, rootGetters) => {
      const featureApplePay = rootGetters['SiteConfig/varByName']('feature_apple_pay')
      const hasActivePaymentMethod = rootGetters['UserProfile/userInfo']?.information?.hasActivePaymentMethod
      return featureApplePay && state.showApplePay && hasActivePaymentMethod && state.isApplePayOptimizelyEnabled
    },
    getBillingAddress: (state) => {
      if (state.applePayAuthorizedResponse) {
        return state.applePayAuthorizedResponse.details
          ? state.applePayAuthorizedResponse.details?.billingContact
          : state.applePayAuthorizedResponse.payment?.billingContact
      }
      return null
    },
    getBillingPhoneNumber: (state) => {
      if (state.applePayAuthorizedResponse) {
        return state.applePayAuthorizedResponse.details
          ? state.applePayAuthorizedResponse.details?.shippingContact.phoneNumber
          : state.applePayAuthorizedResponse.payment?.shippingContact.phoneNumber
      }
      return null
    },
    getApplePayAuthorizedToken: (state) => {
      if (state.applePayAuthorizedResponse) {
        return state.applePayAuthorizedResponse.details
          ? state.applePayAuthorizedResponse.details?.token
          : state.applePayAuthorizedResponse.payment?.token
      }
      return null
    },
    getApplePayPaymentMethodName: (state) => {
      if (state.applePayAuthorizedResponse) {
        return state.applePayAuthorizedResponse.details
          ? state.applePayAuthorizedResponse.details?.token?.paymentMethod.displayName
          : state.applePayAuthorizedResponse.payment?.token?.paymentMethod.displayName
      }
      return null
    }
  },
  mutations: {
    setShowApplePay(state, value) {
      state.showApplePay = value
    },
    setCurrentApplePaySession(state, value) {
      state.currentApplePaySession = value
    },
    setApplePayAuthorizedResponse(state, value) {
      state.applePayAuthorizedResponse = value
    },
    setapplePayToken(state, value) {
      state.applePayToken = value
    },
    setPaymentTypesEnabled(state, value) {
      state.paymentTypesEnabled = value
    },
    setApplePayOptimizely(state, value) {
      state.isApplePayOptimizelyEnabled = value
    },
  },
  actions: {
    resetApplePaySession({ commit }) {
      commit('setCurrentApplePaySession', {})
    },
    resetapplePayToken({ commit }) {
      commit('setapplePayToken', {})
    },
    async validateMerchant({ commit, rootGetters }, event) {
      const { validationURL } = event
      const userId = rootGetters['UserProfile/userId']
      const opco = rootGetters['SiteConfig/opco'].toLowerCase()
      const domainName = window.location.hostname // 'ada.giantfood.com'
      const payload = {
        validationUrl: validationURL, //  e.g. "https://apple-pay-gateway.apple.com/paymentservices/paymentSession"
        domainName,
        opco,
        walletType: 'APPLEPAY'
      }
      const vueInstance = window.sharedVue.config.globalProperties
      vueInstance.$trackClientLog('apple_pay_validate_merchant_payload', payload)
      const applePayResponse = await PaymentMethodsApi.getApplePaymentSession(userId, payload)
      // Handle requests
      if (applePayResponse.status === 200 || applePayResponse.response?.status === 200) {
        commit('setCurrentApplePaySession', applePayResponse.data)
        vueInstance.$trackClientLog('apple_pay_validate_merchant_response', applePayResponse)
      }

      // Handle errors for payment session API
      if (applePayResponse.status >= 400 || applePayResponse.response?.status >= 400) {
        commit('Alert/setAlert', {
          type: 'error',
          header: 'Payment Session Error',
          body: 'An unknown error has occurred. Please try again later.',
          primary: {
            text: 'Ok',
            callback: async () => {
              vueInstance.$store.commit('Alert/clear')
            }
          }
        }, { root: true })
        vueInstance.$trackClientLog('apple_pay_validate_merchant_response', applePayResponse.response)
        return applePayResponse.response
      }

      return applePayResponse
    }
  }
}
