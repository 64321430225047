import { GTM_GA4 } from 'utils/constants'

export default function trackNavigation(trackNavData) {
  const vueInstance = window.sharedVue.config.globalProperties
  const {
    action,
    type,
    linkUrl,
    linkText,
    linkId,
    label,
    version,
    index,
    siteLocation,
    elementId
  } = trackNavData
  const dataToTrack = {}
  if (type || action) {
    dataToTrack.type = type || action
  }
  if (linkUrl) {
    dataToTrack.link_url = linkUrl
  }
  if (linkText || label) {
    dataToTrack.link_text = linkText || label
  }
  if (linkId || elementId) {
    dataToTrack.link_id = linkId || elementId
  }
  if (version) {
    dataToTrack.version = version
  }
  if (index) {
    dataToTrack.index = index
  }
  if (siteLocation) {
    dataToTrack.site_location = siteLocation
  }
  vueInstance.$trackGA4Event(GTM_GA4.navigation, dataToTrack)
}
