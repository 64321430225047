import ApiService from '@/api/'
import OrderRatingAPI from 'api/OrderRatingAPI'
import excludeNilFromObj from 'utils/filters/excludeNilFromObj'
import { trackPostDeliveryTipError } from 'utils/tracking/trackPostDeliveryTipError'

const initialState = {
  rate: 0,
  reasons: [],
  comment: '',
  tip: 0,
  loading: false,
  skipped: false,
  complete: false,
  type: null,
  tipAmount: 0,
  usingCustomTip: false,
  reviewOrderServiceType: null,
  isDriverTipAllowed: false
}

export default {
  namespaced: true,
  state: {
    orderId: null,
    fullOrderId: null,
    storeName: 'Peapod',
    orderTitle: 'Pickup Experience',
    deliveryTitle: 'Delivery',
    driver: {
      driverId: null,
      firstName: null,
      lastName: null
    },
    orderHasTip: false,
    providerId: null,
    ...initialState
  },
  getters: {
    orderId: state => state.orderId,
    fullOrderId: state => state.fullOrderId,
    type: state => state.type,
    storeName: state => state.storeName,
    rate: state => state.rate,
    reasons: state => state.reasons,
    comment: state => state.comment,
    tip: state => state.tip,
    loading: state => state.loading,
    skipped: state => state.skipped,
    complete: state => state.complete,
    typeTitle(state) {
      const title = (state.type === 'P') ? state.orderTitle : state.deliveryTitle
      const name = (state.type === 'P') ? '' : `${state.storeName} `
      return `Rate Your ${name}${title}.`
    },
    status(state) {
      const {
        rate, reasons, comment, tip, skipped
      } = state
      return excludeNilFromObj({
        rate, reasons, comment, tip, skipped
      })
    },
    tipAmount: state => state.tipAmount,
    orderHasTip: state => state.orderHasTip,
    reviewOrderServiceType: state => state.reviewOrderServiceType,
    isDriverTipAllowed: state => state.isDriverTipAllowed,
    providerId: state => state.providerId
  },
  mutations: {
    setOrderId(state, value) {
      state.orderId = value
    },
    setFullOrderId(state, value) {
      state.fullOrderId = value
    },
    setOrderHasTip(state, value) {
      state.orderHasTip = value
    },
    setType(state, value) {
      state.type = value
    },
    setStoreName(state, value) {
      state.storeName = value
    },
    setRate(state, value) {
      state.rate = value
    },
    setReasons(state, value) {
      state.reasons = value
    },
    setComment(state, value) {
      state.comment = value
    },
    setTip(state, value) {
      state.tip = value
    },
    setLoading(state, value) {
      state.loading = value
    },
    setSkipped(state, value) {
      state.skipped = value
    },
    setComplete(state, value) {
      state.complete = value
    },
    reset(state) {
      Object.assign(state, initialState)
    },
    setTipAmount(state, value) {
      state.tipAmount = value
    },
    setUsingCustomTip(state, value) {
      state.usingCustomTip = value
    },
    setReviewOrderServiceType(state, value) {
      state.reviewOrderServiceType = value
    },
    setIsDriverTipAllowed(state, value) {
      state.isDriverTipAllowed = value
    },
    setDriver(state, value) {
      state.driver = value
    },
    setProviderId(state, payload) {
      state.providerId = payload
    }
  },
  actions: {
    initialize({ commit }, payload) {
      Object.keys(payload).forEach((key) => {
        const mutationName = `set${key[0].toUpperCase()}${key.slice(1)}`
        commit(mutationName, payload[key])
      })
    },
    async getOrderTipInfo({ state, commit, rootGetters }) {
      try {
        const userId = rootGetters['UserProfile/userId']
        const completedOrderInfo = await OrderRatingAPI.getOrderTipInfo(userId, state.fullOrderId)
        const { tipsExist } = completedOrderInfo.data
        commit('setOrderHasTip', tipsExist)
      } catch (e) {
        trackPostDeliveryTipError(e.message)
      }
    },
    async postTipToOrder({ state, rootGetters }) {
      const userId = rootGetters['UserProfile/userId']
      return OrderRatingAPI.postTipToOrder(userId, state.fullOrderId, state.tipAmount)
    },
    async postApplePayPostDeliveryTip({ state, rootGetters }) {
      const userId = rootGetters['UserProfile/userId']
      const billingAddress = rootGetters['ApplePaymentStore/getBillingAddress']
      const phoneNumber = rootGetters['ApplePaymentStore/getBillingPhoneNumber']
      const applePayToken = rootGetters['ApplePaymentStore/getApplePayToken']
      const opco = rootGetters['SiteConfig/opco']
      const payload = {
        driverTip: {
          amount: state.tipAmount
        },
        opco,
        applePay: {
          billingContact: {
            ...billingAddress,
            phoneNumber
          },
          paymentToken: applePayToken
        }
      }
      return OrderRatingAPI.postApplePayPostDeliveryTip(userId, state.fullOrderId, payload)
    },
    async postLastMileTip({ state, rootGetters }) {
      const userId = rootGetters['UserProfile/userId']
      const postTipResponse = await OrderRatingAPI.postLastMileTip(userId, state.fullOrderId, state.tipAmount)

      if (typeof postTipResponse !== 'object' || postTipResponse.status !== 200) {
        throw new Error(postTipResponse)
      }

      return postTipResponse
    },
    skipReview({ state, commit, rootGetters }) {
      commit('setSkipped', true)
      commit('setLoading', true)

      const data = {
        starRating: 0,
        skipped: state.skipped
      }

      const basketId = rootGetters['RecentOrders/reviewOrder']?.basketId
      const userId = rootGetters['UserProfile/userId']
      const endpoint = `/api/v6.0/user/${userId}/orders/${basketId}/review`

      return ApiService.put(
        endpoint,
        data
      )
        .then((response) => {
          commit('setLoading', false)
          return response
        })
        .catch((response) => {
          commit('setLoading', false)
          return response
        })
        .finally(() => {
          commit('setComplete', true)
        })
    },
    putReview({ commit, state, rootGetters }) {
      let data = {
        tip: state.tip,
        starRating: state.rate,
        reasonsForRating: state.reasons,
        comment: state.comment
      }

      const basketId = rootGetters['RecentOrders/reviewOrder']?.basketId
      const userId = rootGetters['UserProfile/userId']

      const endpoint = `/api/v6.0/user/${userId}/orders/${basketId}/review`
      data = excludeNilFromObj(data)

      commit('setLoading', true)

      return ApiService.put(
        endpoint,
        data
      )
        .then(() => {
          commit('setLoading', false)
          commit('setComplete', true)
        })
        .catch(() => {
          commit('setComplete', true)
          commit('setLoading', false)
        })
        .finally(response => response)
    },
  }
}
