<template>
  <!-- Component for Text Area  -->
  <div :class="wrapperClass">
    <label
      :class="[
        'forms_label',
        labelClass
        ]"
      :for="id"
    >
      {{ inputLabel }}
      <span
        v-if="secondaryLabelText"
        class="text--secondary-dark"
      >
        {{ secondaryLabelText }}
      </span>
    </label>
    <textarea
      :id="id"
      :value="modelValue"
      :name="fieldName"
      :placeholder="placeholder"
      :class="[
        'forms_input',
        inputClass,
        { 'error': error },
      ]"
      :required="isRequired"
      :maxlength="maxLength"
      :aria-describedby="descriptionId"
      :aria-label="ariaLabel"
      :autocomplete="autocomplete"
      :disabled="disabled"
      @input="handleInputChange($event)"
    />
    <div :id="descriptionId">
      <p
        v-if="error"
        aria-live="polite"
        class="text--base-strong text--base-error"
      >
        <slot name="errors" />
      </p>
      <p
        v-if="hasDescription"
        class="pdl-basic-text-input_field-description leader--single"
      >
        <slot name="description" />
      </p>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import replaceSmartPunctuations from 'utils/filters/replaceSmartPunctuations'

export default {
  props: {
    id: {
      type: String,
      required: true
    },
    fieldName: {
      type: String,
      required: true
    },
    ariaLabel: {
      type: String,
      default: ''
    },
    inputLabel: {
      type: String,
      default: ''
    },
    inputClass: {
      type: String,
      default: ''
    },
    labelClass: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    wrapperClass: {
      type: String,
      default: 'forms_input-container'
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    maxLength: {
      type: Number,
      default: null
    },
    hasDescription: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: String,
      default: ''
    },
    autocomplete: {
      type: String,
      default: 'on'
    },
    error: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    instantUpdate: {
      type: Boolean,
      default: false
    },
    secondaryLabelText: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      inputTimeout: null,
      currentValue: this.value,
    }
  },
  setup: () => ({ v$: useVuelidate() }),
  computed: {
    descriptionId() {
      return `${this.id}-description`
    }
  },
  methods: {
    // handle all validation on keypress, but emit
    // value changes after user has stopped
    // providing input for specified timeout
    handleInputChange($event) {
      if (this.instantUpdate) {
        this.$emit('update:modelValue', replaceSmartPunctuations($event.target.value))
      } else {
        clearTimeout(this.inputTimeout)
        this.inputTimeout = setTimeout(() => {
          this.$emit('update:modelValue', replaceSmartPunctuations($event.target.value))
        }, 600)
      }
    }
  },
  emits: ['update:modelValue']
}
</script>
